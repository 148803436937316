import React, { useState, useEffect} from 'react'
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import { getPreorders, deletePreorders, createPreOrdComments } from './apiAdmin'
import {Link, Redirect} from 'react-router-dom'
import { forwardRef } from 'react';
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Badge, Button, Modal } from 'react-bootstrap'
import Moment from 'react-moment';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
  
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
}));

const ManagePreorders = () => {

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const [error1, setError1] = useState('');

    const {user, token} = isAuthenticated()

    const [values, setValues] = useState({
        btnloading:true
    });

    const {btnloading} = values

    const [values1 , setValues1] = useState({
        comments:'',
        isBackup:false,
        yesBack:'',
        noBack:'',
        backupDate:'',
        status:2,
        btnloading2:false,
        error:'',
        success: false
    })

    const {comments, isBackup, backupDate, status, yesBack, noBack, btnloading2, success, error} = values1

    const [openModal, setModal] = useState(false);

    const [ preOrdID, setPreOrdId] = useState("");

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };


      var columns = [
       
        {title: "id", field: "_id", hidden: true},
        {title: "Email", field: "email",render: rowData => <Link to={`/admin/preorder/details/${rowData._id}`}>{rowData.email}</Link>},
        {title: "Type", field: "reg_type", render: rowData => {
            if(rowData.reg_type === 1){
                return <Badge pill variant="primary">Pre-Order Booking</Badge>
            } else if(rowData.reg_type === 2){
                return <Badge pill variant="warning">Top Banner</Badge>
            } else if(rowData.reg_type === 3){
                return <Badge pill variant="success">Bespoke Systems</Badge>
            } else if(rowData.reg_type === 4){
                return <Badge pill variant="info">Packages</Badge>
            }
        }},
        {title: "Date", field: "createdAt", render: rowData => { return <Moment format='DD/MM/YYYY'>{rowData.createdAt}</Moment>}},
        {title: "status", field: "status", render: rowData => {
            return rowData.status === 1 ? <Badge pill variant="success">Subscribed</Badge> :
            <Badge pill variant="danger">UnSubscribed</Badge>
        }},
        {title: "Actions",render: rowData => <Link to={`/admin/update/preorder/${rowData._id}`}><i className="ti-pencil-alt"></i></Link>},
        {title: "",render: rowData => <Link onClick={() => destroy(rowData._id)}><i className="ti-trash"></i></Link>},
        {title:"", render: rowData => <Button onClick={() => clickModal(rowData._id)} variant="outline-primary">Add comments</Button> }
        ]


      const [ preorders, setPreOrders ] = useState([]);
      const [hide, setHide] = useState(false) 

      const loadPreorders = () => {
        getPreorders().then(data => {
            if (data.error) {
                setValues({...values, btnloading:false});
                setError1(data.error);
                setOpen(true);
            } else {
                setPreOrders(data);
                setValues({...values, btnloading:false});
                setOpen(false);
            }
        });
    };

    const destroy = preorderId => {
        if(window.confirm("Do you want to delete this preorder?"))
        {
            setValues({...values, btnloading:true});
            deletePreorders(preorderId, user._id, token).then(data => {
                if (data.error) {
                    setValues({...values, btnloading:false});
                    setError1(data.error);
                    setOpen(true);
                } else {
                    setOpen(false);
                    loadPreorders();
                }
            });
        }
    };

    useEffect(() => {
        loadPreorders();
    }, [])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    }

    const clickModal = (preId) => {
        setPreOrdId("");
        setPreOrdId(preId);
        setModal(true);
    }

    const handleChangeComments = name => event => {

        if(name === "yesBack"){
            setHide(true);
            setValues1({...values1, [name]: event.target.checked, isBackup: true, error:'', success:false, btnloading2:false})
        }
        else if(name === "noBack"){
            setHide(false);
            setValues1({...values1, [name]: event.target.checked, isBackup: false, error:'', success:false, btnloading2:false})
        }
        else {
            setValues1({...values1, [name]: event.target.value, error:'', success:false, btnloading2:false})
        }
    }

    const clickCommentSubmit = (e) => {
        e.preventDefault();
        setValues1({...values1, error:'', success:false, btnloading2:true});
        createPreOrdComments(user._id, token, {preorderId:preOrdID,comments, isBackup, backupDate, added_by: user._id, yesBack, noBack})
        .then(data => {
            if(data.error){
                setValues1({...values1, error:data.error, success:false, btnloading2: false});
            }
            else {
                setValues1({
                    ...values1,
                    comments:'',
                    isBackup:false,
                    yesBack:'',
                    noBack:'',
                    backupDate:'',
                    success: true,
                    error:'',
                    btnloading2: false
                })
                setHide(false);
                setModal(false)
            }
        })
    }

    const showNewsletterModal = () => {

        return (
            <Modal show={openModal} onHide={() => setModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={clickCommentSubmit}>
                    <div className="form-group">
                        <label className="text-muted">Enter comment<span style={{color:"red"}}> *</span></label>
                        <textarea onChange={handleChangeComments('comments')} className="form-control" value={comments} required/>
                    </div>

                    <div className="form-group">
                    <label className="text-muted">Backup needed:<span style={{color:"red"}}> *</span></label><br/>
                        <input type="radio" onChange={handleChangeComments('yesBack')} name="backup" value={yesBack} />
                        <label htmlFor="yesBack" >&nbsp;Yes</label><br/>
                        <input type="radio" onChange={handleChangeComments('noBack')} name="backup" value={noBack} />
                        <label htmlFor="noBack" >&nbsp;No</label><br/>
                    </div>

                    { hide && <div className="form-group">
                        <label className="text-muted">Enter Backup Date<span style={{color:"red"}}> *</span></label>
                        <input type="date" onChange={handleChangeComments('backupDate')} className="form-control" value={backupDate} required/>
                    </div>}
                    
                    <button disabled={btnloading2}  className="btn btn-outline-primary">{btnloading2 ? "Loading..." : "Add"}</button>
                    <br/>
                    {showError()}
                </form>
                </Modal.Body>

            </Modal>
        )

    }

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );
 
    return (
        <AdminLayout topTitle="Pre-Order Bookings">

            <div className="row">     
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                        <h4 className="card-title" style={{textAlign:'center'}}>Pre-Order Bookings</h4>
                                    {showNewsletterModal()}
                                    <MaterialTable
                                    title={<Link to='/create/preorder'>Add new Pre-Order</Link>}
                                    columns={columns}
                                    isLoading={btnloading}
                                    data={preorders}
                                    icons={tableIcons}
                                    options={{
                                        pageSize:10
                                    }}
                                    localization={{ body:{ emptyDataSourceMessage:<h6>No Pre-Order Bookings to display</h6> } }}
                                    />

                                    <div className={classes.root}>
                                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>                                             
                                            <Alert onClose={handleClose} severity="error">{error1}</Alert>                                                                                  
                                        </Snackbar>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        
    </AdminLayout>
    )
}

export default ManagePreorders
