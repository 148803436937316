import React, { useState, useEffect } from 'react'
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import { getUser, getQuesByUserid, getOrdersByUserid } from '../admin/apiAdmin'
import { Link } from 'react-router-dom'
import { forwardRef } from 'react';
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Moment from 'react-moment';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
  
const useStyles1 = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
}));

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const UserDetails = ({match}) => { 

    const classes = useStyles();

    const classes1 = useStyles1();

    const [open, setOpen] = useState(false);

    const [error, setError] = useState('');

    const [loading, setLoading] = useState(true);

    const [values1, setValues1] = useState({
        btnloading:true
    });

    const {btnloading} = values1
    
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };


    var columns = [
           
        {title: "Order Id", field: "_id",hidden:true},
        {title: "Order ID", field: "orderid",render: rowData => <Link to={`/admin/order/details/${rowData._id}/${rowData.userid._id}`}>{rowData.orderid}</Link>},
        {title: "Product", field: "title"},
        {title:"Date of Order", field: "createdAt", render: rowData => {return <Moment format='DD/MM/YYYY'>{rowData.createdAt}</Moment>}},
        {title: "System Type", field:"system_type"},
        {title: "Purchase price(£)", field: "price"},
       
        {title: "Order Status", field: "status"}
    ]

    const [values, setValues] = useState({
        prefix:'',
        name: '',
        lastname: '',
        email: '',
        phone: '',
        role: '',
        status: '',
        subscription_plan: '',
        subscription_amount:'',
        subscription_paid:'',
        spinloading:true
    });

    const [questions, setQuestions] = useState([]);

    const [orders, setOrders] = useState([]);

    const {prefix,name, lastname, email, phone,role,status,subscription_amount,subscription_paid,subscription_plan} = values

    const { token } = isAuthenticated()

    const init = userId => {
        getUser(userId,token).then(data => {
            if (data.error) {
                setLoading(false);
                setError(data.error);
                setOpen(true);
            } else {
               
                setValues({
                    ...values,
                    prefix:data.prefix,
                    name: data.name,
                    lastname: data.lastname,
                    email: data.email,
                    phone: data.phone,
                    role: data.role,
                    status: data.status,
                    subscription_amount: data.subscription_amount,
                    subscription_paid:data.subscription_paid,
                    subscription_plan: data.subscription_plan,
                    spinloading:false
                });
                loadQuestions(match.params.userId);
            }
        });
    };

    const loadQuestions = (userId) => {
        getQuesByUserid(userId).then(data => {
            if(data.error) {
                setLoading(false);
                setError(data.error);
                setOpen(true);
            } else {
                setQuestions(data);
                setOpen(false);
            }
        });
    };

    const loadOrders = (userId) => {
        getOrdersByUserid(userId).then(data => {
            if(data.error) {
                setLoading(false);
                setError(data.error);
                setOpen(true);
            } else {
                setOrders(data);
                setLoading(false);
                setOpen(false);
            }
        });
    };


    useEffect(() => {
        init(match.params.userId);
        loadOrders(match.params.userId);
    }, []);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    }


    const userDetail = () => (
        <form>       
                     <h4><b>Title:</b> &nbsp;{prefix}</h4><br></br> 
                    <h4><b>First Name:</b> &nbsp;{name}</h4> <br></br> 
                    <h4><b>Last Name:</b>&nbsp; {lastname}</h4><br></br> 
                    <h4><b>Email:</b>&nbsp; {email}</h4> <br></br> 
                    <h4><b>Phone:</b>&nbsp; {phone}</h4><br></br> 
                    <h4><b>Role:</b>&nbsp; {role === 1 ? 'Admin' : 'Customer'}</h4><br></br> 
                    <h4><b>Status:</b>&nbsp; {status === 1 ? 'Active' : 'Deactivated'}</h4>  <br></br> 
                    {((Number(subscription_plan) === 1 || Number(subscription_plan) === 0) || Number(subscription_plan) === 2) && (<h4><b>Subscription Plan:</b>&nbsp;{Number(subscription_plan) === 1 ? 'Gold' : (Number(subscription_plan) === 0 ? 'Silver' : (Number(subscription_plan) === 2 && 'Bronze'))} </h4>)}<br/>
                    {((Number(subscription_plan) === 1 || Number(subscription_plan) === 0) || Number(subscription_plan) === 2) && (<h4><b>Subscription Amount:</b>&nbsp;£ {subscription_amount} </h4>)}<br/>
                    {(Number(subscription_plan) === 1 || Number(subscription_plan) === 0 || Number(subscription_plan) === 2) && (<h4><b>Subscription Status:</b>&nbsp;{(subscription_paid) ? "Active" : "InActive" }<br></br> </h4>)}
        
                
        </form>
    );

    const quesDetails = () => {
        return (
            questions.map((p,i) => (
                <div>
                        <div className="col-md-10 px-0 mb-3">
                                            <div className="col-md-12 bord-line">

                                                <div className="row">
                                                    <div className="col-md-1 wid-5-per">
                                                       
                                                    </div>
                                                    <div className="col-md-8 py-3 wid-60-per-in">

                                                    <h5 className="pb-3 color-blk-in font-one-re"><b>Question and Answers</b></h5>

                                                    
                                   
                                        <h4>Category:&nbsp; &nbsp; &nbsp; 
                                        {(p.security === true && p.safety === true && p.wellness === true) && 'safety, security, wellness'}
                                        {(p.security === true && p.safety === false && p.wellness === false) && 'security'}
                                        {(p.security === true && p.safety === true && p.wellness === false) && 'safety, security'}
                                        {(p.security === true && p.safety === false && p.wellness === true) && 'security, wellness'}
                                        {(p.security === false && p.safety === true && p.wellness === true) && 'safety, wellness'}
                                        {(p.security === false && p.safety === true && p.wellness === false) && 'safety'}
                                        {(p.security === false && p.safety === false && p.wellness === true) && 'wellness'}
                                        </h4>
                                    <h4 key={i}>What type of house?&nbsp; &nbsp;  &nbsp; {p.house_type === 0 && <span>Flat</span>} {p.house_type === 1 && <span>Terraced</span>} {p.house_type === 2 && <span>Detached</span>} {p.house_type === 3 && <span>Semi-Detached</span>}  {p.house_type === 4 && <span>Bungalow</span>}</h4>
                                    <h4 key={i}>How many people live in the house?&nbsp; &nbsp;  &nbsp; {p.no_people > 0 ? p.no_people : null}</h4>
                                    <h4 key={i}>How many floors in this house?&nbsp; &nbsp;  &nbsp; {p.no_floors > 0 ? p.no_floors : null}</h4>
                                    <h4 key={i}>How many entrance door/access points?&nbsp; &nbsp;  &nbsp;{p.no_entdoors > 0 ? p.no_entdoors : null}</h4>
                                    <h4 key={i}>How many bedrooms in the house?&nbsp; &nbsp; &nbsp; {p.no_bedrooms > 0 ? p.no_bedrooms : null}</h4>
                                    <h4 key={i}>Do you have a pet?&nbsp;&nbsp;&nbsp;{p.pet ? 'Yes' : 'No'}</h4>
                                   
                                    {p.pet ? <h4>Would you like to remove motion sensors from your customised bundle?&nbsp;&nbsp;&nbsp;{p.isMotionSensor ? 'Yes' : 'No'}</h4> : ''}
                                    {/* <h4 key={i}>How many entrance doors or access points (including windows) does your home have? &nbsp;
                                    
                                        {(p.driveway === true && p.pet === true && p.shed === true) && 'driveway, pet, shed'}
                                        {(p.driveway === true && p.pet === false && p.shed === false) && 'driveway'}
                                        {(p.driveway === true && p.pet === true && p.shed === false) && 'pet, driveway'}
                                        {(p.driveway === true && p.pet === false && p.shed === true) && 'driveway, shed'}
                                        {(p.driveway === false && p.pet === true && p.shed === true) && 'pet, shed'}
                                        {(p.driveway === false && p.pet === true && p.shed === false) && 'pet'}
                                        {(p.driveway === false && p.pet === false && p.shed === true) && 'shed'}

                                    </h4> */}
                                     <h4 key={i}>Date:&nbsp;&nbsp;<Moment format='DD/MM/YYYY'>{p.createdAt}</Moment></h4>
                                   
                                                    </div>
                                                    <div className="col-md-3 pt-3">
                                                        <p className="font-one-edit text-right"></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        ))
        )
    }

    return (
        <AdminLayout className="container" topTitle="User Details">
            <div className="row">     
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body"> 
                            <h4 className="card-title" style={{textAlign:'center'}}>Customer Details</h4>
                            {/* {(spinloading) ? <div className='loader-container'><div className='loader'></div></div> : ""} */}
                                {!loading && userDetail()}

                                <br/>
                                {!loading && quesDetails()}
                                <br/>

                                <MaterialTable
                                    title={"Order Details"}
                                    columns={columns}
                                    isLoading={loading}
                                    data={orders}
                                    icons={tableIcons}
                                    localization={{ body:{ emptyDataSourceMessage:<h6>No orders to display</h6> } }}
                                    />

                                <Backdrop className={classes.backdrop} open={loading} >
                                    <CircularProgress color="inherit" />
                                </Backdrop> 

                                <div className={classes.root}>
                                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>                                             
                                        <Alert onClose={handleClose} severity="error">{error}</Alert>                                                                                  
                                    </Snackbar>
                                </div>

                        </div>
                        <hr/>
                           
                    </div>
                </div>
           </div>
        </AdminLayout>
    )
    
}
export default UserDetails;
