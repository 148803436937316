import React, { useState, useEffect} from 'react'
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import { getNewsletters, removeNewsletter } from './apiAdmin'
import {Link} from 'react-router-dom'
import { forwardRef } from 'react';
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Badge } from 'react-bootstrap'
import Moment from 'react-moment';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
  
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
}));

const ManageNewsletters = () => {

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const [error, setError] = useState('');

    const {user, token} = isAuthenticated()

    const [btnloading, setBtnloading] = useState(true);

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };


      var columns = [
       
        {title: "id", field: "_id", hidden: true},
        {title: "Email", field: "email"},
        {title: "Date", field: "createdAt", render: rowData => { return <Moment format='DD/MM/YYYY'>{rowData.createdAt}</Moment>}},
        {title: "status", field: "status", render: rowData => {
            return rowData.status === 1 ? <Badge pill variant="success">Subscribed</Badge> :
            <Badge pill variant="danger">UnSubscribed</Badge>
        }},
        {title: "Actions",render: rowData => <Link to={`/admin/newsletter/update/${rowData._id}`}><i className="ti-pencil-alt"></i></Link>},
        {title: "",render: rowData => <Link onClick={() => destroy(rowData._id)}><i className="ti-trash"></i></Link>}
        ]


      const [ newsletters, setNewsletters ] = useState([]);

      const loadNewsletters = () => {
        getNewsletters().then(data => {
            if (data.error) {
                setBtnloading(false);
                setError(data.error);
                setOpen(true);
            } else {
                setNewsletters(data);
                setBtnloading(false);
                setOpen(false);
            }
        });
    };

    const destroy = newsletterId => {
        if(window.confirm("Do you want to delete this newsletter?"))
        {
            setBtnloading(true);
            removeNewsletter(user._id, token, {id:newsletterId}).then(data => {
                if (data.error) {
                    setBtnloading(false);
                    setError(data.error);
                    setOpen(true);
                } else {
                    setOpen(false);
                    loadNewsletters();
                }
            });
        }
    };

    useEffect(() => {
        loadNewsletters();
    }, [])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    }
 
    return (
        <AdminLayout topTitle="Manage Newsletters">

            <div className="row">     
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                        <h4 className="card-title" style={{textAlign:'center'}}>Manage Newsletters</h4>
                                
                                    <MaterialTable
                                    title={<Link to='/create/newsletter'>Add new newsletter</Link>}
                                    columns={columns}
                                    isLoading={btnloading}
                                    data={newsletters}
                                    icons={tableIcons}
                                    options={{
                                        pageSize:10
                                    }}
                                    localization={{ body:{ emptyDataSourceMessage:<h6>No newsletters to display</h6> } }}
                                    />

                                    <div className={classes.root}>
                                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>                                             
                                            <Alert onClose={handleClose} severity="error">{error}</Alert>                                                                                  
                                        </Snackbar>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        
    </AdminLayout>
    )
}

export default ManageNewsletters
