import React, {useState,useEffect} from 'react'
import { Redirect,Link } from 'react-router-dom'
import ShowImage from '../../../admin/ShowImage'
import { addItem, updateItem, removeItem } from './cartHelpers'
import { withRouter } from 'react-router-dom'
import { isAuthenticated } from '../../../auth/index'

const Card = ({product, showAddToCartButton = true, cartUpdate = false, showRemoveProductButton=false,history}) => {

    const [redirect, setRedirect] = useState(false)
    const [count, setCount] = useState(1);
    const [hidecount, setHideCount] = useState(false);
    const [values, setValues] = useState({
        productIDs: ''
    })
    const {
        productIDs} = values

   
  
    const shouldRedirect = redirect => {
        if(redirect) {
            return <Redirect to="/revieworder" />
        }
    }
    const handleChange = (e) =>{
        setCount(e.target.value < 1 ? 1 : e.target.value)        
    };
    const addToCart1 = (count,e) => { 
        setHideCount(true);
        addItem(product, setRedirect(false));
        updateItem(product._id,count);
    }

    useEffect(() => {
        if(localStorage.getItem("cart")) {
            const items = JSON.parse(localStorage.getItem("cart"))
            let ids=',';
            items.map((product, i) => ( 
                ids+=product._id+","
            ))
            setValues({
                productIDs: ids})
        }
        
    }, [])

    const showAddToCart = showAddToCartButton => {
        return (           
                showAddToCartButton && (
                    (isAuthenticated() && hidecount) ?                   
                    <div className='blue-acti'>Cart updated. <a href='/revieworder'>View cart</a></div> : (isAuthenticated())?
                    <div className="col-md-12 row">
                    <div className="col-md-6 pt-1">
                    <div className="form-group">
        
        <select className="form-control" id="sel1" name="sellist1" onChange={e => handleChange(e)}>
        <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
          <option>6</option>
          <option>7</option>
          <option>8</option>
          <option>9</option>
          <option>10</option>
        </select>     
        
      </div></div>
                   
                <div onClick={(e) => addToCart1(count,e)} className="col-md-6 add-to-cart-btn pt-2">+ <Link to={history.location.pathname} >Add to cart</Link></div></div>:
                    <div className="col-md-12"> <Link to="/step1"> <button className="btn btn-success pull-right mb-3" data-toggle="modal" data-target="#send-request">Design your personalised system</button></Link></div>
                )               
            
        );
    };

    const showRemoveButton = showRemoveProductButton => {
        return (
            showRemoveProductButton && (
                <button onClick={() => removeItem(product._id)} className="btn btn-outline-danger mt-2 mb-2">
                   Remove Product
                </button>
            )
            
        );
    };

   

        

    return (    

       

        <div className="row">
            <div className="col-md-3 p-0 add-more-img-pad">
                {shouldRedirect(redirect)}
               <ShowImage  item={product._id}/>
            </div>
            <div className="col-md-6 pl-5 ">
              {  
               <h4 className="font-gen"><a href={'/product-details/'+product._id}>{product.name}</a> &nbsp;&nbsp;&nbsp; </h4>
}
                     <p>{product.description}</p>
                <div className="row pt-4" >
               
                    {(productIDs.includes(','+product._id+',') && (isAuthenticated()))?
<div className='blue-acti'><a href='/revieworder'>View cart</a></div> :
 
showAddToCart(showAddToCartButton)
}
                  {/* {showCartUpdateOptions(cartUpdate)}  */}
                  
                  
                   {showRemoveButton(showRemoveProductButton)}
                </div>
            </div>
            <div className="col-md-3 pl-5 ">
                <h4 className="font-gen">£ {product.sale_price}</h4>
            </div>
        </div>



            
    )
}

export default withRouter(Card)
