import React, {Fragment} from 'react'
import Header from './Header'
import Footer from './Footer'

import './css/bootstrap.min.css'
//import './css/owl.carousel.min.css'
//import './css/owl.theme.default.min.css'
//import './css/animate.min.css'
//import './css/jquery.pagepiling.css'
//import './css/jquery.fancybox.min.css'
import './css/LineIcons.css'
import '../admin/css/style_new.css'
import './css/style.css'
import './css/custom.css'



const Layout = ({ className,children}) => {

    return (
        <Fragment style={{overflowY: 'scroll', overflowX: 'hidden'}}>
            
           
            <Header />
            
                <div className="section pp-scrollable slide-dark slide-dark-footer pp-section active" data-anchor="Intro" style={{zindex: '2'}}>
                       <div className={className}>
                           {children}
                       </div>
                       </div>
                       <Footer />
          
          
                     
                    
            
        </Fragment>
        
        
    )
}

export default Layout
