import React, {useState} from 'react'
import { createNewsletter } from './Priorities/apiUser'

const Subscribe = () => {

    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSucces] = useState(false);

    const handleChange = (e) => {
        setError('')
        setSucces(false);
        setEmail(e.target.value)
    };

    const clickSubmit = (e) => {
        e.preventDefault();
        setError('');
        setSucces(false);
        createNewsletter({email})
        .then(data => {
            if(data.error) {
                setError(data.error);
            } else {
                setError("");
                setSucces(true);
            }
        });
    };

    const showSuccess = () => {
        if(success) {
            return <div className="title-mini stay-marg" style={{color:'green'}}>{email} has been succesfully subscribed.</div>
        }
    };

    const showError = () => {
        if(error) {
            if(error === "11000 duplicate key error collection: SafelyTeam.newsletters index: email already exists") {
                return <div className="title-mini stay-marg" style={{color:'red'}}>{email} already exists!</div>
            }
            else {
                return <div className="title-mini stay-marg" style={{color:'red'}}>{error}</div>
            }    
        }
    };


    return (
        <div className="col-md-12 px-0 connect-bg inner-safely">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 py-5 text-mini-mbile">
                                        <div className="title-mini stay-marg">Stay Connected</div>
                                        <div className="slide-title-sub font-weight-bold black-col-sub subs-font">Subscribe to our latest<br/> info on products, tips</div>
                                    </div>

                                    <div className="col-md-6 input-tb-padd">
                                        <div className="input-group mb-3">
                                            <input type="text" onChange={handleChange} value={email} className="form-control input-form-bg pl-0" placeholder="Enter your email Here" id="demo" />
                                            <div className="input-group-append">
                                                <span onClick={clickSubmit} className="input-group-text exa-bg px-0"><a href="#/"><img src={require('./img/icon-arr.png')} width="30" className="ic-arrow-lt" alt="Right Arrow Icon - 24/7 Mobile Monitoring - Safely" /></a></span>
                                            </div>
                                            
                                        </div>
                                        {showError()}
                                            {showSuccess()}
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
    )
}

export default Subscribe
