import React, {useState, useEffect, Fragment} from 'react'
import {Helmet} from 'react-helmet'
import Header from '../Header'
import Footer from '../Footer'
import { API } from "../../../config";
import { updateOrderSts } from './apiUser'
import NewLayout from '../NewLayout'


const Success = () => {

const [session,setSession] = useState({});

const params = new URLSearchParams(window.location.search.substring(1));
const orderid = params.get("order");    
const sessionId = params.get("session_id");  



const [values, setValues] = useState({successmsg: '',error: '', updated: false})
const {error, successmsg, updated,  } = values

 useEffect(() => {
   async function fetchSession() {
     setSession(
       await fetch(`${API}/checkout-session?sessionId=` + sessionId).then((res) =>
         res.json().then((responseJson) => {
                       updateOrder(orderid, responseJson.payment_intent);
                   })
       )
     )
   }
   fetchSession();
 }, [sessionId]);



const showSuccess = () => (
   <div className="alert alert-success">
       { successmsg }
   </div>
);

const updateOrder = (orderid, paymentid) => {
   updateOrderSts(orderid, 'Processing', paymentid).then(data => {
       if(data.error) {
           setValues({...values, error: data.error, successmsg:'Unable to place order'})
       } else {
           setValues({...values, successmsg: 'Order placed successfully', updated:true})
       }
   })  
 };     

return (
      <NewLayout topTitle="SafelyTeam - Payment Failed">
          <section> 
              <div className="col-md-12 px-0 pt-5">
                  <div className="col-md-12 px-0 inner-tp-pad ">                    
                      <div className="container abt-text-top-container pt-5">
                        <div className="col-md-12 py-5">
                            <h1 className="text-center pt-5 blue-acti">Payment Failed</h1>
                     
                          <div className="alert alert-success">
                            <h4>Thanks for placing your order with SafelyTeam.</h4>
                            <h4>Something has gone wrong with your payment.</h4>
                            <h4>To complete payment or edit your order, <a className="btn btn-info btn-gra-my-p" href="/user/yourorders">Please Click Here</a></h4>
                            <h4>Once we receive the payment, we can pre-configure and dispatch your system.</h4>             
                          </div>
                      </div>
                    </div>
                </div>
              </div>
          </section> 
       </NewLayout>
      
)
}

export default Success