import React, {useState, useEffect,Component } from 'react'
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import { Redirect } from "react-router-dom"
import {createHomePage,newUpdateHomePage,getHomePage, uploadHomeImg } from './apiAdmin'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion,AccordionSummary,AccordionDetails,Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import CKEditor from 'ckeditor4-react';
import PropTypes from 'prop-types';

// const editorConfiguration = {
//     plugins: [Essentials, Bold, Italic, Paragraph],
//     toolbar: ["bold", "italic"]
//   };

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStylesSnack = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
}));

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const useStyles1 = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

const AddHomePage = () => {

    const { user, token} = isAuthenticated();
    

    const [spinloading , setSpinloading] = useState(true);

    const [btnloading, setBtnloading] = useState(false);

    const [home, setHome] = useState([])

    const [btnName, setBtnName] = useState("Add");

    const classes = useStyles();

    const classes1 = useStyles1();

    const [open, setOpen] = useState(false);

    const classesSnack = useStylesSnack();

    const [openSnack, setOpenSnack] = useState(false);

    const [ values, setValues] = useState({
        title:'',
        homeId:'',
        top_banner:'',

        slider1_image:'',
        slider1_text:'',
        slider1_link:'',

        slider2_image:'',
        slider2_text:'',
        slider3_link:'',

        slider3_image:'',
        slider3_text:'',
        slider3_link:'',

        unique_heading1:'',
        unique_content1:'',
        unique_image1:'',

        unique_heading2:'',
        unique_content2:'',
        unique_image2:'',

        unique_heading3:'',
        unique_content3:'',
        unique_image3:'',

        changeLives_heading1:'',
        changeLives_content1:'',
        changeLives_image1:'',

        changeLives_heading2:'',
        changeLives_content2:'',
        changeLives_image2:'',

        changeLives_heading3:'',
        changeLives_content3:'',
        changeLives_image3:'',

        error:'',
        success:false,   
    });

    const { 
        title,
        homeId,
        top_banner,

        slider1_image,
        slider1_text,
        slider1_link,

        slider2_image,
        slider2_text,
        slider2_link,

        slider3_image,
        slider3_text,
        slider3_link,

        unique_content1,
        unique_heading1,
        unique_image1,

        unique_heading2,
        unique_content2,
        unique_image2,

        unique_heading3,
        unique_content3,
        unique_image3,

        changeLives_heading1,
        changeLives_content1,
        changeLives_image1,

        changeLives_heading2,
        changeLives_content2,
        changeLives_image2,

        changeLives_heading3,
        changeLives_content3,
        changeLives_image3,

        error,
        success,
    } = values;

    const loadHome = () => {
        getHomePage().then(data => {
            if(data.error) {
                console.log(data.error);
                setSpinloading(false);
            } else {
                setHome(data);
                console.log(data);
                
                if(data.length >= 1) {
                    setValues({...values,
                        title:data[0].title,
                        homeId: data[0]._id,
                        top_banner: data[0].top_banner,

                        slider1_image: data[0].slider1_image,
                        slider1_text: data[0].slider1_text,
                        slider1_link: data[0].slider1_link,
                
                        slider2_image: data[0].slider2_image,
                        slider2_text: data[0].slider1_text,
                        slider2_link: data[0].slider2_link,
                
                        slider3_image: data[0].slider3_image,
                        slider3_text: data[0].slider2_text,
                        slider3_link: data[0].slider3_link,

                        unique_heading1: data[0].unique_heading1,
                        unique_content1: data[0].unique_content1,
                        unique_image1: data[0].unique_image1,

                        unique_heading2: data[0].unique_heading2,
                        unique_content2: data[0].unique_content2,
                        unique_image2: data[0].unique_image2,

                        unique_heading3: data[0].unique_heading3,
                        unique_content3: data[0].unique_content3,
                        unique_image3: data[0].unique_image3,

                        changeLives_heading1: data[0].changeLives_heading1,
                        changeLives_content1: data[0].changeLives_content1,
                        changeLives_image1: data[0].changeLives_image1,

                        changeLives_heading2: data[0].changeLives_heading2,
                        changeLives_content2: data[0].changeLives_content2,
                        changeLives_image2: data[0].changeLives_image2,

                        changeLives_heading3: data[0].changeLives_heading3,
                        changeLives_content3: data[0].changeLives_content3,
                        changeLives_image3: data[0].changeLives_image3,

                    })
                    setBtnName("Update")
                }  
                setSpinloading(false);  
            }
        });
    };

    useEffect(() => {
        loadHome()
    },[])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpenSnack(false);
    }

    const handleChangeImage = name => event => {
        setOpen(true);
        setOpenSnack(false);
        const value = ( name === "unique_image1" || name === "unique_image2" || name === "unique_image3" || name === "slider1_image" || name === "slider2_image" || name === "slider3_image" || name === "changeLives_image1" || name === "changeLives_image2" || name === "changeLives_image3") ? event.target.files[0] : event.target.value;
        var formData = new FormData();
        var tempName="";
        formData.set(name, value);
        formData.set('name',name);
        uploadHomeImg(user._id,token,formData).then(
           async data => {
                if(data.error){
                    console.log("Update failed");
                    setValues({...values,success:false, error:data.error})
                    setOpen(false)
                    setOpenSnack(true);
                }
                else {
                   
                    tempName= data;
                    let tempData = await newUpdateHomePage(user._id, token, {[name]:data,homeId})

                    if(tempData){
                        setValues({...values, [name]:tempData.tempName, error:''})  
                        setOpen(false)           
                    }  
                }
            }
        )
        setValues({ ...values, [name]: value });
    } 

    const handleChange =(e, name) => {
        console.log("name",name)
        setOpenSnack(false);
        var tempValue = e.target.value

        newUpdateHomePage(user._id, token, {[name]: tempValue, homeId}).then(
            data => {
                if (data.error) {
                    console.log("Update failed");
                    setValues({...values, success:false, error:data.error})
                    setOpen(false)
                    setOpenSnack(true);
                   
                } 
                else {
                    console.log("Success")
                    setValues({ ...values, [name]: tempValue, error:'' });
                    tempValue="";
                }
            }
        ) 
    }

    const handleChangeTop =(data, name) => {
        console.log("name",name)
        setOpenSnack(false);
        var tempValue = data
        newUpdateHomePage(user._id, token, {[name]: tempValue, homeId}).then(
            data => {
                if (data.error) {
                    console.log("Update failed");
                    setValues({...values, success:false, error:data.error})
                    setOpenSnack(true);
                    setOpen(false)
                } 
                else {
                    console.log("Success")
                    setValues({ ...values, [name]: tempValue, error:'' });
                    tempValue="";
                }
            }
        ) 
    }

    const onEditorChange =(evt,name ) => {
        var tempValue = evt.editor.getData()
        setOpenSnack(false);
        newUpdateHomePage(user._id, token, {[name]: tempValue, homeId}).then(
            data => {
                if (data.error) {
                    console.log("Update failed");
                    setValues({...values, success:false, error:data.error})
                    setOpenSnack(true);
                    setOpen(false)
                } 
                else {
                    console.log("Success")
                    setValues({ ...values, [name]: tempValue, error:'' });
                    tempValue="";
                }
            }
        )   
    }

   

    const handleChange1 =(data, name ) => {
        console.log("name",name)
        setOpenSnack(false);
        var tempValue = data.editor.getData()
        
        newUpdateHomePage(user._id, token, {[name]: tempValue, homeId}).then(
            data => {
                if (data.error) {
                    console.log("Update failed");
                    setValues({...values, success:false, error:data.error})
                    setOpenSnack(true);
                } 
                else {
                    console.log("Success")
                    setValues({ ...values, [name]: tempValue, error:'' });
                    tempValue="";
                }
            }
        )         
    }
    CKEditor.editorUrl = 'https://cdn.ckeditor.com/4.16.0/full/ckeditor.js';
    const showForm = () => {
        return (
            <form className="mb-3">
                <h4><b>{title}</b></h4>
                <hr/>
                <br/>
                <div className="row">


                    <Accordion>
                        <AccordionSummary
                         expandIcon={<ExpandMoreIcon />}
                         aria-controls="panel1a-content"
                         id="panel1a-header">
                             <Typography className={classes1.heading}><b style={{fontSize:'1rem'}}>Top Banner Section</b></Typography>

                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography>
                                <div className="row">
                                    <div className="col-md-12">
                                    <CKEditor
                    data={top_banner}
                    config={ {
                           // plugins: [font]
                               }}
                              
                    onBlur={e => onEditorChange(e,'top_banner')} />
                    {/* <label>
                        Change value:
                        <textarea defaultValue={top_banner} onChange={e => handleChange1(e,'top_banner')} />
                    </label>
                    {EditorPreview(top_banner)} */}
                   
                                        {/* <div className="form-group">
                                            <label className="text-muted">Enter Top banner text</label>
                                            <input onBlur={e => handleChange(e,'top_banner')} type="text" className="form-control" defaultValue={top_banner} required />
                                        </div> */}
                                            {/* <CKEditor
                                                //editor={ ClassicEditor }
                                               // config={ {
                                                   // plugins: [ ],
                                                    //toolbar: [ 'imageUpload'],
                                                    // ckfinder: {
                                                    //     uploadUrl: 'https://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json'
                                                    // }
                                               // } }
                                                // config={editorConfiguration}
                                                data={top_banner}
                                                onChange={handleChangeTop(data, 'top_banner')}
                                                onInit={editor => {
                                                    // You can store the "editor" and use when it is needed.
                                                    console.log("Editor is ready to use!", editor);
                                                  }}
                                                onReady={ editor => {
                                                    // You can store the "editor" and use when it is needed.
                                                    console.log( 'Editor is ready to use!' );
                                                } }
                                               
                                                onBlur={ ( event, editor ) => {
                                                    const data = editor.getData();
                                                    handleChangeTop(data, 'top_banner')
                                                } }
                                                
                                                
                                            /> */}
                                    </div>  
                                </div>                        
                                
                            </Typography>

                        </AccordionDetails>
                    </Accordion> 

                    <Accordion>
                        <AccordionSummary
                         expandIcon={<ExpandMoreIcon />}
                         aria-controls="panel1a-content"
                         id="panel1a-header">
                             <Typography className={classes1.heading}><b style={{fontSize:'1rem'}}>Slider Section</b></Typography>

                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography>
                                <div className="row">

                                    <div className="col-md-6">

                                        <h5 style={{fontWeight:'bold'}}>Slider 1</h5>

                                        <div className="form-group">
                                            <label className="text-muted">Enter Text 1</label>
                                            <input onBlur={e => handleChange(e,'slider1_text')} type="text" className="form-control" defaultValue={slider1_text} required />
                                        </div>

                                        <div className="form-group">
                                            <label className="text-muted">Enter Link 1</label>
                                            <input onBlur={e => handleChange(e, 'slider1_link')} type="text" className="form-control" defaultValue={slider1_link} required />
                                        </div>

                                        <label className="text-muted">Upload Image 1<span style={{color:'red'}}> *</span></label>
                                        <div className="form-group">
                                            <label className="btn btn-secondary">
                                                <input onChange={handleChangeImage('slider1_image')} type="file" name="slider1_image" accept="image/*" />
                                            </label>
                                        </div>

                                    </div>

                                    <div className="col-md-6">
                                        {(home.length >=1 && home[0].slider1_image) ? <><img src={"/uploads/"+slider1_image} width="50%" height="auto" className="img-fluid" alt="" /></> : <><img src={require('../theme/user/img/uploaded/no-preview.png')} width="50%" height="auto" className="img-fluid" alt="" /></>}
                                    </div>

                                    <div className="col-md-6">

                                        <h5 style={{fontWeight:'bold'}}>Slider 2</h5>

                                        <div className="form-group">
                                            <label className="text-muted">Enter Text 2</label>
                                            <input onBlur={e => handleChange(e,'slider2_text')} type="text" className="form-control" defaultValue={slider2_text} required />
                                        </div>

                                        <div className="form-group">
                                            <label className="text-muted">Enter Link 2</label>
                                            <input onBlur={e => handleChange(e, 'slider2_link')} type="text" className="form-control" defaultValue={slider2_link} required />
                                        </div>

                                        <label className="text-muted">Upload Image 2<span style={{color:'red'}}> *</span></label>
                                        <div className="form-group">
                                            <label className="btn btn-secondary">
                                                <input onChange={handleChangeImage('slider2_image')} type="file" name="slider2_image" accept="image/*" />
                                            </label>
                                        </div>

                                    </div>

                                    <div className="col-md-6">
                                        {(home.length >=1 && home[0].slider2_image) ? <><img src={"/uploads/"+slider2_image} width="50%" height="auto" className="img-fluid" alt="" /></> : <><img src={require('../theme/user/img/uploaded/no-preview.png')} width="50%" height="auto" className="img-fluid" alt="" /></>}

                                    </div>

                                    <div className="col-md-6">

                                        <h5 style={{fontWeight:'bold'}}>Slider 3</h5>

                                        <div className="form-group">
                                            <label className="text-muted">Enter Text 3</label>
                                            <input onBlur={e => handleChange(e,'slider3_text')} type="text" className="form-control" defaultValue={slider3_text} required />
                                        </div>

                                        <div className="form-group">
                                            <label className="text-muted">Enter Link 3</label>
                                            <input onBlur={e => handleChange(e, 'slider3_link')} type="text" className="form-control" defaultValue={slider3_link} required />
                                        </div>

                                        <label className="text-muted">Upload Image 3<span style={{color:'red'}}> *</span></label>
                                        <div className="form-group">
                                            <label className="btn btn-secondary">
                                                <input onChange={handleChangeImage('slider3_image')} type="file" name="slider3_image" accept="image/*" />
                                            </label>
                                        </div>

                                    </div>

                                    <div className="col-md-6">
                                        {(home.length >=1 && home[0].slider3_image) ? <><img src={"/uploads/"+slider3_image} width="50%" height="auto" className="img-fluid" alt="" /></> : <><img src={require('../theme/user/img/uploaded/no-preview.png')} width="50%" height="auto" className="img-fluid" alt="" /></>}
                                    </div>

                                </div>

                            </Typography>

                        </AccordionDetails>
                    </Accordion>


                    <Accordion>
                        <AccordionSummary
                         expandIcon={<ExpandMoreIcon />}
                         aria-controls="panel1a-content"
                         id="panel1a-header">
                             <Typography className={classes1.heading}><b style={{fontSize:'1rem'}}>What makes us unique Section</b></Typography>

                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography>

                                <div className="row">

                                

                                    <div className="col-md-6">

                                        <h5 style={{fontWeight:'bold'}}>What makes us unique Section 1</h5>

                                        <div className="form-group">
                                            <label className="text-muted">Heading 1</label>
                                            <input onBlur={e => handleChange(e,"unique_heading1")} type="text" className="form-control" defaultValue={unique_heading1} required />
                                        </div>

                                        <div className="form-group">
                                            <label className="text-muted">Content 1</label>
                                            <CKEditor
                    data={unique_content1}                   
                    onBlur={e => handleChange1(e,'unique_content1')} />
                                            {/* <input onBlur={e => handleChange(e, 'unique_content1')} type="text" className="form-control" defaultValue={unique_content1} required /> */}
                                        </div>

                                        <label className="text-muted">Upload Image 1<span style={{color:'red'}}> *</span></label>
                                        <div className="form-group">
                                            <label className="btn btn-secondary">
                                                <input onChange={handleChangeImage('unique_image1')} type="file" name="unique_image1" accept="image/*" />
                                            </label>
                                        </div>

                                    </div>

                                    <div className="col-md-6">
                                        {(home.length >=1 && home[0].unique_image1) ? <><img src={"/uploads/"+unique_image1} width="50%" height="auto" className="img-fluid" alt="" /></> : <><img src={require('../theme/user/img/uploaded/no-preview.png')} width="50%" height="auto" className="img-fluid" alt="" /></>}                
                                    </div>

                                    <div className="col-md-6">

                                        <h5 style={{fontWeight:'bold'}}>What makes us unique Section 2</h5>

                                        <div className="form-group">
                                            <label className="text-muted">Heading 2</label>
                                            <input onBlur={e => handleChange(e,'unique_heading2')} type="text" className="form-control" defaultValue={unique_heading2} required />
                                        </div>

                                        <div className="form-group">
                                            <label className="text-muted">Content 2</label>
                                            <CKEditor
                    data={unique_content2}                   
                    onBlur={e => handleChange1(e,'unique_content2')} />
                                            {/* <input onBlur={e => handleChange(e, 'unique_content2')} type="text" className="form-control" defaultValue={unique_content2} required /> */}
                                        </div>

                                        <label className="text-muted">Upload Image 2<span style={{color:'red'}}> *</span></label>
                                        <div className="form-group">
                                            <label className="btn btn-secondary">
                                                <input onChange={handleChangeImage('unique_image2')} type="file" name="unique_image2" accept="image/*" />
                                            </label>
                                        </div>

                                    </div>

                                    <div className="col-md-6">
                                        {(home.length >=1 && home[0].unique_image2) ? <><img src={"/uploads/"+unique_image2} width="50%" height="auto" className="img-fluid" alt="" /></> : <><img src={require('../theme/user/img/uploaded/no-preview.png')} width="50%" height="auto" className="img-fluid" alt="" /></>}                               
                                    </div>

                                    <div className="col-md-6">

                                        <h5 style={{fontWeight:'bold'}}>What makes us unique Section 3</h5>

                                        <div className="form-group">
                                            <label className="text-muted">Heading 3</label>
                                            <input onBlur={e => handleChange(e,'unique_heading3')} type="text" className="form-control" defaultValue={unique_heading3} required />
                                        </div>

                                        <div className="form-group">
                                            <label className="text-muted">Content 3</label>
                                            <CKEditor
                    data={unique_content3}                   
                    onBlur={e => handleChange1(e,'unique_content3')} />
                                            {/* <input onBlur={e => handleChange(e, 'unique_content3')} type="text" className="form-control" defaultValue={unique_content3} required /> */}
                                        </div>

                                        <label className="text-muted">Upload Image 3<span style={{color:'red'}}> *</span></label>
                                        <div className="form-group">
                                            <label className="btn btn-secondary">
                                                <input onChange={handleChangeImage('unique_image3')} type="file" name="unique_image3" accept="image/*" />
                                            </label>
                                        </div>

                                    </div>

                                    <div className="col-md-6">
                                        {(home.length >=1 && home[0].unique_image3) ? <><img src={"/uploads/"+unique_image3} width="50%" height="auto" className="img-fluid" alt="" /></> : <><img src={require('../theme/user/img/uploaded/no-preview.png')} width="50%" height="auto" className="img-fluid" alt="" /></>}                             
                                    </div>

                                </div>
                                
                            </Typography>

                        </AccordionDetails>
                    </Accordion> 


                    <Accordion>
                        <AccordionSummary
                         expandIcon={<ExpandMoreIcon />}
                         aria-controls="panel1a-content"
                         id="panel1a-header">
                             <Typography className={classes1.heading}><b style={{fontSize:'1rem'}}>How we change lives Section</b></Typography>

                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography>

                                <div className="row">

                                    <div className="col-md-6">

                                        <h5 style={{fontWeight:'bold'}}>How we change lives Section 1</h5>

                                        <div className="form-group">
                                            <label className="text-muted">Heading 1</label>
                                            <input onBlur={e => handleChange(e,'changeLives_heading1')} type="text" className="form-control" defaultValue={changeLives_heading1} required />
                                        </div>

                                        <div className="form-group">
                                            <label className="text-muted">Content 1</label>
                                            <CKEditor
                    data={changeLives_content1}                   
                    onBlur={e => handleChange1(e,'changeLives_content1')} />
                                            {/* <input onBlur={e => handleChange(e, 'changeLives_content1')} type="text" className="form-control" defaultValue={changeLives_content1} required /> */}
                                        </div>

                                        <label className="text-muted">Upload Image 1<span style={{color:'red'}}> *</span></label>
                                        <div className="form-group">
                                            <label className="btn btn-secondary">
                                                <input onChange={handleChangeImage('changeLives_image1')} type="file" name="changeLives_image1" accept="image/*" />
                                            </label>
                                        </div>

                                    </div>

                                    <div className="col-md-6">
                                        {(home.length >=1 && home[0].changeLives_image1) ? <><img src={"/uploads/"+changeLives_image1} width="50%" height="auto" className="img-fluid" alt="" /></> : <><img src={require('../theme/user/img/uploaded/no-preview.png')} width="50%" height="auto" className="img-fluid" alt="" /></>}                                    
                                    </div>

                                    <div className="col-md-6">

                                        <h5 style={{fontWeight:'bold'}}>How we change lives Section 2</h5>

                                        <div className="form-group">
                                            <label className="text-muted">Heading 2</label>
                                            <input onBlur={e => handleChange(e,'changeLives_heading2')} type="text" className="form-control" defaultValue={changeLives_heading2} required />
                                        </div>

                                        <div className="form-group">
                                            <label className="text-muted">Content 2</label>
                                            <CKEditor
                    data={changeLives_content2}                   
                    onBlur={e => handleChange1(e,'changeLives_content2')} />
                                            {/* <input onBlur={e => handleChange(e, 'changeLives_content2')} type="text" className="form-control" defaultValue={changeLives_content2} required /> */}
                                        </div>

                                        <label className="text-muted">Upload Image 2<span style={{color:'red'}}> *</span></label>
                                        <div className="form-group">
                                            <label className="btn btn-secondary">
                                                <input onChange={handleChangeImage('changeLives_image2')} type="file" name="changeLives1_image2" accept="image/*" />
                                            </label>
                                        </div>

                                    </div>

                                    <div className="col-md-6">
                                        {(home.length >=1 && home[0].changeLives_image2) ? <><img src={"/uploads/"+changeLives_image2} width="50%" height="auto" className="img-fluid" alt="" /></> : <><img src={require('../theme/user/img/uploaded/no-preview.png')} width="50%" height="auto" className="img-fluid" alt="" /></>}                       
                                    </div>

                                    <div className="col-md-6">

                                        <h5 style={{fontWeight:'bold'}}>How we change lives Section 3</h5>

                                        <div className="form-group">
                                            <label className="text-muted">Heading 3</label>
                                            <input onBlur={e => handleChange(e,'changeLives_heading3')} type="text" className="form-control" defaultValue={changeLives_heading3} required />
                                        </div>

                                        <div className="form-group">
                                            <label className="text-muted">Content 3</label>
                                            <CKEditor
                    data={changeLives_content3}                   
                    onBlur={e => handleChange1(e,'changeLives_content3')} />
                                            {/* <input onBlur={e => handleChange(e, 'changeLives_content3')} type="text" className="form-control" defaultValue={changeLives_content3} required /> */}
                                        </div>

                                        <label className="text-muted">Upload Image 3<span style={{color:'red'}}> *</span></label>
                                        <div className="form-group">
                                            <label className="btn btn-secondary">
                                                <input onChange={handleChangeImage('changeLives_image3')} type="file" name="changeLives1_image3" accept="image/*" />
                                            </label>
                                        </div>

                                    </div>

                                    <div className="col-md-6">
                                        {(home.length >=1 && home[0].changeLives_image3) ? <><img src={"/uploads/"+changeLives_image3} width="50%" height="auto" className="img-fluid" alt="" /></> : <><img src={require('../theme/user/img/uploaded/no-preview.png')} width="50%" height="auto" className="img-fluid" alt="" /></>}              
                                    </div>

                                </div>
                                
                            </Typography>

                        </AccordionDetails>
                    </Accordion> 
                </div>             
              
            </form>
        )
    }

    const clickSubmit = (event) => {

        event.preventDefault();
        setBtnloading(true);

        createHomePage(user._id,token,{title}).then(data => {
            if(data.error){
                setValues({...values, error: data.error})
                setOpenSnack(true);
            }
            else {
                loadHome()
                setBtnloading(false)
                //setValues({...values, success: true})
            }
        })

    }

    const handleChangeforTitle = name => event => {
        setOpenSnack(false);
        setValues({ ...values, [name]: event.target.value });
    } 
    const EditorPreview = (data) => {
        return (
            <div className="editor-preview">
                <h2>Rendered content</h2>
                <div dangerouslySetInnerHTML={ { __html: data } }></div>
            </div>
        );
    }
    const showTitle = () => {
        return (
            <form onSubmit={clickSubmit}>

                <div className="col-md-12">

                    <div className="form-group">
                        <label className="text-muted">Title</label>
                        <input onChange={handleChangeforTitle('title')} type="text" className="form-control" value={title} required />
                    </div>

                </div>
                <button className="btn btn-outline-primary" disabled={btnloading}>{btnloading ? "Loading..." : "create"}</button>

            </form>
        )
    }

    /* const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    ); */

    const showSuccess = () => {
        return <div className="alert alert-success" style={{ display: success ? '' : 'none' }}>
            Successfully Added
        </div>
    };

    
    return (
        <AdminLayout className="container" topTitle="HomePage Settings">

            <div className="row">
                    
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title" style={{textAlign:'center'}}>HomePage Settings</h4>
                            {(spinloading) ? <div className='loader-container'><div className='loader'></div></div> : ""}
                            {showSuccess()}
                            {/* {showError()} */}
                            {(!spinloading) && (home.length >=1   ? showForm() : showTitle())}
                            

                            <Backdrop className={classes.backdrop} open={open} >
                                <CircularProgress color="inherit" />
                            </Backdrop>  

                            <div className={classesSnack.root}>
                                
                                <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}>
                                            
                                    <Alert onClose={handleClose} severity="error">{error}</Alert>
                                                                                
                                </Snackbar>
                            </div> 
                                       
                        </div>
                    </div>
                </div>
           </div>
        </AdminLayout>
    )
   
}

export default AddHomePage
