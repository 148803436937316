import React, {useState, useEffect} from 'react'
import {getAllProducts} from './Priorities/apiUser'
import ShowImage from '../../admin/ShowImage'
import Subscribe from './Subscribe'
import {Tabs,Tab } from 'react-bootstrap'; 
import NewLayout from './NewLayout'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const ProductListing = () => {

    const classes = useStyles();

    const [loading, setLoading] = useState(true);

    const [products, setProducts] = useState([]);

    const [safeproducts, setSafeProducts] = useState([]);

    const [secproducts, setSecProducts] = useState([]);

    const [wellproducts, setWellProducts] = useState([]);

    const [values, setValues] = useState({
      btnloading:true
    });

    const {btnloading} = values

    const initAll = ids => {
      getAllProducts(ids).then(data => {
        if (data.error) {    
            console.log(data.error);
            setValues({...values, btnloading:false});
            setLoading(false);
        } else {
            // populate the state
            setProducts(data);
            setValues({...values, btnloading:false});   
            setLoading(false);       
        }
      });
    }; 

    const initSafe = ids => {
      getAllProducts(ids).then(data => {
        if (data.error) {          
            console.log(data.error);
            setLoading(false);             
        } else {
            // populate the state
            setSafeProducts(data);          
        }
      });
    };  

    const initSecurity = ids => {
      getAllProducts(ids).then(data => {
        if (data.error) {         
            console.log(data.error);
            setLoading(false);  
        } else {
            // populate the state
            setSecProducts(data);          
        }
      });
    };  

    const initWellness = ids => {
      getAllProducts(ids).then(data => {
        if (data.error) {         
            console.log(data.error);
            setLoading(false);  
        } else {
            // populate the state
            setWellProducts(data);          
        }
      });
    };  

    useEffect(() => {
      initAll(showAllProducts(1));
      initSafe(showAllProducts(2));
      initSecurity(showAllProducts(3));
      initWellness(showAllProducts(4)); 
    }, [])

    const showAllProducts = (Id) => {
      let list = [];
      if(Id === 1) // All
      {
        list=["5f773c5c541f443e5ce64aaa","5f9d650ff268b98214e737bf","5f7b2d5bffa2800728e971b5","5f7b2f0bffa2800728e971bf","5f7b2ec3ffa2800728e971bd","5f7b2f74ffa2800728e971c1","5f7b2e6fffa2800728e971bb","5f7b2dc6ffa2800728e971b7","5f7b302bffa2800728e971c5","5f7b2e1effa2800728e971b9","5f7b2fc3ffa2800728e971c3","5f9d6592f268b98214e737c1"]
        //list = ["5f7b2ec3ffa2800728e971bd","5f7b2f74ffa2800728e971c1","5f7b2d5bffa2800728e971b5","5f7b2e1effa2800728e971b9","5f7b2e6fffa2800728e971bb","5f7b302bffa2800728e971c5","5f7b2f0bffa2800728e971bf","5f7b2dc6ffa2800728e971b7","5f773c5c541f443e5ce64aaa","5f9d650ff268b98214e737bf","5f7b2fc3ffa2800728e971c3","5f9d6592f268b98214e737c1"];
      } 
      else if(Id === 2) // Safety
      {
        list = ["5f7b2f0bffa2800728e971bf","5f7b2ec3ffa2800728e971bd","5f7b2dc6ffa2800728e971b7","5f7b2e6fffa2800728e971bb","5f7b2f74ffa2800728e971c1","5f7b2e1effa2800728e971b9","5f7b2fc3ffa2800728e971c3","5f7b302bffa2800728e971c5"];
      }
      else if(Id === 3) // Security
      {
        list = ["5f9d650ff268b98214e737bf","5f7b2d5bffa2800728e971b5","5f7b2f0bffa2800728e971bf","5f7b2ec3ffa2800728e971bd","5f7b2fc3ffa2800728e971c3"];
      }
      else if(Id === 4) // Wellness
      {
        list = ["5f7b2f0bffa2800728e971bf","5f7b2ec3ffa2800728e971bd","5f7b2dc6ffa2800728e971b7","5f7b2e1effa2800728e971b9","5f7b2fc3ffa2800728e971c3"];
      }
      return list;

    }

    const productList = () => {
      return (
    
              <div className="row">
                
                     {products.map((p,i) => (

                        <div className="col-md-6 mb-2-btm pl-0 plr-0px book">
                            <div className="col-md-12 box-bord-gr info">
                                 <div className="row pt-8-per-but">

                                   <div className="col-md-12 py-5 px-5">
                                       <a className="btn btn-primary mr-2 mb-3" key={i} href={`/product-details/${p._id}`}>See Details</a>
                                     </div>
                                 </div>
                             </div>


                             <div className="col-md-12 box-bord-gr image">
                                 <div className="row">
                                     <div className="col-md-5"> <ShowImage key={i} item={p._id} /> </div>
                                     <div className="col-md-7 py-5 px-5 text-mini-mbile">
                                         <h3 className="font-hd-on" key={i}>{p.name}</h3>
                                         <p className="inner-line" key={i}>{p.description}</p>
                                     </div>
                                 </div>
                             </div>
                         </div>
                                            
                     ))}
                                                                           
                  </div>
       
      )
    }

    const safetyList = () => {
      return (
        
          <div className="row">
                    
                    {safeproducts.map((p,i) => (
    
                       <div className="col-md-6 mb-2-btm pl-0 plr-0px book">
                           <div className="col-md-12 box-bord-gr info">
                                <div className="row pt-8-per-but">
    
                                  <div className="col-md-12 py-5 px-5">
                                      <a className="btn btn-primary mr-2 mb-3 sd" href={`/product-details/${p._id}`}>See Details</a>
                                    </div>
                                </div>
                            </div>
    
    
                            <div className="col-md-12 box-bord-gr image">
                                <div className="row">
                                    <div className="col-md-5"> <ShowImage key={i} item={p._id} /> </div>
                                    <div className="col-md-7 py-5 px-5 text-mini-mbile">
                                        <h3 className="font-hd-on" key={i}>{p.name}</h3>
                                        <p className="inner-line" key={i}>{p.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                                           
                    ))}
                                      
                                       
                 </div>
     
        )
      }

      const securityList = () => {
        return (
          
          <div className="row">
                
                     {secproducts.map((p,i) => (

                        <div className="col-md-6 mb-2-btm pl-0 plr-0px book">
                            <div className="col-md-12 box-bord-gr info">
                                 <div className="row pt-8-per-but">

                                   <div className="col-md-12 py-5 px-5">
                                       <a className="btn btn-primary mr-2 mb-3" key={i} href={`/product-details/${p._id}`}>See Details</a>
                                     </div>
                                 </div>
                             </div>


                             <div className="col-md-12 box-bord-gr image">
                                 <div className="row">
                                     <div className="col-md-5"> <ShowImage key={i} item={p._id} /> </div>
                                     <div className="col-md-7 py-5 px-5 text-mini-mbile">
                                         <h3 className="font-hd-on" key={i}>{p.name}</h3>
                                         <p className="inner-line" key={i}>{p.description}</p>
                                     </div>
                                 </div>
                             </div>
                         </div>
                                            
                     ))}
                                       
                                        
                  </div>
          )
        }
      
        
      const wellnessList = () => {
        return (

          <div className="row">
                
                     {wellproducts.map((p,i) => (

                        <div className="col-md-6 mb-2-btm pl-0 plr-0px book">
                            <div className="col-md-12 box-bord-gr info">
                                 <div className="row pt-8-per-but">

                                   <div className="col-md-12 py-5 px-5">
                                       <a className="btn btn-primary mr-2 mb-3" key={i} href={`/product-details/${p._id}`}>See Details</a>
                                     </div>
                                 </div>
                             </div>


                             <div className="col-md-12 box-bord-gr image">
                                 <div className="row">
                                     <div className="col-md-5"> <ShowImage key={i} item={p._id} /> </div>
                                     <div className="col-md-7 py-5 px-5 text-mini-mbile">
                                         <h3 className="font-hd-on" key={i}>{p.name}</h3>
                                         <p className="inner-line" key={i}>{p.description}</p>
                                     </div>
                                 </div>
                             </div>
                         </div>
                                            
                     ))}
                                       
                                        
                  </div>
          )
        }

    return (
                  <NewLayout topTitle="Our Products | Best Home Safety System | SafelyTeam" metaDescription="Our high-quality products are intelligently designed to integrate with one another to provide a fully connected smart home that keeps your family safe and healthy 24/7/365. With leading remote monitoring technologies stay always connected.">
               
                        <div className="section pp-scrollable slide-dark slide-dark-footer">
                        
                            <div className="col-md-12 px-0 inner-tp-pad inner-safely">
                                <div className="container">
                                    <div className="col-md-10 inner-header">
                                        <h1 className="white-inner-txt">Our Products </h1>

                                    </div>
                                </div>
                                <img src={require('./img/prd-bg.webp')} className="img-fluid" alt="Customer Image | Home Security System Companies" />
                                {(btnloading) ? <div className='loader-container'><div className='loader'></div></div> : ""}  
                            </div>
                            <div className="col-md-12 inner-safely mb-5">
                                <div className="container ptb-inner">

                                    <div className="col-md-12 px-0">
                                        <div className="row">

                                        <div className="col-md-2 px-0 padd-tp-srt"><button className="btn pad-lt-srt"> Filter Products</button></div>
                                        <Tabs defaultActiveKey="showall" className="show-all-btm" id="uncontrolled-tab-example">
                                        <Tab eventKey="showall" title="Show All">
                                        
                                          {productList()}
                                          </Tab>
                                          <Tab eventKey="safety" title="Safety">
                                          {safetyList()}
                                          </Tab>
                                          <Tab eventKey="security" title="Security">
                                          {securityList()}
                                          </Tab>
                                          <Tab eventKey="wellness" title="Wellness">
                                          {wellnessList()}
                                          </Tab>
                                          
                                        </Tabs>

                                          
                                        </div>
                                    </div>

                                   

                                </div>
                            </div>

                            <Backdrop className={classes.backdrop} open={loading} >
                              <CircularProgress color="inherit" />
                          </Backdrop>  
                            
                            <Subscribe />

                        </div>
                    </NewLayout>
    )
}

export default ProductListing
