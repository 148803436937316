import React, {Fragment, useState, useEffect} from 'react'
import Nav from './Nav'
import Footer from '../Footer'
import { isAuthenticated } from '../../../auth/index'
import { getCart } from './cartHelpers'
import { addOrder,checkCoupon, checknewUserCoupon,addUserOrder, getShippingByUserId,getSubscriptions,addOrderforAdPack } from './apiUser'
import Subscribe from '../Subscribe'
import { STRIPE_PUBLIC, STRIPE_PUBLIC_1,API,TESTING_USER } from "../../../config";
import { loadStripe } from '@stripe/stripe-js';
import {login,authenticate} from '../../../auth/index'
import queryString from "query-string";
import {Helmet} from 'react-helmet'
import {Link} from 'react-router-dom'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

const Shipping = () => {

    let stripePromise = loadStripe(STRIPE_PUBLIC);

    const { user,token } = isAuthenticated();
    const [items, setItems] = useState([])
    const [address, setAddress] = useState([])

    if(isAuthenticated()){

        if(user._id === TESTING_USER){
            stripePromise = loadStripe(STRIPE_PUBLIC_1);
        }
    }
    
    const query = queryString.stringify({value:'shippingdetails'});

    const [openSpin, setOpenSpin] = useState(true)

    const [check, setCheck] = useState(false) 
    const [hide, setHide] = useState(false) 
    const [btnloading1, setbtnloading1] = useState(false);

    const [values2, setValues2] = useState({
        gold_price:'',
        silver_price:'',
        bronze_price:'',
        error2:''
    })

    const { gold_price, silver_price, bronze_price, error2} = values2;

    let [coupon_amnt,setCouponAmnt] = useState(0);
    let [coupon_percentage, setCouponPercentage] = useState(0);
    let [coupon_id, setCouponId] = useState('');
    let [coupon_type,setCouponType] = useState(0);

    const [coupon, setCoupon] = useState('');
    const [error_coupon, setErrorCoupon] = useState('');
    const [success_coupon, setSuccesCoupon] = useState(false);

    const [values, setValues] = useState({
        first_name: '',
        last_name: '',
        email: '',
        confirm_email:'',
        phone:'',
        password: '',
        pin_code: '',
        house_no: '',
        area: '',
        city: '',
        state: '',
        country: '',
        userid: '',
        title: '',
        discount: '',
        quantity: '',
        price: '',
        temp_user: '',
        subscription_type: '',
        shipping_charge: '',
        error: '',
        packageName:'',
        packageprice:0,
        packagehead:'',
        temp_productid: '',
        temp_price: '',
        temp_quantity: '',
        temp_name:'',
        pack_quantity:'',
        pre1:'',
        pre2:'',
        check_terms:false,
        check_email:false,
        pre_config:'',
        recentAdd:'0',
        prefix:'',
        safety:'',
        security:'',
        wellness:'',
        btnloading:false,
        success: false
    })

    const {
        first_name,
        last_name,
        email,
        confirm_email,
        phone,
        password,
        pin_code,
        house_no,
        area,
        city,
        state,
        country,
        userid,
        title,
        discount,
        quantity,
        price,
        temp_user,
        subscription_type,
        shipping_charge,
        packageName,
        packageprice,
        packagehead,
        temp_user1,
        temp_productid,
        temp_price,
        temp_quantity,
        temp_name,
        pack_quantity,
        pre1,
        pre2,
        pre_config,
        recentAdd="0",
        prefix,
        error,
        btnloading,
        safety,
        security,
        wellness,
        check_terms=false,
        check_email=false,
        success } = values

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };


    const getTotal = () => {
        return items.reduce((currentValue, nextValue) => {
            return currentValue + nextValue.count * nextValue.sale_price;
        }, 0);
    };
     

    const showSummary = (items) => {
        return (
            <div className="col-md-12 bg-summa p-4">
            <h2 className="font-gen font-we-650 color-blk-in pt-0 pb-3 mb-0">Order Summary</h2>

            <div className="row font-we-650">
                <div className="col-md-9 font-one-edit color-blk-in" >
                {packageprice > 0 && <span>{pack_quantity} x {packagehead}</span>} <br></br><span dangerouslySetInnerHTML={{__html: packageName}}></span>            
                </div>
                <div className="col-md-3 font-one-edit color-blk-in">
                 {packageprice > 0 &&  <span>£{packageprice}</span>}
                </div>            
                             
                        {items.map((product,i) => (
                             <div className="row font-we-650 col-md-12 py-3 text-dark-gr clearfix">
                            <div className="col-md-10 font-one-edit color-blk-in">  {product.count} x {product.name}</div> 
                      
                        <div className="col-md-2 font-one-edit color-blk-in">£{product.sale_price*product.count}</div>
                       </div>
                       ))}
                                   
            </div>
            
            <div className="row font-we-650 py-3 text-dark-gr">
                <div className="col-md-9 font-one-edit text-dark-gr">
                    {(subscription_type===1)?
                        <span>  Gold subscription </span> :
                        (subscription_type===0)?
                        <span>  Silver subscription  </span> :
                        (subscription_type===2)?
                        <span>  Bronze subscription  </span>:""
                    }
                </div>
                <div className="col-md-3 font-one-edit text-dark-gr">
                    {(subscription_type===1)?
                        <span>  £{gold_price} / mo </span> :
                        (subscription_type===0)?
                        <span>  £{silver_price} / mo </span>:
                        (subscription_type===2)?
                        <span>  £{bronze_price} / mo </span>:
                        ""
                    }      
                </div>
            </div>
            {/* <div className="row font-we-650 text-dark-gr">
                <div className="col-md-9 font-one-edit text-dark-gr">
                    Shipping
                </div>
                <div className="col-md-3 font-one-edit text-dark-gr">
                    Free
                </div>
            </div> */}
            {/* <div className="row py-3 font-we-650">
                <div className="col-md-9 font-one-edit dis-bl-text font-we-650">
                    Total
                </div>
                <div className="col-md-3 font-one-edit dis-bl-text font-we-650">
                £{packageprice>0 ? (getTotal()+Number(packageprice)) : (getTotal())}
                </div>
            </div> */}
            
            {(coupon_percentage !== 0) || (coupon_amnt !==0) ? (

                <div className="row py-3 font-we-600">
                    <div className="col-md-9 font-one-edit dis-bl-text font-we-600">
                        Discount (Applied Coupon - {coupon})
                    </div>
                    <div className="col-md-3 font-one-edit dis-bl-text font-we-600">
                    £ {packageprice >0 ?(coupon_amnt !==0 ? (coupon_amnt).toFixed(2) : ((getTotal() +Number(packageprice))*coupon_percentage /100).toFixed(2)) : (coupon_amnt !==0 ? (coupon_amnt).toFixed(2) : ((getTotal())*coupon_percentage/100).toFixed(2))}
                    </div>
                </div> 

            ) : ''}
            

            {((coupon_percentage !== 0) || (coupon_amnt !==0)) ? (
            <div className="row font-we-650">
                    <div className="col-md-9 font-one-edit-in color-blk-in">
                        Order Total<br/>
                    
                    </div>
                    <div className="col-md-3 font-one-edit-in color-blk-in">
                    
                        £{packageprice > 0 ? (coupon_amnt !==0 ? ((getTotal() +Number(packageprice))-coupon_amnt).toFixed(2) :(coupon_percentage !==0 && ((getTotal() +Number(packageprice)) - (getTotal() +Number(packageprice))*coupon_percentage /100).toFixed(2) )) : (coupon_amnt !==0 ? ( getTotal()-coupon_amnt).toFixed(2) : (coupon_percentage !==0 && (getTotal()-(getTotal())*coupon_percentage/100).toFixed(2)))}
                
                    </div>
                </div>)
            :(

                <div className="row font-we-650">
                    <div className="col-md-9 font-one-edit-in color-blk-in">
                        Order Total<br/>
                    
                    </div>
                    <div className="col-md-3 font-one-edit-in color-blk-in">
                    
                        £{packageprice > 0 ? (getTotal() +Number(packageprice)).toFixed(2) : (getTotal()).toFixed(2)}
                
                    </div>
                </div>

            )}
        
            
            {(subscription_type===1)?
                  <div className="row"><em style={{textAlign:'right',width:'100%'}}> <hr/>(£{gold_price} / mo recurring payment starts on next month)</em></div> :
                 (subscription_type===0)?
                 <div className="row"><em style={{textAlign:'right',width:'100%'}}> <hr/>(£{silver_price} / mo recurring payment starts on next month)</em></div>:
                 (subscription_type===2)?
                 <div className="row"><em style={{textAlign:'right',width:'100%'}}> <hr/>(£{bronze_price} / mo recurring payment starts on next month)</em></div>:
                 ""
            }
                    <hr/>
                    <div className="row"><em style={{textAlign:'right',width:'100%'}}>Estimated delivery time is 3 working days, you will receive a tracking link once your order has been dispatched</em></div>
                    
        </div>
        )
    }

    const loadAddress = () => {
        getShippingByUserId(user._id).then(data => {
            if (data.error) {
               console.log(data.error)
               setOpenSpin(false);
            } else {
                // populate the state
                setAddress(data)
                setOpenSpin(false);
            }
        });
    };

    const handleAddressChange = (e, shippingId) => {
      
        /* recentAdd = shippingId;  */
        setValues({...values, error: false,btnloading:false,recentAdd:shippingId})
       
    }

    const showRecentAddress = () => {
        let lblid="radio";
        return (
            <div>
                 <h4 className="font-gen font-we-600 color-blk-in">Most recently used</h4> 
                {address.map((p,i) => (                   
                    <div className="col-md-10 px-0 mb-3">
                    <div className="col-md-12 bord-line">

                        <div className="row">
                            <div className="col-md-1 wid-5-per">
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input id={lblid+i} type="radio" value={recentAdd} onChange={e=> handleAddressChange(e,p._id)} className="form-check-input" name="optradio1" />
                                    </label>
                                </div>
                            </div>
                            <label for={lblid+i} className="col-md-11"> 
                                                                <div className="row">
                            <div className="col-md-8 py-3 wid-60-per-in">

                                <h5 className="pb-3 color-blk-in font-one-re">Deliver to this address</h5>
                                <p className="pb-0 mb-0">
                                {p.house_no}
                                {p.area !== undefined && <><br/><span>{p.area}</span></>}<br/>
                                {p.city}{p.state !== undefined && <span>-{p.state}</span>}<br/>
                                {p.country}<br/>
                                {p.pin_code}<br/>
                                Phone no: {p.phone}

                                </p>
                            </div>
                            <div className="col-md-3 pt-3 text-right">                             
                            </div>
                            </div>
                            </label>
                          
                        </div>
                    </div>
                </div>
                ))}
                    
            </div>
        )
    }

    const handleChangeCoupon = (e) => {
        setErrorCoupon('')
        setCoupon(e.target.value)
    };

    const clickSubmitCoupon = (e) => {
        e.preventDefault();
        setbtnloading1(true);
        setErrorCoupon('');
        setSuccesCoupon(false);
        if(isAuthenticated())
        {

            checkCoupon(user._id,token,{coupon})
            .then(data => {
                if(data.error) {
                    setErrorCoupon(data.error);
                    setbtnloading1(false);
                } else {
                    setErrorCoupon("");
                    setSuccesCoupon(true);
                  
                    var couponType = data.value_type;
                    if(couponType === 1)//  By amount
                    { 
                        setCouponId(data._id);                     
                        setCouponType(data.value_type);
                        setCouponPercentage(0);
                        setCouponAmnt(data.coupon_value);                     
                    }
                    else if(couponType === 2) // By Percentage
                    {
                        setCouponId(data._id);                   
                        setCouponType(data.value_type);
                        setCouponPercentage(data.coupon_value);
                        setCouponAmnt(0);
                    }
                    setbtnloading1(false);
                }
            });

        }
        else {
                    /* setErrorCoupon("Coupon Code is not used for first order!"); */
                    checknewUserCoupon({coupon})
                    .then(data => {
                        if(data.error) {
                            setErrorCoupon(data.error);
                            setbtnloading1(false);
                        } else {
                            setErrorCoupon("");
                            setSuccesCoupon(true);
                           
                            var couponType = data.value_type;
                            if(couponType === 1)//  By amount
                            { 
                                setCouponId(data._id);                            
                                setCouponType(data.value_type);
                                setCouponPercentage(0);
                                setCouponAmnt(data.coupon_value);                             
                            }
                            else if(couponType === 2) // By Percentage
                            {
                                setCouponId(data._id);                          
                                setCouponType(data.value_type);
                                setCouponPercentage(data.coupon_value);
                                setCouponAmnt(0);
                            }
                            setbtnloading1(false);
                        }
                    })         
        }

    };

    const [adID, setAdID] = useState("");

    useEffect(() => {
        setItems(getCart());
        loadSubscriptions();
        var tempEmail="";
        if(isAuthenticated()) {
            loadAddress();
        } 
        else {
            if(localStorage.getItem('_guest')){
                const guestData = JSON.parse(localStorage.getItem('_guest'))
                setValues({email:guestData.email});
                tempEmail = guestData.email;
                setOpenSpin(false);
            }
        }
        const quesData = JSON.parse(localStorage.getItem('ques'));
        const typeData = JSON.parse(localStorage.getItem('type'));
        const packQty = JSON.parse(localStorage.getItem('packageQnty'));
        const packData = JSON.parse(localStorage.getItem('package'));
        const adpackQty = JSON.parse(localStorage.getItem('adpackageQnty'));
        const adpackData = JSON.parse(localStorage.getItem('adpackage'));
        if(localStorage.getItem('package')){
            setValues({email:tempEmail,packageName:[packData.packageName]+""+temp_user,temp_name:packData.packagetempName,temp_price:packData.packagetempPrice, temp_quantity:packData.packagetempQuantity,temp_productid:packData.packageproductid,packageprice:[packData.packageTotprice*packQty.val],temp_user: quesData.temp_user,
            subscription_type: typeData.subscription_type,safety: quesData.safety,security: quesData.security,wellness:quesData.wellness,packagehead:[packData.packageHead],pack_quantity:packQty.val})
        }
        else if(localStorage.getItem('adpackage')){
            setValues({email:tempEmail,packageName:[adpackData.adpackageName]+"",temp_name:adpackData.adpackagetempName,temp_price:adpackData.adpackagetempPrice, temp_quantity:adpackData.adpackagetempQuantity,temp_productid:adpackData.adpackageproductid,packageprice:[adpackData.adpackageTotprice*adpackQty.val],
            subscription_type: typeData.subscription_type,packagehead:[adpackData.adpackageHead],pack_quantity:adpackQty.val})
            
            const adsData = JSON.parse(localStorage.getItem('ads'));

            if(localStorage.getItem('ads')){
                setAdID(adsData.adID)
            }
        }
        else
        {
            setValues({              
                subscription_type: -1
            })
            //check cart is empty         
            if(localStorage.getItem('cart')){
                const cartData = JSON.parse(localStorage.getItem('cart'));
                if(cartData.length>0){}
                else{
                    window.location.href="/revieworder/";
               }     
            } 
            else{
                window.location.href="/revieworder/";
           }  
        }    
    }, [])

    const loadSubscriptions = () => {
        getSubscriptions().then(data => {
            if(data.error) {
                setValues2({...values2,error2:data.error})
                setOpenSpin(false);
            } else {
                var tempGold=0;
                var tempSilver=0;
                var tempBronze=0;
                for(var i=0;i<data.length;i++){
                    if(data[i].plan === "Gold"){
                        tempGold = data[i].price;
                    }
                    else if(data[i].plan === "Silver"){
                        tempSilver = data[i].price;
                    }
                    else if(data[i].plan === "Bronze"){
                        tempBronze = data[i].price;
                    }
                }
                setValues2({
                ...values2,
                gold_price:tempGold,
                silver_price:tempSilver,
                bronze_price:tempBronze,
                error2:''  
                })      
                setTimeout(() => setOpenSpin(false),3000)   
            }
        }) 
    }

    const handleChange = name => event => {
        if(event.target.value === "Custom"){
            setHide(true);
        }
        if(name === 'pre1') {
            setValues({ ...values, error: false,btnloading:false, [name]: 0, pre_config:0 })
        } else if(name === 'pre2') {
            setValues({ ...values, error: false,btnloading:false, [name]: 1, pre_config:1 })
        } else if(name === "check_terms"){
            setValues({...values, error: false, btnloading:false, [name]: event.target.checked})
        } else if(name === "check_email"){
            setValues({...values, error: false, btnloading:false, [name]: event.target.checked})
        }else {
            setValues({ ...values, error: false,btnloading:false, [name]: event.target.value});
        }    
    };
    

    const localHandlePaymentforNewUser = async (orderid,packageHead,preConfig,subscription_type,final_price,email,password) => {
        
        login({email,password})
        .then(data1 =>{
            if(data1.error) {
                 console.log("error",data1.error);
            } else {
                authenticate(data1,() => {})
                localStorage.setItem('checkValues',JSON.stringify({order:orderid, preconfig:preConfig, sub_type:subscription_type, title:packageHead, totPrice:final_price, adID1:adID}))
                if(Number(subscription_type) === 1 || Number(subscription_type) === 0 || Number(subscription_type) === 2){
                    localStorage.setItem('planValues',JSON.stringify({gPrice:gold_price, sPrice:silver_price, bPrice:bronze_price}))
                }
                return window.location.href="/checkout";
            }
        })  
    }

    const localHandlePaymentforExisting = async (orderid,packageHead,preConfig,subscription_type,final_price) => {
        
        localStorage.setItem('checkValues',JSON.stringify({order:orderid, preconfig:preConfig, sub_type:subscription_type, title:packageHead, totPrice:final_price,adID1:adID}))
        if(Number(subscription_type) === 1 || Number(subscription_type) === 0 || Number(subscription_type) === 2){
            localStorage.setItem('planValues',JSON.stringify({gPrice:gold_price, sPrice:silver_price, bPrice:bronze_price}))
        }
        return window.location.href="/checkout";
          
    }


    const clickSubmit = (event) => {

        event.preventDefault();
        setValues({...values, error: false,btnloading: true });
        setOpen(true);

        let tempDiscount=0;
        let final_price=0;

        if(packageprice>0)
        {
            final_price=(getTotal()+Number(packageprice));
        }
        else
        {
            final_price=(getTotal());
        }
        
        if(coupon_amnt !==0 || coupon_percentage !==0){
            if(coupon_amnt !==0){
                final_price = final_price-coupon_amnt;
                tempDiscount = coupon_amnt;
            }
            else if(coupon_percentage !==0 ){
                var tempValue1=0;
                tempValue1=(final_price)*(coupon_percentage/100);
                final_price = (final_price)-tempValue1;
                tempDiscount = tempValue1;
            
            }
        }

        let ProdDescription;
        let ProdTitle;
        let Prodid;
        let Prodprice;
        let Prodquan;
        let Prodname;
        let systemType="";
       
        if(items.length>0 &&  packagehead !== undefined){
            Prodquan = items.map((p,i) => (p.count))+","+temp_quantity;
            ProdTitle=items.map((p,i) =>(p.count)+" x "+(p.name).split(','))+"," +pack_quantity+" x "+packagehead;
            ProdDescription =items.map((p,i) => (p.count)+" x "+(p.name).split(',') + "<br/>")+"" +packageName;
            Prodid = items.map((p,i) => (p._id).split(','))+","+temp_productid;
            Prodprice = items.map((p,i) => (p.sale_price))+","+temp_price;
            Prodname = items.map((p,i) => (p.name))+","+temp_name;
           
        } else if(items.length>0 && packagehead === undefined){
            Prodquan = items.map((p,i) => (p.count))+",";
            ProdTitle=items.map((p,i) =>(p.count)+" x "+(p.name).split(','));
            ProdDescription =items.map((p,i) => (p.count)+" x "+(p.name) + "<br/>");
            Prodid = items.map((p,i) => (p._id).split(','))+",";
            Prodprice = items.map((p,i) => (p.sale_price))+",";
            Prodname = items.map((p,i) => (p.name))+",";
        } 
         else {
            ProdTitle=items.map((p,i) => (p.name).split(','))+" " +packagehead;
            ProdDescription =items.map((p,i) => (p.name).split(','))+"" +packageName;
            Prodid = temp_productid;
            Prodprice = temp_price;
            Prodquan = temp_quantity;
            Prodname = temp_name;
        }
       
        if(localStorage.getItem('ques')){

            if(safety && security && wellness) {
                systemType = "Safety, Security, Wellness";
            }
            else if(safety && !security && !wellness) {
                systemType = "Safety";
            }
            else if(safety && security && !wellness) {
                systemType = "Safety, Security";
            }
            else if(safety && !security && wellness) {
                systemType = "Safety, Wellness";
            }
            else if(!safety && security && !wellness) {
                systemType = "Security";
            }
            else if(!safety && security && wellness) {
                systemType = "Security, Wellness";
            }
            else if(!safety && !security && wellness) {
                systemType = "Wellness";
            }
        }

       
        
       
        if(isAuthenticated()) {

            if(adID === ""){

                addUserOrder({prefix,first_name, phone, pin_code,check_terms, userid: user._id ,couponid:coupon_id,couponType:coupon_type, house_no, area, city, state, country:"UK",discount:tempDiscount,Prodid, Prodquan,Prodname, Prodprice,system_type:systemType,order_description:`${ProdDescription}`, title:`${ProdTitle}` ,quantity:pack_quantity, temp_user, subscription_type, price: final_price, pre_config,recentAdd},user._id,token)
                .then(data => {
                    if(data.error) {
                        console.log(data.error)
                        setValues({...values, error: data.error, success:false,btnloading:false})
                        setOpen(false)
                    } else {
                        
                        //remove all local storage
                        localStorage.removeItem('cart');
                        localStorage.removeItem('ques');
                        localStorage.removeItem('type');
                        localStorage.removeItem('package');
                        localStorage.removeItem('packageQnty');
                        localStorage.removeItem('ads');
                        localStorage.removeItem('adpackage');
                        localStorage.removeItem('adpackageQnty'); 
                        //window.location.href="/success/" ;
                        setValues({
                            ...values,
                            prefix:'',
                            first_name: '',
                            last_name: '',
                            email: '',
                            phone:'',
                            password: '',
                            pin_code: '',
                            house_no: '',
                            area: '',
                            city: '',
                            state: '',
                            country: '',
                            userid: '',
                            title: '',
                            discount: '',
                            quantity: '',
                            price: '',
                            temp_user:'',              
                            shipping_charge: '',
                            error: '',
                            btnloading:true,
                            success: true
                        })
                        let json = JSON.parse(JSON.stringify(data));
                        let order_id = json[0].orderid;
                        {localHandlePaymentforExisting(order_id,ProdTitle,pre_config,subscription_type,final_price)}
                    }
                })

            }
            else {
                addUserOrder({prefix,first_name, phone, pin_code,check_terms, userid: user._id ,couponid:coupon_id,couponType:coupon_type, house_no, area, city, state, country:"UK",discount:tempDiscount,Prodid, Prodquan,Prodname, Prodprice,system_type:systemType,order_description:`${ProdDescription}`, title:`${ProdTitle}` ,quantity:pack_quantity, temp_user, subscription_type, price: final_price, pre_config,recentAdd,adId:adID},user._id,token)
                .then(data => {
                    if(data.error) {
                        console.log(data.error)
                        setValues({...values, error: data.error, success:false,btnloading:false})
                        setOpen(false);
                    } else {
                        
                        //remove all local storage
                        localStorage.removeItem('cart');
                        localStorage.removeItem('ques');
                        localStorage.removeItem('type');
                        localStorage.removeItem('package');
                        localStorage.removeItem('packageQnty');
                        localStorage.removeItem('ads');
                        localStorage.removeItem('adpackage');
                        localStorage.removeItem('adpackageQnty'); 
                       
                        setValues({
                            ...values,
                            prefix:'',
                            first_name: '',
                            last_name: '',
                            email: '',
                            phone:'',
                            password: '',
                            pin_code: '',
                            house_no: '',
                            area: '',
                            city: '',
                            state: '',
                            country: '',
                            userid: '',
                            title: '',
                            discount: '',
                            quantity: '',
                            price: '',
                            temp_user:'',              
                            shipping_charge: '',
                            error: '',
                            btnloading:true,
                            success: true
                        })
                        let json = JSON.parse(JSON.stringify(data));
                        let order_id = json[0].orderid;
                        {localHandlePaymentforExisting(order_id,ProdTitle,pre_config,subscription_type,final_price)}
                    }
                })
            }
  
        } else {

            if(adID === ""){

                addOrder({prefix,first_name, last_name, email,confirm_email,password,couponid:coupon_id,couponType:coupon_type,check_terms,check_email, phone, pin_code, house_no, area, city, state, country:"UK",discount:tempDiscount, Prodid, Prodquan, Prodname,system_type:systemType, Prodprice,order_description:`${ProdDescription}`, title:`${ProdTitle}` ,quantity:pack_quantity, temp_user,subscription_type, price: final_price, pre_config, recentAdd })
                .then(data => {
                    if(data.error) {
                        setValues({...values, error: data.error, success:false,btnloading:false})
                        setOpen(false);
                    } else {
                       
                        //remove all local storage          
                        localStorage.removeItem('cart');
                        localStorage.removeItem('ques');
                        localStorage.removeItem('type');
                        localStorage.removeItem('package');
                        localStorage.removeItem('packageQnty');
                        localStorage.removeItem('ads');
                        localStorage.removeItem('adpackage');
                        localStorage.removeItem('adpackageQnty');
                        setValues({
                            ...values,
                            prefix:'',
                            first_name: '',
                            last_name: '',
                            phone:'',
                            pin_code: '',
                            house_no: '',
                            area: '',
                            city: '',
                            state: '',
                            country: '',
                            userid: '',
                            title: '',
                            discount: '',
                            quantity: '',
                            price: '',
                            temp_user: '',               
                            shipping_charge: '',
                            error: '',
                            btnloading:true,
                            success: true
                        })
                        let json = JSON.parse(JSON.stringify(data));
                        let order_id = json[0].orderid;
                        {localHandlePaymentforNewUser(order_id,ProdTitle,pre_config,subscription_type,final_price,email,password)}
                    }
                })    

            }
            else {

                addOrderforAdPack({prefix,first_name, last_name, email,confirm_email,password,couponid:coupon_id,couponType:coupon_type,check_terms,check_email, phone, pin_code, house_no, area, city, state, country:"UK",discount:tempDiscount, Prodid, Prodquan, Prodname,system_type:systemType, Prodprice,order_description:`${ProdDescription}`, title:`${ProdTitle}` ,quantity:pack_quantity, temp_user,subscription_type, price: final_price, pre_config, recentAdd,adId:adID })
                .then(data => {
                    if(data.error) {
                        setValues({...values, error: data.error, success:false,btnloading:false})
                        setOpen(false);
                    } else {
                        
                        //remove all local storage          
                        localStorage.removeItem('cart');
                        localStorage.removeItem('ques');
                        localStorage.removeItem('type');
                        localStorage.removeItem('package');
                        localStorage.removeItem('packageQnty');
                        localStorage.removeItem('ads');
                        localStorage.removeItem('adpackage');
                        localStorage.removeItem('adpackageQnty');
                        setValues({
                            ...values,
                            prefix:'',
                            first_name: '',
                            last_name: '',         
                            phone:'',
                            pin_code: '',
                            house_no: '',
                            area: '',
                            city: '',
                            state: '',
                            country: '',
                            userid: '',
                            title: '',
                            discount: '',
                            quantity: '',
                            price: '',
                            temp_user: '',               
                            shipping_charge: '',
                            error: '',
                            btnloading:true,
                            success: true
                        })
                        let json = JSON.parse(JSON.stringify(data));
                        let order_id = json[0].orderid;
                        {localHandlePaymentforNewUser(order_id,ProdTitle,pre_config,subscription_type,final_price,email,password)}
                    }
                })
              
            }       
        }         
    }



    const newAddressForm = () => {
        return (
           
                 <div className="col-md-10 px-0 pt-4">
                                                <h4 className="font-gen font-we-600 color-blk-in">Add New Address</h4>
                                                <div className="col-md-12 px-0">
                                                    <div className="form-group">
                                                    <label className="text-muted">Title<span style={{color:"red"}}> *</span></label>
                                                        <select required
                                                            onChange={handleChange('prefix')} 
                                                            className="form-control">
                                                                <option >Select Title</option>
                                                                <option value="Mr">Mr</option>
                                                                <option value="Mrs">Mrs</option>
                                                                <option value="Ms">Ms</option>
                                                                <option value="Miss">Miss</option>
                                                                <option value="Dr">Dr</option>
                                                                <option value="Sir">Sir</option>
                                                                <option value="Custom">Custom</option>
                                                        </select>
                                                        <input type="text" style={{display: hide ? 'block' :'none'}}  onChange={handleChange('prefix')} className="form-control border-rad-0-px" id="usr"  placeholder="Enter the Custom Pronoun" />
                                                    </div>

                                                    <div className="form-group">
                                                        {!isAuthenticated() ? (
                                                           <label for="usr">First Name <span style={{color:"red"}}> *</span></label>
                                                        ) : (
                                                            <label for="usr">Name <span style={{color:"red"}}> *</span></label>
                                                        )}     
                                                        <input type="text" onChange={handleChange('first_name')} className="form-control border-rad-0-px" id="usr" value={first_name}  />
                                                    </div>
                                                  
                                                    {!isAuthenticated() && (
                                                    <div className="form-group">      
                                                        <label for="usr">Last Name <span style={{color:"red"}}> *</span></label>
                                                        <input type="text" onChange={handleChange('last_name')} className="form-control border-rad-0-px" id="usr" value={last_name}  />
                                                    </div>
                                                    )}

                                                    {!isAuthenticated() && (
                                                    <div className="form-group">
                                                        <label for="usr">Email Address <span style={{color:"red"}}> *</span></label>
                                                        <input onChange={handleChange('email')} type="text" className="form-control border-rad-0-px" id="usr" value={email} />
                                                    <em style={{fontSize:'12px'}}>*The email ID you enter here will be used for future login to the site.</em>
                                                    </div>
                                                    )}
                                                    {!isAuthenticated() && (
                                                    <div className="form-group">
                                                        <label for="usr">Confirm Email Address <span style={{color:"red"}}> *</span></label>
                                                        <input onChange={handleChange('confirm_email')} type="text" className="form-control border-rad-0-px" id="usr" value={confirm_email} />
                                                    {/* <em style={{fontSize:'12px'}}>*The email ID you enter here will be used for future login to the site.</em> */}
                                                    </div>
                                                    )}

                                                    <div className="form-group">
                                                        <label for="usr">Phone Number<span style={{color:"red"}}> *</span></label>
                                                        <input onChange={handleChange('phone')} type="text" className="form-control border-rad-0-px" id="usr" value={phone} placeholder="e.g 07123456789" />
                                                    </div>

                                                    <div className="form-group">
                                                        <label for="usr">Address Line 1<span style={{color:"red"}}> *</span></label>
                                                        <input  onChange={handleChange('house_no')} type="text" className="form-control border-rad-0-px" id="usr" value={house_no} />
                                                    </div>
                                                    
                                                    <div className="form-group">
                                                        <label for="usr">Address Line 2(Optional)</label>
                                                        <input onChange={handleChange('area')} type="text" className="form-control border-rad-0-px" id="usr" value={area} />
                                                    </div>

                                                    <div className="form-group">
                                                        <label for="usr">Town/City<span style={{color:"red"}}> *</span></label>
                                                        <input onChange={handleChange('city')} type="text" className="form-control border-rad-0-px" id="usr" value={city} />
                                                    </div>

                                                    <div className="form-group">
                                                        <label for="usr">County(Optional)</label>
                                                        <input onChange={handleChange('state')} type="text" className="form-control border-rad-0-px" id="usr" value={state} />
                                                    </div>

                                                    
                                                    <div className="form-group">
                                                        <label for="usr">Postal Code<span style={{color:"red"}}> *</span></label>
                                                        <input onChange={handleChange('pin_code')} type="text" className="form-control border-rad-0-px" id="usr" value={pin_code} />
                                                    </div>                  
                                                    
                                                    
                                                    <div className="form-group">
                                                        <label for="usr">Country</label>
                                                        <input onChange={handleChange('country')} type="text" className="form-control border-rad-0-px" id="usr" value="UK" readOnly />
                                                    </div>
                                                    
                                                    {!isAuthenticated() && (
                                                    <div className="form-group">
                                                        <label for="usr">Password <span style={{color:"red"}}> *</span></label>
                                                        <input onChange={handleChange('password')} type="password" className="form-control border-rad-0-px" id="usr" value={password} />
                                                        <em style={{fontSize:'11px'}}>*Remember the password. This will be your secret code to enter into the site</em>
                                                    </div>
                                                    )}                                                              

                                                </div>
                                            </div>
           
        )
    }
    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error === "11000 duplicate key error collection: SafelyTeam.users index: email already exists" ? (
                <span>Sorry! This Email already registered with us, please <a href="/login">click here to login</a> to continue or try with new email id</span>
            ) : 
            error === "11000 duplicate key error collection: SafelyTeam.users index: phone already exists" ? (
                <span>Sorry! Phone Number already exists.</span>
            ) : 
            (
                error
            )}
        </div>
    );


    const showSuccess = () => (
       
        <div className="alert alert-success" style={{ display: success ? '' : 'none' }}>
            Your order has been successfully placed.  
        </div>
    );

    const showSuccessCoupon = () => {
        if(success_coupon) {
            return <div className="alert alert-success" style={{ display: success_coupon ? '' : 'none' }}>Your coupon code applied.</div>
        }
    };

    const showErrorCoupon = () => {
        if(error_coupon) {
            return <div className="alert alert-danger" style={{ display: error_coupon ? '' : 'none' }}>{error_coupon}</div>
        }
    };




    return (
        <Fragment style={{overflowY: 'scroll', overflowX: 'hidden'}}>
             <Helmet>
                <title>Safely Team - Checkout​</title>
            </Helmet>
            <Nav />
            

                    <div className="section pp-scrollable slide-dark slide-dark-footer">
                    

                        <div className="col-md-12 top-det-sp-in plr-0px">
                            <div className="col-md-12 pt-5 text-center">
                                <h3>Shipping Details</h3>
                                {/* {showSuccess()} */}
                                
                            </div>
                            <div className="col-md-12 plr-0px">
                                <div className="container plr-0px">
                                    <div className="row px-5 pt-5 qty-text pb-2">
                                        <div className="col-md-6 plr-0px">
                                        
                                        { address.length > 0 &&  showRecentAddress() } 
                                        
                                        { isAuthenticated() && (<div><br/><h5 style={{color:'black',display: check ? 'none' : ''}}>Enter New Address here</h5><button onClick={() => setCheck(true)} style={{ display: check ? 'none' : '' }} className="btn btn-success" data-toggle="modal" data-target="#send-request">Add New Address</button></div>)}
                                        
                                        {/* <button onClick={() => setCheck(false)} style={{ display: check ? '' : 'none' }} className="btn btn-success pull-right mb-3" data-toggle="modal" data-target="#send-request">Hide</button> */}
                                               
                                                { !isAuthenticated() && (<div className='alert alert-warning'><h4>Already a registered customer, please <a href={`/login?${query}`}>click here to login</a></h4></div>)}
                                                
                                                
                                                { (check && isAuthenticated()) && newAddressForm()} {!isAuthenticated() && newAddressForm()}<br/>
                                                

                                        </div>
                                        <div className="col-md-6 plr-0px">                          

                                        {showSummary(items)}
                                       
                                            <div className="col-md-12 pt-5 pr-0">

                                                <label className="form-check-label">Pre-Configure Your Products<span style={{color:"red"}}> *</span></label>
                                                    <div className="form-check">                                                 
                                                        <label className="form-check-label">
                                                            <input type="radio" value={pre1} defaultChecked={pre1} onChange={handleChange('pre1')}  className="form-check-input" name="optradio" /> <h5>Now</h5>
                                                        </label>

                                                    </div>

                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="radio" value={pre2} defaultChecked={pre2} onChange={handleChange('pre2')}  className="form-check-input" name="optradio" /> <h5>Later</h5>
                                                        </label>
                                                    </div> 
                                                    <em style={{fontSize:'11px'}}>Pre-configuration involves assigning room names to your devices. This ensures that once your system arrives, it can be installed in less than 30 minutes. </em><br/><br/>

                                                    <em style={{fontSize:'11px'}}>You must complete pre-configuration before we can dispatch your system. You can either pre-configure after checkout, or complete later. </em><br/><br/>

                                                   
                                                        <hr/>
                                                       
                                                        <div className="col-md-12 p-0">
                                                        <div className="row">
                                                        <div className="col-md-8">
                                                        <div className="form-group">
                                                        {/* <label for="usr">Coupon</label> */}
                                                        <input onChange={handleChangeCoupon} type="text" className="form-control" id="usr" value={coupon} placeholder="Enter Your Coupon Code Here" required />
                                                    </div>
                                                    </div>
                                                    <div className="col-md-4 p-0">
                                                    <button onClick={clickSubmitCoupon} disabled={btnloading1} className="btn btn-success mb-3">{btnloading1 ? "Loading..." : "Apply Coupon"}</button><br/><br/>
                                                    </div>
                                                    </div>
                                                    </div>
                                                   
                                                    {showErrorCoupon()}
                                                    {showSuccessCoupon()}
                                                        
                                                        <hr/>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input onChange={handleChange('check_terms')} type="checkbox" id="usr" checked={check_terms} value={check_terms}  /> <h5>Please click here to indicate your acceptance of the <Link to={`/terms`} style={{textDecoration:"underline"}} target="_blank">Safely Team Terms of Supply of Products and Service</Link>, which apply to your purchase and I understand that my personal data will be processed as set out in the <Link to={`/privacy`} style={{textDecoration:"underline"}} target="_blank">Safely Team Privacy Policy.</Link></h5>
                                                        </label>
                                                    </div>

                                                    {!isAuthenticated() && 
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input onChange={handleChange('check_email')} type="checkbox" id="usr" checked={check_email} value={check_email}  /> <h5>Please tick here if you would like us to contact you by email or text message with information about our products and services which we feel may be of interest to you.</h5>
                                                                </label>
                                                            </div>
                                                        } 

                                                <button onClick={clickSubmit}  disabled={btnloading} className="btn btn-success pull-right mb-3" data-toggle="modal" data-target="#send-request">{btnloading ? "Loading..." : "Proceed to checkout"}</button>
                                                {showError()}
                                                <Backdrop className={classes.backdrop} open={open} >
                                                    <CircularProgress color="inherit" />
                                                </Backdrop>
                                                <Backdrop className={classes.backdrop} open={openSpin} >
                                                    <CircularProgress color="inherit" />
                                                </Backdrop>
                                            </div>
                                        </div>
                                       

                                    </div>
                                </div>
                            </div>
                          
                        </div>
                        <Subscribe />
                        <Footer />
                        </div>
                   

               
            
        </Fragment>
    )
}

export default Shipping
