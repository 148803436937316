import React, {useState} from 'react'
import { resetPassword } from './apiUser'
import Header from '../theme/user/Header'
import Footer from '../theme/user/Footer'
import {Helmet} from 'react-helmet'

const ForgotPassword = () => {

    const [email, setEmail] = useState('')
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const handleChange = (e) => {
        setError('')
        setEmail(e.target.value)
    };

    const clickSubmit = (e) => {
        e.preventDefault();
        setError('');
        setSuccess(false);

        resetPassword({email})
        .then(data => {
            if(data.error) {
                setError(true);
            } else {
                setEmail('');
                setError("");
                setSuccess(true);
            }
        });
    };

    const newForm = () => (

        <div className="row ptb-inner-login">
         
        <div className="col-md-6 center-whole">
     
        <form onSubmit={clickSubmit}>
            <div className="form-group">
                <label className="text-muted">Enter Email:<span style={{color:"red"}}> *</span></label>
                <input type="text" className="form-control" onChange={handleChange} value={email} autoFocus required/>
                
            </div>
            <button className="btn btn-outline-primary">
                     Submit
            </button>
        </form>
        </div>
       
        </div>
        
    );

    const showSuccess = () => (
        <div className="alert alert-success" style={{ display: success ? '' : 'none' }}>
          <h4>SafelyTeam sent a password reset link to your email. Click the link and reset a password. If you don't see the email, check your spam folder.
          </h4>  
        </div>
    )

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {email} doesnot exist.
        </div>
    )
 
    return (
        <div>
             <Helmet>
            <title>SafelyTeam – Forgot Your Password​​​</title>
          </Helmet>
            <Header />
            <section> 
          <div className="col-md-12 px-0 pt-5">
    <div className="col-md-12 px-0 inner-tp-pad ">
                       
    <div className="container abt-text-top-container pt-5">
    <div className="col-md-12 py-5">
    <h1 className="text-center pt-5 blue-acti">Forgot Your Password? </h1>
            <p className="login-rt-gyap">To reset your password, please enter your registered Email.</p>
            <p className="login-rt-gyap">We will send the password reset instructions to the email address for this account.
</p>
        

                                            {newForm()}
                                            {showSuccess()}
                                            {showError()}
                                    </div>
                                </div>
                            </div>
                          
                        </div>
                        </section> 
                        <Footer />
                    </div> 
             
    )
}

export default ForgotPassword
