import React,{useState,useEffect,Fragment} from 'react'
import {Helmet} from 'react-helmet'
import Nav from './Nav'
import Footer from '../Footer'
import Subscribe from '../Subscribe'
import { API } from "../../../config";
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import {isAuthenticated, authenticate} from '../../../auth/index'
import {updateCustomerId, createCardDetail, getActiveCardsById, activateUserSubscription, createOrderSubscription, getSubscriptionLogs} from './apiUser'
import { getUser } from './apiUser'
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));


const CheckoutCall = () => {

    const [loading, setLoading] = useState(true);
    const stripe = useStripe();
    const elements = useElements();
    const { user,token } = isAuthenticated();
    const classes = useStyles();
    const [error, setError] = useState("")
    const [open, setOpen] = useState(false);

    const [values,setValues] = useState({
        customerName:'',
        email:''
    })
    
    const {customerName,email} = values;

    const [subDays, setSubDays] = useState('');

    const [values1,setValues1] = useState({
        price:"",
        title:"",
        orderId:'',
        preConfig:'',
        subType:'',
        adId:''
    })

    const {title,price,preConfig,orderId,subType,adId} = values1;

    const [values3, setValues3] = useState({
        gold_price:'',
        silver_price:'',
        bronze_price:''
    })

    const { gold_price, silver_price, bronze_price} = values3;

    const [ values4, setValues4] = useState({
        silverPrice:'',
        goldPrice:'',
        bronzePrice:'',
        subPlan:'',
        isCancelled: false,
        isReview: false
    })

    const { silverPrice, goldPrice, subPlan, isCancelled, isReview, bronzePrice} = values4;

    const [cards, setCards] = useState([]);

    const [recentCard, setRecentCard] = useState("");

    const [userValue, setUserValue] = useState({
        subscription_paid:'',
    })

    const { subscription_paid} = userValue;

    const loadUser = () => {
        getUser(user._id,token).then(data => {
            if(data.error){
                console.log("Sub log error")
                setLoading(false);
            }
            else {
              
                setUserValue({
                    ...userValue,
                    subscription_paid:data.subscription_paid,
                })
                if(data.subscription_paid !== user.subscription_paid){
                    authenticate({token, user:{_id:user._id, prefix:user.prefix, name:user.name, lastname:user.lastname, email:user.email, role:user.role, customerid:user.customerid, subscriptionid:data.subscriptionid, subscription_paid:data.subscription_paid, isSubCancelled: data.isSubCancelled}},() => {})   
                }
                loadCards();
            }
        })
    }

    const loadCards = () => {
        getActiveCardsById({userId:user._id}).then(data => {
            if(data.error){
                console.log(data.error);
                setLoading(false);
            }
            else {
                setCards(data);
                loadSubLogs()
            }
        })
    }

    const loadSubLogs = () => {
        getSubscriptionLogs(user._id, token,{userId:user._id})
        .then(data => {
            if(data.error){
                console.log("Sub log error")
                setLoading(false);
            }
            else {              
                     
                var tempStartDate;
                var tempEndDate;
                var count = 0;
                var days = 0;
                var aa;
                var bb;

                for(var i=0;i<data.length;i++){
                    tempStartDate = moment(data[i].createdAt).format("DD/MM/YYYY");
                    tempEndDate = moment(data[i].updatedAt).format("DD/MM/YYYY");
                    aa = moment(tempStartDate, 'DD/MM/YYYY');
                    bb = moment(tempEndDate, 'DD/MM/YYYY');
                    days = bb.diff(aa, 'days'); 
                    count+=Number(days);
                    days=0;
                    tempStartDate="";
                    tempEndDate="";
                    aa=0;
                    bb=0;
                } 
                setSubDays(count);
                setLoading(false);
                
            }
        })
    }


    useEffect(() => {
        const tempData = JSON.parse(localStorage.getItem('checkValues'))
        localStorage.removeItem('_guest');
        if(localStorage.getItem('checkValues')){
            localStorage.removeItem('subValue');
            
            if(tempData.totPrice<=0)
            {
                setValues1({orderId:tempData.order,price:0.30,preConfig:tempData.preconfig,subType:tempData.sub_type,title:tempData.title, adId: tempData.adID})}
            else{
                setValues1({orderId:tempData.order,price:tempData.totPrice,preConfig:tempData.preconfig,subType:tempData.sub_type,title:tempData.title, adId: tempData.adID})
            }
            
            if(localStorage.getItem('planValues')){

                const tempPlanData = JSON.parse(localStorage.getItem('planValues'));
                setValues3({...values3, gold_price:tempPlanData.gPrice, silver_price:tempPlanData.sPrice, bronze_price:tempPlanData.bPrice})

            }
        }
        else if(localStorage.getItem('subValue')){

            const subData = JSON.parse(localStorage.getItem('subValue'))
            setValues4({...values4, subPlan: subData.plan, silverPrice: subData.silverP, goldPrice: subData.goldP, bronzePrice: subData.bronzeP, isCancelled: subData.isCancelled, isReview: subData.isReview})
            setValues1({subType:subData.plan})
        }
        else {
            window.location.href="/revieworder/";
        }

        if(isAuthenticated()){
            setValues({customerName:user.name+' '+user.lastname, email:user.email})
        }
        loadUser()
    },[])

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value});
    };


    const clickSubmit = async (event) => {

        event.preventDefault();
        setOpen(true);
        let customerId="";
        let paymentMethodId="";
        let secretId="";
        let charges;
        let card;
        var createExistSub;
        var createExistSub1;
        var createExistSub2;
        var createExistSub3;

        if(isCancelled && (Number(subPlan) === 0 || Number(subPlan) === 1 || Number(subPlan) === 2)){ // Activate Existing Subscription (only Subscription without products)
            
            customerId = user.customerid;
            var tempPrice;
            var description;

            if(Number(subPlan) === 0){
                tempPrice = silverPrice;
                description = "Silver Subscription Plan";
            }
            else if(Number(subPlan) === 1){
                tempPrice = goldPrice;
                description = "Gold Subscription Plan";
            }   
            else if(Number(subPlan) === 2){
                tempPrice = bronzePrice;
                description = "Bronze Subscription Plan";
            }   

            // Choosing Old card
            if(recentCard){ 

                paymentMethodId = recentCard;

                if(Number(subDays) <=30){
                    var tempDays = 30-Number(subDays);

                     createExistSub = await fetch(`${API}/activate/existing/subscription`, {
                        method:'POST',
                        headers: {'Content-Type': 'application/json',},
                        body: JSON.stringify({ 
                                customer: String(customerId),
                                payment_method:paymentMethodId,
                                subs_type: String(subPlan),
                                userid: String(user._id),
                                days: tempDays
                        })
                    });
                                           
                }
                else {

                     createExistSub = await fetch(`${API}/activate/new/subscription`, {
                        method:'POST',
                        headers: {'Content-Type': 'application/json',},
                        body: JSON.stringify({ 
                                customer: String(customerId),
                                payment_method:paymentMethodId,
                                subs_type: String(subPlan),
                                userid: String(user._id)
                        })
                    });
                        
                }

                const subData = await createExistSub.json();
                
                if(subData.id){
                    
                    let userUpdate = await activateUserSubscription(user._id, token, {userId:user._id, subPlan: subPlan, tempPrice:tempPrice, subId: subData.id});
                   

                    if(userUpdate.subscription_paid){

                            authenticate({token, user:{_id:user._id,prefix:user.prefix,name:user.name,lastname:user.lastname,email:user.email,role:user.role,customerid:customerId,subscriptionid:subData.id,subscription_paid:true, isSubCancelled: false}},() => {})    
                            localStorage.removeItem('subValue');
                            if(isReview){
                                return window.location.href="/revieworder";
                            }
                            else {
                                return window.location.href="/user/subscription/history";
                            }    
                    }
                        
                }                 

            }
            else {
                
                // Choosing New Card
               
                const createSetupIntent = await fetch(`${API}/card-wallet`, {
                method:'POST',
                headers: {'Content-Type': 'application/json',},
                    body: JSON.stringify({ 
                        name: customerName,
                        email: String(email), 
                        customer: String(customerId),
                        userid: String(user._id)
                    })
                });
                        
                const setupSession = await createSetupIntent.json(); 
            
                secretId=setupSession.client_secret;

                if (!stripe || !elements) {
                    // Stripe.js has not yet loaded.
                    // Make sure to disable form submission until Stripe.js has loaded.
                    return;
                }
    
                const result = await stripe.confirmCardSetup(String(secretId), {
                    payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: customerName,
                    },
                    }
                });
        
                let tempRes = result.setupIntent;

                if (result.error) {
                    setOpen(false);
                    setError(result.error.message);
                } else {
                    // The setup has succeeded. Display a success message and send
                    // result.setupIntent.payment_method to your server to save the
                    // card to a Customer'
                    paymentMethodId = tempRes.payment_method;
        
                    const getPaymentMethod = await fetch(`${API}/get/payment-method/id`, {
                        method:'POST',
                        headers: {'Content-Type': 'application/json',},
                        body: JSON.stringify({                    
                            payment_method:paymentMethodId,
                            userid: String(user._id)
                        })
                    });
    
                    const resultPM = await getPaymentMethod.json();
                  
                    card = resultPM.card
                   
                    if(resultPM){
                        const updateCard = await createCardDetail({
                            userId: user._id,
                            card_no: card.last4,
                            payment_method: paymentMethodId,
                            exp_year : card.exp_year,
                            exp_month: card.exp_month,
                            brand: card.brand
                        })
                       
                        if(updateCard){
                           
                            if(Number(subDays) <=30){
                                var tempDays1 = 30-Number(subDays);
                                
                                createExistSub1 = await fetch(`${API}/activate/existing/subscription`, {
                                    method:'POST',
                                    headers: {'Content-Type': 'application/json',},
                                    body: JSON.stringify({ 
                                            customer: String(customerId),
                                            payment_method:paymentMethodId,
                                            subs_type: String(subPlan),
                                            userid: String(user._id),
                                            days: tempDays1
                                    })
                                });

                            }
                            else {
                                createExistSub1 = await fetch(`${API}/activate/new/subscription`, {
                                    method:'POST',
                                    headers: {'Content-Type': 'application/json',},
                                    body: JSON.stringify({ 
                                            customer: String(customerId),
                                            payment_method:paymentMethodId,
                                            subs_type: String(subPlan),
                                            userid: String(user._id)
                                    })
                                });
                            }
                                                                 
                            const subData1 = await createExistSub1.json();

                            if(subData1.id){
                                  
                                let userUpdate = await activateUserSubscription(user._id, token, {userId:user._id, subPlan: subPlan, tempPrice:tempPrice, subId: subData1.id});

                                if(userUpdate.subscription_paid){
                                    
                                        authenticate({token, user:{_id:user._id,prefix:user.prefix,name:user.name,lastname:user.lastname,email:user.email,role:user.role,customerid:customerId,subscriptionid:subData1.id,subscription_paid:true, isSubCancelled: false}},() => {})    
                                        localStorage.removeItem('subValue');
                                        if(isReview){
                                            return window.location.href="/revieworder";
                                        }
                                        else {
                                            return window.location.href="/user/subscription/history";
                                        }                           
                                }
                                            
                            }     
                                        
                        }  
                    }
                    else {
                        setError("Something went wrong, Enter the correct card details")
                    }
            
                }

            }

        }
        else {
                // Existing User
            if(user.customerid){ 

                customerId = user.customerid;

                // Activating Existing Subscription Plan with Products
                if(!user.subscription_paid && (Number(subType) === 1 || Number(subType) === 0 || Number(subType) === 2)){

                    // choosing recent Card
                    if(recentCard){

                        paymentMethodId = recentCard;
                    
                        const createPayment = await fetch(`${API}/charge-payment-intent`, {
                            method:'POST',
                            headers: {'Content-Type': 'application/json',},
                            body: JSON.stringify({ 
                                name: customerName,
                                customer: String(customerId), 
                                price:price,
                                payment_method:paymentMethodId,
                                userid: String(user._id)
                            })
                        });
                        
                        const paymentSession = await createPayment.json(); 
                        
                        if(paymentSession.status === "succeeded"){

                            if(Number(subDays) <=30){
                                var tempDays = 30-Number(subDays);

                                createExistSub2 = await fetch(`${API}/activate/existing/subscription`, {
                                    method:'POST',
                                    headers: {'Content-Type': 'application/json',},
                                    body: JSON.stringify({ 
                                        customer: String(customerId),
                                        payment_method:paymentMethodId,
                                        subs_type: String(subType),
                                        userid: String(user._id),
                                        days: tempDays
                                    })
                                });

                            }
                            else {

                                createExistSub2 = await fetch(`${API}/activate/new/subscription`, {
                                    method:'POST',
                                    headers: {'Content-Type': 'application/json',},
                                    body: JSON.stringify({ 
                                        customer: String(customerId),
                                        payment_method:paymentMethodId,
                                        subs_type: String(subType),
                                        userid: String(user._id)
                                    })
                                });

                            }
                   
                            const setupData2 = await createExistSub2.json();

                            if(setupData2.id){
                          
                                authenticate({token, user:{_id:user._id,prefix:user.prefix,name:user.name,lastname:user.lastname,email:user.email,role:user.role,customerid:customerId,subscriptionid:setupData2.id,subscription_paid:true,isSubCancelled:false}},() => {})    
                                localStorage.removeItem('checkValues');
                                localStorage.removeItem('planValues');
                                return window.location.href=`/success?order=${orderId}`+`&preconfig=`+preConfig+`&sub_type=`+subType+`&paymentId=`+paymentSession.id+`&subscriptionId=`+setupData2.id+`&adId=`+adId;
                            }                 
                        }
                        else {
                            setError("Something went wrong, Enter the correct card details")
                        }

                    }
                    else {
                        
                        // Choosing New Card
                        const createSetupIntent = await fetch(`${API}/card-wallet`, {
                            method:'POST',
                            headers: {'Content-Type': 'application/json',},
                            body: JSON.stringify({ 
                                name: customerName,
                                email: String(email), 
                                customer: String(customerId),
                                userid: String(user._id)
                            })
                        });
                        
                        const setupSession = await createSetupIntent.json(); 
                      
                        secretId=setupSession.client_secret;
            
                        if (!stripe || !elements) {
                            // Stripe.js has not yet loaded.
                            // Make sure to disable form submission until Stripe.js has loaded.
                            return;
                        }
            
                        const result = await stripe.confirmCardSetup(String(secretId), {
                            payment_method: {
                            card: elements.getElement(CardElement),
                            billing_details: {
                                name: customerName,
                            },
                            }
                        });
                
                      
                        let tempRes = result.setupIntent;
            
                        if (result.error) {
                            setOpen(false);
                            setError(result.error.message);
                        } else {
                            // The setup has succeeded. Display a success message and send
                            // result.setupIntent.payment_method to your server to save the
                            // card to a Customer'
                            paymentMethodId = tempRes.payment_method;
                          
                            const createPayment = await fetch(`${API}/charge-payment-intent`, {
                                method:'POST',
                                headers: {'Content-Type': 'application/json',},
                                body: JSON.stringify({ 
                                    name: customerName,
                                    customer: String(customerId), 
                                    price:price,
                                    payment_method:paymentMethodId,
                                    userid: String(user._id)
                                })
                            });
                            
                            const paymentSession = await createPayment.json();
                            
                            charges = paymentSession.charges
                           
                            card = charges.data[0].payment_method_details.card
                          
                            if(paymentSession.status === "succeeded"){
                              
                                const updateCard = await createCardDetail({
                                    userId: user._id,
                                    card_no: card.last4,
                                    payment_method: paymentMethodId,
                                    exp_year : card.exp_year,
                                    exp_month: card.exp_month,
                                    orderId: orderId,
                                    brand: card.brand
                                    })
                
                                    if(updateCard){

                                        if(Number(subDays) <=30){

                                            var tempDays = 30-Number(subDays);
                                            createExistSub3 = await fetch(`${API}/activate/existing/subscription`, {
                                                method:'POST',
                                                headers: {'Content-Type': 'application/json',},
                                                body: JSON.stringify({ 
                                                    customer: String(customerId),
                                                    payment_method:paymentMethodId,
                                                    subs_type: String(subType),
                                                    userid: String(user._id),
                                                    days: tempDays
                                                })
                                            });
                                        }
                                        else {
                                            createExistSub3 = await fetch(`${API}/create/new/subscription`, {
                                                method:'POST',
                                                headers: {'Content-Type': 'application/json',},
                                                body: JSON.stringify({ 
                                                    customer: String(customerId),
                                                    payment_method:paymentMethodId,
                                                    subs_type: String(subType),
                                                    userid: String(user._id)
                                                })
                                            });
                                        }
                                        
                                        
                                        const subData3 = await createExistSub3.json();
                                    
                                        if(subData3.id){
                                                authenticate({token, user:{_id:user._id,prefix:user.prefix,name:user.name,lastname:user.lastname,email:user.email,role:user.role,customerid:user.customerid,subscriptionid:subData3.id,subscription_paid:true,isSubCancelled:false}},() => {})
                                              
                                                localStorage.removeItem('checkValues');
                                                localStorage.removeItem('planValues');
                                                return window.location.href=`/success?order=${orderId}`+`&preconfig=`+preConfig+`&sub_type=`+subType+`&paymentId=`+paymentSession.id+`&subscriptionId=`+subData3.id+`&adId=`+adId;
                                        }
                                        
                                    }  
                            }
                            else {
                                setError("Something went wrong, Enter the correct card details")
                            }
            
                        }
                    }

                }
                else if(user.subscription_paid && recentCard){  // Existing User with only Products and without Subscription // choosing Recent Card
                   
                    paymentMethodId = recentCard;
                  
                        const createPayment = await fetch(`${API}/charge-payment-intent`, {
                            method:'POST',
                            headers: {'Content-Type': 'application/json',},
                            body: JSON.stringify({ 
                                name: customerName,
                                customer: String(customerId), 
                                price:price,
                                payment_method:paymentMethodId,
                                userid: String(user._id)
                            })
                        });
                        
                        const paymentSession = await createPayment.json();
                       
                        if(paymentSession.status === "succeeded"){      
                            localStorage.removeItem('checkValues');
                            localStorage.removeItem('planValues');
                            return window.location.href=`/success?order=${orderId}`+`&preconfig=`+preConfig+`&sub_type=`+subType+`&paymentId=`+paymentSession.id+`&subscriptionId=""`+`&adId=`+adId;
                            //setOpen(false);                   
                        }
                        else {
                            setError("Something went wrong, Enter the correct card details")
                        }
                }
                else {

                    // Existing User with Only Products and without Subscription // choosing New Card

                    const createSetupIntent = await fetch(`${API}/card-wallet`, {
                        method:'POST',
                        headers: {'Content-Type': 'application/json',},
                        body: JSON.stringify({ 
                            name: customerName,
                            email: String(email), 
                            customer: String(customerId),
                            userid: String(user._id)
                        })
                    });
                    
                    const setupSession = await createSetupIntent.json(); 
        
                    secretId=setupSession.client_secret;
        
                    if (!stripe || !elements) {
                        // Stripe.js has not yet loaded.
                        // Make sure to disable form submission until Stripe.js has loaded.
                        return;
                    }
        
                    const result = await stripe.confirmCardSetup(String(secretId), {
                        payment_method: {
                        card: elements.getElement(CardElement),
                        billing_details: {
                            name: customerName,
                        },
                        }
                    });
            
                    let tempRes = result.setupIntent;
        
                    if (result.error) {
                        setOpen(false);
                        setError(result.error.message);
                    } else {
                        // The setup has succeeded. Display a success message and send
                        // result.setupIntent.payment_method to your server to save the
                        // card to a Customer'
                        paymentMethodId = tempRes.payment_method;
                      
                        const createPayment = await fetch(`${API}/charge-payment-intent`, {
                            method:'POST',
                            headers: {'Content-Type': 'application/json',},
                            body: JSON.stringify({ 
                                name: customerName,
                                customer: String(customerId), 
                                price:price,
                                payment_method:paymentMethodId,
                                userid: String(user._id)
                            })
                        });
                        
                        const paymentSession = await createPayment.json(); 
                        
                        charges = paymentSession.charges
                      
                        card = charges.data[0].payment_method_details.card
            
                        if(paymentSession.status === "succeeded"){
                           
                            const updateCard = await createCardDetail({
                                userId: user._id,
                                card_no: card.last4,
                                payment_method: paymentMethodId,
                                exp_year : card.exp_year,
                                exp_month: card.exp_month,
                                orderId: orderId,
                                brand: card.brand
                                })
            
                                if(updateCard){
                                   
                                    localStorage.removeItem('checkValues');
                                    localStorage.removeItem('planValues');
                                    return window.location.href=`/success?order=${orderId}`+`&preconfig=`+preConfig+`&sub_type=`+subType+`&paymentId=`+paymentSession.id+`&subscriptionId=""`+`&adId=`+adId;
                                    //setOpen(false);
                                }  
                        }
                        else {
                            setError("Something went wrong, Enter the correct card details")
                        }
        
                    }
                    
                }

            }
            else {

                // New User with Products and Subscriptions
                if(Number(subType) === 1 || Number(subType) === 0 || Number(subType) === 2){
                   
                    const createCustomer = await fetch(`${API}/create-customer`, {
                        method:'POST',
                        headers: {'Content-Type': 'application/json',},
                        body: JSON.stringify({ 
                            name: customerName,
                            email: String(email), 
                            userid: String(user._id)
                        })
                    });
                    
                    const customerSession = await createCustomer.json();
                   
                    customerId=customerSession.id;
        
                    const tempUser = await updateCustomerId(user._id, token, {userid:user._id, customerid:customerId});
        
                    if(tempUser.customerid)
                    {
        
                        const createSetupIntent = await fetch(`${API}/card-wallet`, {
                            method:'POST',
                            headers: {'Content-Type': 'application/json',},
                            body: JSON.stringify({ 
                                name: customerName,
                                email: String(email), 
                                customer: String(customerId),
                                userid: String(user._id)
                            })
                        });
                        
                        const setupSession = await createSetupIntent.json();
                        
                        secretId=setupSession.client_secret;
                    
                            if (!stripe || !elements) {
                                // Stripe.js has not yet loaded.
                                // Make sure to disable form submission until Stripe.js has loaded.
                                return;
                            }
                        
                            const result = await stripe.confirmCardSetup(String(secretId), {
                                payment_method: {
                                card: elements.getElement(CardElement),
                                billing_details: {
                                    name: customerName,
                                },
                                }
                            });
                    
                            let tempRes = result.setupIntent;
                            
                        
                            if (result.error) {
                                setOpen(false);
                                setError(result.error.message);
                            } else {
                            
                                paymentMethodId = tempRes.payment_method;
          
                                const createPayment = await fetch(`${API}/charge-payment-intent`, {
                                    method:'POST',
                                    headers: {'Content-Type': 'application/json',},
                                    body: JSON.stringify({ 
                                        name: customerName,
                                        customer: String(customerId), 
                                        price:price,
                                        payment_method:paymentMethodId,
                                        userid: String(user._id)
                                    })
                                });
                                
                                const paymentSession = await createPayment.json();  
                                
                                charges = paymentSession.charges
                               
                                card = charges.data[0].payment_method_details.card
            
                                if(paymentSession.status === "succeeded"){
                                
                                    const updateCard = await createCardDetail({
                                        userId: user._id,
                                        card_no: card.last4,
                                        payment_method: paymentMethodId,
                                        exp_year : card.exp_year,
                                        exp_month: card.exp_month,
                                        orderId: orderId,
                                        brand: card.brand
                                    })
                
                                    if(updateCard){
                                        
                                        const createSub = await fetch(`${API}/create/new/subscription`, {
                                            method:'POST',
                                            headers: {'Content-Type': 'application/json',},
                                            body: JSON.stringify({ 
                                                customer: String(customerId),
                                                payment_method:paymentMethodId,
                                                subs_type: String(subType),
                                                userid: String(user._id)
                                            })
                                        });
                                        
                                        const setupSub = await createSub.json();
                    
                                        if(setupSub.id){
                                            authenticate({token, user:{_id:user._id,prefix:user.prefix,name:user.name,lastname:user.lastname,email:user.email,role:user.role,customerid:customerId,subscriptionid:setupSub.id,subscription_paid:true, isSubCancelled: false}},() => {})
                                            localStorage.removeItem('checkValues');
                                            localStorage.removeItem('planValues');
                                            return window.location.href=`/success?order=${orderId}`+`&preconfig=`+preConfig+`&sub_type=`+subType+`&paymentId=`+paymentSession.id+`&subscriptionId=`+setupSub.id+`&adId=`+adId;
                                        }
            
                                    }  
                                }
                                else {
                                    setError("Something went wrong, Enter the correct card details")
                                }
                            
                            }
        
                    }        

                }
                else {
                    
                    // Rare Scenario New User without Subscription
                    const createCustomer = await fetch(`${API}/create-customer`, {
                        method:'POST',
                        headers: {'Content-Type': 'application/json',},
                        body: JSON.stringify({ 
                            name: customerName,
                            email: String(email), 
                            userid: String(user._id)
                        })
                    });
                    
                    const customerSession = await createCustomer.json();

                    customerId=customerSession.id;
        
                    const tempUser = await updateCustomerId(user._id, token, {userid:user._id, customerid:customerId});
                  
        
                    if(tempUser.customerid)
                    {
        
                        const createSetupIntent = await fetch(`${API}/card-wallet`, {
                            method:'POST',
                            headers: {'Content-Type': 'application/json',},
                            body: JSON.stringify({ 
                                name: customerName,
                                email: String(email), 
                                customer: String(customerId),
                                userid: String(user._id)
                            })
                        });
                        
                        const setupSession = await createSetupIntent.json();
                        
                        secretId=setupSession.client_secret;
                    
                            if (!stripe || !elements) {
                                // Stripe.js has not yet loaded.
                                // Make sure to disable form submission until Stripe.js has loaded.
                                return;
                            }
                        
                            const result = await stripe.confirmCardSetup(String(secretId), {
                                payment_method: {
                                card: elements.getElement(CardElement),
                                billing_details: {
                                    name: customerName,
                                },
                                }
                            });
                    
                            let tempRes = result.setupIntent;
                            
                        
                            if (result.error) {
                                setOpen(false);
                                setError(result.error.message);
                            } else {
                            
                                paymentMethodId = tempRes.payment_method;
                            
                                const createPayment = await fetch(`${API}/charge-payment-intent`, {
                                    method:'POST',
                                    headers: {'Content-Type': 'application/json',},
                                    body: JSON.stringify({ 
                                        name: customerName,
                                        customer: String(customerId), 
                                        price:price,
                                        payment_method:paymentMethodId,
                                        userid: String(user._id)
                                    })
                                });
                                
                                const paymentSession = await createPayment.json();
                              
                                charges = paymentSession.charges
                              
                                card = charges.data[0].payment_method_details.card
                              
            
                                if(paymentSession.status === "succeeded"){
                                  
                                    const updateCard = await createCardDetail({
                                        userId: user._id,
                                        card_no: card.last4,
                                        payment_method: paymentMethodId,
                                        exp_year : card.exp_year,
                                        exp_month: card.exp_month,
                                        orderId: orderId,
                                        brand: card.brand
                                    })
                
                                    if(updateCard){         
                                        authenticate({token, user:{_id:user._id,prefix:user.prefix,name:user.name,lastname:user.lastname,email:user.email,role:user.role,customerid:customerId,subscriptionid:user.subscriptionid,subscription_paid:user.subscription_paid}},() => {})                      
                                        localStorage.removeItem('checkValues');
                                        localStorage.removeItem('planValues');
                                        return window.location.href=`/success?order=${orderId}`+`&preconfig=`+preConfig+`&sub_type=`+subType+`&paymentId=`+paymentSession.id+`&subscriptionId=""`+`&adId=`+adId;                                 
                                    }  
                                }
                                else {
                                    setError("Something went wrong, Enter the correct card details")
                                }
                            
                            }
        
                    }
                    
                }
            }
        }
    }
    

    const showCheckout = () => {
        return (

            <form onSubmit={clickSubmit}>
                 
                {isAuthenticated() && (user.customerid ? '' :

                <div className="form-group">
                    <label className="text-muted">Name<span style={{color:"red"}}> *</span></label>
                    <input onChange={handleChange('customerName')} type="text" className="form-control" value={customerName} required/>
                </div>
                    
                )}

                {isAuthenticated() && (user.customerid ? '' :
                <div className="form-group">
                    <label className="text-muted">Email<span style={{color:"red"}}> *</span></label>
                    <input onChange={handleChange('email')} type="email" className="form-control" value={email} required/>
                </div>
                    
                )}
    
                <CardSection />
                <br/>
                <button className="btn btn-outline-primary" disabled={!stripe}>Proceed to Payment</button>    
                <br/>
                <br/>                     
            </form>
        )
    }


    const handleCardChange = (e ,paymentMethod) => {
        setRecentCard(paymentMethod)
    }

    const showRecentCards = () => {
        let lblid="radio";
        return (
            <div>
                <h4 className="font-gen font-we-600 color-blk-in">Saved Cards</h4> 
                {cards.map((p,i) => (                   
                    <div className="col-md-10 px-0 mb-3">
                    <div className="col-md-12 bord-line">

                        <div className="row">
                            <div className="col-md-1 wid-5-per">
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input id={lblid+i} type="radio" value={recentCard} onChange={e=> handleCardChange(e,p.payment_method)} className="form-check-input" name="optradio1" />
                                    </label>
                                </div>
                            </div>
                            <label for={lblid+i} className="col-md-11"> 
                                                                <div className="row">
                            <div className="col-md-8 py-3 wid-60-per-in">

                                <h5 className="pb-3 color-blk-in font-one-re">Use this Card</h5>
                                <p className="pb-0 mb-0">
                                <b>Card No:</b>&nbsp;xxxx xxxx xxxx {p.card_no}<br/>
                                <b>Exp month/year:</b>&nbsp;{p.exp_month} / {p.exp_year}<br/>
                                <b>Card Type:</b>&nbsp;{p.brand}<br/>
                                </p>
                            </div>
                            <div className="col-md-3 pt-3 text-right">                             
                            </div>
                            </div>
                            </label>
                          
                        </div>
                    </div>
                </div>
                ))}
            </div>
        )
    }

    const showSub = () => {
        return (

            <div className="col-md-12 bg-summa p-4">
                <h2 className="font-gen font-we-650 color-blk-in pt-0 pb-3 mb-0">Order Summary</h2>
                <div className="row py-3 font-we-600">
                   
                </div> 

                <div className="row font-we-650 py-3 text-dark-gr">
                <div className="col-md-9 font-one-edit text-dark-gr">
                    {(Number(subType)===1)?
                        <span>  Gold subscription </span> :
                        (Number(subType)===0)?
                        <span>  Silver subscription  </span>:
                        (Number(subType)===2)?
                        <span>  Bronze subscription  </span>:""
                    }
                </div>
                <div className="col-md-3 font-one-edit text-dark-gr">
                    {(Number(subType)===1)?
                    <span>  £{goldPrice} / mo </span> :
                    (Number(subType)===0)?
                    <span>  £{silverPrice} / mo </span>:
                    (Number(subType)===2)?
                    <span>  £{bronzePrice} / mo </span>:
                    ""
                }
                  
                </div>
            </div>
            <hr/>

                <div className="row font-we-650">
                    <div className="col-md-6 font-one-edit-in color-blk-in">
                        Order Total<br/>
                    
                    </div>
                    <div className="col-md-6 font-one-edit-in color-blk-in">
                    
                        {(Number(subType)===1)?
                            <span>  £{goldPrice} / mo </span> :
                            (Number(subType)===0)?
                            <span>  £{silverPrice} / mo </span>:
                            (Number(subType)===2)?
                            <span>  £{bronzePrice} / mo </span>:
                            ""
                        }
                
                    </div>
                </div>
                

            </div>

        )
    }

    const showProducts = () => {
        return (
            <div className="col-md-12 bg-summa p-4">
                <h2 className="font-gen font-we-650 color-blk-in pt-0 pb-3 mb-0">Order Summary</h2>
                <div className="row py-3 font-we-600">
                    <div className="col-md-6 font-one-edit dis-bl-text font-we-600">
                       Product:
                    </div>
                    <div className="col-md-6 font-one-edit dis-bl-text font-we-600">
                        {title}
                    </div>
                </div> 

                <div className="row font-we-650 py-3 text-dark-gr">
                <div className="col-md-9 font-one-edit text-dark-gr">
                    {(Number(subType)===1)?
                        <span>  Gold subscription </span> :
                        (Number(subType)===0)?
                        <span>  Silver subscription  </span>:
                        (Number(subType)===2)?
                        <span>  Bronze subscription  </span>:""
                    }
                </div>
               
                <div className="col-md-3 font-one-edit text-dark-gr">
                    {(Number(subType)===1)?
                        <span>  £{gold_price} / mo </span> :
                        (Number(subType)===0)?
                        <span>  £{silver_price} / mo </span>:
                        (Number(subType)===2)?
                        <span>  £{bronze_price} / mo </span>:
                        ""
                    }
                  
                </div>
            </div>
            <hr/>

                <div className="row font-we-650">
                    <div className="col-md-6 font-one-edit-in color-blk-in">
                        Order Total<br/>
                    
                    </div>
                    <div className="col-md-6 font-one-edit-in color-blk-in">
                    {(Number(price)===0.30)?
                   <span>£ 0</span> :
                   <span>   £ {Number(price).toFixed(2)}</span>
                    }
                
                    </div>
                </div>

                {(subType===1)?
                  <div className="row"><em style={{textAlign:'right',width:'100%'}}> <hr/>(£{gold_price} / mo recurring payment starts on next month)</em></div> :
                 (subType===0)?
                 <div className="row"><em style={{textAlign:'right',width:'100%'}}> <hr/>(£{silver_price} / mo recurring payment starts on next month)</em></div>:
                 (subType===2)?
                 <div className="row"><em style={{textAlign:'right',width:'100%'}}> <hr/>(£{bronze_price} / mo recurring payment starts on next month)</em></div>:
                 ""
                }
            </div>
        )
    }

    const showError = () => {
        if(error) {
            return <h3 className="text-danger">{error}</h3>
        }
    };

    return (

        <Fragment style={{overflowY: 'scroll', overflowX: 'hidden'}}>
            <Helmet>
                <title>Safely Team - Checkout​</title>
            </Helmet>
            <Nav />

            <div className="section pp-scrollable slide-dark slide-dark-footer">
                <div className="col-md-12 top-det-sp-in plr-0px">
                    <div className="col-md-12 pt-5 text-center">
                        <h3>Checkout</h3>                                      
                    </div>
                    {(loading) ? <div className='loader-container'><div className='loader'></div></div> : ""}  
                    <div className="col-md-12 plr-0px">
                        <div className="container plr-0px">
                            <div className="row px-5 pt-5 qty-text pb-2">

                                <div className="col-md-6 plr-0px">
                                   {cards.length >0 && showRecentCards()}

                                    {isAuthenticated() && (user.customerid ? <><br/><h5 className="font-gen font-we-600 color-blk-in">Add New Card</h5></> : <h5 className="font-gen font-we-600 color-blk-in">Enter Card Details</h5>)}
                                    {!loading && showCheckout()}
                                    {showError()}
                                </div>

                                <div className="col-md-6 plr-0px">
                                   
                                    {isCancelled ? showSub() : showProducts()}
                                   
                                </div>

                            </div>
                        
                        </div>
                    </div>

                    <Backdrop className={classes.backdrop} open={open} >
                        <CircularProgress color="inherit" />
                    </Backdrop>           
                </div>
                <Subscribe />
                <Footer />
            </div>
        </Fragment> 
                        
    )
}

export default CheckoutCall
