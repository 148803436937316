import React,{Fragment} from 'react'
import Header from './Header'
import Footer from './Footer'
import Subscribe from './Subscribe'
import {Helmet} from 'react-helmet'

const Blog1 = () => {
    return (
        <Fragment>
             <Helmet>
              <title>Safely – Blog</title>
            </Helmet>
            <Header />

            
    <section>
   
      <div className="col-md-12 px-0 inner-tp-pad inner-safely">
        <div className="container">
          <div className="col-md-10 inner-header-blog blog-head-top mt-5">
            <h2 className="white-inner-txt">Safely – Blog</h2>
            <div className="row">

            </div>
           
          </div>
        </div>
        <img src={require('./img/blog-img.png')} className="img-fluid" alt=""/> </div>
      <div className="col-md-12 inner-safely">
        <div className="container">
         
         <div className="col-md-12">
          <div className="row ptb-inner">
          <div className="col-md-12">
            <center>
          <div className="alert alert-primary">
            <br/><br/>
              <h3>Coming Soon!</h3>
              <h4>We are currently working on this feature and will launch soon!</h4>
              <br/><br/>
            </div>
            </center>
              </div></div></div>
          <div className="row">
           
            {/* <div className="col-md-6 mb-5">
              <div className="col-md-12 plr-0px">
                <a href="/blog/home-safety-in-times-of-covid-19"><img src={require('./img/covid.png')} className="img-fluid" alt=""/></a>

              </div>
            <div className="col-md-12 py-4 plr-0px">
              
              <h4><a href="/blog/home-safety-in-times-of-covid-19">Home Safety In Times Of Covid-19</a></h4>
            </div>
            <div className="col-md-12">
              <div className="row">
              <div className="col-md-2"><img src={require('./img/author8.png')} className="img-fluid" alt=""/></div>
              <div className="col-md-9 px-0 pt-3">Daniel Wainwright<br/>Nov 12 . 10 min read</div>
            </div>
            </div>
            </div>
             <div className="col-md-6 mb-5">
              <div className="col-md-12 plr-0px">
                <a href="/blog/are-home-security-devices-worth-your-pick"><img src={require('./img/covid-1.png')} className="img-fluid" alt=""/></a>

              </div>
            <div className="col-md-12 py-4 plr-0px">
              
              <h4><a href="/blog/are-home-security-devices-worth-your-pick">Are Home-Security Devices Worth Your Pick?</a></h4>
            </div>
            <div className="col-md-12">
              <div className="row">
              <div className="col-md-2"><img src={require('./img/philip.png')} className="img-fluid" alt=""/></div>
              <div className="col-md-9 px-0 pt-3">Philip Simon<br/>Nov 10 . 12 min read</div>
            </div>
            </div>
            </div>
             */}
          </div>
          
          
          

          
          


        </div>
      </div>
   


      <Subscribe />

                        </section>
                        <Footer />
                   
 
            
        </Fragment>
    )
}

export default Blog1
