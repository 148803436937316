import React from 'react'
import Subscribe from './Subscribe'
import NewLayout from './NewLayout'

const Sitemap = () => {
    return (
        <NewLayout topTitle="Sitemap | SafelyTean" metaDescription="">

            <div className="section pp-scrollable slide-dark slide-dark-footer"> 

                <div className="col-md-12 px-0 inner-tp-pad inner-safely">
                    <div className="container">
                        <div className="col-md-10 inner-header">
                          <h1 className="white-inner-txt abt-hd-res">Sitemap </h1>
                          
                        </div>
                    </div>
                    <img src={require('./img/faq-img.png')} className="img-fluid" alt="Sitemap"/> 
                </div>

                <div className="col-md-12 inner-safely">
                    <div className="container">

                        <div className="row ptb-inner">

                            <div className="col-md-12 px-0">

                                <div className="row">

                                    <div className="col-md-4 wow fadeInUp" data-wow-delay="0.4s">
                                        <h4>Products</h4>
                                        <ul>
                                            <li> <a href="/product-details/5f773c5c541f443e5ce64aaa">Gateway​</a></li>
                                            <li> <a href="/product-details/5f9d650ff268b98214e737bf">Keypad​</a></li>
                                            <li> <a href="/product-details/5f7b2dc6ffa2800728e971b7">Air Quality Sensor</a></li>
                                            <li> <a href="/product-details/5f7b2f0bffa2800728e971bf">Contact Sensor</a></li>
                                            <li> <a href="/product-details/5f7b2e1effa2800728e971b9">Panic Button</a></li>
                                            <li> <a href="/product-details/5f7b2d5bffa2800728e971b5">Siren </a></li>
                                            <li> <a href="/product-details/5f7b2f74ffa2800728e971c1">Smoke Sensor</a></li>
                                            <li> <a href="/product-details/5f7b2e6fffa2800728e971bb">Heat Sensor</a></li>
                                            <li> <a href="/product-details/5f7b302bffa2800728e971c5">Flood Alarm</a></li>
                                            <li> <a href="/product-details/5f7b2ec3ffa2800728e971bd">Motion Sensor</a></li>
                                            <li> <a href="/product-details/5f7b2fc3ffa2800728e971c3">Smart Plug</a></li>
                                            <li> <a href="/product-details/5f9d6592f268b98214e737c1">Key Tag</a></li>
                                        </ul>
                                    </div>

                                    <div className="col-md-4 wow fadeInUp" data-wow-delay="0.6s">
                                        <h4>SafelyTeam</h4>
                                        <ul>
                                            <li> <a href="/">Home</a></li>  
                                            <li> <a href="/step1">Bespoke Systems</a></li>   
                                            <li> <a href="/packages">Packages</a></li>   
                                            <li> <a href="/crime">Crime In UK</a></li>                                   
                                            <li> <a href="/contactus">Contact Us</a></li>				
                                        </ul>
                                        <h4>SafelyTeam Connect App</h4>
                                        <ul>
                                            <li> <a href="https://play.google.com/store/apps/details?id=com.safelyteam.safely" target="_blank">Android </a></li>
                                            <li> <a href="https://apps.apple.com/gb/app/safely-connect/id1525530018" target="_blank">iOS</a></li>         
                                        </ul>
                                    </div>

                                    <div className="col-md-4 wow fadeInUp" data-wow-delay="0.8s">
                                        <h4>Help</h4>
                                        <ul>   
                                            <li> <a href="http://blog.safelyteam.com/">Blogs</a></li>
                                            <li> <a href="/faq">FAQ</a></li>
                                            <li> <a href="https://safelyteam.zendesk.com/hc/en-gb" target="_blank">Help Centre</a></li>
                                            <li> <a href="/privacy">Privacy Policy</a></li>
                                            <li> <a href="/terms">Terms and Conditions</a></li>
                                            <li><a href="/cookies">Cookies</a></li>
                                        </ul>
                                    </div>

                                </div>

                            </div>

                        </div>

                        {/* Building Design */}
                        <div className="row ptb-inner">
                            <div className="col-md-4 px-0 pt-5"> <img src={require('./img/build-btm.jpg')} className="img-fluid" alt="Home Image | Smoke Detectos For Home | Safety"/> </div>
                            <div className="col-md-4 px-0 pt-5"> </div>
                            <div className="col-md-4 px-0 pt-5"> <img src={require('./img/right-man.jpg')} className="img-fluid" alt="Out Door Image | Anti-theft Devices For Smart Home"/> </div>
                        </div>

                    </div>
                </div>
                    

                <Subscribe /> 

            </div>                            
        </NewLayout>
    )
}

export default Sitemap
