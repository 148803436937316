import React from 'react'
import AdminLayout from './AdminLayout'

const AdminDashboard = () => {
    return (
        <AdminLayout topTitle="Dashboard">
			<div className="row">
				<div className="col-md-12 grid-margin stretch-card">
					<div className="card">
						<div className="card-body">
             				<h3> Admin Dasboard </h3>
        				</div>
					</div>
				</div>
			</div>
        </AdminLayout>
    )
}

export default AdminDashboard
