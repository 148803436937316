import React, {useState, useEffect, Fragment} from 'react'
import { isAuthenticated,authenticate } from '../../auth/index'
import { updateUser, getUser } from './Priorities/apiUser'
import Navbar from '../admin/Navbar'
import Sidebar from './Sidebar'
import {Helmet} from 'react-helmet'

const MyProfile = ({match}) => {

    const [values, setValues] = useState({
        prefix:'',
        name: '',
        lastname: '',
        email: '',
        phone: '',
        error: '',
        success: false
    });

    const {prefix,name, lastname, email, phone,success, error} = values

    const [spinloading,setspinloading] = useState(true);

    const [btnloading,setbtnloading] = useState(false);

    const { token } = isAuthenticated()

    const [hide, setHide] = useState(false) 

    const init = userId => {
        getUser(userId,token).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
                setspinloading(false);
            } else {            
                setValues({
                    ...values,
                    prefix:data.prefix,
                    name: data.name,
                    lastname: data.lastname,
                    email: data.email,
                    phone: data.phone
                });
                setspinloading(false);
            }
        });
    };


    useEffect(() => {
        init(match.params.userId);
    }, []);

    const handleChange = name => event => {
        if(event.target.value === "Custom"){
            setHide(true);
        }
        setValues({ ...values, error: false, [name]: event.target.value});
    };


    const clickSubmit = (event) => {
        event.preventDefault();
        setbtnloading(true);
        setValues({...values, error: false });
        updateUser(match.params.userId,token,{prefix,name, lastname, email, phone})
        .then(data => {
            if(data.error) {
                setValues({...values, error: data.error, success:false})
                setbtnloading(false);
            } else {
                setValues({
                    ...values,
                    error: '',
                    success: true
                })
                authenticate({token, user:{_id:data._id,prefix:data.prefix,name:data.name,lastname:data.lastname,email:data.email,role:data.role,customerid:data.customerid,subscriptionid:data.subscriptionid,subscription_paid:data.subscription_paid, isSubCancelled:data.isSubCancelled}},() => {})
                setbtnloading(false);
            }
        })
    }

    const [titleList,setTitlelist] = useState([
        { label:"Mr ",value: "Mr"},
        { label:"Mrs",value: "Mrs"},
        { label:"Ms",value: "Ms"},
        { label:"Miss",value: "Miss"},
        { label:"Dr",value: "Dr"},
        { label:"Sir",value: "Sir"},
        { label:"Custom",value:"Custom"}
      ])
 
    const updateUserForm = () => (
        <form onSubmit={clickSubmit} >
            <div className="form-group">
                <label className="text-muted">Title<span style={{color:"red"}}> *</span></label>
                    <select required onChange={handleChange('prefix')} className="form-control">
                        <option value="">Choose Here</option>
                        {    
                            titleList.map(room => (                                            
                                (prefix === room.value ? "" :                   
                                                                    
                                <option key={room.value} value={room.value}>
                                    {room.label}
                                </option>)     
                            ))
                        }
                        { (prefix) ?  
                                <option selected key={prefix} value={prefix}>
                                    {prefix}  
                                </option> : "" } 
                    </select>
                    <input type="text" style={{display: hide ? 'block' :'none'}}  onChange={handleChange('prefix')} className="form-control border-rad-0-px" id="usr" placeholder="Enter the Custom Prefix" />
            </div>
            <div className="form-group">
                <label className="text-muted">First Name<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('name')} type="text" className="form-control" value={name} required />
            </div>

            <div className="form-group">
                <label className="text-muted">Last Name<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('lastname')} type="text" className="form-control" value={lastname} required />
            </div>

            <div className="form-group">
                <label className="text-muted">Email<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('email')} type="email" className="form-control" value={email} readOnly required />
            </div>

            <div className="form-group">
                <label className="text-muted">Phone No<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('phone')} type="number" className="form-control" value={phone} required />
            </div>
            
            <button  disabled={btnloading} className="btn btn-success">{btnloading ? "Loading..." : "Update"}</button>
            
            <div><a className="nav-link" href="/user/dashboard">Go Back to Dashboard </a></div>
            
        </form>
    );

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => (
        <div className="alert alert-success" style={{ display: success ? '' : 'none' }}>
            Profile updated Successfully
        </div> 
    );


    return (
        <Fragment>
            <Helmet>
                <title>SafelyTeam - Edit Profile</title>
            </Helmet>
             <div className="container-scroller">
                <Navbar />
                
                <div className="container-fluid page-body-wrapper">
    
                <Sidebar />

                        <div className="main-panel">
                          <div className="content-wrapper white-bg-ad">
                            <div className="row">
                              <div className="col-md-12 grid-margin">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <h4 className="font-weight-bold mb-0">Edit Profile</h4>
                                  </div>
                                <div>  
                              </div>
                            </div>
                          </div>
                        </div>
                            {(spinloading) ? <div className='loader-container'><div className='loader'></div></div> : ""} 
                            {showSuccess()}
                            {showError()}
                            {!spinloading && updateUserForm()}
                      </div> 
                    </div>

                </div>
        </div>
     
        </Fragment>
    )
}

export default MyProfile
