import React, {Fragment} from 'react'
import Header from './Header'
import Footer from './Footer'
import {Helmet} from 'react-helmet'

const NewLayout = ({children,topTitle,metaDescription}) => {
    return (
        <Fragment style={{overflowY: 'scroll', overflowX: 'hidden'}}>
            <Helmet>
                <title>{`${topTitle}`}</title> 
                <meta name="description" content={`${metaDescription}`} />
            </Helmet>

            <Header />

            {children}

            <Footer />
            
        </Fragment>
    )
}

export default NewLayout
