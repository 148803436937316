import React, {Fragment, useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../admin/Navbar'
import Sidebar from './Sidebar'
import { getAllShippingByUserId, updateShippingStatus } from './Priorities/apiUser'
import { isAuthenticated } from '../../auth/index'
import {Helmet} from 'react-helmet'

const AllAddress = () => {

  const { user,token } = isAuthenticated()
  const [loading1, setLoading1] = useState(true)

  const [address, setAddress] = useState([])

  const loadAddress = () => {
    getAllShippingByUserId(user._id).then(data => {
        if (data.error) {
           console.log(data.error)
           setLoading1(false);
        } else {
            // populate the state
            setAddress(data)
            setLoading1(false);
        }
    });
  };

  const destroy = shippingId => {
    if(window.confirm("Do you want to delete this address?"))
    {
      updateShippingStatus(shippingId, user._id,{status:2,Id:shippingId}, token).then(data => {
          if (data.error) {
              console.log(data.error);
          } else {
              loadAddress();
          }
      });
    }
};

  useEffect(() => {
    loadAddress();
  },[])
 
    const allAddress = () => {
      return (
        <div className="row">
          
                {address.map((p,i) => ( 
                    <div className="col-md-6 px-0 mb-3">
                      <div className="col-md-8 bord-line">

                        <div className="row">
                           
                            <div className="col-md-8 py-3 wid-60-per-in">

                                <h4 className="pb-3 color-blk-in font-one-re">{p.prefix}. {p.first_name} {p.last_name}</h4>
                                <p className="pb-0 mb-0">
                                {p.house_no}
                                {p.area !== undefined && <><br/><span>{p.area}</span></>}<br/>
                                {p.city}{p.state !== undefined && <span>-{p.state}</span>}<br/>
                                {p.country}<br/>
                                {p.pin_code}<br/>
                                Phone no: {p.phone}

                                </p>
                            </div>
                            <div className="col-md-3 pt-3 text-right">
                                <p className="font-one-edit"><Link to={`/user/updateaddress/${p._id}/alladdress`}>Edit</Link> | <span style={{cursor:'pointer'}} onClick={() => destroy(p._id)}>Delete</span></p>
                            </div>
                        </div>
                    </div>
                </div>

                
                ))}
        </div>
      )
    }

    const showNotfound = () => {
      return (
              <div className="col-md-12 px-0">
                  <div className="col-md-12" style={{backgroundColor: '#f5f5f5', border: 'solid 1px #e7e7e7', borderTop: '0px'}}>
                      <br/>
                       <h4 style={{textAlign:'center',color:'red'}}>Not found!!</h4>
                  </div>
              </div>
      )
  }


    return (
        <Fragment>
            <Helmet>
                <title>SafelyTeam - All Address</title>
            </Helmet>
             <div className="container-scroller">
                <Navbar />
                
                <div className="container-fluid page-body-wrapper">
    
                <Sidebar />

                        <div className="main-panel">
                          <div className="content-wrapper white-bg-ad">
                            <div className="row">
                              <div className="col-md-12 grid-margin">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <h4 className="font-weight-bold mb-0">All Addresses</h4>
                                  </div>
                                <div>  
                              </div>
                            </div>
                          </div>
                        </div>
                        {(loading1) ? <div className='loader-container'><div className='loader'></div></div> : ""}  
                     
                        {!loading1 && (address.length>=1 ? allAddress() : showNotfound())}
                      </div> 
                    </div>

                </div>

        </div>

     
        </Fragment>
    )
}

export default AllAddress
