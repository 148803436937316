import React from 'react'
import CustomScripts from '../../hooks/customscripts';

const Footer = () => {
    CustomScripts('https://code.jquery.com/jquery-1.12.4.min.js');
    CustomScripts('/themes/admin/js/off-canvas.js');
    CustomScripts('/themes/admin/js/hoverable-collapse.js');
    CustomScripts('/themes/admin/js/template.js');    
    CustomScripts('/themes/admin/js/dashboard.js');

    return (
        <div>
            <footer className="footer">
                    <div className="d-sm-flex justify-content-center justify-content-sm-between">
                        <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">© SafelyTeam 2021 All right reserved. Powered By <a href="#/" > Ecesis</a></span>
                    
                    </div>
                </footer>
        </div>
    )
}

export default Footer
