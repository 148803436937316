import React, { useState } from 'react'
import { Redirect } from 'react-router-dom';
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import {createSupplier} from '../admin/apiAdmin'

const AddSupplier = () => {

    const [values, setValues] = useState({
        companyname: '',
        contactname: '',
        email: '',
        phone: '',
        address: '', 
        btnloading:false,    
        error: '',
        success: false
    });

    const {companyname, contactname, email, phone, address,btnloading, success, error} = values

    const { user, token} = isAuthenticated()

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value,btnloading:false});
    };


    const clickSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: false,btnloading:true });
        createSupplier(user._id,token,{companyname,contactname,email,phone,address})
        .then(data => {
            if(data.error) {
                setValues({...values, error: data.error, success:false,btnloading:false})
            } else {
                setValues({
                    ...values,
                    companyname:'',
                    contactname: '',
                    email: '',
                    phone: '',
                    address: '',
                    btnloading:false,
                    error: '',
                    success: true
                })
            }
        })
    }
 
    const supplierForm = () => (
        <form onSubmit={clickSubmit}>
            <div className="form-group">
                <label className="text-muted">Company Name<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('companyname')} type="text" className="form-control" value={companyname} required/>
            </div>

            <div className="form-group">
                <label className="text-muted">Contact Name<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('contactname')} type="text" className="form-control" value={contactname} required/>
            </div>

            <div className="form-group">
                <label className="text-muted">Email<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('email')} type="email" className="form-control" value={email} required/>
            </div>

            <div className="form-group">
                <label className="text-muted">Phone No<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('phone')} type="number" className="form-control" value={phone} required/>
            </div>

            <div className="form-group">
                <label className="text-muted">Address</label>
                <textarea onChange={handleChange('address')} className="form-control" value={address} />
            </div>

            <button  className="btn btn-outline-primary" disabled={btnloading}> {btnloading ? "Loading..." : "Add"} </button>
        </form>
    );

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => {
        if(success) {
            return <Redirect to="/admin/suppliers" />
        }
    };


    return (
        <AdminLayout className="container" topTitle="Add Supplier">
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title" style={{textAlign:'center'}}>Add Supplier</h4>
                            {showSuccess()}
                            {showError()}
                            {supplierForm()}
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
    
}
export default AddSupplier;
