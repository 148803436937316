import { API } from '../../../config'

export const createQuestion = ( question ) => {

        return fetch(`${API}/question/create`,{
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": 'application/json'
            },
            body:JSON.stringify(question)
        })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        })  
    
};


export const createQuestionuser = ( userid, token, question ) => {

        return fetch(`${API}/question/create/${userid}`,{
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": 'application/json',
                Authorization: `Bearer ${token}`
            },
            body:JSON.stringify(question)
        })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        })   
};

export const addQuestion = ( question ) => {
    return fetch(`${API}/question/add`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(question)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const getProducts = () => {
    return fetch(`${API}/products`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getProductList = () => {
    return fetch(`${API}/list/products`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getProduct = productId => {
    return fetch(`${API}/product/${productId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getProductByName = product => {
    //console.log(name, email, password);
    return fetch(`${API}/product/listbyname`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body:JSON.stringify(product)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};


export const addOrder = order => {
    //console.log(name, email, password);
    return fetch(`${API}/order/create`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body:JSON.stringify(order)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const addOrderforAdPack = order => {
    //console.log(name, email, password);
    return fetch(`${API}/order/create/ad/package`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body:JSON.stringify(order)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};


export const addUserOrder = (order,userId,token) => {
    //console.log(name, email, password);
    return fetch(`${API}/order/createuser/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(order)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const UpdateUserOrder = (order,userId,token) => {
    //console.log(name, email, password);
    return fetch(`${API}/order/update/product/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(order)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};


export const getShippingByUserId = userId => {
    return fetch(`${API}/order/shipping/address/${userId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};
export const getAllShippingByUserId = userId => {
    return fetch(`${API}/order/shipping/addresses/${userId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};
export const updateOrder = (userId, token, createOrderData) => {
    return fetch(`${API}/order/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ order: createOrderData })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getAllProducts = (ids) => {
    return fetch(`${API}/product/listProducts/${ids}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
 };


 export const createNewsletter = ( newsletter ) => {

    return fetch(`${API}/newsletter/create`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body:JSON.stringify(newsletter)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })  

};

export const updateOrderSts = (orderId, status, paymentId) => {
    return fetch(`${API}/updateOrderStatus`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ 
                'orderId' : String(orderId),
                'status' : String(status),
                'paymentId' : String(paymentId),
                })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}; 

export const updateSubscribeSts = (orderId, status, subscriptionId, paymentid, subType) => {
    return fetch(`${API}/update/subscription/status`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            'orderId' : String(orderId),
            'status' : String(status),
            'paymentId' : String(subscriptionId),
            'subscriptionId' : String(subscriptionId),
            'payment' : String(paymentid),
            'subType' : Number(subType),
            
        })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getAddress = shippingId => {
    return fetch(`${API}/shipping/${shippingId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateAddress = (shippingId, userId, token, shipping) => {
    return fetch(`${API}/shipping/${shippingId}/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(shipping)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const deleteAddress = (shippingId, userId, token) => {
   
    return fetch(`${API}/shipping/remove/${shippingId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getUser = (userId,token) => {
    return fetch(`${API}/user/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateUser = ( userId, token, user) => {
    return fetch(`${API}/user/update/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}

export const createContact = ( contact ) => {

    return fetch(`${API}/contact/create`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body:JSON.stringify(contact)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })  

};

export const getCancelledOrders = (userId) => {
    return fetch(`${API}/order/cancelledorder/${userId}`, { 
        method: "GET",
        headers: {
            Accept: "application/json"
        }
    }).then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const changePassword = (userId, token, userData) => {
    return fetch(`${API}/changepassword/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(userData)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateWaitingOrderStatus = ( userId,token,orderData ) => {
    return fetch(`${API}/order/update/waiting-status/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(orderData)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updatPreConfigStatus = ( userId,token,orderData ) => {
    return fetch(`${API}/order/update/preconfig-status/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(orderData)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateCancelStatus = ( userId,token,orderData ) => {
    return fetch(`${API}/order/update/cancel-status/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(orderData)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getUserOrders = (userId, token) => {
    return fetch(`${API}/order/user/${userId}`, { 
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
           Authorization: `Bearer ${token}`
        }
    }).then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getUserOrders1 = (userId, token) => {
    return fetch(`${API}/orderlist/user/${userId}`, { 
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
           Authorization: `Bearer ${token}`
        }
    }).then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getUsersPendingOrders = (userId) => {
    return fetch(`${API}/order/pending-config/user/${userId}`, { 
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    }).then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateShippingStatus = ( shippingId,userId,status, token) => {
   
    return fetch(`${API}/shipping/status/${shippingId}/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(status)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const getCrime = data => {
    
    return fetch(`${API}/crime`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body:JSON.stringify(data)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const getOrderByUserID = userId => {
    return fetch(`${API}/order/user/list/${userId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getOrderByID = orderId => {
    return fetch(`${API}/order/${orderId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getSubscriptions = () => {
    return fetch(`${API}/subscription/lists`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getUserSubscription = userId => {
    return fetch(`${API}/order/customer-subscription/lists/${userId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateUserSubscription = ( userId, token,order) => {
    return fetch(`${API}/order/cancel-by-user/subscription/plan/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(order)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const activateUserSubscription = ( userId, token,order) => {
    return fetch(`${API}/order/activate-by-user/subscription/plan/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(order)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateUserSubscriptionHistory = ( userId, token,order) => {
    return fetch(`${API}/order/cancel-by-user/subscription/history/plan/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(order)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const createNotes = ( userId, token, notes ) => {

    return fetch(`${API}/note/create/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(notes)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })  

};


export const checkCoupon = ( userid, token, coupons ) => {

    return fetch(`${API}/check/coupon/${userid}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(coupons)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })   
};

export const checknewUserCoupon = ( coupon ) => {

    return fetch(`${API}/check/new-user/coupon`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body:JSON.stringify(coupon)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })   
};

export const createReturnProduct = ( userid, token, returnprod ) => {

    return fetch(`${API}/return-product/create/${userid}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:returnprod
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })   
};

export const getAds = () => {
    return fetch(`${API}/list/ads/users`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getAdsbyId = (adsId) => {
    return fetch(`${API}/ads/${adsId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getAdsProducts = (adsProd) => {
    
    return fetch(`${API}/list/all/ads/products/user`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(adsProd)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const getOrdersByIdforAdId = (orderId) => {
    return fetch(`${API}/order/adId/${orderId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json'
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateCustomerId = ( userId, token, user) => {
    return fetch(`${API}/user/update/customerid/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const createCardDetail = (card) => {
    return fetch(`${API}/create/card-detail`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
        },
        body: JSON.stringify(card)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getCardDetailsById = (card) => {
    return fetch(`${API}/list/card/details/cardId`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
        },
        body: JSON.stringify(card)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getActiveCardsById = (card) => {
    return fetch(`${API}/list/active/cards`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
        },
        body: JSON.stringify(card)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateCardStatus = ( card ) => {
    return fetch(`${API}/update/card/status`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(card)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const createOrderSubscription = ( userId, token, order) => {
    return fetch(`${API}/create/order/user/subscription/${userId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(order)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const createSubscriptionLog = ( userId, token, sub) => {
    return fetch(`${API}/create/order/subscription/logs/${userId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(sub)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const createPreorder = ( preorder ) => {
    return fetch(`${API}/create/preorder`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(preorder)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const createMultiplePreorder = ( preorder ) => {
    return fetch(`${API}/create/multiple/preorder`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(preorder)
    })
        .then(response => {           
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getSubscriptionLogs = ( userId, token, sub) => {
    return fetch(`${API}/get/user-subscription/logs/${userId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(sub)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

