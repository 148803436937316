import React from 'react'
import Subscribe from './Subscribe'
import Header from './Header'
import Footer from './Footer'
import { isAuthenticated } from '../../auth/index'
import {Helmet} from 'react-helmet'

const UserDashboard = () => {

    const {user: { _id,name,lastname,prefix}} = isAuthenticated()
 
    return (
        <div style={{overflowY: 'scroll', overflowX: 'hidden'}}>
             <Helmet>
                <title>SafelyTeam - Dashboard</title>
            </Helmet>
            <Header />
            
                    <div className="section pp-scrollable slide-dark slide-dark-footer">      
                        
                        <div className="col-md-12 px-0 inner-tp-pad inner-safely">
                            <div className="col-md-12 inner-safely">
                                <div className="container">

                                    <div className="row ptb-inner">
                                        <div className="col-md-12 pt-5 px-0">

                                            <div className="col-md-12 text-center">

                                                <h3>Hello, {prefix}. {lastname}!</h3>
                                            </div>
                                            <div className="col-md-10 mx-auto">
                                                <div className="row user-bg-bl">
                                                    <div className="col-md-3 p-5 user-center-mid">
                                                        <img src={require('./img/user.png')} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="col-md-9 user-center-mid" style={{paddingTop: '9%'}}>
                                                        <h3 className="username-txt-white">{prefix}. {lastname}</h3>
                                                        <p className="edit-prof-text"><a href={`/user/myprofile/${_id}`}>Edit Profile</a></p>
                                                    </div>
                                                </div>
                                                <div className="row pt-5">
                                                    <div className="col-md-6 pl-0 mb-5 padd-rightuser">
                                                        <div className="col-md-12 py-3 border-in-profile">
                                                            <div className="row">
                                                                <div className="col-md-3 p-4 user-center-mid"><img src={require('./img/order-img.png')} className="img-fluid" alt="" /></div>
                                                                <div className="col-md-9 pt-3 user-center-mid">
                                                                    <h4 className="col-black-ode"><a href="/user/yourorders">Orders</a></h4>
                                                                    <p>Track, Return or Buy again</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 pr-0 mb-5 padd-rightuser">
                                                        <div className="col-md-12 py-3 border-in-profile">
                                                            <div className="row">
                                                                <div className="col-md-3 p-4 user-center-mid"><img src={require('./img/config-icon.png')} className="img-fluid" alt="" /></div>
                                                                <div className="col-md-9 pt-3 user-center-mid">
                                                                    <h4 className="col-black-ode"><a href="/user/pendingconfiguration">Configuration</a></h4>
                                                                    <p>Complete pending configuration</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 pl-0 mb-5 padd-rightuser">
                                                        <div className="col-md-12 py-3 border-in-profile">
                                                            <div className="row">
                                                                <div className="col-md-3 p-4 user-center-mid"><img src={require('./img/address-icon.png')} className="img-fluid" alt="" /></div>
                                                                <div className="col-md-9 pt-3 user-center-mid">
                                                                    <h4 className="col-black-ode"><a href="/user/alladdress">Addresses</a></h4>
                                                                    <p>Change your Address</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 pr-0 mb-5 padd-rightuser">
                                                        <div className="col-md-12 py-3 border-in-profile">
                                                            <div className="row">
                                                                <div className="col-md-3 p-4 user-center-mid"><img src={require('./img/saved-cards.png')} className="img-fluid" alt="" /></div>
                                                                <div className="col-md-9 pt-3 user-center-mid">
                                                                    <h4 className="col-black-ode"><a href="/user/changepassword">Change Password</a></h4>
                                                                    <p>Update your password</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row ptb-inner">
                                        <div className="col-md-4 px-0 pt-5"> <img src={require('./img/build-btm.jpg')} className="img-fluid" alt="" /> </div>
                                        <div className="col-md-4 px-0 pt-5"> </div>
                                        <div className="col-md-4 px-0 pt-5"> <img src={require('./img/right-man.jpg')} className="img-fluid" alt="" /> </div>
                                    </div>


                                </div>
                            </div>
                           
                        </div>
                        <Subscribe />
                    </div>
                    <Footer />
             
        </div>

    )
}

export default UserDashboard
