import React, { useState, useEffect, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { isAuthenticated } from '../../auth/index'
import { getAddress, updateAddress } from './Priorities/apiUser'
import Navbar from '../admin/Navbar'
import Sidebar from './Sidebar'
import {Helmet} from 'react-helmet'

const UpdateAddress = ({match}) => {

    const [values, setValues] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        pin_code: '',
        house_no: '',    
        area: '',
        city: '',
        state:'',
        country:'',
        error:'',
        prefix:'',
        success: false
    });

    const {first_name,prefix, last_name,phone,pin_code,house_no,area,city,state,country, success, error} = values

    const { user, token} = isAuthenticated()

    const [btnloading, setBtnloading] = useState(false);

    const loadAddress = shippingId => {
        getAddress(shippingId).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                // populate the state
                setValues({
                    ...values,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    phone: data.phone,
                    pin_code: data.pin_code,
                    house_no: data.house_no,
                    area: data.area,
                    city: data.city,
                    state:data.state,
                    country: data.country,
                    prefix:data.prefix
                });
            }
        });
    };

    useEffect(() => {
        loadAddress(match.params.shippingId); 
    }, []);

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value});
    };


    const clickSubmit = (event) => {
        event.preventDefault();
        setBtnloading(true)
        setValues({...values, error: false });
        updateAddress(match.params.shippingId,user._id,token,{ID:match.params.shippingId,first_name,prefix, last_name, phone, pin_code, house_no,area,city,state,country})
        .then(data => {
            if(data.error) {
                setValues({...values, error: data.error, success:false})
                setBtnloading(false);
            } else {
                setValues({
                    ...values,
                    first_name: '',
                    last_name: '',
                    phone: '',
                    pin_code: '',
                    house_no: '',    
                    area: '',
                    city: '',
                    state:'',
                    country:'',
                    error:'',
                    success: true
                })
                setBtnloading(false);
            }
        })
    }
    const [titleList,setTitlelist] = useState([
        { label:"Mr",value: "Mr"},
        { label:"Mrs",value: "Mrs"},
        { label:"Ms",value: "Ms"},
        { label:"Miss",value: "Miss"},
        { label:"Dr",value: "Dr"},
        { label:"Sir",value: "Sir"}
      ])
    const updateShippingForm = () => (
    <form onSubmit={clickSubmit}>
        <div className="row">
            
                <div className="col-md-6">

                        <div className="form-group">
                            <label className="text-muted">Title<span style={{color:"red"}}> *</span></label>
                                                        <select required
                                                            onChange={handleChange('prefix')} 
                                                            className="form-control">
                                                                <option>Select Title</option> {
                                                                titleList.map(room =>(
                                                                    (prefix === room.value ? 
                                                                    <option selected key={room.value} value={room.value}>
                                                                        {room.value}
                                                                    </option> :
                                                                    <option key={room.value} value={room.value}>{room.value}</option>)
                                                                ))
                                                            }
                                                                
                                                                
                                                        </select>
                                                    </div>

                    <div className="form-group">
                        <label className="text-muted">First Name <span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChange('first_name')} type="text" className="form-control" value={first_name} required/>
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Last Name <span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChange('last_name')} type="text" className="form-control" value={last_name} />
                    </div>

                    <div className="form-group">
                        <label for="usr">Phone Number <span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChange('phone')} type="text" className="form-control border-rad-0-px" id="usr" value={phone} />
                    </div>
                                      
                    
                    <div className="form-group">
                        <label for="usr">Address Line 1 <span style={{color:"red"}}> *</span></label>
                        <input  onChange={handleChange('house_no')} type="text" className="form-control border-rad-0-px" id="usr" value={house_no} />
                    </div>
                    <div className="form-group">
                        <label for="usr">Address Line 2 </label>
                        <input onChange={handleChange('area')} type="text" className="form-control border-rad-0-px" id="usr" value={area} />
                    </div>

            </div>
            <div className="col-md-6">
                    
                    <div className="form-group">
                        <label for="usr">Town/City <span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChange('city')} type="text" className="form-control border-rad-0-px" id="usr" value={city} />
                    </div>
                    <div className="form-group">
                        <label for="usr">County</label>
                        <input onChange={handleChange('state')} type="text" className="form-control border-rad-0-px" id="usr" value={state} />
                    </div>
                    <div className="form-group">
                        <label for="usr">Postcode <span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChange('pin_code')} type="text" className="form-control border-rad-0-px" id="usr" value={pin_code} />
                    </div>
                    <div className="form-group">
                        <label for="usr">Country </label>
                        <input onChange={handleChange('country')} type="text" className="form-control border-rad-0-px" id="usr" readOnly value={country} />
                    </div>
                    <button  disabled={btnloading} className="btn btn-success">{btnloading ? "Loading..." : "Update"}</button>       
            </div>      
        </div>
    </form>                                          
    );

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => {
        if(success) {
            if(match.params.testValue === "recentaddress") {
                return <Redirect to="/user/recentaddress" />
            } else {
                return <Redirect to="/user/alladdress" />
            }    
        }
    };

    return (
        <Fragment>

            <Helmet>
                <title>SafelyTeam - Update Address</title>
            </Helmet>
            
             <div className="container-scroller">
                <Navbar />
                
                <div className="container-fluid page-body-wrapper">
    
                <Sidebar />

                        <div className="main-panel">
                          <div className="content-wrapper white-bg-ad">
                            <div className="row">
                              <div className="col-md-12 grid-margin">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <h4 className="font-weight-bold mb-0">Update Address</h4>
                                  </div>
                                <div>  
                              </div>
                            </div>
                          </div>
                        </div>
                            
                            {showSuccess()}
                            {showError()}
                           
                        {updateShippingForm()}
                      </div> 
                    </div>

                </div>
        </div>
     
        </Fragment>
    )
}

export default UpdateAddress
