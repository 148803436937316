import React, { Fragment, useState, useEffect } from 'react'
import { isAuthenticated } from '../../auth/index' 
import {Redirect} from 'react-router-dom'
import { getOrdersById, getSubOrdersByOrderid,updateAssignDevice, updateRoomName } from '../../user/apiUser'
import { updatPreConfigStatus } from './Priorities/apiUser'
import Navbar from '../admin/Navbar';
import Sidebar from './Sidebar'
import {OverlayTrigger,Tooltip} from 'react-bootstrap'


const YourOrderDetails = ({match}) => {

    const {user, token} = isAuthenticated()
    const [values1, setCheck] = useState({check:false, selectedid:1000}) 
    const [selectedCustoms, setCustoms] = useState(',')

    const [values, setValues] = useState({
        id:'',
        btnloading:true
    });

    const {id,btnloading} = values
    
    const [ordValue, setOrdValue] = useState({
      ordid:'',
      ordId1:'',
      tempId:'',
      tempStatus:true,
      success: false,
      error:'',
      btnloading1:false
  });

  const {ordid,ordId1,tempId,tempStatus, success, error,btnloading1} = ordValue

    const {check,selectedid} = values1

    const [ suborders, setSuborders] = useState([]); 
    const [ suborders1, setValSuborders] = useState([]); 
    
    const loadOrders = (orderId) => {
        getOrdersById(orderId).then(data => {
            if(data.error) {
                console.log(data.error);
                setValues({...values,   btnloading:false});
            } else {
                setValues({
                    ...values,
                    id: data.orderid,
                    btnloading:true
                });
                setOrdValue({ ...ordValue, ordid:data.orderid});
            }
        });
    };

     const loadSubOrders = (orderId) => {
        getSubOrdersByOrderid(orderId).then(data => {
            if(data.error) {
                console.log(data.error);
                setValues({...values,   btnloading:false});
            } else {
                setSuborders(data);
                setValues({...values,   btnloading:false});
            }
        });
    };   
   

    useEffect(() => {
        loadOrders(match.params.orderId);
        loadSubOrders(match.params.orderId); 
    }, []);

    const handleAssignDeviceChange = (e, suborderId) => {
      setOrdValue({...ordValue,error:'',btnloading1:true,error:false, tempStatus:true});
         updateAssignDevice(user._id, token, suborderId, e.target.value).then(
            data => {
                if (data.error) {
                  setOrdValue({...ordValue,error:'',btnloading1:false,error:false, tempStatus:true});
                    console.log("Assign Device update failed");
                } else {
                  setOrdValue({...ordValue,error:'',btnloading1:false,error:false, tempStatus:true});
                    //loadSubOrders(match.params.orderId); 
                }
            }
        ); 
    };

    const handleRoomNameChange = (e, suborderId,i,selectedCustoms1) => {
      setOrdValue({...ordValue,error:'',btnloading1:true,error:false, tempStatus:true});
      if(e.target.value === "Custom") {  
              
          setCheck({...values1,check:true,selectedid:i});
               
          setCustoms(selectedCustoms1+i+",");            
          setOrdValue({...ordValue,error:'',btnloading1:false,error:false, tempStatus:true});
          return 0;
      }
      else
      { 
        var toRemove = ','+i+',';
        selectedCustoms1 = selectedCustoms1.replace(toRemove,",");     
        setCustoms(selectedCustoms1);
        setCheck({...values1,check:false,selectedid:i});
      }
      
          updateRoomName(user._id, token, suborderId, e.target.value).then(
          data => {
              if (data.error) {
                setOrdValue({...ordValue,error:'',btnloading1:false,error:false, tempStatus:true});
                  console.log("Room Name update failed");
              } else {
                setOrdValue({...ordValue,error:'',btnloading1:false,error:false, tempStatus:true});
                  //loadSubOrders(match.params.orderId);                    
              }
          }
       ); 
      };

  const handleRoomNameChange1 = (e, suborderId,i) => {

    /* setRoomlist(names => [...names,{label:e.target.value,value:e.target.value}]) */
    updateRoomName(user._id, token, suborderId, e.target.value).then(
      data => {
          if (data.error) {
              console.log("Room Name update failed");
          } else {
              //loadSubOrders(match.params.orderId); 
          }
      }
    ); 
  };

  const [roomList,setRoomlist] = useState([
    { label:"Kitchen ",value: "Kitchen"},
    { label:"Living Room",value: "Living Room"},
    { label:"Dining Room",value: "Dining Room"},
    { label:"Porch",value: "Porch"},
    { label:"Hallway",value: "Hallway"},
    { label:"Landing",value: "Landing"},
    { label:"Master Bedroom",value: "Master Bedroom"},
    { label:"Bedroom",value: "Bedroom"},
    { label:"Guest Bedroom",value: "Guest Bedroom"},
    { label:"Bathroom",value: "Bathroom"},
    { label:"En-Suite",value: "En-Suite"},
    { label:"Office",value: "Office"},
    { label:"Garage",value: "Garage"},
    { label:"Utility Room",value: "Utility Room"},
    { label:"Conservatory",value: "Conservatory"},
    { label:"Custom",value: "Custom"}
  ])


  const roomName = (o,roomname,i) => (

    
    <div className="form-group mb-0">
      {roomname === "Key Tags" ? (<select className="form-control height-40-px" id="sel1">
        <option>Key Tags</option>
      </select>) :   (roomname === "Gateway") ? (<select className="form-control height-40-px" id="sel1">
        <option>Gateway</option>
      </select>) : (
        <div>

<select className="form-control height-40-px" id="sel1" onChange={e => handleRoomNameChange(e, o,i,selectedCustoms)}>
    
    <option value="">Choose Here</option>
    {    
        roomList.map(room => (                                            
            (roomname === room.value ? "" :                   
        
            <option key={room.value} value={room.value}>
                {room.label}
            </option>)     
        ))
    }
        { (roomname) ?  
            <option selected key={roomname} value={roomname}>
                  {roomname}  
                  </option> : "" } 
        </select>
        <br/>
        <input className="form-control" type="text" id={i} onBlur={e => handleRoomNameChange1(e, o,i)} defaultValue={roomname} placeholder="Enter Your Custom Room Name" style={{display:  ((contains(','+i+',',selectedCustoms)) ||(check && selectedid===i)) ? 'block' : 'none' }}/>

        </div>
      )}
        
    </div>
)


  const assignDevice = (o,assign_device1) => (
  
    <div className ="form-group">
      {assign_device1 === "Key Tags" ? (
        <input type="text" className="form-control" name="devicename" defaultValue={assign_device1} placeholder={assign_device1} readOnly />
      ) :(assign_device1 === "Gateway") ? (
        <input type="text" className="form-control" name="devicename" defaultValue={assign_device1} placeholder={assign_device1} readOnly />
      ) :  (
        <input type="text" className="form-control" name="devicename" onBlur={e => handleAssignDeviceChange(e, o)} defaultValue={assign_device1} placeholder={assign_device1} />
      )}
        
    </div>
  )
  
  
  
  const clickSubmit = async (orderID) => { 
    setOrdValue({...ordValue,error:'',btnloading1:true,error:false, tempStatus:true});
    await getSubOrdersByOrderid(orderID).then(data => {
      if(data.error) {
          console.log(data.error);
          //setValues({...values,   btnloading:false});
      } else {
        setValSuborders(data);       
        if(data.length>0){
          let chkvalid=false; 
          for(let i=0;i<data.length;i++){
           
            if(data[i].assign_device==='' || data[i].assign_device===undefined)
             {        
              setOrdValue({...ordValue,error:'All fields required', success:false});
              chkvalid=true;
              break;             
            }
             else if(data[i].room_name==='' || data[i].room_name===undefined)
             {         
               setOrdValue({...ordValue,error:'All fields required', success:false}) ; 
               chkvalid=true;   
              break;       
            }    
           
          }   
         
          if(chkvalid)
         {  
           setOrdValue({...ordValue,error:'All fields required', success:false, btnloading1:false}) ; 
           
        }
          else
          {
            
            updatPreConfigStatus(user._id,token,{tempStatus:true,orderID})
            .then(data => {
              if(data.error) {
                setOrdValue({...ordValue,error:data.error, success:false})
              } else {
                setOrdValue({
                  ...ordValue,
                  tempStatus: false,
                  error:'',
                  success: true,
                  btnloading1:false
                })
              }
            })
          }
        }
        else{
          setOrdValue({...ordValue,error:'All fields required'.data.length, success:false, btnloading1:false}) ;
        }       
      } });
  }

  const renderTooltip = (tips,props) => (
    <Tooltip id="button-tooltip" {...props}>{tips}</Tooltip>
  );

    const showCongiguration = () => {
      return (

              <div className="col-md-12 px-0">
                  <div className="row">
                    <div className="col-md-12 stretch-card">
                      <div className="card position-relative card-shad-in">
                        <div className="card-body">
                          <div className="row estimated-bord">                
                            <div className="col-md-6">
                              <p className="card-title blue-esti-head">Order Number: {ordid}</p>
                            </div>
                          </div>

                          <div className="row"> 
          
                              <div className="table-responsive pt-3 px-2">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th className="pend-table-hd">
                                      Product
                                    </th>
                                    <th className="pend-table-hd">
                                      Device Name
                                    </th>
                                    
                                    <th className="pend-table-hd">
                                    Room Name
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                    {suborders.map((s,i) => (
                                          <tr>
                                    
                                          <td className="font-we-prod">
                                            {s.productid.name}&nbsp;<OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}  overlay={renderTooltip(s.productid.tips)}>
                                                <i className="fa fa-info-circle blue-acti" aria-hidden="true" style={{float:'none'}}></i>
                                            </OverlayTrigger> 
                                          </td>
                                          <td>
                                            {assignDevice(s._id,s.assign_device)}
                                          </td>
                                    
                                          <td>
                                            {roomName(s._id,s.room_name,i)}
                                          </td>
                                        </tr> 
                                    ))}
                                  
                                </tbody>
                              </table>
                              {(btnloading) ? <div className='loader-container'><div className='loader'></div></div> : ""}  
                                
                            </div>

                            </div>
                          </div>  
                        </div>
                      </div>
                    </div>
                  </div>
              
      )
  }

  const showError = () => (
    <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
        {error}
    </div>
  );

  const showSuccess = () => {
   
      if(success) {
        return <Redirect to="/user/yourorders" />
      }
  }; 
  const contains = (element,arr) => {
    if(arr.includes(element))
    {
            return true;
        }    
    return false;
  }


    return (
        <Fragment>
             <div className="container-scroller">
                <Navbar />             
                <div className="container-fluid page-body-wrapper">
                  <Sidebar />
                      <div className="main-panel">
                        <div className="content-wrapper white-bg-ad">
                          <div className="row">
                            <div className="col-md-12 grid-margin">
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <h4 className="font-weight-bold mb-0">Pending Configuration</h4>
                                </div>
                              <div>         
                            </div>
                          </div>
                        </div>
                      </div>
                        {showSuccess()}
                    
                        {showCongiguration()}
                        {showError()}
                        <div className="col-md-12" style={{backgroundColor: '#f5f5f5', border: 'solid 1px #e7e7e7', borderTop: '0px'}}>
                          <div className="row">
                            <div className="col-md-12 py-3 text-right">
                           
                              <button type="button" disabled={btnloading1} className="btn btn-info btn-gra-my-p" style={{borderRadius: '25px'}} onClick={() => clickSubmit(match.params.orderId)}>{btnloading1 ? "Loading..." : "Save"}</button>
                            </div>

                          
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
        </Fragment>
    )
}

export default YourOrderDetails