import React, { useState, useEffect} from 'react'
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import {Link} from 'react-router-dom'
import {getOrders, updateOrdStatus, getStatusValues,updateUserSubscription} from './apiAdmin'
import { forwardRef } from 'react';
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Moment from 'react-moment';
import { Modal,Badge } from 'react-bootstrap'
import { STRIPE_PUBLIC, API } from "../config";
import { loadStripe } from '@stripe/stripe-js';

const ManageOrders = () => {

    const {user, token} = isAuthenticated()
    const [show, setShow] = useState(false);
    const [ temp, setTemp] = useState('');
    const [showdelayed, setShowDelayed] = useState(false);
    const [showCancelled, setShowCancelled] = useState(false);

    const [values3, setValues3] = useState({
        shipmentid:'',
        message:''
    })

    const { shipmentid, message} = values3;

    const [values4, setValues4] = useState({
        reason:'',
        resolution_time:''
    })

    const { reason, resolution_time} = values4;

    const [values5, setValues5] = useState({
        cancelled_reason:''
    })

    const { cancelled_reason} = values5;

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };

    var columns = [
        {title:"Id", field:"_id", hidden:true},
        {title: "Order ID", field: "orderid",render: rowData => {
            if(rowData.source){
                return <span><Link to={`/admin/order/details/${rowData._id}/${rowData.userid._id}`}>{rowData.orderid}</Link><br/><b>Source:&nbsp;{rowData.source}</b></span>
            }
            else {
                return <Link to={`/admin/order/details/${rowData._id}/${rowData.userid._id}`}>{rowData.orderid}</Link>
            }
        }},
        {title: "Name", field: "userid",render: rowData => <Link to={`/admin/user/details/${rowData.userid._id}`}>{rowData.userid.prefix}.{rowData.userid.name} {rowData.userid.lastname}</Link>},
        {title: "Date of Order", field: "createdAt", render: rowData => {return <Moment format='DD/MM/YYYY'>{rowData.createdAt}</Moment>}},
      
        {title: "Products Purchased", field: "title"},

        {title: "Price(£)", field: "price",render: rowData => {return (<><span><b>Purchase:</b> {rowData.price.toFixed(2)}</span><br/><span><b>Discount:</b> {rowData.discount.toFixed(2)}</span></>)}},
       
        {title: "Payment Status", field:"paymentId", render: rowData => { return ((rowData.status === "Awaiting Payment") || ((rowData.paymentId === "undefined") || (rowData.paymentId === "null") || (rowData.paymentId === undefined)) ? <span>UnPaid</span>
            : <><span>Paid</span><br/><span><b>Date:</b> <Moment format='DD/MM/YYYY'>{rowData.payment_date}</Moment></span></>)}},
       /*  {title:"Payment Date", field:"payment_date", render: rowData => {return ((rowData.status === "Awaiting Payment") || ((rowData.paymentId === "undefined") || (rowData.paymentId === "null") || (rowData.paymentId === undefined)) ? '' : <Moment format='DD/MM/YYYY'>{rowData.payment_date}</Moment>)}}, */
        {title: "Is Pre-Configuration", field: "pre_config",render: rowData => {
            return (rowData.pre_config === 1) ? <span style={{ color: "red", fontWeight: "bold" }}>No</span> :
            (rowData.status === "Customer Pre-configuration Complete" ||  rowData.status === "Safely Pre-configuration Complete" || rowData.status === "Shipped" || rowData.status === "Delivered" || rowData.status === "Delayed") ? <><span style={{ color: "green", fontWeight: "bold" }}>Yes</span><br/><b>Status:</b><br/><Badge pill variant="success">Completed</Badge></> :
            (rowData.status === "Awaiting Payment" || rowData.status === "Awaiting Customer Pre-configuration" ? <><span style={{ color: "green", fontWeight: "bold" }}>Yes</span><br/><b>Status:</b><Badge pill variant="danger">InComplete</Badge></> : <><span style={{ color: "green", fontWeight: "bold" }}>Yes</span><br/><b>Status:</b><br/><Badge pill variant="danger">{rowData.status}</Badge></>)
        }},
        /* {title: "Configuration Status", field: "status",render: rowData => {
            return (rowData.status === "Customer Pre-configuration Complete" ||  rowData.status === "Safely Pre-configuration Complete" || rowData.status === "Shipped" || rowData.status === "Delivered" || rowData.status === "Delayed") ? <p style={{ color: "green", fontWeight: "bold" }}>Completed</p> :
            (rowData.status === "Awaiting Payment" || rowData.status === "Awaiting Customer Pre-configuration" ? <p style={{ color: "red", fontWeight: "bold" }}>InComplete</p> : rowData.status) 
        }}, */
         {title: "Order Status", field: "status", render:rowData => { 
            if(rowData.status === "Awaiting Payment"){
                return <Badge pill variant="danger">{rowData.status}</Badge>
            }
            else if(rowData.status === "Awaiting Customer Pre-configuration"){
                return <Badge pill variant="warning">{rowData.status}</Badge>
            }
            else if(rowData.status === "Customer Pre-configuration Complete"){
                return <Badge pill variant="primary">{rowData.status}</Badge>
            }
            else if(rowData.status === "Safely Pre-configuration Complete"){
                return <Badge pill variant="primary">{rowData.status}</Badge>
            }
            else if(rowData.status === "Shipped"){
                return <Badge pill variant="info">{rowData.status}</Badge>
            }
            else if(rowData.status === "Delivered"){
                return <Badge pill variant="success">{rowData.status}</Badge>
            }
            else if(rowData.status === "Order Cancelled"){
                return <Badge pill variant="danger">{rowData.status}</Badge>
            }
            else if(rowData.status === "Delayed"){
                return <Badge pill variant="warning">{rowData.status}</Badge>
            }
            else if(rowData.status === "Return Completed"){
                return <Badge pill variant="success">{rowData.status}</Badge>
            }
            else {
                return <Badge pill variant="primary">{rowData.status}</Badge>
            }
        }}
    ]

        const [orders, setOrders] = useState([]);
        const [statusValues, setStatusValues] = useState([]);

        const [values, setValues] = useState({
            btnloading:true
        });
    
        const {btnloading} = values
    
    
        const loadOrders = () => {
            getOrders().then(data => {
                if (data.error) {
                    console.log(data.error);
                    setValues({...values, btnloading:false});
                } else {                 
                    setOrders(data);
                    setValues({...values, btnloading:false});
                }
            });
        };
    
        const loadStatusValues = () => {
            getStatusValues(user._id, token).then(data => {
                if (data.error) {
                    console.log(data.error);
                    setValues({...values,   btnloading:false});
                } else {
                    setStatusValues(data);
                }
            });
        };
    
        useEffect(() => {
            loadOrders();
            loadStatusValues();
        }, []);

        const handleChangeforShipment = name => event => {
            setValues3({ ...values3, [name]: event.target.value});
        }

        const clickSubmitShipment = (event) => {
            event.preventDefault();
            setValues3({...values3});
            updateOrdStatus(user._id, token,temp, {orderId:temp, status:"Shipped",shipmentid,message}).then(
                data => {
                    if (data.error) {
                        console.log("Status update failed");
                    } else {
                        setShow(() => setShow(false))
                        setTemp(() => setTemp(''));
                        setValues3({
                            ...values3,
                            shipmentid:'',
                            message:''
                        })
                        loadOrders();
                    }
                }
            ); 
    
        }

        const showModal = () => {
            return (
                <Modal show={show} onHide={() => setShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <form onSubmit={clickSubmitShipment}>
                        <div className="form-group">
                            <label className="text-muted">Shipment ID<span style={{color:"red"}}> *</span></label>
                            <input onChange={handleChangeforShipment('shipmentid')} type="text" className="form-control" value={shipmentid} required/>
                        </div>
                        <div className="form-group">
                            <label className="text-muted">Message<span style={{color:"red"}}> *</span></label>
                            <input onChange={handleChangeforShipment('message')} type="text" className="form-control" value={message} required/>
                        </div>
                        <button  className="btn btn-outline-primary">Add</button>
                    </form>
                    </Modal.Body>
    
                </Modal>
            )
        }

        const handleChangeforDelayed = name => event => {
            setValues4({ ...values4, [name]: event.target.value});
        }
    
        const clickSubmitDelayed = (event) => {
            event.preventDefault();
            setValues4({...values4});
            updateOrdStatus(user._id, token,temp, {orderId:temp, status:"Delayed",reason,resolution_time}).then(
                data => {
                    if (data.error) {
                        console.log("Status update failed");
                    } else {
                        setShowDelayed(() => setShowDelayed(false))
                        setTemp(() => setTemp(''));
                        setValues4({
                            ...values4,
                            reason:'',
                            resolution_time:''
                        })
                        loadOrders();
                    }
                }
            );
    
        }
    
        const showDelayModal = () => {
            return (
                <Modal show={showdelayed} onHide={() => setShowDelayed(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <form onSubmit={clickSubmitDelayed}>
                        <div className="form-group">
                            <label className="text-muted">Reason<span style={{color:"red"}}> *</span></label>
                            <input onChange={handleChangeforDelayed('reason')} type="text" className="form-control" value={reason} required/>
                        </div>
                        <div className="form-group">
                            <label className="text-muted">Resolution Time<span style={{color:"red"}}> *</span></label>
                            <input onChange={handleChangeforDelayed('resolution_time')} type="number" className="form-control" value={resolution_time} required/>
                        </div>
                        <button  className="btn btn-outline-primary">Add</button>
                    </form>
                    </Modal.Body>
    
                </Modal>
            )
        }

        const handleChangeforCancelled = name => event => {
            setValues5({ ...values5, [name]: event.target.value});
        }

        const clickSubmitCancelled = (event) => {
            event.preventDefault();
            setValues5({...values5});
            updateOrdStatus(user._id, token,temp, {orderId:temp, status:"Order Cancelled",cancelled_reason:cancelled_reason}).then(
                data => {
                    if (data.error) {
                        console.log("Status update failed");
                    } else {
                        setShowCancelled(() => setShowCancelled(false))
                        setTemp(() => setTemp(''));
                        setValues5({
                            ...values5,
                            cancelled_reason:''               
                        })
                        loadOrders();
                    }
                }
            );
    
        }
    
        const showCancelModal = () => {
            return (
                <Modal show={showCancelled} onHide={() => setShowCancelled(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <form onSubmit={clickSubmitCancelled}>
                        <div className="form-group">
                            <label className="text-muted">Reason<span style={{color:"red"}}> *</span></label>
                            <input onChange={handleChangeforCancelled('cancelled_reason')} type="text" className="form-control" value={cancelled_reason} required/>
                        </div>
                        
                        <button  className="btn btn-outline-primary">Continue</button>
                    </form>
                    </Modal.Body>
    
                </Modal>
            )
        }  


         const handleStatusChange = async (e, orderId, paymentId, subscription_type) => {
            if(e.target.value !== "Update Status"){
                if(window.confirm("Do you want to change this status?"))
                {
                    var tempPayID="";
                   
                    if(e.target.value === "Shipped") {
                        setShow(() => setShow(true));
                        setTemp(() => setTemp(orderId));
                        return 0;
                    }   
                    else if(e.target.value === "Delayed") {
                        setShowDelayed(()=>setShowDelayed(true));
                        setTemp(() => setTemp(orderId));
                        
                        return 0;
                    }
                    else if(e.target.value === "Order Cancelled") {
                        setShowCancelled(()=>setShowCancelled(true));
                        setTemp(() => setTemp(orderId));
                        if((paymentId!=="") && (paymentId.includes("sub_")) && (subscription_type === 1 || subscription_type === 0))
                        {     

                            const Customerresponse2 = await fetch(`${API}/all-invoices/subscriptionId`, {
                                method:'POST',
                                headers: {'Content-Type': 'application/json',},
                                body: JSON.stringify({ 
                                'subscriptionId': String(paymentId)        
                                })
                            });  
                            const allInvoices = await Customerresponse2.json();
                           
                            
                            var getInvoices = allInvoices.data[0];
                            tempPayID = getInvoices.payment_intent;
                           
                            const response = await fetch(`${API}/cancel-subscription`, {
                            method:'POST',
                            headers: {'Content-Type': 'application/json',},
                            body: JSON.stringify({ 
                                'paymentid': String(paymentId)        
                                })
                            });
                            
                            const deleted = await response.json();
                            updateUserSubscription(user._id,token,{orderId:orderId});
                          
                            const refund = await fetch(`${API}/refund`, {
                                method:'POST',
                                headers: {'Content-Type': 'application/json',},
                                body: JSON.stringify({ 
                                'paymentintentid': String(tempPayID)   
                                })
                            });  
                            const refunddetails = await refund.json();
                           
                          }
                          else if((paymentId !=="" && paymentId !== undefined && paymentId !== "null" && paymentId !== "undefined") && (subscription_type !== 1 || subscription_type !== 0)){
                            const refund = await fetch(`${API}/refund`, {
                              method:'POST',
                              headers: {'Content-Type': 'application/json',},
                              body: JSON.stringify({ 
                                'paymentintentid': String(paymentId)   
                                })
                            });  
                            const refunddetails = await refund.json();
                           
                          }
                        return 0;   
                    }
                    updateOrdStatus(user._id, token, orderId, {orderId:orderId, status:e.target.value}).then(
                        data => {
                            if (data.error) {
                                console.log("Status update failed");
                            } else {
                                loadOrders();
                            }
                        }
                    );
                }
            }
        }; 
    
        const showStatus = (o,status1,paymentId1,sub_type1) => (
            <div className="form-group">


        {(status1 === "Awaiting Payment") && (<select
                className="form-control"
                onChange={e => handleStatusChange(e, o,paymentId1,sub_type1)}
            > <option>Update Status</option>
            {
                statusValues.map((status, index) => (
                (status1 === status ? <option selected key={index}>{status}</option> : (
                    status === "Order Cancelled" && <option  key={index} value={status}>{status}</option>
                ))
                
            ))}
        </select>)}
            
        {(status1 === "Awaiting Customer Pre-configuration") && (<select
                className="form-control"
                onChange={e => handleStatusChange(e, o,paymentId1,sub_type1)}
            > <option>Update Status</option>
            {
                statusValues.map((status, index) => (
                (status1 === status ? <option selected key={index} >{status}</option>
                  : ((status !== "Awaiting Payment" && status !== "Customer Pre-configuration Complete") && <option key={index} value={status}>
                  {status}
              </option>) )
                
            ))}
        </select>)}

        {(status1 === "Safely Pre-configuration Complete") && (<select
                className="form-control"
                onChange={e => handleStatusChange(e, o,paymentId1,sub_type1)}
            > <option>Update Status</option>
            {
                statusValues.map((status, index) => (
                (status1 === status ? <option selected key={index}>{status}</option>
                  : ((status !== "Awaiting Payment" && status !== "Awaiting Customer Pre-configuration" && status!== "Customer Pre-configuration Complete") && <option key={index} value={status}>
                  {status}
              </option>) )
                
            ))}
        </select>)}

        {(status1 === "Customer Pre-configuration Complete") && (<select
                className="form-control"
                onChange={e => handleStatusChange(e, o,paymentId1,sub_type1)}
            > <option>Update Status</option>
            {
                statusValues.map((status, index) => (
                (status1 === status ? <option selected key={index}>{status}</option>
                  : ((status !== "Awaiting Payment" && status !== "Awaiting Customer Pre-configuration" ) && <option key={index} value={status}>
                  {status}
              </option>) )
                
            ))}
        </select>)}

        {(status1 === "Shipped") && (<select
                className="form-control"
                onChange={e => handleStatusChange(e, o,paymentId1,sub_type1)}
            > <option>Update Status</option>
            {
                statusValues.map((status, index) => (
                (status1 === status ? <option selected key={index} >{status}</option>
                  : ((status !== "Awaiting Payment" && status !== "Awaiting Customer Pre-configuration" && status !== "Customer Pre-configuration Complete" && status !== "Safely Pre-configuration Complete") && <option key={index} value={status}>
                  {status}
              </option>) )
                
            ))}
        </select>)}

        {(status1 === "Delivered") && (<select
                className="form-control"
                onChange={e => handleStatusChange(e, o,paymentId1,sub_type1)}
            > <option>Update Status</option>
            {
                statusValues.map((status, index) => (
                (status1 === status ? <option selected key={index} >{status}</option>
                  : ((status !== "Awaiting Payment" && status !== "Awaiting Customer Pre-configuration" && status !== "Customer Pre-configuration Complete" && status !== "Safely Pre-configuration Complete" && status !== "Shipped" && status!=="Delayed") && <option key={index} value={status}>
                  {status}
              </option>) )
                
            ))}
        </select>)}

        {(status1 === "Order Cancelled") && (<select
                className="form-control"
                onChange={e => handleStatusChange(e, o,paymentId1,sub_type1)}
            > <option>Update Status</option>
            {
                statusValues.map((status, index) => (
                (status1 === status && <option selected key={index} >{status}</option>
                  )
                
            ))}
        </select>)}

        {(status1 === "Delayed") && (<select
                className="form-control"
                onChange={e => handleStatusChange(e, o,paymentId1,sub_type1)}
            > <option>Update Status</option>
            {
                statusValues.map((status, index) => (
                (status1 === status ? <option selected key={index} >{status}</option>
                  : ((status !== "Awaiting Payment" && status !== "Awaiting Customer Pre-configuration" && status !== "Customer Pre-configuration Complete" && status !== "Safely Pre-configuration Complete") && <option key={index} value={status}>
                  {status}
              </option>) )
                
            ))}
        </select>)}

            </div>
        );
 

    return (
        <AdminLayout topTitle="Manage Orders">

            <div className="row">     
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                        <h4 className="card-title" style={{textAlign:'center'}}>Manage Orders</h4>

                                    
                                   {showModal()}
                                   {showDelayModal()}
                                   {showCancelModal()}

                                    <MaterialTable
                                        title={"Orders"}
                                        isLoading={btnloading}
                                        columns={columns}
                                        data={orders}
                                        icons={tableIcons}                             
                                        options={{
                                            pageSize:10                                            
                                        }}
                                        localization={{ body:{ emptyDataSourceMessage:<h6>No orders to display</h6> } }}
                                        />
                                                                                                                                                                   
                        </div>
                    </div>
                </div>
            </div>
            
        </AdminLayout>
    )
};

export default ManageOrders;