import React, {useState} from 'react'
import Subscribe from './Subscribe'
import { createContact} from './Priorities/apiUser'
import NewLayout from './NewLayout'

const ContactUs = () => {

      const [values, setValues] = useState({
        name: '',
        email: '',
        message: '',
        error: '',
        success: false
      });

      const {name, email, message, success, error} = values

      const handleChange = name => event => {
          setValues({ ...values, error: false, [name]: event.target.value});
      };

      const clickSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: false });
        createContact({name, email, message})
        .then(data => {
            if(data.error) {
                setValues({...values, error: data.error, success:false})
            } else {
                setValues({
                    ...values,
                    name:'',
                    email: '',
                    message: '',
                    error: '',
                    success: true
                })
            }
        })
    }
 
    const contactForm = () => {
      return (

        <div className="col-sm-12 pt-5 pb-5 px-0">
          <form onSubmit={clickSubmit}>
          <div className="form-group contact-wid">
            <label for="usr">Name:<span style={{color:"red"}}> *</span></label>
            <input onChange={handleChange('name')} value={name} type="text" className="form-control bord-cont-rad" id="usr" required/>
          </div>
          <div className="form-group contact-wid">
            <label for="usr">Email:<span style={{color:"red"}}> *</span></label>
            <input onChange={handleChange('email')} value={email} type="email" className="form-control bord-cont-rad" id="usr" required/>
          </div>
          <div className="form-group contact-wid">
            <label for="usr">Message:<span style={{color:"red"}}> *</span></label>
            <textarea onChange={handleChange('message')} value={message} className="form-control bord-cont-rad" rows="5" required id="comment"></textarea>
          </div>
          <div className="form-group text-right contact-wid">
          <button  className="btn btn-success" data-toggle="modal" data-target="#send-request">Submit</button>
          </div>
          </form>

      </div>

      )
    }

    const showError = () => (
      <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
          {error}
      </div>
  );

  const showSuccess = () => (
    <div className="alert alert-success" style={{ display: success ? '' : 'none' }}>
        <p>Thank you for contacting us – we will get back to you soon!</p>
    </div>
);

    return (
        <NewLayout topTitle="Contact Us | 24/7 Home Security Solutions | SafelyTeam" metaDescription="Have a question regarding home security systems and how it works? We are happy to answer. Contact us and get all the answers to your queries and doubts.">
            
                
                        <div className="section pp-scrollable slide-dark slide-dark-footer">                        
                       
                        <div className="col-md-12 px-0 inner-tp-pad inner-safely">
                          <div className="container">
                            <div className="col-md-10 inner-header">
                              <h1 className="white-inner-txt">Have a Question? </h1>
                              <p className="white-inner-p tp-menu-show"><a className="btn btn-success" id="chat-team" data-toggle="modal" data-target="#send-request">Chat with our Team</a></p>
                            </div>
                          </div>
                          <img src={require('./img/cont-bg.png')} className="img-fluid" alt="Remote monitoring home security systems for kids and elderlies" /> </div>

                        <div className="col-md-12 inner-safely">
                          <div className="container">
                            <div className="row ptb-inner">
                            <div className="col-md-6 padd-top-blog-sin">
                              <div className="col-md-12 px-0">
                              <h3>Write to us</h3>
                              </div>
                              <div className="col-md-12 px-0">

                            
                              {contactForm()}
                              {showSuccess()}
                              {showError()}
                            
                            </div>
                          </div>
                            <div className="col-md-6 padd-top-blog-sin">
                              <div className="col-md-12 px-0">
                              <h3>Contact Information</h3>
                              </div>
                              <div className="col-md-12 px-0">
                              {/* <p className="pt-5" style={{lineHeight: '2rem;'}}> 10-16 Grape Street,<br></br>
                                London, Greater London,<br></br>
                                WC2H 8DY, GB
                                </p> */}
                               <p style={{lineHeight: '2rem', fontSize:'15px'}}>SafelyTeam Ltd,<br/>
                                  130 Aztec West,<br/>
                                  Bristol,<br/>
                                  BS32 4UB</p>
                               <p style={{lineHeight: '3rem', fontSize:'15px'}}> 
                                    <i className="ti-mobile">&nbsp;</i><a href="tel:0808 501 5388">0808 501 5388</a>&nbsp;&nbsp;&nbsp;<br/>
                                    <i className="ti-email">&nbsp;</i><a href="mailto:hello@safelyteam.com">hello@safelyteam.com</a>
                                </p>
                              </div>
                            </div>
                          </div>
                             
                          </div>
                                              </div>
                            <div className="row ptb-inner">
                                  <div className="col-md-4 px-0 pt-5"> <img src={require('./img/build-btm.jpg')} className="img-fluid" alt="Home Security Solution Provider | Best Smoke Detector For Smart Homes" /> </div>
                                  <div className="col-md-4 px-0 pt-5"> </div>
                                  <div className="col-md-4 px-0 pt-5"> <img src={require('./img/right-man.jpg')} className="img-fluid" alt="Safely Outdoor Image - Buy Home Security Kits " /> </div>
                                </div>
   
                            <Subscribe />

                        </div>
                   
          
        </NewLayout>
    )

}
export default ContactUs