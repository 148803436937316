import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Subscribe from './Subscribe'
import {getAllProducts} from './Priorities/apiUser'
import ShowImage from '../../admin/ShowImage'
import NewLayout from './NewLayout'

const Safety = () => {

  const [safeproducts, setSafeProducts] = useState([]);

  const [values, setValues] = useState({
    btnloading:true
  });

  const {btnloading} = values

  const initSafe = ids => {
    getAllProducts(ids).then(data => {
      if (data.error) {
          
          console.log(data.error);
          setValues({...values,   btnloading:false});
      } else {
          // populate the state
          setSafeProducts(data);       
          setValues({...values,   btnloading:false});   
      }
    });
  };  

  useEffect(() => {
    initSafe(showAllProducts());
 }, [])

 const showAllProducts = (Id) => {
   let list = ["5f7b2f0bffa2800728e971bf","5f7b2ec3ffa2800728e971bd","5f7b2dc6ffa2800728e971b7","5f7b2e6fffa2800728e971bb","5f7b2f74ffa2800728e971c1","5f7b2e1effa2800728e971b9","5f7b2fc3ffa2800728e971c3","5f7b302bffa2800728e971c5"];
   return list;
 }

 const showProducts = () => {
   return (
        <div className="row ptb-inner">
           {(btnloading) ? <div className='loader-container'><div className='loader'></div></div> : ""}  
            
            {safeproducts.map((p,i) => (
    
            <div className="col-md-6 mb-2-btm pl-0 plr-0px book">
                <div className="col-md-12 box-bord-gr info">
                    <div className="row pt-8-per-but">

                      <div className="col-md-12 py-5 px-5">
                          <Link className="btn btn-primary mr-2 mb-3" key={i} to={`/product-details/${p._id}`}>See Details</Link>
                        </div>
                    </div>
                </div>


         <div className="col-md-12 box-bord-gr image">
             <div className="row">
                 <div className="col-md-5"> <ShowImage key={i} item={p._id} /> </div>
                 <div className="col-md-7 py-5 px-5 text-mini-mbile">
                     <h3 className="font-hd-on" key={i}>{p.name}</h3>
                     <p className="inner-line" key={i}>{p.description}</p>
                 </div>
             </div>
         </div>
     </div>
                        
 ))}
          



          <div className="col-md-12 text-center shop-btn">
            <a className="btn btn-success" href="/step1">Shop the SafelyTeam Packages</a>
          </div>
      
      </div>
   )
 }
 
    return (
      
        <NewLayout topTitle="SafelyTeam – Safety">
            
                
                    <div className="section pp-scrollable slide-dark slide-dark-footer">
    
      <div className="col-md-12 px-0 inner-tp-pad inner-safely">
        <div className="container">
          <div className="col-md-10 inner-header">
            <h1 className="white-inner-txt">Safety </h1>
            <p className="white-inner-p">Protect Your Home and Family​</p>
          </div>
        </div>
        <img src={require('./img/safety-header.jpg')} className="img-fluid" alt="" /> </div>
      <div className="col-md-12 inner-safely">
        <div className="container ptb-inner">
          <div className="row">
            <div className="col-md-6 inner-para-pad">
              <h3 className="pb-3">Protect Your Home and Family​</h3>
              <p className="inner-line">Caring for your home and family is our number one priority. In addition to our security offering, our Safety solution helps to prevent accidental damage such as fire and water leaks.​
</p>
            </div>
            <div className="col-md-6"><img src={require('./img/mom-girl.jpg')} className="img-fluid" alt="" /></div>
          </div>
          <div className="row ptb-inner">
            <div className="col-md-12 px-0 pt-5">
              <h3 className="pb-0">How SafelyTeam provides Safety for your home</h3>
              <p className="inner-line">Our Safety system constantly monitors your environment for hazards including smoke, fire, water leaks and air quality levels to ensure that you remain safe in your home. We pre-emptively provide suggestions and tips to improve your environment before anything gets out of hand.​
</p>
            </div>
             <div className="col-md-12 px-0 pt-2">
              <h3 className="pb-0">Products​</h3>
              <p className="inner-line">Our safety system is compatible with many devices to provide an all-round, safe smart home experience. Devices currently available under our Safety solution are:​
</p>
            </div>
          </div>

      {showProducts()}


          <div className="row ptb-inner text-mini-mbile mb-know">
            <h2 className="font-in-head inner-safely">Know It, Trust It.<br></br>
              Explore the use cases </h2>
          </div>
          <div className="row ptb-inner px-0">
            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
              <h3 className="alerts-txt">Instant<br></br>
                Alerts </h3>
              <p className="inner-line">Be prepared for whatever life throws your way. Our ​

smart sensors alert you instantly to any signs of danger​

so you can rest easy knowing your home is fully protected. ​</p>
            </div>
            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/alerts-img.jpg')} className="img-fluid" alt="" /> </div>
            <div className="col-md-6 mb-5 pl-0 plr-0px tp-menu-show"> <img src={require('./img/child.jpg')} className="img-fluid" alt="" /> </div>
            <div className="col-md-6 pr-ann-left al-tp mb-5 plr-0px text-mini-mbile">
              <h3 className="alerts-txt">Optimise Your​<br></br>
                Smart Home​ </h3>
              <p className="inner-line">Our fully connected Safety Solutions are constantly ​

monitoring your home environment. This not only ​

helps you to protect your home from harm, ​

but allows you to create even smarter automation rules ​

and scenes – making your home work better for you.​</p>
            </div>
            <div className="col-md-6 mb-5 pl-0 plr-0px sh-img"> <img src={require('./img/child.jpg')} className="img-fluid" alt="" /> </div>
            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
              <h3 className="alerts-txt">Fully protected, <br></br>even when away​ </h3>
              <p className="inner-line">From anywhere in the world, receive instant app notifications of threats to your home. <strong>Our alarm monitoring centre will also be in contact with you immediately to guide you on dealing with any threats to your home such as a fire, flood or assistance alarm.</strong>
</p>
            </div>
            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/safe-home.jpg')} className="img-fluid" alt="" /> </div>
            <div className="col-md-12 text-center ptb-inner">

              <a className="btn btn-primary mr-2 mb-3" href="/step1">Design your personalised system<i className="lni lni-chevron-right"></i></a>
            </div>
          </div>
          <div className="row ptb-inner">
            <div className="col-md-4 px-0 pt-5"> <img src={require('./img/build-btm.jpg')} className="img-fluid" alt="" /> </div>
            <div className="col-md-4 px-0 pt-5"> </div>
            <div className="col-md-4 px-0 pt-5"> <img src={require('./img/right-man.jpg')} className="img-fluid" alt="" /> </div>
          </div>


        </div>
      </div>
    <Subscribe />
    </div>
                     
          
        </NewLayout>
    )

}
export default Safety