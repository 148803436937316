import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Subscribe from './Subscribe'
import {getAllProducts} from './Priorities/apiUser'
import ShowImage from '../../admin/ShowImage'
import NewLayout from './NewLayout'

const Wellness = () => {

  const [wellproducts, setWellProducts] = useState([]);

    const [values, setValues] = useState({
      btnloading:true
    });

    const {btnloading} = values

    const initWellness = ids => {
      getAllProducts(ids).then(data => {
        if (data.error) {  
            console.log(data.error);
            setValues({...values,   btnloading:false});
        } else {
            // populate the state
            setWellProducts(data);    
            setValues({...values,   btnloading:false});      
        }
      });
    };  

    useEffect(() => {
      initWellness(showAllProducts()); 
   }, [])
  
   const showAllProducts = (Id) => {
     let list = ["5f7b2f0bffa2800728e971bf","5f7b2ec3ffa2800728e971bd","5f7b2dc6ffa2800728e971b7","5f7b2e1effa2800728e971b9","5f7b2fc3ffa2800728e971c3"];
     return list;
   }

   const showProducts = () => {
     return (
      <div className="row ptb-inner">
        {(btnloading) ? <div className='loader-container'><div className='loader'></div></div> : ""}  
          
          {wellproducts.map((p,i) => (

          <div className="col-md-6 mb-2-btm pl-0 plr-0px book">
              <div className="col-md-12 box-bord-gr info">
                  <div className="row pt-8-per-but">

                    <div className="col-md-12 py-5 px-5">
                        <Link className="btn btn-primary mr-2 mb-3" key={i} to={`/product-details/${p._id}`}>See Details</Link>
                      </div>
                  </div>
              </div>


              <div className="col-md-12 box-bord-gr image">
                  <div className="row">
                      <div className="col-md-5"> <ShowImage key={i} item={p._id} /> </div>
                      <div className="col-md-7 py-5 px-5 text-mini-mbile">
                          <h3 className="font-hd-on" key={i}>{p.name}</h3>
                          <p className="inner-line" key={i}>{p.description}</p>
                      </div>
                  </div>
              </div>
          </div>
                    
))}
     

          <div className="col-md-6 text-center shop-btn">
            <a className="btn btn-success" href="/step1">Shop the SafelyTeam Packages</a>
          </div>
     
     
     
     </div>
     )
   }

 
    return (
        <NewLayout topTitle="SafelyTeam – Wellness">
            
            
                        <div className="section pp-scrollable slide-dark slide-dark-footer">                        
                        <div className="col-md-12 px-0 inner-tp-pad inner-safely">
        <div className="container">
          <div className="col-md-10 inner-header">
            <h1 className="white-inner-txt">Wellness </h1>
            <p className="white-inner-p">Care for your loved ones and living environment​</p>
          </div>
        </div>
        <img src={require('./img/wellness-header.png')} className="img-fluid" alt="" /> </div>
      <div className="col-md-12 inner-safely">
        <div className="container ptb-inner">
          <div className="row">
            <div className="col-md-6 inner-para-pad">
              <h3 className="pb-3">Keeping an eye on loved ones.​</h3>
              <p className="inner-line">Maintaining a healthy body and mind is crucial to living a happy life. Our busy lives mean we lose time to look after ourselves and our family. Through the power of connected technology, we can make caring for ourselves and family just that one step easier.​<br></br>
SafelyTeam’s wellness solutions have been specially engineered so you can monitor you and your family's well-being from the touch of a button. From monitoring the air you breathe, temperature of your rooms, panic alerts and even being able to monitor if your parents have taken their medicine from their kitchen cabinets - our wellness solution has you covered. ​

</p>
            </div>
            <div className="col-md-6"><img src={require('./img/mom-girl.jpg')} className="img-fluid" alt="" /></div>
          </div>
          <div className="row ptb-inner">
            <div className="col-md-10 px-0 pt-5">
              <h3 className="pb-3">Products​</h3>
              <p className="inner-line">Our wellness system looks after you and your environment and provides peace of mind for your family. It can be used to create advanced home automation rules and convenience features, to simplify your day-to-day life. Check out our product range below.​

</p>
            </div>
          </div>

          {showProducts()}
          <div className="row ptb-inner text-mini-mbile mb-know">
            <h2 className="font-in-head inner-safely">Understand It<br></br>
              Better With Use Cases</h2>
          </div>
          <div className="row ptb-inner px-0">
            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
              <h3 className="alerts-txt">24/7 Environmental <br></br>Monitoring</h3>
              <p className="inner-line">We spend 90% of our lives inside and with indoor air often up to 5x more polluted than outdoor air, it is important to keep the air we breathe as clean as possible. Our air quality sensor monitors for harmful airborne compound levels known as VOCs and can suggest when to ventilate your home. ​</p>
            </div>
            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/alerts-img.jpg')} className="img-fluid" alt="" /> </div>
            <div className="col-md-6 mb-5 pl-0 plr-0px tp-menu-show"> <img src={require('./img/personal-care.png')} className="img-fluid" alt="" /> </div>
            <div className="col-md-6 pr-ann-left al-tp mb-5 plr-0px text-mini-mbile">
              <h3 className="alerts-txt">Ultimate <br></br>Peace of Mind</h3>
              <p className="inner-line">Take care of loved ones and grant them independence with SafelyTeam's Panic Button. Whether walking around their home or garden, our Panic Buttons can alert you to any emergencies they may face.​
</p>
            </div>
            <div className="col-md-6 mb-5 pl-0 plr-0px sh-img"> <img src={require('./img/personal-care.png')} className="img-fluid" alt="" /> </div>
            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
              <h3 className="alerts-txt">Stay Connected <br></br>with Family </h3>
              <p className="inner-line">All our wellness solutions include a Motion and Contact sensor as standard. These can be used to monitor the activity of loved ones without intruding on their privacy.  For example, monitor medicine use or keep up with routines using  our unique Wellness solution.​
</p>
            </div>
            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/weelness-babies.png')} className="img-fluid" alt="" /> </div>
            <div className="col-md-12 text-center ptb-inner">              
              <a className="btn btn-primary mr-2 mb-3" href="step1">Design your personalised system<i className="lni lni-chevron-right"></i></a>
            </div>
          </div>
          <div className="row ptb-inner">
            <div className="col-md-4 px-0 pt-5"> <img src={require('./img/build-btm.jpg')} className="img-fluid" alt="" /> </div>
            <div className="col-md-4 px-0 pt-5"> </div>
            <div className="col-md-4 px-0 pt-5"> <img src={require('./img/right-man.jpg')} className="img-fluid" alt="" /> </div>
          </div>


        </div>
      </div>
   
                            <Subscribe />

                        </div>
                        
                    </NewLayout>
    )

}
export default Wellness