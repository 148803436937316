import React, {Fragment, useEffect, useState} from 'react'
import { isAuthenticated } from '../../auth/index'
import Navbar from '../admin/Navbar'
import Sidebar from './Sidebar'
import {Helmet} from 'react-helmet'
import {getCardDetailsById, getUser} from './Priorities/apiUser'
import { API } from "../../config";
import { Badge } from 'react-bootstrap'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const ManageSubscriptionCard = () => {
    const { user, token} = isAuthenticated();

    const [cards, setCards] = useState([]);

    const [loading, setLoading] = useState(true);

    const [subId, setSubId] = useState("");

    const [paymentMethodID, setPaymentMethodID] = useState("");

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const init = userId => {
        getUser(userId,token).then(data => {
            if (data.error) {
                console.log("error");
            } else {
               console.log(data.subscriptionid);
               setSubId(data.subscriptionid)
               checkStatus(data.subscriptionid);
               loadCards();
            }
        });
    };

    const loadCards = () => {
        getCardDetailsById({userId:user._id}).then(data => {
            if(data.error){
                console.log(data.error);
                setLoading(false);
                setOpen(false)
            }
            else {
                setCards(data);
                setLoading(false);
                setOpen(false)
            }
        })
    }

    useEffect(() => {
        init(user._id);
    },[])

    const checkStatus = async (subId) => {

        const getSub = await fetch(`${API}/get-subscription`, {
            method:'POST',
            headers: {'Content-Type': 'application/json',},
            body: JSON.stringify({ 
                paymentid: subId,
                userid: user._id
              })
          });
          
          const subRes = await getSub.json();
          console.log(subRes);
          console.log("PM",subRes.default_payment_method)
          setPaymentMethodID(subRes.default_payment_method);
    }

    const showStatus = (Id) => {
        console.log("ID",Id);
        if(Id === paymentMethodID)
        {
            return <span><b>Status:</b>&nbsp;<Badge pill variant="success">Active</Badge></span>
        }
        else{
            return <span onClick={() => changeStatus(Id)} style={{cursor:'pointer'}}><Badge pill variant="primary">Make Default</Badge></span>
        }
    }

    const changeStatus = async (id) => {
           
        setOpen(true);
        const updateSubStatus = await fetch(`${API}/update/card/subscription/status`, {
            method:'POST',
            headers: {'Content-Type': 'application/json',},
            body: JSON.stringify({ 
                payment_method: id,
                userid: user._id,
                subscriptionId: subId
              })
          });
          
          const substatusRes = await updateSubStatus.json();
          console.log(substatusRes);
          if(substatusRes.status === "active"){
              init(user._id);
          }
          
    }

    const showCards = () => {
        return (
            <div className="row">
          
                {cards.map((p,i) => ( 
                    <div className="col-md-6 px-0 mb-3">
                      <div className="col-md-8 bord-line">

                        <div className="row">
                           
                            <div className="col-md-8 py-3 wid-60-per-in">

                                <h4 className="pb-3 color-blk-in font-one-re"></h4>
                                <p className="pb-0 mb-0">
                               
                                <b>Card No:</b>&nbsp;xxxx xxxx xxxx {p.card_no}<br/>
                                <b>Exp month/year:</b>&nbsp;{p.exp_month} / {p.exp_year}<br/>
                                <b>Brand:</b>&nbsp;{p.brand}<br/>
                                {showStatus(p.payment_method)}

                                </p>
                            </div>
                            <div className="col-md-3 pt-3 text-right">
                               {/*  <p className="font-one-edit"><Link to={`/user/updateaddress/${p._id}/recentaddress`}>Edit</Link> | <span style={{cursor:'pointer'}} onClick={() => destroy(p._id)}>Delete</span></p> */}
                            </div>
                        </div>
                    </div>
                </div>

                
                ))}
        </div>
        )
    }

    const showNotfound = () => {
        return (

            <div className="col-md-12 px-0">
                  <div className="col-md-12" style={{backgroundColor: '#f5f5f5', border: 'solid 1px #e7e7e7', borderTop: '0px'}}>
                      <br/>
                       <h4 style={{textAlign:'center',color:'red'}}>Card Not found!!</h4>
                  </div>
              </div>

        )
    }

    return (
        <Fragment>
            <Helmet>
                <title>Safely - Manage Cards</title>
            </Helmet>
             <div className="container-scroller">
                <Navbar />
                
                <div className="container-fluid page-body-wrapper">
    
                <Sidebar />

                        <div className="main-panel">
                          <div className="content-wrapper white-bg-ad">
                            <div className="row">
                              <div className="col-md-12 grid-margin">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <h4 className="font-weight-bold mb-0">Manage Cards</h4>
                                    
                                  </div>
                                <div>  
                              </div>
                            </div>
                          </div>
                        </div>

                        {(loading) ? <div className='loader-container'><div className='loader'></div></div> : ""}  
                                    
                        {!loading && (cards.length>=1 ? showCards() : showNotfound())}

                        <Backdrop className={classes.backdrop} open={open} >
                        <CircularProgress color="inherit" />
                    </Backdrop>     
                      </div> 
                    </div>

                </div>
                

        </div>

     
        </Fragment>
    )
}

export default ManageSubscriptionCard
