import React,{useState, Fragment} from 'react'
import { STRIPE_PUBLIC, API } from "../../config";
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import CardSection from './Priorities/CardSection';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import {isAuthenticated} from '../../auth/index'
import { createCardDetail} from './Priorities/apiUser'
import Navbar from '../admin/Navbar'
import Sidebar from './Sidebar'
import {Helmet} from 'react-helmet'

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));


const AddCardCall = () => {

    const stripe = useStripe();
    const elements = useElements();
    const { user,token } = isAuthenticated();
    const classes = useStyles();
    const [error, setError] = useState("")
    const [open, setOpen] = useState(false);

    const clickSubmit = async (event) => {
        event.preventDefault();
        setOpen(true);
        let customerId="";
        let paymentMethodId="";
        let secretId="";
        let charges;
        let card;
        let customerName = user.name+' '+user.lastname;

        if(user.customerid){
            console.log("Existing User");
            customerId = user.customerid;

            const createSetupIntent = await fetch(`${API}/card-wallet`, {
                method:'POST',
                headers: {'Content-Type': 'application/json',},
                body: JSON.stringify({ 
                    customer: String(customerId),
                    userid: String(user._id)
                })
            });
              
              const setupSession = await createSetupIntent.json(); 
              console.log("SetupSession",setupSession); 

              secretId=setupSession.client_secret;

            if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return;
            }

            const result = await stripe.confirmCardSetup(String(secretId), {
                payment_method: {
                  card: elements.getElement(CardElement),
                  billing_details: {
                    name: customerName,
                  },
                }
            });
    
            console.log("CardResult",result);
            let tempRes = result.setupIntent;
           
            if (result.error) {
                setOpen(false);
                setError(result.error.message);
            } else {
                // The setup has succeeded. Display a success message and send
                // result.setupIntent.payment_method to your server to save the
                // card to a Customer'
                paymentMethodId = tempRes.payment_method;
    
                const getPaymentMethod = await fetch(`${API}/get/payment-method/id`, {
                    method:'POST',
                    headers: {'Content-Type': 'application/json',},
                    body: JSON.stringify({                    
                        payment_method:paymentMethodId,
                        userid: String(user._id)
                    })
                });

                const resultPM = await getPaymentMethod.json();
                console.log("Res Payment Session",resultPM);
                  
                card = resultPM.card
                console.log("Charges.data 2",card); 
    
                if(resultPM){
                      console.log("Payment done");
                      const updateCard = await createCardDetail({
                          userId: user._id,
                          card_no: card.last4,
                          payment_method: paymentMethodId,
                          exp_year : card.exp_year,
                          exp_month: card.exp_month,
                          brand: card.brand
                        })
    
                        if(updateCard){
                            console.log("Card Added");
                            return window.location.href=`/user/manage/cards`;
                        }  
                }

            }
        }
       
    }

    const showError = () => {
        if(error) {
            return <h3 className="text-danger">{error}</h3>
        }
    };


    return (
        <Fragment>

            <Helmet>
                <title>SafelyTeam - Add Card</title>
            </Helmet>

                <div className="container-scroller">
                <Navbar />
                
                <div className="container-fluid page-body-wrapper">
    
                <Sidebar />

                        <div className="main-panel">
                          <div className="content-wrapper white-bg-ad">
                            <div className="row">
                              <div className="col-md-12 grid-margin">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <h4 className="font-weight-bold mb-0">Add Card</h4>
                                  </div>
                                <div>  
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={clickSubmit}>
                                    <CardSection />
                                    <br/>
                                    <button className="btn btn-outline-primary" disabled={!stripe}>Add Card</button>
                                </form>
                                {showError()}
                            </div>
                        </div>        
                            <Backdrop className={classes.backdrop} open={open} >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                      </div> 
                    </div>

                </div>
        </div>

       
       
       
       </Fragment>          
                        
    )
}

export default AddCardCall
