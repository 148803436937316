import React,{useEffect} from 'react'
import useGTM from '@elgorditosalsero/react-gtm-hook'
import CookieConsent, { Cookies } from "react-cookie-consent";
import { hotjar } from 'react-hotjar';
const Footer = () => {
  const { init, UseGTMHookProvider } = useGTM()
 

  useEffect(() => {

   
    let val = Cookies.get('AcceptCookie');
   
    /* console.log("Val1",val) */
    if(String(val) === "false"){
      Cookies.remove("_hjid");
      Cookies.remove("_hjFirstSeen");
      Cookies.remove("_ga");
      Cookies.remove("_gid");
      Cookies.remove("_gcl_au");
      Cookies.remove("_hjIncludedInPageviewSample");
      Cookies.remove("_hjAbsoluteSessionInProgress");
      Cookies.remove("_gat_UA-183634151-1");
    }
    else{ init({ id: 'GTM-PZ8H2QV' });
    hotjar.initialize(2078198, 6);
  }
  },[])
    return (
        <>
          <section>
          <div className="col-md-12 px-0" style={{backgroundColor: '#212121'}}>
          <div className="slide-container pt-5 pb-0">
              {/* <div className="col-sm-2 px-0"><a href="/"><img src={require('./img/back-to-top.png')} width="300" className="img-fluid back-to-top" alt="" /></a></div> */}
              <div className="container cnt-test tp-menu-show">
                  <div className="row">
                      <div className="col-md-3 wow fadeInUp" data-wow-delay="0.2s"><a href="/"><img src={require('./img/ftr-logo.webp')} width="150" alt="Logo- SafelyTeam - Best DIY Smart Home Security Solutions" /></a>
                        <p><img src={require('./img/ns-gold.webp')} width="70" alt="SafelyTeam Smart Home Security Devices- NSI Gold Rated Monitoring"/></p>
                        <p style={{color: '#FFF', fontSize: '0.7rem'}}>Our alarm receiving centre is <br/>NSI Gold certified</p>
                        <p className="pt-4"><img src={require('./img/ico-logo.webp')} width="150" alt="Icon | Accidental Fire Prevention | Safely"/></p>
                        <p style={{color:'#FFF', fontSize:'0.7rem'}}>Registered Data Controller</p>
                      </div>
                      <div className="col-md-8">
                          <div className="row">
                              <div className="col-md-3 wow fadeInUp" data-wow-delay="0.4s">
                                  <h4 className="ftr-prod">Products</h4>
                                  <ul className="non-list ftr-lnk">
                                    <li> <a href="/product-details/5f773c5c541f443e5ce64aaa">Gateway​</a></li>
                                    <li> <a href="/product-details/5f9d650ff268b98214e737bf">Keypad​</a></li>
                                    <li> <a href="/product-details/5f7b2dc6ffa2800728e971b7">Air Quality Sensor</a></li>
                                    <li> <a href="/product-details/5f7b2f0bffa2800728e971bf">Contact Sensor</a></li>
                                    <li> <a href="/product-details/5f7b2e1effa2800728e971b9">Panic Button</a></li>
                                    <li> <a href="/product-details/5f7b2d5bffa2800728e971b5">Siren </a></li>
                                    <li> <a href="/product-details/5f7b2f74ffa2800728e971c1">Smoke Sensor</a></li>
                                    <li> <a href="/product-details/5f7b2e6fffa2800728e971bb">Heat Sensor</a></li>
                                    <li> <a href="/product-details/5f7b302bffa2800728e971c5">Flood Alarm</a></li>
                                    <li> <a href="/product-details/5f7b2ec3ffa2800728e971bd">Motion Sensor</a></li>
                                    <li> <a href="/product-details/5f7b2fc3ffa2800728e971c3">Smart Plug</a></li>
                                    <li> <a href="/product-details/5f9d6592f268b98214e737c1">Key Tag</a></li>
                                  </ul>
                              </div>
                              <div className="col-md-3 wow fadeInUp" data-wow-delay="0.6s">
                                  <h4 className="ftr-prod">SafelyTeam</h4>
                                  <ul className="non-list ftr-lnk mb-5">
                                    {/* <li> <a href="/aboutus">About SafelyTeam</a></li> */}
                                   {/*  <li> <a href="/how-it-works">How It Works</a></li>		 */}	
                                    <li> <a href="/crime">Crime In UK</a></li>                                   
                                    <li> <a href="/contactus">Contact Us</a></li>				
                                  </ul>
                                    <h4 className="ftr-prod">SafelyTeam Connect App</h4>
                                    <ul className="non-list ftr-lnk">
                                      <li> <a href="https://play.google.com/store/apps/details?id=com.safelyteam.safely" target="_blank">Android </a></li>
                                      <li> <a href="https://apps.apple.com/gb/app/safely-connect/id1525530018" target="_blank">iOS</a></li>
                                    
                                    </ul>
                              </div>
                              <div className="col-md-3 wow fadeInUp" data-wow-delay="0.8s">
                                  <h4 className="ftr-prod">Help</h4>
                                  <ul className="non-list ftr-lnk">   
                                    <li> <a href="/faq">FAQ</a></li>
                                    <li> <a href="https://safelyteam.zendesk.com/hc/en-gb" target="_blank">Help Centre</a></li>
                                    <li> <a href="/privacy">Privacy Policy</a></li>
                                    <li> <a href="/terms">Terms and Conditions</a></li>
                                    <li><a href="/cookies">Cookies</a></li>
                                  </ul>
                              </div>
                              <div className="col-md-3 wow fadeInUp" data-wow-delay="1s">
                              <h4 className="ftr-prod1">Address</h4>
                                  <p style={{color: 'rgb(255, 255, 255)', fontSize: '0.9rem',lineHeight: '1.8rem'}}>SafelyTeam Ltd,<br/>
                                  130 Aztec West,<br/>
                                  Bristol,<br/>
                                  BS32 4UB</p>
                                  <h4 className="ftr-prod mt-3">Social</h4>
                                  <a href="https://www.facebook.com/Safelyteam/" target="_blank" className="mr-3 mb-4"><img src={require('./img/fb.png')} width="30" alt="Social Media Icon | Theft Protection System in UK | Safely"/></a>
                                  <a href="https://twitter.com/safelyteam?s=08" target="_blank" className="mr-3 mb-4"><img src={require('./img/tw.png')} width="30" alt="Social Media Icon | Buy Contact Sensor | Safely "/></a>
                                  <a href="https://www.instagram.com/safelyteam/" target="_blank" className="mr-3 mb-4"><img src={require('./img/in.png')} width="30" alt="Instagram Icon | Smart Home Automation Solutions in UK"/></a>
                                  <a href="https://www.linkedin.com/company/safely-team/" target="_blank" className="mr-3 mb-4"><img src={require('./img/lin.png')} width="30" alt="Linkedin Icon | Home Safety Devices | Safely"/></a>
                                  <a href="https://www.youtube.com/channel/UCoWYMWip0_tOT23Q2rRxEeQ" target="_blank" className="mr-2"><img src={require('./img/yb.png')} width="30" alt="Youtube Icon | Home Safety Alarms For Elderly | Safely"/></a>
                                  
                                <h4 className="ftr-prod1 mt-5">© 2021 SafelyTeam Ltd.</h4>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              {/* <!-- -----mobile-------------- --> */}

          <div className="col-md-12 pt-5 sh-img" style={{backgroundcolor: '#212121', paddingLeft: '10%'}}>
                  <div className="row">
                  <div className="col-xs-4 wow fadeInUp" style={{width: '50%'}} data-wow-delay="0.2s"> 
                  <h4 className="ftr-prod">Products</h4>
                            <ul className="non-list ftr-lnk">
                                  <li>  <a href="/product-details/5f773c5c541f443e5ce64aaa">Gateway​</a></li>
                                  <li>  <a href="/product-details/5f9d650ff268b98214e737bf">Keypad​</a></li>
                                  <li>  <a href="/product-details/5f7b2dc6ffa2800728e971b7">Air Quality Sensor</a></li>
                                  <li>  <a href="/product-details/5f7b2f0bffa2800728e971bf">Contact Sensor</a></li>
                                  <li>  <a href="/product-details/5f7b2e1effa2800728e971b9">Panic Button</a></li>
                                  <li>  <a href="/product-details/5f7b2d5bffa2800728e971b5">Siren </a></li>
                                  <li>  <a href="/product-details/5f7b2f74ffa2800728e971c1">Smoke Sensor</a></li>
                                  <li>  <a href="/product-details/5f7b2e6fffa2800728e971bb">Heat Sensor</a></li>
                                  <li>  <a href="/product-details/5f7b302bffa2800728e971c5">Flood Alarm</a></li>
                                  <li>  <a href="/product-details/5f7b2ec3ffa2800728e971bd">Motion Sensor</a></li>
                                  <li>  <a href="/product-details/5f7b2fc3ffa2800728e971c3">Smart Plug</a></li>
                                  <li>  <a href="/product-details/5f9d6592f268b98214e737c1">Key Tag</a></li>
                            </ul>
                              <h4 className="ftr-prod pt-3">Help</h4>
                                <ul className="non-list ftr-lnk">
                                  <li> <a href="/faq">FAQ</a></li>
                                  <li> <a href="https://safelyteam.zendesk.com/hc/en-gb" target="_blank">Help Centre</a></li>
                                  <li> <a href="/privacy">Privacy Policy</a></li>
                                  <li> <a href="/terms">Terms and Conditions</a></li>
                                  <li><a href="/cookies">Cookies</a></li>
                                </ul>
                        </div>

                        <div className="col-xs-4 wow fadeInUp" style={{width: '50%'}} data-wow-delay="0.4s">
                          <h4 className="ftr-prod">SafelyTeam</h4>
                            <ul className="non-list ftr-lnk">
                            {/* <li> <a href="/aboutus">About SafelyTeam</a> </li> */}
                            {/* <li> <a href="/how-it-works" target="/blank">How It works</a></li>  */} 
                            <li> <a href="/crime">Crime In UK</a></li>                          
                            <li> <a href="/contactus" target="/blank">Contact us</a></li>       
                            </ul>
                            <h4 className="ftr-prod1">Address</h4>
                                  <p style={{color: 'rgb(255, 255, 255)', fontSize: '0.9rem',lineHeight: '1.8rem'}}>SafelyTeam Ltd,<br/>
                                  130 Aztec West,<br/>
                                  Bristol,<br/>
                                  BS32 4UB</p>
                            <h4 className="ftr-prod">SafelyTeam Connect App</h4>
                <ul className="non-list ftr-lnk">
                  <li> <a href="https://play.google.com/store/apps/details?id=com.safelyteam.safely" target="_blank">Android </a></li>
                  <li> <a href="https://apps.apple.com/gb/app/safely-connect/id1525530018" target="_blank">iOS</a></li>
                
                </ul>
                <p><img src={require('./img/ns-gold.webp')} width="50" alt="SafelyTeam Smart Home Security Devices- NSI Gold Rated Monitoring"/></p>
                      <p style={{color: '#FFF', fontSize: '0.7rem'}}>Our alarm receiving centre is <br/>NSI Gold certified</p>
                      <p style={{width: '100%'}}><img src={require('./img/ico-logo.webp')} width="100" alt="Icon | Accidental Fire Prevention | Safely" /></p>
          <p style={{color: '#FFF', fontSize: '0.7rem', paddingBottom: '120px'}}>Registered Data Controller</p>
            </div>
            </div>
          
          </div>
          </div>
          {/* <div className="col-md-12 tp-menu-show" style={{position: 'relative', bottom: '0px', zIndex: '23', height: '50px'}}>
            <div className="row">
            <div className="col-md-7 text-right animate-element delay8 fadeInUp">
              <h4 className="ftr-prod1 legal-txt-col"><a href="/privacy" target="_blank">Privacy Policy</a> &nbsp;&nbsp;&nbsp;<a className="legal-txt-col" href="/terms">Terms and Conditions</a></h4>
              </div> 
            <div className="col-md-4 text-right animate-element delay10 fadeInUp pr-5"><h4 className="ftr-prod1">© 2020 Safely Ltd.</h4></div>
          </div>

          </div>*/}
            </div>
          </section>




          {/* <CookieConsent
            location="bottom" 
            buttonText="Agree"
            cookieName="AcceptCookie"
            style={{ zIndex:"999999", padding: '0px 50px',lineHeight: '1.5'}}
            buttonStyle={{ color: "#fff", fontSize: "14px",padding:"8px",backgroundColor:"#00a6e6" }}
            expires={150} njmmmmmmmmnjjjjmmmnjmnjjm
          >
          We use cookies to improve your experience on our site, including providing payment services and support. By using this site, you agree to the use of our cookies. See our privacy policy <a href="/privacy" target="_blank">here</a>.{" "}
            
          </CookieConsent> */}
          <CookieConsent
           
           location="bottom" 
           enableDeclineButton 
           declineButtonText="Accept Essential Cookies"
           declineButtonStyle={{ color: "#fff", fontSize: "14px",padding:"8px" }}
           onDecline={() => {
             Cookies.remove("_hjid");
             Cookies.remove("_hjFirstSeen");
             Cookies.remove("_ga");
             Cookies.remove("_gid");
             Cookies.remove("_gcl_au");
             Cookies.remove("_hjIncludedInPageviewSample");
             Cookies.remove("_hjAbsoluteSessionInProgress")
             Cookies.remove("_gat_UA-183634151-1");
           }}
           ariaDeclineLabel
           buttonText="Accept All Cookies"
           cookieName="AcceptCookie"
           style={{ zIndex:"999999", padding: '0px 50px',lineHeight: '1.5'}}
           buttonStyle={{ color: "#fff", fontSize: "14px",padding:"8px",backgroundColor:"#00a6e6" }}
           expires={150}
         >
         We use cookies to improve your experience on our site, including providing payment services and support. See our cookies policy <a href="/cookies">here</a>.{" "}
           
         </CookieConsent>

          </>  


)
}

export default Footer