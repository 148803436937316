import React, {useState, useEffect} from 'react'
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import Moment from 'react-moment';
import { getProduct, getInventory,removeInvHistory } from './apiAdmin'
import ShowImage from './ShowImage'
import { Link } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const ProductDetails = ({match}) => {

    const { user, token} = isAuthenticated();

    const classes = useStyles();

    const [ btnloading, setBtnloading] = useState(true);

    const [values, setValues] = useState({
        prodid: '',
        name: '',
        description: '',
        long_description: '',
        purchase_price: '',
        sale_price: '',
        weight: '',
        reserved:'',
        discountedprice: '',
        suppliers:[],
        supplier: '',
        categories: [],
        category: '',
        inventory: '',
        add: '',
        deleted: '',
        added_date: '',
        removed_date: '',
        threshold:'',
        reason: '',
        photo: '',
        error: '',
        createdProduct: '',
        redirectToProfile: false,
        formData: ''
    })

    const [inhistory, setHistory] = useState([])

    const { 
        prodid,
        name,
        description,
        long_description,
        purchase_price,
        sale_price,
        weight,
        reserved,
        threshold,
        //suppliers,
        supplier,
        //categories,
        category,
        inventory,
        //photo,
        error,
        createdProduct,
        //redirectToProfile
        //formData
     } = values;


     const init = productId => {
        getProduct(productId).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
                setBtnloading(false);
            } else {
                // populate the state
                setValues({
                    ...values,
                    prodid: data.prodid,
                    name: data.name,
                    description: data.description,
                    long_description: data.long_description,
                    purchase_price: data.purchase_price,
                    sale_price: data.sale_price,
                    weight: data.weight,
                    reserved:data.reserved,
                    supplier: data.supplier.companyname,
                    category: data.category.map((c,i) => (<span key={i}>{""+c.name+","}</span>)),
                    add: data.add,
                    deleted: data.deleted,
                    added_date: data.added_date,
                    removed_date: data.removed_date,
                    threshold: data.threshold,
                    reason: data.reason,
                    inventory: data.inventory,
                    photo: data.photo,
                    formData: new FormData()
                });
                setBtnloading(false);
            }
        });
    };

    const loadHistory = (productId) => {
        getInventory(productId).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setHistory(data);
            }
        });
    };

    const destroy = inhistoryId => {
        if(window.confirm("Do you want to delete this inventory history?"))
        {
            removeInvHistory({inhistoryId,status:2}, user._id, token).then(data => {
                if (data.error) {
                    setValues({ ...values, error: data.error });
                } else {
                    loadHistory(match.params.productId)
                }
            });
        }
    };
     

    useEffect(() => {
        init(match.params.productId);
        loadHistory(match.params.productId)
    }, []);


     
    const historyList = inhistory.map((p,i) => (
        <tr key={i}>
           
           <td><Moment format='DD/MM/YYYY'>{p.createdAt}</Moment></td>        
           <td>{p.reason}</td>
           <td>{p.quantity}</td>
           <td>{p.added_by.name}</td>
           <td><Link onClick={() => destroy(p._id)}><i className="ti-trash"></i></Link></td>
        </tr>
    ));
  
     const newPostForm = () => (

        <form className="mb-3">       
            <div className="row">
                <div className="col-md-6">
                        
                    <h4><b>Product ID</b>:&nbsp;&nbsp;{prodid}</h4> <br></br> 
                    <h4><b>Product Name</b>:&nbsp;&nbsp;{name}</h4><br></br> 
                    <h4><b>Quantity</b>:&nbsp;&nbsp;{inventory}</h4> <br></br> 
                    <h4><b>Reserved</b>:&nbsp;&nbsp;{reserved}</h4><br/><br/>
                    <h4><b>Threshold</b>:&nbsp;&nbsp;{threshold}</h4><br/><br/>
                    <h4><b>Short Description</b>:&nbsp;&nbsp;{description}</h4><br></br> 
                    <h4><b>Long Description</b>:&nbsp;&nbsp;{long_description}</h4><br></br> 
                    <h4><b>Purchase Price</b>:&nbsp;&nbsp;{purchase_price}</h4><br></br> 
                    <h4><b>Sale price</b>:&nbsp;&nbsp;{sale_price}</h4>  <br></br> 
                    <h4><b>Weight</b>:&nbsp;&nbsp;{weight}</h4><br></br> 
                    <h4><b>Supplier</b>:&nbsp;&nbsp;{supplier}</h4><br></br> 
                    <h4><b>Category</b>:&nbsp;&nbsp;{category}</h4><br></br> 
                    <h4><b>Status</b>:&nbsp;&nbsp;{((inventory === threshold) ? <span style={{backgroundColor:'#f0ad4e',color:'white'}} className="btn">Average</span> : ((inventory > threshold) ? <span style={{backgroundColor:'#5cb85c',color:'white'}} className="btn">Good</span> : <span style={{backgroundColor:'#FFBF00',color:'white'}} className="btn">Poor</span>))}</h4>  <br></br> 
                           
                </div>

                <div className="col-md-6">
                    <ShowImage item={match.params.productId} url="product" />
                </div>
            </div>
<hr/>
            <div className="row">
                <div className="col-md-12">
                    <h3> Inventory History:</h3>
                        <br />
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>           
                                <tr>
                                    <th>Date</th>
                                    <th>Reason</th>
                                    <th>Quantity</th>
                                    <th>Added by</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {historyList}
                            </tbody>
                            </table>
                            {inhistory.length === 0 && <><br/><h4 style={{textAlign:'center'}}>Sorry! No Inventory history found</h4></>}
                    </div>
                </div>                 
            </div>      
        </form>
     );

     const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => (
        <div className="alert alert-info" style={{ display: createdProduct ? '' : 'none' }}>
            <h2>{`${createdProduct}`} is updated!</h2>
        </div>
    );


    return (
        <AdminLayout className="container" topTitle="Product Details">
                    <div className="row">  
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title" style={{textAlign:'center'}}>Product Details</h4>
                            
                                    {/* {(btnloading) ? <div className='loader-container'><div className='loader'></div></div> : ""} */}
                                        {showSuccess()}
                                        {showError()}
                                        {!btnloading && newPostForm()}

                                        <Backdrop className={classes.backdrop} open={btnloading} >
                                                <CircularProgress color="inherit" />
                                        </Backdrop>  

                                </div>
                            </div>
                        </div>
                    </div>
        </AdminLayout>
    )
}


export default ProductDetails;