import React, {useState, useEffect} from 'react'
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import { Redirect } from "react-router-dom"
import { getProduct,updateInventory } from './apiAdmin'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const UpdateInventory = ({match}) => {

    const classes = useStyles();

    const [loading, setLoading] = useState(true);

    const { user, token} = isAuthenticated();

    const [values, setValues] = useState({
        inventory:'',
        product: '',
        reason: '',
        quantity: '',
        inventory_type: '', 
        btnloading1:false,
        error: '',
        success: false
    })


    const {inventory, quantity, reason,btnloading1, inventory_type, success, error,productname} = values


     const init = productId => {
        getProduct(productId).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error});
                setLoading(false)
            } else {
                // populate the state
                setValues({
                    ...values,
                    inventory: data.inventory,
                    productname: data.name
                });
                setLoading(false)
            }
        });
    };



    useEffect(() => {
        init(match.params.productId);
    }, []);


    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value,btnloading1:false});
    };

     const clickSubmit = event => {
            event.preventDefault()
            setValues({...values, error: false,btnloading1:true})

            updateInventory( user._id, token,{product:match.params.productId,quantity,inventory_type,reason})
            .then(data => {
                if(data.error) {
                    setValues({...values, error: data.error, success:false,btnloading1:false})
                } else {
                    setValues({
                        ...values,
                        inventory:'',
                        product:'',
                        reason: '',
                        inventory_type: '',
                        quantity: '',
                        btnloading1:false,
                        error: '',
                        success: true

                    })
                }
            })
     };

 

     const newInventoryForm = () => (

         <form className="mb-3" onSubmit={clickSubmit}>
                <h4><b>Product Name</b>: &nbsp;{productname}</h4> 
                <h4><b>Quantity</b>: &nbsp;{inventory}</h4>
             <div className="row">
                 <div className="col-md-12">

                 <div className="form-group">
                    <input onChange={handleChange('inventory')} type="number" className="form-control" value={inventory} hidden="true" />
                </div>

                 <div className="form-group">
                    <label className="text-muted">Type<span style={{color:"red"}}> *</span></label>
                    <select onChange={handleChange('inventory_type')} className="form-control">
                        <option>Select Type</option>
                        <option value="1">Add</option>
                        <option value="2">Remove</option>
                    </select>
                </div>

                <div className="form-group">
                    <label className="text-muted">Inventory<span style={{color:"red"}}> *</span></label>
                    <input onChange={handleChange('quantity')} type="number" className="form-control" value={quantity} required />
                </div>
             

                <div className="form-group">
                    <label className="text-muted">Reason<span style={{color:"red"}}> *</span></label>
                    <input onChange={handleChange('reason')} type="text" className="form-control" value={reason} required />
                </div>
            
                </div>             
            </div>       

            <button  className="btn btn-outline-primary" disabled={btnloading1}> {btnloading1 ? "Loading..." : "Update"} </button>

         </form>
     );

     const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => {
        if(success) {
            return <Redirect to="/admin/products" />
        }
    };

    return (
        <AdminLayout className="container" topTitle="Update Inventory">
            <div className="row">    
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6 offset-md-2">
                                    <h4 className="card-title" style={{textAlign:'center'}}>Update Inventory</h4>
                                    {/* {(btnloading) ? <div className='loader-container'><div className='loader'></div></div> : ""}   */}
                                        {showSuccess()}
                                        {showError()}
                                        {!loading && newInventoryForm()}
                                        <Backdrop className={classes.backdrop} open={loading} >
                                            <CircularProgress color="inherit" />
                                        </Backdrop>  
                            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </AdminLayout>
    )
}


export default UpdateInventory;