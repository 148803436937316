import React, { useState, useEffect } from 'react'
import { Redirect,Link } from 'react-router-dom';
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import {getSubscription,updateSubscription} from '../admin/apiAdmin'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const UpdateSubscription = ({match}) => {

    const classes = useStyles();

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState({
        plan:'',
        price: '',
        btnloading:false,
        error: '',
        success: false
    });

    const {plan,price,btnloading,success, error} = values

    const { user,token } = isAuthenticated()

    const init = subscriptionId => {
        getSubscription(subscriptionId).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
                setLoading(false);
            } else {           
                setValues({
                    ...values,
                    plan:data.plan,
                    price: data.price
                });
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        init(match.params.subscriptionId);
    }, []);

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value,btnloading:false});
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: false,btnloading:true });
        updateSubscription(match.params.subscriptionId,user._id,token,{subscriptionId:match.params.subscriptionId,plan,price})
        .then(data => {
            if(data.error) {
                setValues({...values, error: data.error, success:false,btnloading:false})
            } else {
                setValues({
                    ...values,
                    plan:'',
                    price: '',
                    error: '',
                    btnloading:false,
                    success: true
                })
            }
        })
    }

    const updateForm = () => (
        <form onSubmit={clickSubmit}>

            <div className="form-group">
                <label className="text-muted">Plan<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('plan')} readOnly="true" type="text" className="form-control" value={plan} required />
            </div>

            <div className="form-group">
                <label className="text-muted">Price(£)<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('price')} type="number" className="form-control" value={price} required />
            </div>

            <button  className="btn btn-outline-primary" disabled={btnloading}> {btnloading ? "Loading..." : "Update"} </button>
            <div><Link className="nav-link" to="/admin/subscriptions">Go Back to List </Link></div>

        </form>
    )

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => (
        success && <Redirect to="/admin/subscriptions" />
    );


    return (

        <AdminLayout className="container" topTitle="Update Subscription">
        <div className="row">     
            <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body"> 
                        <h4 className="card-title" style={{textAlign:'center'}}>Update Subscription Plans</h4>

                            {showSuccess()}
                            {showError()}
                            {!loading && updateForm()}

                            <Backdrop className={classes.backdrop} open={loading} >
                                <CircularProgress color="inherit" />
                            </Backdrop>  

                    </div>
                </div>
            </div>
       </div>
    </AdminLayout>
    )
}

export default UpdateSubscription
