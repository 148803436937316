import React, { useState} from 'react'
import { Link } from 'react-router-dom';
import Layout from '../theme/user/Layout'
import {register} from '../auth/index'

const Register = () => {

    const [values, setValues] = useState({
        name: '',
        lastname: '',
        email: '',
        phone: '',
        password: '',
        error: '',
        success: false
    });

    const {name, lastname, email, phone, password,success, error} = values

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value});
    };


    const clickSubmit = (event) => {
        event.preventDefault();
            setValues({...values, error: false });
            register({name,lastname, email, phone, password})
            .then(data => {
                if(data.error) {
                    setValues({...values, error: data.error, success:false})
                } else {
                    setValues({
                        ...values,
                        name:'',
                        lastname: '',
                        email: '',
                        phone: '',
                        password: '',
                        error: '',
                        success: true
                    })
                }
            })
    }
 
    const registerForm = () => (
        <div className="row ptb-inner">
             <div className="col-md-3">      
            </div>
        <div className="col-md-6 center-whole">
        <form>
            <h1>Register As Customer</h1>
            <div className="form-group">
                <label className="text-muted">First Name<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('name')} type="text" className="form-control" value={name} />
            </div>

            <div className="form-group">
                <label className="text-muted">Last Name<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('lastname')} type="text" className="form-control" value={lastname} />
            </div>

            <div className="form-group">
                <label className="text-muted">Email<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('email')} type="email" className="form-control" value={email} />
            </div>

            <div className="form-group">
                <label className="text-muted">Phone No<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('phone')} type="number" className="form-control" value={phone} />
            </div>

            <div className="form-group">
                <label className="text-muted">Password<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('password')} type="password" className="form-control" value={password} />
            </div>

            
            
            <button onClick={clickSubmit} className="btn btn-primary">Signup</button>
            <p style={{paddingTop: '10px'}}>Already have an account? <Link to="/login" style={{color:'blue'}}>Login</Link></p>
            <br />
        </form>
        </div>
        <div className="col-md-3">
           <p></p>
        </div>
        </div>
    );

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );


    const showSuccess = () => (
        <div className="alert alert-info" style={{ display: success ? '' : 'none' }}>
            New account is created. Please <Link to="/signin">Signin</Link>
        </div>
    );

    return (
        <Layout  className="container">
            <h3 className="light-gr" style={{textAlign:'center'}}>Register</h3>
           {showSuccess()}
           {showError()}
           {registerForm()}
        </Layout>
    )
    
}
export default Register
