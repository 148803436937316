import React from 'react'
import Subscribe from './Subscribe'
import NewLayout from './NewLayout'

const PrivacyPolicy = () => {
    return (
        <NewLayout topTitle="Privacy Policy | Top Home Security Solutions in UK | SafelyTeam" metaDescription="Please read this privacy policy carefully as it contains information on who we are and how and why we collect, use and share your personal information.">
           
                        <div className="section pp-scrollable slide-dark slide-dark-footer">                        
                            <div className="col-md-12 px-0 inner-tp-pad inner-safely">
                                <div className="container">
                                    <div className="col-md-10 inner-header">
                                        <h1 className="white-inner-txt abt-hd-res">Privacy Policy </h1>
                                            
                                        </div>
                                        </div>
                                        <img src={require('./img/abt-us.png')} className="img-fluid" alt=""/> 
                                        </div>
                                    <div className="col-md-12 inner-safely">
                                        <div className="container">
                                        
                                        <div className="row ptb-inner">
                                            <div className="col-md-12 px-0">
                                            
                                            <p className="inner-line">
                                            SafelyTeam takes your privacy very seriously. Please read this privacy policy carefully as it contains important information on who we are and how and why we collect, store, use and share your personal information. It also explains your rights in relation to your personal information and how to contact us or supervisory authorities in the event you have a complaint
                                            </p>
                                            <p className="inner-line">
                                            We are SafelyTeam Ltd a company registered in England and Wales. Our company registration number is 12457612 and our registered office is at Nene House, 4 Rushmills, Northampton, England, NN4 7YB. 
                                            </p>

                                            <p className="inner-line">
                                            We collect, use and are responsible for certain personal information about you. When we do so we are subject to the <span style={{color:'blue',textDecoration:'underline'}}>General Data Protection Regulation,</span> which applies across the European Union and the United Kingdom and we are responsible as ‘controller’ of that personal information for the purposes of those laws (except for audio/video data captured by cameras connected to your SafelyTeam gateway). 
                                            </p>

                                            <p className="inner-line">
                                            If you download the app of EZVIZ in order for an EZVIZ camera to be connected to your SafelyTeam gateway, EZVIZ will process the audio and video recorded by your camera in accordance with their Privacy Policy at <a href="https://www.ezvizlife.com/uk/legal/privacy-policy">https://www.ezvizlife.com/uk/legal/privacy-policy.</a>
                                            </p>
                                            <br/>
                                            <h5>Personal information we collect about you and other people in relation to your SafelyTeam account</h5><br/>
                                            <p className="inner-line">
                                                We may collect and use the following personal information about you:
                                            </p>
                                            <ul>
                                                <li>your name and contact information, including email address and telephone number</li>
                                                <li>
                                                    Subscription Data. This is the data we collect in order to set up and manage your SafelyTeam service. It includes how many people live at your property; the type of property the service will be provided for (including the rooms in the property, and which rooms the SafelyTeam sensors will be placed in); contact details for people who are to be alerted in the event of an alarm being triggered; email addresses and log-in details of users authorised to access your account, and people who are to be contacted in the event of an alarm; the history of activity on your account, like your preferences set for alarms being triggered and disarmed.
                                                </li>
                                                <li>
                                                    Sensor Data. This is data recorded by SafelyTeam equipment you install (such as a heat sensor or door lock sensor) and transmitted to us and our service providers as part of the SafelyTeam service. You are in control of what Sensor Data is recorded and transmitted, through your placement of the equipment and your selection of what information is shared with users, by means of the SafelyTeam app or dashboard. Any Sensor Data comprising video or audio (such as that captured by doorbell or indoor cameras) is referred to in this Policy as ‘Audio/Visual Sensor Data’.
                                                </li>
                                                <li>location data if you choose to give this to us</li>
                                                <li>your billing information, transaction, and payment card information</li>
                                                <li>your contact history, purchase history and saved items</li>
                                                <li>Information about how you use our website, or the SafelyTeam mobile app</li>
                                                <li>your responses to surveys, competitions and promotions</li>
                                            </ul>
                                            <p className="inner-line">
                                            You might provide us with personal data about other people in the course of setting up or using the SafelyTeam service. This could be friends, family and other people who live at the property being monitored by SafelyTeam. It might also be people you list as a contact in the event of an alarm being triggered.  IT IS IMPORTANT THAT YOU GET THEIR CONSENT TO YOUR GIVING THEIR INFORMATION TO US. <br/>
                                            You must bring this privacy policy to their attention when asking for their consent – we do not have their details prior to your providing us with their information so you are the only person who can realistically obtain their consent. Before entering their information into the SafelyTeam dashboard or app (as the case may be) you must have obtained their consent to disclose their information to us, and to our processing their information to provide the service to you. If you do not have this consent, you must not enter their information into the app or the dashboard. If they initially give the consent, but then withdraw it later, you must immediately replace them with someone else who has consented.
                                            </p>
                                            <p className="inner-line">
                                            We do not provide the service to any person under 18 and require that users do not permit use of their SafelyTeam account by any person under 18 except for their own children for whom they have parental responsibility at law, and in respect of whom they consent to the provision of personal data for the purposes of the services.
                                            </p><br/>
                                            <h5>How your personal information is collected</h5> <br/>
                                            <p className="inner-line">
                                            We collect most of this personal information directly from you—in person, by email and/or via our website and app. However, we may also collect information about you from our customer if they specify you as an authorised user of the service, or a person to be contacted in the event of an alarm.
                                            </p><br/>
                                            <h5>Your responsibilities regarding Audio/Visual Sensor Data</h5> <br/>
                                            <p className="inner-line">
                                            If you download the app of EZVIZ in order for an EZVIZ camera to be connected to your SafelyTeam gateway, EZVIZ will process the audio and video recorded by your camera in accordance with their Privacy Policy at <a href="https://www.ezvizlife.com/uk/legal/privacy-policy">https://www.ezvizlife.com/uk/legal/privacy-policy.</a>
                                            </p>
                                            <p className="inner-line">
                                            “Audio/Visual Sensor Data” is video or audio captured by cameras or other A/V equipment we supply you. You control the purposes for which Audio/Visual Sensor Data is used. We do not control what you use the video/audio for, or where you put the equipment capturing it – to the extent that we process the audio/video, we only do so on your behalf so that you can decide what it is used for, and how it is used. 
                                            </p>
                                            <p className="inner-line">
                                            You are the data controller with respect to all Sensor Data. Data protection laws in force in the UK apply to your use of the Audio/Visual Sensor Data. You alone are responsible for ensuring that your capturing and processing of Audio/Visual Sensor Data complies with those laws. You will need to display a notice that alerts delivery people and visitors that you are recording audio and video using our products. 
                                            </p>
                                            <p className="inner-line">
                                            It was put in place before GDPR, but this guidance from the data protection regulator should be followed when using the SafelyTeam service to record audio or video - <a href="https://ico.org.uk/media/1542/cctv-
                                                code-of-practice.pdf">https://ico.org.uk/media/1542/cctv-
                                                code-of-practice.pdf.</a> . You must make sure that the sensor or camera doesn’t record any audio or video from anywhere apart from your own property – not another person’s property or a public place. 
                                            </p><br/>
                                            <h5>How and why we use your personal information</h5> <br/>
                                            <p className="inner-line">
                                            Under data protection law, we can only use your personal information if we have a proper reason for doing so, eg:
                                            </p>
                                            <ul>
                                                <li>to comply with our legal and regulatory obligations;</li>
                                                <li>for the performance of our contract with you or to take steps at your request before entering
                                                    into a contract;
                                                </li>
                                                <li>for our legitimate interests or those of a third party; or</li>
                                                <li>where you have given consent.</li>
                                            </ul>
                                            <p className="inner-line">
                                                A legitimate interest is when we have a business or commercial reason to use your information, so
                                                long as this is not overridden by your own rights and interests.
                                            </p>
                                            <p className="inner-line">
                                            The table below explains what we use (process) your personal information for and our reasons for doing so:
                                            </p>

                                            <div className="table-responsive">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>What we use your personal information for</th>
                                                        <th>Our reasons</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>To provide the SafelyTeam service to you</td>
                                                        <td>For the performance of our contract with you<br/>or to take steps at your request before entering into a contract</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                        To prevent and detect fraud against you or SafelyTeam
                                                        </td>
                                                        <td>For our legitimate interests or those of a third party,<br/> ie to minimise fraud that could be damaging for us and for you</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Gathering and providing information required by<br/>or relating to audits, enquiries or investigations by regulatory bodies</td>
                                                        <td>To comply with our legal and regulatory obligations</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ensuring business policies are adhered to,<br/> eg policies covering security and internet use</td>
                                                        <td>For our legitimate interests or those of a third party,<br/> ie to make sure we are following our own<br/>internal procedures so we can deliver the best service to you</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Operational reasons, such as improving efficiency,<br/> training and quality control</td>
                                                        <td>For our legitimate interests or those of a third party,<br/> ie to be as efficient as we can so we can deliver<br/> the best service for you at the best price</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ensuring the confidentiality of commercially sensitive information</td>
                                                        <td>For our legitimate interests or those of a third party,<br/> ie to protect trade secrets and other commercially valuable information<br/>
                                                        To comply with our legal and regulatory obligations
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Statistical analysis to help us manage our business,<br/> eg in relation to our financial performance, <br/>customer base, product range or other efficiency measures</td>
                                                        <td>For our legitimate interests or those of a third party,<br/> ie to be as efficient as we can so we can deliver<br/> the best service for you at the best price</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Preventing unauthorised access and modifications to systems</td>
                                                        <td>For our legitimate interests or those of a third party,<br/> ie to prevent and detect criminal activity<br/> that could be damaging for us and for you<br/>
                                                        To comply with our legal and regulatory obligations
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Updating and enhancing customer records</td>
                                                        <td>For the performance of our contract with you <br/>or to take steps at your request before entering into a contract<br/>
                                                        To comply with our legal and regulatory obligations<br/>
                                                        For our legitimate interests or those of a third party,<br/> eg making sure that we can keep in touch with our<br/> customers about existing orders and new products
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Statutory returns</td>
                                                        <td>To comply with our legal and regulatory obligations</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Marketing our services and those of selected third parties to:<br/>
                                                        —existing and former customers;<br/>
                                                        —third parties who have previously expressed an interest in our services;<br/>
                                                        —third parties with whom we have had no previous dealings.
                                                        </td>
                                                        <td>For our legitimate interests or those of a third party,<br/> ie to promote our business to existing and former customers</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                            <p className="inner-line">
                                            To be clear, except where we are compelled by law to disclose it (for example to the police), we will only ever use Sensor Data to provide you with the SafelyTeam service. Depending on your subscription, this might include passing it to our alarm response provider and law enforcement authorities.
                                            </p>
                                            <p className="inner-line">
                                            The above table does not apply to special category personal information, which we will only process with your explicit consent. <b>‘Special Category’</b> personal information is data revealing racial or ethnic origin, political opinions, religious beliefs, philosophical beliefs or trade union membership, Genetic and biometric data and data concerning health, sex life or sexual orientation. We offer a door lock which operates on a fingerprint unlocking mechanism – the fingerprint data is held locally on the device itself. We do not process it.
                                            </p><br/>
                                            <h5>Promotional communications</h5><br/>
                                            <p className="inner-line">
                                            We may use your personal information to send you updates (by email, text message, telephone or post) about our products and services, including exclusive offers, promotions or new products and services.
                                            </p>
                                            <p className="inner-line">
                                            We have a legitimate interest in processing your personal information for promotional purposes (see above <b>‘How and why we use your personal information’</b>). This means we do not usually need your consent to send you promotional communications. However, where consent is needed, we will ask for this consent separately and clearly.
                                            </p>
                                            <p className="inner-line">
                                            We will always treat your personal information with the utmost respect and never sell it to other organisations for marketing purposes.
                                            </p>
                                            <p className="inner-line">
                                            You have the right to opt out of receiving promotional communications at any time by:
                                            </p>
                                            <ul>
                                                <li>contacting us at hello@safelyteam.com</li>
                                                <li>using the ‘unsubscribe’ link in emails </li>
                                            </ul>
                                            <p className="inner-line">
                                            We may ask you to confirm or update your marketing preferences if you instruct us to provide further products or services in the future, or if there are changes in the law, regulation, or the structure of our business.
                                            </p><br/>
                                            <h5>Who we share your personal information with</h5><br/>
                                            <p className="inner-line">
                                            We routinely share personal information with:
                                            </p>
                                            <ul>
                                                <li>third parties we use to help deliver our products to you, for example warehouses and delivery companies;</li>
                                                <li>Amazon Web Services, on whose servers we host our business information including Subscription Data (see <a href="https://aws.amazon.com/compliance/gdpr-center/">https://aws.amazon.com/compliance/gdpr-center/</a>);</li>
                                                <li>Safe4, who act on our behalf as a technology service provider by running the platform on which Subscription Data and Sensor Data is processed. Safe4 may use your Sensor Data to compile statistics for their own purposes (these stats are on an aggregated, anonymised basis and will not identify you or anyone else individually). For more information about Safe4, see <a href="https://www.safe4.com/">https://www.safe4.com/;</a></li>
                                                <li>Stanley, who operate our alarm response service. Stanley may use data from your interactions with their alarm response centre (including recordings of your phone calls with them) for their own data retention obligations which require them to keep information for two years. For more information about Stanley, see <a href="https://www.stanleysecurity.co.uk/">https://www.stanleysecurity.co.uk/;</a></li>
                                                <li>Our payment services providers (including Stripe and PayPal);</li>
                                                <li>Our customer services partner, ZenDesk (<a href="https://www.zendesk.co.uk/">https://www.zendesk.co.uk/</a>);</li>
                                                <li>Other third parties we use to help us run our business, eg marketing agencies or website hosts.</li>
                                            </ul>
                                            <p className="inner-line">
                                            We only allow our service providers to handle your personal information if we are satisfied they take appropriate measures to protect your personal information. We may also share personal information with external auditors, eg in relation to the audit of our accounts.
                                            </p>
                                            <p className="inner-line">
                                            We may disclose and exchange information with law enforcement agencies and regulatory bodies to comply with our legal and regulatory obligations. 
                                            </p>
                                            <p className="inner-line">
                                            We may also need to share some personal information with other parties, such as potential buyers of some or all of our business or during a re-structuring. Usually, information will be anonymised but this may not always be possible. The recipient of the information will be bound by confidentiality obligations.
                                            </p><br/>
                                            <h5>Where your personal information is held</h5>
                                            <p className="inner-line">
                                            Information may be held at our offices and those of our service providers, representatives and agents as described above (see above: <b>‘Who we share your personal information with’</b>).
                                            </p>
                                            <p className="inner-line">
                                            Some of these third parties may be based outside the European Economic Area. For more information, including on how we safeguard your personal information when this occurs, see below: <b>‘Transferring your personal information out of the UK and EEA’</b>.
                                            </p><br/>
                                            <h5>How long your personal information will be kept</h5><br/>
                                            <p className="inner-line">
                                            We will keep your personal information while you have an account with us or we are providing products and services to you. Thereafter, we will keep your personal information for as long as is necessary:
                                            </p>
                                            <ul>
                                                <li>to respond to any questions, complaints or claims made by you or on your behalf;</li>
                                                <li>to show that we treated you fairly;</li>
                                                <li>to keep records required by law.</li>
                                            </ul>
                                            <p className="inner-line">
                                            We will not retain your personal information for longer than necessary for the purposes set out in this policy. Different retention periods apply for different types of personal information, which we can share with you on request.
                                            </p>
                                            <p className="inner-line">
                                            EZVIZ will delete video and audio recordings comprised in Audio/Visual Sensor Data 30 days from the date it is captured. 
                                            </p><br/>
                                            <h5>Transferring your personal information out of the UK and EEA</h5><br/>
                                            <p className="inner-line">
                                            To deliver services to you, it is sometimes necessary for us to share your personal information outside the UK and/or European Economic Area (EEA), eg with our service providers located outside the UK/EEA.
                                            </p>
                                            <p className="inner-line">
                                            These transfers are subject to special rules under European and UK data protection law. This means we can only transfer your personal information to a country or international organisation outside the UK/EEA where:
                                            </p>
                                            <ul>
                                                <li>the European Commission has issued an ‘adequacy decision’ in relation to that country or international organisation; or </li>
                                                <li>there are appropriate safeguards in place, together with enforceable rights and effective legal remedies for data subjects; or</li>
                                                <li>a specific exception applies under data protection law</li>
                                            </ul><br/>
                                            <h5>European Commission adequacy decision</h5><br/>
                                            <p className="inner-line">
                                            The European Commission has the power to determine whether a country or international organisation provides an adequate level of protection for personal information and, if it does, to issue an ‘adequacy decision’. The effect of such a decision is that personal information can flow from the UK/EEA to that country without any further safeguards being necessary.
                                            </p>
                                            <p className="inner-line">
                                            It can take several years for the European Commission to issue an adequacy decision and only a small number of countries currently benefit from one.
                                            </p>
                                            <p className="inner-line">
                                            We may transfer personal information to the countries that have the benefit of a European Commission adequacy decision.
                                            </p><br/>
                                            <h5>Transfers with appropriate safeguards</h5><br/>
                                            <p className="inner-line">
                                            We may transfer your data to a third country or international organisation on this ground where we are satisfied the transfer complies with data protection law, appropriate safeguards are in place, and enforceable rights and effective legal remedies are available for data subjects.
                                            </p>
                                            <p className="inner-line">
                                            The safeguards will usually include using standard data protection contract clauses approved by the European Commission. 
                                            </p>
                                            <p className="inner-line">
                                            To obtain a copy of the standard data protection clauses and further information about relevant safeguards, see here - <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en">https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en</a>
                                            </p><br/>
                                            <h5>Further information</h5><br/>
                                            <p className="inner-line">
                                            If you would like further information about data transferred outside the UK or EEA, please contact us (see ‘How to contact us’ below).
                                            </p><br/>
                                            <h5>Your rights</h5><br/>

                                            <p className="inner-line">
                                                You have the following rights, which you can exercise free of charge:
                                            </p>
                                            <div className="table-responsive">
                                            <table className="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <td>Access</td>
                                                        <td>The right to be provided with a copy of your<br/> personal information (the right of access)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Rectification</td>
                                                        <td>The right to require us to correct any mistakes in<br/> your personal information</td>
                                                    </tr>
                                                    <tr>
                                                        <td>To be forgotten</td>
                                                        <td>The right to require us to delete your personal<br/> information—in certain situations</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Restriction of processing</td>
                                                        <td>The right to require us to restrict processing of<br/> your personal information—in certain<br/> circumstances, eg if you contest the accuracy of<br/> the data</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Data portability</td>
                                                        <td>The right to receive the personal information you<br/> provided to us, in a structured, commonly used<br/> and machine-readable format and/or transmit<br/> that data to a third party—in certain situations</td>
                                                    </tr>
                                                    <tr>
                                                        <td>To object</td>
                                                        <td>The right to object:<br/>
                                                        —at any time to your personal information being<br/> processed for direct marketing (including<br/> profiling);<br/>
                                                        —in certain other situations to our continued<br/> processing of your personal information, eg<br/> processing carried out for the purpose of our<br/> legitimate interests.
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Not to be subject to automated individual<br/>
                                                        decision making
                                                        </td>
                                                        <td>The right not to be subject to a decision based<br/>
                                                        solely on automated processing (including<br/>
                                                        profiling) that produces legal effects concerning<br/>
                                                        you or similarly significantly affects you
                                                        </td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                            </div>
                                            <p className="inner-line">
                                            For further information on each of those rights, including the circumstances in which they apply, please contact us or see the <span style={{color:'blue',textDecoration:'underline'}}>Guidance from the UK Information Commissioner’s Office (ICO) on individuals’ rights under the General Data Protection Regulation</span>.
                                            </p>
                                            <p className="inner-line">
                                            If you would like to exercise any of those rights, please:
                                            </p>
                                            <ul>
                                                <li>email, call or write to us —see below: <b>‘How to contact us’;</b> and</li>
                                                <li>let us have enough information to identify you (eg your full name, email address and customer or order number);</li>
                                                <li>let us have proof of your identity and address (a copy of your driving licence or passport and a recent utility or credit card bill); and</li>
                                                <li>let us know what right you want to exercise and the information to which your request relates.</li>
                                            </ul><br/>
                                            <h5>Keeping your personal information secure</h5><br/>
                                            <p className="inner-line">
                                            We have appropriate security measures to prevent personal information from being accidentally lost or used or accessed unlawfully. We limit access to your personal information to those who have a genuine business need to access it. Those processing your information will do so only in an authorised manner and are subject to a duty of confidentiality. 
                                            </p>
                                            <p className="inner-line">
                                            We also have procedures in place to deal with any suspected data security breach. We will notify you and any applicable regulator of a suspected data security breach where we are legally required to do so.
                                            </p>
                                            <p className="inner-line">
                                            If you want detailed information from Get Safe Online on how to protect your information and your computers and devices against fraud, identity theft, viruses and many other online problems, please visit <a href="www.getsafeonline.org">www.getsafeonline.org.</a> Get Safe Online is supported by HM Government and leading businesses.
                                            </p><br/>
                                            <h5>How to complain</h5><br/>

                                            <p className="inner-line">
                                            We hope that we can resolve any query or concern you may raise about our use of your information. 
                                            </p>
                                            <p className="inner-line">
                                            The <span style={{color:'blue',textDecoration:'underline'}}>General Data Protection Regulation</span> also gives you right to lodge a complaint with a supervisory authority, in particular in the European Union (or European Economic Area) state where you work, normally live or where any alleged infringement of data protection laws occurred. The supervisory authority in the UK is the Information Commissioner who may be contacted at <a href="https://ico.org.uk/concerns">https://ico.org.uk/concerns</a> or telephone: 0303 123 1113.
                                            </p><br/>
                                            <h5>Changes to this privacy policy</h5><br/>
                                            <p className="inner-line">
                                            We may change this privacy notice from time to time—when we do we will inform you via email if the change is a significant one.
                                            </p><br/>
                                            <h5>How to contact us</h5><br/>
                                            <p className="inner-line">
                                            Please contact us if you have any questions about this privacy policy or the information we hold about you:
                                            </p>
                                            <p className="inner-line">
                                            by email at <a href="#/">hello@safelyteam.com</a>
                                            </p>
                                            <p className="inner-line">
                                            by phone at 0808 501 5388 
                                            </p>
                                            <p className="inner-line">
                                            by post at: SafelyTeam Ltd, 130 Aztec West, Bristol BS32 4UB
                                            </p>


                                            </div>
                                        </div>
                                        
                                                 

                                        <div className="row">
                                            <div className="col-md-12 px-0">
                                            
                                            
                                            </div>
                                        </div>
                                        <div className="row ptb-inner">
                                            <div className="col-md-4 px-0 pt-5"> <img src={require('./img/build-btm.jpg')} className="img-fluid" /> </div>
                                            <div className="col-md-4 px-0 pt-5"> </div>
                                            <div className="col-md-4 px-0 pt-5"> <img src={require('./img/right-man.jpg')} className="img-fluid" /> </div>
                                        </div>


                                        </div>
                                    </div>
                                
                 
                            <Subscribe />

                        </div>            
          
        </NewLayout>
    )
}

export default PrivacyPolicy
