import { API } from "../config";

export const read = (userId, token) => {
    return fetch(`${API}/user/${userId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const update = (userId, token, user) => {
    return fetch(`${API}/user/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateUser = (user, next) => {
    if (typeof window !== "undefined") {
        if (localStorage.getItem("jwt")) {
            let auth = JSON.parse(localStorage.getItem("jwt"));
            auth.user = user;
            localStorage.setItem("jwt", JSON.stringify(auth));
            next();
        }
    }
};

export const getUserOrders = (userId, token) => {
    return fetch(`${API}/order/user/${userId}`, { 
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
           Authorization: `Bearer ${token}`
        }
    }).then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const getOrdersById = (orderId) => {
    return fetch(`${API}/order/${orderId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json'
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getSubOrdersByOrderid = (orderid) => {
    return fetch(`${API}/suborders/order/${orderid}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json'
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateAssignDevice = (userId, token, suborderId, assign_device) => {
    return fetch(`${API}/usersuborder/${suborderId}/assigndevice/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ assign_device,suborderId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateRoomName = (userId, token, suborderId, room_name) => {
    return fetch(`${API}/usersuborder/${suborderId}/roomname/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ room_name,suborderId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateDeviceId = (userId, token, suborderId, deviceid) => {
    return fetch(`${API}/usersuborder/${suborderId}/deviceid/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ deviceid,suborderId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getOrderLog = orderId => {
    return fetch(`${API}/orderlog/read/${orderId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getStatusValues = (userId, token) => {
    return fetch(`${API}/order/status-values/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateOrdStatus = (userId, token, orderId, status) => {
    return fetch(`${API}/order/${orderId}/orderstatus/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ status, orderId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const resetPassword = (email) => {
    
    return fetch(`${API}/reset-password`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body:JSON.stringify(email)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const reset = (userData) => {
    return fetch(`${API}/password/reset`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(userData)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};