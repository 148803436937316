import React from 'react'
import Subscribe from './Subscribe'
import NewLayout from './NewLayout'

const FAQ = () => {
 
    return (
        <NewLayout topTitle="FAQ | Best Home security systems | SafelyTeam" metaDescription="Have a question regarding home security systems and how it works?  We have compiled a list of FAQs to help you with your queries. Check them out.">
            
               
                        <div className="section pp-scrollable slide-dark slide-dark-footer">                        
                       
                        <div className="col-md-12 px-0 inner-tp-pad inner-safely">
        <div className="container">
          <div className="col-md-10 inner-header">
            <h1 className="white-inner-txt abt-hd-res">FAQs </h1>
            
          </div>
        </div>
        <img src={require('./img/faq-img.png')} className="img-fluid" alt="FAQ - home security solutions | Mobile app monitoring"/> </div>
        <div className="col-md-12 inner-safely">
        <div className="container">
          
          <div className="row ptb-inner">
            <div className="col-md-12 px-0">
              
            <div className="col-md-12 mb-5 plr-0px text-mini-mbile">
              <div className="accordian">
  <ul className="acc-in">
    
    <li>
      <h3 className="title acc-head-faq"><span className="fa fa-plus icon-size-in"></span>
      What is a Security system and how does it work? 

      </h3>
       <ul className="pl-0">
        <li>
         
          <p className="blk-head-in">The most basic definition of any security system is found in its name. It is literally a means or method by which something is secured through a system of interworking components and devices. All home security systems work on the same basic principle of securing entry points, like doors and windows, as well as interior space containing valuables like art, computers, guns, and coin collections. Regardless of the size of your home, or the number of doors and windows or interior rooms a homeowner decides to protect, the only real difference is in the number of security components deployed throughout the home and monitored by the control panel. 
 </p>

         
        </li>
        
      </ul>
    </li>
    
    <li>
      <h3 className="title acc-head-faq"><span className="fa fa-plus icon-size-in"></span>
       How do SafelyTeam’s home security systems work? 

      </h3>
       <ul className="pl-0">
        <li>
         
          <p className="blk-head-in">Our security system monitors for movement or any doors/windows opening while your system is armed. If there is activity detected our smart siren will be activated and will play a loud siren to alert neighbours of a burglary. The SafelyTeam connect app will also notify you of a burglary and our alarm monitoring centre will be in contact with you to guide you through how to deal with the situation. 
 </p>

         
        </li>
        
      </ul>
    </li>
    <li>
      <h3 className="title acc-head-faq"><span className="fa fa-plus icon-size-in"></span>
        Why should I invest in a home security system? 
      </h3>
       <ul className="pl-0">
        <li>
         
          <p className="blk-head-in">Purchasing a security system can reduce your annual home insurance premium and decrease the likelihood of a burglary at you home. Investing in home security also prevents the negative mental effects and lost sense of safety a home invasion can bring.</p>

         
        </li>
        
      </ul>
    </li>
    
    <li>
      <h3 className="title acc-head-faq"><span className="fa fa-plus icon-size-in"></span>
        Why should I invest in home monitoring? 

      </h3>
       <ul className="pl-0">
        <li>
         
          <p className="blk-head-in">Alarm monitoring ensures that you are instantly alerted of any dangers to your home such as a burglary or fire. Alarm monitoring can also allow for friends and family to be notified of any danger to you or your home so that they can assist with any emergency. In the case of an emergency, the alarm monitoring centre will call and text you and your alarm contacts and alert them of the danger.</p>

         
        </li>
        
      </ul>
    </li>
    
    <li>
      <h3 className="title acc-head-faq"><span className="fa fa-plus icon-size-in"></span>
      If the power goes out, does my Security system still work?   

      </h3>
       <ul className="pl-0">
        <li>
         
          <p className="blk-head-in">Our gateway, the brains of the system, which communicates with the alarm monitoring centre and your phone app, includes as standard a 2-hour battery backup. This means even in a power outage your SafelyTeam system will remain online and the 2 hours battery backup provides plenty of time for a grid to restore power to your home. Our intelligent gateway will also notify you when the power was lost and when it has returned.   
</p>

         
        </li>
        
      </ul>
    </li>
    <li>
      <h3 className="title acc-head-faq"><span className="fa fa-plus icon-size-in"></span>
      If the internet goes out, does my wireless Security system still work? 

      </h3>
       <ul className="pl-0">
        <li>
         
          <p className="blk-head-in">Our systems come standard with e-sims which can let them to switch to mobile internet (cellular) if your home internet goes down. So, if your internet is down your SafelyTeam system will always be online. 
 

 </p>
          
        </li>
        
      </ul>
    </li>
    <li>
      <h3 className="title acc-head-faq"><span className="fa fa-plus icon-size-in"></span>
      Can I control my home security from my phone? 

      </h3>
       <ul className="pl-0">
        <li>
         
          <p className="blk-head-in">Yes, you can monitor and control your system from your SafelyTeam Connect app, wherever you are in the world with an internet connection. You can holiday in Spain and, at the tap of a button, monitor when the last time someone entered the front door or if anyone has been in your room. You can also monitor and be alerted of smoke, heat and flood alarms. 

</p>

         
        </li>
        
      </ul>
    </li>

    <li>
      <h3 className="title acc-head-faq"><span className="fa fa-plus icon-size-in"></span>
      How does a motion detector work?


      </h3>
       <ul className="pl-0">
        <li>
         
          <p className="blk-head-in">Motion sensors will alert you if someone is sneaking in or around your home. Most motion sensors rely on similar technologies to detect intruders: they pick up on tiny changes in things like temperature, vibration, or radiation.</p>

         
        </li>
        
      </ul>
    </li>
     <li>
      <h3 className="title acc-head-faq"><span className="fa fa-plus icon-size-in"></span>
      How to choose a Security system? 

      </h3>
       <ul className="pl-0">
        <li>
         
          <p className="blk-head-in">Ask yourself a few questions before you start shopping: 

</p>
<ol className="colortext">
  
  <li>What am I protecting?</li>
  <li>How do I want to install it?</li>
  <li>How big is my home?</li>
  <li>What is my family’s routine?</li>
  <li>What is my budget?</li>
</ol>
         <p className="colortext">The answers to these questions can tell you what kind of features you want, along with the best way to install, and how big of a system you need. </p>
        </li>
        
      </ul>
    </li>
     
     <li>
      <h3 className="title acc-head-faq"><span className="fa fa-plus icon-size-in"></span>
       Can I arm my home with my pet indoors?  


      </h3>
       <ul className="pl-0">
        <li>
         
          <p className="blk-head-in">We love our furry friends, but our motion sensors still can pick them up when they are running about the house. Therefore, if you would like to arm your home with your pet, we suggest disabling the motion sensors for when you have left your pet at home. The reason why we do not use pet friendly motion sensors is they can be easily fooled by intruders which can be very dangerous.</p>

         
        </li>
        
      </ul>
    </li>
     <li>
      <h3 className="title acc-head-faq"><span className="fa fa-plus icon-size-in"></span>
        Should I choose a wired or wireless security system? 


      </h3>
       <ul className="pl-0">
        <li>
         
          <p className="blk-head-in">Wired and wireless security systems each have advantages and disadvantages. A wired system might be the most reliable, but there’s a risk of wires being cut. Wired systems involve more labour for installation too. Wireless systems can be used in more places and can be installed in about 30 minutes without help from a technician. They’re portable too; you can move a wireless system from one property to another. But a poor signal and electromagnetic interference can affect it. 

</p>


         
        </li>
        
      </ul>
    </li>
     <li>
      <h3 className="title acc-head-faq"><span className="fa fa-plus icon-size-in"></span>
        What happens in an event of an emergency? Will my system tell me where in my home it happened?   


      </h3>
       <ul className="pl-0">
        <li>
         
          <p className="blk-head-in">In the case of an emergency such as a break in, the sensors that detected the intrusion will trigger a very loud alarm via the smart siren. You will also immediately be notified on your mobile device via the SafelyTeam Connect App regarding the nature of the break-in including which sensor detected it and where in the house this is. The alarm monitoring centre will also contact you and advise you how to deal with the situation. 

</p>

         
        </li>
        
      </ul>
    </li>
     <li>
      <h3 className="title acc-head-faq"><span className="fa fa-plus icon-size-in"></span>
        I don't have good cellular service at my house. Will my system still work? 


      </h3>
       <ul className="pl-0">
        <li>
         
          <p className="blk-head-in">Absolutely. Our cellular backup will connect to the strongest major wireless provider at your location. 

</p>

         
        </li>
        
      </ul>
    </li>
     <li>
      <h3 className="title acc-head-faq"><span className="fa fa-plus icon-size-in"></span>
        Can I save money on my homeowner’s insurance by having an alarm system? 

      </h3>
       <ul className="pl-0">
        <li>
         
          <p className="blk-head-in">It's common for some insurers to provide a discount of up to 20% on homeowner’s insurance if you have a professionally monitored system. Be sure to check with your insurance company for more information. 

</p>

         
        </li>
        
      </ul>
    </li>
     <li>
      <h3 className="title acc-head-faq"><span className="fa fa-plus icon-size-in"></span>
        Does a smart home security system add value to my home? 


      </h3>
       <ul className="pl-0">
        <li>
         
          <p className="blk-head-in">Installing a smart home system and automating your home will very likely increase the value of your home. More and more people want smart home functionality in their next homes. If your house is equipped with smart tech, you can most likely raise your asking price.</p>

         
        </li>
        
      </ul>
    </li>
     <li>
      <h3 className="title acc-head-faq"><span className="fa fa-plus icon-size-in"></span>
        Is it hard to install a DIY smart home security system? 


      </h3>
       <ul className="pl-0">
        <li>
         
          <p className="blk-head-in">All our components are simple to install. It’s about as easy as changing a lightbulb or hanging a picture frame on the wall. All you need to do is open the package, remove pull tabs and place the devices as instructed on the boxes. There should be no need to call any professional for installation, but this is an option if required when purchasing. 

</p>

         
        </li>
        
      </ul>
    </li>
     <li>
      <h3 className="title acc-head-faq"><span className="fa fa-plus icon-size-in"></span>
        How do I pre-configure my SafelyTeam smart home security systems? 

      </h3>
       <ul className="pl-0">
        <li>
         
          <p className="blk-head-in">All our components are preconfigured and pre-programmed so that you can quickly and easily install your system when it arrives. After purchasing, you will be invited to help pre-configure your devices to suit your home and then we will do the rest. Once your SafelyTeam system arrives, use our pre-attached adhesive pads to stick components to the wall or door. You don’t need to drill holes—you simply put your sensors and control panel in place and activate the system. 

</p>

         
        </li>
        
      </ul>
    </li>
     <li>
      <h3 className="title acc-head-faq"><span className="fa fa-plus icon-size-in"></span>
        What are the benefits of having a home security system? 


      </h3>
       <ul className="pl-0">
        <li>
         
          <ol className="colortext">
  
  <li>Increased protection for your household </li>
  <li>Controlling your home from afar </li>
  <li>Peace of mind </li>
  <li>Financial savings </li>
  <li>Protects your family when you’re gone </li>
</ol>
         
        </li>
        
      </ul>
    </li>
     <li>
      <h3 className="title acc-head-faq"><span className="fa fa-plus icon-size-in"></span>
        How can smart home systems improve my health? 


      </h3>
       <ul className="pl-0">
        <li>
         
          <p className="blk-head-in">Here’s an insight into how smart homes improve your health. 

</p>
<p className="colortext">Breathe easy <br/>With SafelyTeam's Air Quality Sensor, you can monitor air quality. It helps you act on air quality information, allowing you to open ventilation, or automatically open windows and doors thus making your home dust and pollutant free. <br/><br/>
Improves your sleep <br/>Not only can SafelyTeam keep you comfortable, we can also assist with essential functions like sleep. Our smart sensors gently dim the lights in the lead-up to bedtime and can assist children go to sleep with night lighting and even music. <br/><br/>

</p>

         
        </li>
        
      </ul>
    </li>
     <li>
      <h3 className="title acc-head-faq"><span className="fa fa-plus icon-size-in"></span>
       Can I save and manage electricity with a home security system?  


      </h3>
       <ul className="pl-0">
        <li>
         
          <p className="blk-head-in">Yes! A SafelyTeam smart home does not only allow you to control your appliances from your mobile, but also allows you to monitor your electricity usage over time. Using smart plugs and lighting, you can easily control your energy usage and save money! 
</p>


         
        </li>
        
      </ul>
    </li>
     
     
  </ul>
</div>
            </div>
            
            <div className="row">
              <div className="col-md-8 pl-4">
                <h3 className="still-have center-textin"> Still Have a <br/>Question?</h3>
              </div>
              <div className="col-md-4 pt-3">
                <div className="row">
             
                <div className="col-md-12 pt-3">
                  <div className="row">
                    <div className="col-md-6 center-textin-lt">
                    <i className="ti-mobile">&nbsp;</i><a href="tel:0808 501 5388">0808 501 5388</a>
                    </div>
                    <div className="col-md-6 center-textin-lt">
                    <i className="ti-email">&nbsp;</i><a href="mailto:hello@safelyteam.com">hello@safelyteam.com</a>
                    </div>
                  </div>
                </div>
              </div>
                
              </div>
            </div>
            </div>
          </div>
          
                    
          <div className="row ptb-inner">
            <div className="col-md-4 px-0 pt-5"> <img src={require('./img/build-btm.jpg')} className="img-fluid" alt="Home Image | Smoke Detectos For Home | Safety"/> </div>
            <div className="col-md-4 px-0 pt-5"> </div>
            <div className="col-md-4 px-0 pt-5"> <img src={require('./img/right-man.jpg')} className="img-fluid" alt="Out Door Image | Anti-theft Devices For Smart Home"/> </div>
          </div>


        </div>
      </div>
    
                            <Subscribe />

                        </div>              
          
        </NewLayout>
    )

}
export default FAQ