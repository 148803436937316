import React, { useState } from 'react'
import { Redirect } from 'react-router-dom';
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import {createEmailTemplate} from '../admin/apiAdmin'
import CKEditor from 'ckeditor4-react';

const AddEmailTemplate = () => {

    const { user, token} = isAuthenticated()

    const [values, setValues] = useState({
        name:'',
        content:'',
        subject:'',
        error:'',
        success:false,
        btnloading:false
    })

    const { name, subject, content, error, success, btnloading} = values;

    const handleChange = name => event => {
        var value = (name === "content" ?  event.editor.getData() : event.target.value)
        setValues({ ...values, error: false, [name]:value,btnloading:false});
    };

    const clickSubmit = (e) => {
        e.preventDefault();
        setValues({...values, btnloading:true, success:false, error:''});
        createEmailTemplate(user._id, token, {name, subject, content})
        .then(data => {
            if(data.error){
                setValues({...values, btnloading:false, success:false, error:data.error})
            }
            else {
                setValues({
                    ...values,
                    name:'',
                    subject:'',
                    content:'',
                    error:'',
                    success:true,
                    btnloading:false
                })
            }
        })
    }

    const showForm = () => (
        <form onSubmit={clickSubmit}>
            <div className="form-group">
                <label className="text-muted">Template Name:<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('name')} type="text" className="form-control" value={name} required/>
            </div>
            <div className="form-group">
                <label className="text-muted">Subject:<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('subject')} type="text" className="form-control" value={subject} required/>
            </div>
            <div>
            <label className="text-muted">Content:<span style={{color:"red"}}> *</span></label>
            <CKEditor
                    data={content}
                    config={{}}                          
                    onChange={handleChange('content')} />
            </div>
            <br/>
            <center>
                <button  className="btn btn-outline-primary" disabled={btnloading}> {btnloading ? "Loading..." : "Add"} </button>
            </center>         
        </form>
    )

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => {
        if(success) {
            return <Redirect to="/admin/email/templates" />
        }
    };


    return (
        <AdminLayout className="container" topTitle="Add Email Template">
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title" style={{textAlign:'center'}}>Add Email Template</h4>
                            {showSuccess()}
                            {showError()}
                            {showForm()}
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default AddEmailTemplate
