import React,{Fragment} from 'react'
import Header from './Header'
import Footer from './Footer'
import Subscribe from './Subscribe'
import {Helmet} from 'react-helmet'

const Blog1 = () => {
    return (
        <Fragment>
            <Helmet>
  <title>Safely – Home Safety In Times Of Covid-19 : A Life-Saving Device You Need To Own ‘Now’</title>
</Helmet>
            <Header />

             <div id="content" className="content">
  <div className="homepage-personal1 a-pagepiling">
    <div className="section pp-scrollable slide-dark slide-dark-footer">
     
      <div className="col-md-12 px-0 inner-tp-pad inner-safely">
        
        <img src={require('./img/blog-img.png')} className="img-fluid" alt="" /> </div>
      <div className="col-md-12 inner-safely">
        <div className="container">
          <div className="row ptb-inner">
          <div className="col-md-12 padd-top-blog-sin">
            <h3>Home Safety In Times Of Covid-19 : A Life-Saving Device You Need To Own ‘Now’
</h3>
          </div>
          
        </div>
          <div className="row py-4">
            <div className="col-md-6">
            <div className="row">
              <div className="col-md-3 text-center-blog"><img src={require('./img/author8.png')} className="img-fluid" alt="" /></div>
              <div className="col-md-9 px-0 pt-3 text-center-blog">Daniel Wainwright<br/>Nov 12 . 10 min read</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row pt-3">
                <div className="col-md-9 pt-3 share-text-al">Share</div>
                <div className="col-md-2 px-0 pt-1 text-right" >
                  <div className="row pt-3">
                  <div className="col-md-4 wid-30-blog"><a href="#/"><i className="fa fa-facebook" aria-hidden="true"></i></a></div>
                  <div className="col-md-4 wid-30-blog"><a href="#/"><i className="fa fa-twitter" aria-hidden="true"></i></a></div>
                  <div className="col-md-4 wid-30-blog">
                  <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                </div>
                </div>
                </div>

            </div>
          </div>
          </div>
        <div className="row">
          <div className="col-md-12">
            <p className="inner-line text-justi-blog">Did you know?<br/>

“9 out of 10 people worldwide breathe polluted air.”- WHO<br/>

Which in the longer run, has adverse repercussions on the health of people in day to day life.<br/>

Now that we are in 2020 and living in the “New Normal”, spending most of our time indoors, it is very essential to keep the home environment clean and safe to ensure the safety of yourself and your family.<br/>

To bring this to the possibility at your fingertips, we are sharing a life-saving device you all need to own right now to live safer and healthier.



</p>
<h4>Say Hi To Cleaner & Healthier Home Environment</h4>
<p className="inner-line text-justi-blog">A healthy space is a happy space.
<br/>

If you are keeping your home safe and secure from harmful air compounds, you are acquiring the real wealth of life.
<br/>

As much as you need any other home security device to protect your home from burglars and intruders, you need a device that keeps your home safer by guarding the home environment.  
<br/>

To own an air quality sensor for your home is the absolute essential step you need to take in 2020.
<br/>
See how this amazing device works in keeping your home tenfold secure and safe and keeps your family always healthy.




</p>
<h4>Air Quality Sensor- How does it work</h4>
<p className="inner-line text-justi-blog">A battery-driven Air Quality Sensor will enable you to monitor indoor air quality and ensure that the air inside a room is clean and healthy. <br/>

This smart environmental monitoring device can monitor the environment for volatile organic compounds(VOCs) and temperature changes in your home and prompt you to ventilate rooms, or you can set this to occur automatically if you have compatible devices. <br/>

The device is compatible with different devices to provide a seamless connectivity and security experience. This includes WiFi, Bluetooth, Z-Wave, Zigbee, and Cellular. </p>

<h4>Why do you need an Air Quality Sensor?</h4>
<p className="inner-line text-justi-blog">A single reason for breathing safely and healthy would not be enough, it’s a basic necessity of the human body.<br/>

Still, we compiled a few use cases and reasons for why your home and you actually need an air quality sensor.
 </p>
<h4>1. Indoor air is critical to your health</h4>
<p className="inner-line text-justi-blog">Indoor air is even more dynamic than outdoor air.<br/>

Most of us spend most of our time at home and unlike outdoor air, indoor air is quite continuous in recycling itself, making it trap and raise the concentration of air compounds that directly or indirectly affects human health.<br/>

With air quality sensors, you’re ensuring safer indoor air quality.

 </p>
 <h4>2. Taking care of the newborns</h4>
<p className="inner-line text-justi-blog">Did you know? Bad air quality may affect the health of your newborn starting from the womb itself.<br/>

Those little humans demand huge care.<br/>

From the right quality clothes to the best possible quality air, everything should be taken care of.<br/>

To give your newborns the right environment, an air quality sensor is an ideal device to keep the mother and the newborn safe always.


 </p>
 <h4>3. Keeping Elderlies Safe</h4>
<p className="inner-line text-justi-blog">Elderlies are even more vulnerable to respiratory problems caused by bad air quality.<br/>

An air quality sensor prompts you to make space for the best possible air quality giving 24/7 protection to your loved elders and saving them from a serious condition in the long run.


 </p>
 <h4>4. Avoiding Many Adverse Health Issues</h4>
<p className="inner-line text-justi-blog">Many serious health issues can be avoided if we have exposure to good quality air.<br/>
Serious health conditions like asthma, heart disease, lung problems, etc are long-term effects of bad air quality. <br/>

By controlling your indoor air quality at home, you’re keeping serious health issues at bay.


 </p>
 <h4>5. A Gift Of Health For Your Wellness</h4>
<p className="inner-line text-justi-blog">Keeping yourself and your dear ones safe is the best gift you can give them.<br/>

Air quality sensor gives you the peace of mind in one place of living and breathing in a healthy environment.<br/>

Living more, managing less.


 </p>
 <h4>Final Thoughts</h4>
<p className="inner-line text-justi-blog">An air quality sensor is surely a life-saver device to keep you and your family 24/7/365 days protected from negative health effects caused by bad quality air.<br/>

It is a must-have device you need to own ‘Now’.<br/>

You can own an air quality sensor at- <a href="#">Safely</a>.<br/>
To check out more amazing devices for home security visit- <a href="#">Safely</a>.



 </p>

          </div>
        </div>
        

        

        

        

        <div className="row py-4">
            <div className="col-md-6">
            <div className="row">
              <div className="col-md-3 text-center-blog"><img src={require('./img/author8.png')} className="img-fluid" alt="" /></div>
              <div className="col-md-9 px-0 pt-3 text-center-blog">Daniel Wainwright<br/>Nov 12 . 10 min read</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row pt-3">
                <div className="col-md-9 pt-3 share-text-al">Share</div>
                <div className="col-md-2 px-0 pt-1 text-right" >
                  <div className="row pt-3">
                  <div className="col-md-4 wid-30-blog"><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></div>
                  <div className="col-md-4 wid-30-blog"><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></div>
                  <div className="col-md-4 wid-30-blog">
                  <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                </div>
                </div>
                </div>

            </div>
          </div>
          </div>

          <div className="row py-5">
            <div className="col-md-12 more-art">
              <p>More Articles</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-5">
              <div className="col-md-12 plr-0px">
                <img src={require('./img/covid.png')} className="img-fluid" alt="" />

              </div>
            <div className="col-md-12 py-4 plr-0px">
              
              <h4>Home Safety In Times Of Covid-19</h4>
            </div>
            <div className="col-md-12">
              <div className="row">
              <div className="col-md-2 text-center-blog"><img src={require('./img/author8.png')} className="img-fluid" alt="" /></div>
              <div className="col-md-9 px-0 pt-3 text-center-blog">Daniel Wainwright<br/>Nov 12 . 10 min read</div>
            </div>
            </div>
            </div>
             <div className="col-md-6 mb-5">
              <div className="col-md-12 plr-0px">
                <img src={require('./img/covid-1.png')} className="img-fluid" alt="" />

              </div>
            <div className="col-md-12 py-4 plr-0px">
              
              <h4><a href="/blog/are-home-security-devices-worth-your-pick">Are Home-Security Devices Worth Your Pick?</a></h4>
            </div>
            <div className="col-md-12">
              <div className="row">
              <div className="col-md-2 text-center-blog"><img src={require('./img/philip.png')} className="img-fluid" alt="" /></div>
              <div className="col-md-9 px-0 pt-3 text-center-blog">Philip Simon<br/>Nov 10 . 12 min read</div>
            </div>
            </div>
            </div>
             
                         
          </div>
          
          
        </div>
      </div>


      <Subscribe />

                        </div>
                        <Footer />
                    </div>
                   
                </div>
 
            
        </Fragment>
    )
}

export default Blog1
