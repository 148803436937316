import { API } from '../config'

/**
 * to perform crud on category
 * get all category
 * get a single category
 * update single category
 * delete single category
 */
export const createCategory = (userId, token, category) => {
    
    return fetch(`${API}/category/create/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(category)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};


export const getCategories = () => {
    return fetch(`${API}/categories`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getCategory = categoryId => {
    return fetch(`${API}/category/${categoryId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateCategory = (categoryId, userId, token, category) => {
    return fetch(`${API}/category/${categoryId}/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(category)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const deleteCategory = (categoryId, userId, token) => {
   
    return fetch(`${API}/category/${categoryId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


/**
 * to perform crud on product
 * get all products
 * get a single product
 * update single product
 * delete single product
 */

export const createProduct = (userId, token, product) => {
    
    return fetch(`${API}/product/create/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: product
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};


//${API}/products?limit=undefined`
export const getProducts = () => {
    return fetch(`${API}/products`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const deleteProduct = (productId, userId, token) => {    
    return fetch(`${API}/product/${productId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getProduct = productId => {
    return fetch(`${API}/product/${productId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// Inventory
export const getInventory = productId => {
    return fetch(`${API}/inventory/read/${productId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateProduct = (productId, userId, token, product) => {
    return fetch(`${API}/product/${productId}/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: product
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const updateInventory = ( userId, token, inhistory) => {
    return fetch(`${API}/inventory/create/${userId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(inhistory)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};

export const deleteInvHistory = (inhistoryId, userId, token) => {    
    return fetch(`${API}/inventory/history/${inhistoryId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};

export const removeInvHistory = ( inventory,userId,token) => {
    return fetch(`${API}/update/status/inventory/history/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(inventory)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}

/**
 * to perform crud on users
 * get all users
 * get a single users
 * update single users
 * delete single users
 */

export const addUser = (userId, token, user) => {
   
    return fetch(`${API}/create/user/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(user)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const getUsers = () => {
    return fetch(`${API}/users`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getUser = (userId,token) => {
    return fetch(`${API}/user/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getQuesByUserid = (userId) => {
    return fetch(`${API}/question/user/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json'
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getOrdersByUserid = (userId) => {
    return fetch(`${API}/order/user/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json'
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateUser = ( userId, token, user) => {
    return fetch(`${API}/user/update/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateUser1 = ( userId, token, user) => {
    return fetch(`${API}/update/user/details/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateStatus = ( userId,status, token) => {
   
    return fetch(`${API}/user/update-status/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(status)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


/**
 * to perform crud on supplier
 * get all supplier
 * get a single supplier
 * update single supplier
 * delete single supplier
 */

export const createSupplier = (userId, token, supplier) => {
    
    return fetch(`${API}/supplier/create/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(supplier)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const getSuppliers = () => {
    return fetch(`${API}/suppliers`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getSupplier = supplierId => {
    return fetch(`${API}/supplier/${supplierId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateSupplier = (supplierId, userId, token, supplier) => {
    return fetch(`${API}/supplier/${supplierId}/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(supplier)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const deleteSupplier = (supplierId, userId, token) => {
   
    return fetch(`${API}/supplier/${supplierId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


/**
 * to perform crud on Orders
 * get all orders
 * get a single product
 * update single product
 * delete single product
 */

export const getOrders = () => {
    return fetch(`${API}/orders/list`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getOrdersById = (orderId) => {
    return fetch(`${API}/order/${orderId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json'
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const getStatusValues = (userId, token) => {
    return fetch(`${API}/order/status-values/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateOrdStatus = (userId, token,orderId, order) => {
    return fetch(`${API}/order/${orderId}/orderstatus/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(order)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getSubOrdersByOrderid = (orderid) => {
    return fetch(`${API}/suborders/order/${orderid}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json'
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateAssignDevice = (userId, token, suborderId, assign_device) => {
    return fetch(`${API}/suborder/${suborderId}/assigndevice/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ assign_device,suborderId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateRoomName = (userId, token, suborderId, room_name) => {
    return fetch(`${API}/suborder/${suborderId}/roomname/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ room_name,suborderId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateDeviceId = (userId, token, suborderId, deviceid) => {
    return fetch(`${API}/suborder/${suborderId}/deviceid/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ deviceid,suborderId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getOrderLog = orderId => {
    return fetch(`${API}/orderlog/read/${orderId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getOrderBySubOrderId = suborderId => {
    return fetch(`${API}/suborder/orderid/${suborderId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getReturnRequests = () => {
    return fetch(`${API}/lists/return-requests`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateReturnReq = (returnId, userId, token, returnReq) => {
    return fetch(`${API}/return/requests/update/${returnId}/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(returnReq)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const getReturnProd = orderId => {
    return fetch(`${API}/return-request/order/${orderId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateOrdStatus1 = (userId, token,orderId, order) => {
    return fetch(`${API}/return-request/${orderId}/orderstatus/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(order)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const updateTechnicianStatus = ( userId, token, suborder) => {
    return fetch(`${API}/update/suborder/technician-verified/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(suborder)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getUserDetails = (order) => {
    
    return fetch(`${API}/get/user/by-order`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body:JSON.stringify(order)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const getShipment = (userId, token, order) => {
    
    return fetch(`${API}/get/shipment-details/orderid/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(order)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const updateShipmentDetails = ( userId, token, shipment) => {
    return fetch(`${API}/update/shipment-details/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(shipment)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateShipmentPickup = ( userId, token, shipment) => {
    return fetch(`${API}/shipment/update/pickup/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(shipment)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

/**
 * to perform crud on Newsletter
 * get all products
 * get a single product
 * update single product
 * delete single product
 */

export const getNewsletters = () => {
    return fetch(`${API}/newsletters`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const deleteNewsletter = (newsletterId, userId, token) => {
   
    return fetch(`${API}/newsletter/${newsletterId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const createNewsletter = ( newsletter ) => {

    return fetch(`${API}/newsletter/create`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body:JSON.stringify(newsletter)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })  

};

export const getNewsletter = newsletterId => {
    return fetch(`${API}/newsletter/${newsletterId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateNewsletter = (userId, token, newsletter) => {
    return fetch(`${API}/update/newsletter/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(newsletter)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const removeNewsletter = (userId, token, newsletter) => {
    return fetch(`${API}/delete/newsletter/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(newsletter)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

/**
 * to perform crud on Contacts
 * get all products
 * get a single product
 * update single product
 * delete single product
 */

export const getContacts = () => {
    return fetch(`${API}/contacts`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const deleteContact = (contactId, userId, token) => {
   
    return fetch(`${API}/contact/remove/${contactId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

/**
 * to perform crud on Notes
 * get all products
 * get a single product
 * update single product
 * delete single product
 */

export const getNotes = orderId => {
    return fetch(`${API}/notes/order/${orderId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

/**
 * to perform crud on Subscriptions
 * get all products
 * get a single product
 * update single product
 * delete single product
 */

export const getActiveSubscriptionLogs = ( userId, token, sub) => {
    return fetch(`${API}/get/subscription/active/lists/${userId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(sub)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getSubscriptionLogs = ( userId, token, sub) => {
    return fetch(`${API}/get/user-subscription/logs/${userId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(sub)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getSubscriptions = () => {
    return fetch(`${API}/subscription/lists`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateSubscription = ( subscriptionId,userId, token, subscription) => {
    return fetch(`${API}/subscription/plan/update/${subscriptionId}/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(subscription)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getSubscription = subscriptionId => {
    return fetch(`${API}/subscription/${subscriptionId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getSubscriptionlists = () => {
    return fetch(`${API}/orders/subscription/list`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateUserSubscription = ( userId, token,order) => {
    return fetch(`${API}/order/cancel-user/subscription/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(order)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateUserSubscriptionHistory = ( userId, token,order) => {
    return fetch(`${API}/order/cancel-user/subscription/history/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(order)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateUserSubscriptionHistoryUser = ( userId, token,order) => {
    return fetch(`${API}/order/cancel-by-user/subscription/history/plan/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(order)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const createSubscriptionLog = ( userId, token, sub) => {
    return fetch(`${API}/create/order/subscription/logs/${userId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(sub)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


/**
 * to perform crud on Coupons
 * get all products
 * get a single product
 * update single product
 * delete single product
 */

export const createCoupon = (userId, token, coupon) => {
    
    return fetch(`${API}/coupon/create/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(coupon)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const getCoupons = () => {
    return fetch(`${API}/coupon/lists`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateCoupon = (couponId, userId, token, coupon) => {
    return fetch(`${API}/coupon/update/${couponId}/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(coupon)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getCoupon = couponId => {
    return fetch(`${API}/coupon/${couponId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getCouponByOrderId = (userId, token, coupon) => {
    
    return fetch(`${API}/get/coupon-list/order/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(coupon)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const getCouponlogsByCouponId = (coupon,userId,token) => {
    
    return fetch(`${API}/get/coupon-logs/list/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(coupon)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const getCouponLogs = () => {
    return fetch(`${API}/coupon/logs/lists`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

/**
 * to perform crud on ads
 * get all products
 * get a single product
 * update single product
 * delete single product
 */

export const createAd = (userId, token, ads) => {
    
    return fetch(`${API}/create/new/ads/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: ads
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const createAdsProduct = (userId, token, adsProd) => {
    
    return fetch(`${API}/create/ads/product/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: adsProd
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const getAds = () => {
    return fetch(`${API}/list/ads`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getAdsProducts = (userId, token, adsProd) => {
    
    return fetch(`${API}/lists/ads/product/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(adsProd)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const getAdsById = adsId => {
    return fetch(`${API}/ads/${adsId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateAd = (userId, token,adsId, ads) => {
    
    return fetch(`${API}/update/ads/${adsId}/${userId}`,{
        method: "PUT",
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: ads
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const getAdData = (userId, token, adsProd) => {
    
    return fetch(`${API}/get/ad/data/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(adsProd)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const updateAdProdPrice = (userId, token, adsProd) => {
    
    return fetch(`${API}/update/ad/product/price/${userId}`,{
        method: "PUT",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(adsProd)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const updateAdsProdPhoto = (userId, token,adsId, adsProd) => {
    
    return fetch(`${API}/update/ads-product/photo/${adsId}/${userId}`,{
        method: "PUT",
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: adsProd
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const removeAdsProd = (adsProdId, userId, token) => {    
    return fetch(`${API}/remove/ads/product/${adsProdId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getAdsProdList = (userId, token, adsProd) => {
    
    return fetch(`${API}/get/ads/product/list/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(adsProd)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const updateStatusAds = (userId, token, ads) => {
    
    return fetch(`${API}/update/status/ads/${userId}`,{
        method: "PUT",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(ads)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const getActiveAds = () => {
    return fetch(`${API}/list/ads/users`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

/**
 * to perform crud on HomePage
 * get all products
 * get a single product
 * update single product
 * delete single product
 */

export const createHomePage = (userId, token, home) => {
    
    return fetch(`${API}/create/homepage/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(home)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const newUpdateHomePage = (userId, token, home) => {
    
    return fetch(`${API}/homepage/new/update/${userId}`,{
        method: "PUT",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(home)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const getHomePage = () => {
    return fetch(`${API}/list/homepages`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const uploadHomeImg = (userId, token, home) => {
    
    return fetch(`${API}/home/upload/image/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: home
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

/**
 * to perform crud on Cards
 * get all products
 * get a single product
 * update single product
 * delete single product
 */

 export const getCards = () => {
     return fetch(`${API}/list/cards`,{
         method:'GET'
     })
     .then(response => {
         return response.json();
     })
     .catch(err => console.log(err));
 }

 export const updateCardStatus = ( card ) => {
    return fetch(`${API}/update/card/status`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(card)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

/**
 * to perform crud on preorder bookings
 * get all preOrders
 * get a single preOrders
 * update single preOrders
 * delete single preOrders
 * create PreOrd comments
 */


export const getPreorders = () => {
    return fetch(`${API}/lists/preorder/bookings`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const deletePreorders = (preorderId, userId, token) => {
   
    return fetch(`${API}/delete/preorder/${preorderId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updatePreorder = (userId, token, preorder) => {
    return fetch(`${API}/update/preorder/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(preorder)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const removePreorder = (userId, token, preorder) => {
    return fetch(`${API}/remove/preorder/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(preorder)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const createPreorder = ( preorder ) => {

    return fetch(`${API}/create/preorder`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body:JSON.stringify(preorder)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })  

};

export const getPreorder = preorderId => {
    return fetch(`${API}/preorder/${preorderId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const createPreOrdComments = (userId, token, preorder ) => {

    return fetch(`${API}/create/preorder/comments/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(preorder)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })  

};

export const getPreOrdComments = (userId, token, preorder ) => {

    return fetch(`${API}/get/preorder/comments/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(preorder)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })  

};


/**
 * to perform crud on Email Templates
 * get all Email Templates
 * get a single Email Template
 * update single Email Template
 * delete single Email Template
 */

export const createEmailTemplate = (userId, token, template ) => {

    return fetch(`${API}/create/email/template/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(template)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })  

};

export const updateEmailTemplate = (userId, token, template ) => {

    return fetch(`${API}/update/email/template/${userId}`,{
        method: "PUT",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(template)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })  

};

export const getEmailTemplates = () => {
    return fetch(`${API}/email/templates`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getEmailTemplate = (templateId) => {
    return fetch(`${API}/email/template/${templateId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

/**
 * to perform crud on review
 * get all review
 * get a single review
 * update single review
 * delete single review
 */

export const getReviews = () => {
    return fetch(`${API}/list/reviews`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getReview = (reviewId) => {
    return fetch(`${API}/review/${reviewId}`,{
        method:'GET'
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
}

export const createReview = (userId, token, review ) => {

    return fetch(`${API}/create/review/${userId}`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(review)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })  

};

export const updateReview = (userId, token, review ) => {

    return fetch(`${API}/update/review/${userId}`,{
        method: "PUT",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body:JSON.stringify(review)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })  

};

export const deleteReview = (reviewId, userId, token) => {
   
    return fetch(`${API}/review/${reviewId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};