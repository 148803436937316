import React from 'react'

const Nav = () => {
   

    return (
        
        <div className="col-sm-12 pb-30-con animate-element delay2 fadeInUp"
        style={{backgroundColor: '#FFF', position: 'fixed', top: '0px', zIndex: '26'}}>
       <div className="row">
           <div className="col-md-2 go-ba">
           <a href="/">
                                   <img src={require('../img/logo.png')} width="120" alt="" />
                                </a>
              </div>
           <div className="col-md-8 pt-4 text-center">
               <ul className="list-inline text-center mb-0">
                   <li className="list-inline-item top-list-bread">
                       <a href="/step1/">
                           <i className="icofont-simple-right"></i> Select your priorities - (Step 1 of 3)
                       </a>
                   </li>
                   <li className="list-inline-item top-list-bread">
                       <a href="/step2/">
                           <i className="icofont-simple-right"></i>Custom details
                           - (Step 2 of 3)
                       </a>
                   </li>
                   <li className="list-inline-item top-list-bread">
                       <a href="/step3/" className="blue-acti"> 
                           <i className="icofont-simple-right"></i>Review Package
                           - (Step 3 of 3)
                       </a>
                   </li>

               </ul>
           </div>
       </div>

   </div>
  
    )
}

export default Nav
