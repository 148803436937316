import React from "react";
import { API } from "../config";

const ShowImage = ({ item, url }) => (
    <div className="product-img" style={{minHeight:'100px'}}>
       <a target='blank' href={'/product-details/'+item}>
        <img
            src={`${API}/product/photo/${item}`}
            alt="details"
            className="mb-3"
            style={{ maxHeight: "100%", maxWidth: "100%" }}
        />
        </a>
    </div>
);

export default ShowImage;
