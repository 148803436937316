import React, {Fragment, useState} from 'react'
import {  withRouter } from 'react-router-dom'
import { isAuthenticated, logout } from '../../auth/index'
import { changePassword } from './Priorities/apiUser'
import Navbar from '../admin/Navbar'
import Sidebar from './Sidebar'
import {Helmet} from 'react-helmet'

const ChangePassword = ({history}) => {

    const [values, setValues] = useState({
        password: '',
        password1: '',
        password2: '',
        error: '',
        success: false
    });

    const {password, password1, password2, success, error} = values

    const [btnloading, setbtnloading] = useState(false);

    const { user,token } = isAuthenticated()

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value});
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        setbtnloading(true);
        setValues({...values, error: false });
        changePassword(user._id,token,{password, password1, password2})
        .then(data => {
            if(data.error) {
                setbtnloading(false)
                setValues({...values, error: data.error, success:false})
            } else {
                setValues({
                    ...values,
                    password: '',
                    password1: '',
                    password2: '',
                    error: ''
                })
                logout(() => {history.push('/login')})
            }
        })
    }

    const showError = () => (
        <>
        <br/>
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
        </>
    ); 
 
    const showChangePwd = () => {
        return (
            <form onSubmit={clickSubmit}>

                <div className="form-group">
                    <label className="text-muted">Current Password<span style={{color:"red"}}> *</span></label>
                    <input onChange={handleChange('password')} type="password" className="form-control" value={password}  required />
                </div>
                <div className="form-group">
                    <label className="text-muted">Enter New Password<span style={{color:"red"}}> *</span></label>
                    <input onChange={handleChange('password1')} type="password" className="form-control" value={password1}  required />
                </div>
                <div className="form-group">
                    <label className="text-muted">Confirm New Password<span style={{color:"red"}}> *</span></label>
                    <input onChange={handleChange('password2')} type="password" className="form-control" value={password2}  required />
                </div>

                <button disabled={btnloading} className="btn btn-success">{btnloading ? "Loading..." : "Change Password"}</button>

            </form>
        )
    }

    return (
        <Fragment>
            <Helmet>
                <title>SafelyTeam - Change Password</title>
            </Helmet>
             <div className="container-scroller">
                <Navbar />
                
                <div className="container-fluid page-body-wrapper">
    
                <Sidebar />

                        <div className="main-panel">
                          <div className="content-wrapper white-bg-ad">
                            <div className="row">
                              <div className="col-md-12 grid-margin">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <h4 className="font-weight-bold mb-0">Change Password</h4>
                                  </div>
                                <div>  
                              </div>
                            </div>
                          </div>
                        </div>            
                            {showChangePwd()}
                            {showError()}
                      </div> 
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(ChangePassword)
