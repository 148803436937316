import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import Header from '../theme/user/Header'
import Footer from '../theme/user/Footer'
import {login, authenticate, isAuthenticated, checkAdmin, checkUser, logout} from '../auth/index'
import {Helmet} from 'react-helmet'

const Login = ({location,history}) => {

    const [values, setValues] = useState({
        email: '',
        password: '',
        error: '',
        loading: false,
        redirectToReferrer: false
    });

    const { email, password, loading, error, redirectToReferrer} = values;

    const { user } = isAuthenticated()

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value});
    };


    const clickSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: false, loading: true });
        login({ email, password})
        .then(data => {
            if(data.error) {
                setValues({...values, error: data.error, loading: false});
            } else {
                authenticate(data, () => {
                    setValues({
                        ...values,
                        redirectToReferrer: true
                    });
                });
            }
        });
    };
    const loginForm = () => (
      
        <div className="row ptb-inner-login">
            <div className="col-md-3">      
            </div>
        <div className="col-md-6 center-whole">
            <form>

                <div className="form-group">
                    <label className="text-muted">Email<span style={{color:"red"}}> *</span></label>
                    <input onChange={handleChange('email')} type="email" className="form-control" value={email} />
                </div>

                <div className="form-group">
                    <label className="text-muted">Password<span style={{color:"red"}}> *</span></label>
                    <input onChange={handleChange('password')} type="password" className="form-control" value={password} />
                </div>
                <button onClick={clickSubmit} className="btn btn-primary">Login</button>
                <p style={{paddingTop: '10px'}}><a href="/forgotpassword" style={{color:"blue"}}>Forgot Password?</a></p>
            </form>
        </div>
        <div className="col-md-3">
           <p></p>
        </div>
        </div>
      
    );

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showLoading = () => (
        loading && (<div className="preloader">
        <div className="three-bounce">
            <div className="one"></div>
            <div className="two"></div>
            <div className="three"></div>
        </div>
    </div>)
    );

    const redirectUser = () => {
        if(redirectToReferrer) {
            if(!location.search) {
                if(user && user.role === 1) {
                    return <Redirect to="/admin/dashboard" />
                } else {
                    return <Redirect to="/user/dashboard" />
                }
            } else {
                return <Redirect to="/shippingdetails" />
            }
            
        }
        if(isAuthenticated()) {
            if(user && user.role === 1 && checkAdmin()) {
                return <Redirect to="/admin/dashboard" />
            } 
            else if(user && user.role === 0 && checkUser()) {
                return <Redirect to="/user/dashboard" />
            }
            else {
                logout(() => {history.push('/login')})
            }
        }
    }

    return (
        <div style={{overflowY: 'scroll', overflowX: 'hidden'}}>
               <Helmet>
            <title>SafelyTeam - Login</title>
          </Helmet>
        <Header />
        <section> 
         {showLoading()}
         <div className="col-md-12 px-0 pt-5">
    <div className="col-md-12 px-0 inner-tp-pad ">
                       
    <div className="container abt-text-top-container pt-5">
    <div className="col-md-12 py-5">
    <h1 className="text-center pt-5 blue-acti">LOGIN</h1>
            
                 
        
           {loginForm()}         
           {showError()}
           {redirectUser()}
           </div>
          
           </div>
        
                        
                    </div>
                   
                </div>
                </section> 
                <Footer />
        </div>
    )
    
}
export default Login
