import React, {useState, useEffect} from 'react'
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import { getAdsProducts,getAdsById } from './apiAdmin'
import { API } from "../config";
import ShowImage from "./ShowImage"

const AdDetails = ({match}) => {

    const { user, token} = isAuthenticated();
    const [ btnloading, setBtnloading] = useState(true);

    const [adProds, setAdProds] = useState([]);

    const [ values, setValues] = useState({
        id:'',
        category_name:'',
        category_description:'',
        category_image: '',
        package_salePrice:'',
        error:'',
        success:false,
        formData:''
    });

    const [total,setTotal] = useState(0);

    const { id,category_name, category_description,package_salePrice, category_image, error, success, formData} = values;

    const loadAdsProds = () => {
        getAdsProducts(user._id,token,{Id:match.params.adsId}).then(data => {
            if (data.error) {
                console.log(data.error);
                setBtnloading(false);
            } else {
                setAdProds(data);
                setBtnloading(false);
                var sum=0;
                for(var i=0;i<data.length;i++){
                    sum+=data[i].prod_price;
                }
                setTotal(sum);
            }
        });
    };

    const loadAds = () => {
        getAdsById(match.params.adsId).then(data => {
            if(data.error){
                setValues({...values, error: data.error});
            } else {
                setValues({
                    ...values,
                    id:data._id,
                    category_name: data.category_name,
                    category_description: data.category_description,
                    package_salePrice: data.package_salePrice,
                    formData: new FormData()
                })
            }
        })
    }

    useEffect(() => {
        loadAdsProds();
        loadAds();  
        
    }, []);

    const showCategory = () => (
        <div className="row">
            <div className="col-md-6">
                <h5><b>Category Name:</b>&nbsp;&nbsp;{category_name}</h5>
                <h5><b>category_description:</b>&nbsp;&nbsp;{category_description}</h5>
                <h5><b>Package Sale Price:</b>&nbsp;&nbsp;{package_salePrice}</h5>
                <h5><b>Category image</b></h5><br/>
                <img src={`${API}/ads/image/${match.params.adsId}`} style={{ maxHeight: "50%", maxWidth: "50%" }} />,
            </div>
        </div>
    )

    const showProdImg = (id,prod_image,productID) => {

        if(prod_image){
            return <img src={`${API}/ads/products/image/${id}`} width="100%" />
        }
        else {
                return <ShowImage item={productID} url="product" />    
            }
        
    }

    const showAds = () => {
        return (
           
                   
                <div className="table-responsive ">
                    <table className="table table-bordered table-responsive">
                    <thead>
                        <tr>
                        
                        <th className="pend-table-hd">
                            Products
                        </th>
                        <th className="pend-table-hd">
                       Price
                        </th>
                        <th className="pend-table-hd">
                            Image
                        </th>
                        
                        </tr>
                    </thead>
                    <tbody>
                        {adProds.map((s,i) => (
                                <tr>                                                    
                                    <td key={i} className="font-we-prod">
                                    {s.productId.name}
                                    </td>
                                    <td key={i}>
                                       {s.prod_price}
                                    </td>
                                    <td key={i}>
                                        {showProdImg(s._id, s.prod_image,s.productId._id)}
                                    </td>                              
                                   
                            </tr> 
                        ))}
                        <tr>
                            <td></td>
                            <td>Total:</td>
                            <td>{total}</td>
                        </tr>
                        
                    </tbody>
                    </table>
                   
                </div>
           
        )
    }

    return (
        <AdminLayout className="container" topTitle="Ad Details">
            <div className="row">     
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body"> 
                            <h4 className="card-title" style={{textAlign:'center'}}>Ad Details</h4>
                            {(btnloading) ? <div className='loader-container'><div className='loader'></div></div> : ""}  
                            {!btnloading && showCategory()}
                            {!btnloading && showAds()}
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default AdDetails
