import React, {Fragment,useEffect, useState} from 'react'
import { isAuthenticated, authenticate } from '../../auth/index'
import { updateWaitingOrderStatus, createSubscriptionLog, getUsersPendingOrders,updateCancelStatus,updateUserSubscription, createNotes, getSubscriptions, getUser  } from './Priorities/apiUser'
import Navbar from '../admin/Navbar'
import Sidebar from './Sidebar'
import Moment from 'react-moment';
import { STRIPE_PUBLIC, STRIPE_PUBLIC_1,API,TESTING_USER} from '../../config';
import { loadStripe } from '@stripe/stripe-js';
import { Modal } from 'react-bootstrap'
import {Helmet} from 'react-helmet'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const PendingConfiguration = () => {

    let stripePromise = "";
    const [history, setHistory] = useState([]);
    const {user: {_id, name, email, role, isSubCancelled, lastname, prefix, customerid,subscription_paid,subscriptionid},token} = isAuthenticated()  // destructor from isAuthenticated or use isAuthenticated.user.role in list
    if(_id === TESTING_USER){
      stripePromise = loadStripe(STRIPE_PUBLIC_1);    
    }
    else {
        stripePromise = loadStripe(STRIPE_PUBLIC);
    }
    const [values, setValues] = useState({
      type:'',
      success:false,
      btnloading:false, 
      error:''
    })
    const [loading1, setLoading1] = useState(true)

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const { type, success,btnloading, error} = values

    const [ values1, setValues1 ] = useState({
      spinloading:false
    })

    const { spinloading } = values1;

    const [subValues, setSubValues] = useState({
      gold_price:'',
      silver_price:'',
      bronze_price:''
    })

    const { gold_price, silver_price, bronze_price} = subValues;

    const [showCancelled, setShowCancelled] = useState(false);
    const [ temp, setTemp] = useState('');

    const [values5, setValues5] = useState({
      cancelled_reason:''
    })

    const { cancelled_reason} = values5;

    const [notes, setNotes] = useState('');
    const [ btnloading1, setBtnloading1] = useState(false);
    const [showNotes, setShowNotes] = useState(false);

    const loadUser = () => {
      getUser(_id,token).then(data => {
          if(data.error){
              console.log("Sub log error")
              setOpen(false);
              setLoading1(false);
          }
          else {     
              if(data.subscription_paid !== subscription_paid){
                  authenticate({token, user:{_id:_id, prefix:prefix, name:name, lastname:lastname, email:email, role:role, customerid:customerid, subscriptionid:data.subscriptionid, subscription_paid:data.subscription_paid, isSubCancelled: data.isSubCancelled}},() => {})   
              }
              loadSubscriptions()
          }
      })
    }
    
    const init = () => {
        getUsersPendingOrders(_id).then(data => {
            if (data.error) {
                console.log(data.error);
                setLoading1(false);
                setOpen(false);
            } else {
                setHistory(data);
                setLoading1(false);
                setOpen(false);
            }
        });
    };

    const loadSubscriptions = () => {
      getSubscriptions().then(data => {
        if(data.error) {
            console.log("Sub Error");
        } else {
            var tempGold=0;
            var tempSilver=0;
            var tempBronze=0;
            for(var i=0;i<data.length;i++){
                if(data[i].plan === "Gold"){
                    tempGold = data[i].price;
                }
                else if(data[i].plan === "Silver"){
                    tempSilver = data[i].price;
                }
                else if(data[i].plan === "Bronze"){
                    tempBronze = data[i].price;
                }
            }
            setSubValues({
            ...subValues,
            gold_price:tempGold,
            silver_price:tempSilver,
            bronze_price:tempBronze,
            })       
            init()  
        }
      }) 
    }

    useEffect(() => {
      localStorage.removeItem('cart1');
      loadUser()
    }, []);

    const userInfo = () => {
        return (
            <div className="card mb-5">
            <h3 className="card-header">User Information</h3>
            <ul className="list-group">
                <li className="list-group-item">{name}</li>
                <li className="list-group-item">{email}</li>
                <li className="list-group-item">{role ===1 ? 'Admin' : 'Registered User'}</li>
            </ul>
        </div>
        );
    };

    const clickSubmit = (Id) => {
     
      setValues({...values, error:false, btnloading:true})
      updateWaitingOrderStatus(_id,token,{type:"1",Id})
      .then(data => {
        if(data.error) {
          setValues({...values, error:data.error, success:false});
        } else {
          setValues({
            ...values,
            type:'',
            error:'',
            btnloading:false,
            success:true
          })
          loadUser();
        }
      })
    }

    const localHandlePayment = async (orderid,final_price,preConfig,subscription_type,packageHead) => {
      setOpen(true);
      localStorage.setItem('checkValues',JSON.stringify({order:orderid, preconfig:preConfig, sub_type:subscription_type, title:packageHead, totPrice:final_price}))
      if(Number(subscription_type) === 1 || Number(subscription_type) === 0 || Number(subscription_type) === 2){
        localStorage.setItem('planValues',JSON.stringify({gPrice:gold_price, sPrice:silver_price, bPrice:bronze_price}))
      }
      return window.location.href="/checkout";      
    }

    const localAdHandlePayment = async(orderid,final_price,preConfig,subscription_type,packageHead,adID) => {
      setOpen(true);
      localStorage.setItem('AdcheckValues',JSON.stringify({order:orderid, preconfig:preConfig, sub_type:subscription_type, title:packageHead, totPrice:final_price, adID:adID}))
      if(Number(subscription_type) === 1 || Number(subscription_type) === 0 || Number(subscription_type) === 2){
          localStorage.setItem('AdplanValues',JSON.stringify({gPrice:gold_price, sPrice:silver_price, bPrice:bronze_price}))
      }
      return window.location.href="/ad/checkout";
    }

    const handleChangeforCancelled = name => event => {
      setValues5({ ...values5, [name]: event.target.value});
    }

    const clickSubmitCancelled = (event) => {
      event.preventDefault();
      setOpen(true);
      setValues5({...values5});
      updateCancelStatus(_id, token, {orderId:temp, status:"Order Cancelled",cancelled_reason:cancelled_reason}).then(
      data => {
          if (data.error) {
              console.log("Status update failed");
          } else {
              setShowCancelled(() => setShowCancelled(false))
              setTemp(() => setTemp(''));
              setValues5({
                ...values5,
                cancelled_reason:''               
              })
              
              loadUser();
          }
      }
      );

    }

    const clickSubmit2 = async (orderID1,paymentId,subscription_type,status,subscriptionId) => {
      
      if(window.confirm("Are you sure you want to continue?"))
      {
       
        setShowCancelled(()=>setShowCancelled(true));
        setTemp(() => setTemp(orderID1));
        var refundID="";
        var tempRefundID;
        var getRefund;
        if(status !== "Awaiting Payment")
        {

          const response = await fetch(`${API}/get-refund`, {
            method:'POST',
            headers: {'Content-Type': 'application/json',},
            body: JSON.stringify({ 
                'paymentid': String(paymentId),
                'userid': String(_id)             
                })
          })

          const payment = await response.json();

          getRefund = payment.data[0];
          if(getRefund !== undefined){
              tempRefundID = getRefund.id;

              const response1 = await fetch(`${API}/retrieve-refund`, {
                  method:'POST',
                  headers: {'Content-Type': 'application/json',},
                  body: JSON.stringify({ 
                      'ID': String(tempRefundID),
                      'userid': String(_id)             
                      })
              })

              const payment1 = await response1.json();

              refundID = payment1.id;
              
          }

          if((paymentId !=="") && (!isSubCancelled) && (subscription_paid) && (String(subscriptionId) === String(subscriptionid)) && (subscription_type === 1 || subscription_type === 0 || subscription_type === 2))
          {      
             
              const response = await fetch(`${API}/cancel-subscription`, {
                method:'POST',
                headers: {'Content-Type': 'application/json',},
                body: JSON.stringify({ 
                  'paymentid': String(subscriptionId),
                  'userid': String(_id)         
                  })
              });
              
              const deleted = await response.json();

              let updateuser = await updateUserSubscription(_id,token,{userId:_id, subscriptionid})

              if(refundID === ""){

                const refund = await fetch(`${API}/refund`, {
                  method:'POST',
                  headers: {'Content-Type': 'application/json',},
                  body: JSON.stringify({ 
                    'paymentintentid': String(paymentId),
                    'userid': String(_id)    
                    })
                });  
                const refunddetails = await refund.json();

              }  

              authenticate({token, user:{_id:_id,prefix:prefix,name:name,lastname:lastname,email:email,role:role,customerid:customerid,subscriptionid:subscriptionid,subscription_paid:false, isSubCancelled: false}},() => {})    
             
          }
          else if((paymentId !=="" && paymentId !== undefined && paymentId !== "null" && paymentId !== "undefined") && (subscription_type !== 1 || subscription_type !== 0)){
            if(refundID === ""){

              const refund = await fetch(`${API}/refund`, {
                method:'POST',
                headers: {'Content-Type': 'application/json',},
                body: JSON.stringify({ 
                  'paymentintentid': String(paymentId),
                  'userid': String(_id)    
                  })
              });  
              const refunddetails = await refund.json();

            }
             
          }

        }     
              
        return 0;  
      }

    }

    const showCancelModal = () => {
      return (
          <Modal show={showCancelled} /* onHide={() => setShowCancelled(false)} */>
              <Modal.Header /* closeButton */>
                  <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <form onSubmit={clickSubmitCancelled}>
                  <div className="form-group">
                      <label className="text-muted">Reason<span style={{color:"red"}}> *</span></label>
                      <input onChange={handleChangeforCancelled('cancelled_reason')} type="text" className="form-control" value={cancelled_reason} required/>
                  </div>
                  
                  <button  className="btn btn-outline-primary">Continue</button>
              </form>
              </Modal.Body>

          </Modal>
      )
  } 
  
  const handleChange_notes = (e) => {
    setNotes(e.target.value)
    setBtnloading1(false);
  };

  const clickNoteSubmit = (e) => {
    e.preventDefault();
    setBtnloading1(true);
    createNotes(_id,token,{user_notes:notes,orderid:temp})
    .then(data => {
      if(data.error) {
          setBtnloading1(false);
      } else {
        setShowNotes(false);
          setTemp(() => setTemp(''));
          loadUser();
          setBtnloading1(false);         
      }
    });
    
  }

  const clickNoteSubmit1 = (orderID2) => {
    setShowNotes(true);
    setTemp(() => setTemp(orderID2));
    return 0;
  }

  const showNotesModal = () => {
    return (
      
        <Modal show={showNotes} onHide={() => setShowNotes(false)}>
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form onSubmit={clickNoteSubmit}>
                <div className="form-group">
                    <label className="text-muted">Notes<span style={{color:"red"}}> *</span></label>
                    <input onChange={handleChange_notes} type="text" className="form-control" value={notes} autoFocus  required/>
                </div>
                
                <button disabled={btnloading1}  className="btn btn-outline-primary">{btnloading1 ? "Loading..." : "Add"}</button>
            </form>
            </Modal.Body>

        </Modal>

    )
  }

    const userOrders = () => {
        return (
          <div>
          {
            history.map(item => (
            <div className="col-md-12 px-0 mb-2">
            <div className="row">
              <div className="col-md-12 stretch-card">
                <div className="card position-relative card-shad-in">
                  <div className="card-body">
                    <div className="row estimated-bord">
                    <div className="col-md-6 blue-esti-head">
                    <p className="card-title blue-esti-head">Order Status : {item.status}</p>
                      {((item.status === "Awaiting Customer Pre-configuration") && (item.pre_config === 1)) && (<p className="card-title" style={{color:'red'}}>Please complete the preconfiguration for us to ship the product <button className="btn btn-info btn-gra-my-p" disabled={btnloading} onClick={() => clickSubmit(item._id)}>{btnloading ? "Loading..." : "Click here to complete pre-configuration now"}</button></p>)}
                    <p className="card-title blue-esti-head">Ordered Date :  <Moment format='DD/MM/YYYY'>{(item.createdAt)}</Moment></p>
                    </div>
                    <div className="col-md-6 text-right">
                      <p className="card-title blue-esti-head">Order Number: {item.orderid}</p>     
                      <p className="card-title blue-esti-head">Payment Status: {(item.status === "Awaiting Payment") ? <span style={{color:'red'}}>Waiting for Payment</span> : "Paid"}</p>    
                      <p className="card-title blue-esti-head">{((item.status === "Awaiting Payment") && (item.source === undefined || item.source === "undefined" || item.source === "" || item.source === null)) ? <button className="btn btn-info btn-gra-my-p" onClick={() => localHandlePayment(item._id, item.price,item.pre_config,item.subscription_type, item.title)}>Pay Now</button> : ((item.status === "Awaiting Payment") && (item.source)) && <button className="btn btn-info btn-gra-my-p" onClick={() => localAdHandlePayment(item._id, item.price,item.pre_config,item.subscription_type, item.title, item.adId)}>Pay Now</button>}</p>    
                    </div>
                    </div>
                    <div className="row">
                  
                      <div className="col-md-12 col-xl-6 d-flex flex-column justify-content-top">
                        <div className="ml-xl-4 pt-3">
                          <p className="products-text-in-dark">Products</p>
                          <h1 className="custo-sec-ad pt-2 pb-2">{/* {item.quantity} x */}{item.title}</h1>
                          <div className="row">
                            <div className="col-md-6">                           
                              <ul className="list-inline pr-name-air-sen">                              
                              <li dangerouslySetInnerHTML={{__html: item.order_description}}></li>                          
                              </ul>
                            </div> 
                          </div>
                        </div>  
                      </div>
                       <div className="col-md-12 col-xl-6 d-flex flex-column justify-content-top">
                        {item.shipping && 
                          <div className="ml-xl-4 pt-3">
                            <p className="products-text-in-dark">Delivery Address</p>
                            <h1 className="custo-sec-ad pt-2">{item.shipping.prefix}. {item.shipping.first_name} {item.shipping.last_name} | {item.shipping.phone}</h1>
                            <p>{item.shipping.house_no}</p>
                            <p>{item.shipping.area !== undefined && (item.shipping.area)}</p>
                            <p>{item.shipping.city}</p>
                            <p>{item.shipping.state !== undefined && (item.shipping.state)}</p>
                            <p>{item.shipping.country}</p>   
                            <p>{item.shipping.pin_code}</p>                          
                          </div>  
                          }
                      </div>
                    </div>
                  </div>
  
                </div>
  
              </div>
  
            </div>
            <div className="col-md-12" style={{backgroundColor: '#f5f5f5', border: 'solid 1px #e7e7e7', borderTop: '0px'}}>
              <div className="row">
                <div className="col-md-9 py-3">

                {item.shipping && <button onClick={() => clickNoteSubmit1(item._id)}  className="btn btn-info btn-gra-my-p" style={{borderRadius: '25px'}}>Add Notes</button>}
                
                {(item.pre_config === 0 && item.status === "Awaiting Customer Pre-configuration" && (item.title.includes("Customised Safely Package") || item.title.includes("Customised SafelyTeam Package") || item.adId)) ? (<a href={"/user/order/details/"+(item._id)} className="btn btn-info btn-gra-my-p" style={{borderRadius: '25px'}}>Complete pre-configuration now </a>) : ''}

                {((item.status === "Customer Pre-configuration Complete" || item.status === "Safely Pre-configuration Complete") && (item.title.includes("Customised Safely Package") || item.title.includes("Customised SafelyTeam Package") || item.adId))  ? (<span className="btn btn-info btn-gra-my-p" style={{borderRadius: '25px'}}>Pre-configuration completed </span>) : ''}

                {(item.status === "Customer Pre-configuration Complete" && (item.title.includes("Customised Safely Package")|| item.title.includes("Customised SafelyTeam Package") || item.adId))  ? (<a href={"/user/order/details/"+(item._id)} className="btn btn-info btn-gra-my-p" style={{borderRadius: '25px'}}>Edit</a>) : ''}

                {((item.status === "Awaiting Payment") && (item.title.includes("Customised Safely Package") || item.title.includes("Customised SafelyTeam Package") )) && (<a href={"/user/update/package/"+(item._id)}  className="btn btn-info btn-gra-my-p" style={{borderRadius: '25px'}}>Edit Package</a>)}

                {((item.status === "Awaiting Payment") && (item.adId) && (item.source === undefined || item.source === "undefined" || item.source === "" || item.source === null)) && (<a href={"/user/update/ads/package/"+(item._id)}  className="btn btn-info btn-gra-my-p" style={{borderRadius: '25px'}}>Edit Package</a>)}

                {(item.status === "Awaiting Payment" || item.status === "Customer Pre-configuration Complete" || item.status === "Safely Pre-configuration Complete" || item.status === "Awaiting Customer Pre-configuration") ? (<button onClick={() => clickSubmit2(item._id,item.paymentId,item.subscription_type,item.status, item.subscriptionId)}  className="btn btn-info btn-gra-my-p" style={{borderRadius: '25px'}}>Cancel Order</button>) : ''}



                  </div>
                <div className="col-md-3 pt-4">
                  <div className="row">
                    <div className="col-md-6 text-right">
                      <h3 className="total-font"> Total</h3>
                    </div>
                    <div className="col-md-6">
                    <h3 className="total-font-bl">£{item.price.toFixed(2)}</h3>          
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            ))
          }
         
          </div>
        );
    };

    const showError = () => (
      <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
          {error}
      </div>
  );

  const showNotfound = () => {
    return (
            <div className="col-md-12 px-0">
                <div className="col-md-12" style={{backgroundColor: '#f5f5f5', border: 'solid 1px #e7e7e7', borderTop: '0px'}}>
                    <br/>
                     <h4 style={{textAlign:'center',color:'red'}}>Pre-configuration is complete</h4>
                </div>
            </div>
    )
}
    

    return (

        <Fragment>
          <Helmet>
                <title>Safely Team - Pending Configuration</title>
            </Helmet>
             <div className="container-scroller">
                <Navbar />
                
                <div className="container-fluid page-body-wrapper">
    
                <Sidebar />

                        <div className="main-panel">
                          <div className="content-wrapper white-bg-ad">
                            <div className="row">
                              <div className="col-md-12 grid-margin">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <h4 className="font-weight-bold mb-0">Pending Configuration</h4>
                                  </div>
                                <div>  
                              </div>
                            </div>
                          </div>
                        </div>
                        {(loading1) ? <div className='loader-container'><div className='loader'></div></div> : ""}  
                       
                            {showError()}
                            {showCancelModal()}
                            {showNotesModal()}
                           {!loading1 && (history.length >= 1 ? userOrders() : showNotfound())}
                        {/* {showSuccess()} */}

                        <Backdrop className={classes.backdrop} open={open} >
                          <CircularProgress color="inherit" />
                        </Backdrop>   
                      </div> 
                    </div>

                </div>

        </div>

     
        </Fragment>
    )
};


export default PendingConfiguration;