import React, {useState, useEffect} from 'react'
import { Redirect } from 'react-router-dom';
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import {getReview, updateReview} from '../admin/apiAdmin'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const UpdateReview = ({match}) => {

    const classes = useStyles();

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState({
        reviewTitle:'',
        reviewDescription:'',
        postedBy:'',
        postedDate:'',
        country:'',
        rating:'',
        error:'',
        success:false,
        btnloading:false
    })

    const { reviewTitle, reviewDescription, postedBy, postedDate, country, rating, error, success, btnloading} = values;

    const { user, token} = isAuthenticated()

    const loadReview = (id) => {
        getReview(id).then(data => {
            if(data.error){
                setValues({...values, error:data.error, success:false})
                setLoading(false);
            }
            else {
                setValues({
                    ...values, 
                    reviewTitle: data.reviewTitle,
                    reviewDescription: data.reviewDescription,
                    postedBy: data.postedBy,
                    postedDate: data.postedDate.replace("T00:00:00.000Z",""),
                    country: data.country,
                    rating: data.rating
                })
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        loadReview(match.params.reviewId)
    },[])

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value, btnloading:false, success:false});
    };

    const clickSubmit = (e) => {
        e.preventDefault();
        setValues({...values, success:false, error:'', btnloading:true});
        updateReview(user._id, token, {id:match.params.reviewId, reviewTitle, reviewDescription, postedBy, postedDate, rating, country})
        .then(data => {
            if(data.error){
                setValues({...values, success:false, btnloading:false, error:data.error});
            }
            else {
                setValues({
                    ...values,
                    reviewTitle:'',
                    reviewDescription:'',
                    postedBy:'',
                    postedDate:'',
                    country:'',
                    rating:'',
                    error:'',
                    btnloading:false,
                    success: true
                })
            }
        })
    }

    const [ratingTypes, setRatingTypes] = useState([
        {label:"1", value:"1"},
        {label:"2", value:"2"},
        {label:"3", value:"3"},
        {label:"4", value:"4"},
        {label:"5", value:"5"}
    ])

    const reviewForm = () => (
        <form onSubmit={clickSubmit}>

            <div className="form-group">
                <label className="text-muted">Title<span style={{color:'red'}}> *</span></label>
                <input type="text" className="form-control" onChange={handleChange('reviewTitle')} value={reviewTitle} required />
            </div>

            <div className="form-group">
                <label className="text-muted">Decription<span style={{color:'red'}}> *</span></label>
                <textarea onChange={handleChange('reviewDescription')} className="form-control" value={reviewDescription} required />
            </div>

            <div className="form-group">
                <label className="text-muted">Posted By<span style={{color:'red'}}> *</span></label>
                <input type="text" className="form-control" onChange={handleChange('postedBy')} value={postedBy} required />
            </div>

            <div className="form-group">
                <label className="text-muted">Posted Date<span style={{color:"red"}}> *</span></label>           
                <input onChange={handleChange('postedDate')} type="date"  className="form-control" value={postedDate} required/>
            </div>

            <div className="form-group">
                <label className="text-muted">Country<span style={{color:'red'}}> *</span></label>
                <input type="text" className="form-control" onChange={handleChange('country')} value={country} required />
            </div>

            <div className="form-group">
                 <label className="text-muted">Rating<span style={{color:"red"}}> *</span></label>
                 <select required
                    onChange={handleChange('rating')} 
                    className="form-control">
                        <option >Select Rating</option>
                        {
                           ratingTypes.map(cus => (
                           (Number(rating) === Number(cus.value) ? <option key={cus.value} value={cus.value} selected>{cus.label}</option>
                           : <option key={cus.value} value={cus.value}>{cus.label}</option>)
                           ))
                       }
                 </select>
             </div>
             <button  className="btn btn-outline-primary" disabled={btnloading}> {btnloading ? "Loading..." : "Add"} </button>
        </form>
    )

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => {
        if(success) {
            return <Redirect to="/admin/reviews" />
        }
    };


    return (
        <AdminLayout className="container" topTitle="Update Trustpilot Review">
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title" style={{textAlign:'center'}}>Update Trustpilot Review</h4>
                            {showSuccess()}
                            {showError()}
                            {!loading && reviewForm()}
                            <Backdrop className={classes.backdrop} open={loading} >
                                <CircularProgress color="inherit" />
                            </Backdrop>  
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default UpdateReview
