import React, {useState} from 'react'
import { Redirect } from "react-router-dom"
import { createNewsletter } from './apiAdmin'
import AdminLayout from '../theme/admin/AdminLayout'

const AddNewsletter = () => {

    const [values, setValues] = useState({
        email:'',
        error:'',
        success:false,
        btnloading:false
    });

    const { btnloading, email, error, success } = values

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value,btnloading:false});
    };

    const clickSubmit = (e) => {
        e.preventDefault();
        setValues({...values, error: false,btnloading:true });
        createNewsletter({email})
        .then(data => {
            if(data.error) {
                setValues({...values, error: data.error, success:false,btnloading:false})
            } else {
                setValues({
                    ...values,
                    email:'',
                    reg_type: '',
                    error: '',
                    btnloading:false,
                    success: true
                })
            }
        });
    };

    const newsletterForm = () => (
     
        <form onSubmit={clickSubmit}>
            <div className="form-group">
                <label className="text-muted">Email<span style={{color:"red"}}> *</span></label>
                <input type="email" className="form-control" onChange={handleChange('email')} value={email} autoFocus required/>
                
            </div>

            <button disabled={btnloading} className="btn btn-outline-primary">
            {btnloading ? "Loading..." : "Add"}
            </button>
        </form>
        
    );

    const showSuccess = () => {
        if(success) {
            return <Redirect to="/admin/newsletters" />
        }
    };

    const showError = () => {
        if(error) {
            if(error === "11000 duplicate key error collection: STM.newsletters index: email already exists") {
                return <div className="title-mini stay-marg" style={{color:'red'}}>{email} already exists!</div>
            }
            else {
                return <div className="title-mini stay-marg" style={{color:'red'}}>{error}</div>
            }    
        }
    };

    return (
        <AdminLayout className="container" topTitle="Add Newsletter">
            <div className="row">  
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title" style={{textAlign:'center'}}>Add Newsletter</h4>
          
                            {showSuccess()}
                            {showError()}
                            {newsletterForm()}

                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default AddNewsletter
