import React, {useState, useEffect} from 'react'
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import Moment from 'react-moment';
import { getReview } from './apiAdmin'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const useStylesStar = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      '& > * + *': {
        marginTop: theme.spacing(1),
      },
    },
  }));

const ReviewDetails = ({match}) => {

    const classes = useStyles();

    const classesStar = useStylesStar();

    const [ loading, setLoading] = useState(true);

    const [reviews, setReviews] = useState([]);

    const [error, setError] = useState('');

    const loadReview = (id) => {
        getReview(id).then(data => {
            if(data.error){
                setError(data.error)
                setLoading(false);
            }
            else {
                setReviews(data);
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        loadReview(match.params.reviewId)
    },[])

    const showDetails = () => (
        <div className="row">

            <div className="col-md-12">
                <div className="col-md-12 bord-line">
                    <div className="col-md-10 py-3 wid-60-per-in">
                        
                        
                        
                        <div className="row">
                            <div className="col-md-8">
                                <span><b>Posted By : </b>{reviews.postedBy}</span><br/><br/>
                                
                                <span><b>Ratings : </b>
                                    <div className={classesStar.root}>
                                        <Rating name="size-medium" defaultValue={reviews.rating} readOnly />
                                    </div>       
                                </span><br/>
                            </div>
                            <div className="col-md-4">
                                <span><b>Posted Date : </b><Moment format='DD/MM/YYYY'>{reviews.postedDate}</Moment></span><br/><br/>
                                <span><b>Country : </b>{reviews.country}</span><br/><br/>
                            </div>

                            <div className="col-md-12">
                                <span><b>Title : </b>{reviews.reviewTitle}</span><br/><br/>
                                <span><b>Description : </b>{reviews.reviewDescription}</span><br/><br/>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    return (
        <AdminLayout className="container" topTitle="Trustpilot Review Details">
                    <div className="row">  
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title" style={{textAlign:'center'}}>Trustpilot Review Details</h4>
                                                                    
                                        {showError()}
                                        {!loading && showDetails()}

                                        <Backdrop className={classes.backdrop} open={loading} >
                                                <CircularProgress color="inherit" />
                                        </Backdrop>  

                                </div>
                            </div>
                        </div>
                    </div>
        </AdminLayout>
    )
}

export default ReviewDetails
