import React, {useState, useEffect} from 'react'
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { getActiveAds } from './apiAdmin'
import {FRONT_URL} from '../config'

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const CampaignUrl = () => {

    const [ spinloading, setSpinloading] = useState(true);

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const [ads, setAds] = useState([]);

    const [ values, setValues] = useState({
        adId:'',
        source:'',
        error:'',
        success: false
    })

    const { adId, source, error, success} = values;

    const [links, setLinks] = useState("");

    const loadAds = () => {
        getActiveAds().then(data => {
            if(data.error){
                console.log(data.error);
                setSpinloading(false)
            } else {
                setAds(data);
                setSpinloading(false)
            }
        })
    }

    useEffect(() => {
        loadAds()
    },[])

    const handleChange = name => event => {
        setValues({...values, [name]:event.target.value})
    }

    const clickSubmit = (e) => {
        setOpen(true);
        e.preventDefault();
        if(adId && source){
            setLinks(`${FRONT_URL}/source?id=${adId}`+`&source=`+source);
            setValues({...values, error:'',success:true})
            setOpen(false);
        }
        else {
            setValues({...values , error:"Please fill all the required fields.", success:false});
            setOpen(false);
        }
    }

    const adform = () => (
        <form onSubmit={clickSubmit}>

            <div className="form-group">
                <label className="text-muted">Packages<span style={{color:"red"}}> *</span></label>

                <select required onChange={handleChange('adId')} className="form-control">

                    <option>Select Package</option>{
                        ads && ads.map((c,i) => (
                            <option key={i} value={c._id}>{c.category_name}</option>
                        ))
                    }
                </select>
            </div>

            <div className="form-group">
                    <label className="text-muted">Source<span style={{color:"red"}}> *</span></label>
                        <select required
                            onChange={handleChange('source')} 
                            className="form-control">
                            <option >Select Source</option>
                            <option value="amazon">Amazon</option>
                            <option value="ebay">Ebay</option>
                        </select>
                </div>

            <button className="btn btn-outline-primary">Generate</button>

        </form>
    )


    const showSuccess = () => {
        if(success) {
            return <div><br/>

        <div className="alert alert-success" style={{ display: success ? '' : 'none' }}>
            <label className="text-muted">Generated Url:</label>
            <input type="text" className="form-control" value={links} />
        </div>
            
                
            </div>
        }
    };

    const showError = () => {
        if(error) {
            return <><br/><div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
                        {error}
                    </div></>
        }
    };

    return (
        <AdminLayout className="container" topTitle="Campaign Url">
            <div className="row">  
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title" style={{textAlign:'center'}}>Campaign Url</h4>
                            {(spinloading) ? <div className='loader-container'><div className='loader'></div></div> : ""}
                            {!spinloading && adform()}
                            {showSuccess()}
                            {showError()}

                            <Backdrop className={classes.backdrop} open={open} >
                                <CircularProgress color="inherit" />
                            </Backdrop>   

                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default CampaignUrl
