import React, {useState, useEffect, Fragment} from 'react'
import Subscribe from './Subscribe'
import {Redirect, Link} from 'react-router-dom'
import { getProduct,getAllProducts } from './Priorities/apiUser'
import { addItem } from './Priorities/cartHelpers'
import { isAuthenticated } from '../../auth/index'
import Card1 from './Priorities/Card1'
//import {Accordion,Card} from 'react-bootstrap'
import { Accordion,AccordionSummary,AccordionDetails,Typography,makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NewLayout from './NewLayout'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

  const useStylesBack = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const ProductDetails = ({match}) => {

    const classes = useStyles();

    const classesBack = useStylesBack();

    const [loading1, setLoading] = useState(true);

    const [prodName,setProdName] = useState("");

    const [product, setProduct] = useState([]);

    const [products1, setProducts1] = useState([]);

    const [redirect, setRedirect] = useState(false);

    const [values, setValues] = useState({
        btnloading:true
    });

    const {btnloading} = values

    const loadProduct = productId => {
        getProduct(productId).then(data => {
            if(data.error) {
                console.log(data.error);
                setValues({...values,  btnloading:false});
            } else {
                setProduct(data);
                let tempName="";
                tempName=data.name;
                tempName=tempName.replace(' ','-').replace(' ','-').replace(' ','-');
                tempName=tempName.toLocaleLowerCase();
                setProdName(tempName);
                setValues({...values,  btnloading:false});
            }
        });
       
    };

    const init = ids => {
        getAllProducts(ids).then(data => {
            if (data.error) {
                console.log(data.error);
                setLoading(false);
            } else {
                // populate the state
                setProducts1(data);   
                setTimeout(() => setLoading(false),1200)  
            }
        });
    };


    useEffect(() => {
        loadProduct(match.params.productId);
        init(setRelatedProducts(match.params.productId));
    }, [])

    const checkTitle = (id) => {
        
        if(id === "5f773c5c541f443e5ce64aaa"){ // Gateway
            return "Gateway | Smart Home Automation Solutions| SafelyTeam"
        }
        else if(id === "5f7b2d5bffa2800728e971b5"){  // Siren
            return "Siren | Best Smart Home Siren| SafelyTeam"
        }
        else if(id === "5f7b2ec3ffa2800728e971bd"){ // Motion Sensor
            return "Motion Sensor | Product Details | SafelyTeam"
        }
        else if(id === "5f7b2e6fffa2800728e971bb"){ // Heat Sensor
            return "Heat Alarm | Best Fire Prevention System| SafelyTeam"
        }
        else if(id === "5f7b302bffa2800728e971c5"){ // Flood Alarm
            return "Flood Alarm | Water Leak Alarm | SafelyTeam"
        }
        else if(id === "5f7b2fc3ffa2800728e971c3"){ // Smart Plug
            return "Smart Plug | Best Home Automation Solutions| SafelyTeam"
        }
        else if(id === "5f9d650ff268b98214e737bf"){ // Keypad
            return "Keypad | Best Smart Door Lock | SafelyTeam"
        }
        else if(id === "5f7b2f0bffa2800728e971bf"){ // Contact Sensor
            return "Contact Sensor | Best Anti-Theft Prevention System in UK | SafelyTeam"
        }
        else if(id === "5f7b2f74ffa2800728e971c1"){ // Smoke Alarm
            return "Smoke Alarm | Best Fire Prevention System In UK| SafelyTeam"
        }
        else if(id === "5f7b2dc6ffa2800728e971b7"){ // Air Quality Sensor
            return "Air Quality Sensor | Best Indoor Air Quality Sensor | SafelyTeam";
        }
        else if(id === "5f7b2e1effa2800728e971b9"){ // Panic Button
            return "Panic Button | Best Alarm Panic Button Key| SafelyTeam";
        }
        else if(id === "5f9d6592f268b98214e737c1"){ // Key Tags
            return "Key Tags | Best Smart Front Door Lock System | SafelyTeam";
        }
        return "Product Details - SafelyTeam";
    }

    const checkDescription = (id) => {
        
        if(id === "5f773c5c541f443e5ce64aaa"){ // Gateway
            return "SafelyTeam's gateway is included with all our systems. It connects all your devices together and enables control of your system using the SafelyTeam Connect app. Best Smart Home Automation system in UK. Buy now."
        }
        else if(id === "5f7b2d5bffa2800728e971b5"){ // Siren
           return "Our smart sirens are as loud as a rock concert, reaching 105dB - ensuring that your siren can be heard outside. Keep trespassers and intruders at bay 24/7/365. Best home security siren system available in Bristol."
        }
        else if(id === "5f7b2ec3ffa2800728e971bd"){ // Motion Sensor
            return "Motion Sensor is a key component that trigger an alarm in the event of intrusion, notify all users via the SafelyTeam Connect app and our dedicated service personnel will call you."
        }
        else if(id === "5f7b2e6fffa2800728e971bb"){ // Heat Sensor
            return "Our heat sensors are specially engineered to alert you to the risk of fires where smoke sensors would be too sensitive and cause false alarms. Best Accidental Fire Prevention system in Bristol, UK. Buy Now."
        }
        else if(id === "5f7b302bffa2800728e971c5"){ // Flood Alarm
            return "Our flood alarms can alert you to the earliest warning signs to avoid potential damage from leaky roofs, appliances, burst pipes and heavy rainfall.  Best smart water leak alarm system in Bristol."
        }
        else if(id === "5f7b2fc3ffa2800728e971c3"){ // Smart Plug
            return "The smart plug enables you to control your appliances and can extend the range of your system when placed strategically in your home. Mobile monitoring available. Buy Now"
        }
        else if(id === "5f9d650ff268b98214e737bf"){ // Keypad
            return "A Keypad can be placed near doors used to frequently enter and exit your property and can indicate to visitors that your home is secured and protected. Best smart door lock available in UK."
        }
        else if(id === "5f7b2f0bffa2800728e971bf"){ // Contact Sensor
            return "The contact sensor can be used to detect intrusions and to monitor activity of loved ones. Our smart contact sensor have a 9-year battery life and require zero maintenance. Best Anti-theft protection system for 24/7 home security. Mobile Monitoring available"
        }
        else if(id === "5f7b2f74ffa2800728e971c1"){ // Smoke Alarm
            return "Whether inside or outside your home, our smoke alarm will trigger a siren and send an instant app notification to users. Our 24/7 service personnel will also contact you..."
        }
        else if(id === "5f7b2dc6ffa2800728e971b7"){ // Air Quality Sensor
            return "Our unique air quality sensor monitors the Volatile Organic Compound (VOC) levels in your home. This ensures that your living environment is safe and secure 24/7/365.";
        }
        else if(id === "5f7b2e1effa2800728e971b9"){ // Panic Button
            return "Our panic buttons are built to last with a 10-year battery life and a durable design. With the touch of a button, instant panic alerts can be sent to loved ones in emergency.";
        }
        else if(id === "5f9d6592f268b98214e737c1"){ // Key Tags
            return "Our key tags make arming and disarming your home even easier. Just tap the card on the keypad and select your system arm state to instantly secure your home.";
        }
        return "Product Description - SafelyTeam";
    }

    const setRelatedProducts = (Id) => {
        let list = [];
        if(Id === "5f7b2ec3ffa2800728e971bd") // Motion Sensor - [Contact sensor, Air Quality Sensor, Flood Alarm]
        {
            list = ["5f7b2f0bffa2800728e971bf","5f7b2dc6ffa2800728e971b7","5f7b302bffa2800728e971c5"];
        }
        else if(Id === "5f7b2f74ffa2800728e971c1") // Smoke Alarm - [Heat sensor, Flood Alarm, Air Quality Sensor]
        {
            list = ["5f7b2e6fffa2800728e971bb","5f7b302bffa2800728e971c5","5f7b2dc6ffa2800728e971b7"];
        }
        else if(Id === "5f7b2d5bffa2800728e971b5") // Siren - [Keypad, Contact sensor , Motion Sensor]
        {
            list = ["5f9d650ff268b98214e737bf","5f7b2f0bffa2800728e971bf","5f7b2ec3ffa2800728e971bd"];
        }
        else if(Id === "5f7b2e1effa2800728e971b9") //Panic Button - [Air Quality Sensor, Smart Plug]
        {
            list = ["5f7b2dc6ffa2800728e971b7","5f7b2fc3ffa2800728e971c3"];
        }
        else if(Id === "5f7b2e6fffa2800728e971bb") // Heat Sensor - [Smoke Alarm, Air Quality Sensor, Flood Alarm]
        {
            list = ["5f7b2f74ffa2800728e971c1","5f7b2dc6ffa2800728e971b7","5f7b302bffa2800728e971c5"];
        }
        else if(Id === "5f7b302bffa2800728e971c5") // Flood Alarm - [Heat Sensor, Smoke Alarm, Air Quality Sensor]
        {
            list = ["5f7b2e6fffa2800728e971bb","5f7b2f74ffa2800728e971c1","5f7b2dc6ffa2800728e971b7"];
        }
        else if(Id === "5f7b2f0bffa2800728e971bf") // Contact Sensor - [Motion Sensor, Siren, Panic Button]
        {
            list = ["5f7b2ec3ffa2800728e971bd","5f7b2d5bffa2800728e971b5","5f7b2e1effa2800728e971b9"];
        }
        else if(Id === "5f7b2dc6ffa2800728e971b7") // Air Quality Sensor - [Motion Sensor, Flood Alarm, Panic Button]
        {
            list = ["5f7b2ec3ffa2800728e971bd","5f7b302bffa2800728e971c5","5f7b2e1effa2800728e971b9"];
        }
        else if(Id === "5f773c5c541f443e5ce64aaa") // Gateway - [Keypad, Smart Plug, Siren]
        {
            list = ["5f9d650ff268b98214e737bf","5f7b2fc3ffa2800728e971c3","5f7b2d5bffa2800728e971b5"];
        }
        else if(Id === "5f9d650ff268b98214e737bf") // Keypad - [Siren, Contact Sensor]
        {
            list = ["5f7b2d5bffa2800728e971b5","5f7b2f0bffa2800728e971bf"];
        }
        else if(Id === "5f7b2fc3ffa2800728e971c3") // Smart Plug - [Gateway]
        {
            list = ["5f773c5c541f443e5ce64aaa"];
        }
        return list;
    }

    const addToCart = () => {
        addItem(product, setRedirect(true));
    }
    

    const shouldRedirect = redirect => {
        if(redirect) {
            return window.location.href="/revieworder"; 
            //return <Redirect to="/revieworder" />
        }
    }
  
    const imageHeader = () => {
        return (
                
                    <div className="row border-who">
                       
                        {(btnloading) ? <div className='loader-container'><div className='loader'></div></div> : ""}  
                            {product._id === "5f7b2ec3ffa2800728e971bd" && ( //Motion sensor
                                <div className="col-md-6 px-0">
                                    <img src={require('./img/motion-sensor.png')} className="img-fluid" alt="Motion Sensor Alarm | Best Home Security Systems In UK" /> 
                                </div>
                            )}

                            {product._id === "5f7b2f74ffa2800728e971c1"  && ( //Smoke alaram 
                                <div className="col-md-6 p-5 text-center">
                                    <img src={require('./img/product/smoke-sensor/top/smoke-alarm.png')} className="img-fluid" alt="Smoke Alarm| Best protection from accidental fire" /> 
                                </div>
                            )}
                            
                            {product._id === "5f7b2d5bffa2800728e971b5"  && ( //Siren
                                <div className="col-md-6 text-center pt-5">
                                    <img src={require('./img/product/siren/top/smart-siren.png')} className="img-fluid" alt="Home Security Siren - Quick Notification & Alerts" /> 
                                </div>
                            )}

                            

                            {product._id === "5f7b2e1effa2800728e971b9"  && ( //Panic Button
                                <div className="col-md-6 px-0">
                                    <img src={require('./img/product/panic-button/top/panic_button_front.png')} className="img-fluid" alt="Panic Button - wireless emergency panic button systems" /> 
                                </div>
                            )}

                            {product._id === "5f7b2e6fffa2800728e971bb"  && ( //Heat Sensor
                                 <div className="col-md-6 px-0">
                                    <img src={require('./img/product/heat-sensor/top/heat-sensor.png')} className="img-fluid" alt="Heat Alarm | 24/7 Protection from accidental fire" />
                                </div>
                            )}

                            {product._id === "5f7b302bffa2800728e971c5"  && ( //Flood Alarm
                                <div className="col-md-6 px-0" style={{backgroundColor: '#FFF'}}>
                                    <img src={require('./img/product/flood-sensor/top/flood-alarm-in.png')} className="img-fluid" alt="Flood Alarm - water leak detection alarm systems" /> 
                                </div>
                            )}

                            {product._id === "5f7b2f0bffa2800728e971bf"  && ( //Contact Sensor
                            
                                <div className="col-md-6 p-5 text-center">
                                    <img src={require('./img/product/contact-sensor/top/conta-img.png')} className="img-fluid" alt="Contact Sensor | Home security Automation" /> 
                                </div>
                            
                            )}

                            {product._id === "5f7b2dc6ffa2800728e971b7"  && ( //Air Quality Sensor
                                 <div className="col-md-6 px-0">
                                    <img src={require('./img/product/air-quality-sensor/air-q.png')} className="img-fluid" alt="Air Quality Sensor | home wellness technology" /> 
                                </div>
                            )}
                           
                            {product._id === "5f9d650ff268b98214e737bf" && ( // Keypad
                                <div className="col-md-6 p-5 text-center">
                                <img src={require('./img/product/keypad/top/keypad.png')} className="img-fluid" alt="Keypad- Arm and disarm your system" /> 
                            </div>
                            )}

                            {/* Smart Plug */}
                            {product._id === "5f7b2fc3ffa2800728e971c3" && ( //Smart Plug
                                <div className="col-md-6 p-5 text-center">
                                <img src={require('./img/product/smart-plug/top/smart-plug.png')} className="img-fluid" alt="Smart Plug - Smart Home Security Devices" /> 
                            </div>
                            )}

                            {/* Gateway */}
                            {product._id === "5f773c5c541f443e5ce64aaa" && ( //Gateway
                                <div className="col-md-6 p-5 text-center">
                                <img src={require('./img/product/gateway/top/gateway.png')} className="img-fluid" alt="Gateway - Brain of your home security system" /> 
                            </div>
                            )}

                            {product._id === "5f9d6592f268b98214e737c1"  && ( //KeyTag 
                                <div className="col-md-6 px-0">
                                    <img src={require('./img/product/key-tags/top/Key-tag-main.jpg')} className="img-fluid" alt="Key Tags - best keyless door lock" /> 
                                </div>
                            )}
                            
                            
                       
                        <div className="col-md-6 pl-5 pt-5">
                            <h3 className="font-hd-on">{product.name}</h3>
                          {product._id !== "5f773c5c541f443e5ce64aaa" && <h3 className="font-hd-on">£ {product.sale_price}.00</h3>}  
                            <p className="inner-line newtext">  {/* Contact Sensor */}
                                {product._id === "5f7b2f0bffa2800728e971bf" ? (<span>Your doors and windows are the barriers that separate your home from unwanted guests and monitoring them is essential to detect
                                                                intrusion. Our contact sensors can be placed on doors and windows and will, when armed:<br/>

                                                                <ul>
                                                                    <li>Trigger a burglar alarm</li>
                                                                    <li>Send an app notification to all users</li>
                                                                    <li>Alert the alarm monitoring centre</li>
                                                                </ul>

                                                                You can do more than just secure windows with our contact sensors. For example, place them on medicine cabinets and use the app to
                                                                monitor when a loved one has taken their medicine, or check if you left an important drawer open in the office.</span>)
                                                                : (product.long_description)}
                              
                            </p>
                            <div className="row pt-5">
                                <div className="col-md-3 pt-1">
                       
                                </div>
                                {isAuthenticated() && product._id !== "5f773c5c541f443e5ce64aaa" ? (
                                     <div className="col-md-6"> <button onClick={addToCart} className={prodName+' '+'btn btn-success pull-right mb-3'}>Add to cart</button></div>
                                ) : (
                                    <div className="col-md-6"> <Link to="/step1"> <button className="btn btn-success pull-right mb-3">Design your personalised system</button></Link></div>
                                )}
                               
                            </div>
                        </div>
                    </div>

        )
    }


    const motionSensor = () => {
        return (
            <div>
                <div className="row ptb-inner">
                <div className="col-md-10 px-0 pt-5 mx-auto">
                    <div className="row">
                        <div className="col-lg-4 center-whole pl-5-inner">
                            <div className="award-item">

                                <div className="award-item-logo-in"><img src={require('./img/vectors/motion-sensor/icon1-01.svg')} width="110" alt="" /></div>
                                <div className="award-item-descr slide-descr mar-lt-ico">24/7 Security​​ <br/>for your home</div>

                            </div>
                        </div>

                        <div className="col-lg-4 center-whole pl-5-inner">
                            <div className="award-item">

                                <div className="award-item-logo-in"><img src={require('./img/vectors/motion-sensor/icon2-01.svg')} width="110" alt="" /></div>
                                <div className="award-item-descr slide-descr mar-lt-ico">Monitor Activity <br/>around you or your <br/> loved one's home​</div>

                            </div>
                        </div>

                        <div className="col-lg-4 center-whole pl-5-inner">
                            <div className="award-item">

                                <div className="award-item-logo-in"><img src={require('./img/vectors/motion-sensor/icon3-01.svg')} width="110" alt="" /></div>
                                <div className="award-item-descr slide-descr mar-lt-ico">Create your <br/> ultimate smart home​</div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

          
            <div className="row ptb-inner px-0">
                <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
                    <h3 className="alerts-txt">
                    A watchful eye for your home and family<br/>
                    </h3>
                    <p className="inner-line newtext">The motion sensor will act as a powerful deterrent to intruders. It
can instantly alert you of unwanted activity in your home and can also be used to monitor activity of loved
ones without invading privacy.</p>
                </div>
                <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/child.jpg')} className="img-fluid" alt="" /> </div>
                <div className="col-md-6 mb-5 pl-0 plr-0px tp-menu-show"> <img src={require('./img/product/motion-sensor/triples/one.jpg')} className="img-fluid" alt="" /> </div>
                <div className="col-md-6 pr-ann-left al-tp mb-5 plr-0px text-mini-mbile">
                    <h3 className="alerts-txt">
                    Built-in temperature sensor<br/>
                        
                    </h3>
                    <p className="inner-line newtext">Our motion sensors can also serve as an excellent way to monitor the
temperature around your home and create smart rules. For example, you can set your heaters to turn on
when the room temperature falls below 20°C.</p>
                </div>
                <div className="col-md-6 mb-5 pl-0 plr-0px sh-img"> <img src={require('./img/product/motion-sensor/triples/one.jpg')} className="img-fluid" alt="" /> </div>
                <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
                    <h3 className="alerts-txt">Tamper Proof </h3>
                    <p className="inner-line newtext">Our app instantly alerts you of sabotage if the sensor detects that it is being tampered with.</p>
                </div>
                <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/old-age.png')} className="img-fluid" alt="" /> </div>
                
          </div>
            <div className="row">
            <div className="col-md-6 pl-0 pr-0">
                    <img src={require('./img/product/motion-sensor/mid/motion-sens.png')} className="img-fluid" alt="" />
                </div>
                <div className="col-md-6 bg-in-lt pl-5 py-5">

                    <ul className="list-inline" style={{marginLeft: '2rem'}}>

                        <li className="list-in">3 Year Battery life</li>
                        <li className="list-in">Built-in temperature sensor</li>
                        <li className="list-in">Tamper Proof</li>
                        <li className="list-in">Indicator light for motion</li>
                        <li className="list-in">Battery status indicator</li>
                        <li className="list-in">Small and compact design</li>
                        <li className="list-in">Multiple mounting options
(standing, flat on a wall, flat on
a ceiling, corner of a wall)</li>

                    </ul>
                </div>
                
            </div>
            <div className="col-md-12 ptb-inner text-mini-mbile mb-know mb-tech">
                <h2 className="font-in-head tech-sp font-in-head-inn ml-minus"></h2>
            </div>

            {/* <div className="row">
                <div className="col-md-12">
                   
                            
                        <Accordion >
                            <Accordion.Toggle className="accordian"  eventKey="0" as={Card.Header}>
                                <h3 className="title">
                                <span className="fa fa-plus icon-size-in"></span>
                                Technical Specifications
                                </h3>
                            </Accordion.Toggle>
                            <br/>

                            <Accordion.Collapse className="col-md-12" eventKey="0">
                            <div className="row">
                            <div className="col-md-4 px-0 pt-5 pl-list-gen">
                                <h4 className="font-gen">General</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Dimesions: 70 X 70 X 21 mm</li>
                                    <li className="font-nor">Colour: White</li>
                                </ul>
                                <h4 className="font-gen">Power Supply</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Battery: 2 x AA, exchangeable</li>
                                    <li className="font-nor">Battery life: 3 years, reporting every 2 minutes</li>
                                    <li className="font-nor">
                                        Battery level and low battery warning can be reported
                                    </li>
                                </ul>
                                <h4 className="font-gen">Radio Features</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Sensitivity: -92 dBm</li>
                                    <li className="font-nor">Output power: +3 dBm</li>
                                </ul>
                                <h4 className="font-gen">Environment</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">IP className: IP30</li>
                                    <li className="font-nor">Operation temperature 0 to +50°C</li>
                                    <li className="font-nor">Relative humidity 5% - 85%, non condensing</li>
                                </ul>
                                <h4 className="font-gen">Specification Occupancy and <br/>alarm sensor</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Sensitivity range: 9 m</li>
                                    <li className="font-nor">View angle: 45° up/down, left/right</li>
                                    <li className="font-nor">Off-time: configurable 2 s - 65,000 s</li>
                                    <li className="font-nor">Two trigger levels: one for occupancy and one for alarm</li>
                                </ul>
                            </div>
                            <div className="col-md-4 pt-5"> <img src={require('./img/product/motion-sensor/round/specifi-image.png')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-4 pl-3 pt-5">
                                <h4 className="font-gen">Specification light sensor</h4>
                                <ul className="pl-3 mb-dimesions-btm">
                                    <li className="font-nor">Resolution: dark, light, bright</li>
                                    <li className="font-nor">Sample time: config.: 2 s -65,000 s</li>
                                    <li className="font-nor">Reporting: configurable</li>

                                </ul>
                                <h4 className="font-gen">Specification temperature sensor</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Range: 0 to +50°C</li>
                                    <li className="font-nor">Resolution: 0.1°C (accuracy Typ ±0.5°C and Max ±2°C)</li>
                                    <li className="font-nor">Sample time: config.: 2 s -65,000 s</li>
                                    <li className="font-nor">Reporting: configurable</li>

                                </ul>
                                <h4 className="font-gen">Communication</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Zigbee Home Automation</li>
                                    <li className="font-nor">Zigbee end-device</li>

                                </ul>
                                <h4 className="font-gen">Certification</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">
                                        Conforming to CE, FCC, IC, ISED, RED, RoHS and REACH directives
                                    </li>
                                    <li className="font-nor">
                                        Zigbee Home Automation 1.2 certified
                                    </li>

                                </ul>
                            </div>
                        </div>
                        
                            </Accordion.Collapse>
                        </Accordion>
                   
                </div>
            </div> */}

    <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}><b style={{fontSize:'1rem'}}>Technical Specifications</b></Typography>
        </AccordionSummary>
        <AccordionDetails style={{marginLeft:'10px'}}>
          <Typography>
          <div className="row">
                            <div className="col-md-4 px-0 pt-5 pl-list-gen">
                                <h4 className="font-gen">General</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Dimesions: 70 X 70 X 21 mm</li>
                                    <li className="font-nor">Colour: White</li>
                                </ul>
                                <h4 className="font-gen">Power Supply</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Battery: 2 x AA, exchangeable</li>
                                    <li className="font-nor">Battery life: 3 years, reporting every 2 minutes</li>
                                    <li className="font-nor">
                                        Battery level and low battery warning can be reported
                                    </li>
                                </ul>
                                <h4 className="font-gen">Radio Features</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Sensitivity: -92 dBm</li>
                                    <li className="font-nor">Output power: +3 dBm</li>
                                </ul>
                                <h4 className="font-gen">Environment</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">IP className: IP30</li>
                                    <li className="font-nor">Operation temperature 0 to +50°C</li>
                                    <li className="font-nor">Relative humidity 5% - 85%, non condensing</li>
                                </ul>
                                <h4 className="font-gen">Specification Occupancy and <br/>alarm sensor</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Sensitivity range: 9 m</li>
                                    <li className="font-nor">View angle: 45° up/down, left/right</li>
                                    <li className="font-nor">Off-time: configurable 2 s - 65,000 s</li>
                                    <li className="font-nor">Two trigger levels: one for occupancy and one for alarm</li>
                                </ul>
                            </div>
                            <div className="col-md-4 pt-5"> <img src={require('./img/product/motion-sensor/round/specifi-image.png')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-4 pl-3 pt-5">
                                <h4 className="font-gen">Specification light sensor</h4>
                                <ul className="pl-3 mb-dimesions-btm">
                                    <li className="font-nor">Resolution: dark, light, bright</li>
                                    <li className="font-nor">Sample time: config.: 2 s -65,000 s</li>
                                    <li className="font-nor">Reporting: configurable</li>

                                </ul>
                                <h4 className="font-gen">Specification temperature sensor</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Range: 0 to +50°C</li>
                                    <li className="font-nor">Resolution: 0.1°C (accuracy Typ ±0.5°C and Max ±2°C)</li>
                                    <li className="font-nor">Sample time: config.: 2 s -65,000 s</li>
                                    <li className="font-nor">Reporting: configurable</li>

                                </ul>
                                <h4 className="font-gen">Communication</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Zigbee Home Automation</li>
                                    <li className="font-nor">Zigbee end-device</li>

                                </ul>
                                <h4 className="font-gen">Certification</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">
                                        Conforming to CE, FCC, IC, ISED, RED, RoHS and REACH directives
                                    </li>
                                    <li className="font-nor">
                                        Zigbee Home Automation 1.2 certified
                                    </li>
                                </ul>
                            </div>
                        </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br/>              
        </div>

        )
    }

    const smokeAlarm = () => {
        return (
            <div>

                    <div className="row ptb-inner">
                        <div className="col-md-10 px-0 pt-5 mx-auto">
                            <div className="row">
                                <div className="col-lg-4 center-whole pl-5-inner">
                                    <div className="award-item">

                                        <div className="award-item-logo-in"><img src={require('./img/vectors/smoke-sensor/icon1-01.svg')} width="110" alt="" /></div>
                                        <div className="award-item-descr slide-descr mar-lt-ico">Detects and alerts <br/> you of fires with​ <br/> an 85dB alarm</div>

                                    </div>
                                </div>

                                <div className="col-lg-4 center-whole pl-5-inner">
                                    <div className="award-item">

                                        <div className="award-item-logo-in"><img src={require('./img/vectors/smoke-sensor/icon2-01.svg')} width="110" alt="" /></div>
                                        <div className="award-item-descr slide-descr mar-lt-ico">Monitor <br/> room <br/> temperature​</div>

                                    </div>
                                </div>

                                <div className="col-lg-4 center-whole pl-5-inner">
                                    <div className="award-item">

                                        <div className="award-item-logo-in"><img src={require('./img/vectors/smoke-sensor/icon3-01.svg')} width="110" alt="" /></div>
                                        <div className="award-item-descr slide-descr mar-lt-ico">Stylish​ <br/> and compact<br/> design</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                      
                      

                        <div className="row ptb-inner px-0">
                            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">
                                Elegant and compact design<br/>
                                </h3>
                                <p className="inner-line newtext">Our smoke sensor is modern, compact, and seamlessly
blends into its environment. </p>
                            </div>
                            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/smoke-sen-1.png')} className="img-fluid"  alt="" /> </div>
                            <div className="col-md-6 mb-5 pl-0 plr-0px tp-menu-show"> <img src={require('./img/product/smoke-sensor/triples/one.jpg')} className="img-fluid"  alt="" /> </div>
                            <div className="col-md-6 pr-ann-left al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">Temperature Monitoring</h3>
                                <p className="inner-line newtext">The smoke alarm also has a built-in thermometer which can be
used to monitor the temperature in the room and control smart appliances through a
smart plug.</p>
                            </div>
                            <div className="col-md-6 mb-5 pl-0 plr-0px sh-img"> <img src={require('./img/product/smoke-sensor/triples/one.jpg')} className="img-fluid"  alt="" /> </div>
                            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">3 Year Battery life</h3>
                                <p className="inner-line newtext">Our smoke alarm comes with a 3-year battery life. We can also alert
you when your batteries are low and recommend when to replace them.</p>
                            </div>
                            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/smoke-sen-3.png')} className="img-fluid"  alt="" /> </div>
                        </div>


                        <div className="row">
                            <div className="col-md-6 pl-0 pr-0">
                                <img src={require('./img/product/smoke-sensor/mid/sm-smoke.png')} className="img-fluid"  alt="" />
                            </div>
                            <div className="col-md-6 bg-in-lt pl-5 py-5">

                                <ul className="list-inline" style={{marginLeft: '2rem'}}>

                                    <li className="list-in">3 Year Battery life</li>
                                    <li className="list-in">Battery status indicator</li>
                                    <li className="list-in">Temperature Sensor</li>
                                    <li className="list-in">Small and compact design</li>
                                    <li className="list-in">85dB siren</li>
                                    <li className="list-in">No holes or drilling required</li>

                                </ul>
                            </div>
                            
                        </div>
                       <div className="col-md-12 ptb-inner text-mini-mbile mb-know mb-tech">
                            <h2 className="font-in-head tech-sp font-in-head-inn ml-minus"></h2>
                        </div>

                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography className={classes.heading}><b style={{fontSize:'1rem'}}>Technical Specifications</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{marginLeft:'10px'}}>
                        <Typography>

                        <div className="row">
                            <div className="col-md-4 px-0 pt-5 pl-list-gen">
                                <h4 className="font-gen">General</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Dimensions: Ø65 x 40 mm</li>
                                    <li className="font-nor">Colour: White</li>

                                </ul>
                                <h4 className="font-gen">Power Supply</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Battery: 1 x CR123, exchangeable</li>
                                    <li className="font-nor">Battery life: 3 years, reporting every 5 minutes</li>
                                    <li className="font-nor">
                                        Battery level and low battery warning can be reported
                                    </li>

                                </ul>
                                <h4 className="font-gen">Radio Features</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Sensitivity: -98 dBm</li>
                                    <li className="font-nor">Output power: +8 dBm</li>

                                </ul>
                                <h4 className="font-gen">Environment</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">IP className: IP20</li>
                                    <li className="font-nor">Operation temperature: 0 to +50°C</li>
                                    <li className="font-nor">Relative humidity 10-95% non-condensing</li>
                                </ul>



                            </div>
                            <div className="col-md-4 pt-5"> <img src={require('./img/product/smoke-sensor/round/smoke-round.png')} className="img-fluid"  alt="" /> </div>
                            <div className="col-md-4 pl-3 pt-5">
                                <h4 className="font-gen">Specification Smoke sensor</h4>
                                <ul className="pl-3 mb-dimesions-btm">
                                    <li className="font-nor">Optical</li>
                                    <li className="font-nor">Siren output 85 dB/3m</li>

                                </ul>
                                <h4 className="font-gen">Specification temperature sensor</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Range: 0 to +50°C</li>
                                    <li className="font-nor">Resolution: 0.1°C (accuracy Typ ±0.5°C and Max ±2°C)</li>
                                    <li className="font-nor">Sample time: config.: 2 s -65,000 s</li>
                                    <li className="font-nor">Reporting: configurable</li>


                                </ul>
                                <h4 className="font-gen">Communication</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Zigbee Home Automation</li>
                                    <li className="font-nor">Zigbee end-device</li>

                                </ul>
                                <h4 className="font-gen">Certification</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">
                                        Conforming to CE, RED, EMC and RoHS directives
                                    </li>
                                    <li className="font-nor">
                                        DIN EN 14604 and DIN 14676 certified
                                    </li>
                                    <li className="font-nor">
                                        Zigbee Home Automation 1.2 certified
                                    </li>

                                </ul>

                            </div>
                        </div>

                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <br/>                  
            </div>
        )
    }


    const siren = () => {
        return (

            <div>

                        <div className="row ptb-inner">
                            <div className="col-md-10 px-0 pt-5 mx-auto">
                                <div className="row">
                                    <div className="col-lg-4 center-whole pl-5-inner">
                                        <div className="award-item">

                                            <div className="award-item-logo-in"><img src={require('./img/vectors/siren/icon1-01.svg')} width="110" alt="" /></div>
                                            <div className="award-item-descr slide-descr mar-lt-ico">Deter​ intruders <br/> with a <br/>105dB siren</div>

                                        </div>
                                    </div>

                                    <div className="col-lg-4 center-whole pl-5-inner">
                                        <div className="award-item">

                                            <div className="award-item-logo-in"><img src={require('./img/vectors/siren/icon2-01.svg')} width="110" alt="" /></div>
                                            <div className="award-item-descr slide-descr mar-lt-ico">Extend the <br/>  range of your <br/> SafelyTeam system</div>

                                        </div>
                                    </div>

                                    <div className="col-lg-4 center-whole pl-5-inner">
                                        <div className="award-item">

                                            <div className="award-item-logo-in"><img src={require('./img/vectors/siren/icon3-01.svg')} width="110" alt="" /></div>
                                            <div className="award-item-descr slide-descr mar-lt-ico">Adjustable siren <br/>volume​ to suit your <br/> home and family</div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                      
                      
                        <div className="row ptb-inner px-0">
                            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">
                                Keep intruders at bay<br/>
                                </h3>
                                <p className="inner-line newtext">The 105dB alarm makes sure our smart siren can be heard inside and outside your
home, to detect unwanted guests & burglars throughout the year.</p>
                            </div>
                            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/siren-img-1.png')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-6 mb-5 pl-0 plr-0px tp-menu-show"> <img src={require('./img/product/siren/triples/three.jpg')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-6 pr-ann-left al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">12-hour battery backup</h3>
                                <p className="inner-line newtext">In the event of a power loss, the siren can operate as expected for up to 12 hours.</p>
                            </div>
                            <div className="col-md-6 mb-5 pl-0 plr-0px sh-img"> <img src={require('./img/product/siren/triples/three.jpg')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">Suitable for every home</h3>
                                <p className="inner-line newtext">The smart siren can be modified to suit any home environment with our adjustable
sound levels.</p>
                            </div>
                            <div className="col-md-6 mb-5 plr-0px">  <img src={require('./img/product/siren/triples/two.jpg')} className="img-fluid" alt="" />  </div>



                        </div>
                        <div className="row">
                            <div className="col-md-6 pl-0 pr-0">
                                <img src={require('./img/product/siren/mid/sm-siren.png')} className="img-fluid" alt="" />
                            </div>
                            <div className="col-md-6 bg-in-lt pl-5 py-5">

                                <ul className="list-inline" style={{marginLeft: '2rem'}}>

                                    <li className="list-in">System Range extender</li>
                                    <li className="list-in">12-hour battery back-up</li>
                                    <li className="list-in">Mains Power indicator</li>
                                    <li className="list-in">Battery backup power indicator</li>
                                    <li className="list-in">Audible feedback of countdown for entry and exit time</li>
                                    <li className="list-in">Configurable sound settings</li>

                                </ul>
                            </div>
                            
                        </div>
                        <div className="col-md-12 ptb-inner text-mini-mbile mb-know mb-tech">
                            <h2 className="font-in-head tech-sp font-in-head-inn ml-minus"></h2>
                        </div>

                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography className={classes.heading}><b style={{fontSize:'1rem'}}>Technical Specifications</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{marginLeft:'10px'}}>
                        <Typography>

                        <div className="row">
                            <div className="col-md-4 px-0 pt-5 pl-list-gen">
                                <h4 className="font-gen">General</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Dimensions: 119 x 49 x 23 mm</li>
                                    <li className="font-nor">Colour: White</li>

                                </ul>
                                <h4 className="font-gen">Power</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Power supply: 230V</li>
                                    <li className="font-nor">Power Consumption 0.4W</li>

                                </ul>
                                <h4 className="font-gen">Radio</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Sensitivity: -100 dBm</li>
                                    <li className="font-nor">Output power: +12 dBm</li>

                                </ul>
                                <h4 className="font-gen">Environment</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">IP className: IP20</li>
                                    <li className="font-nor">Operation temperature 0 to +50°C</li>
                                </ul>
                            </div>
                            <div className="col-md-4 pt-5"> <img src={require('./img/product/siren/round/siren-center.png')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-4 pl-3 pt-5">
                                <h4 className="font-gen">Range extension</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Zigbee 3.0 MESH</li>
                                </ul>
                                <h4 className="font-gen">Siren</h4>
                                <ul className="pl-3 mb-dimesions-btm">
                                    <li className="font-nor">3.2 kHz, 90 dBm @ 1m</li>
                                </ul>
                                <h4 className="font-gen">Voice</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Voice prompt: 50 sound clips</li>
                                    <li className="font-nor">Voice quality: Smartphone Speaker</li>
                                </ul>
                                <h4 className="font-gen">Communication</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Wireless protocol: Zigbee 3.0</li>
                                    <li className="font-nor">Zigbee router</li>

                                </ul>
                                <h4 className="font-gen">Certification</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">
                                        Conforming to CE, RED, EMC, Low Voltage and RoHS directives
                                    </li>
                                </ul>
                            </div>

                        </div>

                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <br/>   

            </div>

           
        )
    }


    const panicButton = () => {
        return (
            <div>
                <div className="row ptb-inner">
                            <div className="col-md-10 px-0 pt-5 mx-auto">
                                <div className="row">
                                    <div className="col-lg-4 center-whole pl-5-inner">
                                        <div className="award-item">

                                            <div className="award-item-logo-in"><img src={require('./img/vectors/panic-button/icon1-01.svg')} width="110" alt="" /></div>
                                            <div className="award-item-descr slide-descr mar-lt-ico">Assistance at​ <br/>your fingertips </div>

                                        </div>
                                    </div>

                                    <div className="col-lg-4 center-whole pl-5-inner">
                                        <div className="award-item">

                                            <div className="award-item-logo-in"><img src={require('./img/vectors/panic-button/icon2-01.svg')} width="110" alt="" /></div>
                                            <div className="award-item-descr slide-descr mar-lt-ico">Water resistant​ <br/> in all <br/> environments</div>

                                        </div>
                                    </div>

                                    <div className="col-lg-4 center-whole pl-5-inner">
                                        <div className="award-item">

                                            <div className="award-item-logo-in"><img src={require('./img/vectors/panic-button/icon3-01.svg')} width="110" alt="" /></div>
                                            <div className="award-item-descr slide-descr mar-lt-ico">Small, compact​​, <br/> practical  <br/> and modern</div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row ptb-inner px-0">
                            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">
                                Built to last<br/>
                                </h3>
                                <p className="inner-line newtext">Our panic buttons are built to last with a 10-year battery life
and a durable design.</p>
                            </div>
                            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/panic-btn-1.png')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-6 mb-5 pl-0 plr-0px tp-menu-show"> <img src={require('./img/product/panic-button/triples/one.jpg')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-6 pr-ann-left al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">Guaranteed Protection</h3>
                                <p className="inner-line newtext">Our panic buttons can be used anywhere from in
the bath to out in the garden. With excellent wireless range, the panic
button will always be there to protect you in case of emergency.</p>
                            </div>
                            <div className="col-md-6 mb-5 pl-0 plr-0px sh-img"> <img src={require('./img/product/panic-button/triples/one.jpg')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">Total peace of mind</h3>
                                <p className="inner-line newtext">With the touch of a button, instant panic alerts can
be sent to loved ones, granting independence and ultimate peace of mind to
friends and family.</p>
                            </div>
                            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/panic-btn-3.png')} className="img-fluid" alt="" /> </div>



                        </div>
                        <div className="row">
                            <div className="col-md-6 pl-0 pr-0">
                                <img src={require('./img/product/panic-button/mid/pan-btn.png')} className="img-fluid" alt="" />
                            </div>
                            <div className="col-md-6 bg-in-lt pl-5 py-5">

                                <ul className="list-inline" style={{marginLeft: '2rem'}}>

                                    <li className="list-in">Stay connected with loved ones</li>
                                    <li className="list-in">Water-resistant</li>
                                    <li className="list-in">Included neck strap, wrist strap and sticky pad</li>
                                    <li className="list-in">Small and Compact Design</li>
                                    <li className="list-in">Up to 10-year battery life</li>
                                    <li className="list-in">Ultimate peace of mind</li>
                                    <li className="list-in">Battery status indicator</li>

                                </ul>
                            </div>
                            
                        </div>
                       <div className="col-md-12 ptb-inner text-mini-mbile mb-know mb-tech">
                            <h2 className="font-in-head tech-sp font-in-head-inn ml-minus"></h2>
                        </div>

                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography className={classes.heading}><b style={{fontSize:'1rem'}}>Technical Specifications</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{marginLeft:'10px'}}>
                        <Typography>

                        <div className="row">
                            <div className="col-md-4 px-0 pt-5 pl-list-gen">
                                <h4 className="font-gen">General</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Dimensions: 40 x 53 x 10,8 mm</li>
                                    
                                </ul>
                                <h4 className="font-gen">Power Supply</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Battery: CR2450 (Coin cell), up to 10 years of battery life</li>
                                </ul>
                                <h4 className="font-gen">Radio</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Sensitivity: - 100 dBm</li>
                                    <li className="font-nor">Output Power: + 10 dBm</li>

                                </ul>
                                <h4 className="font-gen">Environment</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Water-resistant when used in the bathtub and shower</li>
                                    <li className="font-nor">Operation Temperature: 0 – 50 oC</li>
                                    <li className="font-nor">Relative humidity 5 % - 85 % non condensing</li>
                                </ul>
                               
                            </div>
                            <div className="col-md-4 pt-5"> <img src={require('./img/product/panic-button/round/panic-center.png')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-4 pl-3 pt-5">
                                <h4 className="font-gen">Communication</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Wireless protocol: Zigbee 3.0</li>
                                </ul>
                                <h4 className="font-gen">Certification</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Conforming to CE, RED, EMC, RoHS and REACH directives</li>
                                    <li className="font-nor">Zigbee 3.0 certified</li>
                                </ul>
                                <h4 className="font-gen">Lanyard</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Dimensions (LxWxT): 440 x 10 x 1.2 mm</li>
                                    <li className="font-nor">Material: Polyester + ABS</li>
                                    <li className="font-nor">Colour: Black</li>
                                    <li className="font-nor">Safety feature: Safety break release clip</li>
                                </ul>
                                <h4 className="font-gen">Wristband (included)</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Dimensions (LxWxT): 280 x 22 x 1.05 mm</li>
                                    <li className="font-nor">Material: Nylon + 304 stainless steel</li>
                                    <li className="font-nor">Colour: Black</li>
                                </ul>
                            </div>

                        </div>

                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <br/>   
                        
            </div>
        )
    }


    const heatSensor = () => {
        return (
            <div>
                 <div className="row ptb-inner">
                            <div className="col-md-10 px-0 pt-5 mx-auto">
                                <div className="row">
                                    <div className="col-lg-4 center-whole pl-5-inner">
                                        <div className="award-item">

                                            <div className="award-item-logo-in"><img src={require('./img/vectors/heat-sensor/icon1-01.svg')} width="110" alt="" /></div>
                                            <div className="award-item-descr slide-descr mar-lt-ico">Prevent damage<br/> to your <br/> home</div>

                                        </div>
                                    </div>

                                    <div className="col-lg-4 center-whole pl-5-inner">
                                        <div className="award-item">

                                            <div className="award-item-logo-in"><img src={require('./img/vectors/heat-sensor/icon2-01.svg')} width="110" alt="" /></div>
                                            <div className="award-item-descr slide-descr mar-lt-ico">Control  <br/> your <br/> smart home</div>

                                        </div>
                                    </div>

                                    <div className="col-lg-4 center-whole pl-5-inner">
                                        <div className="award-item">

                                            <div className="award-item-logo-in"><img src={require('./img/vectors/heat-sensor/icon3-01.svg')} width="110" alt="" /></div>
                                            <div className="award-item-descr slide-descr mar-lt-ico">Excellent  <br/> battery <br/> life</div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                      
                      
                        <div className="row ptb-inner px-0">
                            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">
                                Minimise false alarms<br/>
                                </h3>
                                <p className="inner-line newtext">Our heat sensors are specially engineered to alert you to
the risk of fires where smoke sensors would be too sensitive and cause false
alarms.</p>
                            </div>
                            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/heat-in-1.png')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-6 mb-5 pl-0 plr-0px tp-menu-show"> <img src={require('./img/product/heat-sensor/triples/one.jpg')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-6 pr-ann-left al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">Compact and easy to install</h3>
                                <p className="inner-line newtext">This wireless heat alarm comes with a ready-to-use
sticky pad and can be installed anywhere in just a few seconds</p>
                            </div>
                            <div className="col-md-6 mb-5 pl-0 plr-0px sh-img"> <img src={require('./img/product/heat-sensor/triples/one.jpg')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">Excellent battery life</h3>
                                <p className="inner-line newtext">Our heat sensors come with a 5-year battery life. We can
also alert you when your batteries are low and recommend when to replace them. </p>
                            </div>
                            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/heat-in-3.png')} className="img-fluid" alt="" /> </div>



                        </div>
                        <div className="row">
                            <div className="col-md-6 pl-0 pr-0">
                                <img src={require('./img/product/heat-sensor/mid/mid11.jpg')} className="img-fluid" alt="" />
                            </div>
                            <div className="col-md-6 bg-in-lt pl-5 py-5">

                                <ul className="list-inline" style={{marginLeft: '2rem'}}>

                                    <li className="list-in">5-year battery life</li>
                                    <li className="list-in">Battery status indicator</li>
                                    <li className="list-in">Temperature sensor</li>
                                    <li className="list-in">No holes or drilling required</li>
                                    <li className="list-in">85dB siren</li>
                                    <li className="list-in">Small and compact design</li>
                                    <li className="list-in">Minimise false alarms</li>

                                </ul>
                            </div>
                            
                        </div>
                        <div className="col-md-12 ptb-inner text-mini-mbile mb-know mb-tech">
                            <h2 className="font-in-head tech-sp font-in-head-inn ml-minus"></h2>
                        </div>

                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography className={classes.heading}><b style={{fontSize:'1rem'}}>Technical Specifications</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{marginLeft:'10px'}}>
                        <Typography>

                        <div className="row">
                            <div className="col-md-4 px-0 pt-5 pl-list-gen">
                                <h4 className="font-gen">General</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Dimensions: Ø 65 x 40 mm</li>
                                    <li className="font-nor">Colour: White</li>

                                </ul>
                                <h4 className="font-gen">Power Supply</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Battery: 1 x CR123A, exchangeable</li>
                                    <li className="font-nor">Battery life: 5 years, reporting every 5 minutes</li>
                                    <li className="font-nor">
                                        Battery level and low battery warning can be reported
                                    </li>

                                </ul>
                                <h4 className="font-gen">Radio Features</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Sensitivity: -98 dBm</li>
                                    <li className="font-nor">Output power: +8 dBm</li>

                                </ul>
                                <h4 className="font-gen">Environment</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">IP className: IP20</li>
                                    <li className="font-nor">Operation temperature 0 to +50°C</li>
                                    <li className="font-nor">Relative humidity up to 95% non-condensing</li>
                                </ul>

                            </div>
                            <div className="col-md-4 pt-5"> <img src={require('./img/product/heat-sensor/round/specifi-heat.png')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-4 pl-3 pt-5">
                                <h4 className="font-gen">Specification Fire sensor</h4>
                                <ul className="pl-3 mb-dimesions-btm">
                                    <li className="font-nor">Heat based fire sensing</li>
                                    <li className="font-nor">Siren output 85 dB/3m</li>

                                </ul>
                                <h4 className="font-gen">Specification temperature sensor</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Range: 0 to +50°C</li>
                                    <li className="font-nor">Resolution: 0.1°C (accuracy Typ ±0.5°C and Max ±2°C)</li>
                                    <li className="font-nor">Reporting: configurable</li>

                                </ul>
                                <h4 className="font-gen">Communication</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Zigbee Home Automation</li>
                                    <li className="font-nor">Zigbee end-device</li>

                                </ul>
                                <h4 className="font-gen">Certification</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">
                                        Conforming to CE, RED, EMC and RoHS directives
                                    </li>
                                    <li className="font-nor">
                                        DIN EN 14604 and DIN 14676 certified
                                    </li>
                                    <li className="font-nor">
                                        Zigbee Home Automation 1.2 certified
                                    </li>

                                </ul>


                            </div>

                        </div>

                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <br/>   

            </div>
        )
    }

    const floodAlarm = () => {
        return (
            <div>
                <div className="row ptb-inner">
                            <div className="col-md-10 px-0 pt-5 mx-auto">
                                <div className="row">
                                    <div className="col-lg-4 center-whole pl-5-inner">
                                        <div className="award-item">

                                            <div className="award-item-logo-in"><img src={require('./img/vectors/flood-alarm/icon1-01.svg')} width="110" alt="" /></div>
                                            <div className="award-item-descr slide-descr mar-lt-ico">Prevent expensive ​ <br/> accidents <br/> in your home</div>

                                        </div>
                                    </div>

                                    <div className="col-lg-4 center-whole pl-5-inner">
                                        <div className="award-item">

                                            <div className="award-item-logo-in"><img src={require('./img/vectors/flood-alarm/icon2-01.svg')} width="110" alt="" /></div>
                                            <div className="award-item-descr slide-descr mar-lt-ico">Instant​ Mobile <br/> alerts</div>

                                        </div>
                                    </div>

                                    <div className="col-lg-4 center-whole pl-5-inner">
                                        <div className="award-item">

                                            <div className="award-item-logo-in"><img src={require('./img/vectors/flood-alarm/icon3-01.svg')} width="110" alt="" /></div>
                                            <div className="award-item-descr slide-descr mar-lt-ico">Extra loud siren​ <br/> and long-lasting <br/> battery life</div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                     
                     
                        <div className="row ptb-inner px-0">
                            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">
                                85dB siren<br/>
                                </h3>
                                <p className="inner-line newtext">This extra loud siren will alert you at the earliest sign of a water leak. If you're
away from home, our instant notifications can help you mitigate any damage to your
home before it gets too late.</p>
                            </div>
                            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/flood-al-1.png')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-6 mb-5 pl-0 plr-0px tp-menu-show"> <img src={require('./img/product/flood-sensor/triples/one.jpg')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-6 pr-ann-left al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">Long-lasting battery life</h3>
                                <p className="inner-line newtext">The flood sensor has exceptional battery capabilities so you can
rest easy and trust this sensor to look after your home wherever you are.</p>
                            </div>
                            <div className="col-md-6 mb-5 pl-0 plr-0px sh-img"> <img src={require('./img/product/flood-sensor/triples/one.jpg')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">Wide ranging uses</h3>
                                <p className="inner-line newtext">Our flood sensors have excellent signal range and should be placed
around your home in areas such as kitchens, bathrooms, attics and utility rooms to help
protect your home and family.</p>
                            </div>
                            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/flood-al-2.png')} className="img-fluid" alt="" /> </div>



                        </div>
                        <div className="row">
                            <div className="col-md-6 pl-0 pr-0">
                                <img src={require('./img/product/flood-sensor/mid/flood-alm.png')} className="img-fluid" alt="" />
                            </div>
                            <div className="col-md-6 bg-in-lt pl-5 py-5">

                                <ul className="list-inline" style={{marginLeft: '2rem'}}>

                                    <li className="list-in">Protect your home from expensive
accidents</li>
                                    <li className="list-in">85dB siren</li>
                                    <li className="list-in">Small and compact design</li>
                                    <li className="list-in">Instant alerts via the SafelyTeam Connect
App</li>
                                    <li className="list-in">5-year battery life</li>
                                    <li className="list-in">Easy installation anywhere in your
home</li>

                                </ul>
                            </div>
                            
                        </div>
                       <div className="col-md-12 ptb-inner text-mini-mbile mb-know mb-tech">
                            <h2 className="font-in-head tech-sp font-in-head-inn ml-minus"></h2>
                        </div> 

                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography className={classes.heading}><b style={{fontSize:'1rem'}}>Technical Specifications</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{marginLeft:'10px'}}>
                        <Typography>

                        <div className="row">
                            <div className="col-md-4 px-0 pt-5 pl-list-gen">
                                <h4 className="font-gen">General</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Dimensions: Ø 60 x 37 mm</li>
                                    <li className="font-nor">Colour: White</li>

                                </ul>
                                <h4 className="font-gen">Power Supply</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Battery: 1 x CR123A, exchangeable</li>
                                    <li className="font-nor">Battery life: 5 years, reporting every 5 minutes</li>
                                    <li className="font-nor">
                                        Battery level and low battery warning can be reported
                                    </li>

                                </ul>
                                <h4 className="font-gen">Radio Features</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Sensitivity: -98 dBm</li>
                                    <li className="font-nor">Output power: +8 dBm</li>

                                </ul>
                                <h4 className="font-gen">Environment</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">IP className: IP20</li>
                                    <li className="font-nor">Operation temperature 0 to +50°C</li>
                                    <li className="font-nor">Relative humidity up to 95% non-condensing</li>
                                </ul>
                                <h4 className="font-gen">Range</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Minimum 100 meters (open field)</li>
                                </ul>
                                <h4 className="font-gen">Specification Alarm</h4>
                                <ul className="pl-3 mb-dimesions-btm">
                                    <li className="font-nor">Siren output 85 dB/3m</li>
                                </ul>
                            </div>
                            <div className="col-md-4 pt-5"> <img src={require('./img/product/flood-sensor/round/specifi-flood.png')} className="img-fluid"  alt="" /> </div>
                            <div className="col-md-4 pl-3 pt-5">

                                <h4 className="font-gen">Specification liquid detector</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Default reporting every 5 minutes</li>
                                    <li className="font-nor">Immediate reporting in alarm mode</li>
                                </ul>
                                <h4 className="font-gen">Specification temperature sensor</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Range: 0 to +50°C</li>
                                    <li className="font-nor">Resolution: 0.1°C (accuracy Typ ±0.5°C and Max ±2°C)</li>
                                    <li className="font-nor">Sample time: config.: 2 s -65,000 s</li>
                                    <li className="font-nor">Reporting: configurable</li>
                                </ul>
                                <h4 className="font-gen">Communication</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Zigbee Home Automation</li>
                                    <li className="font-nor">Zigbee end-device</li>

                                </ul>
                                <h4 className="font-gen">Certification</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">
                                        Conforming to CE, FCC, RED, EMC and RoHS directives
                                    </li>
                                    <li className="font-nor">
                                        Zigbee Home Automation 1.2 certified
                                    </li>
                                </ul>
                               

                            </div>
                        </div>

                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <br/>   
            </div>
        )
    }


    const contactSensor = () => {
        return (
            <div>
                <div className="row ptb-inner">
                            <div className="col-md-10 px-0 pt-5 mx-auto">
                                <div className="row">
                                    <div className="col-lg-4 center-whole pl-5-inner">
                                        <div className="award-item">

                                            <div className="award-item-logo-in"><img src={require('./img/vectors/contact-sensor/icon1-01.svg')} width="110" alt="" /></div>
                                            <div className="award-item-descr slide-descr mar-lt-ico">24/7 Security <br/>for your doors and <br/> private cabinets</div>

                                        </div>
                                    </div>

                                    <div className="col-lg-4 center-whole pl-5-inner">
                                        <div className="award-item">

                                            <div className="award-item-logo-in"><img src={require('./img/vectors/contact-sensor/icon2-01.svg')} width="110" alt="" /></div>
                                            <div className="award-item-descr slide-descr mar-lt-ico">Immediate <br/>  Alerts​ To <br/> intrusion</div>

                                        </div>
                                    </div>

                                    <div className="col-lg-4 center-whole pl-5-inner">
                                        <div className="award-item">

                                            <div className="award-item-logo-in"><img src={require('./img/vectors/contact-sensor/icon3-01.svg')} width="110" alt="" /></div>
                                            <div className="award-item-descr slide-descr mar-lt-ico">Remote <br/> monitoring without​ <br/>  invading privacy</div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                      
                      
                        <div className="row ptb-inner px-0">
                            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">
                                Monitor activity in your home<br/>
                                </h3>
                                <p className="inner-line newtext">The contact sensor can be used to detect intrusions and to monitor activity of loved ones.</p>
                            </div>
                            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/conta-sen2.png')} className="img-fluid"  alt="" /> </div>
                            <div className="col-md-6 mb-5 pl-0 plr-0px tp-menu-show"> <img src={require('./img/product/contact-sensor/triples/one.jpg')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-6 pr-ann-left al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">Built-in temperature sensors</h3>
                                <p className="inner-line newtext">As well as alerting you to intrusion, the contact sensor can be used to monitor your home
environment and setup smart home rules.</p>
                            </div>
                            <div className="col-md-6 mb-5 pl-0 plr-0px sh-img"> <img src={require('./img/product/contact-sensor/triples/one.jpg')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">Excellent battery life</h3>
                                <p className="inner-line newtext">Our extremely efficient contact sensors have a 9-year battery life and require zero maintenance.</p>
                            </div>
                            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/conta-sen3.png')} className="img-fluid" alt="" /> </div>



                        </div>
                        <div className="row">
                            <div className="col-md-6 pl-0 pr-0">
                                <img src={require('./img/product/contact-sensor/mid/cont-mid.png')} className="img-fluid" alt="" />
                            </div>
                            <div className="col-md-6 bg-in-lt pl-5 py-5">

                                <ul className="list-inline" style={{marginLeft: '2rem'}}>

                                    <li className="list-in">9 Year Battery life</li>
                                    <li className="list-in">Battery status indicator</li>
                                    <li className="list-in">Temperature Sensor</li>
                                    <li className="list-in">Tamper Proof</li>
                                    <li className="list-in">LED to indicate when a door is
opened or closed</li>
                                    <li className="list-in">Current state (open/closed)
display on app</li>
                                    <li className="list-in">Last activity reading on app</li>
                                    <li className="list-in">Small and compact design</li>

                                </ul>
                            </div>
                            
                        </div>
                       <div className="col-md-12 ptb-inner text-mini-mbile mb-know mb-tech">
                            <h2 className="font-in-head tech-sp font-in-head-inn ml-minus"></h2>
                        </div>

                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography className={classes.heading}><b style={{fontSize:'1rem'}}>Technical Specifications</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{marginLeft:'10px'}}>
                        <Typography>

                        <div className="row">
                            <div className="col-md-4 px-0 pt-5 pl-list-gen">
                                <h4 className="font-gen">General</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Sensor: 76 x 26 x 17 mm</li>
                                    <li className="font-nor">Magnet: 30 x 12 x 9 mm</li>
                                    <li className="font-nor">Colour: White</li>
                                </ul>
                                <h4 className="font-gen">Power Supply</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Battery: 2 x AAA, exchangeable</li>
                                    <li className="font-nor">Battery life: 9 years of battery life, when reporting every 2 min</li>
                                    <li className="font-nor">Battery level and low battery warning can be reported</li>
                                </ul>
                                <h4 className="font-gen">Radio</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Sensitivity: -98 dBm</li>
                                    <li className="font-nor">Output power: +8 dBm</li>

                                </ul>
                                <h4 className="font-gen">Environment</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">IP className: IP40</li>
                                    <li className="font-nor">Operation temperature: 0 to +50°C</li>
                                    <li className="font-nor">Relative humidity 5% - 85%, non condensing</li>
                                </ul>

                            </div>
                            <div className="col-md-4 pt-5"> <img src={require('./img/product/contact-sensor/round/conta-round.png')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-4 pl-3 pt-5">
                                <h4 className="font-gen">Specifications Temperature sensor</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Range: 0 to +50°C</li>
                                    <li className="font-nor">Resolution: 0.1°C (accuracy Typ ±0.5°C and Max ±2°C)</li>
                                    <li className="font-nor">Sample time: config.: 2 s - 65,000 s</li>
                                    <li className="font-nor">Reporting: configurable</li>
                                </ul>
                                <h4 className="font-gen">Specifications detection sensor</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Magnetic: 0.1 - 1.0 cm</li>
                                </ul>
                                <h4 className="font-gen">Communication</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Wireless protocol: Zigbee Home Automation</li>
                                    <li className="font-nor">Wireless protocol: Zigbee end-device</li>

                                </ul>
                                <h4 className="font-gen">Certification</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Conforming to CE, FCC, IC, ISED, RED, and RoHS directives</li>
                                    <li className="font-nor">Zigbee Home Automation 1.2 certified</li>
                                </ul>
                            </div>
                        </div>

                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <br/>   

                        
            </div>
        )
    }

    const airQuality = () => {
        return (
            <div>
                <div className="row ptb-inner">
                            <div className="col-md-10 px-0 pt-5 mx-auto">
                                <div className="row">
                                    <div className="col-lg-4 center-whole pl-5-inner">
                                        <div className="award-item">

                                            <div className="award-item-logo-in"><img src={require('./img/vectors/aqs/icon1-01.svg')} width="110" alt="" /></div>
                                            <div className="award-item-descr slide-descr mar-lt-ico">Create a <br/>safe and healthy <br/> home environment​</div>

                                        </div>
                                    </div>

                                    <div className="col-lg-4 center-whole pl-5-inner">
                                        <div className="award-item">

                                            <div className="award-item-logo-in"><img src={require('./img/vectors/aqs/icon2-01.svg')} width="110" alt="" /></div>
                                            <div className="award-item-descr slide-descr mar-lt-ico">24/7 VOC, <br/> temperature and humidity <br/> monitoring</div>

                                        </div>
                                    </div>

                                    <div className="col-lg-4 center-whole pl-5-inner">
                                        <div className="award-item">

                                            <div className="award-item-logo-in"><img src={require('./img/vectors/aqs/icon3-01.svg')} width="110" alt="" /></div>
                                            <div className="award-item-descr slide-descr mar-lt-ico">Create smart <br/> rules and scenes <br/> to control your home</div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                 
                     
                        <div className="row ptb-inner px-0">
                            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">
                                Keep your living area clean and fresh<br/>
                                </h3>
                                <p className="inner-line newtext">Since today most people spend 90% of their time
indoors, the quality of indoor air is critical to human health, work performance, and comfort. By
monitoring the levels of volatile organic compounds (VOCs), you can monitor indoor air quality
and ensure that the air inside a room is clean and healthy.</p>
                            </div>
                            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/product/air-quality-sensor/triples/one.jpg')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-6 mb-5 pl-0 plr-0px tp-menu-show"> <img src={require('./img/product/air-quality-sensor/triples/two.jpg')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-6 pr-ann-left al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">Temperature and humidity monitoring</h3>
                                <p className="inner-line newtext">With built in temperature and humidity sensors, our
unique air quality sensors can be used to help prevent mould from harming your home and family.</p>
                            </div>
                            <div className="col-md-6 mb-5 pl-0 plr-0px sh-img"> <img src={require('./img/product/air-quality-sensor/triples/two.jpg')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">Prevent long-term health effects from harmful compounds</h3>
                                <p className="inner-line newtext">Indoor air is often up to 5x more
harmful than outside air. With instant alerts to poor air quality and recommendations for
improving it, our air quality sensors can help to keep your family healthy for years to come.</p>
                            </div>
                            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/product/air-quality-sensor/triples/three.jpg')} className="img-fluid" alt="" /> </div>



                        </div>
                        <div className="row">
                            <div className="col-md-6 pl-0 pr-0">
                                <img src={require('./img/product/air-quality-sensor/mid/tech-top.png')} className="img-fluid" alt="" />
                            </div>
                            <div className="col-md-6 bg-in-lt pl-5">

                                <ul className="list-inline" style={{marginLeft: '2rem',marginTop:'30px'}}>

                                    <li className="list-in">Protect your family and environment</li>
                                    <li className="list-in">Enhanced environmental monitoring</li>
                                    <li className="list-in">Prevent long-term harm</li>
                                    <li className="list-in">Easy to install anywhere</li>
                                    <li className="list-in">Small and compact design</li>
                                    <li className="list-in">Instant notifications</li>
                                    <li className="list-in">2 Year Battery life</li>
                                    <li className="list-in">Temperature sensor</li>
                                    <li className="list-in">Humidity sensor</li>
                                    <li className="list-in">VOC level sensor</li>

                                </ul>
                            </div>
                            
                        </div>
                        <div className="col-md-12 ptb-inner text-mini-mbile mb-know mb-tech">
                            <h2 className="font-in-head tech-sp font-in-head-inn ml-minus"></h2>
                        </div> 
                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography className={classes.heading}><b style={{fontSize:'1rem'}}>Technical Specifications</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{marginLeft:'10px'}}>
                        <Typography>

                        <div className="row">
                            <div className="col-md-4 px-0 pt-5 pl-list-gen">
                                <h4 className="font-gen">General</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Dimensions: 70 x 70 x 21 mm</li>
                                    <li className="font-nor">Colour: White</li>
                                </ul>
                                <h4 className="font-gen">Power Supply</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Battery: 2 x AA, exchangeable</li>
                                    <li className="font-nor">Battery life: 2 years, reporting every 5 minutes</li>
                                    <li className="font-nor">Resolution: 0.1 Volt</li>
                                </ul>
                                <h4 className="font-gen">Radio features</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Sensitivity: -97 dBm</li>
                                    <li className="font-nor">Output power: up to +7 dBm</li>

                                </ul>
                                <h4 className="font-gen">Environment</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Operation temperature 0 to +50°C</li>
                                    <li className="font-nor">IP className: IP20</li>
                                </ul>
                                <h4 className="font-gen">Specifications VOC sensor</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Range: 0 to 60000 ppb</li>
                                    <li className="font-nor">Resolution: 1 - 32 ppb</li>
                                    <li className="font-nor">Reporting: configurable</li>
                                </ul>
                            </div>
                            <div className="col-md-4 pt-5"> <img src={require('./img/product/air-quality-sensor/round/air-round.png')} className="img-fluid" alt="" /> </div>
                            <div className="col-md-4 pl-3 pt-5">

                                <h4 className="font-gen">Specifications Temperature sensor</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Range: 0 to +50°C</li>
                                    <li className="font-nor">Resolution: 0.1°C (accuracy typical ± 0.2°C)</li>
                                    <li className="font-nor">Sample time: 30 s</li>
                                    <li className="font-nor">Reporting: configurable</li>
                                </ul>
                                <h4 className="font-gen">Specifications Humidity sensor</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Range: 0 to 100% RH</li>
                                    <li className="font-nor">Resolution: 1% RH (accuracy typical 2%, 20-80% RH)</li>
                                </ul>
                                <h4 className="font-gen">Communication</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Wireless protocol: Zigbee Home Automation</li>
                                    <li className="font-nor">Wireless protocol: Zigbee end-device</li>

                                </ul>
                                <h4 className="font-gen">Certification</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Conforming to CE, RED, EMC, RoHS and REACH directives</li>
                                    <li className="font-nor">Zigbee 3.0 certified</li>
                                </ul>
                            </div>
                        </div>


                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <br/>   
            </div>
        )
    }

    const keyPad = () => {
        return (
            <div>

                <div className="row ptb-inner">
                    <div className="col-md-10 px-0 pt-5 mx-auto">
                        <div className="row">
                            <div className="col-lg-4 center-whole pl-5-inner">
                                <div className="award-item">

                                    <div className="award-item-logo-in"><img src={require('./img/vectors/keypad/icon-01.svg')} width="110" alt="" /></div>
                                    <div className="award-item-descr slide-descr mar-lt-ico">Control your <br/>SafelyTeam home <br/> Security system​</div>

                                </div>
                            </div>

                            <div className="col-lg-4 center-whole pl-5-inner">
                                <div className="award-item">

                                    <div className="award-item-logo-in"><img src={require('./img/vectors/keypad/icon2-01.svg')} width="110" alt="" /></div>
                                    <div className="award-item-descr slide-descr mar-lt-ico">Tap and go with <br/> the key tag for easy access <br/>to your home​</div>

                                </div>
                            </div>

                            <div className="col-lg-4 center-whole pl-5-inner">
                                <div className="award-item">

                                    <div className="award-item-logo-in"><img src={require('./img/vectors/keypad/icon3-01.svg')} width="110" alt="" /></div>
                                    <div className="award-item-descr slide-descr mar-lt-ico">Fits perfectly​ <br/>  and stylishly <br/>  in your home</div>

                                </div>
                            </div>
                    </div>
                </div>
          </div>
        
          <div className="row ptb-inner px-0">
            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
              <h3 className="alerts-txt">Visibility even in the dark<br/>
                </h3>
              <p className="inner-line newtext"> The keypad comes with a proximity activated backlight which will light up when an object is close to it, allowing the user to easily view the keypad keys in dark environments.</p>
            </div>
            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/product/keypad/triple/vis-dark.png')} className="img-fluid" alt="" /> </div>
            <div className="col-md-6 mb-5 pl-0 plr-0px tp-menu-show"> <img src={require('./img/product/keypad/triple/tap.jpg')} className="img-fluid" alt="" /> </div>
            <div className="col-md-6 pr-ann-left al-tp mb-5 plr-0px text-mini-mbile">
              <h3 className="alerts-txt">Tap and go</h3>
              <p className="inner-line newtext"> The keypad works seamlessly with our key cards, fobs and stickers. Tap on the keypad with your card, fob or sticker and choose your alarm state in an instant.</p>
            </div>
            <div className="col-md-6 mb-5 pl-0 plr-0px sh-img"> <img src={require('./img/product/keypad/triple/tap.jpg')} className="img-fluid" alt="" /> </div>
            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
              <h3 className="alerts-txt">Home security at your fingertips </h3>
              <p className="inner-line newtext">Our keypad offers everything you need to be able to control your home security system. The simple and stylish design will fit neatly in your home and act as the perfect control panel for your SafelyTeam system. ​</p>
            </div>
            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/product/keypad/triple/finger.png')} className="img-fluid" alt="" /> </div>
           
           
              
          </div>
          <div className="row">
            <div className="col-md-6 pl-0 pr-0">
              <img src={require('./img/product/keypad/mid/key-btm.png')} className="img-fluid" alt="" />
            </div>
            <div className="col-md-6 bg-in-lt pl-5 py-5">
              
                <ul className="list-inline" style={{marginLeft: '2rem'}}>
                  
                  <li className="list-in">Proximity-activated backlight</li>
                  <li className="list-in">12 months battery life (under normal usage)</li>
                  <li className="list-in">Neat and Compact design</li>
                  <li className="list-in">Key Tag support for ‘Tap and go’</li>
                  <li className="list-in">Battery Status indicator</li>
                  <li className="list-in">Audible feedback from keys​</li>
                  <li className="list-in">LED alarm status indicator​</li>

                </ul>
            </div>
            
          </div>
           <div className="col-md-12 ptb-inner text-mini-mbile mb-know mb-tech">
            <h2 className="font-in-head tech-sp font-in-head-inn ml-minus"></h2>
          </div> 

                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography className={classes.heading}><b style={{fontSize:'1rem'}}>Technical Specifications</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{marginLeft:'10px'}}>
                        <Typography>

                            <div className="row">
                                <div className="col-md-4 px-0 pt-5 pl-list-gen"> 
                                <h4 className="font-gen">General</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Dimensions: 90 x 90 x 22 mm</li>
                                                              
                                </ul>
                                <h4 className="font-gen">Power Supply</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Battery: 4 x AA Alkaline</li>   
                                    <li className="font-nor">Battery Life: 12 Months (Normal usage)</li>   
                                    <li className="font-nor">Battery status and Warning</li>                
                                </ul>
                                <h4 className="font-gen">Radio</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Sensitivity: -100dBm</li>
                                    <li className="font-nor">Output Power: + 10 dBm</li>
                                    
                                </ul>
                                <h4 className="font-gen">Environment</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">For indoor and outdoor use</li>
                                    <li className="font-nor">Operation temperature: 0 to +50°C</li>
                                    
                                </ul>
                                    <h4 className="font-gen">Presence</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Detection of person present (within 5 – 10 cm)</li>
                                    <li className="font-nor">Detection interval 500 ms (1s reaction)</li>
                                </ul>
                                </div>
                                <div className="col-md-4 pt-5"> <img src={require('./img/product/keypad/round/keypad-round.png')} className="img-fluid" alt="" /> </div>
                                <div className="col-md-4 pl-3 pt-5"> 
                                <h4 className="font-gen">Key Pad</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">‘0’ – ‘9’ + 6 function buttons</li> 
                                    <li className="font-nor">Backlight: On when unit active (person present) and light level low</li> 
                                </ul>
                                <h4 className="font-gen">RFID</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Mifare reader.</li>
                                    <li className="font-nor">Reading distance: ~5 cm.</li>
                                </ul>  
                                <h4 className="font-gen">Communication</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Zigbee 3.0</li>
                                    <li className="font-nor">Zigbee end-device</li>
                                    
                                </ul> 
                                    <h4 className="font-gen">Certifications</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Conforming to CE, FCC, IC, ISED, RED, RoHS and REACH directives</li>
                                    <li className="font-nor">Zigbee 3.0 certified</li>
                                    
                                </ul> 
                                </div>                            
                            </div>
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <br/>   
            </div>
        )
    }

    const smartPlug = () => {
        return (
            <div>

<div className="row ptb-inner">
            <div className="col-md-10 px-0 pt-5 mx-auto">
              <div className="row">
                <div className="col-lg-4 center-whole pl-5-inner">
<div className="award-item">

<div className="award-item-logo-in"><img src={require('./img/vectors/smart-plug/icon1-01.svg')} width="110" alt="" /></div>
<div className="award-item-descr slide-descr mar-lt-ico">Monitor power <br/>consumption​ </div>

</div>
</div>

 <div className="col-lg-4 center-whole pl-5-inner">
<div className="award-item">

<div className="award-item-logo-in"><img src={require('./img/vectors/smart-plug/icon2-01.svg')} width="110" alt="" /></div>
<div className="award-item-descr slide-descr mar-lt-ico">Convert regular ​ <br/> devices into <br/>smart appliances</div>

</div>
</div>

 <div className="col-lg-4 center-whole pl-5-inner">
<div className="award-item">

<div className="award-item-logo-in"><img src={require('./img/vectors/smart-plug/icon3-01.svg')} width="110" alt="" /></div>
<div className="award-item-descr slide-descr mar-lt-ico">Set smart  <br/>rules and scenes <br/> to control appliances</div>

</div>
</div>
              </div>
            </div>
          </div>
          
        
          <div className="row ptb-inner px-0">
            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
              <h3 className="alerts-txt">Create your perfect smart home<br/>
                </h3>
              <p className="inner-line newtext">Smart plugs are an excellent way to revolutionise your home experience. Using the SafelyTeam Connect app, you can control your appliances and automate actions within your home from anywhere in the world – or across your living room.​</p>
            </div>
            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/product/smart-plug/triple/perfect.png')} className="img-fluid" alt="" /> </div>
            <div className="col-md-6 mb-5 pl-0 plr-0px tp-menu-show"> <img src={require('./img/product/smart-plug/triple/one.jpg')} className="img-fluid" alt="" /> </div>
            <div className="col-md-6 pr-ann-left al-tp mb-5 plr-0px text-mini-mbile">
              <h3 className="alerts-txt">Monitor energy consumption</h3>
              <p className="inner-line newtext">The smart plug monitors electricity usage of any appliance so you can keep your home running as efficiently as possible​.</p>
            </div>
            <div className="col-md-6 mb-5 pl-0 plr-0px sh-img"> <img src={require('./img/product/smart-plug/triple/one.jpg')} className="img-fluid" alt="" /> </div>
            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
              <h3 className="alerts-txt">Optimise your SafelyTeam System</h3>
              <p className="inner-line newtext">Smart plugs also boost the performance of the rest of your SafelyTeam system by acting as range extenders. When placed around your home and turned on, they can improve the signal strength and battery life of your other devices.​</p>
            </div>
            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/product/smart-plug/triple/optimise.png')} className="img-fluid" alt="" /> </div>
           
           
              
          </div>
          <div className="row">
             <div className="col-md-6 pl-0 pr-0">
              <img src={require('./img/product/smart-plug/mid/plug-mini.png')} className="img-fluid" alt="" />
            </div>
            <div className="col-md-6 bg-in-lt pl-5 py-5">
              
                <ul className="list-inline" style={{marginLeft: '2rem'}}>
                  
                  <li className="list-in">Create your Smart home and control appliances from the SafelyTeam Connect app</li>
                  <li className="list-in">Monitor live power consumption​</li>
                  <li className="list-in">View total power consumption​</li>
                  <li className="list-in">Improve signal quality and battery life of other devices​</li>
                  <li className="list-in">Manually turn on or off your smart plug​</li>
                  <li className="list-in">Optimise your smart home​</li>

                </ul>
            </div>
            
          </div>
          <div className="col-md-12 ptb-inner text-mini-mbile mb-know mb-tech">
            <h2 className="font-in-head tech-sp font-in-head-inn ml-minus"></h2>
          </div> 
                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography className={classes.heading}><b style={{fontSize:'1rem'}}>Technical Specifications</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{marginLeft:'10px'}}>
                        <Typography>

                            <div className="row">
                                <div className="col-md-4 px-0 pt-5 pl-list-gen"> 
                                <h4 className="font-gen">General</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Dimensions: Ø41 X 45 mm</li>
                                    <li className="font-nor">Colour: White</li>
                                    
                                    <li className="font-nor">Power supply: 230V +/-10%</li>
                                    <li className="font-nor">Power consumption: 0.4 W</li>
                                    
                                </ul>
                                <h4 className="font-gen">Radio</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Sensitivity: -101 dBm @ 1% PER</li> 
                                    <li className="font-nor">Output power: +8 dBm</li>                   
                                </ul>
                                <h4 className="font-gen">Environment</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">IP className: IP40</li>
                                    <li className="font-nor">Operation temperature 0 to +50°C</li>
                                    <li className="font-nor">Relative humidity 5% - 85%, non condensing</li>  
                                    
                                </ul>
                                <h4 className="font-gen">Specifications power meter</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Voltage range: 207 to 253 VAC</li>
                                    <li className="font-nor">Accuracy: Typ +/- 1%</li>
                                    <li className="font-nor">Reported resolution: 1W</li>
                                </ul>
                                
                                </div>
                                <div className="col-md-4 pt-5"> <img src={require('./img/product/smart-plug/round/smart-plug-round.png')} className="img-fluid" alt="" /> </div>
                                <div className="col-md-4 pl-3 pt-5"> 
                                <h4 className="font-gen">Specifications remote control</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Max. switch voltage: 250 VAC</li>
                                    <li className="font-nor">Max. load: 16 A</li>
                                    <li className="font-nor">Max. continuous load: 10 A</li>
                                    <li className="font-nor">Overload and over temperature protection</li>
                                </ul>
                                <h4 className="font-gen">Communication</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Zigbee Home Automation 1.2</li> 
                                    <li className="font-nor">Zigbee router</li> 
                                </ul>
                                <h4 className="font-gen">Certification</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Conforming to CE, RED, Low Voltage and RoHS directives</li>
                                    <li className="font-nor">Zigbee Home Automation 1.2 certified</li>
                                </ul>  
                                
                                </div>                                 
                            </div>
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <br/>   
            </div>
        )
    }

    const gateWay = () => {
        return (
            <div>

                <div className="row ptb-inner">
                            <div className="col-md-10 px-0 pt-5 mx-auto">
                            <div className="row">
                                <div className="col-lg-4 center-whole pl-5-inner">
                <div className="award-item">

                <div className="award-item-logo-in"><img src={require('./img/vectors/gateway/icon1-01.svg')} width="110" alt="" /></div>
                <div className="award-item-descr slide-descr mar-lt-ico">Fully secured <br/>data and home </div>

                </div>
                </div>

                <div className="col-lg-4 center-whole pl-5-inner">
                <div className="award-item">

                <div className="award-item-logo-in"><img src={require('./img/vectors/gateway/icon2-01.svg')} width="110" alt="" /></div>
                <div className="award-item-descr slide-descr mar-lt-ico">Control your <br/> smart home via the <br/>SafelyTeam Connect app​</div>

                </div>
                </div>

                <div className="col-lg-4 center-whole pl-5-inner">
                <div className="award-item">

                <div className="award-item-logo-in"><img src={require('./img/vectors/gateway/icon3-01.svg')} width="110" alt="" /></div>
                <div className="award-item-descr slide-descr mar-lt-ico">Battery and  <br/> cellular backup</div>

                </div>
                </div>
              </div>
            </div>
          </div>
          
        
          <div className="row ptb-inner px-0">
            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
              <h3 className="alerts-txt">Dedicated hardware encryption <br/>
                </h3>
                <p className="inner-line newtext"> We go one step ahead of our competitors and offer dedicated hardware encryption, meaning your data is securely stored and never in the wrong hands.​</p>
            </div>
            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/product/gateway/triple/one.png')} className="img-fluid" alt="" /> </div>
            <div className="col-md-6 mb-5 pl-0 plr-0px tp-menu-show"> <img src={require('./img/product/gateway/triple/two.jpg')} className="img-fluid" alt="" /> </div>
            <div className="col-md-6 pr-ann-left al-tp mb-5 plr-0px text-mini-mbile">
              <h3 className="alerts-txt">Integrated sim card</h3>
              <p className="inner-line newtext"> In an event your internet is down, our gateways are fitted with e-sims to allow them to carry on functioning as normal on a mobile internet connection. </p>
            </div>
            <div className="col-md-6 mb-5 pl-0 plr-0px sh-img"> <img src={require('./img/product/gateway/triple/two.jpg')} className="img-fluid" alt="" /> </div>
            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
              <h3 className="alerts-txt">Battery backup </h3>
              <p className="inner-line newtext">Our gateways include a 2-hour battery backup built-in, meaning that even in a power cut, your SafelyTeam system will not be affected. Our app will notify you immediately of the power loss and when the power has been restored.​</p>
            </div>
            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/product/gateway/triple/three.png')} className="img-fluid" alt="" /> </div>
                      
          </div>
          <div className="row">
            <div className="col-md-6 pl-0 pr-0">
              <img src={require('./img/product/gateway/mid/mid1.jpg')} className="img-fluid" alt="" />
            </div>
            <div className="col-md-6 bg-in-lt pl-5 py-5">
              
                <ul className="list-inline" style={{marginLeft: '2rem'}}>
                  
                  <li className="list-in">LEDs to indicate power, internet, alarm and paring status​</li>
                  <li className="list-in">2 Hour Battery Backup</li>
                  <li className="list-in">Dedicated hardware encryption</li>
                  <li className="list-in">Wi-Fi, Ethernet and Mobile Internet connectivity</li>
                  <li className="list-in">Compact and Elegant Design</li>

                </ul>
            </div>
            
          </div>
          <div className="col-md-12 ptb-inner text-mini-mbile mb-know mb-tech">
            <h2 className="font-in-head tech-sp font-in-head-inn ml-minus"></h2>
          </div>

                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography className={classes.heading}><b style={{fontSize:'1rem'}}>Technical Specifications</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{marginLeft:'10px'}}>
                        <Typography>
                            <div className="row">
                                <div className="col-md-4 px-0 pt-5 pl-list-gen"> 
                                <h4 className="font-gen">Platform</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Linux - MGW 201</li>
                                    <li className="font-nor">Memory - 512 MB RAM + 4 GB Flash </li>       
                                </ul>
                                <h4 className="font-gen">Wireless Sensor Communication</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Accelerometer 3-axis, 8 bit </li>
                                    <li className="font-nor">ZigBee 2.4 GHz</li>
                                    <li className="font-nor">ZigBee SE Certificate </li>
                                    <li className="font-nor">Bluetooth BLE 4.2 </li>                
                                </ul>
                                <h4 className="font-gen">Backup Communication</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">2G/4G - LTE Cat1 (EU)</li>
                                    <li className="font-nor">eSIM (Installed) </li>
                                    <li className="font-nor">SIM card holder </li>   
                                </ul>
                                    
                                </div>
                                <div className="col-md-4 pt-5"> <img src={require('./img/product/gateway/round/gateway.png')} className="img-fluid" alt="" /> </div>
                                <div className="col-md-4 pl-3 pt-5"> 
                                <h4 className="font-gen">Communication</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">Ethernet</li>
                                    <li className="font-nor">W-LAN</li>
                                    <li className="font-nor">USB (ready)</li>
                                </ul>
                                <h4 className="font-gen">Power</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">9V Power Adapter </li> 
                                    <li className="font-nor">Battery Backup - UFX 103450C </li>                                  
                                </ul>
                                <h4 className="font-gen">Accessories</h4>
                                <ul className="pl-3 dimesions-btm">
                                    <li className="font-nor">1m Ethernet Cable</li>
                                    <li className="font-nor">Wall Bracket included  </li>
                                </ul>                                     
                                </div>                                           
                            </div>
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <br/>   
            </div>
        )
    }

    const keyTags = () => {
        return (
            <div>
                 <div className="row ptb-inner">
                        <div className="col-md-10 px-0 pt-5 mx-auto">
                            <div className="row">
                                <div className="col-lg-4 center-whole pl-5-inner">
                                    <div className="award-item">

                                        <div className="award-item-logo-in"><img src={require('./img/vectors/keytag/icon1-01.svg')} width="110" alt="" /></div>
                                        <div className="award-item-descr slide-descr mar-lt-ico">Tap and go <br/>to arm <br/>your system​</div>

                                    </div>
                                </div>

                                <div className="col-lg-4 center-whole pl-5-inner">
                                    <div className="award-item">

                                        <div className="award-item-logo-in"><img src={require('./img/vectors/keytag/icon2-01.svg')} width="110" alt="" /></div>
                                        <div className="award-item-descr slide-descr mar-lt-ico">Sleek and easily <br/>stored away​​</div>

                                    </div>
                                </div>

                                <div className="col-lg-4 center-whole pl-5-inner">
                                    <div className="award-item">

                                        <div className="award-item-logo-in"><img src={require('./img/vectors/keytag/icon3-01.svg')} width="110" alt="" /></div>
                                        <div className="award-item-descr slide-descr mar-lt-ico">High quality <br/>material, <br/>built to last​</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                        <div className="row ptb-inner px-0">
                            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">
                                Tap and Go<br/>
                                </h3>
                                <p className="inner-line newtext">Our unique Key cards help instantly secure your home when
on-the-go. All our key cards are fully protected against forgery and cloning, meaning
this card is uniquely yours forever.</p>
                            </div>
                            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/product/key-tags/triples/mid-one.jpg')} className="img-fluid"  alt="" /> </div>
                            <div className="col-md-6 mb-5 pl-0 plr-0px tp-menu-show"> <img src={require('./img/product/key-tags/triples/mid-two.jpg')} className="img-fluid"  alt="" /> </div>
                            <div className="col-md-6 pr-ann-left al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">Track who has armed and disarmed your system</h3>
                                <p className="inner-line newtext">With instant notifications from
the SafelyTeam Connect app, you can easily see who has armed and disarmed your
system</p>
                            </div>
                            <div className="col-md-6 mb-5 pl-0 plr-0px sh-img"> <img src={require('./img/product/key-tags/triples/mid-two.jpg')} className="img-fluid"  alt="" /></div>
                            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
                                <h3 className="alerts-txt">Style and Substance</h3>
                                <p className="inner-line newtext">The SafelyTeam Key cards not only look great inside your wallet;
they have been specially engineered to deliver a consistent experience year after
year.</p>
                            </div>
                           <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/product/key-tags/triples/mid-three.jpg')} className="img-fluid"  alt="" /> </div>
                        </div>


                        <div className="row">
                            <div className="col-md-6 pl-0 pr-0">
                                 <img src={require('./img/product/key-tags/mid/mid-new.jpg')} className="img-fluid"  alt="" /> 
                            </div>
                            <div className="col-md-6 bg-in-lt pl-5 py-5">

                                <ul className="list-inline" style={{marginLeft: '2rem'}}>

                                    <li className="list-in">Quickly arm and disarm your home</li>
                                    <li className="list-in">High quality design</li>
                                    <li className="list-in">Fully Secure and Safe</li>
                                    <li className="list-in">Monitor home activity</li>
                                    <li className="list-in">Seamless experience</li>
                                    <li className="list-in">Style and Ease</li>

                                </ul>
                            </div>
                            
                        </div>

            </div>

        )
    }

    const banner = () => {
        return (
                    <div className="row ptb-inner">
                        <div className="col-md-12 text-center gred-in-bg py-5">
                            <h3 className="grad-bold mb-4 pt-4"> Take care of your loved ones in their every move</h3>
                            <a href="/step1" className="btn btn-primary mr-2 mb-3 white-but" type="submit">Buy Now</a>
                        </div>
                    </div>
        )
    }


    const addProducts = () => {
        return (
                <div className="row ptb-inner" id="products">
    
                    <div className="col-md-12 py-4">
                      {products1.length >=1 && <h4 className="font-gen">Add more Products</h4> }  
                    </div>

                    {products1.map((p,i) => (
                                            
                        <div key={i} className="col-md-6 px-0 btm-bo-air mb-5 pb-5">
                            <Card1 product={p} />
                        </div>

                    ))}                           
                                       
                </div>
        )
    }

    return (
        <>
            
            <NewLayout topTitle={checkTitle(match.params.productId)} metaDescription={checkDescription(match.params.productId)}>
            
                    <div className="section pp-scrollable slide-dark slide-dark-footer">
                    

                        <div className="col-md-12 inner-tp-pad inner-safely">
                            <div className="container ptb-inner top-det-sp">
                           
                                {imageHeader()}

                                {product._id === "5f7b2ec3ffa2800728e971bd" && motionSensor()}

                                {product._id === "5f7b2f74ffa2800728e971c1" && smokeAlarm()}

                                {product._id === "5f7b2d5bffa2800728e971b5" && siren()}                             

                                {product._id === "5f7b2e1effa2800728e971b9" && panicButton()}

                                {product._id === "5f7b2e6fffa2800728e971bb" && heatSensor()}

                                {product._id === "5f7b302bffa2800728e971c5" && floodAlarm()}

                                {product._id === "5f7b2f0bffa2800728e971bf" && contactSensor()}

                                {product._id === "5f7b2dc6ffa2800728e971b7" && airQuality()}

                                {product._id === "5f9d650ff268b98214e737bf" && keyPad()}

                                {product._id === "5f7b2fc3ffa2800728e971c3" && smartPlug()}

                                {product._id === "5f773c5c541f443e5ce64aaa" && gateWay()}

                                {product._id === "5f9d6592f268b98214e737c1" && keyTags()}

                                {banner()}

                                
                                 {addProducts()} 

                                 <Backdrop className={classesBack.backdrop} open={loading1} >
                                    <CircularProgress color="inherit" />
                                </Backdrop>  


                            </div>
                        </div>

                        <Subscribe />

                    </div>

                
            {shouldRedirect(redirect)}
            </NewLayout>
        </>
      
    )
}

export default ProductDetails
