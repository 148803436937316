import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Subscribe from './Subscribe'
import {getAllProducts} from './Priorities/apiUser'
import ShowImage from '../../admin/ShowImage'
import NewLayout from './NewLayout'

const Security = () => {

  const [secproducts, setSecProducts] = useState([]);

  const [values, setValues] = useState({
    btnloading:true
  });

  const {btnloading} = values

  const initSecurity = ids => {
    getAllProducts(ids).then(data => {
      if (data.error) {     
          console.log(data.error);
          setValues({...values,   btnloading:false});
      } else {
          // populate the state
          setSecProducts(data);    
          setValues({...values,   btnloading:false});      
      }
    });
  };  

  useEffect(() => {
    initSecurity(showAllProducts());
 }, [])

 const showAllProducts = (Id) => {
   let list = ["5f9d650ff268b98214e737bf","5f7b2d5bffa2800728e971b5","5f7b2f0bffa2800728e971bf","5f7b2ec3ffa2800728e971bd","5f7b2fc3ffa2800728e971c3"];
   return list;
 }

 const showProducts = () => {
   return (
          <div className="row ptb-inner">

              {(btnloading) ? <div className='loader-container'><div className='loader'></div></div> : ""}  
                
                {secproducts.map((p,i) => (

                <div className="col-md-6 mb-2-btm pl-0 plr-0px book">
                    <div className="col-md-12 box-bord-gr info">
                        <div className="row pt-8-per-but">

                          <div className="col-md-12 py-5 px-5">
                              <Link className="btn btn-primary mr-2 mb-3" key={i} to={`/product-details/${p._id}`}>See Details</Link>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-12 box-bord-gr image">
                        <div className="row">
                            <div className="col-md-5"> <ShowImage key={i} item={p._id} /> </div>
                            <div className="col-md-7 py-5 px-5 text-mini-mbile">
                                <h3 className="font-hd-on" key={i}>{p.name}</h3>
                                <p className="inner-line" key={i}>{p.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
                                    
                ))}
                              
                

              <div className="col-md-6 text-center shop-btn">
                <a className="btn btn-success" href="/step1">Shop the SafelyTeam Packages</a>
              </div>
        </div>
   )
 }

 
    return (
        <NewLayout topTitle="SafelyTeam – Security">
                   
                        <div className="section pp-scrollable slide-dark slide-dark-footer">                        
                        <div className="col-md-12 px-0 inner-tp-pad inner-safely">
        <div className="container">
          <div className="col-md-10 inner-header">
            <h1 className="white-inner-txt">Security </h1>
            <p className="white-inner-p">Secure Your Home </p>
          </div>
        </div>
        <img src={require('./img/security-header.png')} className="img-fluid" alt="" /> </div>
      <div className="col-md-12 inner-safely">
        <div className="container ptb-inner">
          <div className="row">
            <div className="col-md-6 inner-para-pad">
              <h3 className="pb-3">Protect Your Home and Family's Wellbeing​</h3>
              <p className="inner-line">Protecting your home and family is of paramount importance to us all. Burglaries pose an ever-present threat to your family and belongings and guarding your home against intrusion is vital to creating a feeling of security within your home. Not only protect your home, but your family's physical and mental well-being with our gold standard smart home security system.<br></br>
SafelyTeam’s security solution looks after your home by detecting and preventing intrusion. Our security system is monitored professionally 24/7 by our NSI Gold standard alarm monitoring response centre, ensuring peace of mind wherever you are.​

</p>
            </div>
            <div className="col-md-6"><img src={require('./img/mom-girl.jpg')} className="img-fluid" alt="" /></div>
          </div>
          <div className="row ptb-inner">
            <div className="col-md-10 px-0">
              <h3 className="pb-3">Products</h3>
              <p className="inner-line">Our security system guards your home against intruders and alerts you in an instant of any break-ins. Our security range monitors your home and can also be used to create advanced home automation rules and convenience features, to simplify your day-to-day life. Check out our security product range below. ​


</p>
            </div>
          </div>

            {showProducts()}

          <div className="row ptb-inner text-mini-mbile mb-know">
            <h2 className="font-in-head inner-safely">Understand It, Trust It.<br></br>
              Explore the use cases </h2>
          </div>
          <div className="row ptb-inner px-0">
            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
              <h3 className="alerts-txt">Arm your system from <br></br>anywhere in the world​ <br></br>
                Mode </h3>
              <p className="inner-line">The security system works with our SafelyTeam Connect app which can be used to arm and disarm your system from anywhere in the world. You can also monitor when and who has armed your system and see if doors or windows are left open with just  a swipe of a finger. ​</p>
            </div>
            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/alerts-img.jpg')} className="img-fluid" alt="" /> </div>
            <div className="col-md-6 mb-5 pl-0 plr-0px tp-menu-show"> <img src={require('./img/calling-trusted.png')} className="img-fluid" alt="" /> </div>
            <div className="col-md-6 pr-ann-left al-tp mb-5 plr-0px text-mini-mbile">
              <h3 className="alerts-txt">Tamper proof </h3>
              <p className="inner-line">All our security sensors have built-in tamper protection, and our gateway uses dedicated hardware encryption meaning that your system and data are as secure as possible.​
</p>
            </div>
            <div className="col-md-6 mb-5 pl-0 plr-0px sh-img"> <img src={require('./img/calling-trusted.png')} className="img-fluid" alt="" /> </div>
            <div className="col-md-6 pr-ann al-tp mb-5 plr-0px text-mini-mbile">
              <h3 className="alerts-txt">NSI Gold accredited <br></br>24/7 Professional monitoring​ </h3>
              <p className="inner-line">Our monitoring team can alert you of burglaries, low batteries in devices, smoke, heat and flood alarms. They will call you immediately in any of the above scenarios and if you are not available will then contact you next alarm contact and leave you with a message.​

</p>
            </div>
            <div className="col-md-6 mb-5 plr-0px"> <img src={require('./img/home-secu.png')} className="img-fluid" alt="" /> </div>
            <div className="col-md-12 text-center ptb-inner">             
              <a className="btn btn-primary mr-2 mb-3" href="/step1">Design your personalised system<i className="lni lni-chevron-right"></i></a>
            </div>
          </div>
          <div className="row ptb-inner">
            <div className="col-md-4 px-0 pt-5"> <img src={require('./img/build-btm.jpg')} className="img-fluid" alt="" /> </div>
            <div className="col-md-4 px-0 pt-5"> </div>
            <div className="col-md-4 px-0 pt-5"> <img src={require('./img/right-man.jpg')} className="img-fluid" alt="" /> </div>
          </div>


        </div>
      </div>
    
                 
                            <Subscribe />

                        </div>
          
        </NewLayout>
    )

}
export default Security