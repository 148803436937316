import React, {useState, useEffect} from 'react'
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import { Redirect } from "react-router-dom"
import { createProduct, getCategories, getSuppliers } from './apiAdmin'
import Select from 'react-select'

const AddProduct = () => {

    const { user, token} = isAuthenticated();
    const [values, setValues] = useState({
        prodid: '',
        name: '',
        description: '',
        long_description:'',
        purchase_price: '',
        sale_price: '',
        weight: '',
        suppliers:[],
        supplier: '',
        categories: [],
        category: '',
        inventory: '',
        threshold:'',
        photo: '',
        btnloading:false,
        error: '',
        createdProduct: '',
        redirectToProfile: false,
        formData: ''
    })

    const [selectedValue, setSelectedValue] = useState([]);
    
    const [categories, setCategories] = useState([]);

    const [suppliers, setSuppliers] = useState([]);

    const { 
        prodid,
        name,
        description,
        long_description,
        purchase_price,
        sale_price,
        weight,
        //supplier,
        //category,
        inventory,
        threshold,
        //photo,
        btnloading,
        error,
        createdProduct,
        //redirectToProfile,
        formData
     } = values;

     const init = () => {
                // populate the state
                setValues({
                    ...values,
                    formData: new FormData()
                });
                // load categories
                init1();
                init2();
            }


     // load categories and set form data
     const init1 = () => {
         getCategories().then(data => {
             if(data.error) {
                setValues({...values, error: data.error})
             } else {
                setCategories(data);
             }
         })
     }

     
     // load suppliers and set form data
     const init2 = () => {
        getSuppliers().then(data => {
            if(data.error) {
               setValues({...values, error: data.error})
            } else {
                setSuppliers(data);
            }
        })
    }

     useEffect(() => {
         init()   
     },[])

      const data2 = categories.map((c) => (
       
        {
            value:""+[c._id]+"",
            label:""+ [c.name]+""
        }
     )) 


     const handleChange = name => event => {
         if(name === 'category') {
            setSelectedValue(Array.isArray(event) ? event.map(x => x.value) : []);
             const value = Array.isArray(event) ? event.map(x => x.value) : [];
             formData.set(name,value)
             setValues({...values, [name]: value,btnloading:false })
         } else {
            const value = name === 'photo' ? event.target.files[0] : event.target.value
            formData.set(name, value)
            setValues({...values, [name]: value,btnloading:false})
         }
        
     };

     const clickSubmit = event => {
            event.preventDefault()
            setValues({...values, error: '',btnloading:true })

            createProduct(user._id,token,formData)
            .then(data => {
                if(data.error) {
                    setValues({...values, error: data.error,btnloading:false})
                } else {
                    setValues({
                        ...values,
                        prodid: '',
                        name: '',
                        description: '',
                        long_description:'',
                        photo: '',
                        purchase_price: '',
                        sale_price:'',
                        weight: '',
                        threshold:'',
                        inventory: '',
                        btnloading: false,
                        createdProduct: data.name

                    })
                }
            })
     };
     
 
     const newPostForm = () => (

         <form className="mb-3" onSubmit={clickSubmit}>

             <div className="row">
                 <div className="col-md-6">

                    <div className="form-group">
                        <label className="text-muted">Product ID<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChange('prodid')} type="text" className="form-control" value={prodid} required />
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Product Name<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChange('name')} type="text" className="form-control" value={name} required />
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Short Description<span style={{color:"red"}}> *</span></label>
                        <textarea onChange={handleChange('description')} className="form-control" value={description} required />
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Long Description<span style={{color:"red"}}> *</span></label>
                        <textarea onChange={handleChange('long_description')} rows="7" cols="7" className="form-control" value={long_description} required />
                    </div>


                    <div className="form-group">
                        <label className="text-muted">Category<span style={{color:"red"}}> *</span></label>
                        <Select required
                            className="dropdown"
                            placeholder="Select Category"
                            value={data2.filter(obj => 
                                selectedValue.includes(obj.value))}
                            options={data2}
                            onChange={ handleChange('category')}
                            isMulti
                            isClearable
                        />
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Supplier Name<span style={{color:"red"}}> *</span></label>
                        <select required
                            onChange={handleChange('supplier')} 
                            className="form-control">
                            <option >Select Supplier</option> { 
                                suppliers && suppliers.map((c, i) => (
                                <option key={i} value={c._id}>{c.companyname}
                            </option>
                            ))}
                        </select>
                    </div>

                 </div>

                 <div className="col-md-6">
                
                    <div className="form-group">
                        <label className="text-muted">Quantity<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChange('inventory')} type="number" className="form-control" value={inventory} required />
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Threshold<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChange('threshold')} type="number" className="form-control" value={threshold} required />
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Purchase Price<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChange('purchase_price')} type="number" className="form-control" value={purchase_price} required />
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Sale Price<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChange('sale_price')} type="number" className="form-control" value={sale_price} required />
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Weight<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChange('weight')} type="number" className="form-control" value={weight} required />
                    </div>

                    <label className="text-muted">Product Image<span style={{color:"red"}}> *</span></label>

                    <div className="form-group">
                        <label className="btn btn-secondary">
                        <input onChange={handleChange('photo')} type="file" name="photo" accept="image/*" required />
                        </label>
                    </div>
             
                </div>
            </div>

            <button  className="btn btn-outline-primary" disabled={btnloading}> {btnloading ? "Loading..." : "Add Product"} </button>
         </form>  
     );

     const showError = () => (
        <div className="alert alert-danger" role="alert" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => (
        createdProduct && <Redirect to="/admin/products" /> 
    )


    return (
        <AdminLayout className="container" topTitle="Add Product" >

            <div className="row">
                    
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title" style={{textAlign:'center'}}>Add Product</h4>
               
            
                            {showSuccess()}
                            {showError()}
                            {newPostForm()}
                
                        </div>
                    </div>
                </div>
           </div>
        </AdminLayout>
    )
}


export default AddProduct;