import React from 'react'
import Subscribe from './Subscribe'
import NewLayout from './NewLayout'

const TermsConditions = () => {
    return (
        <NewLayout topTitle="Terms & Conditions | Best Home Security System | SafelyTeam" metaDescription="These are the terms and conditions on which we supply our products and service to protect your home. Remote monitoring available.  Buy Now.">
            
                      <div className="section pp-scrollable slide-dark slide-dark-footer">                        
                        <div className="col-md-12 px-0 inner-tp-pad inner-safely">
                                <div className="container">
                                    <div className="col-md-10 inner-header">
                                        <h1 className="white-inner-txt abt-hd-res">Terms and Conditions </h1>
                                    </div>
                                </div>
                                <img src={require('./img/abt-us.png')} className="img-fluid" alt=""/> 
                                </div>
                                <div className="col-md-12 inner-safely">
                                <div className="container">
                                    
                                    <div className="row ptb-inner">
                                    <div className="col-md-12 px-0">
                                        <h4>SafelyTeam - Our terms of supply of products and service</h4><br/>
                                        
                                        <p className="inner-line"><b>1.	These terms</b></p>
                                        <p className="inner-line">
                                        <b>1.1	What these terms cover.</b> These are the terms and conditions on which we supply our products and service to you. When we refer to ‘products’ we are referring to the hub, keypad, sensors, and other equipment you order from us in order to receive the service. The ‘service’ is the SafelyTeam Subscription package you select when you place your order, including access to the SafelyTeam app and the dashboard provided on our website.
                                        </p>
                                        <p className="inner-line">
                                        <b>1.2	Why you should read them.</b>Please read these terms carefully before you submit your order to us. These terms tell you who we are, how we will provide products to you, how you and we may change or end the contract, what to do if there is a problem and other important information. If you think that there is a mistake in these terms, please contact us to discuss. 
                                        </p>
                                        <p className="inner-line">
                                        <b>1.3	Some Important Points To Note about the SafelyTeam service:</b>
                                        </p>
                                        <ol type="a">
                                            <li>
                                                <b>ANY ALARM RESPONSE CENTRE SERVICES YOU BUY WILL NOT START UNTIL FIVE DAYS FROM INSTALLATION.</b> See section 8.2 for more details of this ‘practice period’.
                                            </li>
                                            <li>
                                                <b>MAKE SURE YOU HAVE A STABLE FIXED-LINE INTERNET CONECTION THROUGHOUT YOUR SUBSCRIPTION.</b> Neither the products nor the service will work without a continuous internet connection covering the premises. We will not be responsible for any failure of the products or the services caused by a poor or interrupted internet connection. We provide a limited temporary mobile back-up in the event of failure but this is not a substitute for a continuous stable fixed-line internet connection.
                                            </li>
                                            <li>
                                            	<b>YOU MUST PLACE SafelyTeam EQUIPMENT IN THE RIGHT PLACES.</b> We provide simple instructions as to where the various sensors we send you need to be placed around the premises. We will not be responsible for any failure of the products or the services caused by incorrect placement of any equipment. If SafelyTeam equipment is not properly installed, or improperly placed, you may experience false alarms or detection failures.
                                            </li>
                                            <li>
                                            	<b>SafelyTeam IS NOT A REPLACEMENT FOR THE EMERGENCY SERVICES OR CERTIFIED ALARM SYSTEMS.</b> Please do not rely on SafelyTeam to resolve any issues involving danger to life or personal safety, property risk, fires, floods, burglaries or other emergencies. If you have any such emergency, you should immediately contact the emergency services by calling 999. 
                                            </li>
                                            <li>
                                            	<b>SafelyTeam IS NOT A REPLACEMENT FOR INSURANCE OR CERTIFIED MEDICAL DEVICES.</b> Do not rely on SafelyTeam to carry in a failsafe manner information about time-critical or life-threatening situations. The products are not guaranteed to operate without interruption 24/7 and are not certified for medical use. You must not rely on SafelyTeam as a replacement for insurance for the premises and its contents.
                                            </li>
                                            <li>
                                            	<b>MAKE SURE YOU TELL PEOPLE THAT THE PREMISES ARE MONITORED BY YOU.</b> If the property for which the service is provided is used by people other than you (for example, if it is an AirBnB property), you are responsible for complying with privacy laws, which require you to provide notice to (and obtain freely-given, specific, verifiable consent from) those people that the property is monitored by equipment which captures audio and video. See our Privacy Policy for more on your responsibilities as a “data controller”.
                                            </li>
                                            <li>
                                            	<b>OUR SMOKE SENSOR AND HEAT SENSOR ARE NOT INTENDED AS A FIRE DETECTION MECHANISM.</b> Our smoke sensor is intended to monitor levels of air quality in the premises. Not to detect fires. It does not therefore conform to the regulatory requirements mandated for fire or smoke alarms, such as UL 217. To properly protect the premises, please purchase and install fire or smoke alarms that are intended for fire detection and which comply with these regulatory requirements. You should contact your local authority having jurisdiction or consult a qualified professional to assist in the installation, maintenance and location of any smoke sensor. 
                                            </li>
                                            <li>
                                                <b>OUR WEARABLE PERSONAL BUTTON OR SENSOR IS FOR INDOOR, DRY, USE ONLY.</b> Our personal alarm buttons have a range that means they may not work in the garden or outside. They are not waterproof. Do not wear them in the shower or while swimming.
                                            </li>
                                            
                                        </ol>
                                        <p className="inner-line">
                                        <b>1.4	Are you a business customer or a consumer? </b> In some areas you will have different rights under these terms depending on whether you are a business or consumer. You are a consumer if:
                                        </p>
                                        <ul>
                                            <li>You are an individual.</li>
                                            <li>You are buying products from us wholly or mainly for your personal use (not for use in connection with your trade, business, craft or profession, including operating an AirBnB or similar account).</li>
                                        </ul>
                                        <p className="inner-line">
                                            <b>1.5	If you are a business customer this is our entire agreement with you.</b>. If you are a business customer these terms (including the Data Processing Terms at the end of this document) constitute the entire agreement between us in relation to your purchase. You acknowledge that you have not relied on any statement, promise, representation, assurance or warranty made or given by or on behalf of us which is not set out in these terms and that you shall have no claim for innocent or negligent misrepresentation based on any statement in this agreement. 
                                        </p><br/>
                                        <h4>2.	Information about us and how to contact us</h4><br/>
                                        <p className="inner-line">
                                            <b>2.1	Who we are.</b>. We are SafelyTeam Ltd a company registered in England and Wales. Our company registration number is 12457612 and our registered office is at Nene House, 4 Rushmills, Northampton, England, NN4 7YB. 
                                        </p>
                                        <p className="inner-line">
                                        <b>2.2	How to contact us. </b>You can contact us by telephoning our customer service team at 0808 501 5388 or by email to us at <b style={{textDecoration:'underline'}}>hello@safelyteam.com</b> or by post at: SafelyTeam Ltd, 130 Aztec West, Bristol BS32 4UB.
                                        </p>
                                        <p className="inner-line">
                                        <b>2.3	How we may contact you. </b>If we have to contact you we will do so by telephone or by writing to you at the email address or postal address you provided to us in your order. 
                                        </p>
                                        <p className="inner-line">
                                        <b>2.4	"Writing" includes emails. </b>When we use the words "writing" or "written" in these terms, this includes emails.
                                        </p><br/>
                                        <h4>3.	Our contract with you</h4><br/>
                                        <p className="inner-line">
                                        <b>3.1	How we will accept your order. </b>Our acceptance of your order will take place when we email you to accept it, at which point a contract will come into existence between you and us. 
                                        </p>
                                        <p className="inner-line">
                                        <b>3.2	If we cannot accept your order. </b>If we are unable to accept your order, we will inform you of this in writing and will not charge you for the product. This might be because our hub or sensors or other equipment is out of stock, or because of unexpected limits on our resources which we could not reasonably plan for. 
                                        </p>
                                        <p className="inner-line">
                                        <b>3.3	Your order number. </b>We will assign an order number to your order and tell you what it is when we accept your order. It will help us if you can tell us the order number whenever you contact us about your order.
                                        </p>
                                        <p className="inner-line">
                                            <b>3.4	We only ship to the UK. </b>Our website is solely for the promotion of our products and services in the UK. We do not accept orders from addresses outside the UK.
                                        </p>
                                        <p className="inner-line">
                                            <b>3.5	We only sell to adults. You must be 18 years of age or older to buy or use the service.</b> The service is not directed or suitable for children under the age of 18. We do not knowingly collect any personal data from children under 18 without parental consent. If you add your child under the age of 18 as a user of the service, you will be deemed to have given your parental consent to our processing their personal data. You must not permit any other child to use the service.
                                        </p><br/>
                                        <h4>4.	Our products</h4><br/>
                                        <p className="inner-line">
                                            <b>4.1	Equipment may vary slightly from their pictures. </b>The images of the sensors, hub and other products on our website are for illustrative purposes only. We cannot guarantee that a device's display of the colours accurately reflects the colour of the products. Your product may vary slightly from those images.
                                        </p>
                                        <p className="inner-line">
                                            <b>4.2	Product packaging may vary. </b>The packaging of the product may vary from that shown in images on our website. 
                                        </p>
                                        <p className="inner-line">
                                        <b>Making sure your information is accurate. </b>If we accept your order we will configure the price, and the products and service we provide, in accordance with information you provide about the premises, during the sign-up process. You are responsible for ensuring that the information you provide is correct.
                                        </p><br/>
                                        <h4>5.	Installation</h4><br/>
                                        <p className="inner-line">
                                        5.1	You will be asked during your purchase process to choose whether you wish to purchase our installation service of the products and if so, a date for installation of the products. 
                                        </p>
                                        <p className="inner-line">
                                        5.2	If you fail to keep an agreed installation appointment time for the installation of your products, we will still charge you for the installation services and you will not be entitled to a refund in respect of either the products or the service. 
                                        </p>
                                        <p className="inner-line">
                                        5.3	The people we engage to carry out installation services will only work in the premises if there is someone at least 18 years old or older at all times during the appointment. You must ensure we have the access to premises as we may reasonably require for the installation. If you don’t, we won’t be able to install the products. We will still charge you for the installation services and you will not be entitled to a refund in respect of either the products or the service.
                                        </p>
                                        <p className="inner-line">
                                        5.4	We will take reasonable care to carry out the installation without causing unnecessary damage to the premises. We will put right any damage directly caused by our negligence. But you acknowledge that the installation services may necessarily involve some dismantling, modification or moving of the fixtures and fittings in the premises. ‘Making good’ after we complete installation is your responsibility and is not included in the cost of the service.
                                        </p>
                                        <p className="inner-line">
                                        5.5	Our engineers may decline to start or continue installation services at the premise if they believe that doing so would pose a health and safety risk to them (for example, if anyone in the premises has symptoms of COVID-19).
                                        </p><br/>
                                        <h4>6.	Your Account And Password</h4><br/>
                                        <p className="inner-line">
                                        6.1	If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat that information as confidential. You must not disclose it to any third party.
                                        </p>
                                        <p className="inner-line">
                                        6.2	We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of service.
                                        </p>
                                        <p className="inner-line">
                                        6.3	If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at <b style={{textDecoration:'underline'}}>hello@safelyteam.com.</b>
                                        </p>
                                        <p className="inner-line">
                                        6.4	If you wish to set-up or change access controls to your SafelyTeam account, you may do so at any time by means of the SafelyTeam app.  
                                        </p><br/>
                                        <h4>7.	The SafelyTeam App</h4><br/>
                                        <p className="inner-line">
                                        7.1	SafelyTeam grants you a limited personal, non-exclusive, non-transferable, right to use the SafelyTeam mobile application for Android or iOS for your personal, non-commercial use in connection with the SafelyTeam service and only on an Apple or Android device and in accordance with these terms. All other rights in the app are reserved by SafelyTeam on behalf of itself and its licensors. In the event of your breach of these terms your right to use the app will terminate immediately.
                                        </p>
                                        <p className="inner-line">
                                        7.2	The SafelyTeam mobile app is available at Google Play and Apple AppStore. We only support the app if it has been downloaded from those app stores. The app has some minimum device requirements and will only work on certain phones and operating system versions – please check the requirements on Google Play and AppStore to check that the app will work on your device.
                                        </p><br/>
                                        <h4>8.	Alarm Response Centre</h4><br/>
                                        <p className="inner-line">
                                        8.1	If the Subscription you have purchased include access to an Alarm Response Centre, an alarm response centre in the United Kingdom will be notified if certain circumstances are detected by the products. 
                                        </p>
                                        <p className="inner-line">
                                        8.2	The Alarm Response Centre is certified as being in compliance with EN50518, the European Standard for Monitoring and Alarm Receiving Centres.
                                        </p><br/>
                                        <h4>9.	Guard Services</h4><br/>
                                        <p className="inner-line">
                                        9.1	Dispatch of a guard to attend premises in response to an alarm detected by the Alarm Response Centre may be available in certain areas, but is not guaranteed. Guard despatch services shall be chargeable on a case by case basis in accordance with a rate agreed with you at the time that you request them. The services consist of the Alarm Response Centre representative contacting a company that provides on-site response services upon the receipt of signals from the products reporting specific conditions at the premises in which they are located, to request the company to attend the premises.
                                        </p>
                                        <p className="inner-line">
                                        9.2	In the event that we agree to provide you with a guard service, we make no promise regarding the time within which any guard will attend the premises.
                                        </p><br/>
                                        <h4>10.	Limited Mobile Back-up</h4><br/>
                                        <p className="inner-line">
                                        10.1	Some of the products are provided with a temporary mobile back-up connection in the event of failure of the premises internet connection. This is only available within the range of available base stations of the network providing the mobile connection. For the temporary back-up to provide continued service, your property must be located within that range, and it is your sole responsibility to check this before you place your order. 
                                        </p>
                                        <p className="inner-line">
                                        10.2	Use of the mobile network connection in the products is solely for the purpose of facilitating a temporary back-up connection for the products. The connection is subject to any mobile network terms of service we make available to you when ordering the products. We may immediately terminate your Subscription in the event of your breach of any of those terms.
                                        </p><br/>
                                        <h4>11.	Subscriptions</h4><br/>
                                        <p className="inner-line">
                                        11.1	References to a ‘Subscription’ in these terms means a subscription to access the ‘SafelyTeam’ service.
                                        </p>
                                        <p className="inner-line">
                                        11.2	You will be guided through the Subscription process when you make a purchase. Before confirming a purchase, you will be given the opportunity to review your chosen Subscription and amend any errors in your order. Please ensure that you check carefully before confirming your purchase.
                                        </p>
                                        <p className="inner-line">
                                        11.3	Subscriptions are monthly. If you do not cancel your Subscription in accordance with these Terms, the Subscription will automatically renew each month at the then-current price for the next Subscription period. We will charge your payment card on file with us on the first day of the renewal of the Subscription period.
                                        </p><br/>
                                        <h4>12.	Your rights to make changes</h4><br/>
                                        <p className="inner-line">
                                        If you wish to make a change to the product you have ordered please contact us. We will let you know if the change is possible. If it is possible we will let you know about any changes to the price of the product, the timing of supply or anything else which would be necessary as a result of your requested change and ask you to confirm whether you wish to go ahead with the change. 
                                        </p><br/>
                                        <h4>13.	Our rights to make changes</h4><br/>
                                        <p className="inner-line">
                                        <b>13.1	Changes to the products and services. </b>We may change the products and services: 
                                        </p>
                                        <ol type="a">
                                            <li>to reflect changes in relevant laws and regulatory requirements (for example, in the event that the law regarding how we must process information collected from our products); and </li>
                                            <li>to implement minor technical adjustments and improvements, for example to address a security threat. </li>
                                        </ol>
                                        <p className="inner-line">
                                        13.2	Changes to these Terms. We may update these Terms at any time by updating this page. If we make changes that we consider material, we will notify you by email. If you do not agree to any of the changes we notify to you by email, you may terminate your Subscription by emailing us at hello@safelyteam.com. By continuing to use the service after such changes, you will be taken to accept them.
                                        </p><br/>
                                        <h4>14.	Providing the products</h4><br/>
                                        <p className="inner-line">
                                        <b>14.1	Delivery costs. </b>The costs of delivery will be as displayed to you on our website.
                                        </p>
                                        <p className="inner-line">
                                        <b>14.2	When we will provide the products. </b>During the order process we will let you know when we will provide the products to you. We will also tell you during the order process when and how you can end the contract. 
                                        </p>
                                        <p className="inner-line">
                                        <b>14.3	Stopping the contract.</b> We will supply the the service to you until your Subscription expires or you end the contract as described in clause 15 or we end the contract by written notice to you as described in clause 17.
                                        </p>
                                        <p className="inner-line">
                                        <b>14.4	We are not responsible for delays outside our control. </b>If our supply of the products or the service is delayed by an event outside our control then we will contact you as soon as possible to let you know and we will take steps to minimise the effect of the delay. Provided we do this we will not be liable for delays caused by the event, but if there is a risk of substantial delay you may contact us to end the contract and receive a refund for any products or service you have paid for but not received. 
                                        </p>
                                        <p className="inner-line">
                                        <b>14.5	If you are not at home when the products are delivered. </b>If no one is available at your address to take delivery and the products cannot be posted through your letterbox, we will leave you a note informing you of how to rearrange delivery or collect the products from a local depot.
                                        </p>
                                        <p className="inner-line">
                                        <b>14.6	If you do not re-arrange delivery. </b>If, after a failed delivery to you, you do not re-arrange delivery or collect them from a delivery depot we will contact you for further instructions and may charge you for storage costs and any further delivery costs. If, despite our reasonable efforts, we are unable to contact you or re-arrange delivery or collection we may end the contract and clause 17.2 will apply.
                                        </p>
                                        <p className="inner-line">
                                        <b>14.7	When you become responsible for the products. </b>A product will be your responsibility from the time we deliver the product to the address you gave us.
                                        </p>
                                        <p className="inner-line">
                                        <b>14.8	When you own products. </b>You will own products once we have received payment for them in full.
                                        </p>
                                        <p className="inner-line">
                                        <b>14.9	Reasons we may suspend the supply of services to you.</b>We may have to suspend the supply of a product to:
                                        </p>
                                        <ol type="a">
                                            <li>deal with technical problems or make minor technical changes;</li>
                                            <li>update the services to reflect changes in relevant laws and regulatory requirements;</li>
                                            <li>make changes to the services as requested by you or notified by us to you (see clause 13).</li>
                                        </ol>
                                        <p className="inner-line">
                                        <b>14.10	Your rights if we suspend the supply of the service. </b>We will contact you in advance to tell you we will be suspending supply of the service, unless the problem is urgent or an emergency. If we have to suspend the service for longer than 3 days in any 7 day period we will adjust the price so that you do not pay for the service while they are suspended. You may contact us to end the contract if we suspend it (or tell you we are going to suspend it) for a period of more than 7 days and we will refund any sums you have paid in advance for the service in respect of the period after you end the contract.
                                        </p>
                                        <p className="inner-line">
                                        <b>14.11	We may also suspend supply of the service if you do not pay. </b>If you do not pay us for the products or the service when you are supposed to (see clause 21.4) and you still do not make payment within 7 days of us reminding you that payment is due, we may suspend supply of the service until you have paid us the outstanding amounts. We will contact you to tell you we are suspending supply of the service. We will not suspend the service where you dispute the unpaid invoice (see clause 21.7). We will not charge you for the service during the period for which the service is suspended. 
                                        </p><br/>
                                        <h4>15.	Your rights to end the contract</h4><br/>
                                        <p className="inner-line">
                                        <b>15.1	Ending your contract with us. </b>Your rights when you end the contract will depend on what you have bought, whether there is anything wrong with it, how we are performing, when you decide to end the contract and whether you are a consumer or business customer:
                                        </p>
                                        <ol type="a">
                                            <li>
                                                <b>If you want to end the contract because of something we have done or have told you we are going to do, see </b>clause 15.2;
                                            </li>
                                            <li><b>If you are a consumer and have just changed your mind about the service, see </b>clause 15.3. You may be able to get a refund if you are within the cooling-off period, but this may be subject to deductions and you will have to pay the costs of return of any products; or</li>
                                            <li><b>(c)	If what you have bought is faulty or misdescribed you may have a legal right to end the contract </b>(or to get the product repaired or replaced or a service re-performed or to get some or all of your money back), see clause 19 if you are a consumer and clause 20 if you are a business.</li>
                                        </ol>
                                        <p className="inner-line">
                                        <b>15.2	Ending the contract because of something we have done or are going to do. </b>IIf you are ending a contract for a reason set out at (a) to (e) below, the contract will end immediately and we will refund you in full for any products or services which have not been provided and you may also be entitled to compensation. The reasons are:
                                        </p>
                                        <ol type="a">
                                            <li>
                                                we have told you about an upcoming change to the product or the service or these terms which you do not agree to;

                                            </li>
                                            <li>we have told you about an error in the price or description of the service you have ordered and you do not wish to proceed;</li>
                                            <li>there is a risk that supply of the products or services may be significantly delayed because of events outside our control; </li>
                                            <li>we have suspended supply of the products or services for technical reasons, or notify you we are going to suspend them for technical reasons, in each case for a period of more than 7 days; or</li>
                                            <li>you have a legal right to end the contract because of something we have done wrong. </li>
                                            
                                        </ol>
                                        <p className="inner-line">
                                        <b>15.3	Exercising your right to change your mind if you are a consumer (Consumer Contracts Regulations 2013). </b>If you are a consumer then for most products bought online you have a legal right to change your mind within 14 days and receive a refund. These rights, under the Consumer Contracts Regulations 2013, are explained in more detail in these terms. 
                                        </p><p className="inner-line">
                                        <b>15.4	Our goodwill guarantee for consumers. </b>Please note, these terms reflect the goodwill guarantee offered by SafelyTeam Ltd of Nene House, 4 Rushmills, Northampton, England, NN4 7YB to its UK consumer customers, which is more generous than your legal rights under the Consumer Contracts Regulations in the ways set out below. This goodwill guarantee does not affect your legal rights in relation to faulty or misdescribed products (see clause 19.1):
                                        </p>
                                        <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Right under the Consumer Contracts<br/>Regulations 2013</th>
                                                    <th>How our goodwill guarantee is more<br/> generous</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>14-day period to change your mind.</td>
                                                    <td>30 day period to change your mind.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                        <p className="inner-line">
                                        <b>15.5	When consumers do not have a right to change their minds. </b>Your right as a consumer to change your mind does not apply in respect of:
                                        </p>
                                        <ol type="a">
                                            <li>Installation services, once these have been completed, even if the cancellation period is still running; or</li>
                                            <li>products once they have been installed, or they have been attached adhesively to anything in the premises.</li>

                                        </ol>
                                        <p className="inner-line">
                                        <b>15.6	How long do consumers have to change their minds? </b>If you are a consumer how long you have to change your mind depends on what you have ordered and how it is delivered.
                                        </p>
                                        <ol type="a">
                                            <li>You have 30 days after the day you (or someone you nominate) receives the products, unless they are split into several deliveries over different days. In this case you have until 30 days after the day you (or someone you nominate) receives the last delivery.</li>
                                        </ol>
                                        <br/>
                                        <h4>16.	How to end the contract with us (including if you are a consumer who has changed their mind)</h4><br/>
                                        <p className="inner-line">
                                        <b>16.1	Tell us you want to end the contract. </b>To end the contract with us, please let us know by calling customer services on <b>0808 501 5388</b> or email us at <b style={{textDecoration:'underline'}}>hello@safelyteam.com.</b> Please provide your name, home address, details of the order and, where available, your phone number and email address. 
                                        </p>
                                        <p className="inner-line">
                                        <b>16.2	Returning products after ending the contract. </b>. If you end the contract for any reason after products have been dispatched to you or you have received them, you must return them to us. You must either return the products in person to where you bought them, or post them back to us at SafelyTeam Ltd, 130 Aztec West, Bristol BS32 4UB. Please call customer services on 0808 501 5388 or email us at <b style={{textDecoration:'underline'}}>hello@safelyteam.com</b> for a return label or to arrange collection. If you are a consumer exercising your right to change your mind you must send off the products within 14 days of telling us you wish to end the contract. 
                                        </p>
                                        <p className="inner-line">
                                        <b>16.3	When we will pay the costs of return. </b>We will pay the costs of return:
                                        </p>
                                        <ol type="a">
                                            <li>if the products are faulty or misdescribed; or</li>
                                            <li>if you are ending the contract because we have told you of an upcoming change to the product or these terms, an error in pricing or description, a delay in delivery due to events outside our control or because you have a legal right to do so as a result of something we have done wrong.</li>
                                        </ol>
                                        <p className="inner-line">
                                        In all other circumstances (including where you are a consumer exercising your right to change your mind) you must pay the costs of return.   
                                        </p>
                                        <p className="inner-line">
                                        <b>16.4	How we will refund you. </b>If you are entitled to a refund under these terms we will refund you the price you paid for the products including delivery costs, by the method you used for payment. However, we may make deductions from the price, as described below.
                                        </p>
                                        <p className="inner-line">
                                        <b>16.5	When we may make deduction from refunds if you are a consumer exercising your right to change your mind.</b> If you are exercising your right to change your mind: 
                                        </p>
                                        <ol type="a">
                                            <li>We may reduce your refund of the price (excluding delivery costs) to reflect any reduction in the value of the products, if this has been caused by your handling them in a way which would not be permitted in a shop. If we refund you the price paid before we are able to inspect the products and later discover you have handled them in an unacceptable way, you must pay us an appropriate amount. </li>
                                            <li>The maximum refund for delivery costs will be the costs of delivery by the least expensive delivery method we offer. For example, if we offer delivery of a product within 3-5 days at one cost but you choose to have the product delivered within 24 hours at a higher cost, then we will only refund what you would have paid for the cheaper delivery option.</li>
                                            <li>Where the product is a service, we may deduct from any refund an amount for the supply of the service for the period for which it was supplied, ending with the time when you told us you had changed your mind. The amount will be in proportion to what has been supplied, in comparison with the full coverage of the contract.</li>
                                            
                                        </ol>
                                        <p className="inner-line">
                                        <b>16.6	When your refund will be made. </b>We will make any refunds due to you as soon as possible. If you are a consumer exercising your right to change your mind then: 
                                        </p>
                                        <ol type="a">
                                            <li>If we have not offered to collect them, your refund will be made within 14 days from the day on which we receive the product back from you or, if earlier, the day on which you provide us with evidence that you have sent the product back to us. For information about how to return a product to us, see clause 16.2.</li>
                                            <li>In all other cases, your refund will be made within 14 days of your telling us you have changed your mind.</li>
                                        </ol>
                                        <br/>
                                        <h4>17.	Our rights to end the contract</h4><br/>
                                        <p className="inner-line">
                                        <b>17.1	We may end the contract if you break it. </b>We may end the contract for a product or the service at any time by writing to you if:
                                        </p>
                                        <ol type="a">
                                            <li>you do not make any payment to us when it is due and you still do not make payment within 7 days of us reminding you that payment is due;</li>
                                            <li>you do not, within a reasonable time, allow us to deliver the products to you or collect them from us; or</li>
                                            <li>you do not, within a reasonable time, allow us access to your premises to supply the services.</li>
                                        </ol>
                                        <p className="inner-line">
                                        <b>17.2	You must compensate us if you break the contract.</b>. If we end the contract in the situations set out in clause 17.1 we will refund any money you have paid in advance for products we have not provided but we may deduct or charge you reasonable compensation for the net costs we will incur as a result of your breaking the contract.
                                        </p>
                                        <p className="inner-line">
                                        <b>17.3 We may withdraw the service. </b>We may write to you to let you know that we are going to stop providing the service. We will let you know at least 14 days in advance of our stopping the supply of the service and will refund any sums you have paid in advance for products which will not be provided. 
                                        </p><br/>
                                        <h4>18.	If there is a problem with the product</h4><br/>
                                        <p className="inner-line">
                                        <b>How to tell us about problems. </b>If you have any questions or complaints about the products or the service, please contact us. You can telephone our customer service team at 0808 501 5388 or write to us at SafelyTeam Ltd, 130 Aztec West, Bristol BS32 4UB. 
                                        </p><br/>
                                        <h4>19.	Your rights in respect of defective products or services if you are a consumer</h4><br/>
                                        <p className="inner-line">
                                        19.1	If you are a consumer we are under a legal duty to supply products and services that are in conformity with this contract. See the box below for a summary of your key legal rights in relation to the products and services. Nothing in these terms will affect your legal rights.
                                        </p>
                                        <p className="inner-line">
                                        <b>19.2	Your obligation to return rejected products. </b>If you wish to exercise your legal rights to reject products you must post them back to us at SafelyTeam Ltd, 130 Aztec West, Bristol BS32 4UB. If they are indeed faulty, we will pay the costs of postage or collection. Please call customer services on 0808 501 5388 or email us at <b style={{textDecoration:'underline'}}>hello@safelyteam.com</b> in these circumstances.
                                        </p><br/>
                                        <h4>20.	Your rights in respect of defective products if you are a business</h4><br/>
                                        <p className="inner-line">
                                        20.1	If you are a business customer we warrant that on delivery any products we send you or services we provide to you shall:
                                        </p>
                                        <ol type="a">
                                            <li>conform in all material respects with their description; and</li>
                                            <li>be free from material defects in design, material and workmanship.</li>
                                        </ol>
                                        <p className="inner-line">
                                        20.2	Subject to clause 20.3, if:
                                        </p>
                                        <ol type="a">
                                            <li>you give us notice in writing within a reasonable time of discovery that a product or service does not comply with the warranty set out in clause 20.1;</li>
                                            <li>we are given a reasonable opportunity of examining such product; and</li>
                                            <li>you return such product to us at our cost,<br/>we shall, at our option, repair or replace the defective product, or refund the price of the defective product in full.</li>
                                        </ol>
                                        <p className="inner-line">
                                        20.3	We will not be liable for a product's failure to comply with the warranty in clause 20.1 if: 
                                        </p>
                                        <ol type="a">
                                            <li>you make any further use of such product after giving a notice in accordance with clause 20.2(a);</li>
                                            <li>the defect arises because you failed to follow our oral or written instructions as to the storage, installation, commissioning, use or maintenance of the product or (if there are none) good trade practice;</li>
                                            <li>the defect arises as a result of us following any drawing, design or specification supplied by you;</li>
                                            <li>you alter or repair the product without our written consent; or</li>
                                            <li>the defect arises as a result of fair wear and tear, wilful damage, negligence, or abnormal working conditions.</li>
                                        </ol>
                                        <p className="inner-line">
                                        20.4	Except as provided in this clause 20, we shall have no liability to you in respect of a product's failure to comply with the warranty set out in clause 20.1.
                                        </p>
                                        <p className="inner-line">
                                        20.5	These terms shall apply to any repaired or replacement products supplied by us under clause 20.2.
                                        </p><br/>
                                        <h4>21.	Price and payment</h4><br/>

                                        <p className="inner-line">
                                        <b>21.1	Where to find the price for the products and service. </b>The price of the products and service (which includes VAT) will be the price indicated on the order pages when you placed your order. We use our best efforts to ensure that the price advised to you is correct. However please see clause 21.3 for what happens if we discover an error in the price of the product you order. 
                                        </p>
                                        <p className="inner-line">
                                        <b>21.2	We will pass on changes in the rate of VAT. </b>If the rate of VAT changes between your order date and the date we supply the products, we will adjust the rate of VAT that you pay, unless you have already paid for the products in full before the change in the rate of VAT takes effect.
                                        </p>
                                        <p className="inner-line">
                                        <b>21.3	What happens if we got the price wrong? </b>It is always possible that, despite our best efforts, some of the products and services we sell may be incorrectly priced. We will normally check prices before accepting your order so that, where the correct price at your order date is less than our stated price at your order date, we will charge the lower amount. If the correct price at your order date is higher than the price stated to you, we will contact you for your instructions before we accept your order. If we accept and process your order where a pricing error is obvious and unmistakeable and could reasonably have been recognised by you as a mispricing, we may end the contract, refund you any sums you have paid and require the return of any products provided to you.
                                        </p>
                                        <p className="inner-line">
                                        <b>21.4	When you must pay and how you must pay. </b>We accept payment by payment card. You must pay for our products before we dispatch them. We will not charge your credit or debit card until we dispatch the products to you. For your Subscription, you must make an advance payment for the first month, before we start providing the service. We will charge you monthly in advance for the service until the end of your Subscription. 
                                        </p>
                                        <p className="inner-line">
                                        <b>21.5	Our right of set-off if you are a business customer. </b>If you are a business customer you must pay all amounts due to us under these terms in full without any set-off, counterclaim, deduction or withholding (other than any deduction or withholding of tax as required by law).
                                        </p>
                                        <p className="inner-line">
                                        <b>21.6	We can charge interest if you pay late. </b>If you do not make any payment to us by the due date we may charge interest to you on the overdue amount at the rate of 4% a year above the base lending rate of Barclays Bank from time to time. This interest shall accrue on a daily basis from the due date until the date of actual payment of the overdue amount, whether before or after judgment. You must pay us interest together with any overdue amount. 
                                        </p>
                                        <p className="inner-line">
                                        <b>21.7	What to do if you think an invoice is wrong. </b>If you think an invoice is wrong please contact us promptly to let us know. You will not have to pay any interest until the dispute is resolved. Once the dispute is resolved we will charge you interest on correctly invoiced sums from the original due date.
                                        </p><br/>
                                        <h4>22.	Intellectual Property Rights</h4><br/>
                                        <p className="inner-line">
                                        22.1	We are the owner or the licensee of all intellectual property rights in the products and services (including the web dashboard and the mobile app). Those works are protected by copyright laws and treaties around the world. All such rights are reserved.
                                        </p>
                                        <p className="inner-line">
                                        22.2	Except for the details of your own profile, you must not modify any material you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.
                                        </p>
                                        <p className="inner-line">
                                        22.3	If you are a business: Except for the purposes of your own accessing of the features within the service for your own internal business purposes, you must not use any part of the products or services for any person’s commercial purposes without obtaining a licence to do so from us or our licensors.
                                        </p>
                                        <p className="inner-line">
                                        22.4	If you are a consumer: You must not use any part of the products or services for your own or any person’s commercial purposes.
                                        </p>
                                        <p className="inner-line">
                                        22.5	The ‘SafelyTeam’ logo is a trade mark of SafelyTeam Ltd. You are not authorised to it as a trade mark without our permission. 
                                        </p><br/>
                                        <h4>23.	Our responsibility for loss or damage suffered by you if you are a consumer</h4>
                                        <p className="inner-line">
                                        <b>23.1	We are responsible to you for foreseeable loss and damage caused by us. </b>If we fail to comply with these terms, we are responsible for loss or damage you suffer that is a foreseeable result of our breaking this contract or our failing to use reasonable care and skill, but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time the contract was made, both we and you knew it might happen, for example, if you discussed it with us during the sales process.
                                        </p>
                                        <p className="inner-line">
                                        <b>23.2	We do not exclude or limit in any way our liability to you where it would be unlawful to do so. </b>This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors; for fraud or fraudulent misrepresentation; for breach of your legal rights in relation to the products or services including the right to receive products and services which are: as described and match information we provided to you and any sample or model seen or examined by you; of satisfactory quality; fit for any particular purpose made known to us; supplied with reasonable skill and care and, where installed by us, correctly installed; and for defective products under the Consumer Protection Act 1987.
                                        </p>
                                        <p className="inner-line">
                                        <b>23.3	We are not liable for business losses. </b>If you are a consumer we only supply the products to you for domestic and private use. If you use the products for any commercial, business or re-sale purpose our liability to you will be limited as set out in clause 24.
                                        </p><br/>
                                        <h4>24.	Our responsibility for loss or damage suffered by you if you are a business</h4><br/>
                                        <p className="inner-line">
                                        24.1	Nothing in these terms shall limit or exclude our liability for:
                                        </p>
                                        <ol type="a">
                                            <li>death or personal injury caused by our negligence, or the negligence of our employees, agents or subcontractors (as applicable);</li>
                                            <li>fraud or fraudulent misrepresentation;</li>
                                            <li>breach of the terms implied by section 12 of the Sale of Goods Act 1979 or section 2 of the Supply of Goods and Services Act 1982; </li>
                                            <li>defective products under the Consumer Protection Act 1987; or</li>
                                            <li>any matter in respect of which it would be unlawful for us to exclude or restrict liability.</li>
                                        </ol>
                                        <p className="inner-line">
                                        24.2	Except to the extent expressly stated in clause 20.1 all terms implied by sections 13 to 15 of the Sale of Goods Act 1979 and sections 3 to 5 of the Supply of Goods and Services Act 1982 are excluded.
                                        </p>
                                        <p className="inner-line">
                                        24.3	Subject to clause 24.1:
                                        </p>
                                        <ol type="a">
                                            <li>we shall not be liable to you, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, for any loss of profit, or any indirect or consequential loss arising under or in connection with any contract between us; and</li>
                                            <li>our total liability to you for all other losses arising under or in connection with any contract between us, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, shall be limited to a sum equal to the total paid by you for products, installation and your Subscription in the previous 12 months.</li>
                                        </ol><br/>
                                        <h4>25.	Other important terms</h4><br/>
                                        <p className="inner-line">
                                        <b>25.1	We may transfer this agreement to someone else. </b>We may transfer our rights and obligations under these terms to another organisation. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract.
                                        </p>
                                        <p className="inner-line">
                                        <b>25.2	You need our consent to transfer your rights to someone else. </b>You may only transfer your rights or your obligations under these terms to another person if we agree to this in writing.
                                        </p>
                                        <p className="inner-line">
                                        <b>25.3	Nobody else has any rights under this contract. </b>This contract is between you and us. No other person shall have any rights to enforce any of its terms.
                                        </p>
                                        <p className="inner-line">
                                        <b>25.4	If a court finds part of this contract illegal, the rest will continue in force.</b>Each of the paragraphs of these terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.
                                        </p>
                                        <p className="inner-line">
                                        <b>25.5	Even if we delay in enforcing this contract, we can still enforce it later. </b>If we do not insist immediately that you do anything you are required to do under these terms, or if we delay in taking steps against you in respect of your breaking this contract, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date. For example, if you miss a payment and we do not chase you but we continue to provide the products, we can still require you to make the payment at a later date.
                                        </p>
                                        <p className="inner-line">
                                        <b>25.6	Which laws apply to this contract and where you may bring legal proceedings if you are a consumer. </b>These terms are governed by English law and you can bring legal proceedings in respect of the products in the English courts. If you live in Scotland you can bring legal proceedings in respect of the products in either the Scottish or the English courts. If you live in Northern Ireland you can bring legal proceedings in respect of the products in either the Northern Irish or the English courts.
                                        </p>
                                        <p className="inner-line">
                                        <b>25.7	Which laws apply to this contract and where you may bring legal proceedings if you are a business.</b>. If you are a business, any dispute or claim arising out of or in connection with a contract between us or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of England and Wales and the courts of England and Wales shall have exclusive jurisdiction to settle any such dispute or claim.
                                        </p><br/><br/>
                                        <h4>Data Processing Terms</h4><br/>
                                        <p className="inner-line">
                                        Definitions
                                        </p>
                                        <p className="inner-line">
                                        These Data Processing Terms form part of the SafelyTeam Terms of Business. In these Data Processing Terms:
                                        </p>
                                        <p className="inner-line">
                                        <b>Appropriate Safeguards: </b>means such legally enforceable mechanism(s) for transfers of Personal Data as may be permitted under Data Protection Laws from time to time;
                                        </p>
                                        <p className="inner-line">
                                        <b>Data Controller: </b>has the meaning given to that term (or to the term “controller”) in Data Protection Laws;
                                        </p>
                                        <p className="inner-line">
                                        <b>Data Processor: </b>: has the meaning given to that term (or to the term “processor”) in Data Protection Laws;
                                        </p>
                                        <p className="inner-line">
                                        <b>Data Protection Laws: </b>means as applicable:
                                        </p>
                                        <ol type="a">
                                            <li>in the United Kingdom:<br/>
                                            (i)&nbsp;the Data Protection Act 2018; and<br/>
                                            (ii)&nbsp;the GDPR, and/or any corresponding or equivalent national laws or regulations;
                                            </li>
                                            <li>any laws replacing, amending, extending, re-enacting or consolidating any of the above Data Protection Laws from time to time (whether or not before or after the date of our agreement).</li>
                                        </ol>
                                        <p className="inner-line">
                                        <b>Data Subject: </b>has the meaning given to that term in Data Protection Laws;
                                        </p>
                                        <p className="inner-line">
                                        <b>Data Subject Request: </b>means a request made by a Data Subject to exercise any rights of Data Subjects under Data Protection Laws;
                                        </p>
                                        <p className="inner-line">
                                        <b>GDPR</b>means the General Data Protection Regulation (EU) 2016/679;
                                        </p>
                                        <p className="inner-line">
                                        <b>International Organisation: </b>means an organisation and its subordinate bodies governed by public international law, or any other body which is set up by, or on the basis of, an agreement between two or more countries;
                                        </p>
                                        <p className="inner-line">
                                        <b>International Recipient: </b>has the meaning given to that term in paragraph 6.1;
                                        </p>
                                        <p className="inner-line">
                                        <b>Personal Data: </b>has the meaning given to that term in Data Protection Laws;
                                        </p>
                                        <p className="inner-line">
                                        <b>Personal Data Breach: </b>means any breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, any Protected Data;
                                        </p>
                                        <p className="inner-line">
                                        <b>Processing </b>has the meanings given to that term in Data Protection Laws (and related terms such as process have corresponding meanings);
                                        </p>
                                        <p className="inner-line">
                                        <b>Processing Instructions: </b>has the meaning given to that term in paragraph 2.1.1;
                                        </p>
                                        <p className="inner-line">
                                        <b>Protected Data: </b>means Personal Data comprised in audio/visual data captured by or on behalf of you to the extent that it is processed by SafelyTeam on your behalf in connection with the performance of SafelyTeam’s obligations under the SafelyTeam terms of service;
                                        </p>
                                        <p className="inner-line">
                                        <b>Services:</b> means the Services as defined under our agreement;
                                        </p>
                                        <p className="inner-line">
                                        <b>Sub-processor:</b>means another Data Processor engaged by SafelyTeam for carrying out processing activities in respect of the Protected Data on behalf of you; and
                                        </p>
                                        <p className="inner-line">
                                        <b>Supervisory Authority: </b>: means any local, national or multinational agency, department, official, parliament, public or statutory person or any government or professional body, regulatory or supervisory authority, board or other body responsible for administering Data Protection Laws.
                                        </p>
                                        <p className="inner-line">
                                        Specific interpretive provision(s)
                                        </p>
                                        <p className="inner-line">
                                        In these Data Processing Terms:
                                        </p>
                                        <ol type="a">
                                            <li>references to any Data Protection Laws shall be replaced with or incorporate (as the case may be) references to any applicable laws replacing, amending, extending, re-enacting or consolidating such Data Protection Laws (including the GDPR and any new Data Protection Laws from time to time) and the equivalent terms defined in such applicable laws, once in force and applicable;</li>
                                            <li>a reference to a law includes all subordinate legislation made under that law; and</li>
                                            <li>references to paragraph numbers are to paragraphs of these Data Processing Terms.</li>

                                        </ol>
                                        <h5>
                                        1.	Data Processor and Data Controller
                                        </h5>
                                        <p className="inner-line">
                                        1.1.	The parties agree that, for the Protected Data, you shall be the Data Controller and SafelyTeam shall be the Data Processor. You acknowledge that SafelyTeam also processes a copy of the User Data in the capacity of data controller. 
                                        </p>
                                        <p className="inner-line">
                                        1.2.	SafelyTeam shall process Protected Data in compliance with: <br/>
                                        &nbsp;1.2.1.	the obligations of Data Processors under Data Protection Laws in respect of the performance of its obligations under our agreement; and<br/>
                                        &nbsp;1.2.2.	the terms of our agreement. 
                                        </p>
                                        <p className="inner-line">
                                        1.3.	You shall comply with: <br/>
                                        &nbsp;1.3.1.	all Data Protection Laws in connection with the processing of Protected Data, the Services and the exercise and performance of your respective rights and obligations under our agreement, including maintaining all relevant regulatory registrations and notifications as required under Data Protection Laws; and <br/>
                                        &nbsp;1.3.2.	the terms of our agreement. 
                                        </p>
                                        <p className="inner-line">
                                        1.4.	You warrant, represent and undertake, that all instructions given by you to SafelyTeam in respect of Personal Data shall at all times be in accordance with Data Protection Laws; and 
                                        </p>
                                        <p className="inner-line">
                                        1.5.	You shall not withhold, delay or condition your agreement to any change to our agreement, the Services requested by SafelyTeam in order to promote compliance with Data Protection Laws by the Services, SafelyTeam and any Sub-Processor.
                                        </p>
                                        <h5>2.	Instructions and details of processing</h5>
                                        <p className="inner-line">
                                        2.1.	Insofar as SafelyTeam processes Protected Data on behalf of you, SafelyTeam:<br/>
                                        &nbsp;2.1.1.	unless required to do otherwise by applicable law, shall (and shall take steps to ensure each person acting under its authority shall) process the Protected Data only on and in accordance with your documented instructions as set out in this paragraph 2 and the Data Processing Details below, as updated from time to time in accordance by agreement between the parties (“Processing Instructions”);<br/>
                                        &nbsp;2.1.2.	if applicable law requires it to process Protected Data other than in accordance with the Processing Instructions, shall notify you of any such requirement before processing the Protected Data (unless applicable law prohibits such information on important grounds of public interest); and<br/>
                                        &nbsp;2.1.3.	shall inform you if SafelyTeam becomes aware of a Processing Instruction that, in SafelyTeam’s opinion, infringes Data Protection Laws, provided that this shall be without prejudice to paragraphs 1.3 and 1.4.
                                        </p>
                                        <p className="inner-line">
                                        2.2.	The processing of Protected Data to be carried out by SafelyTeam under our agreement shall comprise the processing set out in the Data Processing Details below, as may be updated from time to time by agreement between the parties.
                                        </p>
                                        <h5>3.	Technical and organisational measures</h5>
                                        <p className="inner-line">
                                        3.1.	SafelyTeam shall implement and maintain, at its cost and expense, the technical and organisational measures:<br/>
                                        &nbsp;3.1.1.	in relation to the processing of Protected Data by SafelyTeam, as set out in the Data Processing Details below; and<br/>
                                        &nbsp;3.1.2.	taking into account the nature of the processing, to assist you insofar as is possible in the fulfilment of your obligations to respond to Data Subject Requests relating to Protected Data.
                                        </p>
                                        <p className="inner-line">
                                        3.2.	Any additional technical and organisational measures shall be at your cost and expense.
                                        </p>
                                        <h5>4.	Using staff and other processors</h5>
                                        <p className="inner-line">
                                        4.1.	You provide general written authorisation to SafelyTeam to engage Sub-processors to perform the Services, including <b>EZVIZ</b>. You shall be given the opportunity to object to any new Sub-Processor and state your grounds for doing so. You acknowledge that Sub-processors are essential in order for SafelyTeam to provide the Services and that objecting to the use of a Sub-Processor may prevent SafelyTeam from continuing to provide the Services to you. In the event that SafelyTeam is unable to adequately address those objections, either party may terminate our agreement upon notice without liability to the other. For the avoidance of doubt, in such circumstances SafelyTeam shall not be obliged to refund any Subscription fees paid by you.<br/>
                                        
                                        </p>
                                        <p className="inner-line">
                                        4.2.	SafelyTeam shall: <br/>
                                        &nbsp;4.2.1.	appoint each Sub-Processor under a written contract substantially on the standard terms of business of that Sub-Processor, or containing materially the same obligations as under these Data Processing Terms, that is enforceable by SafelyTeam; and<br/>
                                        &nbsp;4.2.2.	remain fully liable for all the acts and omissions of each Sub-Processor which constitutes a breach of these terms as if they were its own.
                                        </p>
                                        <p className="inner-line">
                                        4.3.	SafelyTeam shall ensure that all persons authorised by it to process Protected Data are subject to an obligation to keep the Protected Data confidential (except where disclosure is required in accordance with applicable law).
                                        </p>
                                        <h5>5.	Assistance with your compliance and Data Subject rights</h5>
                                        <p className="inner-line">
                                        5.1.	SafelyTeam shall refer all Data Subject Requests it receives to you, provided that if the number of Data Subject Requests exceeds 5 per calendar month, you shall pay SafelyTeam’s charges calculated on a time and materials basis at SafelyTeam’s then current rates for recording and referring the Data Subject Requests in accordance with this paragraph 5.1.
                                        </p>
                                        <p className="inner-line">
                                        5.2.	From the GDPR Date, SafelyTeam shall provide such reasonable assistance as you reasonably require (taking into account the nature of processing and the information available to SafelyTeam) to you in ensuring compliance with your obligations under Data Protection Laws with respect to:<br/>
                                        &nbsp;5.2.1.	security of processing;<br/>
                                        &nbsp;5.2.2.	data protection impact assessments (as such term is defined in Data Protection Laws);<br/>
                                        &nbsp;5.2.3.	prior consultation with a Supervisory Authority regarding high risk processing; and<br/>
                                        &nbsp;5.2.4.	notifications to the Supervisory Authority and/or communications to Data Subjects by you in response to any Personal Data Breach,<br/>
                                        provided you shall pay SafelyTeam’s charges for providing the assistance in this paragraph 5.2, such charges to be calculated on a time and materials basis at SafelyTeam’s then-current rates for professional services.
                                        </p>
                                        <h5>6.	International data transfers</h5>
                                        <p className="inner-line">
                                        6.1.	You agrees that SafelyTeam may transfer Protected Data to countries outside the United Kingdom or to any International Organisation(s) (an “International Recipient”), provided all transfers by SafelyTeam of Protected Data to an International Recipient shall (to the extent required under Data Protection Laws) be effected by way of Appropriate Safeguards and in accordance with Data Protection Laws. The provisions of our agreement shall constitute your instructions with respect to transfers in accordance with paragraph 2.1.
                                        </p>
                                        <h5>7.	Records, information and audit</h5>
                                        <p className="inner-line">
                                        7.1.	SafelyTeam shall maintain, in accordance with Data Protection Laws binding on SafelyTeam, written records of all categories of processing activities carried out on behalf of you.
                                        </p>
                                        <p className="inner-line">
                                        7.2.	SafelyTeam shall, in accordance with Data Protection Laws, contribute and allow for audits either by (at its option): (i) making available to you upon reasonable request interviews with SafelyTeam personnel and documents, which you must treat confidentially under the confidentiality provisions of our agreement or under a non-disclosure agreement concluded between the Parties; or (ii) responding to a written security questionnaire submitted to it by you provided that you will not exercise this right more than once per year and will hold SafelyTeam’s responses in confidence under the confidentiality provisions of our agreement.
                                        </p>
                                        <h5>
                                        8.	Breach notification
                                        </h5>
                                        <p className="inner-line">
                                        8.1.	In respect of any Personal Data Breach involving Protected Data, SafelyTeam shall, without undue delay:<br/>
                                        &nbsp;8.1.1.	notify you of the Personal Data Breach; and<br/>
                                        &nbsp;8.1.2.	provide you with details of the Personal Data Breach.
                                        </p>
                                        <h5>9.	Deletion or return of Protected Data and copies</h5>
                                        <p className="inner-line">
                                        9.1.	SafelyTeam shall, at your written request, either delete or return all the Protected Data to you in such form as you reasonably request within a reasonable time after the earlier of:<br/>
                                        &nbsp;9.1.1.	the end of the provision of the relevant Services related to processing; or<br/>
                                        &nbsp;9.1.2.	once processing by SafelyTeam of any Protected Data is no longer required for the purpose of SafelyTeam’s performance of its relevant obligations under our agreement,<br/>
                                        &nbsp;and delete existing copies (unless storage of any data is required by applicable law and, if so, SafelyTeam shall inform you of any such requirement).
                                        </p><br/>
                                        <h5>DATA PROCESSING DETAILS</h5><br/>
                                        <p className="inner-line">
                                        <b>1 Subject-matter of processing:</b> <br/>Providing the SafelyTeam service.
                                        </p>
                                        <p className="inner-line">
                                        <b>2 Duration of the processing:</b><br/>For the duration of the provision of the Services (including any retention of Personal Data comprised in the Services).
                                        </p>
                                        <p className="inner-line">
                                        <b>3 Nature and purpose of the processing:</b> <br/>To provide the SafelyTeam service to you.
                                        </p>
                                        <p className="inner-line">
                                        <b>4 Type of Personal Data:</b><br/>Audio-visual data captured by equipment connected to the SafelyTeam hub provided to you.
                                        </p>
                                        <p className="inner-line">
                                            <b>5 Categories of Data Subjects:</b><br/>Individuals identifiable from the audio/video data captured by you.
                                        </p>



                                    </div>
                                    </div>
                                    
                                    

                                    <div className="row">
                                    <div className="col-md-12 px-0">
                                        
                                        
                                    </div>
                                    </div>
                                    <div className="row ptb-inner">
                                    <div className="col-md-4 px-0 pt-5"> <img src={require('./img/build-btm.jpg')} className="img-fluid" /> </div>
                                    <div className="col-md-4 px-0 pt-5"> </div>
                                    <div className="col-md-4 px-0 pt-5"> <img src={require('./img/right-man.jpg')} className="img-fluid" /> </div>
                                    </div>


                                </div>
                                </div>
                            
               
                          <Subscribe />

                      </div>
        
      </NewLayout>
    )
}

export default TermsConditions
