import React, {useState, useEffect} from 'react'
import { API } from "../../../config";
import { updateOrderSts,updateSubscribeSts,getOrderByID} from './apiUser'
import { isAuthenticated } from '../../../auth/index'
import NewLayout from '../NewLayout'


const Success = () => {

  const { user,token } = isAuthenticated();

  const [session,setSession] = useState({});

  const [values1,setValues1] = useState({
    title:'',
    error1:'',
    success:false
  })

  const { title,error1,success } = values1;

  const params = new URLSearchParams(window.location.search.substring(1));
  const orderid = params.get("order");    
  const sessionId = params.get("session_id");  
  const preConfig = params.get("preconfig");
  const subscription_type = params.get("sub_type");

  const [values, setValues] = useState({successmsg: false,error: '', updated: false})
  const {error, successmsg, updated,  } = values

 useEffect(() => {
   loadOrders(orderid)
   async function fetchSession() {

    if(subscription_type === "0" || subscription_type === "1") {
      setSession(
        await fetch(`${API}/checkout-session?sessionId=` + sessionId+`&userId=`+ user._id).then((res) =>
          res.json().then((responseJson) => {
           
                        updateSubscription(orderid, responseJson.subscription,responseJson.id);
                    })
        )
      )
    } else {
      setSession(
        await fetch(`${API}/checkout-session?sessionId=` + sessionId+`&userId=`+ user._id).then((res) =>
          res.json().then((responseJson) => {
            
                        updateOrder(orderid, responseJson.payment_intent);
                    })
        )
      )
    }
     
   }
   fetchSession();
 }, [sessionId]);


  const loadOrders = (orderid) => {
    getOrderByID(orderid).then(data => {
      if(data.error) {
        setValues1({...values1,error1:data.error,success:false})
      } else {
        setValues1({
          ...values1,
          title:data.title,
          error1:'',
          success:true  
        })
        
      }
    }) 
  }

const showError = () => (
  <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
      {/* {error} */} Unable to placed Order
  </div>
);

const showSuccess = () => {
  if(success) {
    if(preConfig === "1" && title.includes("Customised Safely Package")) {
  
      return <div className="alert alert-success"><h4>Thank you. Your order has been placed successfully! <a className="btn btn-info btn-gra-my-p" href="/user/yourorders">Click here to see your order</a></h4></div>
    }
    else if(preConfig === "0" && title.includes("Customised Safely Package")) {
     
      return <div className="alert alert-success"><h4>Thank you. Your order has been placed successfully! <a className="btn btn-info btn-gra-my-p" href={`/user/order/details/${orderid}`}>Click here to pre-configure your order</a></h4></div>
    }  
    else {
     
      return <div className="alert alert-success"><h4>Thank you. Your order has been placed successfully! <a className="btn btn-info btn-gra-my-p" href="/user/yourorders">Click here to see your order</a></h4></div>
    }
  }
};

const updateOrder = (orderid, paymentid) => {
   updateOrderSts(orderid, 'Awaiting Customer Pre-configuration', paymentid).then(data => {
       if(data.error) {
           setValues({...values, error: data.error, successmsg:false})
       } else {
           setValues({...values, error:'', successmsg: true, updated:true})
       }
   })  
 };    
 
 const updateSubscription = (orderid, subscriptionid,payment) => {
  updateSubscribeSts(orderid, 'Awaiting Customer Pre-configuration', subscriptionid,payment,subscription_type).then(data => {
      if(data.error) {
          setValues({...values, error: data.error, successmsg:false})
      } else {
          setValues({...values, error:'', successmsg: true, updated:true})
      }
  })  
}; 

return (
      <NewLayout topTitle="SafelyTeam - Order Success">
          <section> 
            <div className="col-md-12 px-0 pt-5">
              <div className="col-md-12 px-0 inner-tp-pad ">                          
                <div className="container abt-text-top-container pt-5">
                  <div className="col-md-12 py-5">
                      <h1 className="text-center pt-5 blue-acti">Order Success</h1>
                            
                        {showSuccess()}
                        {showError()}
                  </div>
                </div>
              </div>
            </div>
          </section> 
      </NewLayout>
   
)
}

export default Success