import React, {useState,useEffect} from 'react'
import AdminLayout from "../theme/admin/AdminLayout"
import { isAuthenticated } from '../auth/index'
import { Redirect,Link } from "react-router-dom"
import { updateNewsletter,getNewsletter } from './apiAdmin'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const UpdateNewsletter = ({match}) => {

    const classes = useStyles();

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState({
        email: '',
        status: '',
        error: '',
        btnloading:false,
        success: false
    });

    const {email,status,btnloading,success, error} = values

    // user and token from local storage
    const { user, token} = isAuthenticated()


    const init = newsletterId => {
        getNewsletter(newsletterId).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
                setLoading(false);
            } else {
                // populate the state
                setValues({
                    ...values,
                    email: data.email,
                    status: data.status
                });
                setLoading(false);
            }
        });
    };


    useEffect(() => {
        init(match.params.newsletterId);
    }, []);

    const handleChange = name => event => {
        setValues({ ...values, error: '',success:false, [name]: event.target.value,btnloading:false});
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        setValues({...values,success:false, error: '',btnloading:true });
        updateNewsletter(user._id,token,{id:match.params.newsletterId,email, status})
        .then(data => {
            if(data.error) {
                setValues({...values, error: data.error, success:false,btnloading:false})
            } else {
                setValues({
                    ...values,
                    email:'',
                    status: '',
                    error: '',
                    btnloading:false,
                    success: true
                })
            }
        })
    }

    const [statusTypes, setStatusTypes] = useState([
        {label:"Subscribe", value:"1"},
        {label:"UnSubscribe", value:"2"},
    ])
 
    const updateNewsletterForm = () => (
        <form onSubmit={clickSubmit}>
            <div className="form-group">
                <label className="text-muted">Email</label>
                <input onChange={handleChange('email')} type="text" className="form-control"  value={email}  readOnly/>      
            </div>

            <div className="form-group">
                 <label className="text-muted">Status</label>
                 <select required
                    onChange={handleChange('status')} 
                    className="form-control">
                        <option>Select type</option> 
                       {
                           statusTypes.map(cus => (
                           (Number(status) === Number(cus.value) ? <option key={cus.value} value={cus.value} selected>{cus.label}</option>
                           : <option key={cus.value} value={cus.value}>{cus.label}</option>)
                           ))
                       }
                                  
                 </select>
             </div>
             <button  className="btn btn-outline-primary" disabled={btnloading}> {btnloading ? "Loading..." : "Update"} </button>
            <div><Link className="nav-link" to="/admin/newsletters">Go Back to List </Link></div>
        </form>
    );

    const showSuccess = () => {
        if(success) {
            return <Redirect to="/admin/newsletters" />
        }
    };

    const showError = () => {
        if(error) {
            return <h3 className="text-danger">{error}</h3>
        }
    };

    return (
        <AdminLayout className="container" topTitle="Update Newsletter">
                <div className="row">              
                    <div className="col-lg-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title" style={{textAlign:'center'}}>Update Newsletter</h4>
                                    <div className="row">
                                        <div className="col-md-8 offset-md-2">
               
                                            {showSuccess()}
                                            {showError()}
                                            {!loading && updateNewsletterForm()}

                                            <Backdrop className={classes.backdrop} open={loading} >
                                                <CircularProgress color="inherit" />
                                            </Backdrop>  
                   
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminLayout>
    )
}

export default UpdateNewsletter
