import React,{useState, useEffect} from 'react'
import AdminLayout from '../theme/admin/AdminLayout'
import { getPreOrdComments,createPreOrdComments } from './apiAdmin'
import {isAuthenticated} from '../auth/index'
import Moment from 'react-moment';
import { Badge, Modal } from 'react-bootstrap'

const PreorderDetails = ({match}) => {

    const { user, token} = isAuthenticated();

    const [ btnloading, setBtnloading] = useState(true);

    const [preComments, setPreComments] = useState([]);

    const [values1 , setValues1] = useState({
        comments:'',
        isBackup:false,
        yesBack:'',
        noBack:'',
        backupDate:'',
        status:2,
        btnloading2:false,
        error:'',
        success: false
    })

    const {comments, isBackup, backupDate, status, yesBack, noBack, btnloading2, success, error} = values1

    const [openModal, setModal] = useState(false);

    const [hide, setHide] = useState(false) 

    const loadPreComments = (ID) => {
        getPreOrdComments(user._id,token,{id:ID}).then(data => {
            if(data.error){
                console.log(data.error);
                setBtnloading(false)
            }else {
                setPreComments(data);
                setBtnloading(false)
            }
        })
    }

    useEffect(() => {
        loadPreComments(match.params.preOrdId)
    },[])

    const showTable = () => (

        <div className="row">
            <div className="table-responsive pt-3 px-2">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Comments</th>
                            <th>Backup needed</th>
                            <th>Backup Date</th>
                            <th>Status</th>
                            <th>Added By</th>
                            <th>Date of comment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {preComments.map((p,i) => (
                            <tr key={i}>
                                <td>{p.comments}</td>
                                <td>{p.isBackup ? "Yes" : "No"}</td>
                                <td>{p.isBackup ? <Moment format='DD/MM/YYYY'>{p.backupDate}</Moment> : '-'}</td>
                                <td>{p.status === 1 ? <Badge pill variant="warning">In progress</Badge> : <Badge pill variant="danger">Closed</Badge>}</td>
                                <td>{p.added_by.name}</td>
                                <td><Moment format='DD/MM/YYYY'>{p.createdAt}</Moment></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {preComments.length === 0 && <><br/><h4 style={{textAlign:'center', color:'red'}}>Sorry! No Pre-Order comments found.</h4></>}
            </div>
        </div>
    )

    const handleChangeComments = name => event => {

        if(name === "yesBack"){
            setHide(true);
            setValues1({...values1, [name]: event.target.checked, isBackup: true, error:'', success:false, btnloading2:false})
        }
        else if(name === "noBack"){
            setHide(false);
            setValues1({...values1, [name]: event.target.checked, isBackup: false, error:'', success:false, btnloading2:false})
        }
        else {
            setValues1({...values1, [name]: event.target.value, error:'', success:false, btnloading2:false})
        }
    }

    const clickCommentSubmit = (e) => {
        e.preventDefault();
        setValues1({...values1, error:'', success:false, btnloading2:true});
        createPreOrdComments(user._id, token, {preorderId:match.params.preOrdId,comments, isBackup, backupDate, added_by: user._id, yesBack, noBack})
        .then(data => {
            if(data.error){
                setValues1({...values1, error:data.error, success:false, btnloading2: false});
            }
            else {
                loadPreComments(match.params.preOrdId);
                setValues1({
                    ...values1,
                    comments:'',
                    isBackup:false,
                    yesBack:'',
                    noBack:'',
                    backupDate:'',
                    success: true,
                    error:'',
                    btnloading2: false
                })
                setHide(false);
                setModal(false)
            }
        })
    }

    const showNewsletterModal = () => {

        return (
            <Modal show={openModal} onHide={() => setModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={clickCommentSubmit}>
                    <div className="form-group">
                        <label className="text-muted">Enter comment<span style={{color:"red"}}> *</span></label>
                        <textarea onChange={handleChangeComments('comments')} className="form-control" value={comments} required/>
                    </div>

                    <div className="form-group">
                    <label className="text-muted">Backup needed:<span style={{color:"red"}}> *</span></label><br/>
                        <input type="radio" onChange={handleChangeComments('yesBack')} name="backup" value={yesBack} />
                        <label htmlFor="yesBack" >&nbsp;Yes</label><br/>
                        <input type="radio" onChange={handleChangeComments('noBack')} name="backup" value={noBack} />
                        <label htmlFor="noBack" >&nbsp;No</label><br/>
                    </div>

                    { hide && <div className="form-group">
                        <label className="text-muted">Enter Backup Date<span style={{color:"red"}}> *</span></label>
                        <input type="date" onChange={handleChangeComments('backupDate')} className="form-control" value={backupDate} required/>
                    </div>}
                    
                    <button disabled={btnloading2}  className="btn btn-outline-primary">{btnloading2 ? "Loading..." : "Add"}</button>
                    <br/>
                    {showError()}
                </form>
                </Modal.Body>

            </Modal>
        )

    }

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    

    return (
                <AdminLayout className="container" topTitle="Pre-Order Details">
                    <div className="row">  
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title" style={{textAlign:'center'}}>Pre-Order Details</h4>
                                        {showNewsletterModal()}
                                        {(btnloading) ? <div className='loader-container'><div className='loader'></div></div> : ""}

                                        {!btnloading && <button className="btn btn-info btn-gra-my-p" onClick={() => setModal(true)} >Add comments</button>}
                                       
                                        {!btnloading && showTable()} 

                                </div>
                            </div>
                        </div>
                    </div>
                </AdminLayout>
    )
}

export default PreorderDetails
