import React, {useState, useEffect, Fragment} from 'react'
//import Header from './Header'
//import Footer from './Footer'
import { isAuthenticated } from '../../auth/index'
import { Redirect } from "react-router-dom"
import {getHomePage} from '../../admin/apiAdmin'
import { createQuestion, createQuestionuser, createNewsletter,getAds, getAdsProducts } from './Priorities/apiUser'
import { v4 as uuidv4 } from 'uuid'
import {Helmet} from 'react-helmet'
import './css/style.css'
import './css/site.css'
import './css/bootstrap.min.css'
//import './css/owl.carousel.min.css'
//import './css/owl.theme.default.min.css'
import './css/libs/animate.css'
import './css/LineIcons.css'
//import './css/jquery.pagepiling.css'
//import './css/jquery.fancybox.min.css'

import {API} from '../../config'
import loadable from '@loadable/component'

//import './css/LineIcons.css'
const Header = loadable(() => import('./Header'))
const Footer = loadable(() => import('./Footer'))

const HomeNew = () => { 
   
    const [homes, setHomes] = useState([])

    const [values, setValues] = useState({
        temp_user: '',
        safety: false,
        security:false,
        wellness: false,
        safetyclass:'col-md-12 round-gradient1 round-fix-height',
        securityclass:'col-md-12 round-gradient1 round-fix-height',
        wellnessclass:'col-md-12 round-gradient1 round-fix-height',
        error: '',       
        success: false
    });
   
    const { user, token} = isAuthenticated();    

    const {  safety, security, wellness,safetyclass,securityclass,wellnessclass, temp_user, error, success} = values

    const [slider, setSlider] = useState([]);

    const loadHome = () => {
        getHomePage().then(data => {
            if(data.error) {
                console.log(data.error);
                //setSpinloading(false);
            } else {
                setHomes(data);
                console.log(data);
                console.log(data[0].unique_heading1)
                console.log(data[0].slider1_image)
                var arr1=[]
                for(var i=0;i<1;i++){
                  if(data[0].slider1_image && data[0].slider1_text && data[0].slider1_link){
                    arr1.push({
                      text: data[0].slider1_text,
                      link: data[0].slider1_link,
                      image: data[0].slider1_image
                    })
                  }

                  if(data[0].slider2_image && data[0].slider2_text && data[0].slider2_link){
                    arr1.push({
                      text: data[0].slider2_text,
                      link: data[0].slider2_link,
                      image: data[0].slider2_image
                    })
                  }

                  if(data[0].slider3_image && data[0].slider3_text && data[0].slider3_link){
                    arr1.push({
                      text: data[0].slider3_text,
                      link: data[0].slider3_link,
                      image: data[0].slider3_image
                    })
                  }

                }
                setSlider(arr1);     
            }
        });
    };

    const handleChange = name => event => {
        if(name === 'safety') {  
            setValues({...values, [name]: event.target.checked, safetyclass: 'col-md-12 round-gradient1 round-fix-height '+event.target.checked})
        } else if(name === 'security') {
            setValues({...values, [name]: event.target.checked, securityclass: 'col-md-12 round-gradient1 round-fix-height '+event.target.checked})
        } else if(name === 'wellness') {
            setValues({...values, [name]: event.target.checked, wellnessclass: 'col-md-12 round-gradient1 round-fix-height '+event.target.checked})
        } else{
            setValues({ ...values,  [name]: event.target.value});   
        }    
        
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        const temp = uuidv4();
        setValues({...values, error: false });
        localStorage.setItem('ques', JSON.stringify({ safety, security, wellness, temp_user:temp,no_people:1,no_floors:1,no_entdoors:1,no_bedrooms:1 }))
         if(isAuthenticated()) {
            createQuestionuser( user._id, token, { safety, security, wellness, temp_user:temp } )
            .then(data => {
                if(data.error) {
                    setValues({...values, error: data.error, success:false})
                } else {
                    setValues({
                        ...values,
                        temp_user:'',
                        safety: '',
                        security:'',
                        wellness: '',
                        safetyclass:'',
                        securityclass:'',
                        wellnessclass:'',
                        error: '',
                        success: true
                    })
                    window.location.href="/step2/";
                }
            })
        } else { 
            
            createQuestion( { safety, security, wellness, temp_user:temp } )
            .then(data => {
                if(data.error) {
                    setValues({...values, error: data.error, success:false})
                } else {
                    setValues({
                        ...values,
                        temp_user:'',
                        safety: '',
                        security:'',
                        wellness: '',
                        safetyclass:'',
                        securityclass:'',
                        wellnessclass:'',
                        error: '',
                        success: true
                    })
                    window.location.href="/step2/";
                }
            })
           
        }
        
    }

     const checkLocal = () => {
        const quesData = JSON.parse(localStorage.getItem('ques'));
       
        if(localStorage.getItem('ques')) {
                setValues({
                    safety: quesData.safety,
                    security: quesData.security,
                    wellness: quesData.wellness,
                    temp_user: quesData.temp_user,
                    safetyclass:'col-md-12 round-gradient1 round-fix-height '+quesData.safety,
                    securityclass:'col-md-12 round-gradient1 round-fix-height '+quesData.security,
                    wellnessclass:'col-md-12 round-gradient1 round-fix-height '+quesData.wellness,                   
                })
        }       
        
    } 
    const [ads, setAds] = useState([])

    let [adsProducts, setAdsProducts] = useState([]);

    const [spinloading, setSpinloading] = useState(true);

    useEffect(() => {
        loadHome()
        checkLocal();
        loadAds();        
    }, [])
   
    const loadAds = () => {
        getAds().then(async data => {
            if(data.error) {
                console.log(data.error);
            } else {
                var adRes = [];
                for(var i=0;i<data.length;i++){
                    let result = await getAdsProducts({Id:data[i]._id})
                    adRes.push(result)
                }
                setAds(data);
                setAdsProducts(adRes);
                setSpinloading(false)
            }
        });
    };
    const [total,setTotal] = useState(0);
    const GetPrice = (Id) => {
        var sum=0;
        if(adsProducts.length >=1){
            for(var i=0;i<adsProducts.length;i++){
                var tempVal1 = adsProducts[i];
            for(var j=0;j<tempVal1.length;j++){
                if(tempVal1[i].adsId === Id){                   
                    sum+=tempVal1[j].prod_price;
                }
            }
        }             
        }
        return sum;
    };
    const checkProducts = (Id) => {
        var tempVal = "";
        if(adsProducts.length >=1){
            for(var i=0;i<adsProducts.length;i++){
                var tempVal1 = adsProducts[i];
                
                for(var j=0;j<tempVal1.length;j++){
                    if(tempVal1[i].adsId === Id){
                        tempVal += `<div class="col-md-6 px-0 pb-2">${tempVal1[j].productId.name}</div>`
                       
                    }
                } 
              
                if(tempVal1.length===4 && tempVal1[i].adsId === Id)  
                {
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`                   
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                }
                else if(tempVal1.length===6 && tempVal1[i].adsId === Id)  
                {
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`   
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>` 
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                                 
                }
            }
            return <div className="row" dangerouslySetInnerHTML={{__html: tempVal}}></div>
        }
        return <div></div>
    }
    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>        
    );

    const showSuccess = () => (
        success && <Redirect to="/step2" /> 
    );

    const [email, setEmail] = useState('');
    const [error1, setError1] = useState('');
    const [success1, setSucces1] = useState(false);

    const handleChange1 = (e) => {
        setError1('')
        setEmail(e.target.value)
    };

    const clickSubmit1 = (e) => {
        e.preventDefault();
        setError1('');
        setSucces1(false);
        createNewsletter({email})
        .then(data => {
            if(data.error) {
                setError1(data.error);
            } else {
                setError1("");
                setSucces1(true);
            }
        });
    };

    const showSuccess1 = () => {
        if(success1) {
            return <div className="title-mini stay-marg" style={{color:'green'}}>{email} has been successfully subscribed.</div>
        }
    };

    const showError1 = () => {
        if(error1) {
            if(error1 === "11000 duplicate key error collection: SafelyTeam.newsletters index: email already exists") {
                return <div className="title-mini stay-marg" style={{color:'red'}}>{email} already exists!</div>
            }
            else {
                return <div className="title-mini stay-marg" style={{color:'red'}}>{error1}</div>
            }    
        }
    };

    const setAdToken = (id,name,description,price) => {
        localStorage.setItem('ads',JSON.stringify({adID:id,name:name,description:description,salePrice:price}));
        return  window.location.href="/adstep";
    }
   
    return (
        <Fragment>
   <Helmet>
                <title>Safely | The Best DIY Home Security Systems</title>
                <meta name="description" content="Protect your home with Safely's trusted home security products and packages, including wireless burglar alarms, air quality detectors & smart sensors." />
            </Helmet>
         
                <Header />
                
                <section>
  <div className="col-md-12 px-0 tp-menu-show pt-5">

    <div id="demo" className="carousel slide" data-ride="carousel">

      <ul className="carousel-indicators">
      {slider.length >=1 && slider.map((s,i) => (
        
            <li data-target="#demo" data-slide-to={i} className={i===0 ? "active" : ""}></li>     

      ))}
      </ul>
  {/* <ul className="carousel-indicators">
    <li data-target="#demo" data-slide-to="0" className="active"></li>
    <li data-target="#demo" data-slide-to="1"></li> 
  </ul> */}
  <div className="carousel-inner">
  {slider.length >=1 && slider.map((s,i) => (
    

      <div className={i=== 0 ? "carousel-item active" : "carousel-item"}>
        <div className="col-md-12 pl-0 learn-more-btn"> <a className="btn btn-success text-center mt-1 buy-pad-lt-rt" href={s.link} style={{fontSize: '1.2rem'}}>{s.text}</a></div>
        <img src={"/uploads/"+s.image} alt="Buy Now" width="100%"/>
      </div>

    
  ))}
  </div>
  
  
    {/* <div className="carousel-inner">
      
      <div className="carousel-item active">
        <div className="col-md-12 pl-0 learn-more-btn"> <a className="btn btn-success text-center mt-1 buy-pad-lt-rt" href="#choose" style={{fontSize: '1.2rem'}}>Buy Now</a></div>
        <img src={require('./img/sl2.webp')} alt="Buy Now" width="100%"/>
      </div>
      
      <div className="carousel-item">
        <div className="col-md-12 pl-0 learn-more-btn1"> <a className="btn btn-success text-center mt-1" href="/step1" style={{fontSize: '1.2rem'}}>Design your personalised solution</a></div>
        <img src={require('./img/sl1.webp')} alt="Design your personalised solution" width="100%"/>
      </div>
    </div> */}
  <a className="carousel-control-prev" href="#demo" data-slide="prev">
    <img src={require('./img/lt-arrow.png')}/>
  </a>
  <a className="carousel-control-next" href="#demo" data-slide="next">
    <img src={require('./img/rt-arrow.png')}/>
  </a>
 
</div>
    
  </div>

   <div className="col-md-12 px-0 sh-img pt-5 mt-5">
    <div className="col-md-12 pl-0 learn-more-btn">
    <div className="col-md-12" style={{textAlign: 'center'}}> 
    <a className="btn btn-success text-center mt-1" href="#choose-mob" style={{fontSize: '1.2rem'}}>Buy Now</a>
    </div></div>
    <img src={require('./img/mob-banner.webp')} alt="Buy Now" className="img-fluid" />
   </div>
</section>
<section>
  <div className="col-md-12 dark-bg-back py-5">
    <div className="container tp-menu-show">
      <div className="row">
        <div className="col-md-3 text-center wow fadeInUp" data-wow-delay="0.2s">
          <div className="row">
          <div className="col-md-12 px-0 around-mob"><img src={require('./img/around.png')} className="img-fluid"/></div>
          <div className="col-md-12 px-0 around-mob1"><p className="around-text font-size-mob-in">Around The Clock <br/>Protection</p></div></div>
        </div>
        <div className="col-md-3 text-center wow fadeInUp" data-wow-delay="0.3s">
          <div className="row">
          <div className="col-md-12 px-0 around-mob"><img src={require('./img/remote.png')} className="img-fluid"/></div>
          <div className="col-md-12 px-0 around-mob1"><p className="around-text font-size-mob-in">Remote <br/>Monitoring</p></div>
        </div>
        </div>
        <div className="col-md-3 text-center wow fadeInUp" data-wow-delay="0.4s">
          <div className="row">
          <div className="col-md-12 px-0 around-mob"><img src={require('./img/instant.png')} className="img-fluid"/></div>
          <div className="col-md-12 px-0 around-mob1"><p className="around-text font-size-mob-in">Instant Alerts and <br/>Notifications</p></div>
        </div>
        </div>
        <div className="col-md-3 text-center wow fadeInUp" data-wow-delay="0.5s">
          <div className="row">
          <div className="col-md-12 px-0 around-mob"><img src={require('./img/ready.png')} className="img-fluid"/></div>
          <div className="col-md-12 px-0 around-mob1"><p className="around-text font-size-mob-in">Ready Out of <br/>The Box</p></div></div>
        </div>
      </div>

      
    </div>
    <div className="container sh-img">
      <div className="row ">
        <div className="col-md-3 text-center wow fadeInUp wid-50-pers" data-wow-delay="0.2s">
          <div className="row">
          <div className="col-md-12 px-0 around-mob"><img src={require('./img/around.png')} className="img-fluid"/></div>
          <div className="col-md-12 px-0 around-mob1"><p className="around-text font-size-mob-in">Around The Clock <br/>Protection</p></div></div>
        </div>
        <div className="col-md-3 text-center wow fadeInUp wid-50-pers" data-wow-delay="0.3s">
          <div className="row">
          <div className="col-md-12 px-0 around-mob"><img src={require('./img/remote.png')} className="img-fluid"/></div>
          <div className="col-md-12 px-0 around-mob1"><p className="around-text font-size-mob-in">Remote <br/>Monitoring</p></div>
        </div>
        </div>
        <div className="col-md-3 text-center wow fadeInUp wid-50-pers" data-wow-delay="0.4s">
          <div className="row">
          <div className="col-md-12 px-0 around-mob"><img src={require('./img/instant.png')} className="img-fluid"/></div>
          <div className="col-md-12 px-0 around-mob1"><p className="around-text font-size-mob-in">Instant Alerts and <br/>Notifications</p></div>
        </div>
        </div>
        <div className="col-md-3 text-center wow fadeInUp wid-50-pers" data-wow-delay="0.5s">
          <div className="row">
          <div className="col-md-12 px-0 around-mob"><img src={require('./img/ready.png')} className="img-fluid"/></div>
          <div className="col-md-12 px-0 around-mob1"><p className="around-text font-size-mob-in">Ready Out of <br/>The Box</p></div></div>
        </div>
      </div>
    </div>
  </div>
</section>        
                

                <section>
                    <div className="col-md-12 px-0 pt-120 pb-5">
                        <div className="container">

                        <div className="row align-items-center wow fadeInUp" data-wow-delay="0.2s">

                        <div className="col-md-6 center-whole">
                        <h3 className="slide-title-info mb-5 mb-md-0 wht-head-i">What we are<br/> all about</h3>
                        </div>
                        </div>
                        <div className="row award-list">

                        <div className="col-lg-4 center-whole pr-0 wrapper-4 rt-lt-gr-box wow fadeInUp" data-wow-delay="0.4s">
                        <a href="/safety" style={{textDecoration:'none'}}>
                        <div className="col-md-12 pr-0 py-2 bg-round-secur-lt pl-4 pr-3 bord-10-rad">
                        <div className="award-item bg-safe-one py-0">

                        <div className="award-item-logo text-center pt-3"><img src={require('./img/Safety.svg')} width="110" alt="" /></div>
                        <div className="award-item-title slide-title-sub font-weig-top text-center">Safety</div>
                        <div className="award-item-descr slide-descr text-center">Our Safety system protects your home by keeping an eye on potential threats and hazards.</div>
                        <div className="award-item-more pb-3 text-center"><a href="/safety" className="text-success" >Learn More <i className="lni lni-chevron-right"></i></a></div>
                        </div>
                        </div>
                        </a>
                        </div>
                        
                        <div className="col-lg-4 center-whole pl-0 pr-0 blue-box-lt-pad-box wow fadeInUp" data-wow-delay="0.6s">
                        <a href="/security" style={{textDecoration:'none'}}>
                        <div className="col-lg-12 secu-back-bg">
                        <div className="award-item secu-back-bg">

                        <div className="award-item-logo text-center"><img src={require('./img/Sec-grey.svg')} width="110" alt="" /></div>
                        <div className="award-item-title slide-title-sub font-weig-top intex-white text-center">Security</div>
                        <div className="award-item-descr slide-descr intex-white text-center">Our Security system looks after you and your home by detecting, alerting and deterring burglaries.</div>
                        <div className="award-item-more text-center"><a href="/security" style={{color: '#FFF'}}>Learn More <i className="lni lni-chevron-right"></i></a></div>
                        </div>
                        </div>
                        </a>
                        </div>

                        <div className="col-lg-4 center-whole pl-0 wrapper-4 rt-lt-gr-box wow fadeInUp" data-wow-delay="0.8s">
                        <a href="/wellness" style={{textDecoration:'none'}}>
                        <div className="col-md-12 pr-0 py-2 bg-round-secur-rt pl-0 bord-10-rad">
                        <div className="award-item bg-safe-one py-0">

                        <div className="award-item-logo text-center pt-3 mb-2"><img src={require('./img/Wellness.svg')} width="110" alt="" /></div>
                        <div className="award-item-title slide-title-sub font-weig-top text-center">Wellness</div>
                        <div className="award-item-descr slide-descr text-center">Our Wellness system cares for you and your environment to provide total peace of mind.</div>
                        <div className="award-item-more pb-3 text-center"><a href="/wellness" className="text-success" >Learn More <i className="lni lni-chevron-right"></i></a></div>
                        </div>
                        </div>
                        </a>
                        </div>

                        {/* <div className="row animate-element delay5 fadeInUp sh-img" style={{marginRight: '0px', marginLeft: '0px', marginBottom: '120px', paddingTop: '50px', width: '100%'}}>
                        <div className="col-md-12">


                        <iframe width="100%" height="200" src="https://www.youtube.com/embed/haZmgvTF39M" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  allowFullScreen></iframe>
                        </div>
                        </div> */}
                        </div>
                        </div>
                    </div>
                </section>
                <section className="tp-menu-show">
  <div className="col-md-12 dark-bg-back py-5">
    <div className="container">
      <div className="row">
        <div className="col-md-4 text-center wow fadeInLeft" data-wow-delay="0.2s">
          <img src={require('./img/c1.png')} />
          
        </div>
        <div className="col-md-4 text-center wow fadeInUp" data-wow-delay="0.4s">
          <img src={require('./img/c2.png')} />
         
        </div>
        <div className="col-md-4 text-center wow fadeInRight" data-wow-delay="0.6s">
          <img src={require('./img/c3.png')} />      
      </div>
    </div>
  </div></div><div id="choose"></div>
</section>   

<section id="choose-mob">
  <div className="container three-row-pdd">
    <div className="col-md-12 center-whole pb-5 wht-top-padin">
          <h3 className="slide-title-info mb-5 mb-md-0 wht-head-i">Choose your<br/>
            package</h3>
            {(spinloading) ? <div className='loader-container'><div className='loader'></div></div> : ""}
        </div>
    <div className="row">
    {ads.map((s,i) => (
      <div className="col-md-4 mb-5 wow fadeInUp" data-wow-delay="0.2s">
        <div className="col-md-12 pb-5 wr-round-border text-mid-cen px-0">
          <div className="col-md-12 px-0 text-center pb-4 tp-menu-show"><img src={`${API}/ads/image/${s._id}`} className="img-fluid border-lef-right-radiius"/></div>
          <div className="col-md-12">
              <div className="col-md-12 safely-pad-top-gyap">
              {(s.category_name.length>20)?
              <p className="secu-sta" style={{lineHeight:'1.5'}}>{s.category_name}</p>:
             <p className="secu-sta" style={{lineHeight:'1.5'}}>{s.category_name}<br/>&nbsp;</p>}
              </div>
            
         
          <div className="col-md-12 px-0 text-center pb-4 sh-img"><img src={`${API}/ads/image/${s._id}`} className="img-fluid"/></div>
          <div className="col-md-12 mb-3">
            <p className="prod-inc">Products included</p>
            <div className="col-md-12">
           
              {checkProducts(s._id)}
                {/* <div className="col-md-6 px-0 pb-2">Gateway</div>
                <div className="col-md-6 px-0 pb-2">Keypad</div>
                <div className="col-md-6 px-0 pb-2">Indoor Siren</div>
                <div className="col-md-6 px-0 pb-2">Contact Sensor</div>
                <div className="col-md-6 px-0 pb-2">Motion Sensor</div>
                <div className="col-md-6 px-0 pb-2"></div>
                <div className="col-md-6 px-0">RFID Key Card</div>
                <div className="col-md-12 px-0 pb-2"></div>
                <div className="col-md-12 px-0 pb-2"></div>
                <div className="col-md-12 px-0 pb-2"></div>
                <div className="col-md-12 px-0 pb-2"></div> */}



             
            </div>
          </div>
          <div className="col-md-12 pt-4">
            <div className="row">
            <div className="col-md-6 price-fon pt-2 wid-50-pers">
                
                <div className="row">
              <div className="col-md-6 wid-50-pers line-trg">
                  <span className="font-min">£</span>{GetPrice(s._id)}
                  </div>
              <div className="col-md-6 wid-50-pers red-txt"> <span className="font-min">£</span>{s.package_salePrice} </div>
              <div className="clearfix"></div>
              </div></div>
              <div className="col-md-6 wid-50-pers"><a className="btn btn-success text-center mt-1" onClick={() => setAdToken(s._id,s.category_name,s.category_description,s.package_salePrice)} style={{fontSize: '0.8rem'}}>Buy Now</a>
              
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
      </div>
 ))}
      </div>
  </div>
</section>

<section>
  <div className="col-md-12 dark-bg-back py-5">
    <div className="container py-5">
      <div className="row">
        <div className="col-md-3 mar-bt-in wow fadeInUp" data-wow-delay="0.2s">
          <div className="row">
            <div className="col-md-4 wid-25lt text-right pr-0"><img src={require('./img/1-des.png')} width="40px"/></div>
            <div className="col-md-8 wid-75lt design-text-top pt-2">
              <p className="mb-0 des-in-text">Design</p>
              <p className="around-text">Design your perfect
system</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 mar-bt-in wow fadeInUp" data-wow-delay="0.4s">
          <div className="row">
            <div className="col-md-4 wid-25lt text-right pr-0"><img src={require('./img/2-des.png')} width="40px"/></div>
            <div className="col-md-8 wid-75lt design-text-top pt-2">
              <p className="mb-0 des-in-text">Personalise</p>
              <p className="around-text">Customise to fit your
home type and needs</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 mar-bt-in wow fadeInUp" data-wow-delay="0.6s">
          <div className="row">
            <div className="col-md-4 wid-25lt text-right pr-0"><img src={require('./img/3-des.png')} width="40px"/></div>
            <div className="col-md-8 wid-75lt design-text-top pt-2">
              <p className="mb-0 des-in-text">Pre-configure</p>
              <p className="around-text">We pre-configure and
ship your system</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 mar-bt-in wow fadeInUp" data-wow-delay="0.8s">
          <div className="row">
            <div className="col-md-4 wid-25lt text-right pr-0"><img src={require('./img/4-des.png')} width="40px"/></div>
            <div className="col-md-8 wid-75lt design-text-top pt-2">
              <p className="mb-0 des-in-text">Installation</p>
              <p className="around-text">You install in less than
10 minutes</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
      
<section class="back-grey-intr banner-top-gyap-bg">
  <div class="container">
    <div class="row">
      <div class="col-md-6 pt-5 pb-5 center-whole">
        <h3 class="slide-title-info mb-5 mb-md-0 wht-head-i">What makes us
            unique</h3>
        <h3 class="slide-title1 text-white pt-5">{(homes.length >=1 && homes[0].unique_heading1) && homes[0].unique_heading1}</h3>

          <div class="slide-descr slide-descr-projects para-text">{(homes.length >=1 && homes[0].unique_content1) && homes[0].unique_content1}</div>
      </div>

      <div class="col-md-6 px-0 appappan-top">
      {(homes.length >=1 && homes[0].unique_image1) && <img src={"/uploads/"+homes[0].unique_image1} class="img-fluid"/>} 
      </div>
    </div>
  </div>
</section>
               
<section class="banner-top-gyap-bg banner-bottom-gyap-bg">
  <div class="container">
    <div class="row">
      <div class="col-md-6 pt-5 pb-5 center-whole">
        
        <h3 class="slide-title1 text-white pt-5">{(homes.length >=1 && homes[0].unique_heading2) && homes[0].unique_heading2}</h3>

          <div class="slide-descr slide-descr-projects para-text">{(homes.length >=1 && homes[0].unique_content2) && homes[0].unique_content2}</div>
      </div>

      <div class="col-md-6 px-0">
        {(homes.length >=1 && homes[0].unique_image2) && <img src={"/uploads/"+homes[0].unique_image2} class="img-fluid"/>} 
      </div>
    </div>
  </div>
</section>

<section class="back-grey-intr banner-top-gyap-bg banner-bottom-gyap-bg">
  <div class="container">
    <div class="row">
      <div class="col-md-6 pt-5 pb-5 center-whole">
        
        <h3 class="slide-title1 text-white pt-5">{(homes.length >=1 && homes[0].unique_heading3) && homes[0].unique_heading3}​</h3>

          <div class="slide-descr slide-descr-projects para-text">{(homes.length >=1 && homes[0].unique_content3) && homes[0].unique_content3}</div>
      </div>

      <div class="col-md-6 px-0">
      {(homes.length >=1 && homes[0].unique_image3) && <img src={"/uploads/"+homes[0].unique_image3} class="img-fluid"/>} 
      </div>
    </div>
  </div>
</section>      
                

                <section>
                    <div className="col-md-12 px-0" style={{backgroundColor: '#212121'}}>
                        <div className="slide-container pb-120-btm the-most-big">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 px-0 mx-auto center-whole">
                                        <h3 className="slide-title-info the-fast wow fadeInDown pt-fast-mob" data-wow-delay="0.2s">The most seamless smart home<br/>
                                        security offering on the market</h3>
                                    </div>
                                    <div className="col-md-12 pt-5">
                                        <div className="row">
                                            <div className="col-md-3 col-sm-6 col-xs-6 mb-2 wow fadeInUp fast-wid-inline" data-wow-delay="0.2s">
                                            <div className="col-md-12 px-0 mb-2">
                                                <img src={require('./img/wireless-icon.webp')} width="30px"/>
                                            </div>
                                            <div className="col-md-12 px-0 mb-2 wireless-head">
                                                Wireless
                                            </div>
                                            <div className="col-md-12 px-0 no-cab-text">
                                                No Cables<br/> Required
                                            </div>
                                            </div>
                                            <div className="col-md-3 col-sm-6 col-xs-6 fast-wid-inline mb-2 wow fadeInUp" data-wow-delay="0.3s">
                                            <div className="col-md-12 px-0 mb-2">
                                                <img src={require('./img/peel-stick.png')} width="30px"/>
                                            </div>
                                            <div className="col-md-12 px-0 mb-2 wireless-head">
                                                Peel and Stick
                                            </div>
                                            <div className="col-md-12 px-0 no-cab-text">
                                                No Tools<br/> Required
                                            </div>
                                            </div>
                                            <div className="col-md-3 col-sm-6 col-xs-6 fast-wid-inline1 mb-2 wow fadeInUp" data-wow-delay="0.4s">
                                            <div className="col-md-12 px-0 mb-2">
                                                <img src={require('./img/bespoke-needs.png')} width="30px" />
                                            </div>
                                            <div className="col-md-12 px-0 mb-2 wireless-head">
                                                Bespoke to your needs
                                            </div>
                                            <div className="col-md-12 px-0 no-cab-text">
                                                Install in <br/>10 minutes
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="back-grey-intr banner-top-gyap-bg banner-bottom-gyap-bg">
  <div class="container">
    <div class="row">
      <div class="col-md-6 pt-5 pb-5 center-whole">
        <h3 class="slide-title-info mb-5 mb-md-0 wht-head-i">How we
change lives</h3>
        <h3 class="slide-title1 text-white pt-5">{(homes.length >=1 && homes[0].changeLives_heading1) && homes[0].changeLives_heading1}</h3>

          <div class="slide-descr slide-descr-projects para-text">{(homes.length >=1 && homes[0].changeLives_content1) && homes[0].changeLives_content1}</div>
      </div>

      <div class="col-md-6 px-0">
      {(homes.length >=1 && homes[0].changeLives_image1) && <img src={"/uploads/"+homes[0].changeLives_image1} class="img-fluid"/>} 
      </div>
    </div>
  </div>
</section>
<section class="banner-top-gyap-bg banner-bottom-gyap-bg">
  <div class="container">
    <div class="row">
      <div class="col-md-6 pt-5 pb-5 center-whole">
        
        <h3 class="slide-title1 text-white pt-5">{(homes.length >=1 && homes[0].changeLives_heading2) && homes[0].changeLives_heading2}​</h3>

          <div class="slide-descr slide-descr-projects para-text">{(homes.length >=1 && homes[0].changeLives_content2) && homes[0].changeLives_content2}</div>
      </div>

      <div class="col-md-6 px-0">
      {(homes.length >=1 && homes[0].changeLives_image2) && <img src={"/uploads/"+homes[0].changeLives_image2} class="img-fluid"/>} 
      </div>
    </div>
  </div>
</section>
              
<section class="back-grey-intr banner-top-gyap-bg banner-bottom-gyap-bg">
  <div class="container">
    <div class="row">
      <div class="col-md-6 pt-5 pb-5 center-whole">
        
        <h3 class="slide-title1 text-white pt-5">{(homes.length >=1 && homes[0].changeLives_heading3) && homes[0].changeLives_heading3}</h3>

          <div class="slide-descr slide-descr-projects para-text">{(homes.length >=1 && homes[0].changeLives_content3) && homes[0].changeLives_content3}</div>
      </div>

      <div class="col-md-6 px-0">
      {(homes.length >=1 && homes[0].changeLives_image3) && <img src={"/uploads/"+homes[0].changeLives_image3} class="img-fluid"/>} 
      </div>
    </div>
  </div>
</section>

             
                <section>
                    <div className="col-md-12 px-0" style={{backgroundColor: 'rgb(33, 33, 33)', zIndex: '4', transform: 'translate3d(0px, 0px, 0px)'}}>
                        <div className="slide-container">
                            <div className="container">
                                <div className="col-md-12 text-center mb-4" style={{paddingTop: '5rem'}}>
                                    <h4 className="g-color wow fadeInDown" data-wow-delay="0.1s">Let us build your bespoke system - Step 1</h4>
                                    <h2 className="whi-color mb-mb1 wow fadeInDown" data-wow-delay="0.2s">What are your priorities for your home?</h2>
                                    <h4 className="appli-colo-hm tp-menu-show font-wei wow fadeInDown" data-wow-delay="0.3s">Please select multiple options, if applicable</h4>
                                    <h4 className="g-color font-wei sh-img wow fadeInDown" data-wow-delay="0.1s">Single tap on the section(s) to select or deselect</h4>
                                </div>
                                <div className="row award-list">
                                    <div className="col-md-10 mx-auto">
                                        <div className="row mb-4 wow fadeInUp" data-wow-delay="0.2s">
                                            <div className="col-md-1 high-fix">
                                                <input className="styled-checkbox" style={{opacity: 'unset',width:'20px',height:'20px',borderRadius:'3px'}} checked={safety} id="styled-checkbox-1" onChange={handleChange('safety')} type="checkbox" value={safety} />                                             
                                            </div>
                                            <label htmlFor="styled-checkbox-1" className="col-md-11">
                                            <div className={safetyclass}>
                                                <div className="row">
                                                    <div className="col-md-2 col-sm-2 col-xs-2 safe-ico-pad wid-30-per" style={{paddingTop: '27px'}}>
                                                        <a><img id="Safely" src={require('./img/Safety.svg')} width="50" alt="" /></a>
                                                    </div>
                                                    <div className="col-md-10 col-sm-10 col-xs-10 pl-022 wid-70-per py-1-grd-boc">
                                                        <p className="mb-0 ur-home-hed pt-2">Safety</p>
                                                        <ul className="pl-4 pt-2">

                                                            <li className="ur-home-li">Detects and alerts you of Fires, Smoke, Water leaks and Panic alerts.</li>
                                                            <li className="ur-home-li">Integrated with our Safely Connect app for smart home connectivity.</li>
                                                            <li className="ur-home-li">Calls and texts you in the event of a fire, flood, or panic alert.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            </label>
                                        </div>
                                        <div className="row mb-4 wow fadeInUp" data-wow-delay="0.4s">
                                            <div className="col-md-1 high-fix">
                                                <input className="styled-checkbox" style={{opacity: 'unset',width:'20px',height:'20px',borderRadius:'3px'}} checked={security} onChange={handleChange('security')} id="styled-checkbox-2" type="checkbox" value={security} />
                                                
                                            </div>
                                            <label htmlFor="styled-checkbox-2" className="col-md-11">
                                            <div className={securityclass} >
                                                <div className="row">
                                                    <div className="col-md-2 col-sm-2 col-xs-2 safe-ico-pad wid-30-per" style={{paddingTop: '27px'}}>
                                                        <a><img id="Safely" src={require('./img/Security.svg')} width="50" alt="" /></a>
                                                    </div>
                                                    <div className="col-md-10 col-sm-10 col-xs-10 pl-022 wid-70-per py-1-grd-boc">
                                                        <p className="mb-0 ur-home-hed pt-2">Security</p>
                                                        <ul className="pl-4 pt-2">

                                                            <li className="ur-home-li">Helps prevent and alerts you to burglaries.</li>
                                                            <li className="ur-home-li">Professionally monitored accredited to a NSI gold standard.</li>
                                                            <li className="ur-home-li">Arm, disarm and monitor your system with our Safely Connect App. </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            </label>
                                        </div>
                                        <div className="row mb-4 wow fadeInUp" data-wow-delay="0.6s">
                                            <div className="col-md-1 high-fix">
                                                <input className="styled-checkbox" style={{opacity: 'unset',width:'20px',height:'20px',borderRadius:'3px'}} checked={wellness} onChange={handleChange('wellness')} id="styled-checkbox-3" type="checkbox" value={wellness} />
                                             
                                            </div>
                                            <label htmlFor="styled-checkbox-3" className="col-md-11">
                                            <div className={wellnessclass}>
                                                <div className="row">
                                                    <div className="col-md-2 col-sm-2 col-xs-2 safe-ico-pad wid-30-per" style={{paddingTop: '27px'}}>
                                                        <a><img id="Wellness" src={require('./img/Wellness.svg')} width="50" alt="" /></a>
                                                    </div>
                                                    <div className="col-md-10 col-sm-10 col-xs-10 pl-022 wid-70-per py-1-grd-boc">
                                                        <p className="mb-0 ur-home-hed pt-2">Wellness</p>
                                                        <ul className="pl-4 pt-2">

                                                            <li className="ur-home-li">Air quality, Temperature and Humidity monitoring.</li>
                                                            <li className="ur-home-li">Monitor activity and Panic alerts with instant app notifications.</li>
                                                            <li className="ur-home-li">Professional monitoring team to call or text a family member in an event of a panic alert.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            </label>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-sm-12 btm-conti-rt pb-30-con wow fadeInUp continue-margin" data-wow-delay="0.2s">
                                <button onClick={clickSubmit} className="btn btn-primary mr-2 mb-3" type="submit">Continue</button>
                                {showSuccess()}
                                {showError()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section>
                    <div className="col-md-12 px-0">
                        <div className="slide-container">
                            <div className="slide-bg">
                                <div className="slide-photo slide-app-bg wow fadeInUp" data-wow-delay="0.4s"></div>
                            </div>
                            <div className="container center-whole">
                                <div className="wow fadeInUp" data-wow-delay="0.2s">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-7 col-lg-12">
                                            <div className="slide-title-sub slide-title-sub-md light-gr">
                                            <h3 className="slide-title-info mb-5 mb-md-0 wht-head-i">Safely <br/> Connect App</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-7 col-lg-12">
                                            <h3 className="slide-title1 text-white" >Your Home <br/>at your Fingertips</h3>
                                            <div className="row">
                                                <div className="col-lg-8 tp-menu-show">
                                                    <div className="slide-descr slide-descr-projects para-text">Control your smart home security system from anywhere in the <br/>world with the Safely Connect App. Monitor temperature, check <br/>for open doors and optimise your smart home with the touch of<br/> a button.</div>
                                                </div>
                                                <div className="col-lg-8 sh-img">
                                                    <div className="slide-descr slide-descr-projects para-text">Control your smart home security system from anywhere in the world with the Safely Connect App. Monitor temperature, check for open doors and optimise your smart home with the touch of a button.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2 mb-3 pl-0">
                                            <a className="pl-3 mb-3 tobo-cent" href="https://play.google.com/store/apps/details?id=com.safelyteam.safely" target="_blank"><img src={require('./img/gplay.png')} className="img-fluid" alt="" /></a>
                                        </div>
                                        <div className="col-md-4 pl-0">
                                            <a className="pl-3" href="https://apps.apple.com/gb/app/safely-connect/id1525530018" target="_blank"><img src={require('./img/app-store.png')} className="img-fluid" alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 sh-img pt-gyap animate-element delay5 fadeInUp"><img src={require('./img/app-mob.webp')} className="img-fluid" alt="" /></div>
                    </div>
                </section>

                <section className="sub-bg-two banner-top-gyap-bg">
  <div className="container">
    <div className="row">
      <div className="col-md-6 pt-3 center-whole">
        
        <h3 className="title-mini">Stay Connected</h3>
        <h3 className="slide-title1 text-white pt-3 font-suc-btm">Subscribe to our latest<br/>
info on products, tips</h3>

          <div className="slide-descr slide-descr-projects para-text" style={{width: '75%'}}>
          <form className="contact-personal-form a-ajax-form">
                            <div className="input-group mb-3">
                                <input type="text" onChange={handleChange1} value={email} className="form-control input-form-bg pl-0" placeholder="Enter your email Here" id="demo" name="email" />
                                <div className="input-group-append">
                                    <span onClick={clickSubmit1} className="input-group-text exa-bg px-0"><a href="#"><img src={require('./img/icon-arr.png')} width="30" style={{marginLeft: '-5px'}} alt="" /></a></span>
                                </div>
                                {showError1()}
                                {showSuccess1()}
                                </div>

                            <div className="message text-success success-message">Your message is successfully sent...</div>
                            <div className="message text-danger error-message">Sorry something went wrong</div>

                            </form>
                      </div>
      </div>

      <div className="col-md-6 px-0 pt-3 pl-5 center-whole">
         <h3 className="title-mini late-buzz">Read our latest articles</h3>

          <p className="pt-3" style={{fontSize: '1.2rem', lineHeight: '1.5'}}>For helpful tips and insights to fully optimise your Safely system, <a href="https://safelyteam.zendesk.com/hc/en-gb" target="_blank" style={{color: '#00a6e6'}}>visit our help articles</a>  </p>
                    
                    </div>
      </div>
    </div>
  
  <div className="row">
    <div className="col-md-6 tp-menu-show">
      <img src={require('./img/left-img-new.webp')} width="300"/>
    </div>
    <div className="col-md-6 text-right">
      <img src={require('./img/xmas.png')} width="500"/>
    </div>
  </div>
</section>
                

    <Footer />
                             
           
                <div className="col-md-12 sh-img" style={{backgroundColor:'#212121', position:'fixed', bottom: '0px',zIndex: '999'}} >      
                    <div className="row">
                    <div className="col-xs-8 py-4 pl-3 text-center" style={{width: '100%'}}>
                    <a href="https://www.facebook.com/Safelyteam/" target="_blank" className="mr-1 mb-4"><img src={require('./img/fb.png')} width="20"/></a>
                                <a href="https://twitter.com/safelyteam?s=08" target="_blank" className="mr-1 mb-4"><img src={require('./img/tw.png')} width="20"/></a>
                                <a href="https://www.instagram.com/safelyteam/" target="_blank" className="mr-1 mb-4"><img src={require('./img/in.png')} width="20"/></a>
                                <a href="https://www.linkedin.com/company/safely-team/" target="_blank" className="mr-1 mb-4"><img src={require('./img/lin.png')} width="20"/></a>
                                <a href="https://www.youtube.com/channel/UCoWYMWip0_tOT23Q2rRxEeQ" target="_blank" className="mr-1"><img src={require('./img/yb.png')} width="20"/></a>
                    
                    </div>
                    
                    <div className="col-md-12 text-center">
                    <h4 className="ftr-prod1" style={{textAlign: 'center'}}>© 2021 Safely Ltd.</h4>
                    </div>
                    </div>
                </div>
           


            <div className="modal fade" id="send-request">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <i className="lni lni-close"></i>
                            </button>
                        </div>
                        <div className="modal-body p-3">
                            <iframe width="100%" className="yvideo" name="yvideo" height="400" src="https://www.youtube.com/embed/haZmgvTF39M?version=3&enablejsapi=1" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default HomeNew
