import React from 'react'
import { isAuthenticated } from '../../../auth/index'
import CheckoutCall from './CheckoutCall'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {STRIPE_PUBLIC, STRIPE_PUBLIC_1,TESTING_USER  } from "../../../config";

const Checkout = () => {
    let stripePromise = loadStripe(STRIPE_PUBLIC);
    const { user } = isAuthenticated();

    if(isAuthenticated()){

        if(user._id === TESTING_USER){
            stripePromise = loadStripe(STRIPE_PUBLIC_1);
            console.log("testing");
        }
        else {
            console.log("Safely");
        }
    }
    return (
        <Elements stripe={stripePromise}>
            <CheckoutCall />
        </Elements>
    )
}

export default Checkout
