import React, {useState, useEffect, Fragment} from 'react'
import { getProductList,getAdsProducts } from './apiUser'
import Footer from '../Footer'
import Subscribe from '../Subscribe'
import {OverlayTrigger,Tooltip} from 'react-bootstrap';
import {Helmet} from 'react-helmet'
import { addItemCount,getCart,updateItem, removeItem1  } from './cartHelpers'
import ShowImage from '../../../admin/ShowImage'
import {API} from '../../../config'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const AdStep = () => {

    const classes = useStyles();

    const [loadingBack, setLoadingBack] = useState(true);

    const [items, setItems] = useState([])
    const [count, setCount] = useState(1);
    const [selprodid, setSelProdId] = useState(1);
    const [products, setProducts] = useState([]);   
    const [btnloading, setLoading] = useState(true) 
    const [btnloading1, setLoading1] = useState(true)
    const [hidecount, setHideCount] = useState(false);
    const [adsProducts, setAdsProducts] = useState([]);

   const loadProducts = () => {
        getProductList().then(data => {
            if(data.error) {
                console.log(data.error);
                setLoading1(false);
                setLoadingBack(false);
            } else {
                setProducts(data);
                setLoading1(false);
                setLoadingBack(false);
            }
        });
    };

    const loadAdsProducts = (Id) => {
        getAdsProducts({Id:Id}).then(data => {
            if (data.error) {
                console.log(data.error);
                setLoading(false);
                setLoadingBack(false);
            } else {
                setAdsProducts(data);
                setLoading(false);
                console.log(data);
            
            }
        });
    };

    const [tempName, setTempName] = useState("")

    const [salePrice, setSalePrice] = useState(0);

    const [prodDescription, setProdDescription] = useState("");

    const [adID, setAdId] = useState("");

    const checkLocal = () => {
       
        const adsData = JSON.parse(localStorage.getItem('ads'));
        if(localStorage.getItem('ques')) {
               localStorage.removeItem("ques");
        }  

        if(localStorage.getItem('package')) {
            localStorage.removeItem("package");
        } 

        if(localStorage.getItem('packageQnty')) {
            localStorage.removeItem("packageQnty");
        } 

        if(localStorage.getItem('ads')){
            loadAdsProducts(adsData.adID);
            setTempName(adsData.name)
            setSalePrice(adsData.salePrice); 
            setProdDescription(adsData.description)
            setAdId(adsData.adID);
        }
        else 
        { 
            window.location.href="/step1";
        }
            
    }

    const [plans, setPlans] = useState({
        type1: '',
        type2: '',
        subscription_type:-1,
        error:'',
        packageprice:0,
        singlepackageprice:0,
        packagecount:1,
        type1class:'col-md-12 bord-line',
        type2class:'col-md-12 bord-line',      
        success: false
    })
    const {type1,type2, error,packageprice,singlepackageprice,packagecount, success,type1class,type2class} = plans;
    
    
    useEffect(() => {         
        checkLocal();    
        loadProducts();   
        const packData1 = JSON.parse(localStorage.getItem('adpackage'));
        if(localStorage.getItem('adpackage')){
            setPlans({packageprice:[packData1.adpackageTotprice],singlepackageprice:[packData1.adpackageTotprice],packagecount:1})
        } 
      
    }, []);  

   useEffect(() => {  
    setItems(getCart());
      
}, [count]);   
useEffect(() => {
       
}, [items])
useEffect(() => {  
    setItems(getCart());
    
}, [selprodid]);  

        

    //Package Add to cart
    const clickSubmit = (event) => {
        event.preventDefault();
        let packageproducts="";
        let packageprice="";
        let packagetempPrice="";
        let packageproductid="";
        let packageQuantity="";
        let packagetempQuantity="";
        let packageName="";
        adsProducts.map(p => {
          let qty=1;
          packageproducts+=p.productId.name+",";
          packageprice+=p.prod_price+",";
          packagetempPrice+=p.prod_price+",";
          packageproductid+=p.productId._id+",";
          packageQuantity+=qty+",";
          packagetempQuantity+=qty+",";
          packageName+=qty+" x "+p.productId.name+"<br/>";
        });
        //Set Package Title
        let PackageHead= tempName;   
        localStorage.setItem('adpackage', JSON.stringify({ adpackageproducts:[packageproducts.trim(',')],adpackagetempName:packageproducts.trim(','),adpackageprice:[packageprice.trim(',')],adpackageproductid:packageproductid.trim(','),adpackageQuantity:[packageQuantity.trim(',')],adpackagetempPrice:packagetempPrice.trim(','), adpackagetempQuantity:packagetempQuantity.trim(','),adpackageTotprice:[salePrice],adpackageName:[packageName],adpackageHead:[PackageHead] })) 
        localStorage.setItem('adpackageQnty', JSON.stringify({val:1})); 
        window.location.href="/revieworder" 
    }

    //Package items formed

    const safelyCustomizedProd = () => {
        return (
            adsProducts.map((s,i) => (
                <div className="col-md-6" key={i}>
                    <span>1 x {s.productId.name}</span>

                    <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }}  overlay={ 
                        <Tooltip id="button-tooltip">
                            <p>{s.productId.description}   </p>  <h4>Highlights:</h4>       <p dangerouslySetInnerHTML={{__html: s.productId.highlights}}></p>
                        </Tooltip>}>
                            <i className="fa fa-info-circle blue-acti" aria-hidden="true" style={{float:'none',marginLeft:'5px'}}></i>
                    </OverlayTrigger>  

                </div>

            ))
        )
    }

    const packDetails = () => {
            
        return (     
            <tr style={{border:'none'}}> 
                <td colSpan="2" style={{border:'none',width:'69%',padding:'5px',paddingTop: '20px'}}>
                <b>{tempName}  </b>             
                </td>               
                <td style={{border:'none',width:'14%',padding:'5px',textAlign:'right'}}>                  
                    £ {salePrice}
                </td>      

        </tr>
        )
    };

    const getCartTotal = () => {
        return items.reduce((currentValue, nextValue) => {
            return currentValue + nextValue.count * nextValue.sale_price;
        }, 0);
    };

    const prodDetails = () => {
        return (       
                items.map((product, i) => (                           
                <tr style={{border:'none'}} key={i}>                                  
                   <td style={{border:'none',width:'10%',padding:'5px'}}>
                        <input type="number" style={{padding:'0px',paddingLeft: '10px'}} onChange={updatehandleChange(product._id)}  value={product.count} className="form-control" id="usr" />
                        </td>
                        <td style={{border:'none',width:'69%',padding:'5px'}}>{product.name}</td>                        
                            <td style={{border:'none',width:'14%',padding:'5px',textAlign:'right'}}> 
                        £ {product.count  * product.sale_price}
                        </td>                 
                </tr>          
                ))
        )
    } 

    const updatehandleChange = productId => event => {
        if(event.target.value == '0')
        {                     
            setCount(0);
            removeItem1(productId);
                   
        }  
        else{
            let tempQuan;
            items.map((p,i) => (
                 productId === p._id && (tempQuan = p.quan)
            ))
               
            setCount(event.target.value < 1 ? 1 : event.target.value)
            if(event.target.value >=1 && event.target.value <=tempQuan) {
                updateItem(productId, event.target.value)      
            }
        }
    }

    const handleChange = (e,product) =>{ 
               
        if(e.target.value == '0')
        {
            setHideCount(true); 
            setSelProdId(product._id);  
            setCount(0);
            removeItem1(product._id);
                       
        }  
        else{
            setHideCount(true); 
            setSelProdId(product._id);         
            let tempQuan=product.quan;  
            setCount(e.target.value < 1 ? 1 : e.target.value)
            if(e.target.value >=1 && e.target.value <=tempQuan) {
                removeItem1(product._id);  
                addItemCount(product, e.target.value);                 
                //updateItem(product._id, e.target.value)                   
            }   
        }  
                     
    };

    const showAddToCart = (product,items) => {     
        const itemsquant = [];
        let selval='0';
        for (var i=0;i<=product.quan;i++) {
            itemsquant.push(<option key={i}>{i}</option>)
        }
        items.map((p, i) => {
            if(p._id === product._id) 
                { selval=p.count;}
        });                
        return ( 
                <div>                       
                    <div className="pb-2">                           
                      <select id="sel1" name="sellist1" defaultValue={selval} onChange={e => handleChange(e,product)}>
                        {itemsquant}           
                       </select> 
                    </div>  
                    {(hidecount && product._id===selprodid) ?                   
                    <div className='blue-acti'>Cart updated.</div> :""}
               </div>
         )  
    };
         
    return (      
        <Fragment style={{overflowY: 'scroll', overflowX: 'hidden'}}>
            <Helmet>
            <title>Bespoke Home Security System | Best Accidental Fire & Water Leak Pervention System | SafelyTeam​</title>
            <meta name="description" content="What are your priorities for your home security? Select them and build a bespoke home security system for your unique home requirements. Stay protected 24/7/365." />
          </Helmet>
            

            <div className="section pp-scrollable slide-dark slide-dark-footer">               

                <div className="col-md-12 inner-tp-pad inner-safely">
                    <div className="container ptb-inner top-det-sp-in">
                        <div className="row border-who">
                            <div className="col-md-6 p-3 text-center"><img src={`${API}/ads/image/${adID}`} className="img-fluid" /></div>
                            <div className="col-md-6 pl-5 pt-2">                               
                                <h3 className="font-hd-on-in">{tempName}</h3>
                                <p>{prodDescription}</p>
                                <h3 className="prod-inclu">Products Included</h3>
                                <div className="row">
                            <div className="col-md-8">
                            <div className="included-detail">
                                <div className="row">
                                {safelyCustomizedProd()}
                                {(btnloading) ? <div className='loader-container'><div className='loader'></div></div> : ""}  
                                
                                </div>   
                               
                                </div>
                           </div>
                          
                       </div>         
                                <div className="row pt-3">
                                    <div className="col-md-3 pt-1">
                                        <h3 className="font-hd-on"><span className="included-detail">£</span>{salePrice}</h3>
                                    </div>
                                    <div className="col-md-6"> </div>
                                </div>
                            </div>
                        </div>

                     

                        <div className="row ">
                        <div className="col-md-12">
                           
                            <center>
                            <div className="col-md-12 py-4">
                                <h2 className="prod-inclu blue-acti" style={{fontSize: '22px',lineHeight: '1.5', borderBottom: '1px solid #dfdfdf'}}>Add more Products</h2>
                                {(btnloading1) ? <div className='loader-container'><div className='loader'></div></div> : ""}  
                            </div>
                            {products.map((product, i) => (
                              
                                <div key={i} className="col-md-12 px-0 btm-bo-air pb-2 mb-3">
                                  
                                    <div className="row pro-list-item">
                                    <div className="col-md-2 p-0 add-more-img-pad small-img">             
                                     <ShowImage  item={product._id}/>
                                    </div>
                                    <div className="col-md-6">
                                    <h4 className="font-gen"><a target='blank' href={'/product-details/'+product._id}>{product.name}</a> &nbsp;&nbsp;&nbsp; </h4>
                                    <p>{product.description}</p><br/>
                                    <p><b>Tips: </b>{product.tips}</p>
                
                                </div>
            <div className="col-md-4">              
            <h4 className="font-gen" style={{textAlign:'center',marginBottom:'0px'}}>£ {product.sale_price}</h4>
                                
           <sub><i>Increase as per your requirements</i> </sub>
           <div className="pt-3" >                  
            {showAddToCart(product,items)}               
                   
                </div>
                {/* <sub><i>Try not to stress. It's anything but difficult to add all the more later, or return ones you needn't bother with.</i></sub> */}
            </div>
        </div>





</div>

                                
                            ))}
                          
                          </center>
</div>
<div className="col-md-12 inner-tp-pad inner-safely">
    <div className="container">
    <div className="row">
<div className="col-md-6"></div>
<div className="col-md-6 bg-summa" style={{float:'right'}}>
<table className="table table-bordered table-responsive table-review" style={{border:'none'}}>
                                               
                                               <tbody style={{border:'none'}}>                        
                                                   {packDetails()}      
                                                   {prodDetails()}
                                               </tbody>
                                           </table>
                         </div>
                         </div>
                         </div>
</div>

                        </div>
                    </div>
                </div>

                <div className="col-md-12 inner-tp-pad inner-safely mb-5">
                <div className="container">
                <div className="row">
<div className="col-md-6"></div>
<div className="col-md-6 bg-summa" style={{float:'right'}}>
                                            <div className="row pt-5">
                                                {/* <div className="col-md-6 pb-3 wid-40-per">
                                                    Shipping
                                                </div>
                                                <div className="col-md-6 pb-3 text-right wid-40-per">
                                                    Free
                                                </div> 
                                                <div className="col-md-6 pb-3 wid-40-per">
                                                    Total
                                                </div>
                                                <div className="col-md-6 pb-3 text-right wid-40-per">
                                                    £{getCartTotal(items)+Number(getTotal())}
                                                </div>
                                               */}
                                            </div>
                                          

                                            <div className="row py-3 mb-5 shipping-bor">
                                                <div className="col-md-6 blue-dis wid-40-per">
                                                    Total
                                                </div>
                                                <div className="col-md-6 blue-dis text-right wid-40-per">
                                                    £{(getCartTotal(items) +Number(salePrice)).toFixed(2)}
                                                </div>                               
                                            </div>

                                            <div className="row py-3 mb-5">
                                                <div className="col-md-12">
                                              
                                       
                                                <button onClick={clickSubmit} className="btn btn-success pull-right mb-3" data-toggle="modal" data-target="#send-request">Add to cart</button>                        
                                                </div>                         
                                            </div></div>
                                      </div></div>
                                        </div>
               
            </div>
            <Subscribe /> 
            <Footer />

              
           



            <div className="col-sm-12 pb-30-con animate-element delay2 fadeInUp"
        style={{backgroundColor: '#FFF', position: 'fixed', top: '0px', zIndex: '26'}}>
       <div className="row">
           <div className="col-md-2 go-ba">
           <a href="/">
                                   <img src={require('../img/logo.png')} width="120" alt="" />
                                </a>
              </div>
           <div className="col-md-8 pt-4 text-center">
               <ul className="list-inline text-center mb-0">
                   <li className="list-inline-item top-list-bread">
                       <a href="/step1">
                           <i className="icofont-simple-right"></i> Select your priorities - (Step 1 of 3)
                       </a>
                   </li>
                   <li className="list-inline-item top-list-bread">
                       <a href="/step2" >
                           <i className="icofont-simple-right"></i>Custom details
                           - (Step 2 of 3)
                       </a>
                   </li>
                   <li className="list-inline-item top-list-bread">
                       <a href="/step3" className="blue-acti">
                           <i className="icofont-simple-right"></i>Review Package
                           - (Step 3 of 3)
                       </a>
                   </li>

               </ul>
           </div>
       </div>

   </div>

                    <Backdrop className={classes.backdrop} open={loadingBack} >
                        <CircularProgress color="inherit" />
                    </Backdrop>  
        
            </Fragment>
    )
}

export default AdStep
