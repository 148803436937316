import React, {useState, useEffect} from 'react'
import { Redirect } from 'react-router-dom'
import BarcodeScannerComponent from 'react-webcam-barcode-scanner';
import { updateDeviceId,getOrderBySubOrderId } from './apiAdmin'
import { isAuthenticated } from '../auth/index'

const Barcode = ({match}) => {

    const [data, setData] = useState('Barcode Not found');
    const { user, token } = isAuthenticated();
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [order,setOrder] = useState({});

    const handleChange = (e) => {
        setError('')
        /* setData(e.target.value) */
    };
    
    const clickSubmit = (e) => {
        e.preventDefault();
        setError('');
        setSuccess(false);
        updateDeviceId(user._id, token, match.params.suborderId, data)
        .then(data => {
            if(data.error) {
                setError(true);
            } else {
                setError("");
                setSuccess(true);
            }
        });
    };


    const loadOrders = (suborderId) => {
        getOrderBySubOrderId(suborderId).then(data => {
            if(data.error) {
                console.log(data.error);
            } else {
                setOrder(data.orderid);
            }
        });
    }; 

    useEffect(() => {
        loadOrders(match.params.suborderId);
    },[])
       
    const showSuccess = () => {
        if(success) {
            return <Redirect to={`/admin/order/details/${order}`} />
        } 
    };

    const showError = () => {
        if(error) {
            return <h3 className="text-danger">Some error occurs</h3>
        }
    };
 
    return (
        <div>
            <center>
            {showSuccess()}
            {showError()}
            <BarcodeScannerComponent
                width={500}
                height={500}
                onUpdate={(err, result) => {
                if (result) { 
                    setData(result.text);
                }  
                }}
            />
            <form onSubmit={clickSubmit}>
                <div className = "form-group">
                    <div className="form-group">
                        <input type="text" value={data} onChange={handleChange} />
                    </div>
            </div>
            <button className="btn btn-outline-primary">
                     Add
            </button>
           </form>
            {console.log('Data',data)}
            </center>
        </div>
    )
}

export default Barcode
