import React, {Fragment, useState, useEffect} from 'react'
import { Redirect } from 'react-router-dom'
import Nav from './Nav'
import Footer from '../Footer'
import { getCart,updateItem, removeItem } from './cartHelpers'
import Subscribe from '../Subscribe'
import { isAuthenticated } from '../../../auth/index'
import { getUser ,getOrderByUserID,getSubscriptions, getUserSubscription,getSubscriptionLogs} from './apiUser'
import {Helmet} from 'react-helmet'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from 'react-bootstrap'

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const ReviewOrder = () => {

    const classes = useStyles();

    const [showModal, setModal] = useState(false);

    const [loading, setLoading] = useState(true);

    const [items, setItems] = useState([])
    const [count, setCount] = useState(1);
    const [valMsg, setvalMsg] = useState(false);
    const {user,token} = isAuthenticated();
    const [orders, setOrders] = useState([])
    const [planType, setPlanType] = useState('');
    const [isCancelled, setIsCancelled] = useState(false);
    const [values2, setValues2] = useState({
        gold_price:'',
        silver_price:'',
        bronze_price:'',
        error2:''
    })
    const { gold_price, silver_price, bronze_price, error2} = values2;

    const [planVal, setPlanVal] = useState({
        stype1: '',
        stype2: '',
        stype3: '',
        stype1class:'col-md-12 bord-line',
        stype2class:'col-md-12 bord-line',  
        stype3class:'col-md-12 bord-line'
    })

    const { stype1, stype2, stype3, stype1class, stype2class, stype3class} = planVal

    const [plans, setPlans] = useState({
        type1: '',
        type2: '',
        type3:'',
        subscription_type:-1,
        error:'',
        packageprice:0,
        singlepackageprice:0,
        packagecount:1,
        type1class:'col-md-12 bord-line',
        type2class:'col-md-12 bord-line',      
        type3class:'col-md-12 bord-line',   
        success: false
    })

    const {type1,type2, type3, error,packageprice,singlepackageprice,packagecount, success,type1class,type2class, type3class} = plans;

    useEffect(() => {
        setItems(getCart());
        loadSubscriptions();
        if(isAuthenticated()) {
            loadUserSubscriptions();
            checkPaid(user._id);
            checkOrders();
        }
        else {
            setLoading(false)
        }

        if(localStorage.getItem('checkValues')){
            localStorage.removeItem('checkValues');
            localStorage.removeItem('planValues');
        }

    }, [count])

    useEffect(() => {
       
    }, [items])
    
    useEffect(() => {
        const packData1 = JSON.parse(localStorage.getItem('package'));
        const packData2 = JSON.parse(localStorage.getItem('adpackage'));
        if(localStorage.getItem('package')){
            setPlans({packageprice:[packData1.packageTotprice],singlepackageprice:[packData1.packageTotprice],packagecount:1,type1class:'col-md-12 bord-line',type2class:'col-md-12 bord-line', type3class:'col-md-12 bord-line'})
        }
        else if(localStorage.getItem('adpackage')){
            setPlans({packageprice:[packData2.adpackageTotprice],singlepackageprice:[packData2.adpackageTotprice],packagecount:1,type1class:'col-md-12 bord-line',type2class:'col-md-12 bord-line', type3class:'col-md-12 bord-line'})
        }
    }, []);
    

const loadSubscriptions = () => {
    getSubscriptions().then(data => {
        if(data.error) {
            setValues2({...values2,error2:data.error})
            setLoading(false);
        } else {
            var tempGold=0;
            var tempSilver=0;
            var tempBronze=0;
            for(var i=0;i<data.length;i++){
                if(data[i].plan === "Gold"){
                    tempGold = data[i].price;
                }
                else if(data[i].plan === "Silver"){
                    tempSilver = data[i].price;
                }
                else if(data[i].plan === "Bronze"){
                    tempBronze = data[i].price;
                }
            }
            
            setValues2({
            ...values2,
            gold_price:tempGold,
            silver_price:tempSilver,
            bronze_price:tempBronze,
            error2:''  
            })
            //setLoading(false);
            setTimeout(() => setLoading(false),3000)
           
        }
    }) 
}

const loadUserSubscriptions = () => {
    getSubscriptionLogs(user._id, token,{userId:user._id})
    .then(data => {
        if (data.error) {
           console.log(data.error)
        } else {      
            // populate the state
            if(data.length >= 1){

                setPlanType(data[0].subscriptionType);
                if(data[0].subscriptionType === 0){
                    setPlanVal({...planVal, stype1class:'col-md-12 bord-line true',stype2class:'col-md-12 bord-line', stype3class:'col-md-12 bord-line', stype1:true, stype2:false, stype3:false})
                }
                else if(data[0].subscriptionType === 1){
                    setPlanVal({...planVal, stype2class:'col-md-12 bord-line true',stype1class:'col-md-12 bord-line', stype3class:'col-md-12 bord-line', stype2:true, stype1:false, stype3:false})
                }    
                else if(data[0].subscriptionType === 2){
                    setPlanVal({...planVal, stype3class:'col-md-12 bord-line true',stype1class:'col-md-12 bord-line', stype2class:'col-md-12 bord-line',stype3:true, stype1:false, stype2:false})
                }  
            }
                  
        }
    });
};

    

    const handleChange = name => event => {
        if(name === 'type1') {
            setvalMsg(false)
            setPlans({...plans, type1class:'col-md-12 bord-line true', type2class:'col-md-12 bord-line',  type3class:'col-md-12 bord-line', [name]:0,subscription_type:0,packagecount:1})
        } else if(name === 'type2') {
            setvalMsg(false)
            setPlans({...plans,[name]:1, type2class:'col-md-12 bord-line true', type1class:'col-md-12 bord-line', type3class:'col-md-12 bord-line',subscription_type:1,packagecount:1})
        }else if(name === 'type3') {
            setvalMsg(false)
            setPlans({...plans,[name]:2, type3class:'col-md-12 bord-line true', type1class:'col-md-12 bord-line', type2class:'col-md-12 bord-line', subscription_type:2,packagecount:1})
        } else {
            setPlans({...plans, [name]:event.target.value});
        }
    }

    const [values1, setValues1] = useState({
        name:'',
        subscription_paid:false,
        error1:'',
        success1:''
    })

    const { name, subscription_paid, error1, success1} = values1;

    const checkPaid = (userId) => {
        getUser(userId,token).then(data => {
            if (data.error) {
                setValues1({ ...values1, error1: data.error });
            } else {
               
                setValues1({
                    ...values1,
                    name: data.name,
                    subscription_paid: data.subscription_paid
                });
                setIsCancelled(data.isSubCancelled);
            }
        });
    }

    const checkOrders = () => {
        getOrderByUserID(user._id).then(data => {
            if (data.error) {
                console.log(data.error);           
            } else {
                setOrders(data);           
            }
        });
    };
   
    const clickSubmit = (event) => {
        event.preventDefault();
        if(!subscription_paid) {
            
        if(!isAuthenticated() && items.length>=1 && packageprice<=1)//Block unregistered user place single order without package
        {
            setPlans({
                ...plans,
                error:'If you are a registered user, please <a href="/login">click here</a>. <br/>Please add a package and try again to place order. <a href="/step3">click here to add your package</a>'
            })
        }
        else if(type1===0 || type2===1 || type3 === 2){
        setPlans({...plans, error: false });
        localStorage.setItem('type', JSON.stringify( plans ))
        
                setPlans({
                    ...plans,
                    type1: '',
                    type2: '',
                    type3: '',
                    subscription_type:'',
                    type1class:'',
                    type2class:'',  
                    type3class:'',                  
                    error:'',
                    success: true
                })
                window.location.href="/shipping";
            }
            else{
                setvalMsg(true);
                setPlans({
                    ...plans,
                    error:'Please Choose one Subscription model'
                })
            }
        } else {
            setPlans({...plans,subscription_type:-1, error: false });
           
            localStorage.setItem('type', JSON.stringify( plans ))
        
                setPlans({
                    ...plans,
                    type1: '',
                    type2: '',
                    type3:'',
                    subscription_type:'',
                    type1class:'',
                    type2class:'',       
                    type3class:'',             
                    error:'',
                    success: true
                })
                window.location.href="/shipping";
        }
        
            
    }

    const updatehandleChange = productId => event => {
        let tempQuan;
        items.map((p,i) => (
            productId === p._id && (tempQuan = p.quan)
        ))
        
        setCount(event.target.value < 1 ? 1 : event.target.value)
        if(event.target.value >=1 && event.target.value <=tempQuan) {
            updateItem(productId, event.target.value)
        }
    }

    const updatehandleChange1 = (singlepackageprice1) => event => {
        setCount(event.target.value < 1 ? 1 : event.target.value)
        if(event.target.value >=1) {
            if(localStorage.getItem('package')) {
                localStorage.setItem('packageQnty', JSON.stringify({val:event.target.value}));
                setPlans({packageprice:[event.target.value * singlepackageprice1],packagecount:event.target.value,singlepackageprice:singlepackageprice1})
            }
            else if(localStorage.getItem('adpackage')) {
                    localStorage.setItem('adpackageQnty', JSON.stringify({val:event.target.value}));
                    setPlans({packageprice:[event.target.value * singlepackageprice1],packagecount:event.target.value,singlepackageprice:singlepackageprice1})
            }
            
            //updateItem(productId, event.target.value)
        }
    }
 
    const getTotal = () => {
        return items.reduce((currentValue, nextValue) => {
            return currentValue + nextValue.count * nextValue.sale_price;
        }, 0);
    };
 

    const prodDetails = () => {
        return (       
                items.map((product, i) => (                           
                <tr>                                  
                   <td>
                        <input type="number" width="100%" style={{width:'100%'}} onChange={updatehandleChange(product._id)} value={product.count} className="form-control" id="usr" />
                        </td>
                        <td>{product.name}</td>                        
                            <td>
                        <i onClick={() => removeItem(product._id)} className="fa fa-trash-o" aria-hidden="true"></i>
                        £ {product.count  * product.sale_price}
                        </td>                 
                </tr>          
                    ))
                )
            }      

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            <span dangerouslySetInnerHTML={{__html: error}}></span>
        </div>
    );

    const showSuccess = () => (
        success && <Redirect to="/shipping" />
    );

    const packDetails = () => {
        const packData = JSON.parse(localStorage.getItem('package'));
       
        if(localStorage.getItem('package')){          
        return (     
            <tr>                        
                <td>
                <input type="number" style={{width:'100%'}} onChange={updatehandleChange1(singlepackageprice)} value={packagecount} className="form-control" id="usr1" readOnly />
                </td>              
                <td>
                    {packData.packageHead}
                    <br></br><span dangerouslySetInnerHTML={{__html: packData.packageName}}></span> 
                </td>               
                <td>
                { (items.length>=1)?"":
                     <i onClick={removePacItem} className="fa fa-trash-o" aria-hidden="true"></i> 
                   }
                    £ {packageprice}
                </td>      

          </tr>
        )}
    };

    const adPackDetails = () => {
        const packData = JSON.parse(localStorage.getItem('adpackage'));
       
        if(localStorage.getItem('adpackage')){          
        return (     
            <tr>                        
                <td>
                <input type="number" style={{width:'100%'}} onChange={updatehandleChange1(singlepackageprice)} value={packagecount} className="form-control" id="usr1" readOnly />
                </td>              
                <td>
                    {packData.adpackageHead}
                    <br></br><span dangerouslySetInnerHTML={{__html: packData.adpackageName}}></span> 
                </td>               
                <td>
                { (items.length>=1)?"":
                     <i onClick={removeAdPacItem} className="fa fa-trash-o" aria-hidden="true"></i> 
                   }
                    £ {packageprice}
                </td>      

          </tr>
        )}
    };

        const showEmptyCart = () => {
            return (
                            <div className="col-md-12 px-0">
                                <div className="col-md-12" style={{backgroundColor: '#f5f5f5', border: 'solid 1px #e7e7e7', borderTop: '0px'}}>
                                    <br/>
                                    <h4 style={{textAlign:'center',color:'red'}}>Buying for the First Time? Go forward and build your customised package <a href="/step1">here</a>.</h4>
                                    
                                </div>
                                <br/>
                                <br/>
                            </div>              
                    
            )
        }

        const showPayment = () => {
            return (
                <div className="col-md-12 px-0">
                    <div className="col-md-12" style={{backgroundColor: '#f5f5f5', border: 'solid 1px #e7e7e7', borderTop: '0px'}}>
                        <br/>
                        <h4 style={{textAlign:'center',color:'red'}}>Uh-Oh! Payment pending for the previous order! Please<a href="/user/yourorders" className="btn btn-info btn-gra-my-p">Pay and Continue</a></h4>
                        
                    </div>
                    <br/>
                    <br/>
                </div>                 
            )
        }

        const showSubscription = () => {
            return (
                <div className="col-md-12 px-0">
                    <div className="col-md-12" style={{backgroundColor: '#f5f5f5', border: 'solid 1px #e7e7e7', borderTop: '0px'}}>
                        <br/>
                        <h4 style={{textAlign:'center',color:'red'}}>Uh-Oh! Your Subscription is deactivated, Please<button onClick={() => setModal(true)} className="btn btn-info btn-gra-my-p">Activate and Continue</button></h4>
                        
                    </div>
                    <br/>
                    <br/>
                </div>                 
            )
        }

        const subhandleChange = name => event => {

            if(name === 'stype1') {
                setPlanType(0) // silver
                setPlanVal({...planVal, stype1class:'col-md-12 bord-line true',stype2class:'col-md-12 bord-line', stype3class:'col-md-12 bord-line', stype1:true, stype2:false, stype3:false})
            } 
            else if(name === 'stype2') {
                setPlanType(1) // gold
                setPlanVal({...planVal, stype2class:'col-md-12 bord-line true',stype1class:'col-md-12 bord-line', stype3class:'col-md-12 bord-line', stype2:true, stype1:false, stype3:false})
            } 
            else if(name === 'stype3') {
                setPlanType(2) // bronze
                setPlanVal({...planVal, stype3class:'col-md-12 bord-line true',stype1class:'col-md-12 bord-line', stype2class:'col-md-12 bord-line',stype3:true, stype1:false, stype2:false})
            } 
        }

        const showSubModal = () => (
            <Modal show={showModal} onHide={() => setModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Choose Plan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>         
    
                    <div className="row">

                   
                        <div className=" col-md-12">
                            <div className="row bg-light-inner choose-sub-padd pb-4">                    
                       

                            <div className="col-md-4 mb-3">
                                <div className={stype3class}>
                                
                                    <div className="row">
                                        <div className="col-md-1 wid-5-per">
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input id="radio3" type="radio" value={stype3} defaultChecked={stype3} onChange={subhandleChange('stype3')}  className="form-check-input" name="optradio" required />
                                                </label>
                                            </div>
                                        </div>
                                        <label for="radio3" className="col-md-11"> 
                                        <div className="row">
                                        <div className="col-md-6 py-3 wid-60-per-in">       
                                                                             
                                            <h5>Bronze</h5>
                                                                                                      
                                        </div>

                                        <div className="col-md-6 pt-3">
                                            <h5>£{bronze_price}/mo</h5>
                                        </div>
                                        <div className="col-md-12">
                                            <p>✔ Full access to Safely Connect App</p>
                                            <ul style={{marginLeft:'20px'}}>
                                                <li>&nbsp;Ability to arm/disarm system and monitor sensors from anywhere.</li>
                                                </ul>
                                            <p>✔ Instant App Notifications</p>
                                            <p>✔ Smart Rules & Scenes</p>
                                            <p>✔ 1-year warranty for all products</p>
                                            <p>✔ All 3rd Party Integrations</p>
                                            <p>✔ Device battery monitoring</p>
                                            <p>✔ Post Sales Service and Support Available</p>
                                            <p>✔ Customized pre-programming of all devices</p>
                                        </div>
                                        </div>  
                                        </label>  
                                    </div>

                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className={stype1class}>
                                
                                    <div className="row">
                                        <div className="col-md-1 wid-5-per">
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input id="radio1" type="radio" value={stype1} defaultChecked={stype1} onChange={subhandleChange('stype1')}  className="form-check-input" name="optradio" required />
                                                </label>
                                            </div>
                                        </div>
                                        <label for="radio1" className="col-md-11"> 
                                        <div className="row">
                                        <div className="col-md-6 py-3 wid-60-per-in">       
                                                                            
                                            <h5>Silver</h5>
                                        
                                        </div>

                                        <div className="col-md-6 pt-3">
                                            <h5>£{silver_price}/mo</h5>
                                        </div>
                                        <div className="col-md-12">
                                                <p>✔ Full access to Safely Connect App</p>
                                                <p>✔ Instant App Notifications</p>
                                                <p>✔ Smart Rules & Scenes</p>
                                                <span>(Scenes allow you to create automated actions in your home using different devices. For example, you can create a scene that, when you get back from work at 6:30pm, turns the radio and kettle on for you.)</span>
                                                <p>✔ 1-year warranty for all products </p>
                                                <p>✔ All 3rd Party Integrations </p>
                                                <p>✔ Device battery monitoring </p>
                                                <p>✔ Post Sales Service and Support Available </p>
                                                <p>✔ Customized pre-programming of all devices </p>
                                                <p>✔ Cellular 4G Backup </p>
                                                <p>✔ Battery Backup</p>
                                                <ul style={{marginLeft:'20px'}}>
                                                <li>&nbsp;Provides connectivity during power or internet outages</li>
                                                </ul>
                                            </div>
                                        </div>  
                                        </label>  
                                    </div>

                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className={stype2class}>
                                
                                    <div className="row">
                                        <div className="col-md-1 wid-5-per">
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input id="radio2" type="radio" value={stype2} defaultChecked={stype2} onChange={subhandleChange('stype2')} className="form-check-input" name="optradio" required />
                                                </label>
                                            </div>
                                        </div>
                                    <label for="radio2" className="col-md-11">
                                        <div className="row">
                                        <div className="col-md-6 py-3 wid-60-per-in">                                 
                                            <h5>Gold</h5>
                                                                                    
                                        </div>
                                        
                                        <div className="col-md-6 pt-3">
                                            <h5>£{gold_price}/mo</h5>
                                        </div>
                                        <div className="col-md-12">
                                        <p>✔ Full access to Safely Connect App</p>
                                        <p>✔ Instant App Notifications</p>
                                        <p>✔ Smart Rules & Scenes</p>
                                        <p>✔ All 3rd Party Integrations</p>
                                        <p>✔ Device battery monitoring</p>
                                        <p>✔ Post Sales Service and Support Available</p>
                                        <p>✔ Customized pre-programming of all devices</p>
                                        <p>✔ Cellular 4G Backup</p>
                                        <p>✔ Battery Backup</p>
                                        <ul style={{marginLeft:'20px'}}>
                                                <li>&nbsp;Provides connectivity during power or internet outages</li>
                                                </ul>
                                            <p>✔ 24/7 Live Monitoring for:</p>
                                            <ul style={{marginLeft:'20px'}}>
                                                <li>&nbsp;Security Alarms</li>
                                                <li>&nbsp;Assistance Alarms</li>
                                                <li>&nbsp;Safety Alarms(Flood & fire)</li>
                                                <li>&nbsp;Complete system health monitoring and tamper alarms</li>
                                            </ul>
                                            <p>✔ 2-Year Warranty</p>
                                            <p>✔ Exclusive 10% Discount on future orders</p>
                                        </div>
                                        </div>
                                        </label>
                                    </div>

                                </div>
                            </div>

                            

                            <div className="col-md-12">
                                <br/>
                                <center>
                                    <button className="btn btn-info btn-gra-my-p" onClick={() => clickSubscription()}>Activate</button>
                                </center>
                            </div>
                       
                        </div>
                    </div>        
                </div>     
                        
                    </Modal.Body>
    
                </Modal>
        )

        const clickSubscription = () => {

            localStorage.setItem('subValue', JSON.stringify({plan: planType,goldP:gold_price, silverP:silver_price, bronzeP:bronze_price, isCancelled: isCancelled, isReview:true}))
            return window.location.href="/checkout";
        }

        const showSummary = () => {
            return (
                <div className="col-md-12 plr-0px">
                                    <div className="container plr-0px">
                                        <div className="col-md-10 mx-auto mb-5 plr-0px">
                                            <table className="table table-bordered table-responsive table-review">
                                                <thead>
                                                    <tr>
                                                    <th style={{width:'10%'}}>Quantity</th>
                                                    <th>Product</th>
                                                    <th>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>                        
                                                    {(localStorage.getItem("package")) ? packDetails() : (localStorage.getItem("adpackage") && adPackDetails())}      
                                                    {prodDetails()}
                                                </tbody>
                                            </table>
                                        </div>

                                      
                                    
                                {!subscription_paid &&
                                    (
                                    <>
                                        <div className="col-md-12 round-top-btm-pad">
                                            <div className="row bg-rev-bl">
                                        
                                                <div className="col-md-9 exp-padd-top">
                                                    <p className="exp-text-lt pb-5"><span style={{fontWeight: 'bold'}}>Experience the full capabilities of your<br/> SafelyTeam System.</span> We are now offering 1-Month Free Gold <br/>monitoring in all our packages.</p>
                                                </div>

                                                <div className="col-md-3 px-0 sh-img pb-3">
                                                    <img src={require('../img/round-month.png')} className="img-fluid"/>
                                                </div>
                                        
                                            </div>
                                            <div className="col-md-3 px-0 tp-menu-show rount-month-pos">
                                                <img src={require('../img/round-month.png')} className="img-fluid"/>
                                            </div>
                                        </div>

                                        <div className=" col-md-12">
                                            <div className="row bg-light-inner choose-sub-padd pb-4">                    
                                                    {/* <div className="col-md-12 py-4">
                                                        <h4 className="choose-sub-in">Please select your monitoring plan</h4>
                                                        <br/>
                                                        <div className="alert alert-warning">
                                                        <h4><b style={{fontSize:'18px'}} className="blinking">1<sup>st</sup> Month Free!</b> - Experience the full capabilities of your Safely System. We are now offering 1-Month Free Gold monitoring in all our packages​.</h4>
                                                        </div>
                                                    </div> */}
                                                  
                                                    {/* <div className="col-md-12 bg-rev-bl mb-3">
  <div className="row">
      <img src={require('../img/free-banner.png')} className="img-fluid" alt=""/>
   
  </div>
</div> */}


                                                <div className="col-md-4 mb-3">
                                                    <div className={type3class}>
                                                    
                                                        <div className="row">
                                                            <div className="col-md-1 wid-5-per">
                                                                <div className="form-check">
                                                                    <label className="form-check-label">
                                                                        <input id="radio3" type="radio" value={type3} defaultChecked={type3} onChange={handleChange('type3')} className="form-check-input" name="optradio" required />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        <label for="radio3" className="col-md-11">
                                                            <div className="row">
                                                            <div className="col-md-9 py-3 wid-60-per-in">                                 
                                                                <h5>Bronze</h5>
                                                                <p>✔ Full access to Safely Connect App</p>
                                            <ul style={{marginLeft:'20px'}}>
                                                <li>&nbsp;Ability to arm/disarm system and monitor sensors from anywhere.</li>
                                                </ul>
                                            <p>✔ Instant App Notifications</p>
                                            <p>✔ Smart Rules & Scenes</p>
                                            <p>✔ 1-year warranty for all products</p>
                                            <p>✔ All 3rd Party Integrations</p>
                                            <p>✔ Device battery monitoring</p>
                                            <p>✔ Post Sales Service and Support Available</p>
                                            <p>✔ Customized pre-programming of all devices</p>
                                                                
                                                            </div>
                                                            
                                                            <div className="col-md-2 pt-3">
                                                                <h5>£{bronze_price}/mo</h5>
                                                            </div>
                                                            </div>
                                                            </label>
                                                        </div>

                                                    </div>
                                                </div>

                                                    <div className="col-md-4">
                                                        <div className={type1class}>
                                                        
                                                            <div className="row">
                                                                <div className="col-md-1 wid-5-per">
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input id="radio1" type="radio" value={type1} defaultChecked={type1} onChange={handleChange('type1')}  className="form-check-input" name="optradio" required />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <label for="radio1" className="col-md-11"> 
                                                                <div className="row">
                                                                <div className="col-md-9 py-3 wid-60-per-in">       
                                                                                                     
                                                                    <h5>Silver</h5>
                                                                    <p>✔ Full access to Safely Connect App</p>
                                                <p>✔ Instant App Notifications</p>
                                                <p>✔ Smart Rules & Scenes</p>
                                                <span>(Scenes allow you to create automated actions in your home using different devices. For example, you can create a scene that, when you get back from work at 6:30pm, turns the radio and kettle on for you.)</span>
                                                <p>✔ 1-year warranty for all products </p>
                                                <p>✔ All 3rd Party Integrations </p>
                                                <p>✔ Device battery monitoring </p>
                                                <p>✔ Post Sales Service and Support Available </p>
                                                <p>✔ Customized pre-programming of all devices </p>
                                                <p>✔ Cellular 4G Backup </p>
                                                <p>✔ Battery Backup</p>
                                                <ul style={{marginLeft:'20px'}}>
                                                <li>&nbsp;Provides connectivity during power or internet outages</li>
                                                </ul>
                                                                    
                                                                  
                                                                </div>

                                                                <div className="col-md-2 pt-3">
                                                                    <h5>£{silver_price}/mo</h5>
                                                                </div>
                                                                </div>  
                                                                </label>  
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className={type2class}>
                                                        
                                                            <div className="row">
                                                                <div className="col-md-1 wid-5-per">
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input id="radio2" type="radio" value={type2} defaultChecked={type2} onChange={handleChange('type2')} className="form-check-input" name="optradio" required />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            <label for="radio2" className="col-md-11">
                                                                <div className="row">
                                                                <div className="col-md-9 py-3 wid-60-per-in">                                 
                                                                    <h5>Gold</h5>
                                                                    <p>✔ Full access to Safely Connect App</p>
                                        <p>✔ Instant App Notifications</p>
                                        <p>✔ Smart Rules & Scenes</p>
                                        <p>✔ All 3rd Party Integrations</p>
                                        <p>✔ Device battery monitoring</p>
                                        <p>✔ Post Sales Service and Support Available</p>
                                        <p>✔ Customized pre-programming of all devices</p>
                                        <p>✔ Cellular 4G Backup</p>
                                        <p>✔ Battery Backup</p>
                                        <ul style={{marginLeft:'20px'}}>
                                                <li>&nbsp;Provides connectivity during power or internet outages</li>
                                                </ul>
                                            <p>✔ 24/7 Live Monitoring for:</p>
                                            <ul style={{marginLeft:'20px'}}>
                                                <li>&nbsp;Security Alarms</li>
                                                <li>&nbsp;Assistance Alarms</li>
                                                <li>&nbsp;Safety Alarms(Flood & fire)</li>
                                                <li>&nbsp;Complete system health monitoring and tamper alarms</li>
                                            </ul>
                                            <p>✔ 2-Year Warranty</p>
                                            <p>✔ Exclusive 10% Discount on future orders</p>
                                                                    
                                                                </div>
                                                                
                                                                <div className="col-md-2 pt-3">
                                                                    <h5>£{gold_price}/mo</h5>
                                                                </div>
                                                                </div>
                                                                </label>
                                                            </div>

                                                        </div>
                                                    </div>


                                                    
                                                   
                                            </div>
                                        </div>
</>
                                        )}
                            
                                        <div className="col-md-12 plr-0px">
                                            <div className="row pt-5">
                                                {/* <div className="col-md-6 pb-3 wid-40-per">
                                                    Shipping
                                                </div>
                                                <div className="col-md-6 pb-3 text-right wid-40-per">
                                                    Free
                                                </div> */}
                                               {/*  <div className="col-md-6 pb-3 wid-40-per">
                                                    Total
                                                </div>
                                                <div className="col-md-6 pb-3 text-right wid-40-per">
                                                    £{getTotal(items)+Number(packageprice)}
                                                </div> */}
                                                {/* <div className="col-md-6 blue-dis pt-2 pb-5 wid-40-per">
                                                    Discount
                                                </div>
                                                <div className="col-md-6 text-right blue-dis pt-2 pb-5 wid-40-per">
                                                    £0.00
                                                </div> */}
                                            </div>

                                           {/*  <div className="row py-3 mb-5 shipping-bor">
                                                <div className="col-md-6 wid-40-per">
                                                    Shipping
                                                </div>
                                                <div className="col-md-6 text-right wid-40-per">
                                                    Free
                                                </div>      
                                            </div> */}

                                            <div className="row py-3 mb-5 shipping-bor">
                                                <div className="col-md-6 blue-dis wid-40-per">
                                                    Total
                                                </div>
                                                <div className="col-md-6 blue-dis text-right wid-40-per">
                                                    £{(getTotal(items) +Number(packageprice)).toFixed(2)}
                                                </div>                               
                                            </div>

                                            <div className="row py-3 mb-5">
                                                <div className="col-md-12">
                                                {(valMsg)?
                                                    <div className='alert alert-danger'>{error}</div> :  showError()}
                                                    {showSuccess()}
                                       
                                                    <button onClick={clickSubmit} className="btn btn-success pull-right mb-3" data-toggle="modal" data-target="#send-request">Proceed to checkout</button>                         
                                                </div>                         
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
            )
        }

        const removePacItem = (event) => {
            localStorage.removeItem('package');
            localStorage.removeItem('packageQnty');            
            window.location.reload();
        };

        const removeAdPacItem = (event) => {
            localStorage.removeItem('adpackage');
            localStorage.removeItem('adpackageQnty');            
            window.location.reload();
        };

    
    return (
        <Fragment style={{overflowY: 'scroll', overflowX: 'hidden'}}>
             <Helmet>
                <title>SafelyTeam – Review Your Order​</title>
            </Helmet>
                <Nav />
               
                        <div className="section pp-scrollable slide-dark slide-dark-footer">
                        
                            <div className="col-md-12 top-det-sp-in">

                                <div className="col-md-12 pt-5 text-center">
                                    <h3>Review Your Order</h3>
                                       
                                </div>
                                {showSubModal()}

                                {(isAuthenticated() && isCancelled) ? (
                                    showSubscription()
                                ) : (

                                    (isAuthenticated() && !subscription_paid && orders.length !== 0) ? (
                                        showPayment()
                                    ) : (isAuthenticated() && orders.length === 0 && items.length>=1 && packageprice>=1 ) ?
                                    (
                                        showSummary()                                                                  
                                    ) : (isAuthenticated() && orders.length === 0 && items.length>=1 ) ?
                                    (                                   
                                        showEmptyCart()
                                    ) : (items.length>=1 || packageprice>=1) ? (
                                            showSummary()
                                        ) : (
                                            showEmptyCart()
                                        )
                                    
                                )} 

                                <Backdrop className={classes.backdrop} open={loading} >
                                    <CircularProgress color="inherit" />
                                </Backdrop>  
                        
                            </div>
                            <Subscribe />
                        </div>
                        <Footer />
                   
                
            
        </Fragment>
    )
}

export default ReviewOrder
