import React, { useState, useEffect } from 'react'
import AdminLayout from '../theme/admin/AdminLayout'
import {getSubscriptionLogs,getUser,updateUserSubscriptionHistoryUser} from './apiAdmin'
import { isAuthenticated } from '../auth/index'
import Moment from 'react-moment';
import { API} from '../config';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Badge } from 'react-bootstrap'

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const SubscriptionDetails = ({match}) => {

    const { user,token } = isAuthenticated()

    const [ subLogs, setSubLogs] = useState([]);

    const [paymentHistory, setPaymentHistory] = useState([])

    const [customerHistory, setCustomerHistory] = useState([])

    const [paymentDetails1,setPaymentDetails] = useState([])

    const [ invoices, setInvoices] = useState([]);

    let [amount, setAmount] = useState('');

    let [interval, setInterval] = useState('');

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const [btnloading, setLoading] = useState(true)

    let [trialDays, setTrialDays] = useState('');

    let [inVoiceData, setInVoiceData] = useState('');

    let [status1, setStatus] = useState('');

    const [values, setValues] = useState({
        prefix:'',
        name: '',
        lastname: '',
        email: '',
        phone: '',
        role: '',
        subscription_paid:'',
        subscriptionId:'',
        error: '',
        success: false
    });

    const {prefix,name, lastname,subscription_paid,subscriptionId, email, phone,role,success, error} = values

    let [billing, setBillings] = useState({
        bill_name:'',
        bill_email:'',
        bill_country:''
    })
    
    let { bill_name, bill_email, bill_country} = billing;

    let [cards, setCards] = useState({
        card_brand:'',
        card_num:'',
        card_ExpMonth:'',
        card_ExpYear:''
      })
    
    let { card_brand,card_num,card_ExpMonth,card_ExpYear} = cards;

    let [allInvoices1,setAllInvoices] = useState({
        inv_description:'',
        inv_start:'',
        inv_end:'',
        inv_amount:'',
        inv_pdf:''
      })
    
    let {inv_description,inv_amount,inv_pdf,inv_end,inv_start} = allInvoices1;

    const [subValues, setSubValues] = useState({
        createdAt:'',
        subAmount:'',
        subType:''
    })

    const { createdAt, subAmount, subType} = subValues;

    const [openSub, setOpenSub] = useState(false);


    const loadSubLogs = () => {
        getSubscriptionLogs(user._id, token,{userId:match.params.userId})
        .then(data => {
            if(data.error){
                console.log("Sub log error")
                setOpen(false);
                setLoading(false); 
            }
            else {
                setSubLogs(data);
                setOpen(false);
                setLoading(false); 
            }
        })
    }

    const init = () => {
        getUser(match.params.userId,token).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setValues({
                    ...values,
                    prefix:data.prefix,
                    name: data.name,
                    lastname: data.lastname,
                    email: data.email,
                    phone: data.phone,
                    role: data.role,
                    subscription_paid: data.subscription_paid,
                    subscriptionId: data.subscriptionid
                });
                loadSubLogs();
            }
        });
    };

    useEffect(() => {
        init();
        
    },[])


    const showSubLogs = () => (
    
        <div className="row">

            <div className="table-responsive pt-3 px-2">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th className="pend-table-hd">Subscription Id</th>
                            <th className="pend-table-hd">Plan Name</th>
                            <th className="pend-table-hd">Price(£)</th>
                            <th className="pend-table-hd">Status</th>
                           {subscription_paid && <th className="pend-table-hd">Action</th>} 
                        </tr>
                    </thead>

                    <tbody>
                        {subLogs.map((s,i) => (
                            <tr key={i}>
                                <td>{s.subscriptionId}</td>
                                <td>{showPlanName(s.subscriptionType)}</td>
                                <td>{s.subscriptionAmount}</td>
                                <td>{showStatus(s.status)}</td>
                                {subscription_paid && <td><button className="btn  btn-primary mr-2 mb-3" onClick={() => clickSubDetails(s.createdAt,s.subscriptionType, s.subscriptionAmount,s.subscriptionId, s.userId._id)}>view more</button></td>}
                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>

        </div>
    )

    const showStatus = (status) => {
        if(status === 1){
            return <Badge pill variant="success">Active</Badge>
        }
        else {
            return <Badge pill variant="danger">Cancelled</Badge>
        }
    }

    const showPlanName = (plan) => {
        if(Number(plan) === 1){
            return <Badge pill variant="warning">Gold</Badge>
        }
        else if(Number(plan) === 0) {
            return <Badge pill variant="secondary">Silver</Badge>
        }
        else if(Number(plan) === 2) {
            return <Badge pill variant="primary">Bronze</Badge>
        }
    }

    const clickSubDetails = (createDate,type,amount,ID,userID) => {
        setOpenSub(false); 
        setOpen(true);
        setSubValues({...subValues,createdAt:createDate,subAmount:amount, subType:type});
        paymentdetails(ID,userID); 

    }

    const paymentdetails = async (paymentId,userID) =>{
        if(paymentId!=="")
        {
            
            const response = await fetch(`${API}/get-subscription`, {
              method:'POST',
              headers: {'Content-Type': 'application/json',},
              body: JSON.stringify({ 
                'paymentid': String(paymentId),
                'userid': String(userID)        
                })
            });
            const payment = await response.json();   
    
            const response1 = await fetch(`${API}/list-all-subscriptions`, {
                method:'POST',
                headers: {'Content-Type': 'application/json',},
                body: JSON.stringify({ 
                  'paymentid': String(paymentId),
                  'userid': String(userID)          
                  })
              });  
            const payment1 = await response1.json();
    
            const Customerresponse = await fetch(`${API}/get-customer`, {
                method:'POST',
                headers: {'Content-Type': 'application/json',},
                body: JSON.stringify({ 
                  'customerid': String(payment.customer),
                  'userid': String(userID)         
                  })
              });  
              const customer = await Customerresponse.json();    
              
              const Customerresponse1 = await fetch(`${API}/upcomming-invoices`, {
                method:'POST',
                headers: {'Content-Type': 'application/json',},
                body: JSON.stringify({ 
                  'customerid': String(payment.customer),
                  'userid': String(userID)         
                  })
              });  
              const customer1 = await Customerresponse1.json();
    
              const Customerresponse2 = await fetch(`${API}/all-invoices`, {
                method:'POST',
                headers: {'Content-Type': 'application/json',},
                body: JSON.stringify({ 
                  'customerid': String(payment.customer),
                  'userid': String(userID)         
                  })
              });  
              const allInvoices = await Customerresponse2.json();
    
              const AllPaymentMethods = await fetch(`${API}/all-payment-methods`, {
              method:'POST',
              headers: {'Content-Type': 'application/json',},
              body: JSON.stringify({ 
                'customerid': String(payment.customer),
                'userid': String(userID)         
                })
            });  
            const allPayments = await AllPaymentMethods.json();
          
            setPaymentHistory(payment);   
            setCustomerHistory (customer);
            setPaymentDetails(allPayments);
            setInvoices(customer1);
            
            var getPayments;
           
            for(var i=0;i<allPayments.data.length;i++){
              if(payment.default_payment_method ===  allPayments.data[i].id){
                getPayments = allPayments.data[i];   
              }
            }
    
            // Card Details Start
            let tempCard = JSON.stringify(getPayments.card);
           
            var tempCard1 = tempCard.replace("{", "").replace("}", "").replace(/['"]+/g, '');
            var tempCardArray = tempCard1.split(",");
    
            var tempExpMonth=0;
            var tempExpYear=0;
            var tempCardNum=0;
            var tempBrand="";
            var tempCardName1="";
            var tempCardValue="";
    
            for(var i=0;i<tempCardArray.length;i++){
    
                var tempCardName = tempCardArray[i].split(':');
                tempCardName1 = tempCardName[0];
                tempCardValue = tempCardName[1];
                if(tempCardName1 === "brand")
                {
                    tempBrand = tempCardValue;
                }
                else if(tempCardName1 === "exp_month")
                {
                    tempExpMonth = tempCardValue;
                }
                else if(tempCardName1 === "exp_year")
                {
                    tempExpYear = tempCardValue;
                }
                else if(tempCardName1 === "last4")
                {
                    tempCardNum = tempCardValue;
                }
              
            }
    
            setCards({
                ...cards,
                card_brand:tempBrand,
                card_num:tempCardNum,
                card_ExpMonth:tempExpMonth,
                card_ExpYear:tempExpYear
            })
    
            //Card Details End
    
            //Billing Details Start
    
            let tempBilling = JSON.stringify(getPayments.billing_details);
    
            var tempBilling1 = tempBilling.replace("{", "").replace("}", "").replace(/['"]+/g, '');
            var tempBillingArray = tempBilling1.split(",");
           
            var tempBillingName ="";
            var tempBillingEmail="";
            var tempBillName1="";
            var tempBillValue="";
            var tempBillCountry="";
            for(var i=0;i<tempBillingArray.length;i++){
    
                var tempBillName = tempBillingArray[i].split(':');
               
                tempBillName1 = tempBillName[0];
                tempBillValue = tempBillName[1];
                if(tempBillName1 === "name"){
                    tempBillingName = tempBillValue;
                }
                else if(tempBillName1 === "email")
                {
                    tempBillingEmail = tempBillValue;
                }
                else if(tempBillName1 === "country")
                {
                    tempBillCountry = tempBillValue
                }
            }
    
            setBillings({
                ...billing,
                bill_name:tempBillingName,
                bill_email:tempBillingEmail,
                bill_country:tempBillCountry
            })
    
            //Billing Details End
    
            // All Invoice Start
            
            var invoiceLength = allInvoices.data.length;
           
            var getInvoices = allInvoices.data[0];
    
            let tempInvoices = JSON.stringify(getInvoices.lines.data);
    
            var tempInVoices1 = tempInvoices.replace("{", "").replace("}", "").replace(/['"]+/g, '');
            var tempInvoiceArray = tempInVoices1.split(",");
          
            var tempInvoiceName1="";
            var tempInvoiceValue="";
            var tempInDes="";
            var tempInStart="";
            var tempInEnd="";
            var tempInvoiceAmount=0;
            for(var i=0;i<tempInvoiceArray.length;i++){
                var tempInvoiceName = tempInvoiceArray[i].split(':');
              
                tempInvoiceName1 = tempInvoiceName[0];
                tempInvoiceValue = tempInvoiceName[1];
    
                if(tempInvoiceValue === "Trial period for Silver Subscription" || tempInvoiceValue === "Trial period for Gold Subscription") {
                  tempInDes = tempInvoiceValue;
                  break;
              }
            }
    
            tempInStart = tempInStart.replace('}','');
    
            setAllInvoices({
                ...allInvoices1,
                inv_description:tempInDes,
                inv_start:tempInStart,
                inv_end:tempInEnd,
                inv_amount:tempAmount,
                inv_pdf:getInvoices.invoice_pdf
            })
    
            var inVoiceArr="";
            var inVoiceAmnt=0;
            var InVoiceCreated=0;
            var InVoicePdf="";
            var InvoiceSub="";
    
            if(tempInDes !== "Trial period for Silver Subscription" && tempInDes !== "Trial period for Gold Subscription" ){
    
              for(var i=0;i<invoiceLength;i++){
                  var getInvoices1 = allInvoices.data[i];         
                  inVoiceAmnt = getInvoices1.total/100;
                  InVoiceCreated = new Date(getInvoices1.created * 1000).toLocaleDateString("en-GB")
                  InVoicePdf = getInvoices1.invoice_pdf; 
                  InvoiceSub = getInvoices1.subscription;
                  inVoiceArr+= `<tr><td>${InVoiceCreated}</td><td>${InvoiceSub}</td><td>${inVoiceAmnt}</td></tr>`
              }
          }
          setInVoiceData(inVoiceArr);
    
            //All Invoice End
            let tempPlan = JSON.stringify(payment.plan);
            var tempPlan1= tempPlan.replace("{", "").replace("}", "").replace(/['"]+/g, '');
            var tempArray = tempPlan1.split(",");
           
            var tempName1="";
            var tempValue="";
            var tempAmount=0;
            var tempTrial=0;
            var tempStatus="";
            var tempInterval="";
           
            for( var i=0;i<tempArray.length;i++)
            {
                var tempName = tempArray[i].split(':');
                tempName1 = tempName[0];
                tempValue = tempName[1];
               
                if(tempName1 === "amount")
                {
                    tempAmount=tempValue;
                }
                else if(tempName1 === "trial_period_days")
                {
                    tempTrial = tempValue;
                }
                else if(tempName1 === "active")
                {
                    tempStatus = tempValue;
    
                }
                else if(tempName1 === "interval")
                {
                    tempInterval = tempValue;
                }
               
                
            }
            setAmount(tempAmount);
            setStatus(tempStatus);
            setTrialDays(tempTrial);
            setInterval(tempInterval);
          
        }
        setOpen(false);
        setOpenSub(true);
        setLoading(false);
       
    }

    const showSubDetails = () => (

        <div className="row">

        <div className="col-md-12 px-0">

                <div className="row">
                    <div className="col-md-12 stretch-card">
                        <div className="card position-relative card-shad-in">
                        <div className="card-body">
                            <div className="row estimated-bord">
                            <div className="col-md-6 blue-esti-head">
                            <p className="card-title blue-esti-head">Subscription  Status : {capitalize(paymentHistory.status)}</p>
                            <p className="card-title blue-esti-head">Created Date :  <Moment format='DD/MM/YYYY'>{(createdAt)}</Moment></p>
                            </div>
                            <div className="col-md-6 text-right">
                            <p className="card-title blue-esti-head">Payment Number: {paymentHistory.id}</p> 
                              
                            </div>
                            </div>
                            <div className="row">
                        
                            <div className="col-md-4 col-xl-4 d-flex flex-column justify-content-top">
                                <div className="ml-xl-4 pt-3">
                                <h5 className="products-text-in-dark">Order Details</h5>
                                <h1 className="custo-sec-ad pt-2 pb-2">Customer Name:&nbsp;{prefix+'. '+name+' '+lastname}</h1>
                                <div className="row">
                                    <div className="col-md-12">                           
                                         <h2 className="custo-sec-ad pt-2 pb-2">Subscription Plan:&nbsp;{Number(subType) === 1 ? 'Gold' : (Number(subType) === 0 ? 'Silver' : Number(subType) === 2 && 'Bronze' )}</h2>      
                                      <h2 className="custo-sec-ad pt-2 pb-2">Total Price:&nbsp;£ {Number(subAmount).toFixed(2)}</h2>  
                                      <h2 className="custo-sec-ad pt-2 pb-2">Register Date: &nbsp;<Moment format='DD/MM/YYYY'>{createdAt}</Moment> </h2>                                                                      
                                    </div>
                                </div>
                                </div>  
                            </div>

                            <div className="col-md-4 col-xl-4 d-flex flex-column justify-content-top">
                                <div className="ml-xl-4 pt-3">
                                <h5 className="products-text-in-dark">Subscription Details</h5>
                                   <h2 className="custo-sec-ad pt-2 pb-2">Subscription Status:  {capitalize(paymentHistory.status)}</h2>
                                    <h2 className="custo-sec-ad pt-2 pb-2">Created Date: <Moment format='DD/MM/YYYY'>{createdAt}</Moment></h2>
                                    <h2 className="custo-sec-ad pt-2 pb-2">Amount: £ {(Number(amount)/100).toFixed(2)}</h2>
                                    <h2 className="custo-sec-ad pt-2 pb-2">Interval: {interval}</h2>
                                    {paymentHistory.trial_start !== null && 
                                    <>   
                                        <h2 className="custo-sec-ad pt-2 pb-2">Trial: 30 days</h2>  
                                        <h2 className="custo-sec-ad pt-2 pb-2">Trial Start Date: {new Date(paymentHistory.trial_start * 1000).toLocaleDateString("en-GB")}</h2>     
                                        <h2 className="custo-sec-ad pt-2 pb-2">Trial End Date: {new Date(paymentHistory.trial_end * 1000).toLocaleDateString("en-GB")}</h2>           
                                    </>}                         
                                    <h2 className="custo-sec-ad pt-2 pb-2">Next Recurring Payment Date: {new Date(invoices.next_payment_attempt * 1000).toLocaleDateString("en-GB")} </h2>                    
                                </div>  
                            </div>

                            <div className="col-md-4 col-xl-4 d-flex flex-column justify-content-top">
                                <div className="ml-xl-4 pt-3">
                                <h5 className="products-text-in-dark">Card Details</h5>
                                   <h2 className="custo-sec-ad pt-2 pb-2">Card Name :&nbsp;&nbsp;{card_brand}</h2>
                                    <h2 className="custo-sec-ad pt-2 pb-2">Card Number :&nbsp;&nbsp;xxxx xxxx xxxx {card_num}</h2>
                                    <h2 className="custo-sec-ad pt-2 pb-2">Card Expiry :&nbsp;&nbsp;{card_ExpMonth+'/'+card_ExpYear}</h2>
                                    <hr/>
                                    <h5 className="products-text-in-dark">Billing Details</h5>
                                   <h2 className="custo-sec-ad pt-2 pb-2">Name :&nbsp;&nbsp;{bill_name}</h2>
                                    <h2 className="custo-sec-ad pt-2 pb-2">Email :&nbsp;&nbsp;{email}</h2>
                                     {/* <h2 className="custo-sec-ad pt-2 pb-2">Country :&nbsp;&nbsp;{bill_country}</h2>  */}
                                    
                                </div>  
                            </div>

                            {inv_description !== "Trial period for Silver Subscription" && inv_description !== "Trial period for Gold Subscription" ? (
                            <div className="col-md-12 col-xl-12 d-flex flex-column justify-content-top">
                                <div className="ml-xl-4 pt-3">
                                <h5 className="products-text-in-dark">Subscription History</h5>
                                        <div className="table-responsive pt-3 px-2">
                                            <table className="table table-bordered">
                                               <thead>
                                                   <tr>
                                                       <th>Recurring<br/>Date</th>
                                                       <th>Subscription<br/>ID</th>
                                                       <th>Amount</th>                                                     
                                                   </tr>
                                               </thead>
                                               <tbody dangerouslySetInnerHTML={{__html: inVoiceData}}>
                                               </tbody>
                                           </table>
                                        </div>
                                </div>  
                            </div>): ''}

                            </div>
                        </div>
        
                        </div>
        
                    </div>
        
                    </div>
                    <div className="col-md-12" style={{backgroundColor: '#f5f5f5', border: 'solid 1px #e7e7e7', borderTop: '0px'}}>
                    <div className="row">
                        <div className="col-md-9 py-3">
                         </div>
                        <div className="col-md-3 pt-4">
                        <div className="row">
                          
                        </div>
                        </div>
                    </div>
                    </div>

        </div>
        </div>
    ) 

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const clickSubmit = async (Id) => {
        if(window.confirm("Do you want to cancel this subscription?"))
        {
            setOpen(true);
            setOpenSub(false);
            if(Id!=="")
            {                 
                const response = await fetch(`${API}/cancel-subscription`, {
                method:'POST',
                headers: {'Content-Type': 'application/json',},
                body: JSON.stringify({ 
                    'paymentid': String(Id),
                    'userid': String(match.params.userId)         
                    })
                });
                
                const deleted = await response.json();
                updateUser(Id); 
            }
        }
    }

    const updateUser = (subID) => {

        updateUserSubscriptionHistoryUser(user._id,token,{userId:match.params.userId, subscriptionid:subID})
        .then(async data => {
            if(data.error){
                console.log("Cancel User Update Error");
            } else {
                init(); 
            }
        })

    }


    return (
        <AdminLayout className="container" topTitle="Subscription Details">
            <div className="row">     
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body"> 
                            <h4 className="card-title" style={{textAlign:'center'}}>Subscription Details</h4>

                            
                              
                               {(!btnloading) && (subscription_paid && <button className="btn btn-info btn-gra-my-p" onClick={() => clickSubmit(subscriptionId)}>Cancel Subscription</button> )}
                               

                                {(btnloading) ? <div className='loader-container'><div className='loader'></div></div> : ""}  
                                {!btnloading && showSubLogs()}
                            <br/>
                            {openSub && showSubDetails()}
                             
                               <Backdrop className={classes.backdrop} open={open} >
                                <CircularProgress color="inherit" />
                            </Backdrop>                    
                             
                        </div>
                        
                    </div>
                </div>
           </div>
        </AdminLayout>
    )
}

export default SubscriptionDetails
