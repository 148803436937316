import React, {useState, useEffect, Fragment} from 'react'
import { Redirect } from "react-router-dom"
import { addQuestion } from './apiUser'
import Footer from '../Footer'
import Subcribe from '../Subscribe'
import {OverlayTrigger,Tooltip,Modal} from 'react-bootstrap';
import {Helmet} from 'react-helmet'
import {Link} from 'react-router-dom'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const Step2 = () => {

    const classes = useStyles();

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState({
        safety: '',
        security:'',
        wellness: '',
        temp_user: '',
        house_type: '',
        no_people: 1,
        no_floors: 1,
        no_entdoors: 1,
        no_bedrooms: 1,
        driveway: false,
        pet: false,
        shed: false,
        isMotionSensor: false,
        message1 : '',
        message2 : '',
        message3 : '',
        message4 : '', 
        check_policy:false,
        error: '',
        btnloading:false,
        success: false
    });
    const [checkhouse, setCheckHouse] = useState(false) 

    const [petModal, setPetModal] = useState(false);

    const {  check_policy=false,safety, security, wellness,driveway, pet,isMotionSensor, shed, temp_user, house_type, no_people, no_floors, no_entdoors, no_bedrooms, error,message1, message2, message3, message4,btnloading, success} = values

    const handleChange = name => event => {
        if(name === 'driveway') {  
            setValues({...values, [name]: true,btnloading:false})
        } else if(name === 'pet') {
            setValues({...values, [name]: event.target.checked,btnloading:false})
            setPetModal(event.target.checked);
            if(!event.target.checked){
                setValues({...values,[name]: event.target.checked, isMotionSensor: event.target.checked});
            }
        } else if(name === 'isMotionSensor') {
            setValues({...values, [name]: event.target.checked,btnloading:false})
            setPetModal(false)
        } else if(name === 'shed') {
            setValues({...values, [name]: true,btnloading:false})
        } else if(name === 'house_type') {
            setValues({ ...values, error: false, [name]: event.target.value,btnloading:false})
            setCheckHouse(true);
        } else if(name === "check_policy"){
            setValues({...values, error: false, btnloading:false, [name]: event.target.checked})
        } else {
            setValues({ ...values, error: false, [name]: event.target.value,btnloading:false});
        }
    };
    const no_bedrooms_IncrementItem = name => event => {
        if(no_bedrooms < 8) {
            setValues({...values, [name]: Number([no_bedrooms]) + 1, message1: null,btnloading:false});          
          } else {
            setValues({...values, message1: "Maximum is 8",btnloading:false});       
          }             
      }

    const no_bedrooms_DecreaseItem = name => event => {
          if(no_bedrooms<2)
          {setValues({ ...values, message1: "Minimum is 1",btnloading:false});  }
          else{
        setValues({ ...values, error: false, [name]: [no_bedrooms] - 1, message1: null,btnloading:false});  
          } 
      }
      

    const no_people_IncrementItem = name => event => {
        if(no_people < 10) {
        setValues({ ...values, [name]: Number([no_people]) + 1, message2: null,btnloading:false});      
    } else {
        setValues({...values, message2: "Maximum is 10",btnloading:false});       
      } 
      }
    const no_people_DecreaseItem = name => event => {
          if(no_people<2)
          {setValues({ ...values , message2: "Minimum is 1",btnloading:false});  }
          else{
        setValues({ ...values, error: false, [name]: [no_people] - 1, message2: null,btnloading:false});  
          } 
      } 

      const no_floors_IncrementItem = name => event => {
        if(no_floors < 5) {
        setValues({ ...values, [name]: Number([no_floors]) + 1,message3: null,btnloading:false});   
    } else {
        setValues({...values, message3: "Maximum is 5",btnloading:false});       
      }    
      }
    const no_floors_DecreaseItem = name => event => {
          if(no_floors<2)
          {setValues({ ...values,  message3: "Minimum is 1",btnloading:false});  }
          else{
        setValues({ ...values, [name]: [no_floors] - 1, message3: null,btnloading:false});  
          } 
      }
      
      const no_entdoors_IncrementItem = name => event => {
        if(no_entdoors < 10) {
        setValues({ ...values, [name]: Number([no_entdoors]) + 1,message4: null,btnloading:false});   
    } else {
        setValues({...values, message4: "Maximum is 10",btnloading:false});       
      }    
      }
    const no_entdoors_DecreaseItem = name => event => {
          if(no_entdoors<2)
          {setValues({ ...values, message4: "Minimum is 1",btnloading:false});  }
          else{
        setValues({ ...values, error: false, [name]: [no_entdoors] - 1, message4: null,btnloading:false});  
          } 
      }
      

    const clickSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: false,btnloading:true });
        if(checkhouse && check_policy) //Answer all question
        {
        localStorage.setItem('ques', JSON.stringify({safety:safety, security:security, wellness:wellness,driveway:driveway, shed: shed, pet: pet, temp_user:temp_user, house_type:house_type, no_people:no_people, no_floors:no_floors, no_entdoors:no_entdoors, no_bedrooms:no_bedrooms,isMotionSensor:isMotionSensor}))
            addQuestion({ safety, security, wellness,driveway, shed, pet, temp_user, house_type, no_people, no_floors, no_entdoors, no_bedrooms, isMotionSensor })
            .then(data => {
                if(data.error) {
                    setValues({...values, error: data.error, success:false,btnloading:false})
                } else {
                    setValues({
                        ...values,
                        safety: '',
                        security:'',
                        wellness: '',                 
                        driveway: '',
                        shed: '',
                        temp_user:'',
                        house_type: '',
                        no_people: '',
                        no_floors:'',
                        no_entdoors: '',
                        no_bedrooms: '',
                        isMotionSensor:'',
                        error: '',
                        btnloading:false,
                        success: true
                    });
                    window.location.href="/step3";
                }
            })
        }
        else if(!checkhouse)
        {
            setValues({...values, error: 'Please choose type of property that you would like to protect',btnloading:true });
        }
        else if(!check_policy)
        {
            setValues({...values, error: 'Please check SafelyTeam Privacy Policy box to proceed',btnloading:true });
        }
    }

    const checkLocal = () => {
        const quesData = JSON.parse(localStorage.getItem('ques'));   
        if(localStorage.getItem('ques')) {
                setValues({
                    safety: quesData.safety,
                    security: quesData.security,
                    wellness: quesData.wellness,
                    temp_user: quesData.temp_user,
                    house_type: quesData.house_type,
                    no_people: quesData.no_people,
                    no_floors:quesData.no_floors,
                    no_entdoors: quesData.no_entdoors,
                    no_bedrooms: quesData.no_bedrooms,
                    driveway: quesData.driveway,
                    isMotionSensor: quesData.isMotionSensor,
                    pet: quesData.pet,
                    shed: quesData.shed,
                })
                setPetModal(quesData.pet);
                if(quesData.house_type!=='' && quesData.house_type!==undefined )
                {
                    setCheckHouse(true);
                }
                else {
                    setCheckHouse(false);
                }
                setLoading(false);
        } 
        else {
            window.location.href="/step1/";
        }
        
    } 

    useEffect(() => {
        checkLocal()
     }, [])

     const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => (
        success && <Redirect to="/step3" /> 
    );
    const checkActiveBtn = (filterName) => {
        return (filterName === house_type) ? "btn btn-primary mr-2 mb-3 white-but-qu wid-40-per active" : "btn btn-primary mr-2 mb-3 white-but-qu wid-40-per";
      }
     
    const errorStyle = {
        color: 'red',      
      };
      const renderTooltip1 = (props) => (
        <Tooltip id="button-tooltip" {...props}>
         We ask this question to estimate the size of your home so that we can add the most appropriate sensors to your package.​
        </Tooltip>
      );
      const renderTooltip2 = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          We ask this so that we can estimate the size of your home. This helps us work out how many system range extenders you require.​
        </Tooltip>
      );
      const renderTooltip3 = (props) => (
        <Tooltip id="button-tooltip" {...props}>
         This question helps us identify how many users will need a key tag to enter your property and the amount of panic buttons that we will provide you with.​
        </Tooltip>
      );
      const renderTooltip4 = (props) => (
        <Tooltip id="button-tooltip" {...props}>
         For maximum home protection, it is recommended that your home has one smoke alarm and one motion sensor per floor. ​
        </Tooltip>
      );
      const renderTooltip5 = (props) => (
        <Tooltip id="button-tooltip" {...props}>
         For ultimate protection and peace of mind, we recommend placing a contact sensor on all easily accessible doors and windows in your home. 
        </Tooltip>
      );

      const showPetModal = () => {
          return (
            <Modal show={petModal} onHide={() => setPetModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h4>Please note that your motion sensors may be triggered by pets such as cats or dogs. Place
your motion sensors in areas where your pets are not active, to avoid false alarms.</h4>
<p>If you would like to remove your motion sensors from your customised bundle please tick
here <input onChange={handleChange('isMotionSensor')} style={{height: '1.5rem',  width: '4%',  marginTop: '1px'}} type="checkbox" id="popup" checked={isMotionSensor} value={isMotionSensor}  /></p>
<p className="col-md-1"> </p>
                        <span>
                        


                        </span>
                    </div>
                </div>
            </div>
            </Modal.Body>

        </Modal>
          )

      }

    return (

        <Fragment style={{overflowY: 'scroll', overflowX: 'hidden'}}>
            <Helmet>
                <title>Build Your Bespoke Home Security System | SafelyTeam​</title>
            </Helmet>
        
           {showPetModal()}

            <div className="section pp-scrollable slide-dark slide-dark-footer">
                


                <div className="col-md-12 top-det-sp-in plr-0px px-0">
                    <div className="col-md-12 inner-safely" style={{paddingRight: '14px'}}>
                        <div className="container pt-top-gyap plr-0px">
                            <div className="col-md-12 mb-4 plr-0px">

                             
                               
                                <div className="col-md-12 plr-0px">

                                    <div className="col-md-12 plr-0px">
                                        <div className="form-check">
                                            <label className="form-check-label">
                                                <input onChange={handleChange('check_policy')} type="checkbox" id="usr" checked={check_policy} value={check_policy}  /> <h5>We will process your personal data in accordance with the <Link to={`/privacy`} style={{textDecoration:"underline"}} target="_blank">SafelyTeam Privacy Policy.</Link> This sets out our use of your data collected during the order process and after that, as part of our service.</h5>
                                            </label>
                                        </div>
                                        <hr style={{borderTop: 'dotted 1px'}} />
                                        <br/>
                                    </div>

                              
                                    <div className="col-md-12 plr-0px">
                                        1. What type of property would you like to protect?  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}  overlay={renderTooltip1}>
                                                <i className="fa fa-info-circle blue-acti" aria-hidden="true" style={{float:'none'}}></i>
                                            </OverlayTrigger> <b style={{color:'red'}}> *</b>
                                       

                                    </div>
                                    <div className="col-md-12 pt-3 wht-type-btm-gyap">
                                        <button className={checkActiveBtn('0')} onClick={handleChange('house_type')} value="0" type="submit">Flat</button>
                                        <button className={checkActiveBtn('1')} onClick={handleChange('house_type')} value="1" type="submit">Terraced</button>
                                        <button className={checkActiveBtn('2')} onClick={handleChange('house_type')} value="2" type="submit">Detached</button>
                                        <button className={checkActiveBtn('3')} onClick={handleChange('house_type')} value="3" type="submit">Semi-Detached</button>
                                        <button className={checkActiveBtn('4')} onClick={handleChange('house_type')} value="4" type="submit">Bungalow</button>
                                    </div>                                 
                               
                                  
                                    <div className="col-md-12">
                                        2. How many bedrooms does your home have? <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}  overlay={renderTooltip2}>
    <i className="fa fa-info-circle blue-acti" aria-hidden="true" style={{float:'none'}}></i>
  </OverlayTrigger>
                                    </div>
                                    <div className="col-md-12 pt-3 wht-type-btm-gyap">
                                        <div className="btn-group">                                          
                                    
                                            <button type="button" onClick={no_bedrooms_DecreaseItem('no_bedrooms')} className="btn btn-primary gro-btn">-</button>  
                                            <button type="button" className="btn btn-primary gro-btn">{no_bedrooms}</button>
                                            <button type="button" onClick={no_bedrooms_IncrementItem('no_bedrooms')} className="btn btn-primary gro-btn">+</button>
                                            
                                        </div>
                                        <p className="required" style={errorStyle}>{message1}</p>
                                    </div>                                                             

                                                                   
                                    <div className="col-md-12">
                                        3. How many people have regular access to your home? <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}  overlay={renderTooltip3}>
    <i className="fa fa-info-circle blue-acti" aria-hidden="true" style={{float:'none'}}></i>
  </OverlayTrigger>
                                    </div>
                                    <div className="col-md-12 pt-3 wht-type-btm-gyap"> 
                                        <div className="btn-group">
                                            <button type="button" onClick={no_people_DecreaseItem('no_people')} className="btn btn-primary gro-btn">-</button>
                                            <button type="button" className="btn btn-primary gro-btn">{no_people}</button>
                                            <button type="button" onClick={no_people_IncrementItem('no_people')} className="btn btn-primary gro-btn">+</button>
                                           
                                        </div>
                                        <p className="required" style={errorStyle}>{message2}</p>
                                    </div>
                                                                   
                                    
                                    <div className="col-md-12">
                                        4. How many floors does your home have? <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}  overlay={renderTooltip4}>
    <i className="fa fa-info-circle blue-acti" aria-hidden="true" style={{float:'none'}}></i>
  </OverlayTrigger>
                                    </div>
                                    <div className="col-md-12 pt-3 wht-type-btm-gyap">
                                        <div className="btn-group"> 
                                            <button type="button" onClick={no_floors_DecreaseItem('no_floors')} className="btn btn-primary gro-btn">-</button>
                                            <button type="button" className="btn btn-primary gro-btn">{no_floors}</button>
                                            <button type="button" onClick={no_floors_IncrementItem('no_floors')} className="btn btn-primary gro-btn">+</button>
                                           
                                        </div>
                                        <p className="required" style={errorStyle}>{message3}</p>
                                    </div>                               
                               
                         
                                    <div className="col-md-12">
                                        5. How many entrance doors or access points (including windows)  would you like to secure? <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}  overlay={renderTooltip5}>
    <i className="fa fa-info-circle blue-acti" aria-hidden="true" style={{float:'none'}}></i>
  </OverlayTrigger>
                                    </div>
                                    <div className="col-md-12 pt-3 wht-type-btm-gyap">
                                        <div className="btn-group">
                                            <button type="button" onClick={no_entdoors_DecreaseItem('no_entdoors')} className="btn btn-primary gro-btn">-</button>
                                            <button type="button" className="btn btn-primary gro-btn">{no_entdoors}</button>
                                            <button type="button" onClick={no_entdoors_IncrementItem('no_entdoors')} className="btn btn-primary gro-btn">+</button>
                                           
                                        </div>
                                        <p className="required" style={errorStyle}>{message4}</p>
                                    </div>

                                    <div className="col-md-12">
                                    6. Do you have a pet?  
                                    </div>
                                    <div className="col-md-12 pt-3 wht-type-btm-gyap">
                                    <input style={{height:'1.5rem'}} onChange={handleChange('pet')} className="form-control col-md-1"  type="checkbox" id="usr" checked={pet} value={pet}  /> 
                                    </div>

                                    
                            
                                    {/* <div className="col-md-12">
                                        6.  How many entrance doors or access points (including windows) does your home have?
                                    </div>
                                    <div className="col-md-12 pt-3 mb-5">
                                        <div className="btn-group">
                                            <div className="row">
                                                <div className="col-md-4 wid-30-per">
                                                    <div className="row">
                                                        <div className="col-md-1">
                                                            <input value={driveway} name="driveway" defaultChecked={driveway} onChange={handleChange('driveway')} id="styled-checkbox-6" type="checkbox" />
                                                            <label for="styled-checkbox-6"></label>
                                                        </div>
                                                        <div className="col-md-3">
                                                            Driveway
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 wid-30-per">
                                                    <div className="row">
                                                        <div className="col-md-1">
                                                            <input className="styled-checkbox2"  value={pet} name="pet" defaultChecked={pet} onChange={handleChange('pet')}  id="styled-checkbox-7" type="checkbox" />
                                                            <label for="styled-checkbox-7"></label>
                                                        </div>
                                                        <div className="col-md-3">
                                                            Pet
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 wid-30-per">
                                                    <div className="row">
                                                        <div className="col-md-1">
                                                            <input className="styled-checkbox2"  defaultChecked={shed} name="shed" onChange={handleChange('shed')}  id="styled-checkbox-8" type="checkbox" />
                                                            <label for="styled-checkbox-8"></label>
                                                        </div>
                                                        <div className="col-md-3">
                                                            Shed
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                    </div> */}
                                </div>
                            </div>



                        </div>
                        {showSuccess()}
                                {showError()}
                        <div className="row">
                            <div className="col-md-3 pt-3"><a href="/step1"><img src={require('../img/lt-arr.png')} width="20" alt="" />&nbsp;&nbsp;Go Back</a></div>
                            <div className="col-md-6 text-center">
                                <button style={{fontSize:'18px'}} onClick={clickSubmit} disabled={btnloading} className="btn btn-primary mr-2 mb-3" type="submit">{btnloading ? "Loading..." : "Continue"}</button>
                            </div>
                            <div className="col-md-3 "></div>
                        </div>
                      
                    </div>

                    <Subcribe />
                </div>
            </div>
            <Footer />
            
           
            



            <div className="col-sm-12 pb-30-con animate-element delay2 fadeInUp"
        style={{backgroundColor: '#FFF', position: 'fixed', top: '0px', zIndex: '26'}}>
       <div className="row">
           <div className="col-md-2 go-ba">
           <a href="/">
                                   <img src={require('../img/logo.png')} width="120" alt="" />
                                </a>
              </div>
           <div className="col-md-8 pt-4 text-center">
               <ul className="list-inline text-center mb-0">
                   <li className="list-inline-item top-list-bread">
                       <a href="/step1">
                           <i className="icofont-simple-right"></i> Select your priorities - (Step 1 of 3)
                       </a>
                   </li>
                   <li className="list-inline-item top-list-bread">
                       <a href="/step2" className="blue-acti">
                           <i className="icofont-simple-right"></i>Custom details
                           - (Step 2 of 3)
                       </a>
                   </li>
                   <li className="list-inline-item top-list-bread">
                       <a href="#">
                           <i className="icofont-simple-right"></i>Review Package
                           - (Step 3 of 3)
                       </a>
                   </li>

                   <Backdrop className={classes.backdrop} open={loading} >
                        <CircularProgress color="inherit" />
                    </Backdrop>  

               </ul>
           </div>
       </div>

   </div>
           </Fragment>

      
    )
}

export default Step2
