import React,{useState, useRef}  from 'react'
import Subscribe from './Subscribe'
import {getCrime} from './Priorities/apiUser'
import NewLayout from './NewLayout'

const Crime = () => {

  const [values, setValues] = useState({
    data1:'',
    btnloading:false
  });

  let [others, setOthers] = useState(0);
  let [theft, setTheft] = useState('');
  let [vehicle, setVehicle] = useState('');
  let [violent, setViolent] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [btnloading1, setLoading] = useState(false) 
  const {data1, btnloading} = values;

  const [crimes, setCrimes] = useState([]);
  const [categories, setCategories] = useState([])
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   
  const myload = useRef(null)

  const handleChange = name => event => {
    setValues({ ...values, error: false, [name]: event.target.value,btnloading:false});
  };

  const clickSubmit = (event) => {
    event.preventDefault();
    setValues({...values, error: false,btnloading:true });
    getCrime({data1})
    .then(data => {
        if(data.error) {
            setValues({...values,btnloading:false})
        } else {
            setValues({
                ...values,
                btnloading:false
            })
            setCrimes(data);
            if(data.length>=1)
            {
            let cat=JSON.stringify(data.reduce((p,i) => (p[i.category] = (p[i.category] || 0) +1, p),{}));
            cat= cat.replace("{", "").replace("}", "").replace(/['"]+/g, '');
            var catarray = cat.split(",");
            var tempName="";
            var tempTotal=0;
            var tempOthers=0;
            var tempTheft=0;
            var tempVehicles=0;
            var tempViolent=0;
            const uppercaseWords = str => str.replace(/^(.)|\s+(.)/g, c => c.toUpperCase());
            for(var i=0;i<catarray.length;i++)
            {
              
              var catName=catarray[i].split(":");          
              tempName = catName[0];
              tempTotal = parseInt(catName[1]);
              
              if(tempName === "other-crime" || tempName === "drugs" || tempName === "criminal-damage-arson" || tempName === "possession-of-weapons" || tempName === "public-order" || tempName === "all-crime" || tempName === "anti-social-behaviour") 
              {
                tempOthers+= tempTotal;
              } 
              else if(tempName === "bicycle-theft" || tempName === "burglary" || tempName === "other-theft" || tempName === "robbery" || tempName === "shoplifting" || tempName === "theft-from-the-person")
              {
                tempTheft+=tempTotal;
              }
              else if(tempName === "vehicle-crime") 
              {
                tempVehicles+=tempTotal;
              }
              else if(tempName === "violent-crime")
              {
                tempViolent+=tempTotal;
              } else 
              {
                tempOthers+=tempTotal;
              }
             
              //tempName+='<tr><td>'+uppercaseWords(catName[0].replace("-", " ").replace("-", " ").replace("-", " "))+'</td><td>'+catName[1]+'</td></tr>';
            }
            
            setOthers(tempOthers);
            setTheft(tempTheft);
            setVehicle(tempVehicles);
            setViolent(tempViolent);
            setCategories(tempName);
            setSuccess(() => setSuccess(true));
            setError(() => setError(false));
            myload.current.scrollIntoView();
          }
          else
            {
              setCrimes([{category: 'anti-social-behaviour'}]);
              setError(() => setError(true));
              setSuccess(() => setSuccess(false));
              setCategories('<tr><td colspan="2" style="color:red">Sorry! No results found in this location.</td></tr>');
              //scrollToRef(myload);             
              myload.current.scrollIntoView();
            }
       
        }
    })
  }

  const clickSubmit1 = (data1) => {  
    setLoading(true);
    setValues({...values,error: false,btnloading:true });
    getCrime({data1})
    .then(data => {
        if(data.error) {
            setValues({...values,btnloading:false})
            setLoading(false);
        } else {
            setValues({
                ...values,
                btnloading:false
            })
            setCrimes(data);
            if(data.length>1)
            {
            let cat=JSON.stringify(data.reduce((p,i) => (p[i.category] = (p[i.category] || 0) +1, p),{}));
            cat= cat.replace("{", "").replace("}", "").replace(/['"]+/g, '');
            var catarray = cat.split(",");
            var res="";
            var tempName="";
            var tempTotal=0;
            var tempOthers=0;
            var tempTheft=0;
            var tempVehicles=0;
            var tempViolent=0;
            const uppercaseWords = str => str.replace(/^(.)|\s+(.)/g, c => c.toUpperCase());
            for(var i=0;i<catarray.length;i++)
            {
              var catName=catarray[i].split(":");  
              tempName = catName[0];
              tempTotal = parseInt(catName[1]);
              
              if(tempName === "other-crime" || tempName === "drugs" || tempName === "criminal-damage-arson" || tempName === "possession-of-weapons" || tempName === "public-order" || tempName === "all-crime" || tempName === "anti-social-behaviour") 
              {
                tempOthers+= tempTotal;
              } 
              else if(tempName === "bicycle-theft" || tempName === "burglary" || tempName === "other-theft" || tempName === "robbery" || tempName === "shoplifting" || tempName === "theft-from-the-person")
              {
                tempTheft+=tempTotal;
              }
              else if(tempName === "vehicle-crime") 
              {
                tempVehicles+=tempTotal;
              }
              else if(tempName === "violent-crime")
              {
                tempViolent+=tempTotal;
              } else 
              {
                tempOthers+=tempTotal;
              }
              
              //res+='<tr><td>'+uppercaseWords(catName[0].replace("-", " ").replace("-", " ").replace("-", " "))+'</td><td>'+catName[1]+'</td></tr>';
            }
            setOthers(tempOthers);
            setTheft(tempTheft);
            setVehicle(tempVehicles);
            setViolent(tempViolent);
            setCategories(res);
            setSuccess(() => setSuccess(true));
            setError(() => setError(false));
          }
          else
            {
              setCrimes([{category: 'anti-social-behaviour'}]);
              setError(() => setError(true));
              setSuccess(() => setSuccess(false));
              setCategories('<tr><td colspan="2" style="color:red">Sorry! No results found in this location.</td></tr>');
            }
              setLoading(false);
        }
    })
  }

  const showLocations = () => {
    return (
      
        <div className="row justify-content-md-center">
            <div className="col-md-6 px-0"> 

            {success && (
              <div className="pt-3">
               <h4 style={{fontSize: '1.5rem', fontWeight: '500', textAlign:'center'}} className='blue-acti'>Data for the last 3 months</h4>
               <br/>
              <table className="table table-striped table-bordered table-responsive">
              <thead>    
                <tr> 
                    <th>Theft/Robbery</th>
                    <th>Vehicle Crime</th>
                    <th>Violent Crime</th>
                    <th>Other</th>
                  </tr>
                </thead>
                {/* <tbody dangerouslySetInnerHTML={{__html: categories}}>
                  {console.log("Categories",categories)}
               
                </tbody> */}
                <tbody>
                  <tr>
                    <td style={{backgroundColor:'#008cc1',color:'#fff',textAlign:'center'}}>{((theft/(theft+vehicle+violent+others)) * 100).toFixed(2)}%</td>
                    <td style={{backgroundColor:'#fe9600',color:'#fff',textAlign:'center'}}>{((vehicle/(theft+vehicle+violent+others)) * 100).toFixed(2)}%</td>
                    <td style={{backgroundColor:'#1b4080',color:'#fff',textAlign:'center'}}>{((violent/(theft+vehicle+violent+others)) * 100).toFixed(2)}%</td>
                    <td style={{backgroundColor:'#959da5',color:'#fff',textAlign:'center'}}>{((others/(theft+vehicle+violent+others)) * 100).toFixed(2)}%</td>
                  </tr>
                </tbody>
              </table>
              <p className='blue-acti' style={{paddingTop:'20px'}}>Statistics provided by <a href="https://data.police.uk/data/" target="_blank">police.uk.</a> The information and statistics displayed are for informational purposes only and may be subject to change.</p>
              </div>
            )}

            {error && <h3 style={{color:'red'}}>Sorry! No results found in this location.</h3>}  
                   
            </div>
      </div>
    )
  }

  const showError = () => {

  }
 
    return (
        <NewLayout topTitle="Crime in the UK | Reliable Home Protection Partner | SafelyTeam" metaDescription="We have compiled this tool of national crime statistics, so that you can get a better idea of the type and levels of crime in your area and select the best solution for it.">
               
            {(btnloading1) ? <div className='load-con'><div className='loader-container'><div className='loader'></div></div></div> : ""}
                
                        <div className="section pp-scrollable slide-dark slide-dark-footer">                        
                          <div className="col-md-12 px-0 inner-tp-pad inner-safely crime-bg-nw">
                            <div className="container">
                              <div className="col-md-12 crime-in-top-fix">
                              <div className="col-md-10 mx-auto text-center crime-in-btm-fix">
                                <h1 className="white-inner-txt">Crime in the UK </h1>
                                <div className="col-md-12 px-0 mx-auto">
                                  <form className="input-group mb-3" onSubmit={clickSubmit}>
                                    
                                    <input type="text" onChange={handleChange('data1')} value={data1} className="form-control" placeholder="Enter your City" style={{height: '60px', border:'solid 2px #FFF', color: '#000'}} required/>
                                    <div className="input-group-append">
                                        <button disabled={btnloading} className="btn btn-success" type="submit" style={{borderRadius: '0px', border:'solid 2px #FFF', background: 'transparent'}}>{btnloading ? "Loading..." : "Go"}</button>
                                    </div>
                                </form>
                                </div>
                              </div>
                              {/* <h4 className="white-inner-txt" style={{fontSize: '1.5rem', fontWeight: '500'}}>Detect Current Location </h4> */}
                            </div>
                          </div>
                            {/* <img src={require('./img/crime-uk-1.jpg')} className="img-fluid" alt="" />  */}
                            </div>
                          <div className="col-md-12 inner-safely" id="load" ref={myload}>
                            <div className="container ptb-inner">
                                  { showLocations()}
                                  {showError()}
                              <div className="row">
                                <div className="col-md-10 px-0 pt-5">
                                  <h3 className="pb-3">How SafelyTeam Adds Safety To Your Home</h3>
                                  <p className="inner-line">It is a sad truth that crime exists wherever in the world you live. From minor offences to more dangerous and life changing crimes, it is crucial that your family, home and belongings are well looked after.</p>
                                  <p className="inner-line">SafelyTeam understands the importance of home security and builds a system that is tailored to your requirements, home type, and priorities. The various smart sensors ensure that every minute that you spend at home is safe and guarded by the highest quality and most cutting-edge technology.</p>
                                  <p className="inner-line">We have compiled this tool of national crime statistics, so that you can get a better idea of the type and levels of crime in your area. ​</p>
                                </div>
                              </div>
                              
                              <div className="row ptb-inner text-mini-mbile mb-know">
                                <h2 className="font-in-head inner-safely">Top Crime<br/>
                                  Locations in UK </h2>
                              </div>
                              <div className="row ptb-inner px-0">
                                <div className="col-md-3 mb-5 plr-0px text-mini-mbile">
                                <a href="#load" onClick={() => clickSubmit1('London')}>
                                  <div className="col-md-12 px-0">
                                  <img src={require('./img/uk-1.png')} className="img-fluid" alt="Crimes in the UK | Home protection devices" />
                                  </div>
                                  <div className="col-md-12 p-2 text-center london-txt">
                                    <p className="mb-0">London</p>
                                  </div>
                                  </a>
                                </div>
                                
                                <div className="col-md-3 mb-5 plr-0px text-mini-mbile">
                                <a href="#load" onClick={() => clickSubmit1('Manchester')}> 
                                  <div className="col-md-12 px-0">
                                    <img src={require('./img/uk-2.png')} className="img-fluid" alt="Crimes in the UK | Home safety devices for elderly" />
                                  </div>
                                  <div className="col-md-12 p-2 text-center london-txt">
                                    <p className="mb-0">Manchester</p>
                                  </div>
                                  </a>
                                </div>

                                <div className="col-md-3 mb-5 plr-0px text-mini-mbile">
                                <a href="#load" onClick={() => clickSubmit1('Liverpool')}> 
                                  <div className="col-md-12 px-0">
                                    <img src={require('./img/uk-3.png')} className="img-fluid" alt="Crimes in the UK | Home protection devices" />
                                  </div>
                                  <div className="col-md-12 p-2 text-center london-txt">
                                    <p className="mb-0">Liverpool</p>
                                  </div>
                                  </a>
                                </div>

                                <div className="col-md-3 mb-5 plr-0px text-mini-mbile">
                                <a href="#load" onClick={() => clickSubmit1('Birmingham')}> 
                                  <div className="col-md-12 px-0">
                                    <img src={require('./img/uk-4.png')} className="img-fluid" alt="Crimes in the UK | Home safety alarms" />
                                  </div>
                                  <div className="col-md-12 p-2 text-center london-txt">
                                    <p className="mb-0">Birmingham</p>
                                  </div>
                                  </a>
                                </div>

                                <div className="col-md-3 mb-5 plr-0px text-mini-mbile">
                                <a href="#load" onClick={() => clickSubmit1('Leicester')}> 
                                  <div className="col-md-12 px-0">
                                    <img src={require('./img/uk-5.png')} className="img-fluid" alt="Crimes in the UK | Wireless home monitoring system" />
                                  </div>
                                  <div className="col-md-12 p-2 text-center london-txt">
                                    <p className="mb-0">Leicester</p>
                                  </div>
                                  </a>
                                </div>

                                <div className="col-md-3 mb-5 plr-0px text-mini-mbile">
                                <a href="#load" onClick={() => clickSubmit1('Nottingham')}> 
                                  <div className="col-md-12 px-0">
                                    <img src={require('./img/uk-6.png')} className="img-fluid" alt="Crimes in the UK | smart home security automation devices" />
                                  </div>
                                  <div className="col-md-12 p-2 text-center london-txt">
                                    <p className="mb-0">Nottingham</p>
                                  </div>
                                  </a>
                                </div>

                                <div className="col-md-3 mb-5 plr-0px text-mini-mbile">
                                <a href="#load" onClick={() => clickSubmit1('Derby')}> 
                                  <div className="col-md-12 px-0">
                                    <img src={require('./img/uk-7.png')} className="img-fluid" alt="Crimes in the UK | Security systems for accidental fires" />
                                  </div>
                                  <div className="col-md-12 p-2 text-center london-txt">
                                    <p className="mb-0">Derby</p>
                                  </div>
                                  </a>
                                </div>
                                <div className="col-md-3 mb-5 plr-0px text-mini-mbile">
                                <a href="#load" onClick={() => clickSubmit1('Bristol')}> 
                                  <div className="col-md-12 px-0">
                                    <img src={require('./img/uk-8.png')} className="img-fluid" alt="Crimes in the UK | Home security devices for burglars" />
                                  </div>
                                  <div className="col-md-12 p-2 text-center london-txt">
                                    <p className="mb-0">Bristol</p>
                                  </div>
                                  </a>
                                </div>
                                <div className="col-md-3 mb-5 plr-0px text-mini-mbile">
                                <a href="#load" onClick={() => clickSubmit1('Leeds')}> 
                                  <div className="col-md-12 px-0">
                                    <img src={require('./img/uk-9.png')} className="img-fluid" alt="Building Image | NSI Gold Standard Monitoring | Safely" />
                                  </div>
                                  <div className="col-md-12 p-2 text-center london-txt">
                                    <p className="mb-0">Leeds</p>
                                  </div>
                                  </a>
                                </div>     
                              
                                <div className="col-md-12 text-center ptb-inner">
                                  <a className="btn btn-primary mr-2 mb-3" href="/step1">Design your personalised system<i className="lni lni-chevron-right"></i></a>
                                  <a className="btn btn-primary mr-2 mb-3" href="/product-listing">Shop all products <i className="lni lni-chevron-right"></i></a>
                                
                                </div>
                              </div>
                              <div className="row ptb-inner">
                                <div className="col-md-4 px-0 pt-5"> <img src={require('./img/build-btm.jpg')} className="img-fluid" alt="Building Image | 24/7 Remote Monitoring | SafelyTeam" /> </div>
                                <div className="col-md-4 px-0 pt-5"> </div>
                                <div className="col-md-4 px-0 pt-5"> <img src={require('./img/right-man.jpg')} className="img-fluid" alt="Out Door Image | Buy Kitchen Fire Detector" /> </div>
                              </div>


                            </div>
                          </div>
                        
                            <Subscribe />

                        </div>
                       
                   
          
        </NewLayout>
    )

}
export default Crime