import React, {Fragment,useEffect, useState} from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Footer from './Footer'
import './css/style_new.css'
import './vendors/ti-icons/css/themify-icons.css'
import './vendors/base/vendor.bundle.base.css'
import {Helmet} from 'react-helmet'
import {getUser} from '../../admin/apiAdmin'
import {isAuthenticated} from '../../auth/index'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const AdminLayout = ({ className, children, topTitle }) => {

    const classes = useStyles();

    const [loading, setLoading] = useState(true)

    const {user, token} = isAuthenticated()

    const checkAdmin = () => {
        getUser(user._id, token)
        .then(data => {
            if(data.error){
                window.location.href="/login/";
            }
            else {
                if(data.role === 1){
                    setLoading(false);
                }
                else {
                    window.location.href="/login/";
                }
            }
        })
    }


    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "./js/dashboard.js";
        script.async = true;
      
        document.body.appendChild(script);
        checkAdmin()
        return () => {
          document.body.removeChild(script);
        }
      }, []);



    return (
        <Fragment>
            <Helmet>
            ( <title>{`SafelyTeam - ${topTitle}`}</title> )
            </Helmet>
                <div className="container-scroller">
                <Navbar />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                           
                            <div className={className}>
                                {!loading && children}
                            </div> 

                            <Backdrop className={classes.backdrop} open={loading} >
                                <CircularProgress color="inherit" />
                            </Backdrop>  
                                          
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </Fragment>
            

    );
}

export default AdminLayout
