import React,{useState,useEffect,Fragment} from 'react'
import {Helmet} from 'react-helmet'
import Nav from '../Priorities/Nav'
import Footer from '../Footer'
import Subscribe from '../Subscribe'
import { API } from "../../../config";
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import CardSection from '../Priorities/CardSection';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import {isAuthenticated, authenticate} from '../../../auth/index'
import {updateCustomerId, createCardDetail, getActiveCardsById} from '../Priorities/apiUser'

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));


const AdCheckoutCall = () => {

    const [loading, setLoading] = useState(true);
    const stripe = useStripe();
    const elements = useElements();
    const { user,token } = isAuthenticated();
    const classes = useStyles();
    const [error, setError] = useState("")
    const [open, setOpen] = useState(false);

    const [values,setValues] = useState({
        customerName:'',
        email:''
    })
    
    const {customerName,email} = values;

    const [values1,setValues1] = useState({
        price:"",
        title:"",
        orderId:'',
        preConfig:'',
        subType:'',
        adId:''
    })

    const {title,price,preConfig,orderId,subType,adId} = values1;

    const [values3, setValues3] = useState({
        gold_price:'',
        silver_price:'',
        bronze_price:''
    })

    const { gold_price, silver_price, bronze_price} = values3;

    const [ values4, setValues4] = useState({
        silverPrice:'',
        goldPrice:'',
        subPlan:'',
        isCancelled: false
    })

    const { silverPrice, goldPrice, subPlan, isCancelled} = values4;

    const [cards, setCards] = useState([]);

    const [recentCard, setRecentCard] = useState("");

    const loadCards = () => {
        getActiveCardsById({userId:user._id}).then(data => {
            if(data.error){
                console.log(data.error);
                setLoading(false);
            }
            else {
                setCards(data);
                setLoading(false);
            }
        })
    }


    useEffect(() => {
        const tempData = JSON.parse(localStorage.getItem('AdcheckValues'))
        if(localStorage.getItem('AdcheckValues')){
            setValues1({orderId:tempData.order,price:tempData.totPrice,preConfig:tempData.preconfig,subType:tempData.sub_type,title:tempData.title, adId:tempData.adID})
            
            if(localStorage.getItem('AdplanValues')){

                const tempPlanData = JSON.parse(localStorage.getItem('AdplanValues'));
                setValues3({...values, gold_price:tempPlanData.gPrice, silver_price:tempPlanData.sPrice, bronze_price:tempPlanData.bPrice})

            }
        }
        else {
            window.location.href="/revieworder/";
        }

        if(isAuthenticated()){
            setValues({customerName:user.name+' '+user.lastname, email:user.email})
        }
        loadCards()
    },[])

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value});
    };



    const clickSubmit = async (event ) => {
        event.preventDefault();
        setOpen(true);
        let customerId="";
        let paymentMethodId="";
        let secretId="";
        let card;

        if(Number(subType) === 1 || Number(subType) === 0 || Number(subType) === 2){

            const createCustomer = await fetch(`${API}/create-customer`, {
                method:'POST',
                headers: {'Content-Type': 'application/json',},
                body: JSON.stringify({ 
                    name: customerName,
                    email: String(email), 
                    userid: String(user._id)
                })
            });
            
            const customerSession = await createCustomer.json();
           
            customerId=customerSession.id;

            const tempUser = await updateCustomerId(user._id, token, {userid:user._id, customerid:customerId});
    
            if(tempUser.customerid)
            {
                const createSetupIntent = await fetch(`${API}/card-wallet`, {
                    method:'POST',
                    headers: {'Content-Type': 'application/json',},
                    body: JSON.stringify({ 
                        name: customerName,
                        email: String(email), 
                        customer: String(customerId),
                        userid: String(user._id)
                    })
                });
                
                const setupSession = await createSetupIntent.json();
                
                secretId=setupSession.client_secret;
            
                    if (!stripe || !elements) {
                        // Stripe.js has not yet loaded.
                        // Make sure to disable form submission until Stripe.js has loaded.
                        return;
                    }
                
                    const result = await stripe.confirmCardSetup(String(secretId), {
                        payment_method: {
                        card: elements.getElement(CardElement),
                        billing_details: {
                            name: customerName,
                        },
                        }
                    });
            
                    let tempRes = result.setupIntent;
                    
                
                    if (result.error) {
                        setOpen(false);
                        setError(result.error.message);
                    } else {
                    
                        paymentMethodId = tempRes.payment_method;

                        const getPaymentMethod = await fetch(`${API}/get/payment-method/id`, {
                            method:'POST',
                            headers: {'Content-Type': 'application/json',},
                            body: JSON.stringify({                    
                                payment_method:paymentMethodId,
                                userid: String(user._id)
                            })
                        });
        
                        const resultPM = await getPaymentMethod.json();
                          
                        card = resultPM.card
    
                        if(resultPM){

                            const updateCard = await createCardDetail({
                                userId: user._id,
                                card_no: card.last4,
                                payment_method: paymentMethodId,
                                exp_year : card.exp_year,
                                exp_month: card.exp_month,
                                orderId: orderId,
                                brand: card.brand
                            })
        
                            if(updateCard){
                                
                                const createSub = await fetch(`${API}/create/new/subscription`, {
                                    method:'POST',
                                    headers: {'Content-Type': 'application/json',},
                                    body: JSON.stringify({ 
                                        customer: String(customerId),
                                        payment_method:paymentMethodId,
                                        subs_type: String(subType),
                                        userid: String(user._id)
                                    })
                                });
                                
                                const setupSub = await createSub.json();
            
                                if(setupSub.id){
                                    authenticate({token, user:{_id:user._id,prefix:user.prefix,name:user.name,lastname:user.lastname,email:user.email,role:user.role,customerid:customerId,subscriptionid:setupSub.id,subscription_paid:true, isSubCancelled:false}},() => {})
                                    localStorage.removeItem('AdcheckValues');
                                    localStorage.removeItem('AdplanValues');
                                    return window.location.href=`/success?order=${orderId}`+`&preconfig=`+preConfig+`&sub_type=`+subType+`&paymentId=`+setupSub.id+`&subscriptionId=`+setupSub.id+`&adId=`+adId;
                                }
    
                            }  
                        }
                        else {
                            setError("Something went wrong, Enter the correct card details")
                        }
                    
                    }

            }        

        }
    }

    

    const showCheckout = () => {
        return (

            <form onSubmit={clickSubmit}>
                 
                {isAuthenticated() && (user.customerid ? '' :

                <div className="form-group">
                    <label className="text-muted">Name<span style={{color:"red"}}> *</span></label>
                    <input onChange={handleChange('customerName')} type="text" className="form-control" value={customerName} required/>
                </div>
                    
                )}

                {isAuthenticated() && (user.customerid ? '' :
                <div className="form-group">
                    <label className="text-muted">Email<span style={{color:"red"}}> *</span></label>
                    <input onChange={handleChange('email')} type="email" className="form-control" value={email} required/>
                </div>
                    
                )}
    
                <CardSection />
                <br/>
                <button className="btn btn-outline-primary" disabled={!stripe}>Proceed to Payment</button>    
                <br/>
                <br/>                     
            </form>
        )
    }


    const handleCardChange = (e ,paymentMethod) => {
        setRecentCard(paymentMethod)
    }

    const showRecentCards = () => {
        let lblid="radio";
        return (
            <div>
                <h4 className="font-gen font-we-600 color-blk-in">Saved Cards</h4> 
                {cards.map((p,i) => (                   
                    <div className="col-md-10 px-0 mb-3">
                    <div className="col-md-12 bord-line">

                        <div className="row">
                            <div className="col-md-1 wid-5-per">
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input id={lblid+i} type="radio" value={recentCard} onChange={e=> handleCardChange(e,p.payment_method)} className="form-check-input" name="optradio1" />
                                    </label>
                                </div>
                            </div>
                            <label for={lblid+i} className="col-md-11"> 
                                                                <div className="row">
                            <div className="col-md-8 py-3 wid-60-per-in">

                                <h5 className="pb-3 color-blk-in font-one-re">Use this Card</h5>
                                <p className="pb-0 mb-0">
                                <b>Card No:</b>&nbsp;xxxx xxxx xxxx {p.card_no}<br/>
                                <b>Exp month/year:</b>&nbsp;{p.exp_month} / {p.exp_year}<br/>
                                <b>Card Type:</b>&nbsp;{p.brand}<br/>
                                </p>
                            </div>
                            <div className="col-md-3 pt-3 text-right">                             
                            </div>
                            </div>
                            </label>
                          
                        </div>
                    </div>
                </div>
                ))}
            </div>
        )
    }

    const showSub = () => {
        return (

            <div className="col-md-12 bg-summa p-4">
                <h2 className="font-gen font-we-650 color-blk-in pt-0 pb-3 mb-0">Order Summary</h2>
                <div className="row py-3 font-we-600">
                   
                </div> 

                <div className="row font-we-650 py-3 text-dark-gr">
                <div className="col-md-6 font-one-edit text-dark-gr">
                    {(Number(subPlan)===1)?
                 <span>  Gold subscription </span> :
                 (Number(subType)===0)?
                 <span>  Silver subscription  </span>:""
                    }
                </div>
                <div className="col-md-3 font-one-edit text-dark-gr">
                {(Number(subType)===1)?
                 <span>  £{goldPrice} / mo </span> :
                 (Number(subType)===0)?
                 <span>  £{silverPrice} / mo </span>:
                 ""
                    }
                  
                </div>
            </div>
            <hr/>

                <div className="row font-we-650">
                    <div className="col-md-6 font-one-edit-in color-blk-in">
                        Order Total<br/>
                    
                    </div>
                    <div className="col-md-6 font-one-edit-in color-blk-in">
                    
                        {(Number(subType)===1)?
                        <span>  £{goldPrice} / mo </span> :
                        (Number(subType)===0)?
                        <span>  £{silverPrice} / mo </span>:
                        ""
                            }
                
                    </div>
                </div>
                

            </div>

        )
    }

    const showProducts = () => {
        return (
            <div className="col-md-12 bg-summa p-4">
                <h2 className="font-gen font-we-650 color-blk-in pt-0 pb-3 mb-0">Order Summary</h2>
                <div className="row py-3 font-we-600">
                    <div className="col-md-6 font-one-edit dis-bl-text font-we-600">
                       Product:
                    </div>
                    <div className="col-md-6 font-one-edit dis-bl-text font-we-600">
                        {title}
                    </div>
                </div> 

                <div className="row font-we-650 py-3 text-dark-gr">
                <div className="col-md-6 font-one-edit text-dark-gr">
                    {(Number(subType)===1)?
                        <span>  Gold subscription </span> :
                        (Number(subType)===0)?
                        <span>  Silver subscription  </span>:
                        (Number(subType)===2)?
                        <span>  Bronze subscription  </span>:""
                    }
                </div>
                <div className="col-md-3 font-one-edit text-dark-gr">
                    {(Number(subType)===1)?
                        <span>  £{gold_price} / mo </span> :
                        (Number(subType)===0)?
                        <span>  £{silver_price} / mo </span>:
                        (Number(subType)===2)?
                        <span>  £{bronze_price} / mo </span>:
                        ""
                    }
                  
                </div>
            </div>
            <hr/>

                <div className="row font-we-650">
                    <div className="col-md-6 font-one-edit-in color-blk-in">
                        Order Total<br/>
                    
                    </div>
                    <div className="col-md-6 font-one-edit-in color-blk-in">
                    
                        £ 0.00
                
                    </div>
                </div> 

                {(subType===1)?
                  <div className="row"><em style={{textAlign:'right',width:'100%'}}> <hr/>(£{gold_price} / mo recurring payment starts on next month)</em></div> :
                 (subType===0)?
                 <div className="row"><em style={{textAlign:'right',width:'100%'}}> <hr/>(£{silver_price} / mo recurring payment starts on next month)</em></div>:
                 (subType===2)?
                 <div className="row"><em style={{textAlign:'right',width:'100%'}}> <hr/>(£{bronze_price} / mo recurring payment starts on next month)</em></div>:
                 ""
                }
            </div>
        )
    }

    const showError = () => {
        if(error) {
            return <h3 className="text-danger">{error}</h3>
        }
    };

    return (

        <Fragment style={{overflowY: 'scroll', overflowX: 'hidden'}}>
            <Helmet>
                <title>Safely Team - Checkout​</title>
            </Helmet>
            <Nav />

            <div className="section pp-scrollable slide-dark slide-dark-footer">
                <div className="col-md-12 top-det-sp-in plr-0px">
                    <div className="col-md-12 pt-5 text-center">
                        <h3>Payment</h3>                                      
                    </div>
                    {(loading) ? <div className='loader-container'><div className='loader'></div></div> : ""}  
                    <div className="col-md-12 plr-0px">
                        <div className="container plr-0px">
                            <div className="row px-5 pt-5 qty-text pb-2">

                                <div className="col-md-6 plr-0px">
                                   {cards.length >0 && showRecentCards()}

                                    {isAuthenticated() && (user.customerid ? <><br/><h5 className="font-gen font-we-600 color-blk-in">Add New Card</h5></> : <h5 className="font-gen font-we-600 color-blk-in">Enter Card Details</h5>)}
                                    {!loading && showCheckout()}
                                    {showError()}
                                </div>

                                <div className="col-md-6 plr-0px">
                                    {isCancelled ? showSub() : showProducts()}
                                   
                                </div>

                            </div>
                        
                        </div>
                    </div>

                    <Backdrop className={classes.backdrop} open={open} >
                        <CircularProgress color="inherit" />
                    </Backdrop>           
                </div>
                <Subscribe />
                <Footer />
            </div>
        </Fragment> 
                        
    )
}

export default AdCheckoutCall
