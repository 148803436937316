import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { logout } from '../../auth/index'


const Navbar = ({history}) => {
    return (
        
            
            <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                    <a className="navbar-brand brand-logo mr-5" href="/"><img src={require('./images/logo.png')} className="mr-2" alt="logo" /></a>
                    <a className="navbar-brand brand-logo-mini" href="/"><img src={require('./images/logo.png')} alt="logo" /></a>
                </div>
                <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
                    {/* <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
                        <span className="ti-view-list"></span>
                    </button> */}
                    {/* <ul className="navbar-nav mr-lg-2">
                        <li className="nav-item nav-search d-none d-lg-block">
                            <div className="input-group">
                                <div className="input-group-prepend hover-cursor" id="navbar-search-icon">
                                    <span className="input-group-text" id="search">
                                        <i className="ti-search"></i>
                                    </span>
                                </div>
                                <input type="text" className="form-control" id="navbar-search-input" placeholder="Search now" aria-label="search" aria-describedby="search" />
                            </div>
                        </li>
                    </ul> */}
                    <ul className="navbar-nav navbar-nav-right">
                    
                       
                        <li className="nav-item nav-profile dropdown">
                            <a className="nav-link dropdown-toggle" href="#/" data-toggle="dropdown" id="profileDropdown">
                                <img src={require('./images/faces/face.png')} alt="profile" />
                            </a>
                            <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                                <a className="dropdown-item" href="/user/dashboard">
                                    <i className="ti-settings text-primary"></i>
                                    Dashboard
                                </a>
                                <a className="dropdown-item"style={{cursor: 'pointer', color: 'grey'}}
                                 onClick={() => logout(() => {
                                 history.push("/login");
                                })}>
                                <i className="ti-power-off text-primary"></i>
                                    Logout
                                </a>
                                {/* <a className="dropdown-item" 
                                style={{cursor: 'pointer'}} 
                                onClick={() => logout(() => {
                                history.push("/");
                                })
                                }><i className="ti-power-off text-primary"></i>Logout
                            </a> */}
                            </div>  
                        </li>
                    </ul>
                    <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
                        <span className="ti-view-list"></span>
                    </button>
                </div>
            </nav>

       
    )
}

export default withRouter(Navbar);
