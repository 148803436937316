import React, { useState } from 'react'
import { Redirect } from 'react-router-dom';
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import {createCoupon} from '../admin/apiAdmin'

const AddCoupon = () => {

    const [values, setValues] = useState({
        coupon_code:'',
        description:'',
        start_date:'',
        end_date:'',
        coupon_value:'',
        value_type:0,
        customer_type:0,
        btnloading:false,
        coupon_limit:'',    
        error: '',
        success: false
    })

    const { coupon_code, coupon_limit, description,start_date, end_date, coupon_value, value_type,customer_type, btnloading, success, error} = values;

    const { user, token} = isAuthenticated()

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value,btnloading:false});
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: false,btnloading:true });
        createCoupon(user._id,token,{coupon_code,description,coupon_value,value_type,end_date,start_date,customer_type,coupon_limit})
        .then(data => {
            if(data.error) {
                setValues({...values, error: data.error, success:false,btnloading:false})
            } else {
                setValues({
                    ...values,
                    coupon_code:'',
                    description: '',
                    coupon_value: '',
                    value_type: '',
                    end_date: '',
                    start_date:'',
                    customer_type:'',
                    coupon_limit:'',
                    btnloading:false,
                    error: '',
                    success: true
                })
            }
        })
    }

    const couponForm = () => (

        <div className="row">
            <div className="col-md-6">
            <br/>
            <form onSubmit={clickSubmit}>
            <div className="form-group">
                <label className="text-muted">Coupon Code<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('coupon_code')} type="text" className="form-control" value={coupon_code} required/>
            </div>

            <div className="form-group">
                <label className="text-muted">Description</label>
                <textarea onChange={handleChange('description')} className="form-control" value={description}/>
            </div>

            <div className="form-group">
                <label className="text-muted">Start Date<span style={{color:"red"}}> *</span></label>
                
                <input onChange={handleChange('start_date')} type="date"  className="form-control" value={start_date} required/>
            </div>

            <div className="form-group">
                <label className="text-muted">End Date<span style={{color:"red"}}> *</span></label>
                
                <input onChange={handleChange('end_date')} type="date"  className="form-control" value={end_date} required/>
            </div>
            
            <div className="form-group">
                 <label className="text-muted">Coupon Type<span style={{color:"red"}}> *</span></label>
                 <select required
                    onChange={handleChange('value_type')} 
                    className="form-control">
                        <option >Select Value Type</option>
                        <option value="1">By Amount</option>
                        <option value="2">By Percentage</option>
                 </select>
             </div>

            <div className="form-group">
                <label className="text-muted">Coupon Value<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('coupon_value')} type="number" className="form-control" value={coupon_value} required/>
            </div>

            <div className="form-group">
                 <label className="text-muted">Customer Type<span style={{color:"red"}}> *</span></label>
                 <select required
                    onChange={handleChange('customer_type')} 
                    className="form-control">
                        <option >Select Customer Type</option>
                        <option value="1">Prospective Customer</option> 
                        <option value="2">Unpaid Customer</option>
                        <option value="3">All Paid Customers</option>
                        <option value="4">Gold Subscribers</option>
                        <option value="5">Silver Subscribers</option>
                 </select>
             </div>

             <div className="form-group">
                <label className="text-muted">Coupon Limit<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('coupon_limit')} type="number" className="form-control" value={coupon_limit} required/>
            </div>
            

            <button  className="btn btn-outline-primary" disabled={btnloading}> {btnloading ? "Loading..." : "Add"} </button>
        </form>

            </div>
        </div>

        

    )

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => {
        if(success) {
            return <Redirect to="/admin/coupons" />
        }
    };

    return (
        <AdminLayout className="container" topTitle="Add Coupon">
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title" style={{textAlign:'center'}}>Add Coupons</h4>
                            {showSuccess()}
                            {showError()}
                            {couponForm()}
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default AddCoupon
