import React, {useState, useEffect} from 'react'
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import { Redirect } from "react-router-dom"
import { createAd} from './apiAdmin'

const AddAds = () => {

    const { user, token} = isAuthenticated();

    const [btnloading, setBtnloading] = useState(false);

    const [ values, setValues] = useState({
        category_name:'',
        category_description:'',
        category_image: '',
        package_salePrice:'',
        error:'',
        success:false,
        formData:new FormData()
    });

    const { category_name, category_description,package_salePrice, category_image, error, success, formData} = values;

    const handleChange = name => event => {
        const value = name === 'category_image' ? event.target.files[0] : event.target.value;
        formData.set(name, value);
        setValues({ ...values, [name]: value });
      }

    const clickSubmit = event => {
        event.preventDefault();
        setBtnloading(true);
        createAd(user._id, token, formData)
        .then(data => {
            if(data.error) {
                setValues({...values, error: data.error});
                setBtnloading(false);
            }
            else {
                setValues({...values, 
                    category_name:'',
                    category_description:'',
                    category_image:'',
                    success:true});
                setBtnloading(false);
            }
        })
    }

    const adsForm = () => (
        <div className="row">
           
                <div className="col-md-6">
                <form onSubmit={clickSubmit}>
                    <div className="form-group">
                        <label className="text-muted">Category Name<span style={{color:'red'}}> *</span></label>
                        <input onChange={handleChange('category_name')} type="text" className="form-control" value={category_name} required />
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Category Description<span style={{color:'red'}}> *</span></label>
                        <textarea onChange={handleChange('category_description')} type="text" className="form-control" value={category_description} required />
                    </div>         

                    <div className="form-group">
                        <label className="text-muted">Package Sale Price<span style={{color:'red'}}> *</span></label>
                        <input onChange={handleChange('package_salePrice')} type="number" className="form-control" value={package_salePrice} required />
                    </div>

                    <label className="text-muted">Category Image<span style={{color:'red'}}> *</span></label>
                    <div className="form-group">
                        <label className="btn btn-secondary">
                            <input onChange={handleChange('category_image')} type="file" name="category_image" accept="image/*" />
                        </label>
                    </div>
               
                <button className="btn btn-outline-primary" disabled={btnloading}>{btnloading ? "Loading..." : "Add"}</button>
                      
            </form>
            
           </div>
        </div>
    )

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => {
        if(success) {
            return <Redirect to="/admin/ads" />
        }
    };

    return (
        <AdminLayout className="container" topTitle="Add Ads" >

            <div className="row">
                    
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title" style={{textAlign:'center'}}>Add Ads</h4>
                                     
                           {showSuccess()}
                            {showError()}
                            {adsForm()}
                                       
                        </div>
                    </div>
                </div>
           </div>
        </AdminLayout>
    )
}

export default AddAds
