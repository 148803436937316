import React  from 'react'
import Subscribe from './Subscribe'
import NewLayout from './NewLayout'

const HowItWorks = () => {
 
    return (
        <NewLayout topTitle="SafelyTeam – How it Works">
            
               
                        <div className="section pp-scrollable slide-dark slide-dark-footer">                        
                        <div className="col-md-12 px-0 inner-tp-pad inner-safely">
                        <div className="container">
          <div className="col-md-10 inner-header">
            <h1 className="white-inner-txt">How it works </h1>
          
          </div>
        </div>
        <img src={require('./img/security-header.png')} className="img-fluid" alt="" /> </div>
      <div className="col-md-12 inner-safely">
        <div className="container ptb-inner">
           
          <div className="row ptb-inner text-mini-mbile mb-know">
            <div className="col-md-8">
            <h2 className="font-in-head inner-safely">Step-by-step<br/>
              journey to a 24/7<br/>
              protected home</h2>
              </div>
              <div className="col-md-4">
                {/* <p>Don't shoot in dark</p> */}
              </div>
          </div>
          <div className="row ptb-inner px-0">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <img src={require('./img/round-1.png')} className="img-fluid" alt="" />
                </div>
                <div className="col-md-9 pt-5">
                  <h3 className="alerts-txt">Your Priorities Matter </h3>
              <p className="inner-line"><strong>Safety</strong> – Protect your home and family from damage caused by fire, water, smoke and other harm.​<br/>
                <strong>Security </strong> – Protect your family from burglaries and the harm that comes with them.​<br/>
                <strong>Wellness </strong> – Protect your home environment and keep your family healthy. ​
              </p>

                </div>
              </div>

               <div className="row">
                <div className="col-md-3">
                  <img src={require('./img/round-2.png')} className="img-fluid" alt="" />
                </div>
                <div className="col-md-9 pt-5">
                  <h3 className="alerts-txt">Help Us</h3>
              <p className="inner-line">Now that you have chosen your priorities, help us create the optimal system for your home so every aspect of your home life is taken care of.​<br/>
                  We ask you about the following details to build up a picture of the home you live in:​<br/>
                  <strong>Property type​</strong><br/>

                  <strong>Number of bedrooms</strong><br/>​

                  <strong>Number of people with regular access to your home</strong>​<br/>

                  <strong>Number of floors​</strong><br/>

                  <strong>Number of access points into your home that you would like to secure​</strong>
              </p>

                </div>
              </div>

               <div className="row">
                <div className="col-md-3">
                  <img src={require('./img/round-3.png')} className="img-fluid" alt="" />
                </div>
                <div className="col-md-9 pt-5">
                  <h3 className="alerts-txt">Customise to Perfection </h3>
              <p className="inner-line">Bespoke smart home systems are what make SafelyTeam unique. Based on your previous answers, you will be presented with your customised package. This stage provides you with the chance to modify your system and add devices that we may have missed. This way, you get the perfect smart home system built for you, by you. </p>
              <p className="inner-line">Here, you can also select your monthly monitoring plan which will help fully optimise your system and protect your home. The details and prices of each are shown below.​</p>
              <img src={require('./img/subbanner.png')} className="img-fluid" alt="" />
                </div>
              </div>

               <div className="row">
                <div className="col-md-3">
                  <img src={require('./img/round-4.png')} className="img-fluid" alt="" />
                </div>
                <div className="col-md-9 pt-5">
                  <h3 className="alerts-txt">Finishing Touches and Delivery </h3>
              <p className="inner-line">Here, you can finalise your purchase and be ready to order. Just fill out the shipping information, add any discount codes and your perfect system will be on its way. You will receive an order confirmation and the expected delivery date will be shown once pre-configuration has been completed.​
              </p>

                </div>
              </div>

               <div className="row">
                <div className="col-md-3">
                  <img src={require('./img/round-5.png')} className="img-fluid" alt="" />
                </div>
                <div className="col-md-9 pt-5">
                  <h3 className="alerts-txt">Pre-Configuration </h3>
              <p className="inner-line">We will stay in touch through email in every step of your SafelyTeam journey. ​<br/>Pre-configuration is one of the most important steps in designing your SafelyTeam system as it enables you to install your system in less than 10 minutes once it arrives. Pre-configuration is also quick to complete and just involves naming your sensors by rooms in your home. This can be completed immediately after payment. ​<br/>If you choose to complete this later, you will be redirected to the pre-configuration page via a link on your order confirmation email. Once pre-configuration is complete, we can send your system to you.​
              </p>

                </div>
              </div>

               <div className="row">
                <div className="col-md-3">
                  <img src={require('./img/round-6.png')} className="img-fluid" alt="" />
                </div>
                <div className="col-md-9 pt-5">
                  <h3 className="alerts-txt">Welcome To the SafelyTeam Family​ </h3>
              <p className="inner-line">Thank you for being a part of the SafelyTeam. You have taken the first step in investing in your family and home’s Safety, Security & Wellness.​
              <br/>We hope you enjoy your new bespoke system.​<br/>Feel free to contact us through our customer support page at any time.
              </p>

                </div>
              </div>

               <div className="row pt-5">
                <div className="col-md-12">
                  <img src={require('./img/hws.png')} className="img-fluid" alt="" />
                </div>
                
              </div>
            </div>
            
                        
          </div>
          <div className="row ptb-inner">
            <div className="col-md-4 px-0 pt-5"> <img src={require('./img/build-btm.jpg')} className="img-fluid" alt="" /> </div>
            <div className="col-md-4 px-0 pt-5"> </div>
            <div className="col-md-4 px-0 pt-5"> <img src={require('./img/right-man.jpg')} className="img-fluid" alt="" /> </div>
          </div>


        </div>
      </div>
    
                            <Subscribe />

                        </div>
                   
          
        </NewLayout>
    )

}
export default HowItWorks