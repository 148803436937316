import React, {useState, useEffect, Fragment, useRef} from 'react'
import Nav from './Nav'
import Footer from '../Footer'
import Subscribe from '../Subscribe'
import { isAuthenticated } from '../../../auth/index'
import { Redirect,Link } from "react-router-dom"
import { createQuestion, createQuestionuser,createMultiplePreorder } from './apiUser'
import { v4 as uuidv4 } from 'uuid'
import '../css/icofont.min.css'
import {Helmet} from 'react-helmet'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
}));



const Step1 = () => {

    const emailRef = useRef()

    const [values, setValues] = useState({
        temp_user: '',
        safety: false,
        security:false,
        wellness: false,
        safetyclass:'col-md-12 round-gradient1 round-fix-height',
        securityclass:'col-md-12 round-gradient1 round-fix-height',
        wellnessclass:'col-md-12 round-gradient1 round-fix-height',
        error: '',
        btnloading:false,       
        success: false
    });
    
    const { user, token} = isAuthenticated();    

    const {  safety, security, wellness,safetyclass,securityclass,wellnessclass, error,btnloading, success} = values

    const [emailVal, setEmailVal] = useState({
        email:'',
        success_msg:false,
        error_msg:''
    })

    const {email, success_msg, error_msg} = emailVal;

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    }

    const handleChange = name => event => {
        setOpen(false);
        if(name === 'safety') {    
            setValues({...values, error:'', [name]: event.target.checked, safetyclass: 'col-md-12 round-gradient1 round-fix-height '+event.target.checked,btnloading:false})       
         } else if(name === 'security') {
            setValues({...values, error:'', [name]: event.target.checked, securityclass: 'col-md-12 round-gradient1 round-fix-height '+event.target.checked,btnloading:false})
        } else if(name === 'wellness') {
            setValues({...values, error:'', [name]: event.target.checked, wellnessclass: 'col-md-12 round-gradient1 round-fix-height '+event.target.checked,btnloading:false})
        } else{
            setValues({ ...values, error:'', [name]: event.target.value,btnloading:false});   
        }    
        
    };

    const handleChangeEmail = name => event => {
        emailRef.current.style.border = '';
        setOpen(false);
        setEmailVal({...emailVal, [name]:event.target.value, success_msg:false, error_msg:''})
    }


    const clickSubmit = (event) => {
        event.preventDefault();
        const temp = uuidv4();
        setValues({...values, error: false,btnloading:true});
        
         if(isAuthenticated()) {
            createQuestionuser( user._id, token, { safety, security, wellness, temp_user:temp } )
            .then(data => {
                if(data.error) {
                    setValues({...values, error: data.error, success:false,btnloading:false})
                    setOpen(true)
                } else {
                    localStorage.setItem('ques', JSON.stringify({ safety, security, wellness, temp_user:temp,no_people:1,no_floors:1,no_entdoors:1,no_bedrooms:1, driveway:false, pet: false, shed: false }))
                    setValues({
                        ...values,
                        temp_user:'',
                        safety: '',
                        security:'',
                        wellness: '',
                        safetyclass:'',
                        securityclass:'',
                        wellnessclass:'',
                        error: '',
                        btnloading:false,
                        success: true,                        
                    })
                    window.location.href="/step2/";
                }
            })
        } else { 

                // store email to database
                    
                createQuestion( { safety, security, wellness, temp_user:temp, email } )
                .then(data => {
                    if(data.error) {
                        if(data.emailErr){
                            emailRef.current.focus();
                            emailRef.current.style.border = '1px solid red';
                            setValues({...values, error: data.error, success:false,btnloading:false})
                            setOpen(true);
                        }
                        else {
                            setValues({...values, error: data.error, success:false,btnloading:false})
                            setOpen(true);
                        }
                        
                    } else {
                        localStorage.setItem('ques', JSON.stringify({ safety, security, wellness, temp_user:temp,no_people:1,no_floors:1,no_entdoors:1,no_bedrooms:1, driveway:false, pet: false, shed: false }))
                        createMultiplePreorder({email, reg_type:3})
                        .then(data => {
                            if(data.error){
                                setEmailVal({...emailVal, error_msg:data.error, success_msg:false})
                                setOpen(true);
                                setValues({...values,btnloading:false})
                            }
                            else {
                                localStorage.setItem('_guest',JSON.stringify({email}))
                                setValues({
                                    ...values,
                                    temp_user:'',
                                    safety: '',
                                    security:'',
                                    wellness: '',
                                    safetyclass:'',
                                    securityclass:'',
                                    wellnessclass:'',
                                    error: '',
                                    btnloading:false,
                                    success: true,                       
                                })
                                window.location.href="/step2/";

                            }
                        })
                        
                    }
                })      
             
        }
        
    }

     const checkLocal = () => {
        const quesData = JSON.parse(localStorage.getItem('ques'));
       
        if(localStorage.getItem('ques')) {
                setValues({
                    safety: quesData.safety,
                    security: quesData.security,
                    wellness: quesData.wellness,
                    temp_user: quesData.temp_user,
                    safetyclass:'col-md-12 round-gradient1 round-fix-height '+quesData.safety,
                    securityclass:'col-md-12 round-gradient1 round-fix-height '+quesData.security,
                    wellnessclass:'col-md-12 round-gradient1 round-fix-height '+quesData.wellness,
                })
        } 
        
    } 

    useEffect(() => {
       checkLocal()
    }, [])

    /* const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    ); */
    /* const showErrorEmail = () => (
        <div className="alert alert-danger" style={{ display: error_msg ? '' : 'none' }}>
            {error_msg}
        </div>
    ); */

    const showSuccess = () => (
        success && <Redirect to="/step2" /> 
    );
     
    return (
        <Fragment style={{overflowY: 'scroll', overflowX: 'hidden'}}>  
        <Helmet>
            <title>Build Your Bespoke Home Security System | SafelyTeam​</title>
            <meta name="description" content="What are your priorities for your home security? Select them and build a bespoke home security system for your unique home requirements. Stay protected 24/7/365. Buy now" />
          </Helmet>
        
                <div className="section pp-scrollable slide-dark slide-dark-footer">
                    {/* <!-- ---------------------content-section-start----------------- --> */}

                        <div className="col-md-12 top-det-sp-in plr-0px px-0">
                                {showSuccess()}
                                
                            <div className="col-md-12 inner-tp-pad inner-safely" style={{paddingRight: '14px'}}>
                                <div className="container pt-4">
                                    
                                    <div className="col-md-12 text-center mb-4">
                                   
                                        <h2 className="what-are-color mb-mb1 animate-element delay3 fadeInUp">
                                            What are your priorities for your
                                            home?
                                        </h2>
                                        <h4 className="font-wei animate-element delay4 fadeInUp">
                                            Single tap on the section to select
                                            or deselect
                                        </h4>
                                        <h4 className="font-wei animate-element delay4 fadeInUp">(Select all that apply)</h4>
                                    </div>

                                    <div className="row award-list animate-element delay5 fadeInUp">
                                    <div className="col-md-9 mx-auto" >
                                        <div className="row mb-4 animate-element delay4 fadeInUp">
                                            <div className="col-md-1 high-fix">

                                                <input className="styled-checkbox" style={{opacity: 'unset',width:'20px',height:'20px',borderRadius:'3px'}} checked={safety} id="styled-checkbox-1" onChange={handleChange('safety')} type="checkbox" value={safety} />
                                               
                                            </div>
                                            <label htmlFor="styled-checkbox-1" className="col-md-10">
                                            <div className={safetyclass}>
                                                <div className="row">
                                                    <div className="col-md-2 col-sm-2 col-xs-2 safe-ico-pad wid-30-per" style={{paddingTop: '27px'}}>
                                                        <a onmouseout="MM_swapImgRestore()" onmouseover="MM_swapImage('Safely','','img/bg/secu-icon-wh.png',1)"><img id="Safely" src={require('../img/Safety.svg')} width="50" alt="Smart Home Safety Systems In The UK | Wireless Monitoring" /></a>
                                                    </div>
                                                    <div className="col-md-10 col-sm-10 col-xs-10 pl-022 wid-70-per">
                                                        <p className="mb-0 ur-home-hed pt-2" style={{color:'#212121'}}>Safety</p>
                                                        <ul className="pl-4 pt-2" style={{color:'#212121'}}>

                                                            <li className="ur-home-li">Detects and alerts you of Fires, Smoke, Water leaks and Panic alerts.</li>
                                                            <li className="ur-home-li">Integrated with our SafelyTeam Connect app for smart home connectivity.​</li>
                                                            <li className="ur-home-li">Calls and texts you in the event of a fire, flood, or panic alert.​</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            </label>
                                        </div>
                                        <div className="row mb-4 animate-element delay6 fadeInUp">
                                            <div className="col-md-1 high-fix">
                                                <input className="styled-checkbox" style={{opacity: 'unset',width:'20px',height:'20px',borderRadius:'3px'}} checked={security} onChange={handleChange('security')} id="styled-checkbox-2" type="checkbox" value={security} />
                                                
                                            </div>
                                            <label htmlFor="styled-checkbox-2" className="col-md-10">
                                            <div className={securityclass} >
                                                <div className="row">
                                                    <div className="col-md-2 col-sm-2 col-xs-2 safe-ico-pad wid-30-per" style={{paddingTop: '27px'}}>
                                                        <a onmouseout="MM_swapImgRestore()" onmouseover="MM_swapImage('Safely','','img/bg/secu-icon-wh.png',1)"><img id="Safely" src={require('../img/Security.svg')} width="50" alt="Smart Home Security Systemss In The UK | Wireless Monitoring" /></a>
                                                    </div>
                                                    <div className="col-md-10 col-sm-10 col-xs-10 pl-022 wid-70-per">
                                                        <p className="mb-0 ur-home-hed pt-2" style={{color:'#212121'}}>Security</p>
                                                        <ul className="pl-4 pt-2" style={{color:'#212121'}}>

                                                            <li className="ur-home-li">Prevents and alerts you of burglaries.</li>
                                                            <li className="ur-home-li">Professionally monitored accredited to a NSI gold standard.</li>
                                                            <li className="ur-home-li">Arm, disarm and monitor your system with our SafelyTeam Connect App. </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            </label>
                                        </div>
                                        <div className="row mb-4 animate-element delay8 fadeInUp">
                                            <div className="col-md-1 high-fix">
                                                <input className="styled-checkbox" style={{opacity: 'unset',width:'20px',height:'20px',borderRadius:'3px'}} checked={wellness} onChange={handleChange('wellness')} id="styled-checkbox-3" type="checkbox" value={wellness} />
                                             
                                            </div>
                                            <label htmlFor="styled-checkbox-3" className="col-md-10">
                                            <div className={wellnessclass}>
                                                <div className="row">
                                                    <div className="col-md-2 col-sm-2 col-xs-2 safe-ico-pad wid-30-per" style={{paddingTop: '27px'}}>
                                                        <a onmouseout="MM_swapImgRestore()" onmouseover="MM_swapImage('Safely','','img/bg/secu-icon-wh.png',1)"><img id="Wellness" src={require('../img/Wellness.svg')} width="50" alt="Smart Wellness Solutions In The UK | Total Environmental Protection" /></a>
                                                    </div>
                                                    <div className="col-md-10 col-sm-10 col-xs-10 pl-022 wid-70-per">
                                                        <p className="mb-0 ur-home-hed pt-2" style={{color:'#212121'}}>Wellness</p>
                                                        <ul className="pl-4 pt-2" style={{color:'#212121'}}>

                                                            <li className="ur-home-li">Air quality, Temperature and Humidity monitoring.​</li>
                                                            <li className="ur-home-li">Monitor activity and Panic alerts with instant app notifications​.</li>
                                                            <li className="ur-home-li">Professional monitoring team to call or text a family member in an event of a panic alert.​</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            </label>
                                        </div>
                                        {/* Email Part Start */}

                                        {!isAuthenticated() && 
                                            <div className="col-md-6 offset-3 plr-0px">
                                                <div className="row">
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">

                                                        <span style={{backgroundColor:'#21dfde'}} className="input-group-text get-offer"><span style={{border:'none',color:'white',  fontSize: '0.8rem',fontWeight: '600',background:'none'}}  >Enter your email</span></span>
                                                    
                                                    </div>
                                                    <input style={{borderColor:'#21dfde'}} ref={emailRef} className="form-control input-bord" onChange={handleChangeEmail('email')} type="email" placeholder="eg. example@mail.com" value={email} />
                                                </div>
                                                </div>
                                            </div>
                                            }

                                    </div>
                                </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                    {/* <a href="/"><img src="themes/users/img/lt-arr.png" width="20" alt="" />&nbsp;&nbsp;Go Back</a> */}
                                    </div>
                                    <div className="col-md-6 pt-3 text-center">
                                        <button style={{fontSize:'18px'}} onClick={clickSubmit} disabled={btnloading} className="btn btn-primary mr-2 mb-3" type="submit">{btnloading ? "Loading..." : "Continue"}</button>
                                        {/* {showError()} */}
                                        {/* {showErrorEmail()} */}
                                    </div>
                                    <div className="col-md-3 text-left"></div>
                                </div>
                            </div>

                            <Subscribe />
                        </div>
                        <Footer />
                </div>                
             


                <div className="col-sm-12 pb-30-con animate-element delay2 fadeInUp"
        style={{backgroundColor: '#FFF', position: 'fixed', top: '0px', zIndex: '26'}}>
       <div className="row">
           <div className="col-md-2 go-ba">
           <a href="/"><img src={require('../img/logo.png')} width="120" alt="" /> </a>
              </div>
           <div className="col-md-8 pt-4 text-center">
               <ul className="list-inline text-center mb-0">
                   <li className="list-inline-item top-list-bread">
                       <a href="/step1" className="blue-acti">
                           <i className="icofont-simple-right"></i> Select your priorities - (Step 1 of 3)
                       </a>
                   </li>
                   <li className="list-inline-item top-list-bread">
                       <a href="/#">
                           <i className="icofont-simple-right"></i>Custom details
                           - (Step 2 of 3)
                       </a>
                   </li>
                   <li className="list-inline-item top-list-bread">
                       <a href="/#">
                           <i className="icofont-simple-right"></i>Review Package
                           - (Step 3 of 3)
                       </a>
                   </li>

               </ul>

                <div className={classes.root}>
                                
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                   
                        <Alert onClose={handleClose} severity="error">{error_msg || error}</Alert>
                                                                    
                    </Snackbar>
                </div>
           </div>
       </div>

   </div>



           
       
        </Fragment>
    )
}

export default Step1