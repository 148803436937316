import React, { useState } from 'react'
import { Redirect } from 'react-router-dom';
import AdminLayout from '../theme/admin/AdminLayout'
import {addUser} from '../admin/apiAdmin'
import {isAuthenticated} from '../auth/index'

const AddUser = () => {

    const {user, token} = isAuthenticated();

    const [values, setValues] = useState({
        prefix:'',
        name: '',
        lastname: '',
        email: '',
        phone: '',
        password: '',
        role: '',
        btnloading:false,
        error: '',
        success: false
    });

    const {prefix,name, lastname, email, phone, password,btnloading, role, success, error} = values

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value,btnloading:false});
    };


    const clickSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: false,btnloading:true });
        addUser(user._id, token, {prefix,name, lastname, email, phone, role, password})
        .then(data => {
            if(data.error) {
                setValues({...values, error: data.error, success:false,btnloading:false})
            } else {
                setValues({
                    ...values,
                    prefix:'',
                    name:'',
                    lastname:'',
                    email: '',
                    phone: '',
                    password: '',
                    role: '',
                    btnloading:false,
                    error: '',
                    success: true
                })
            }
        })
    }
 
    const signUpForm = () => (
        <form onSubmit={clickSubmit} >

                <div className="form-group">
                    <label className="text-muted">Title<span style={{color:"red"}}> *</span></label>
                        <select required
                            onChange={handleChange('prefix')} 
                            className="form-control">
                            <option >Select Title</option>
                            <option value="Mr">Mr</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Ms">Ms</option>
                            <option value="Miss">Miss</option>
                            <option value="Dr">Dr</option>
                            <option value="Sir">Sir</option>
                        </select>
                </div>


            <div className="form-group">
                <label className="text-muted">First Name<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('name')} type="text" className="form-control" value={name} required/>
            </div>

            <div className="form-group">
                <label className="text-muted">Last Name<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('lastname')} type="text" className="form-control" value={lastname} required/>
            </div>

            <div className="form-group">
                <label className="text-muted">Email<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('email')} type="email" className="form-control" value={email} required/>
            </div>

            <div className="form-group">
                <label className="text-muted">Phone No<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('phone')} type="number" className="form-control" value={phone} required/>
            </div>

            <div className="form-group">
                 <label className="text-muted">Role<span style={{color:"red"}}> *</span></label>
                 <select required
                    onChange={handleChange('role')} 
                    className="form-control">
                        <option >Select Role</option>
                        <option value="0">User</option>
                        <option value="1">Admin</option>
                 </select>
             </div>

            <div className="form-group">
                <label className="text-muted">Password<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('password')} type="password" className="form-control" value={password} required/>
            </div>
            <button  className="btn btn-outline-primary" disabled={btnloading}> {btnloading ? "Loading..." : "Add"} </button>
        </form>
    );

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => (
        success && <Redirect to="/admin/users" />
    );

    return (
        <AdminLayout className="container" topTitle="Add User">
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title" style={{textAlign:'center'}}>Add User</h4>
           
                                {showSuccess()}
                                {showError()}
                                {signUpForm()}
           
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
    
}
export default AddUser;
