import React, {useState, useEffect, Fragment} from 'react'
import { getProductList, getAllProducts } from './apiUser'
import Footer from '../Footer'
import Subscribe from '../Subscribe'
import {OverlayTrigger,Tooltip} from 'react-bootstrap';
import {Helmet} from 'react-helmet'
import { addItemCount,getCart,updateItem, removeItem1  } from './cartHelpers'
import ShowImage from '../../../admin/ShowImage'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const Step3 = () => {

    const classes = useStyles();

    const [backloading, setBackLoading] = useState(true);

    const [items, setItems] = useState([])
    const [count, setCount] = useState(1);
    const [selprodid, setSelProdId] = useState(1);
    const [products1, setProducts1] = useState([]);
    const [products, setProducts] = useState([]);   
    const [btnloading, setLoading] = useState(true) 
    const [btnloading1, setLoading1] = useState(true)
    const [hidecount, setHideCount] = useState(false);
   
    const init = ids => {
            getAllProducts(ids).then(data => {
            if (data.error) {
                setLoading(false);
                console.log(data.error);
                setBackLoading(false);
            } else {
                // populate the state
                setProducts1(data);   
                setLoading(false);           
            }
           
       });
   };

   const loadProducts = () => {
    getProductList().then(data => {
        if(data.error) {
            console.log(data.error);
            setLoading1(false);
            setBackLoading(false);
        } else {
            setProducts(data);
            setLoading1(false);
            setBackLoading(false);
        }
    });
   
};
const [plans, setPlans] = useState({
    type1: '',
    type2: '',
    subscription_type:-1,
    error:'',
    packageprice:0,
    singlepackageprice:0,
    packagecount:1,
    type1class:'col-md-12 bord-line',
    type2class:'col-md-12 bord-line',      
    success: false
})
const {type1,type2, error,packageprice,singlepackageprice,packagecount, success,type1class,type2class} = plans;

   const [values, setValues] = useState({
    safety: '',
    security:'',
    wellness: '',
    temp_user: '',
    house_type: '',
    no_people: '',
    no_floors:'',
    no_entdoors: '',
    no_bedrooms: '',
    total_price: 0,
    success: false
});

const { safety, security, no_people, no_floors, no_entdoors, no_bedrooms} = values


   const checkLocal = () => {
    const quesData = JSON.parse(localStorage.getItem('ques'));

    if(localStorage.getItem('ads')) {
        localStorage.removeItem("ads");
    }  

    if(localStorage.getItem('adpackage')) {
        localStorage.removeItem("adpackage");
    } 
    
    if(localStorage.getItem('adpackageQnty')) {
        localStorage.removeItem("adpackageQnty");
    } 
   
    if(localStorage.getItem('ques')) {
            setValues({
                safety: quesData.safety,
                security: quesData.security,
                wellness: quesData.wellness,
                temp_user: quesData.temp_user,
                house_type: quesData.house_type,
                no_people: quesData.no_people,
                no_floors: quesData.no_floors,
                no_entdoors: quesData.no_entdoors,
                no_bedrooms: quesData.no_bedrooms            
            })   
           
            init(SetQuestions(quesData.safety,quesData.security,quesData.wellness,quesData.isMotionSensor));     
        } 
        else 
        { window.location.href="/step1";}
        
    }
    
    const SetQuestions = (safety,security,wellness,pet) => {
        let list=[];
        if((safety)&&(security)&&(wellness)) //Safety, Security and Wellness
        {
            if(pet){
                list = ["5f773c5c541f443e5ce64aaa","5f9d650ff268b98214e737bf","5f7b2d5bffa2800728e971b5","5f7b2f0bffa2800728e971bf","5f9d6592f268b98214e737c1","5f7b2dc6ffa2800728e971b7","5f7b2e6fffa2800728e971bb","5f7b2f74ffa2800728e971c1","5f7b2e1effa2800728e971b9","5f7b302bffa2800728e971c5","5f7b2fc3ffa2800728e971c3"];
            }
            else {
                list = ["5f773c5c541f443e5ce64aaa","5f9d650ff268b98214e737bf","5f7b2d5bffa2800728e971b5","5f7b2f0bffa2800728e971bf","5f9d6592f268b98214e737c1","5f7b2ec3ffa2800728e971bd","5f7b2dc6ffa2800728e971b7","5f7b2e6fffa2800728e971bb","5f7b2f74ffa2800728e971c1","5f7b2e1effa2800728e971b9","5f7b302bffa2800728e971c5","5f7b2fc3ffa2800728e971c3"];
            }
            
        }
        else if((safety)&&(security)&&(!wellness))//Safety and Security
        {
            if(pet){
                list = ["5f773c5c541f443e5ce64aaa","5f9d650ff268b98214e737bf","5f7b2d5bffa2800728e971b5","5f7b2f0bffa2800728e971bf","5f9d6592f268b98214e737c1","5f7b2dc6ffa2800728e971b7","5f7b2e6fffa2800728e971bb","5f7b2f74ffa2800728e971c1","5f7b2e1effa2800728e971b9","5f7b302bffa2800728e971c5","5f7b2fc3ffa2800728e971c3"];
            }
            else {
                list = ["5f773c5c541f443e5ce64aaa","5f9d650ff268b98214e737bf","5f7b2d5bffa2800728e971b5","5f7b2f0bffa2800728e971bf","5f9d6592f268b98214e737c1","5f7b2ec3ffa2800728e971bd","5f7b2dc6ffa2800728e971b7","5f7b2e6fffa2800728e971bb","5f7b2f74ffa2800728e971c1","5f7b2e1effa2800728e971b9","5f7b302bffa2800728e971c5","5f7b2fc3ffa2800728e971c3"];
            }
            
        }
        else if((safety)&&(!security)&&(wellness))//Safety and Wellness
        {
            if(pet){ // Remove Motion Sensor
                list = ["5f773c5c541f443e5ce64aaa","5f7b2d5bffa2800728e971b5","5f7b2f0bffa2800728e971bf","5f7b2dc6ffa2800728e971b7","5f7b2e6fffa2800728e971bb","5f7b2f74ffa2800728e971c1","5f7b2e1effa2800728e971b9","5f7b302bffa2800728e971c5","5f7b2fc3ffa2800728e971c3"];
            }
            else {
                list = ["5f773c5c541f443e5ce64aaa","5f7b2d5bffa2800728e971b5","5f7b2f0bffa2800728e971bf","5f7b2ec3ffa2800728e971bd","5f7b2dc6ffa2800728e971b7","5f7b2e6fffa2800728e971bb","5f7b2f74ffa2800728e971c1","5f7b2e1effa2800728e971b9","5f7b302bffa2800728e971c5","5f7b2fc3ffa2800728e971c3"];
            }      
        }
        else if((!safety)&&(security)&&(wellness))//Security and Wellness
        {
            if(pet){
                list = ["5f773c5c541f443e5ce64aaa","5f9d650ff268b98214e737bf","5f7b2d5bffa2800728e971b5","5f7b2f0bffa2800728e971bf","5f9d6592f268b98214e737c1","5f7b2dc6ffa2800728e971b7","5f7b2e1effa2800728e971b9","5f7b2fc3ffa2800728e971c3"];
            }
            else {
                list = ["5f773c5c541f443e5ce64aaa","5f9d650ff268b98214e737bf","5f7b2d5bffa2800728e971b5","5f7b2f0bffa2800728e971bf","5f9d6592f268b98214e737c1","5f7b2ec3ffa2800728e971bd","5f7b2dc6ffa2800728e971b7","5f7b2e1effa2800728e971b9","5f7b2fc3ffa2800728e971c3"];
            }
            
        }
        else if((safety)&&(!security)&&(!wellness))//Safety
        {
            if(pet){
                list = ["5f773c5c541f443e5ce64aaa","5f7b2f0bffa2800728e971bf","5f7b2dc6ffa2800728e971b7","5f7b2e6fffa2800728e971bb","5f7b2f74ffa2800728e971c1","5f7b2e1effa2800728e971b9","5f7b302bffa2800728e971c5","5f7b2fc3ffa2800728e971c3"];
            }
            else {
                list = ["5f773c5c541f443e5ce64aaa","5f7b2f0bffa2800728e971bf","5f7b2ec3ffa2800728e971bd","5f7b2dc6ffa2800728e971b7","5f7b2e6fffa2800728e971bb","5f7b2f74ffa2800728e971c1","5f7b2e1effa2800728e971b9","5f7b302bffa2800728e971c5","5f7b2fc3ffa2800728e971c3"];
            }
            
        }
        else if((!safety)&&(!security)&&(wellness))//Wellness
        {
            if(pet){
                list = ["5f773c5c541f443e5ce64aaa","5f7b2f0bffa2800728e971bf","5f7b2dc6ffa2800728e971b7","5f7b2e1effa2800728e971b9","5f7b2fc3ffa2800728e971c3"];
            }
            else {
                list = ["5f773c5c541f443e5ce64aaa","5f7b2f0bffa2800728e971bf","5f7b2ec3ffa2800728e971bd","5f7b2dc6ffa2800728e971b7","5f7b2e1effa2800728e971b9","5f7b2fc3ffa2800728e971c3"];
            }
            
        }
        else if((!safety)&&(security)&&(!wellness))//Security
        {
            if(pet){
                list = ["5f773c5c541f443e5ce64aaa","5f9d650ff268b98214e737bf","5f7b2d5bffa2800728e971b5","5f7b2f0bffa2800728e971bf","5f9d6592f268b98214e737c1","5f7b2fc3ffa2800728e971c3"];
            }
            else {
                list = ["5f773c5c541f443e5ce64aaa","5f9d650ff268b98214e737bf","5f7b2d5bffa2800728e971b5","5f7b2f0bffa2800728e971bf","5f9d6592f268b98214e737c1","5f7b2ec3ffa2800728e971bd","5f7b2fc3ffa2800728e971c3"];
            }        
        }
        else
        {   
            list = ["5f7b2dc6ffa2800728e971b7", "5f7b2ec3ffa2800728e971bd","5f7b2f74ffa2800728e971c1", "5f7b2e1effa2800728e971b9","5f9d6592f268b98214e737c1","5f7b2f0bffa2800728e971bf"];
        }
        return list;    
    }
    useEffect(() => {         
        checkLocal();    
        loadProducts();   
        const packData1 = JSON.parse(localStorage.getItem('package'));
        if(localStorage.getItem('package')){
            setPlans({packageprice:[packData1.packageTotprice],singlepackageprice:[packData1.packageTotprice],packagecount:1})
        }
      
    }, []);  

   useEffect(() => {  
    setItems(getCart());
      
}, [count]);   
useEffect(() => {
       
}, [items])
useEffect(() => {  
    setItems(getCart());
    
}, [selprodid]);  

        

//Package Add to cart
const clickSubmit = (event) => {
    event.preventDefault();
        setValues({
          ...values,          
          success: true,
          redirectTorevieworder: true
      })

      let  tot=0;
      let packageproducts="";
      let packageprice="";
      let packagetempPrice="";
      let packagetempName="";
      let packageproductid="";
      let packageQuantity="";
      let packagetempQuantity="";
      let packageName="";
      products1.map(p => {
          let qty=1;
          if(p._id==='5f9d6592f268b98214e737c1'){ //Key Cards
              tot=(Number(tot)+(Number(no_people)*Number(p.sale_price)));
              qty=no_people;
          }
          else if(p._id==='5f7b2e1effa2800728e971b9' && no_people>2) //Panic Button
          { 
              tot=(Number(tot)+(Number(p.sale_price)*2));
              qty=2;
          }      
          else if(p._id==='5f7b2ec3ffa2800728e971bd' &&(no_floors>=1) && (security)) //Motion Sensor
          {
                tot=(Number(tot)+(Number(p.sale_price)*(Number(no_floors))));
                qty=(Number(no_floors));  
          }          
          else if(p._id==='5f7b2f74ffa2800728e971c1' &&(no_floors>=1)&& (safety)) //Smoke
          {
                tot=(Number(tot)+(Number(p.sale_price)*(no_floors)));
                qty=(Number(no_floors));             
          }
          else if(p._id==='5f7b2f0bffa2800728e971bf' && no_entdoors>=1 && (security))//Contact Sensor
          {
                tot=(Number(tot)+(Number(p.sale_price)*(Number(no_entdoors))));
                qty=(Number(no_entdoors));  
          }
          else if(p._id==='5f7b2fc3ffa2800728e971c3' &&(no_bedrooms>=5))//Smart Plug
          {           
               tot=(Number(tot)+(Number(p.sale_price)*(Math.round(no_bedrooms/3)))); 
               qty=(Math.round(no_bedrooms/3));        
          }
          else
          { tot=(Number(tot)+Number(p.sale_price));
            qty=1;}
          packageproducts+=p.name+",";
          packageprice+=p.sale_price+",";
          packagetempPrice+=p.sale_price+",";
          packageproductid+=p._id+",";
          packageQuantity+=qty+",";
          packagetempQuantity+=qty+",";
          packageName+=qty+" x "+p.name+"<br/>";
      });
      //Set Package Title
      let PackageHead=""; 
       if(security){
            PackageHead="Customised SafelyTeam Package";}
       else if (safety){
            PackageHead="Customised SafelyTeam Package";}
        else{
            PackageHead="Customised SafelyTeam Package";}    
      localStorage.setItem('package', JSON.stringify({ packageproducts:[packageproducts.trim(',')],packagetempName:packageproducts.trim(','),packageprice:[packageprice.trim(',')],packageproductid:packageproductid.trim(','),packageQuantity:[packageQuantity.trim(',')],packagetempPrice:packagetempPrice.trim(','), packagetempQuantity:packagetempQuantity.trim(','),packageTotprice:[tot],packageName:[packageName],packageHead:[PackageHead] })) 
      localStorage.setItem('packageQnty', JSON.stringify({val:1})); 
   window.location.href="/revieworder" 
}

//Get total package value
const getTotal = () => {
    let  tot=0;
  
    products1.map(p => {  
        if(p._id==='5f9d6592f268b98214e737c1'){ //Key Cards
            tot=(Number(tot)+(Number(no_people)*Number(p.sale_price)));            
        }
        else if(p._id==='5f7b2e1effa2800728e971b9' && no_people>2) //Panic Button
        {         tot=(Number(tot)+(Number(p.sale_price)*2));            
        }      
        else if(p._id==='5f7b2ec3ffa2800728e971bd' &&(no_floors>=1) && (security) ) //Motion Sensor
        {
              tot=(Number(tot)+(Number(p.sale_price)*(Number(no_floors))));                
        }          
        else if(p._id==='5f7b2f74ffa2800728e971c1' &&(no_floors>=1) && (safety)) //Smoke
        {
              tot=(Number(tot)+(Number(p.sale_price)*(no_floors)));                       
        }
        else if(p._id==='5f7b2f0bffa2800728e971bf' && no_entdoors>=1 && (security))//Contact Sensor
        {
              tot=(Number(tot)+(Number(p.sale_price)*(Number(no_entdoors))));            
        }
        else if(p._id==='5f7b2fc3ffa2800728e971c3' &&(no_bedrooms>=5))//Smart Plug
        {           
             tot=(Number(tot)+(Number(p.sale_price)*(Math.round(no_bedrooms/3))));                 
        }       
        else
        { tot=(Number(tot)+Number(p.sale_price));}       
    });    
    return tot;
   
};

//Package items formed
const customizedProduct = () => {  
    return (       
        products1.map((p,i) => (
                    <div className="col-md-6" key={i}>
                         
                        {(p._id==='5f9d6592f268b98214e737c1') ? 
                            <span>{no_people} x {p.name} </span> :
                        (p._id==='5f7b2e1effa2800728e971b9' && no_people>2) ?
                            <span>2 x {p.name} </span>:
                        (p._id==='5f7b2ec3ffa2800728e971bd' &&(no_floors>=1) && (security))?       
                            <span>{no_floors} x {p.name} </span>:
                        (p._id==='5f7b2f74ffa2800728e971c1' &&(no_floors>=1) && (safety))?
                            <span>{no_floors} x {p.name} </span>:        
                        (p._id==='5f7b2f0bffa2800728e971bf' && no_entdoors>=1 && (security))?        
                            <span>{no_entdoors} x {p.name} </span>:          
                        (p._id==='5f7b2fc3ffa2800728e971c3' &&(no_bedrooms>=5))?       
                            <span>{Math.round(no_bedrooms/3)} x {p.name} </span>:      
                            <span>1 x {p.name}</span>}  
                             <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }}  overlay={ <Tooltip id="button-tooltip">
     <p>{p.description}   </p>  <h4>Highlights:</h4>       <p dangerouslySetInnerHTML={{__html: p.highlights}}></p>
    </Tooltip>}>
                             <i className="fa fa-info-circle blue-acti" aria-hidden="true" style={{float:'none',marginLeft:'5px'}}></i>
                          </OverlayTrigger>                              
                    </div>                     
                ))        
            );
                  
}

const packDetails = () => {
        
    return (     
        <tr style={{border:'none'}}> 
            <td colSpan="2" style={{border:'none',width:'69%',padding:'5px',paddingTop: '20px'}}>
            <b>Customised SafelyTeam Package  </b>             
            </td>               
            <td style={{border:'none',width:'14%',padding:'5px',textAlign:'right'}}>                  
                £ {getTotal()}
            </td>      

      </tr>
    )
    };
    const getCartTotal = () => {
        return items.reduce((currentValue, nextValue) => {
            return currentValue + nextValue.count * nextValue.sale_price;
        }, 0);
    };
    const prodDetails = () => {
        return (       
                items.map((product, i) => (                           
                <tr style={{border:'none'}} key={i}>                                  
                   <td style={{border:'none',width:'10%',padding:'5px'}}>
                        <input type="number" style={{padding:'0px',paddingLeft: '10px'}} onChange={updatehandleChange(product._id)}  value={product.count} className="form-control" id="usr" />
                        </td>
                        <td style={{border:'none',width:'69%',padding:'5px'}}>{product.name}</td>                        
                            <td style={{border:'none',width:'14%',padding:'5px',textAlign:'right'}}> 
                        £ {product.count  * product.sale_price}
                        </td>                 
                </tr>          
                    ))
                )
            } 
            const updatehandleChange = productId => event => {
                if(event.target.value == '0')
                {                     
                    setCount(0);
                    removeItem1(productId);
                   
                }  
                else{
                let tempQuan;
                items.map((p,i) => (
                    productId === p._id && (tempQuan = p.quan)
                ))
               
                setCount(event.target.value < 1 ? 1 : event.target.value)
                if(event.target.value >=1 && event.target.value <=tempQuan) {
                    updateItem(productId, event.target.value)
                   
                }
            }
            }
            const handleChange = (e,product) =>{ 
               
                if(e.target.value == '0')
                    {
                        setHideCount(true); 
                        setSelProdId(product._id);  
                        setCount(0);
                        removeItem1(product._id);
                       
                    }  
                else{
                setHideCount(true); 
                setSelProdId(product._id);         
                let tempQuan=product.quan;  
                        setCount(e.target.value < 1 ? 1 : e.target.value)
                        if(e.target.value >=1 && e.target.value <=tempQuan) {
                            removeItem1(product._id);  
                            addItemCount(product, e.target.value);                 
                            //updateItem(product._id, e.target.value)                   
                        }   
                    }  
                     
            };

            const showAddToCart = (product,items) => {     
                const itemsquant = [];
                let selval='0';
                for (var i=0;i<=product.quan;i++) {
                    itemsquant.push(<option key={i}>{i}</option>)
                }
                items.map((p, i) => {
                    if(p._id === product._id) 
                       { selval=p.count;}
                });                
                return ( 
                    <div>                       
                        <div className="pb-2">                           
                      <select id="sel1" name="sellist1" defaultValue={selval} onChange={e => handleChange(e,product)}>
                        {itemsquant}           
                       </select> 
                    </div>  
               {(hidecount && product._id===selprodid) ?                   
               <div className='blue-acti'>Cart updated.</div> :""}
               </div>
               )  
            };
         
    return (      
        <Fragment style={{overflowY: 'scroll', overflowX: 'hidden'}}>
            <Helmet>
                <title>Build Your Bespoke Home Security System | SafelyTeam​</title>
            </Helmet>
            

            <div className="section pp-scrollable slide-dark slide-dark-footer">               

                <div className="col-md-12 inner-tp-pad inner-safely">
                    <div className="container ptb-inner top-det-sp-in">
                        <div className="row border-who">
                            <div className="col-md-6 p-3 text-center"><img src={require('../img/customised-package.jfif')} className="img-fluid" alt="" /></div>
                            <div className="col-md-6 pl-5 pt-2">                               
                                <h3 className="font-hd-on-in">Customised Safely<br/> Package</h3>
                            
                                <h3 className="prod-inclu">Products Included</h3>
                                <div className="row">
                            <div className="col-md-8">
                            <div className="included-detail">
                                <div className="row">
                                {customizedProduct()}
                                {(btnloading) ? <div className='loader-container'><div className='loader'></div></div> : ""}  
                                
                                </div>   
                               
                                </div>
                           </div>
                          
                       </div>         
                                <div className="row pt-3">
                                    <div className="col-md-3 pt-1">
                                        <h3 className="font-hd-on"><span className="included-detail">£</span>{getTotal()}</h3>
                                    </div>
                                    <div className="col-md-6"> </div>
                                </div>
                            </div>
                        </div>

                     

                        <div className="row ">
                        <div className="col-md-12">
                           
                            <center>
                            <div className="col-md-12 py-4">
                                <h2 className="prod-inclu blue-acti" style={{fontSize: '22px',lineHeight: '1.5', borderBottom: '1px solid #dfdfdf'}}>Add more Products</h2>
                                {(btnloading1) ? <div className='loader-container'><div className='loader'></div></div> : ""}  
                            </div>
                            {products.map((product, i) => (
                              
                                <div key={i} className="col-md-12 px-0 btm-bo-air pb-2 mb-3">
                                  
                                    <div className="row pro-list-item">
                                    <div className="col-md-2 p-0 add-more-img-pad small-img">             
                                     <ShowImage  item={product._id}/>
                                    </div>
                                    <div className="col-md-6">
                                    <h4 className="font-gen"><a target='blank' href={'/product-details/'+product._id}>{product.name}</a> &nbsp;&nbsp;&nbsp; </h4>
                                    <p>{product.description}</p><br/>
                                    <p><b>Tips: </b>{product.tips}</p>
                
                                </div>
            <div className="col-md-4">              
            <h4 className="font-gen" style={{textAlign:'center',marginBottom:'0px'}}>£ {product.sale_price}</h4>
                                
           <sub><i>Increase as per your requirements</i> </sub>
           <div className="pt-3" >                  
            {showAddToCart(product,items)}               
                   
                </div>
                {/* <sub><i>Try not to stress. It's anything but difficult to add all the more later, or return ones you needn't bother with.</i></sub> */}
            </div>
        </div>





</div>

                                
                            ))}
                          
                          </center>
</div>
<div className="col-md-12 inner-tp-pad inner-safely">
    <div className="container">
    <div className="row">
<div className="col-md-6"></div>
<div className="col-md-6 bg-summa" style={{float:'right'}}>
<table className="table table-bordered table-responsive table-review" style={{border:'none'}}>
                                               
                                               <tbody style={{border:'none'}}>                        
                                                   {packDetails()}      
                                                   {prodDetails()}
                                               </tbody>
                                           </table>
                         </div>
                         </div>
                         </div>
</div>

                        </div>
                    </div>
                </div>

                <div className="col-md-12 inner-tp-pad inner-safely mb-5">
                <div className="container">
                <div className="row">
<div className="col-md-6"></div>
<div className="col-md-6 bg-summa" style={{float:'right'}}>
                                            <div className="row pt-5">
                                                {/* <div className="col-md-6 pb-3 wid-40-per">
                                                    Shipping
                                                </div>
                                                <div className="col-md-6 pb-3 text-right wid-40-per">
                                                    Free
                                                </div> 
                                                <div className="col-md-6 pb-3 wid-40-per">
                                                    Total
                                                </div>
                                                <div className="col-md-6 pb-3 text-right wid-40-per">
                                                    £{getCartTotal(items)+Number(getTotal())}
                                                </div>
                                               */}
                                            </div>
                                          

                                            <div className="row py-3 mb-5 shipping-bor">
                                                <div className="col-md-6 blue-dis wid-40-per">
                                                    Total
                                                </div>
                                                <div className="col-md-6 blue-dis text-right wid-40-per">
                                                    £{(getCartTotal(items) +Number(getTotal())).toFixed(2)}
                                                </div>                               
                                            </div>

                                            <div className="row py-3 mb-5">
                                                <div className="col-md-12">
                                              
                                       
                                                <button onClick={clickSubmit} className="btn btn-success pull-right mb-3" data-toggle="modal" data-target="#send-request">Add to cart</button>                        
                                                </div>                         
                                            </div></div>
                                      </div></div>
                                        </div>
               
            </div>
            <Subscribe /> 
            <Footer />

              
           



            <div className="col-sm-12 pb-30-con animate-element delay2 fadeInUp"
        style={{backgroundColor: '#FFF', position: 'fixed', top: '0px', zIndex: '26'}}>
       <div className="row">
           <div className="col-md-2 go-ba">
           <a href="/">
                                   <img src={require('../img/logo.png')} width="120" alt="" />
                                </a>
              </div>
           <div className="col-md-8 pt-4 text-center">
               <ul className="list-inline text-center mb-0">
                   <li className="list-inline-item top-list-bread">
                       <a href="/step1">
                           <i className="icofont-simple-right"></i> Select your priorities - (Step 1 of 3)
                       </a>
                   </li>
                   <li className="list-inline-item top-list-bread">
                       <a href="/step2" >
                           <i className="icofont-simple-right"></i>Custom details
                           - (Step 2 of 3)
                       </a>
                   </li>
                   <li className="list-inline-item top-list-bread">
                       <a href="/step3" className="blue-acti">
                           <i className="icofont-simple-right"></i>Review Package
                           - (Step 3 of 3)
                       </a>
                   </li>

                   <Backdrop className={classes.backdrop} open={backloading} >
                        <CircularProgress color="inherit" />
                    </Backdrop>  

               </ul>
           </div>
       </div>

   </div>
        
            </Fragment>
    )
}

export default Step3
