import React, {Suspense, lazy} from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Register from './user/Register';
import Login from './user/Login';
//import Home from './theme/user/Home';

import ForgotPassword from './user/ForgotPassword'
import ResetPassword from './user/ResetPassword'
import Blog from './theme/user/Blog.js'
import Blog1 from './theme/user/Blog1.js'
import Blog2 from './theme/user/Blog2.js'
import Welcome from './admin/Welcome'

import PrivateRoute from './auth/PrivateRoute'
import Dashboard from './user/UserDashboard'
import MyProfile from './theme/user/MyProfile'
import YourOrders from './theme/user/YourOrders'
import AllOrders from './theme/user/AllOrders'
import AllAddress from './theme/user/AllAddress'
import RecentAddress from './theme/user/RecentAddress'
import UpdateAddress from './theme/user/UpdateAddress'
import YourOrderDetails from './theme/user/YourOrderDetails'
import CancelledOrders from './theme/user/CancelledOrders'
import ChangePassword from './theme/user/ChangePassword'
import PendingConfiguration from './theme/user/PendingConfiguration'
import UpdatePackage from './theme/user/UpdatePackage'
import UserPaymentHistory from './theme/user/UserSubscriptionHistory'
import Sitemap from './theme/user/Sitemap'

import AdminRoute from './auth/AdminRoute'
import AdminDashboard from './theme/admin/AdminDashboard'
import UserDashboard from './theme/user/UserDashboard'
import AddCategory from './admin/AddCategory'
import AddUser from './admin/AddUser'
import AddSupplier from './admin/AddSupplier'
import AddProduct from './admin/AddProduct'
import AddNewsletter from './admin/AddNewsletter'
import AddCoupon from './admin/AddCoupon'
import AddAds from './admin/AddAds'
import AddHomePage from './admin/AddHomePage'
import AddPreOrder from './admin/AddPreOrder'
import AddEmailTemplate from './admin/AddEmailTemplate'
import AddReview from './admin/AddReview'

import UpdateCategory from './admin/UpdateCategory'
import UpdateProduct from './admin/UpdateProduct'
import UpdateSupplier from './admin/UpdateSupplier'
import UpdateInventory from './admin/UpdateInventory'
import UpdateUser from './admin/UpdateUser'
import UpdateNewsletter from './admin/UpdateNewsletter'
import UpdateCoupon from './admin/UpdateCoupon'
import UpdatePreOrder from './admin/UpdatePreOrder'
import UpdateEmailTemplate from './admin/UpdateEmailTemplate'
import UpdateReview from './admin/UpdateReview'

import ManageCategories from './admin/ManageCategories'
import ManageProducts from './admin/ManageProducts'
import ManageSuppliers from './admin/ManageSuppliers'
import ManageUsers from './admin/ManageUsers'
import ManageOrders from './admin/ManageOrders'
import ManageNewsletters from './admin/ManageNewsletters'
import ManageContacts from './admin/ManageContacts'
import ManageSubscriptions from './admin/ManageSubscriptions'
import ManageCoupons from './admin/ManageCoupons'
import ManageReturnReq from './admin/ManageReturnReq'
import ManageAds from './admin/ManageAds'
import ManageCards1 from './admin/ManageCards'
import ManagePreorders from './admin/ManagePreorders'
import ManageEmailTemplates from './admin/ManageEmailTemplates'
import ManageReviews from './admin/ManageReviews'

import ProductDetails from './admin/ProductDetails'
import UserDetails from './admin/UserDetails'
import OrderDetails from './admin/OrderDetails'
import Barcode from './admin/Barcode'
import UpdateSubscription from './admin/UpdateSubscription'
import ManageSubscriptionLogs from './admin/ManageSubscriptionLogs'
import SubscriptionDetails from './admin/SubscriptionDetails'
import AdDetails from './admin/AdDetails'
import UpdateAds from './admin/UpdateAds'
import CampaignUrl from './admin/CampaignUrl'
import PreorderDetails from './admin/PreorderDetails'
import ReviewDetails from './admin/ReviewDetails'

import Step1 from './theme/user/Priorities/Step1'
import Step2 from './theme/user/Priorities/Step2'
import Step3 from './theme/user/Priorities/Step3'
import AdStep from './theme/user/Priorities/AdStep'
import Success from './theme/user/Priorities/success'
import Cancel from './theme/user/Priorities/cancel'
import ReviewOrder from './theme/user/Priorities/ReviewOrder'
import ShippingDetails from './theme/user/Priorities/ShippingDetails'

import ProductListing from './theme/user/ProductListing'
import ProdDetails from './theme/user/ProductDetails'

import AboutUs from './theme/user/AboutUs'
import FAQ from './theme/user/FAQ'
import ContactUs from './theme/user/ContactUs'
import Safety from './theme/user/Safety'
import Security from './theme/user/Security'
import Wellness from './theme/user/Wellness'
import Crime from './theme/user/crime'
import HowItWorks from './theme/user/how-it-works'
import PrivacyPolicy from './theme/user/Privacy-and-Policy'
import TermsCondition from './theme/user/Terms-and-Conditions'
import Cookies from './theme/user/Cookies'
import UpdateAdPackage from './theme/user/UpdateAdPackage'
import Checkout from './theme/user/Priorities/Checkout'
import Shipping from './theme/user/Priorities/Shipping'
import PaymentSuccess from './theme/user/Priorities/PaymentSuccess'
import ManageCards from './theme/user/ManageCards'
import AddCards from './theme/user/AddCards'
import ManageSubscriptionCard from './theme/user/ManageSubscriptionCard'

import HomeNew from './theme/user/HomeNew'

import AdReviewOrder  from './theme/user/Ads/AdReviewOrder'
import AdShipping from './theme/user/Ads/AdShipping'
import AdCheckout from './theme/user/Ads/AdCheckout'
import Packages from './theme/user/Packages'

const Home = lazy(() => import ('./theme/user/Home'))
/*const HomeNew = lazy(() => import ('./theme/user/HomeNew'))*/

const Routes = () => {
    return (
        <BrowserRouter>
        <Suspense fallback={<div className="preloader tp-menu-show">
        <div className="three-bounce">
            <img src={require('./theme/user/img/preloader.gif')} width="200" alt="" />
        </div>
    </div>}>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="/home/new" exact component={HomeNew} />
                <Route path="/register" exact component={Register}/>
                <Route path="/login" exact component={Login}/>
                <Route path="/forgotpassword" exact component={ForgotPassword}/>        
                <Route path="/reset/password/:salt/:userId" exact component={ResetPassword}/> 
                <Route path="/blog" exact component={Blog} />  
                <Route path="/blog/home-safety-in-times-of-covid-19" exact component={Blog1} />      
                <Route path="/blog/are-home-security-devices-worth-your-pick" exact component={Blog2} />   
                <Route path="/cookies" exact component={Cookies} />                       

                <Route path="/step1" exact component={Step1}/>
                <Route path="/step2" exact component={Step2}/>
                <Route path="/step3" exact component={Step3}/>
                <Route path="/success1" exact component={Success}/>
                <Route path="/cancel" exact component={Cancel}/>
                <Route path="/adstep" exact component={AdStep} />
                <Route path="/checkout" exact component={Checkout} />
                <Route path="/shipping" exact component={Shipping} />
                <Route path="/success" exact component={PaymentSuccess} />

                <Route path="/source" exact component={AdReviewOrder} />
                <Route path="/ad/shipping" exact component={AdShipping} />
                <Route path="/ad/checkout" exact component={AdCheckout} />
            
              
                <Route path="/revieworder" exact component={ReviewOrder} />
                <Route path="/shippingdetails" exact component={ShippingDetails} />

                <Route path="/product-listing" exact component={ProductListing} />
                <Route path="/product-details/:productId" exact component={ProdDetails} />
                <Route path="/packages" exact component={Packages} />
				 <Route path="/sitemap" exact component={Sitemap} />

                <PrivateRoute path="/user/dashboard" exact component={UserDashboard} />
                <PrivateRoute path="/user1/dashboard" exact component={Dashboard}/>
                <PrivateRoute path="/user/yourorders" exact component={YourOrders} />
                <PrivateRoute path="/user/allorders" exact component={AllOrders} />
                <PrivateRoute path="/user/cancelledorders" exact component={CancelledOrders} />
                <PrivateRoute path="/user/myprofile/:userId" exact component={MyProfile} />
                <PrivateRoute path="/user/alladdress" exact component={AllAddress} />
                <PrivateRoute path="/user/recentaddress" exact component={RecentAddress} />
                <PrivateRoute path="/user/updateaddress/:shippingId/:testValue" exact component={UpdateAddress} />
                <PrivateRoute path="/user/order/details/:orderId" exact component={YourOrderDetails}/>
                <PrivateRoute path="/user/changepassword" exact component={ChangePassword}/>
                <PrivateRoute path="/user/pendingconfiguration" exact component={PendingConfiguration}/>
                <PrivateRoute path="/user/update/package/:orderId" exact component={UpdatePackage}/>
                <PrivateRoute path="/user/update/ads/package/:orderId" exact component={UpdateAdPackage}/>
                <PrivateRoute path="/user/subscription/history" exact component={UserPaymentHistory}/>
                <PrivateRoute path="/user/manage/cards" exact component={ManageCards} />
                <PrivateRoute path="/user/add/cards" exact component={AddCards} />
                <PrivateRoute path="/user/manage/subscription/card" exact component={ManageSubscriptionCard} />

                <AdminRoute path="/admin/welcome/:orderId" exact component={Welcome} />
                <AdminRoute path="/admin/dashboard" exact component={AdminDashboard}/>
                <AdminRoute path="/create/category" exact component={AddCategory}/>
                <AdminRoute path="/create/supplier" exact component={AddSupplier}/>
                <AdminRoute path="/create/product" exact component={AddProduct}/>
                <AdminRoute path="/create/user" exact component={AddUser}/>
                <AdminRoute path="/create/newsletter" exact component={AddNewsletter}/>
                <AdminRoute path="/create/coupon" exact component={AddCoupon}/>
                <AdminRoute path="/create/ads" exact component={AddAds}/>
                <AdminRoute path="/create/preorder" exact component={AddPreOrder} />
                <AdminRoute path="/create/email/template" exact component={AddEmailTemplate} />
                <AdminRoute path="/admin/homepage/settings" exact component={AddHomePage} />
                <AdminRoute path="/create/review" exact component={AddReview} />

                <AdminRoute path="/admin/categories" exact component={ManageCategories}/>
                <AdminRoute path="/admin/suppliers" exact component={ManageSuppliers}/>
                <AdminRoute path="/admin/products" exact component={ManageProducts}/>
                <AdminRoute path="/admin/users" exact component={ManageUsers}/>
                <AdminRoute path="/admin/orders" exact component={ManageOrders} />
                <AdminRoute path="/admin/newsletters" exact component={ManageNewsletters} />
                <AdminRoute path="/admin/contacts" exact component={ManageContacts} />
                <AdminRoute path="/admin/subscriptions" exact component={ManageSubscriptions} />
                <AdminRoute path="/admin/coupons" exact component={ManageCoupons} />
                <AdminRoute path="/admin/return-requests" exact component={ManageReturnReq} />
                <AdminRoute path="/admin/ads" exact component={ManageAds} />
                <AdminRoute path="/admin/cards" exact component={ManageCards1} />
                <AdminRoute path="/admin/preorder/bookings" exact component={ManagePreorders} />
                <AdminRoute path="/admin/email/templates" exact component={ManageEmailTemplates} />
                <AdminRoute path="/admin/reviews" exact component={ManageReviews} />

                <AdminRoute path="/admin/category/update/:categoryId" exact component={UpdateCategory}/>
                <AdminRoute path="/admin/coupon/update/:couponId" exact component={UpdateCoupon} />
                <AdminRoute path="/admin/product/update/:productId" exact component={UpdateProduct}/>
                <AdminRoute path="/admin/supplier/update/:supplierId" exact component={UpdateSupplier}/>
                <AdminRoute path="/admin/update/inventory/:productId" exact component={UpdateInventory}/>
                <AdminRoute path="/admin/user/update/:userId" exact component={UpdateUser}/>
                <AdminRoute path="/admin/subscription/update/:subscriptionId" exact component={UpdateSubscription}/>
                <AdminRoute path="/admin/newsletter/update/:newsletterId" exact component={UpdateNewsletter}/>
                <AdminRoute path="/admin/update/ad/:adsId" exact component={UpdateAds} />
                <AdminRoute path="/admin/update/review/:reviewId" exact component={UpdateReview} />

                <AdminRoute path="/admin/product/details/:productId" exact component={ProductDetails}/>
                <AdminRoute path="/admin/user/details/:userId" exact component={UserDetails}/>
                <AdminRoute path="/admin/order/details/:orderId/:userId" exact component={OrderDetails}/>
                <AdminRoute path="/admin/barcode/:suborderId" exact component={Barcode}/>
                <AdminRoute path="/admin/subscription/details/:userId" exact component={SubscriptionDetails}/>
                <AdminRoute path="/admin/customer-subscriptions" exact component={ManageSubscriptionLogs} />
                <AdminRoute path="/admin/ad/details/:adsId" exact component={AdDetails} />
                <AdminRoute path="/admin/campaign/url/generator" exact component={CampaignUrl} />
                <AdminRoute path="/admin/preorder/details/:preOrdId" exact component={PreorderDetails} />
                <AdminRoute path="/admin/update/email/template/:templateId" exact component={UpdateEmailTemplate} />
                <AdminRoute path="/admin/update/preorder/:preOrderId" exact component={UpdatePreOrder} />   
                <AdminRoute path="/admin/review/details/:reviewId" exact component={ReviewDetails} />       

                <Route path="/aboutus" exact component={AboutUs} />
                <Route path="/faq" exact component={FAQ} />
                <Route path="/contactus" exact component={ContactUs} />
                <Route path="/safety" exact component={Safety} />
                <Route path="/security" exact component={Security} />
                <Route path="/wellness" exact component={Wellness} />
                <Route path="/crime" exact component={Crime} />
                <Route path="/how-it-works" exact component={HowItWorks} />
                <Route path="/privacy" exact component={PrivacyPolicy} />
                <Route path="/terms" exact component={TermsCondition} />  
            </Switch>
            </Suspense>
        </BrowserRouter>
    )
}

export default Routes