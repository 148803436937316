import React, {useState} from 'react'
import { Redirect } from 'react-router-dom';
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import {createReview} from '../admin/apiAdmin'

const AddReview = () => {

    const [values, setValues] = useState({
        reviewTitle:'',
        reviewDescription:'',
        postedBy:'',
        postedDate:'',
        country:'',
        rating:'',
        error:'',
        success:false,
        btnloading:false
    })

    const { reviewTitle, reviewDescription, postedBy, postedDate, country, rating, error, success, btnloading} = values;

    const { user, token} = isAuthenticated()

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value, btnloading:false, success:false});
    };

    const clickSubmit = (e) => {
        e.preventDefault();
        setValues({...values, success:false, error:'', btnloading:true});
        createReview(user._id, token, {reviewTitle, reviewDescription, postedBy, postedDate, rating, country})
        .then(data => {
            if(data.error){
                setValues({...values, success:false, btnloading:false, error:data.error});
            }
            else {
                setValues({
                    ...values,
                    reviewTitle:'',
                    reviewDescription:'',
                    postedBy:'',
                    postedDate:'',
                    country:'',
                    rating:'',
                    error:'',
                    btnloading:false,
                    success: true
                })
            }
        })
    }

    const reviewForm = () => (
        <form onSubmit={clickSubmit}>

            <div className="form-group">
                <label className="text-muted">Title<span style={{color:'red'}}> *</span></label>
                <input type="text" className="form-control" onChange={handleChange('reviewTitle')} value={reviewTitle} required />
            </div>

            <div className="form-group">
                <label className="text-muted">Decription<span style={{color:'red'}}> *</span></label>
                <textarea onChange={handleChange('reviewDescription')} className="form-control" value={reviewDescription} required />
            </div>

            <div className="form-group">
                <label className="text-muted">Posted By<span style={{color:'red'}}> *</span></label>
                <input type="text" className="form-control" onChange={handleChange('postedBy')} value={postedBy} required />
            </div>

            <div className="form-group">
                <label className="text-muted">Posted Date<span style={{color:"red"}}> *</span></label>           
                <input onChange={handleChange('postedDate')} type="date"  className="form-control" value={postedDate} required/>
            </div>

            <div className="form-group">
                <label className="text-muted">Country<span style={{color:'red'}}> *</span></label>
                <input type="text" className="form-control" onChange={handleChange('country')} value={country} required />
            </div>

            <div className="form-group">
                 <label className="text-muted">Rating<span style={{color:"red"}}> *</span></label>
                 <select required
                    onChange={handleChange('rating')} 
                    className="form-control">
                        <option >Select Rating</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                 </select>
             </div>
             <button  className="btn btn-outline-primary" disabled={btnloading}> {btnloading ? "Loading..." : "Add"} </button>
        </form>
    )

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => {
        if(success) {
            return <Redirect to="/admin/reviews" />
        }
    };


    return (
        <AdminLayout className="container" topTitle="Add Trustpilot Review">
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title" style={{textAlign:'center'}}>Add Trustpilot Review</h4>
                            {showSuccess()}
                            {showError()}
                            {reviewForm()}
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default AddReview
