import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Subscribe from './Subscribe'
import {Helmet} from 'react-helmet'
import NewLayout from './NewLayout'

const AboutUs = () => {
 
    return (
        <NewLayout topTitle="SafelyTeam – About Us">
           
                        <section> 
                        <div className="col-md-12 px-0">                       
                        <div className="col-md-12 px-0 inner-tp-pad inner-safely">
        <div className="container abt-text-top-container">
          <div className="col-md-10 inner-header">
            <h1 className="white-inner-txt abt-hd-res">About us </h1>
            
          </div>
        </div>
        <img src={require('./img/abt-us.png')} className="img-fluid" alt=""/> 
        </div>
      <div className="col-md-12 inner-safely">
        <div className="container">
          
          <div className="row ptb-inner">
            <div className="col-md-12 px-0">
              
              <p className="inner-line text-just-abt" style={{textAlign:"justify"}}>We started SafelyTeam after finding that there wasn’t a simple solution for all of our needs. One of our founders has vulnerable parents who need assistance and monitoring to ensure their day-to-day wellness. Another of our team needed a security system that could be installed without drilling holes and running wiring through their home. Another needed a system to ensure the personal safety of them and their family. Together, we realised that all these solutions had huge untapped potential, which when combined into a single offering, could revolutionise the way you live your life. <br></br><br></br>

We started SafelyTeam to provide the ultimate all-in-one solution for your home, without the hassle. That’s why we set out to find people as passionate as us about creating the most innovative solution available today.<br></br><br></br>

Along the way, we have built relationships in many industries, and nurtured our product to life, developing a business open to change and ready to accelerate the integration of cutting-edge technologies into
day-to-day life. <br></br><br></br>

We don’t compromise on our offering, providing a fully bespoke system and experience throughout,
without complex add-ons or inhibitive customer service. <br></br><br></br>

We are the first to unite Safety, Security and Wellbeing solutions under one roof to provide the only place you need to equip you home with the latest smart home devices.  Every stage of our process has been thoroughly thought out to make sure that getting your SafelyTeam system up and running is as hassle free as possible.  Unlike our competitors, installing our devices requires no drilling, wiring or complicated instructions.  Instead, sit back and let us get your bespoke system ready to use right out of the box.   


</p>
            </div>
          </div>
          
          
          <div className="row px-0">
            <div className="col-md-6 pr-ann al-tp plr-0px text-mini-mbile">
              
              <p className="inner-line abt-head-inner safety-secu-sm">SafelyTeam provides Safety, Security and Wellness solutions for you and your home. 

</p>
            </div>
            <div className="col-md-6 mb-5 plr-0px pt-4"> <img src={require('./img/abt-img.png')} className="img-fluid" /> </div>
            
            
          </div>

          <div className="row">
            <div className="col-md-12 px-0">
              
              
            </div>
          </div>
          <div className="row ptb-inner">
            <div className="col-md-4 px-0 pt-5"> <img src={require('./img/build-btm.jpg')} className="img-fluid" /> </div>
            <div className="col-md-4 px-0 pt-5"> </div>
            <div className="col-md-4 px-0 pt-5"> <img src={require('./img/right-man.jpg')} className="img-fluid" /> </div>
          </div>


        </div>
      </div>
   </div>
      </section>
                          

                        <Subscribe />
                      
        </NewLayout>
    )

}
export default AboutUs



