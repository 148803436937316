import React, {useState, useEffect} from 'react'
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import { Redirect,Link } from "react-router-dom"
import { getProduct,updateProduct, getCategories, getSuppliers } from './apiAdmin'
import Select from 'react-select'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const UpdateProduct = ({match}) => {

    const classes = useStyles();

    const [loading, setLoading] = useState(true);

    const { user, token} = isAuthenticated();
    const [values, setValues] = useState({
        prodid: '',
        name: '',
        description: '',
        long_description: '',
        purchase_price: '',
        sale_price: '',
        weight: '',
        suppliers:[],
        supplier: '',
        categories: [],
        category: '',
        inventory: '',
        threshold:'',
        photo: '',
        btnloading: false,
        error: '',
        createdProduct: '',
        redirectToProfile: false,
        formData: ''
    })

    const [selectedValue, setSelectedValue] = useState([]);

    const [categories, setCategories] = useState([]);

    const [suppliers, setSuppliers] = useState([]);

    const { 
        prodid,
        name,
        description,
        long_description,
        purchase_price,
        sale_price,
        weight,
        threshold,
        //suppliers,
        supplier,
        //categories,
        //category,
        inventory,
        //photo,
        btnloading,
        error,
        createdProduct,
        formData
     } = values;


     const init = productId => {
        getProduct(productId).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
                setLoading(false);
            } else {
                // populate the state
                setValues({
                    ...values,
                    prodid: data.prodid,
                    name: data.name,
                    description: data.description,
                    long_description: data.long_description,
                    purchase_price: data.purchase_price,
                    sale_price: data.sale_price,
                    weight: data.weight,
                    supplier: data.supplier._id,
                    category: data.category._id,
                    inventory: data.inventory,
                    threshold: data.threshold,
                    formData: new FormData()
                });
                setSelectedValue(Array.isArray(data.category) ? data.category.map(x => x._id) : []);
                // load categories
                initCategories();
            }
        });
    };

     // load categories and set form data
     const initCategories = () => {
        getCategories().then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
                setLoading(false);
            } else {
                setCategories(data);
                initSuppliers();
            }
        });
    };

     // load suppliers and set form data
     const initSuppliers = () => {
        getSuppliers().then(data => {
            if(data.error) {
               setValues({...values, error: data.error})
               setLoading(false);
            } else {
               setSuppliers(data);
               setLoading(false);
            }
        })
    }

    useEffect(() => {
        init(match.params.productId);
    }, []);

    const data2 = categories.map((c) => (
       
        {
            value:""+[c._id]+"",
            label:""+ [c.name]+""
        }
     ))


     const handleChange = name => event => {
        if(name === 'category') {
            setSelectedValue(Array.isArray(event) ? event.map(x => x.value) : []);
             const value = Array.isArray(event) ? event.map(x => x.value) : [];
             formData.set(name,value)
             setValues({...values, [name]: value,btnloading:false })
         } else {
            const value = name === 'photo' ? event.target.files[0] : event.target.value
            formData.set(name, value)
            setValues({...values, [name]: value,btnloading:false})
         }
     };

     const clickSubmit = event => {
            event.preventDefault()
            setValues({...values, error: '', btnloading: true})

            updateProduct(match.params.productId, user._id, token, formData)
            .then(data => {
                if(data.error) {
                    setValues({...values, error: data.error,btnloading:false})
                } else {
                    setValues({
                        ...values,
                        prodid: '',
                        name: '',
                        description: '',
                        long_description: '',
                        photo: '',
                        purchase_price: '',
                        threshold:'',
                        sale_price: '',
                        weight: '',
                        inventory: '',
                        btnloading: false,
                        error: false,
                        redirectToProfile: true,
                        createdProduct: data.name

                    })
                }
            })
     };



     const newPostForm = () => (

         <form className="mb-3" onSubmit={clickSubmit}>

             <div className="row">
                 <div className="col-md-6">

                    <div className="form-group">
                        <label className="text-muted">Product ID <span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChange('prodid')} type="text" className="form-control" value={prodid} required />
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Product Name<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChange('name')} type="text" className="form-control" value={name} required />
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Short Description<span style={{color:"red"}}> *</span></label>
                        <textarea onChange={handleChange('description')} className="form-control" value={description} required />
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Long Description<span style={{color:"red"}}> *</span></label>
                        <textarea onChange={handleChange('long_description')} rows="7" cols="7" className="form-control" value={long_description}/>
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Category<span style={{color:"red"}}> *</span></label>
                        <Select 
                            className="dropdown"
                            placeholder="Select Category"
                            value={data2.filter(obj => 
                                selectedValue.includes(obj.value))}
                            options={data2}
                            onChange={ handleChange('category')}
                            isMulti
                            isClearable
                        />
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Supplier<span style={{color:"red"}}> *</span></label>
                        <select 
                            onChange={handleChange('supplier')} 
                            className="form-control">
                                <option >Select supplier</option> { 
                                    suppliers && suppliers.map((c, i) => (
                                        (supplier === c._id ? <option selected key={i} value={c._id}>{c.companyname}
                                            </option> : <option key={i} value={c._id}>{c.companyname}
                                </option>)
                            
                                ))}
                                
                        </select>
                    </div>

                 </div>

                 <div className="col-md-6">
                
                    <div className="form-group">
                        <label className="text-muted">Quantity<span style={{color:"red"}}> *</span></label>
                        <input  type="number" className="form-control" value={inventory} readOnly={true} required/>
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Threshold<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChange('threshold')} type="number" className="form-control" value={threshold} required />
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Purchase Price<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChange('purchase_price')} type="number" className="form-control" value={purchase_price} required />
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Sale Price<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChange('sale_price')} type="number" className="form-control" value={sale_price} required />
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Weight<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChange('weight')} type="number" className="form-control" value={weight} required />
                    </div>

                    <label className="text-muted">Product Image<span style={{color:"red"}}> *</span></label>

                    <div className="form-group">
                        <label className="btn btn-secondary">
                        <input onChange={handleChange('photo')} type="file" name="photo" accept="image/*" />
                        </label>
                    </div>
             
                </div>
            </div>

            <button  className="btn btn-outline-primary" disabled={btnloading}> {btnloading ? "Loading..." : "Update Product"} </button>
             <div><Link className="nav-link" to="/admin/products">Go Back to List </Link></div>
         </form>
     );

     const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => (
        createdProduct && <Redirect to="/admin/products" />
    );


    return (
        <AdminLayout className="container" topTitle="Update Product">
            <div className="row">    
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title" style={{textAlign:'center'}}>Update Product</h4>

                                
                                {showSuccess()}
                                {showError()}
                                {!loading && newPostForm()}

                                <Backdrop className={classes.backdrop} open={loading} >
                                    <CircularProgress color="inherit" />
                                </Backdrop>  
                
                        </div>
                    </div>
                </div>
           </div>
        </AdminLayout>
    )
}


export default UpdateProduct;