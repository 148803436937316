import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import { logout } from '../../auth/index'

const isActive = (history, path) => {
    if(history.location.pathname === path) {
        return { color: '#429ef5'};
    } else {
        return {color: 'grey'};
    }
}

const Sidebar = ({history}) => {

    return (         
                <nav className="sidebar sidebar-offcanvas" id="sidebar">
                    <ul className="nav">
                        <li className="nav-item">
                            <Link className="nav-link" style={isActive(history,'/admin/dashboard')} to="/admin/dashboard">
                                <i className="ti-shield menu-icon"></i>
                                <span className="menu-title">Dashboard</span>
                            </Link>
                        </li>
                        
                        <li className="nav-item">
                            <Link className="nav-link" style={isActive(history,'/admin/categories')} to="/admin/categories">
                                <i className="ti-layout-list-post menu-icon"></i>
                                <span className="menu-title">Manage Categories</span>
                            </Link>
                        </li>
                       
                        <li className="nav-item">
                            <Link className="nav-link" style={isActive(history,'/admin/products')} to="/admin/products">
                                <i className="ti-package menu-icon"></i>
                                <span className="menu-title">Manage Products</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" style={isActive(history,'/admin/users')} to="/admin/users">
                                <i className="ti-user menu-icon"></i>
                                <span className="menu-title">Manage Customers</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" style={isActive(history,'/admin/coupons')} to="/admin/coupons">
                                <i className="ti-ticket menu-icon"></i>
                                <span className="menu-title">Manage Coupons</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" style={isActive(history,'/admin/orders')} to="/admin/orders">
                                <i className="ti-view-list-alt menu-icon"></i>
                                <span className="menu-title">Manage Orders</span>
                            </Link>
                        </li>      

                        <li className="nav-item">
                            <Link className="nav-link" style={isActive(history,'/admin/ads')} to="/admin/ads">
                                <i className="ti-stats-up menu-icon"></i>
                                <span className="menu-title">Manage Ads</span>
                            </Link>
                        </li>  

                        <li className="nav-item">
                            <Link className="nav-link" style={isActive(history,'/admin/campaign/url/generator')} to="/admin/campaign/url/generator">
                                <i className="ti-share menu-icon"></i>
                                <span className="menu-title">Campaign Url</span>
                            </Link>
                        </li>   

                        <li className="nav-item">
                            <Link className="nav-link" style={isActive(history,'/admin/preorder/bookings')} to="/admin/preorder/bookings">
                                <i className="ti-alarm-clock menu-icon"></i>
                                <span className="menu-title">Pre-Order Bookings</span>
                            </Link>
                        </li>   

                        <li className="nav-item">
                            <Link className="nav-link" style={isActive(history,'/admin/homepage/settings')} to="/admin/homepage/settings">
                                <i className="ti-home menu-icon"></i>
                                <span className="menu-title">HomePage Settings</span>
                            </Link>
                        </li>    

                        <li className="nav-item">
                            <Link className="nav-link" style={isActive(history,'/admin/email/templates')} to="/admin/email/templates">
                                <i className="ti-paint-roller menu-icon"></i>
                                <span className="menu-title">Manage Email Templates</span>
                            </Link>
                        </li>   

                        {/*<li className="nav-item">
                            <Link className="nav-link" style={isActive(history,'/admin/reviews')} to="/admin/reviews">
                                <i className="ti-star menu-icon"></i>
                                <span className="menu-title">Manage Trustpilot Reviews</span>
                            </Link>
                        </li> */}

                        <li className="nav-item">
                            <Link className="nav-link" style={isActive(history,'/admin/cards')} to="/admin/cards">
                                <i className="ti-credit-card menu-icon"></i>
                                <span className="menu-title">Manage Cards</span>
                            </Link>
                        </li>                 
                      
                        <li className="nav-item">
                            <Link className="nav-link" style={isActive(history,'/admin/customer-subscriptions')} to="/admin/customer-subscriptions">
                                <i className="ti-receipt menu-icon"></i>
                                <span className="menu-title">Manage User Subscriptions</span>
                            </Link>
                        </li>
                       
                        <li className="nav-item">
                            <Link className="nav-link" style={isActive(history,'/admin/subscriptions')} to="/admin/subscriptions">
                                <i className="ti-crown menu-icon"></i>
                                <span className="menu-title">Manage Subscriptions</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" style={isActive(history,'/admin/suppliers')} to="/admin/suppliers">
                                <i className="ti-pie-chart menu-icon"></i>
                                <span className="menu-title">Manage Suppliers</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" style={isActive(history,'/admin/newsletters')} to="/admin/newsletters">
                                <i className="ti-announcement menu-icon"></i>
                                <span className="menu-title">Manage Newsletters</span>
                            </Link>
                        </li>
                      
                        <li className="nav-item">
                            <Link className="nav-link" style={isActive(history,'/admin/contacts')} to="/admin/contacts">
                                <i className="ti-email menu-icon"></i>
                                <span className="menu-title">Manage Contacts</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" style={isActive(history,'/admin/return-requests')} to="/admin/return-requests">
                                <i className="ti-check-box menu-icon"></i>
                                <span className="menu-title">Manage Return Requests</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="collapse" style={isActive(history,'#/')} href="#/" aria-expanded="false" aria-controls="auth">
                                <i className="ti-write menu-icon"></i>
                                <span className="menu-title">ZenDesk Tickets</span>
                             
                            </a>
                            <div className="collapse" id="auth">
                                <ul className="nav flex-column sub-menu">
                                    <li className="nav-item"> <a className="nav-link" href="/pages/samples/login.html"> Login </a></li>
                                    <li className="nav-item"> <a className="nav-link" href="/pages/samples/login-2.html"> Login 2 </a></li>
                                    <li className="nav-item"> <a className="nav-link" href="/pages/samples/register.html"> Register </a></li>
                                    <li className="nav-item"> <a className="nav-link" href="/pages/samples/register-2.html"> Register 2 </a></li>
                                    <li className="nav-item"> <a className="nav-link" href="/pages/samples/lock-screen.html"> Lockscreen </a></li>
                                </ul>
                            </div>
                        </li>
                        
                        <li className="nav-item">  
                        <span className="nav-link" style={{cursor: 'pointer', color: 'grey'}}
                        onClick={() => logout(() => {
                            history.push("/login");
                        })}>
                        <i className="ti-power-off menu-icon"></i>
                                <span className="menu-title">Logout</span>
                            </span>
                        </li>
                    </ul>
                </nav> 
    )
}

export default withRouter(Sidebar)
