import React, {useState, useEffect} from 'react'
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import { updateAd, createAdsProduct, getProducts, getAdsById,getAdData,updateAdProdPrice,updateAdsProdPhoto, removeAdsProd,getAdsProdList} from './apiAdmin'
import ShowImage from './ShowImage'
import { API } from "../config";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

  const useStylesforSpin = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

const UpdateAds = ({match}) => {

    const { user, token} = isAuthenticated();

    const [spinloading, setSpinloading] = useState(true);

    const [btnloading, setBtnloading] = useState(false);

    const [prodCheck, setProdCheck] = useState(false);

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const classes1 = useStylesforSpin();
    
    const [spinOpen, setSpinOpen] = useState(false);

    const [ values, setValues] = useState({
        id:'',
        category_name:'',
        category_description:'',
        category_image: '',
        package_salePrice:'',
        error:'',
        success:false,
        formData:''
    });

    const { id,category_name, category_description,package_salePrice, category_image, error, success, formData} = values;

    const [values1, setValues1] = useState({
        error1:'',
        formData1:new FormData()
    })

    const {formData1,error1} = values1;

    const [products, setProducts] = useState([])

    const loadAds = () => {
        getAdsById(match.params.adsId).then(data => {
            if(data.error){
                setValues({...values, error: data.error});
            } else {
                setValues({
                    ...values,
                    id:data._id,
                    category_name: data.category_name,
                    category_description: data.category_description,
                    package_salePrice: data.package_salePrice,
                    formData: new FormData()
                })
            }
        })
    }

    const loadProducts = () => {
        getProducts().then(data => {
            if (data.error) {
                console.log(data.error);
                setSpinloading(false);
            } else {
                setProducts(data);
                setSpinloading(false); 
            }
        });
    };

    const [adsProducts, setAdsProducts] = useState([]);

    const loadAdsProducts = () => {
        getAdsProdList(user._id,token,{adsID:match.params.adsId}).then(data => {
            if (data.error) {
                console.log(data.error);
               /*  setSpinloading(false); */
            } else {
                setAdsProducts(data);
               /*  setSpinloading(false); */
            
            }
        });
    };

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpen(false);
    };

    const handleClose1 = () => {
        setSpinOpen(false);
      };
      const handleToggle = () => {
        setSpinOpen(!open);
      }

    useEffect(() => {      
        loadAds();
        loadProducts();
        loadAdsProducts(); 
    },[])

    const handleChange = name => event => {
        const value = name === 'category_image' ? event.target.files[0] : event.target.value
        formData.set(name, value)
        setValues({...values, [name]: value})
      }

    const clickSubmit = event => {
        event.preventDefault();
        setValues({...values, error: ''})
        setBtnloading(true);
      
        updateAd(user._id, token, match.params.adsId, formData)
        .then(data => {
            if(data.error) {
                setValues({...values, error: data.error});
                setBtnloading(false);
            }
            else {
                setValues({...values, success:true});
                setBtnloading(false);
                loadAds();
            }
        })
    }

    const adsForm = () => (
           
                <form className="mb-3" onSubmit={clickSubmit}>
                    <div className="row">

                        <div className="col-md-6">

                            <div className="form-group">
                                <label className="text-muted">Category Name<span style={{color:'red'}}> *</span></label>
                                <input onChange={handleChange('category_name')} type="text" className="form-control" value={category_name} required />
                            </div>

                            <div className="form-group">
                                <label className="text-muted">Category Description<span style={{color:'red'}}> *</span></label>
                                <textarea onChange={handleChange('category_description')} type="text" className="form-control" value={category_description} required />
                            </div> 

                            <div className="form-group">
                                <label className="text-muted">Package Sale Price(£)<span style={{color:'red'}}> *</span></label>
                                <input onChange={handleChange('package_salePrice')} type="number" className="form-control" value={package_salePrice} required />
                            </div>  

                        </div>

                        <div className="col-md-6">

                            <img src={`${API}/ads/image/${id}`} className="mb-3" style={{ maxHeight: "50%", maxWidth: "50%" }} /> <br/>
                            
                            <label className="text-muted">Change Image<span style={{color:'red'}}> *</span></label>
                            <div className="form-group">
                                <label className="btn btn-secondary">
                                    <input onChange={handleChange('category_image')} type="file" name="category_image" accept="image/*" />
                                </label>
                            </div>

                        </div>

                        <div className="col-md-6">
                            <button style={{textAlign:"left"}} className="btn btn-outline-primary" disabled={btnloading}>{btnloading ? "Loading..." : "Update"}</button>
                        </div>

                        <div className="col-md-6">
                            <Link className="nav-link" to="/admin/ads" style={{textAlign:"right"}}><h4><i className="ti-angle-double-left"></i>Go back</h4></Link>
                        </div>
                       
               {showSuccess()}
                
                </div>
                <hr/>
            </form>   
    )

    const checkPrice = (o,price) => {
        if(adsProducts.length >=1){
            for(var i=0;i<adsProducts.length;i++){
                if(adsProducts[i].productId === o){
                   
                    return (<div className="form-group">
                                <input type="number" className="form-control" onBlur={e => handleChangeforPrice(e, o)} defaultValue={adsProducts[i].prod_price} placeholder={adsProducts[i].prod_price} />
                            </div>)
                }
            }
            return (<div className="form-group">
                        <input type="number" className="form-control" onBlur={e => handleChangeforPrice(e, o)} defaultValue={price} placeholder={price} />
                    </div>)
        }
        else {
            return (<div className="form-group">
                        <input type="number" className="form-control" onBlur={e => handleChangeforPrice(e, o)} defaultValue={price} placeholder={price} />
                    </div>)
        }
    }

    const handleChangeforPrice = async (e,prodId) => {
        setSpinOpen(true);
        var tempPrice = e.target.value;
        let priceRes = await getAdData(user._id,token,{adsID:match.params.adsId, prodId:prodId})
        if(priceRes.length >=1){
            updateAdProdPrice(user._id, token, {prod_price:tempPrice,adsID:match.params.adsId, prodId: prodId}).then(
                data => {
                    if (data.error) {
                        console.log("Update Price failed");
                    } else {
                        loadProducts();
                        loadAdsProducts(); 
                        setSpinOpen(false);
                    }
                }
            ); 
        } else{     
            loadProducts();
            loadAdsProducts(); 
            setSpinOpen(false);
            setOpen(true);
        }

    }

    const handleChangeforCheckBox = async (e,prodId,price,photo) => {
        setSpinOpen(true);
        var checkProd1 = e.target.checked;

        let results = await getAdData(user._id,token,{adsID:match.params.adsId, prodId:prodId})

        if(checkProd1){
           
            if(results.length >=1 ){
                let deleteRes = await removeAdsProd(results[0]._id,user._id,token);
            }
                formData1.set("prod_price",price);
                formData1.set("productId",prodId);
                formData1.set("adsId",match.params.adsId);
                formData1.set("prod_image",photo.data.data);
                let createAdProd = await createAdsProduct(user._id,token,formData1)
               setSpinOpen(false);
        }else {
          
            if(results.length >=1 ){
                let deleteRes = await removeAdsProd(results[0]._id,user._id,token);
                setSpinOpen(false);
            }
        }

    }


    const handleChangeForImage = async (event,prodId) => {
        setSpinOpen(true);
        const value = event.target.files[0]
        let formData2= new FormData();
        formData2.set("prod_image",value);
        let results1 = await getAdData(user._id,token,{adsID:match.params.adsId, prodId:prodId})
        if(results1.length >=1){       
            updateAdsProdPhoto(user._id, token, results1[0]._id, formData2).then(
                data => {
                    if (data.error) {
                        console.log("Update Image failed");
                    } else {
                        loadProducts();
                        loadAdsProducts(); 
                        setSpinOpen(false);
                    }
                }
            ); 
           
        }else { 
            loadProducts();
            loadAdsProducts(); 
            setSpinOpen(false);
            setOpen(true);
        }
    }

    const clickCheckBox = (id,sale_price,photo) => {
        if(adsProducts.length >=1){

            for(var i=0;i<adsProducts.length;i++){
                if(adsProducts[i].productId === id){
                   
                    return ( <div className ="form-group">
                                <input onChange={e => handleChangeforCheckBox(e, id,sale_price,photo)} defaultChecked={true} type="checkbox" id="usr" />
                            </div> )
                }
            }
            return ( <div className ="form-group">
                        <input onChange={e => handleChangeforCheckBox(e, id,sale_price,photo)} defaultChecked={prodCheck} type="checkbox" id="usr" />
                    </div> )
        }
        else {
            return ( <div className ="form-group">
                        <input onChange={e => handleChangeforCheckBox(e, id,sale_price,photo)} defaultChecked={prodCheck} type="checkbox" id="usr" />
                    </div> )
        }
    }

    const showProdImg = (id) => {
        if(adsProducts.length >=1){
            for(var i=0;i<adsProducts.length;i++){
                if(adsProducts[i].productId === id){
                    if(adsProducts[i].prod_image){
                        return <img src={`${API}/ads/products/image/${adsProducts[i]._id}`} width="100%" />
                    }        
                }
            }
            return <ShowImage item={id} url="product" />
        }
        else{
            return <ShowImage item={id} url="product" />
        }
    }
       

    const showAddProducts = () => {
         return (
             <div className="col-md-12">

            <h2>Choose Products</h2>
            <div className="table-responsive pt-3 px-2">

                <table className="table table-bordered table-responsive">
                    <thead>
                        <tr>
                            <th className="pend-table-hd"></th>

                            <th className="pend-table-hd">Product Name</th>

                            <th className="pend-table-hd">Product Price(£)</th>

                            <th className="pend-table-hd">Image</th>

                            <th className="pend-table-hd">Change Image</th>

                        </tr>
                    </thead>

                    <tbody>
                        {products.map((s,i) => (
                            <tr>
                                <td key={i}>
                                    {clickCheckBox(s._id,s.sale_price,s.photo)}
                                </td>

                                <td key={i} className="font-we-prod">
                                    {s.name}
                                </td>

                                <td key={i}>
                                    {checkPrice(s._id,s.sale_price)}
                                </td>

                                <td key={i}>               
                                   {showProdImg(s._id)}
                                </td>

                                <td key={i}>
                                    <div className="form-group">
                                        <label className="btn btn-secondary">
                                            <input onChange={e => handleChangeForImage(e,s._id)} type="file" name="prod_image" accept="image/*" />
                                        </label>
                                    </div>
                                   
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
            </div>
         )
    }

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showError1 = () => (
        <div className="alert alert-danger" style={{ display: error1 ? '' : 'none' }}>
            {error1}
        </div>
    );

    const showSuccess = () => (
        <div className="alert alert-success" style={{ display: success ? '' : 'none' }}>
           Ad Updated Successfully
        </div>
    );

    return (
        <AdminLayout className="container" topTitle="Update Ads" >

            <div className="row">
                    
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title" style={{textAlign:'center'}}>Update Ads</h4>
               
                          
                            {(spinloading) ? <div className='loader-container'><div className='loader'></div></div> : ""}
                           
                            {showError()}
                            {!spinloading && adsForm()}
                           {/*  {adProducts()} */}
                           {!spinloading && showAddProducts()}

                            <div className={classes.root}>
                                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                    <Alert onClose={handleClose} severity="error">
                                    First click the checkbox and then update!
                                    </Alert>
                                </Snackbar>
                            </div>

                            <Backdrop className={classes1.backdrop} open={spinOpen}>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                
                        </div>
                    </div>
                </div>
           </div>
        </AdminLayout>
    )
}

export default UpdateAds
