import React, {Fragment,useEffect, useState} from 'react'
import { getCancelledOrders } from './Priorities/apiUser'
import { isAuthenticated } from '../../auth/index'
import Navbar from '../admin/Navbar'
import Sidebar from './Sidebar'
import Moment from 'react-moment';
import {Helmet} from 'react-helmet'

const CancelledOrders = () => {

    const {user} = isAuthenticated()

    const [ orders, setOrders] = useState([]);
    const [loading1, setLoading1] = useState(true)

    const loadOrders = () => {
        getCancelledOrders(user._id).then(data => {
            if (data.error) {
                console.log(data.error);
                setLoading1(false);
            } else {
                setOrders(data);
                setLoading1(false);
            }
        });
    };

    useEffect(() => {
        loadOrders();
    }, []);

    const showCancelledOrders = () => {
        return (
            <div>

                {
                    orders.map(item => (
                    <div className="col-md-12 px-0">
                    <div className="row">
                    <div className="col-md-12 stretch-card">
                        <div className="card position-relative card-shad-in">
                        <div className="card-body">
                            <div className="row estimated-bord">
                            <div className="col-md-6 blue-esti-head">
                            <p className="card-title blue-esti-head">Order Status : {item.status}</p>
                            <p className="card-title blue-esti-head">Ordered Date :  <Moment format='DD/MM/YYYY'>{(item.createdAt)}</Moment></p>
                            </div>
                            <div className="col-md-6 text-right">
                            <p className="card-title blue-esti-head">Order Number: {item.orderid}</p> 
                           
                            <p className="card-title blue-esti-head">Payment Status: {(item.status === "Awaiting Payment") ? <span style={{color:'red'}}>Waiting for Payment</span> : (((item.paymentId !== "undefined") && (item.paymentId !== "null") && (item.paymentId !== undefined)) ? "Paid" : "UnPaid" )}</p>    
                            </div>
                            </div>
                            <div className="row">
                        
                            <div className="col-md-4 col-xl-4 d-flex flex-column justify-content-top">
                                <div className="ml-xl-4 pt-3">
                                <p className="products-text-in-dark">Products</p>
                                <h1 className="custo-sec-ad pt-2 pb-2">{/* {item.quantity} x */}{item.title}</h1>
                                <div className="row">
                                    <div className="col-md-6">                           
                                    <ul className="list-inline pr-name-air-sen">                              
                                    <li dangerouslySetInnerHTML={{__html: item.order_description}}></li>                          
                                    </ul>
                                    </div> 
                                </div>
                                </div>  
                            </div>
                            <div className="col-md-4 col-xl-4 d-flex flex-column justify-content-top">
                                {item.shipping && 
                                    <div className="ml-xl-4 pt-3">
                                    <p className="products-text-in-dark">Delivery Address</p>
                                    <h1 className="custo-sec-ad pt-2">{item.shipping.prefix}.{item.shipping.first_name} {item.shipping.last_name} | {item.shipping.phone}</h1>
                                    <p>{item.shipping.house_no}</p>
                                    <p>{item.shipping.area !== undefined && (item.shipping.area)}</p>
                                    <p>{item.shipping.city}</p>
                                    <p>{item.shipping.state !== undefined && (item.shipping.state)}</p>
                                    <p>{item.shipping.country}</p>   
                                    <p>{item.shipping.pin_code}</p>                         
                                    </div>  
                                }
                            </div>

                            <div className="col-md-4 col-xl-4 d-flex flex-column justify-content-top">
                                <div className="ml-xl-4 pt-3">
                                <p className="products-text-in-dark">Cancelled Reason</p>
                                <p>{item.cancelled_reason}</p>
                                </div>
                            </div>


                            </div>
                        </div>
        
                        </div>
        
                    </div>
        
                    </div>
                    <div className="col-md-12" style={{backgroundColor: '#f5f5f5', border: 'solid 1px #e7e7e7', borderTop: '0px'}}>
                    <div className="row">
                        <div className="col-md-9 py-3">
                        {/* <button type="button" className="btn btn-info btn-gra-my-p" style={{borderRadius: '25px'}}>Chat with our team</button> */}
                        </div>
                        <div className="col-md-3 pt-4">
                        <div className="row">
                            <div className="col-md-6 text-right">
                            <h3 className="total-font"> Total</h3>
                            </div>
                            <div className="col-md-6">
                            <h3 className="total-font-bl">£{item.price.toFixed(2)}</h3>          
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    </div>
                    ))
            }

            </div>
        )
    }

    const showNotfound = () => {
        return (
                <div className="col-md-12 px-0">
                    <div className="col-md-12" style={{backgroundColor: '#f5f5f5', border: 'solid 1px #e7e7e7', borderTop: '0px'}}>
                        <br/>
                         <h4 style={{textAlign:'center',color:'red'}}>No Cancelled Orders are found</h4>
                    </div>
                </div>
        )
    }

    return (
        <Fragment>
            <Helmet>
                <title>Safely Team - Cancelled Orders</title>
            </Helmet>
             <div className="container-scroller">
                <Navbar />
                
                <div className="container-fluid page-body-wrapper">
    
                <Sidebar />

                        <div className="main-panel">
                          <div className="content-wrapper white-bg-ad">
                            <div className="row">
                              <div className="col-md-12 grid-margin">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <h4 className="font-weight-bold mb-0">Cancelled Orders</h4>
                                  </div>
                                <div>  
                              </div>
                            </div>
                          </div>
                        </div>
                        {(loading1) ? <div className='loader-container'><div className='loader'></div></div> : ""}  
                        { !loading1 &&  (orders.length >=1 ? showCancelledOrders() : showNotfound()) }
                        
                        
                      </div> 
                    </div>

                </div>

        </div>

     
        </Fragment>
    )
}

export default CancelledOrders
