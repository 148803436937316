import React,{useState,useEffect,Fragment} from 'react'
import { getUserDetails } from './apiAdmin'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const Welcome = ({match}) => {

    const [values,setValues] = useState({
        prefix:'',
        lastName:''
    });

    const {prefix,lastName} = values

    const loadUsers = () => { 
        getUserDetails({orderId:match.params.orderId}).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setValues({
                    prefix:data.prefix,
                    lastName:data.lastname
                })
                downloadWelcome()
            }
        });
    };
    
    const downloadWelcome = () => {

        html2canvas(document.querySelector("#capture")).then(canvas => {
            //document.body.appendChild(canvas)
        });
        const input = document.getElementById('capture');
        html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
          })
        ;
        html2canvas(input)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF();
          pdf.addImage(imgData, 'PNG',16, 0);
          pdf.save("download.pdf");  
        });

    } 
    useEffect(() => {
        loadUsers()
    }, []) 

    return (
              
               
                <div style={{fontFamily:'"Montserrat", sans-serif',fontSize:'16px', color:'#000'}}>
                    <table id="capture" width="650" border="0" cellspacing="0" cellpadding="0" align="center">
                    <tr>
                    <td width="325">&nbsp;</td>
                        <td>
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td><img src={require('../theme/user/img/pdf/ic1.png')} width="100px"/></td>
                        <td><img src={require('../theme/user/img/pdf/ic2.png')} width="100px" /></td>
                        <td><img src={require('../theme/user/img/pdf/ic3.png')} width="100px" /></td>
                    </tr>
                    </table>

                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style={{fontSize:'100px', fontWeight:'bold'}}>Welcome</td>
                    </tr>
                    <tr>
                        <td colspan="2" style={{paddingTop:'40px'}}>
                            <p style={{fontWeight:'500', color:'#000'}}>Dear {prefix+'. '+lastName},</p>
                        
                            <p style={{fontWeight:'500', paddingTop:'8px', fontSize:'16px', color:'#000', lineHeight:'20px', textAlign:'justify'}}>Welcome to the Safely community. Please find your system enclosed in
                    this parcel.

                    </p>
                    <p style={{paddingTop:'8px', fontSize:'18px', fontWeight:'bold', color:'#000', lineHeight:'20px', textAlign:'center'}}>Follow these instructions to get started
                    </p>

                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                    <table width="650" border="0" cellspacing="0" cellpadding="0">
                    <tr>
                        <td width="85%"><p style={{fontWeight:'500', paddingTop:'8px', fontSize:'20px', color:'#0f4c64', lineHeight:'20px', fontWeight:'bold', marginBottom:'5px'}}>Step 1

                    </p>
                    <p style={{fontWeight:'500', paddingTop:'8px', fontSize:'16px', color:'#000', lineHeight:'20px', marginTop:'0px', textAlign:'justify', paddingRight:'15px'}}>Install the “Safely Connect” app onto your mobile device
                    from the Google Play store or Apple app store. Make sure
                    you allow the notifications and then follow the
                    instructions to “sign-up now” using the email address you
                    purchased the system with. Now sign in and follow the
                    instructions to complete your account setup.
                    </p></td>
                        <td style={{paddingTop:'50px'}}><a><img src={require('../theme/user/img/pdf/download-app.png')} width="100px" alt="" /></a></td>
                    </tr>
                    </table>

                        </td>
                        
                    
                    </tr>
                    <tr>
                    <td colspan="2">
                        <p style={{fontWeight:'500', paddingTop:'8px', fontSize:'20px', color:'#0f4c64', lineHeight:'20px', fontWeight:'bold', marginBottom:'5px'}}>Step 2

                    </p>
                    <p style={{fontWeight:'500', paddingTop:'8px', fontSize:'16px', color:'#000', lineHeight:'20px', marginTop:'0px', textAlign:'justify'}}>Open the Safely Gateway and plug it in using the power cable provided. Connect the Safely Gateway to your Wi-Fi Hub using the ethernet cable
                    included.<br /><br />
                    Now follow the instructions on each device box and install the labelled
                    components into each of your predetermined rooms. 
                    </p>
                        </td>
                    </tr>
                    <tr>
                    <td colspan="2">
                        <p style={{fontWeight:'500',paddingTop:'8px',fontSize:'20px',color:'#0f4c64',lineHeight:'20px',fontWeight:'bold',marginBottom:'5px'}}>Step 3

                    </p>
                    <p style={{fontWeight:'500', paddingTop:'8px', fontSize:'16px', color:'#000', lineHeight:'20px', marginTop:'0px'}}>Once your Safely system is installed enable the alarm receiving centre
                    following the instructions on page 20 of the reference guide.

                    </p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width:'30%'}}><p style={{fontWeight:'500', paddingTop:'8px', fontSize:'16px', fontWeight:'500', color:'#000', lineheight:'24px', marginBottom:'50px'}}>Yours sincerely,<br />
                    The SafelyTeam
                    </p>
                    <a href="https://safelyteam.com/"><img src={require('../theme/user/img/pdf/logo.png')} /></a>
                    </td>
                    <td align="right"><img src={require('../theme/user/img/pdf/ftr-cycle.png')} /></td>
                    </tr>
                    
                    </table>

                </div>
        
    )
}

export default Welcome
