import { API } from '../config'
import {getUser} from '../admin/apiAdmin'

// send to backend
export const register = user => {
    //console.log(name, email, password);
    return fetch(`${API}/register`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body:JSON.stringify(user)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};

export const login = user => {
    //console.log(name, email, password);
    return fetch(`${API}/login`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body:JSON.stringify(user)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
};


export const authenticate = (data, next) => {
    if (typeof window !== 'undefined') {
        localStorage.setItem('jwt', JSON.stringify(data));
        next();
    }
};

export const logout = next => {
    if (typeof window !== 'undefined') {
        localStorage.removeItem('jwt');
        next();
        return fetch(`${API}/logout`, {
            method: 'GET'
        })
        .then(response => {
             console.log('logout', response);
        })
        .catch(err => console.log(err));
    }
};


export const isAuthenticated = () => {
    if (typeof window == 'undefined') {
        return false;
    }
    if (localStorage.getItem('jwt')) {
        return JSON.parse(localStorage.getItem('jwt'));
    } else {
        return false;
    }
};

export const checkAdmin = () => {

    if(isAuthenticated()){
        const {user, token} = isAuthenticated();
        getUser(user._id, token)
            .then(data => {
                if(data.error){
                    return false;
                }
                else {
                    if(data.role === 1){
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            })
    }
    else {
        return false;
    }

}

export const checkUser = () => {

    if(isAuthenticated()){
        const {user, token} = isAuthenticated();
        getUser(user._id, token)
            .then(data => {
                if(data.error){
                    return false;
                }
                else {
                    if(data.role === 0){
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            })
    }
    else {
        return false;
    }

}