import React, {useState, useEffect, Fragment} from 'react'
import Navbar from '../admin/Navbar'
import Sidebar from './Sidebar'
import { isAuthenticated } from '../../auth/index' 
import { getOrdersById } from '../../user/apiUser'
import { getProductList,getProductByName,UpdateUserOrder ,getSubscriptions} from './Priorities/apiUser'
import ShowImage from '../../admin/ShowImage'
import { STRIPE_PUBLIC, STRIPE_PUBLIC_1,API,TESTING_USER } from "../../config";
import { loadStripe } from '@stripe/stripe-js';
import { addItemCount1,getCart1,updateItem1, removeItem2 } from './Priorities/cartHelpers'
import {Helmet} from 'react-helmet'

const UpdatePackage = ({match}) => {

    let stripePromise = "";
    const { user,token } = isAuthenticated();
    const [items, setItems] = useState([])
    const [count, setCount] = useState(1);
    const [selprodid, setSelProdId] = useState(1);
    const [products, setProducts] = useState([]); 
    let [products1, setProducts1] = useState([]);
    let [product, setProduct] = useState([]);
    if(user._id === TESTING_USER){
        stripePromise = loadStripe(STRIPE_PUBLIC_1);    
      }
      else {
          stripePromise = loadStripe(STRIPE_PUBLIC);
      }
    const [hidecount, setHideCount] = useState(false);
    const [btnloading, setLoading] = useState(true)
    const [btnloading1, setLoading1] = useState(true)
    var prodPrice="";
    var prodId="";
    var prodQuan="";
    var prodName="";
    var prodDesc="";
    let tot=0;

    const [packageitems, setCustomPackageItems] = useState('');  
    const [checkPackage, setcheckPackage] = useState('');

    const [values, setValues] = useState({
        id:'',
        order_description:'',
        price:'',
        title:'',
        subscription_type:'',
        pre_config:'',
        error:'',
        success: false
    });


    const {id,order_description,price,title,pre_config,subscription_type,error,success} = values

    const [values1, setValues1] = useState({
        packageName:'',
        packageprice:0,
        packagehead:'',
        temp_productid: '',
        temp_price: '',
        temp_quantity: '',
        temp_name:'',
        pack_quantity:''
    });

    const [ values3, setValues3 ] = useState({
        spinloading:false
      })

    const [values2, setValues2] = useState({
        gold_price:'',
        silver_price:'',
        bronze_price:'',
        error2:''
    })

    const { gold_price, silver_price, bronze_price, error2} = values2;
  
    const { spinloading } = values3;    

    const {packageName,
        packageprice,
        packagehead,
        temp_productid,
        temp_price,
        temp_quantity,
        temp_name,
        pack_quantity} = values1

    const loadOrders = async (orderId) => {
        getOrdersById(orderId).then(data => {
            if(data.error) {
                console.log(data.error);
                setValues({...values,   error:data.error});
                setLoading1(false);
            } else {
                setValues({
                    ...values,
                    id: data._id,
                    order_description:data.order_description,
                    price:data.price,
                    title:data.title,
                    pre_config:data.pre_config,
                    subscription_type:data.subscription_type,
                    error:'',
                    success:true
                });

                var tempName = data.order_description.split("<br/>"); 
                var tempTitle = data.title.split(",");           
                var tempDesc="";               
                
                var bcheck=false;
                var custPackageItems="";
                var titleLength = tempTitle.length;
                
                if(data.title.includes('Customised Safely Package') || data.title.includes('Customised SafelyTeam Package')){
                    setcheckPackage("true");                  
                for(var i=0;i<tempName.length-1;i++)
                {
                    if(titleLength>1) {
                        titleLength--;
                    } else {
                        var Prodname1=tempName[i].split('x');
                        var quan1 = Prodname1[0].replace(" ","");
                        var name1 = Prodname1[1].replace(" ","");
                        tempDesc += tempName[i].split(',')+'<br/>'
                        loadProductByName1(name1,quan1,tempDesc);  
                      
                        if(Prodname1[1].trim() === "Gateway")
                        {    
                        bcheck=true;
                        }
                        if(bcheck)
                        {                          
                            custPackageItems+=" <div className='col-md-6'><span>"+tempName[i].trim()+"</span></div>";
                        }       
                    }                   
                    
                }              
                setCustomPackageItems(custPackageItems);
            }
           
                var tempTitle1;
                var tempQuan1;
                var tempTitle2;
                var tempQuan2;              
            
                if(tempTitle.length>0)
                {
                for(var i=0;i<tempTitle.length;i++)
                {
                    if(tempTitle[i].includes('x'))
                    {                    
                    var Prodname=tempTitle[i].split('x');                    
                    tempTitle1 = Prodname[1].replace(' ','');                   
                    tempQuan1 = Prodname[0].replace(' ','');  
                    }
                else
                {    tempTitle1= tempTitle[i];
                    tempQuan1=1;}

                    if(tempTitle1 === "Customised Safely Package" || tempTitle1 === "Customised SafelyTeam Package")
                    {                        
                        continue;

                    } else {
                        tempTitle2 = tempTitle1;
                        tempQuan2 = parseInt(tempQuan1);
                        loadProductByName(tempTitle2,tempQuan2);                        
                    }                   
                }
                
            }
        }
               
        });
    };

    
    const loadProducts = () => {
        getProductList().then(data => {
            if(data.error) {
                console.log(data.error);
                setLoading(false);
            } else {
                setProducts(data);
                setLoading(false);
                setLoading1(false);
            }
        });
       
    };

    const loadProductByName = (name,count) => {
        getProductByName({name}).then(data => {
            if(data.error) {
                console.log(data.error);
            } else {               
               
                //setProduct(data);
                addItemCount1(data[0],count);
                setHideCount(true); 
                setSelProdId(data[0]._id); 
                //console.log('Prod11',product);       
            }
        });
    }

    const loadProductByName1 = (name,quan,desc) => {
      
        prodQuan+=quan+',';
        prodDesc=desc;
        
        getProductByName({name}).then(data => {
            if(data.error) {
                console.log(data.error);
            } else {
               setProducts1(data);
               
                var price = data[0].sale_price;
                var id = data[0]._id;
                prodPrice+=price+',';
                prodId+=id+',';  
                prodName+=data[0].name+',';
                tot=(Number(tot)+(Number(price)*quan));  
             
                localStorage.setItem('package1', JSON.stringify({ packageproducts:[prodName.trim(',')],packagetempName:prodName.trim(','),packageprice:[prodPrice.trim(',')],packageproductid:prodId.trim(','),packageQuantity:[prodQuan.trim(',')],packagetempPrice:prodPrice.trim(','), packagetempQuantity:prodQuan.trim(','),packageTotprice:[tot],packageName:[prodDesc],packageHead:["Customised SafelyTeam Package"] })) 
                localStorage.setItem('packageQnty1', JSON.stringify({val:1})); 
                const packData = JSON.parse(localStorage.getItem('package1'));
                const packQty = JSON.parse(localStorage.getItem('packageQnty1'));
                if(localStorage.getItem('package1')){
                    setValues1({packageName:[packData.packageName]+"",temp_name:packData.packagetempName,temp_price:packData.packagetempPrice, temp_quantity:packData.packagetempQuantity,temp_productid:packData.packageproductid,packageprice:[packData.packageTotprice*packQty.val],packagehead:[packData.packageHead],pack_quantity:packQty.val})
                }
               
            }
        });
    }

    useEffect(() => {
        //localStorage.removeItem('cart1');
        loadSubscriptions();
        loadOrders(match.params.orderId);
        loadProducts();        
    }, []);
    useEffect(() => {  
        setItems(getCart1());            
    }, [selprodid]); 
    useEffect(() => {            
    }, [items])
    useEffect(() => {  
        setItems(getCart1());    
    }, [count]);     
    
    const loadSubscriptions = () => {
        getSubscriptions().then(data => {
        if(data.error) {
            setValues2({...values2,error2:data.error})
        } else {
            var tempGold=0;
            var tempSilver=0;
            var tempBronze=0;
            for(var i=0;i<data.length;i++){
                if(data[i].plan === "Gold"){
                    tempGold = data[i].price;
                }
                else if(data[i].plan === "Silver"){
                    tempSilver = data[i].price;
                }
                else if(data[i].plan === "Bronze"){
                    tempBronze = data[i].price;
                }
            }
            setValues2({
            ...values2,
            gold_price:tempGold,
            silver_price:tempSilver,
            bronze_price:tempBronze,
            error2:''  
            })         
        }
        }) 
    }
  

    const updatehandleChange = productId => event => {
        if(event.target.value == '0')
        {                     
            setCount(0);
            removeItem2(productId);
           
        }  
        else{
        let tempQuan;
        items.map((p,i) => (
            productId === p._id && (tempQuan = p.quan)
        ))
       
        setCount(event.target.value < 1 ? 1 : event.target.value)
        if(event.target.value >=1 && event.target.value <=tempQuan) {
            updateItem1(productId, event.target.value)
           
        }
    }
    }

    const handleChange = (e,product) =>{ 
               
        if(e.target.value == '0')
            {
                setHideCount(true); 
                setSelProdId(product._id);  
                setCount(0);
                removeItem2(product._id);
               
            }  
        else{
        setHideCount(true); 
        setSelProdId(product._id);         
        let tempQuan=product.quan;  
                setCount(e.target.value < 1 ? 1 : e.target.value)
                if(e.target.value >=1 && e.target.value <=tempQuan) {
                    removeItem2(product._id);  
                    addItemCount1(product, e.target.value);                 
                    //updateItem(product._id, e.target.value)                   
                }   
            }  
             
    };

    const showAddToCart = (product,items) => {     
        const itemsquant = [];
        let selval='0';
        for (var i=0;i<=product.quan;i++) {
            itemsquant.push(<option key={i}>{i}</option>)
        }
        items.map((p, i) => {
            if(p._id === product._id) 
               { selval=p.count;}
        });                
        return ( 
            <div>                       
                <div className="pb-2">                           
              <select id="sel1" name="sellist1" defaultValue={selval} onChange={e => handleChange(e,product)}>
                {itemsquant}           
               </select> 
            </div>  
       {(hidecount && product._id===selprodid) ?                   
       <div className='blue-acti'>Cart updated.</div> :""}
       </div>
       )  
    };

    const packDetails = () => {
       
        return (     
           (checkPackage!=="")? <tr style={{border:'none'}}> 
                <td colSpan="2" style={{border:'none',width:'69%',padding:'5px',paddingTop: '20px'}}>
                <b>Customised SafelyTeam Package  </b>             
                </td>               
                <td style={{border:'none',width:'14%',padding:'5px',textAlign:'right'}}>                  
                    £ {packageprice}
                </td>      
    
          </tr>:""
        )
        };

    const prodDetails = () => {
        return (       
                items.map((product, i) => (                           
                <tr style={{border:'none'}} key={i}>                                  
                   <td style={{border:'none',width:'10%',padding:'5px'}}>
                        <input type="number" style={{padding:'0px',paddingLeft: '10px'}} onChange={updatehandleChange(product._id)}  value={product.count} className="form-control" id="usr" />
                        </td>
                        <td style={{border:'none',width:'69%',padding:'5px'}}>{product.name}</td>                        
                            <td style={{border:'none',width:'14%',padding:'5px',textAlign:'right'}}> 
                        £ {product.count  * product.sale_price}
                        </td>                 
                </tr>          
                ))
        )
    } 

    const getCartTotal = () => {
        return items.reduce((currentValue, nextValue) => {
            return currentValue + nextValue.count * nextValue.sale_price;
        }, 0);
    };

    const handlePaymentLocal = async (orderid,packageHead,preConfig,subscription_type,final_price) => {
        
        localStorage.setItem('checkValues',JSON.stringify({order:orderid, preconfig:preConfig, sub_type:subscription_type, title:packageHead, totPrice:final_price}))
        if(Number(subscription_type) === 1 || Number(subscription_type) === 0 || Number(subscription_type) === 2){
            localStorage.setItem('planValues',JSON.stringify({gPrice:gold_price, sPrice:silver_price, bPrice:bronze_price}))
        }
        return window.location.href="/checkout";
          
    }

    const handlePayment = async (orderid,price,preConfig,subscription_type) => {
       
        let final_price= price
        const stripe = await stripePromise;
        if(subscription_type===0 || subscription_type===1) //If plan choosed
        {
            const response = await fetch(`${API}/create-subscription-session`, {
                method:'POST',
                headers: {'Content-Type': 'application/json',},
                body: JSON.stringify({ 
                  'price': final_price + '00',
                  'qty': '1',
                  'order' : String(orderid),
                  'checkconfig': preConfig,
                  'subs_type': String(subscription_type),
                  'userid': String(user._id)
                  })
              });
              
              const session = await response.json();
              const result = await stripe.redirectToCheckout({
                sessionId: session.id,
              })
      
             if (result.error) {
             // If `redirectToCheckout` fails due to a browser or network
             // error, display the localized error message to your customer
             // using `result.error.message`.
             }else{
         
             }
        }
        else{
        const response = await fetch(`${API}/create-checkout-session`, {
           method:'POST',
           headers: {'Content-Type': 'application/json',},
           body: JSON.stringify({ 
             'price': final_price + '00',
             'qty': '1',
             'order' : String(orderid),
             'checkconfig': preConfig,
             'subs_type': String(subscription_type),
             'userid': String(user._id)
             })
         });
         
         const session = await response.json();
         const result = await stripe.redirectToCheckout({
           sessionId: session.id,
         })
 
        if (result.error) {
            setValues3({...values3, spinloading:false});
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        }else{
            setValues3({...values3, spinloading:false});
        }
    }
    
    };
    const getTotal1 = () => {
        return items.reduce((currentValue, nextValue) => {
            return currentValue + nextValue.count * nextValue.sale_price;
        }, 0);
    };
    const clickSubmit = (event) => {
        setValues3({...values3, spinloading:true});
        event.preventDefault();
        let final_price=(getCartTotal(items)+Number(getTotal()));
        let ProdDescription;
        let ProdTitle;
        let Prodid;
        let Prodprice;
        let Prodquan;
        let Prodname;

        if(items.length>0 &&  (packagehead !== undefined && packagehead !=="")){
            Prodquan = items.map((p,i) => (p.count))+","+temp_quantity;
            ProdTitle=items.map((p,i) =>(p.count)+" x "+(p.name).split(','))+"," +pack_quantity+" x "+packagehead;
            ProdDescription =items.map((p,i) => (p.count)+" x "+(p.name).split(',') + "<br/>")+"" +packageName;
            Prodid = items.map((p,i) => (p._id).split(','))+","+temp_productid;
            Prodprice = items.map((p,i) => (p.sale_price))+","+temp_price;
            Prodname = items.map((p,i) => (p.name))+","+temp_name;
           
        } else if(items.length>0 && (packagehead === undefined || packagehead ==="")){
            Prodquan = items.map((p,i) => (p.count))+",";
            ProdTitle=items.map((p,i) =>(p.count)+" x "+(p.name).split(','));
            ProdDescription =items.map((p,i) => (p.count)+" x "+(p.name) + "<br/>");
            Prodid = items.map((p,i) => (p._id).split(','))+",";
            Prodprice = items.map((p,i) => (p.sale_price))+",";
            Prodname = items.map((p,i) => (p.name))+",";
            final_price = (getTotal1());
        } 
         else {
            ProdTitle=items.map((p,i) => (p.name).split(','))+" " +packagehead;
            ProdDescription =items.map((p,i) => (p.name).split(','))+"" +packageName;
            Prodid = temp_productid;
            Prodprice = temp_price;
            Prodquan = temp_quantity;
            Prodname = temp_name;
        }

        // Prodquan = items.map((p,i) => (p.count))+","+temp_quantity;
        // ProdTitle=items.map((p,i) =>(p.count)+" x "+(p.name).split(','))+"," +pack_quantity+" x "+packagehead;
        // ProdDescription =items.map((p,i) => (p.count)+" x "+(p.name).split(',') + "<br/>")+"" +packageName;
        // Prodid = items.map((p,i) => (p._id).split(','))+","+temp_productid;
        // Prodprice = items.map((p,i) => (p.sale_price))+","+temp_price;
        // Prodname = items.map((p,i) => (p.name))+","+temp_name;
       
        
        if(isAuthenticated()) {
            UpdateUserOrder({orderId:match.params.orderId, userid: user._id,Prodid, Prodquan,Prodname, Prodprice,order_description:`${ProdDescription}`, title:`${ProdTitle}` ,quantity:pack_quantity, price: final_price},user._id,token)
            .then(data => {
                if(data.error) {
                    setValues({...values, error: data.error, success:false,btnloading:false})
                } else {
                    
                      //remove all local storage
            localStorage.removeItem('package1');
            localStorage.removeItem('cart1');
            localStorage.removeItem('packageQnty1');
            //window.location.href="/success/" ;
           
            let json = JSON.parse(JSON.stringify(data));
            let order_id = match.params.orderId;
            {handlePaymentLocal(order_id,ProdTitle,pre_config,subscription_type,final_price)} 
                }
            })
        }
      
    }

    // const getTotal = () => {
    //     return items.reduce((currentValue, nextValue) => {
    //         return currentValue + nextValue.count * nextValue.sale_price;
    //     }, 0);
    // };
    const getTotal = () => {
        let  tot1=0;
      
        const packData1 = JSON.parse(localStorage.getItem('package1'));
        if(localStorage.getItem('package1')){
            tot1=([packData1.packageTotprice])
        }
        return tot1[0];
       
    };
    

    return (
        <Fragment>
            <Helmet>
                <title>Safely Team - Update Package</title>
            </Helmet>
        <div className="container-scroller">
           <Navbar />
           
           <div className="container-fluid page-body-wrapper">

           <Sidebar />

                   <div className="main-panel">
                     <div className="content-wrapper white-bg-ad">
                       <div className="row">
                         <div className="col-md-12 grid-margin">
                           <div className="d-flex justify-content-between align-items-center">
                             <div>
                             {  (checkPackage!=="")? <h4 className="font-weight-bold mb-0">Update Package</h4>:
                             <h4 className="font-weight-bold mb-0">Update Products</h4>}
                             </div>
                           <div>  
                         </div>
                       </div>
                     </div>
                   </div>
{  (checkPackage!=="")?
                   <div className="row border-who">
                            <div className="col-md-6 p-5 text-center"><img src={require('./img/customised-package.jfif')} className="img-fluid" alt="" /></div>
                            <div className="col-md-6 pl-5 pt-5">                               
                            <h3 className="font-hd-on-in">Customised Safely<br/> Package</h3>
                            
                                <h3 className="prod-inclu">Products Included</h3>
                                <div className="row">
                            <div className="col-md-8">
                            <div className="included-detail">
                                <div className="row" dangerouslySetInnerHTML={{__html: packageitems}}>
                             
                                </div>                                  
                                  
                               
                                </div>
                           </div>
                          
                       </div>         
                                <div className="row pt-3">
                                    <div className="col-md-3 pt-1">
                                        {(btnloading1) ? <div className='loader-container'><div className='loader'></div></div> : <h3 className="font-hd-on"><span className="included-detail">£</span>{packageprice}</h3>}
                                    </div>
                                    <div className="col-md-6"> </div>
                                </div>
                            </div>
                        </div>:""}

                        <div className="row ">
                        <div className="col-md-12">
                           
                            <center>
                            <div className="col-md-12 py-4">
                                <h2 className="prod-inclu blue-acti" style={{fontSize: '22px',lineHeight: '1.5', borderBottom: '1px solid #dfdfdf'}}>Add more Products</h2>
                                {(btnloading) ? <div className='loader-container'><div className='loader'></div></div> : ""}  
                            </div>
                            {products.map((product, i) => (
                              
                                <div key={i} className="col-md-12 px-0 btm-bo-air pb-2 mb-3">
                                  
                                    <div className="row pro-list-item">
                                    <div className="col-md-2 p-0 add-more-img-pad small-img">             
                                     <ShowImage  item={product._id}/>
                                    </div>
                                    <div className="col-md-6">
                                    <h4 className="font-gen"><a target='blank' href={'/product-details/'+product._id}>{product.name}</a> &nbsp;&nbsp;&nbsp; </h4>
                                    <p>{product.description}</p><br/>
                                    <p><b>Tips: </b>{product.tips}</p>
                
                                </div>
                                <div className="col-md-4">              
                                    <h4 className="font-gen" style={{textAlign:'center',marginBottom:'0px'}}>£ {product.sale_price}</h4>
                                                    
                                    <sub><i>Increase as per your requirements</i> </sub>
                                    <div className="pt-3" >                  
                                    {showAddToCart(product,items)}             
                                    
                                </div>
                {/* <sub><i>Try not to stress. It's anything but difficult to add all the more later, or return ones you needn't bother with.</i></sub> */}
                            </div>
                        </div>
                    </div>

                                
                            ))}
                          
                          </center>
                    </div>
                    

                </div>

                <div className="col-md-12 inner-tp-pad inner-safely">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6 bg-summa" style={{float:'right'}}>
                                    <table className="table table-bordered table-responsive table-review" style={{border:'none'}}>
                                               
                                        <tbody style={{border:'none'}}>                        
                                                 {packDetails()}   
                                                   {prodDetails()} 
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                         </div>
                    </div>
                <div className="col-md-12 inner-tp-pad inner-safely">
                <div className="container">
                <div className="row">
<div className="col-md-6"></div>
<div className="col-md-6 bg-summa" style={{float:'right'}}>
                                
                                          

                                            <div className="row py-3 shipping-bor">
                                                <div className="col-md-6 blue-dis wid-40-per">
                                                    Total
                                                </div>
                                                <div className="col-md-6 blue-dis text-right wid-40-per">
                                                    
                                                £{(getCartTotal(items)+Number(packageprice)).toFixed(2)} 
                                                </div>                               
                                            </div>
                                            {/* {(subscription_type===1)?
                  <div className="row"><em style={{textAlign:'right',width:'100%'}}> <hr/>(£{gold_price} / mo recurring payment starts on next month)</em></div> :
                 (subscription_type===0)?
                 <div className="row"><em style={{textAlign:'right',width:'100%'}}> <hr/>(£{silver_price} / mo recurring payment starts on next month)</em></div>:
                 ""
                    } */}
                    <div className="row font-we-650 py-3 text-dark-gr">
                <div className="col-md-9 font-one-edit text-dark-gr">
                    {(subscription_type===1)?
                        <span>  Gold subscription </span> :
                        (subscription_type===0)?
                        <span>  Silver subscription  </span> :
                        (subscription_type===2)?
                        <span>  Bronze subscription  </span>:""
                    }
                </div>
                <div className="col-md-3 font-one-edit text-dark-gr">
                    {(subscription_type===1)?
                        <span>  £{gold_price} / mo </span> :
                        (subscription_type===0)?
                        <span>  £{silver_price} / mo </span>:
                        (subscription_type===2)?
                        <span>  £{bronze_price} / mo </span>:
                        ""
                    }   
                  
                </div>
            </div>  
                                            <div className="row py-3 mb-5">
                                                <div className="col-md-12">
                                              
                                       
                                                <button onClick={clickSubmit}  disabled={spinloading} className="btn btn-success pull-right mb-3" data-toggle="modal" data-target="#send-request">{spinloading ? "Loading..." : "Update & Pay"}</button>                        
                                                </div>                         
                                            </div></div>
                                      </div></div>
                                        </div>
                       
    
                 </div> 
               </div>

           </div>

   </div>


   </Fragment>
    )
}

export default UpdatePackage
