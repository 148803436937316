import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom';
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import {updateEmailTemplate, getEmailTemplate} from '../admin/apiAdmin'
// import CKEditor1 from 'ckeditor4-react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { API } from '../config'

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const UpdateEmailTemplate = ({match}) => {

    const classes = useStyles();

    const [loading, setLoading] = useState(true);

    const { user, token} = isAuthenticated()

    const [values, setValues] = useState({
        name:'',
        subject:'',
        content:'',
        error:'',
        success:false,
        btnloading:false
    })

    const { name, subject, content, error, success, btnloading} = values;

    useEffect(() => {
        loadTemplates();
        
       
    },[])

    const handleChange1 = (item)=>{
        console.log("Item",item);
        setValues({ error: false, content:item,btnloading:false});
    }

    const loadCkEditor = () => {
        if(document.querySelector("#editor1") ){
            
            const editor = window.CKEDITOR.replace('editor1',{
             filebrowserUploadUrl: `${API}/upload`,
             removePlugins:'save,exportpdf'
         } )

         // editor.setData(values.serverResponse)
            editor.on('change',(evt)=>{
                const data1 = editor.getData()
                handleChange1(data1)
            })
            
        }
    }

    const loadTemplates = () => {
        getEmailTemplate(match.params.templateId)
        .then(data => {
            if(data.error){
                setValues({...values, success:false, error:data.error});
                setLoading(false);
            }
            else {
                setValues({
                    ...values,
                    name:data.name,
                    subject:data.subject,
                    content:data.content,
                })

                if(!document.querySelector("#editor1") ){
                    let area = document.createElement('textarea');
                    area.id='editor1';
                    area.hidden=true;
                    //area.defaultValue=data.content;
                    //area.value = data.content;

                    console.log("Val",area.value )
                    console.log("Val1",data.content )
                
                    document.querySelector('#edt').appendChild(area);
                    loadCkEditor();
                }

                setLoading(false);
                
            }
        })
    }

    const handleChange = name => event => {
        console.log("Name",name)
        var value = (name === "content" ?  event.editor.getData() : event.target.value)
        setValues({ ...values, error: false, [name]:value,btnloading:false});
    };

    const clickSubmit = (e) => {
        e.preventDefault();
        setValues({...values, btnloading:true, success:false, error:''});
        updateEmailTemplate(user._id, token, {id:match.params.templateId, name, subject, content})
        .then(data => {
            if(data.error){
                setValues({...values, btnloading:false, success:false, error:data.error})
            }
            else {
                setValues({
                    ...values,
                    name:'',
                    content:'',
                    subject:'',
                    error:'',
                    success:true,
                    btnloading:false
                })
            }
        })
    }

    const showForm = () => (
        <form onSubmit={clickSubmit}>
            <div className="form-group">
                <label className="text-muted">Template Name:<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('name')} type="text" className="form-control" value={name} required/>
            </div>
            <div className="form-group">
                <label className="text-muted">Subject:<span style={{color:"red"}}> *</span></label>
                <input onChange={handleChange('subject')} id="txtsub" type="text" className="form-control" value={subject} required/>
            </div>
            <div>
            <label className="text-muted">Content:<span style={{color:"red"}}> *</span></label>
            {/* <CKEditor1
                    data={content}
                    config={{
                        ckfinder: {
                          // Upload the images to the server using the CKFinder QuickUpload command.
                          uploadUrl: "http://localhost:3000/public/uploads/",                         
                          options: {
                            resourceType: "Images",
                          },
                        },
                      }}                         
                    onChange={handleChange('content')} /> */}
                     {/* <textarea className="form-control" id='editor1' hidden={true} defaultValue={content} value={content}/> */}
                     {/* <textarea className="form-control" name="editor2" id="editor2" defaultValue={content} value={content}/> */}
                     
                        <div id='edt'></div>
                 
                   
            </div> 
           
            <br/>
            <center>
                <button  className="btn btn-outline-primary" disabled={btnloading}> {btnloading ? "Loading..." : "Update"} </button>
            </center>         
        </form>
    )

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => {
        if(success) {
            return <Redirect to="/admin/email/templates" />
        }
    };


    return (
        <AdminLayout className="container" topTitle="Update Email Template">
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title" style={{textAlign:'center'}}>Update Email Template</h4>
                            {!loading && showSuccess()}
                            {showError()}
                            {showForm()}
                            <Backdrop className={classes.backdrop} open={loading} >
                                <CircularProgress color="inherit" />
                            </Backdrop>  
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default UpdateEmailTemplate
