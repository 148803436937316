import React, { useState, useEffect} from 'react'
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import {Link} from 'react-router-dom'
import {getEmailTemplates} from './apiAdmin'
import { forwardRef } from 'react';
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Moment from 'react-moment';

const ManageEmailTemplates = () => {

    const {user, token} = isAuthenticated()

    const [values, setValues] = useState({
        btnloading:true
    });

    const {btnloading} = values

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };

    var columns = [
       
        {title: "id", field: "_id", hidden: true},
        {title: "Template Name", field: "name"},
        {title: "Date", field: "createdAt", render: rowData => {return <Moment format='DD/MM/YYYY'>{rowData.createdAt}</Moment>}},
        {title: "Actions",render: rowData => <Link to={`/admin/update/email/template/${rowData._id}`}><i className="ti-pencil-alt"></i></Link>},
        ]

    const [templates, setTemplates] = useState([])

    const loadTemplates = () => {
        getEmailTemplates().then(data => {
            if (data.error) {
                console.log(data.error);
                setValues({...values,   btnloading:false});
            } else {
                setTemplates(data);
                setValues({...values,   btnloading:false});
            }
        });
    };


    useEffect(() => {
        loadTemplates()
    }, [])

    
 

    return (
        <AdminLayout topTitle="Manage Email Templates">

            <div className="row">     
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                        <h4 className="card-title" style={{textAlign:'center'}}>Manage Email Templates</h4>

                                    <MaterialTable
                                    title=""
                                    columns={columns}
                                    isLoading={btnloading}
                                    data={templates}
                                    icons={tableIcons}
                                    options={{
                                        pageSize:10
                                    }}
                                    localization={{ body:{ emptyDataSourceMessage:<h6>No email templates to display</h6> } }}
                                    />
                        </div>
                    </div>
                </div>
            </div>
            
        </AdminLayout>
    )
}

export default ManageEmailTemplates;