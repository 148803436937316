import React from 'react'
import Subscribe from './Subscribe'
import NewLayout from './NewLayout'

const Cookies = () => {
    return (
        <NewLayout topTitle="Cookie Policy | Best Anti-Theft Prevention System In UK | SafelyTeam" metaDescription="Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website. Best home safey system in UK.">
          

                        <div className="section pp-scrollable slide-dark slide-dark-footer">                        
                            <div className="col-md-12 px-0 inner-tp-pad inner-safely">
                                <div className="container">
                                    <div className="col-md-10 inner-header">
                                        <h1 className="white-inner-txt abt-hd-res">Cookies </h1>                                       
                                    </div>
                                </div>
                                <img src={require('./img/abt-us.png')} className="img-fluid" alt=""/> 
                            </div>

                            <div className="col-md-12 inner-safely">
                                <div className="container">                                  
                                    <div className="row ptb-inner">
                                        <div className="col-md-12 px-0">                                    
                                            <p className="inner-line">Our website uses cookies to distinguish you from other users of our website. This helps us to
provide you with a good experience when you browse our website and also allows us to improve
our site.</p>    
                                            <p className="inner-line">A cookie is a small file of letters and numbers that we store on your browser or the hard drive of
your computer if you agree. Cookies contain information that is transferred to your computer&#39;s hard
drive.</p> 
                                            <p className="inner-line">We use the following cookies:</p> 
                                            <ul>
                                                <li><b>Strictly necessary cookies.</b> These are cookies that are required for the operation of our
website. They include, for example, cookies that enable you to log into secure areas of our
website, use a shopping cart or make use of e-billing services.</li>
                                                <li><b>Analytical or performance cookies.</b> These allow us to recognise and count the number of
visitors and to see how visitors move around our website when they are using it. This helps us to
improve the way our website works, for example, by ensuring that users are finding what they
are looking for easily.</li>
                                                <li>Functionality cookies. These are used to recognise you when you return to our website. This
enables us to personalise our content for you, greet you by name and remember your
preferences (for example, your choice of language or region).</li>
                                            </ul>
                                            <br/>
                                            <p className="inner-line">You can find more information about the individual cookies on our site and the purposes for which
we use them in the table below:</p>

                                <div className="table-responsive">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Cookie Title<br/>Cookie Name</th>
                                                        <th>Purpose</th>
                                                        <th>Expiry</th>
                                                        <th>More information</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>__stripe_mid</td>
                                                        <td>Stripe – Site management cookie:<br/>It is used to make credit<br/>card payments and also to<br/>provide fraud prevention</td>
                                                        <td>1 year</td>
                                                        <td>For more information consult<br/><a href="https://stripe.com/en-gb/privacy" target="_blank" style={{textDecoration:'underline',color:'black'}}>Stripes Privacy Policy</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>_hjid</td>
                                                        <td>Hotjar - Site management<br/>cookie: It is used to persist the<br/>Hotjar User ID, unique to that<br/>site on the browser.</td>
                                                        <td>Session</td>
                                                        <td>For more information, please see the<br/><a href="https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar" target="_blank" style={{textDecoration:'underline',color:'black'}}>‘about Hotjar’ section of Hotjar’s support site</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>__stripe_sid</td>
                                                        <td>Stripe – Security cookie: To<br/>provide fraud prevention</td>
                                                        <td>Session</td>
                                                        <td>For more information consult<br/><a href="https://stripe.com/en-gb/privacy" target="_blank" style={{textDecoration:'underline',color:'black'}}>Stripes Privacy Policy</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>_ga</td>
                                                        <td>Google Analytics – Analytics<br/>cookie: Used to distinguish<br/>users on the Google Analytics<br/>platform.</td>
                                                        <td>2 Years</td>
                                                        <td>For more information read<br/><a href="https://support.google.com/analytics/answer/6004245" target="_blank" style={{textDecoration:'underline',color:'black'}}>Google's overview of privacy and safeguarding data</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>_gid</td>
                                                        <td>Google Analytics – Analytics<br/>cookie: Used to distinguish<br/>users on the Google Analytics<br/>platform.</td>
                                                        <td>1 day</td>
                                                        <td>For more information read<br/><a href="https://support.google.com/analytics/answer/6004245" target="_blank" style={{textDecoration:'underline',color:'black'}}>Google&#39;s overview of privacy and safeguarding data</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>_fbp</td>
                                                        <td>Facebook – Advertising cookie:<br/>To store and track visits across<br/>websites, mainly used for<br/>marketing and tracking.</td>
                                                        <td>3 months</td>
                                                        <td>For more information consult<br/><a href="https://www.facebook.com/policy.php" target="_blank" style={{textDecoration:'underline',color:'black'}}>Facebook’s Privacy Policy</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>__zlcmid</td>
                                                        <td>Zendesk – Third Party cookie:<br/>To store a unique userId for chat<br/>bot</td>
                                                        <td>Persistent</td>
                                                        <td>For more information consult<br/><a href="https://www.zendesk.com/company/customers-partners/privacy-policy/" target="_blank" style={{textDecoration:'underline',color:'black'}}>Zendesk’s Privacy Policy</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>_hjAbsoluteSessionInProgress</td>
                                                        <td>Hotjar - Third Party cookie: This<br/>cookie is used to detect the first<br/>pageview session of a user.<br/>This is a True/False flag set by<br/>the cookie.</td>
                                                        <td>30 minutes</td>
                                                        <td>For more information, please see<br/><a href="https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar" target="_blank" style={{textDecoration:'underline',color:'black'}}>the ‘about Hotjar’ section of Hotjar’s support site</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>_hjIncludedInSessionSample</td>
                                                        <td>Hotjar - Third Party cookie: This<br/>cookie is set to let Hotjar know<br/>whether that visitor is included in<br/>the data sampling defined by<br/>your site&#39;s daily session limit.</td>
                                                        <td>30 minutes</td>
                                                        <td>For more information, please see<br/><a href="https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar" target="_blank" style={{textDecoration:'underline',color:'black'}}>the ‘about Hotjar’ section of Hotjar’s support site</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>1P_Jar</td>
                                                        <td>Google Analytics – Analytics<br/>cookie: Set by google, it sets a<br/>unique id to remember your<br/>preference and other<br/>information such as websites<br/>statistics and track conversion<br/>rates</td>
                                                        <td>30 days</td>
                                                        <td>For more information read<br/><a href="https://support.google.com/analytics/answer/6004245" target="_blank" style={{textDecoration:'underline',color:'black'}}>Google&#39;s overview of privacy and safeguarding data</a></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                            </div>
                                            <br/>

                                            <p className="inner-line">To deactivate the use of third party advertising cookies, you may use our cookie consent tool to
manage the use of these types of cookies</p>                                                     
                                            <p className="inner-line">You can block cookies by activating the setting on your browser that allows you to refuse the setting
of all or some cookies. However, if you use your browser settings to block all cookies (including
essential cookies) you may not be able to access all or parts of our website.</p> 
                                            
                                       </div>
                                    </div>
                                        
                                    <div className="row">
                                        <div className="col-md-12 px-0">
                                                                                       
                                        </div>
                                    </div>
                                    <div className="row ptb-inner">
                                        <div className="col-md-4 px-0 pt-5"> <img src={require('./img/build-btm.jpg')} className="img-fluid" /> </div>
                                        <div className="col-md-4 px-0 pt-5"> </div>
                                        <div className="col-md-4 px-0 pt-5"> <img src={require('./img/right-man.jpg')} className="img-fluid" /> </div>
                                    </div>
                                </div>
                            </div>
                                              
                        <Subscribe />
                    </div>
                           
        </NewLayout>
    )
}

export default Cookies
