import React, { useState, useEffect } from 'react'
import AdminLayout from '../theme/admin/AdminLayout'
import { isAuthenticated } from '../auth/index'
import { getOrdersById,getUser, createSubscriptionLog,getCouponByOrderId,updateShipmentDetails,getShipment,getNotes,updateTechnicianStatus, getSubOrdersByOrderid,updateAssignDevice, updateRoomName, updateDeviceId,getStatusValues,updateOrdStatus,getOrderLog,updateUserSubscriptionHistoryUser,getReturnProd,updateOrdStatus1,updateShipmentPickup } from '../admin/apiAdmin'
import Moment from 'react-moment';
import {jsPDF} from 'jspdf'
//import 'jspdf-autotable'
import { CSVLink } from "react-csv";
import { Modal,Table } from 'react-bootstrap'
import { STRIPE_PUBLIC, API,SHIPMENT_KEY } from "../config";
import ShowReturnImg from './ShowReturnImg'
import {Link} from 'react-router-dom'
import Base64Downloader from 'react-base64-downloader';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));


const OrderDetails = ({match}) => {

    const {user, token} = isAuthenticated()
    const [values1, setCheck] = useState({check:false, selectedid:1000})
    const [selectedCustoms, setCustoms] = useState(',')
    const [ retType,setRetType] = useState(0);
    const [ label, setLabel] = useState("");
    const [rateDetails,setRateDetails] = useState([])
    const [trackDetails, setTrackDetails] = useState([])
    const [checkTrack, setCheckTrack] = useState(false);
    const [checkRate,setCheckRate] = useState(false)
    const [btnloading3,setBtnloading3] = useState(false);
    const [btnloading4,setBtnloading4] = useState(false);
    const [checkError,setCheckError] = useState("");
    const [checkShipment, setCheckShipment] = useState(false);
    const [shipmentID,setShipmentID] = useState("");
    const [shipError,setShipError] = useState("");
    const [totalWeight,setTotalWeight] = useState(0);

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const UserID = match.params.userId;

    const [values2, setValues2] = useState({
        btnloading:true
    });

    const [notes,setNotes] = useState([]);

    const {btnloading} = values2
    const {check,selectedid} = values1
    const [show, setShow] = useState(false);
    const [showdelayed, setShowDelayed] = useState(false);
    const [showCancelled, setShowCancelled] = useState(false);
    const [showRejected, setShowRejected] = useState(false);
    const [reject_reason, setRejectReason] = useState("");

    let [check2,setCheck2] = useState(false);
    const [pickupModal, setPickupModal] = useState(false);
    let [count,setCount] = useState(0);
    const [length1,setLength1] = useState(-1);

    const [returnData, setReturnData] = useState([])
    const [ btnloading2, setBtnloading2] = useState(false);

    const [couponValues,setCouponValues] = useState({
        coupon_name :'',
        discount_value:''
    })

    const {coupon_name, discount_value} = couponValues;

    const [btnloading5, setBtnloading5] = useState(false);

    const [ordValue, setOrdValue] = useState({
        ordid:'',
        ordId1:'',
        tempId:'',
        tempStatus:true,
        success: false,
        error:'',
        btnloading1:false
    });
  
    const {ordid,ordId1,tempId,tempStatus, success, error,btnloading1} = ordValue
  

    const [values, setValues] = useState({
        id:'',
        id1:'',
        title: '',
        adId:'',
        email:'',
        price: '',
        shipping: '',
        userid: '',
        createdAt: '',
        firstname:'',
        isSubCancelled:'',
        lastname:'',
        quantity: '',
        phone:'',
        subscription_paid:'',
        status: '',
        paymentId:'',
        payment_date:'',
        discount:'',
        subscription_type:'',
        subscription_type_user:'',
        order_description: '',
        cancelledReason:'',
        preconfig_status:false,
        subscription_amount:'',
        subscriptionId:'',
        subscriptionid:'',
        system_type:'',
        source:'',
        spinloading:true

    });

    const {id,isSubCancelled,subscriptionId,subscriptionid,subscription_type_user,subscription_paid,email,source, id1,title,adId,cancelledReason,subscription_amount,system_type,spinloading, price,preconfig_status, shipping,paymentId,payment_date,subscription_type,discount,status, phone, userid,quantity,order_description, firstname,lastname, createdAt} = values

    const [shippingValues, setShippingValues] = useState({

        pin_code:'',
        house_no:'',
        area:'',
        city:'',
        state:'',
        country:'',
        prefix:''

    })

    const {prefix, pin_code, house_no, area, city, state, country} = shippingValues

     const [ suborders, setSuborders] = useState([]); 
     const [statusValues, setStatusValues] = useState([]);
     const [ orderlog, setOrderLogs ] = useState([]);

     const [values3, setValues3] = useState({
        message:'',
        weight:'',
        length:'',
        height:'',
        width:'',
        boxType:''
    })

    const { message,weight,length,width,height,boxType} = values3;

    const [values4, setValues4] = useState({
        reason:'',
        resolution_time:''
    })

    const { reason, resolution_time} = values4;

    const [values5, setValues5] = useState({
        cancelled_reason:''
    })

    const { cancelled_reason} = values5;

    const [values6, setValues6] = useState({
        refund_status:'',
        refund_date:'',
        refund_amount:'',
        refund_id:''
    })

    const {refund_status,refund_amount,refund_date,refund_id} = values6;

    const [values7,setValues7] = useState({
        preferred_date:'',
        preferred_max_time:'',
        preferred_min_time:''
    })

    const {preferred_date,preferred_max_time,preferred_min_time} = values7;

    const [pickupError,setPickupError] = useState("");

    const [pickupSuccess,setPickupSuccess] = useState("");
    const [pickupDate,setPickupDate] = useState("");


    const loadOrders = (orderId) => {
        getOrdersById(orderId).then(async data => {
            if(data.error) {             
                setValues({...values,spinloading:false})
                setOpen(false);
            } else {
               
                if((data.status === "Order Cancelled" || data.status === "Return Completed") || (data.paymentId !== undefined && data.paymentId !== "undefined" && data.paymentId !== "")){
                  
                    paymentdetails(data.paymentId); 
                } 
                if(data.status === "Shipped"){
                    let shipmentData1 = await getShipment(user._id,token,{orderId:match.params.orderId});
                    
                    if(shipmentData1.shipmentid !== undefined && shipmentData1.shipmentid !== "" && shipmentData1.shipmentid !== null){
                        setCheckShipment(true);
                        setShipmentID(shipmentData1.shipmentid);
                        setLabel(shipmentData1.label);
                        if(shipmentData1.preferred_date){
                            setPickupDate(shipmentData1.preferred_date);
                        }
                    }
                }
              
                setValues({
                    ...values,
                    id1:data._id,
                    email:data.userid.email,
                    id: data.orderid,
                    title: data.title,
                    adId: data.adId,
                    price:data.price,
                    shipping:data.shipping,
                    system_type:data.system_type,
                    userid:data.userid._id,
                    subscriptionid:data.userid.subscriptionid,
                    isSubCancelled: data.userid.isSubCancelled,
                    subscription_paid: data.userid.subscription_paid,
                    firstname: data.userid.name,
                    lastname: data.userid.lastname,
                    subscription_amount: data.userid.subscription_amount,
                    createdAt: data.createdAt,
                    quantity:data.quantity,
                    phone: data.userid.phone,
                    subscriptionId: data.subscriptionId,
                    status: data.status,
                    paymentId: data.paymentId,
                    payment_date: data.payment_date,
                    discount: data.discount,
                    subscription_type: data.subscription_type,
                    subscription_type_user: data.userid.subscription_plan,
                    order_description: data.order_description,
                    preconfig_status:data.preconfig_status,
                    cancelledReason:data.cancelled_reason,
                    source:data.source,
                    spinloading:false
                });
              
                setOpen(false);

                if(data.shipping){
                    setShippingValues({
                        ...shippingValues,
                        pin_code: data.shipping.pin_code,
                        house_no: data.shipping.house_no,
                        area: data.shipping.area,
                        city: data.shipping.city,
                        state: data.shipping.state,
                        country: data.shipping.country,
                        prefix:data.shipping.prefix,
                    })
                }
                if(Number(data.discount) !== 0) {
                    loadCoupons(match.params.orderId);
                }
            }
        });
    };

    const paymentdetails = async (paymentId) => {

        if(paymentId.includes("sub_")){
            const Customerresponse2 = await fetch(`${API}/all-invoices/subscriptionId`, {
                method:'POST',
                headers: {'Content-Type': 'application/json',},
                body: JSON.stringify({ 
                'subscriptionId': String(paymentId),
                'userid': String(UserID)        
                })
            });  
            const allInvoices = await Customerresponse2.json();
            
            var getInvoices = allInvoices.data[0];
            paymentId = getInvoices.payment_intent;
           
        }

        const response = await fetch(`${API}/get-refund`, {
            method:'POST',
            headers: {'Content-Type': 'application/json',},
            body: JSON.stringify({ 
                'paymentid': String(paymentId),
                'userid': String(UserID)             
                })
        })

        const payment = await response.json();
       
        var getRefund = payment.data[0];
        if(getRefund !== undefined){
            var tempRefundID = getRefund.id;

            const response1 = await fetch(`${API}/retrieve-refund`, {
                method:'POST',
                headers: {'Content-Type': 'application/json',},
                body: JSON.stringify({ 
                    'ID': String(tempRefundID),
                    'userid': String(UserID)             
                    })
            })

            const payment1 = await response1.json();

            setValues6({
                refund_date:payment1.created,
                refund_id:payment1.id,
                refund_amount:payment1.amount,
                refund_status:payment1.status
            })
        }
        

    }

     const loadSubOrders = (orderId) => {
        getSubOrdersByOrderid(orderId).then(data => {
            if(data.error) {
              
                setValues2({...values2, btnloading:false});
            } else {
                /* setSuborders(data); */
                setValues2({...values2, btnloading:false});
                setLength1(data.length);
                var sorting = ["5f773c5c541f443e5ce64aaa","5f7b2d5bffa2800728e971b5","5f7b2fc3ffa2800728e971c3","5f7b2dc6ffa2800728e971b7","5f7b2f0bffa2800728e971bf","5f7b302bffa2800728e971c5","5f7b2e6fffa2800728e971bb","5f9d650ff268b98214e737bf","5f9d6592f268b98214e737c1","5f7b2ec3ffa2800728e971bd","5f7b2e1effa2800728e971b9","5f7b2f74ffa2800728e971c1"] // Gateway,Siren,Smart Plug,AirQuality,Contact Sensor,Flood Alarm,Heat Sensor,Keypad,KeyTags,Motion Sensor,Panic Butoon,Smoke Alarm
               
                var result = [];
                for(var i=0;i<sorting.length;i++){
                    for(var j=0;j<data.length;j++){

                        var temp=data[j].productid;

                        if(sorting[i] === temp._id){
                            result.push(data[j]);
                            temp="";
                        }
                    }
                }
                setSuborders(result);
               
                var count1 = 0;               
                for(var i=0;i<data.length;i++){
                   var tempCheck = data[i].technician_verified                  
                    if(tempCheck){
                        count1+=1;
                    }           
                }             
                setCount(count1);
                var sum =0;
                for(var i=0;i<data.length;i++){
                    sum += data[i].productid.weight;
                }
                setTotalWeight(sum);
                setValues3({...values3,weight:sum});
            }
        });
    }; 

    const loadOrderlogs = (orderId) => {
        getOrderLog(orderId).then(data => {
            if(data.error) {
                console.log(data.error);
            } else {
                setOrderLogs(data);
              
            }
        });
    }; 

    const loadNotes = (orderId) => {
        getNotes(orderId).then(data => {
            if(data.error) {
                console.log(data.error);
            } else {
                setNotes(data);
            }
        });
    };
    
    const loadCoupons = (orderId) => {
        getCouponByOrderId(user._id,token,{orderId}).then(data => {
            if(data.error) {
                console.log(data.error);
            } else {
                setCouponValues({
                    ...couponValues,
                   coupon_name:data.couponid.coupon_code,
                    discount_value: data.orderid.discount 
                })
            }
        })
    }

    const loadStatusValues = () => {
        getStatusValues(user._id, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setStatusValues(data);
            }
        });
    };

    const loadReturnRequests = (orderId) => {
        getReturnProd(orderId).then(data => {
            if(data.error) {
                console.log(data.error);
            } else {
                setReturnData(data);
                if(data.length >=1){
                    setRetType(data[0].return_type);
                }
                
            }
        });
    }; 

    useEffect(() => {
        loadOrders(match.params.orderId);
        loadSubOrders(match.params.orderId); 
        loadStatusValues();
        loadOrderlogs(match.params.orderId);
        loadNotes(match.params.orderId);
        loadReturnRequests(match.params.orderId);
        
    }, []);

    const handleAssignDeviceChange = (e, suborderId) => {
         updateAssignDevice(user._id, token, suborderId, e.target.value).then(
            data => {
                if (data.error) {
                    console.log("Assign Device update failed");
                } else {
                    loadSubOrders(match.params.orderId); 
                }
            }
        ); 
    };

    

   const handleDeviceIdChange = (e, suborderId) => {
    updateDeviceId(user._id, token, suborderId, e.target.value).then(
       data => {
           if (data.error) {
               console.log("Device ID update failed");
           } else {
               loadSubOrders(match.params.orderId); 
           }
       }
   ); 
};

   const deviceID = (o,deviceid1) => (
      
           <center>
            <div className="form-group">

                <input type="text" className="form-control" onBlur={e => handleDeviceIdChange(e, o)} /* value={deviceid1} placeholder="Enter Device ID" */ defaultValue={deviceid1} placeholder="Enter Device ID Manually" />
            </div>
            
            <h4>or</h4>
            <a href={`/admin/barcode/${o}`}>Add Device</a>
            </center>
      
   )

   const handleRoomNameChange = (e, suborderId,i,selectedCustoms1) => {
    setOrdValue({...ordValue,error:'',btnloading1:true,error:false, tempStatus:true});
    
    if(e.target.value === "Custom") {  
        setCheck({...values1,check:true,selectedid:i});
        setCustoms(selectedCustoms1+i+",");            
        setOrdValue({...ordValue,error:'',btnloading1:false,error:false, tempStatus:true});
        return 0;
    }
    else
    { 
        var toRemove = ','+i+',';
        selectedCustoms1 = selectedCustoms1.replace(toRemove,",");     
        setCustoms(selectedCustoms1);
        setCheck({...values1,check:false,selectedid:i});
    }
    
    updateRoomName(user._id, token, suborderId, e.target.value).then(
        data => {
            if (data.error) {
              setOrdValue({...ordValue,error:'',btnloading1:false,error:false, tempStatus:true});
            } else {
              setOrdValue({...ordValue,error:'',btnloading1:false,error:false, tempStatus:true});
              
                //loadSubOrders(match.params.orderId);                    
            }
        }
     ); 
    };


    const handleRoomNameChange1 = (e, suborderId,i) => {

    /* setRoomlist(names => [...names,{label:e.target.value,value:e.target.value}]) */
    updateRoomName(user._id, token, suborderId, e.target.value).then(
      data => {
          if (data.error) {
              console.log("Room Name update failed");
          } else {
              //loadSubOrders(match.params.orderId); 
          }
      }
    ); 
  };

   const [roomList,setRoomlist] = useState([
    { label:"Kitchen ",value: "Kitchen"},
    { label:"Living Room",value: "Living Room"},
    { label:"Dining Room",value: "Dining Room"},
    { label:"Porch",value: "Porch"},
    { label:"Hallway",value: "Hallway"},
    { label:"Landing",value: "Landing"},
    { label:"Master Bedroom",value: "Master Bedroom"},
    { label:"Bedroom",value: "Bedroom"},
    { label:"Guest Bedroom",value: "Guest Bedroom"},
    { label:"Bathroom",value: "Bathroom"},
    { label:"En-Suite",value: "En-Suite"},
    { label:"Office",value: "Office"},
    { label:"Garage",value: "Garage"},
    { label:"Utility Room",value: "Utility Room"},
    { label:"Conservatory",value: "Conservatory"},
    { label:"Custom",value: "Custom"}
])

const roomName = (o,roomname,i) => (
    
    <div className="form-group mb-0">
      {roomname === "Key Tags" ? (<select className="form-control height-40-px" id="sel1">
        <option>Key Tags</option>
      </select>) :   (roomname === "Gateway") ? (<select className="form-control height-40-px" id="sel1">
        <option>Gateway</option>
      </select>) : (
        <div>

<select className="form-control height-40-px" id="sel1" onChange={e => handleRoomNameChange(e, o,i,selectedCustoms)}>
    
    <option value="">Choose Here</option>
    {    
        roomList.map(room => (                                            
            (roomname === room.value ? "" :                   
        
            <option key={room.value} value={room.value}>
                {room.label}
            </option>)     
        ))
    }
        { (roomname) ?  
            <option selected key={roomname} value={roomname}>
                  {roomname}  
                  </option> : "" } 
        </select>
        <br/>
        <input className="form-control" type="text" id={i} onBlur={e => handleRoomNameChange1(e, o,i)} defaultValue={roomname} placeholder="Enter Your Custom Room Name" style={{display:  ((contains(','+i+',',selectedCustoms)) ||(check && selectedid===i)) ? 'block' : 'none' }}/>

        </div>
      )}
        
    </div>
)


    const assignDevice = (o,assign_device1) => (
        <div className ="form-group">
            <input type="text" className="form-control" onBlur={e => handleAssignDeviceChange(e, o)} defaultValue={assign_device1} placeholder={assign_device1} />
        </div>
    )
    
    
    const handleChangeforShipment = name => event => {
        if(event.target.value === "Small"){
            setValues3({ ...values3, [name]: event.target.value,length:30.5, width:22.9,height:15.2,weight:totalWeight+300});
        }
        else if(event.target.value === "Medium"){
            setValues3({ ...values3, [name]: event.target.value,length:30.5, width:22.9,height:22.9,weight:totalWeight + 325});
        }
        else if(event.target.value === "Large"){
            setValues3({ ...values3, [name]: event.target.value,length:33, width:25.4,height:31.8,weight:totalWeight + 385});
        }
        else {
            setValues3({ ...values3, [name]: event.target.value});
        }
        
    }

    const handleChangeforPickup = name => event => {
        setValues7({...values7, [name]:event.target.value});
    }

    const clickSubmitShipment = async (event) => {
        setBtnloading3(true);
        event.preventDefault();
        setValues3({...values3});
      
            let shipment1 = await fetch('https://api.easyship.com/rate/v1/rates',{
                method:'POST',
                headers:{ 
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${SHIPMENT_KEY}`},
        
                body: JSON.stringify({

                    destination_country_alpha2: 'GB',
                    destination_city: String(city),
                    destination_address_line_1: String(house_no),
                    destination_postal_code: String(pin_code),
                    taxes_duties_paid_by: 'Sender',
                    is_insured: false,
                    apply_shipping_rules: true,
                    items: [
                      {
                        quantity: '1',
                        actual_weight: Number(weight)/1000,
                        height: Number(height),
                        width: Number(width),
                        length: Number(length),
                        category: 'home_appliances',
                        declared_currency: 'GBP',
                        /* declared_customs_value: 35 */
                      }
                    ],
                    

                })
            });
            
            let shipmentResults = await shipment1.json();
        
            var rateResults = [];
            var rateCount = shipmentResults.rates.length;
            for(var i=0;i<rateCount;i++){
                rateResults.push(shipmentResults.rates[i]);
            }
          
            if(rateCount >= 1){
                setRateDetails(rateResults);
                setCheckRate(true);
                setBtnloading3(false);
                setCheckError("");
            }
            else {
                setCheckError("Sorry, we couldn't find any shipping solutions based on the information provided.");
                setBtnloading3(false);
                setCheckRate(false);
            }
           

    }

    const clickSubmitShipment1 = (courierId,amount) => {

        if(window.confirm("Are you sure you want to continue?"))
        {
         updateOrdStatus(user._id, token,match.params.orderId, {orderId:match.params.orderId,length,width,height,weight,amount:amount, status:"Shipped",courierid:courierId,message}).then(
            data => {
                if (data.error) {
                    console.log("Status update failed");
                } else {
                    setShow(() => setShow(false))
                    setValues3({
                        ...values3,
                        message:'',
                        width:'',
                        height:'',
                        weight:'',
                        length:''
                    })
                    loadOrders(match.params.orderId);
                }
            }
        ); 

        }
    }

    const clickShipmentDetails = async () => {
        if(window.confirm("Are you sure you want to continue?"))
        {
            setBtnloading4(true);
            let shipmentData = await getShipment(user._id,token,{orderId:match.params.orderId});
            var tempID = shipmentData._id;
            var tempAddress2="";
            if(area !== undefined){
                tempAddress2=area;
            }

           let createShipment = await fetch('https://api.easyship.com/shipment/v1/shipments/create_and_buy_label',{
                method:'POST',
                headers:{ 
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${SHIPMENT_KEY}`
                },
        
                body: JSON.stringify({
                    taxes_duties_paid_by:'Sender',
                    is_insured: false,
                    selected_courier_id: String(shipmentData.courierid),
                    allow_courier_fallback:false,
                    destination_country_alpha2: 'GB',
                    destination_city: String(city),
                    destination_postal_code:String(pin_code),
                    destination_name: String(prefix+'. '+firstname+' '+lastname),
                    destination_address_line_1:String(house_no),
                    destination_address_line_2:String(tempAddress2),
                    destination_phone_number:String(phone),
                    items:[
                        {
                            description:'text',
                            actual_weight: Number(shipmentData.weight)/1000,
                            height: Number(shipmentData.height),
                            width: Number(shipmentData.width),
                            length: Number(shipmentData.length),
                            category: 'home_appliances',
                            declared_currency: 'GBP',
                            declared_customs_value: 35
                        }
                    ],
                    buy_label_synchronous:true,
                    format:"PDF",
                    label:"4x6",
                    commercial_invoice:"4x6",
                    packing_slip:"4x6"


                })
        })
        let results = await createShipment.json();
        let results1 = results.shipment;
        var tempShipmentId = results1.easyship_shipment_id;
        let tempLabel = results1.label_response;
      
        if(results1.label_state === "not_created"){
            setShipError(tempLabel.errors[0]);
            setBtnloading4(false);
            return 0;
        }
          
        if(tempShipmentId !== "" && tempShipmentId !== null && tempShipmentId !== undefined){

            let getShipmentRes = await fetch(`https://api.easyship.com/shipment/v1/shipments/${tempShipmentId}?format=PNG&label=4X6`,{
                method:'GET',
                    headers:{
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${SHIPMENT_KEY}`}
            })

            let shipResults = await getShipmentRes.json();
            let tempValue = shipResults.shipment;
            let tempValue1 = tempValue.shipping_documents;
            let tempUrl = tempValue.tracking_page_url;    
            let tempValue3 = tempValue1.label;
            let tempImg = tempValue3.base64_encoded_strings[0]

            let updateShipmentId = await updateShipmentDetails(user._id,token,{tempUrl,tempImg,shipmentid:tempShipmentId,ID:tempID});
            if(updateShipmentId.shipmentid !== ""){
                setBtnloading4(false);
                loadOrders(match.params.orderId)
            }         
        }else {
            setShipError("Something went wrong, please try again");
            setBtnloading4(false);
        }
        
        }
    }

    const clickPickupRequest = async (event) => {
        event.preventDefault();
        if(window.confirm("Are you sure you want to continue ?"))
        {
            setBtnloading5(true);
           let shipmentDatas = await getShipment(user._id,token,{orderId:match.params.orderId});

           let createPickup = await fetch('https://api.easyship.com/pickup/v1/pickups',{
                method:'POST',
                headers:{
                    Accept:'application/json',
                    'Content-Type':'application/json',
                    Authorization: `Bearer ${SHIPMENT_KEY}`
                },
                body: JSON.stringify({
                    courier_id: shipmentDatas.courierid,
                    preferred_date: preferred_date,
                    preferred_max_time: preferred_max_time,
                    preferred_min_time: preferred_min_time,
                    easyship_shipment_ids: [`${shipmentDatas.shipmentid}`]
                })
            })
            let pickupResults = await createPickup.json();

            if(pickupResults.message === "Failed to create Pickup"){
                setPickupError(pickupResults.errors[0]);
                setBtnloading5(false);
            }
            else {
                let updatePickupDate = await updateShipmentPickup(user._id,token,{ID:shipmentDatas._id,preferred_date,preferred_max_time,preferred_min_time})
                if(updatePickupDate){
                    setPickupSuccess("You have successfully requested the pickup.");
                    setBtnloading5(false);
                    loadOrders(match.params.orderId) 
                }
            } 
        }
    }

    const shippingErrorMsg = () => {
        if(shipError) {
            return <h4 className="text-danger">{shipError}</h4>
        }
    }

    const shippingPickupErrorMsg = () => {
        if(pickupError) {
            return <h4 className="text-danger">{pickupError}</h4>
        }
    }

    const pickupSuccessMsg = () => {
        if(pickupSuccess) {
            return <h4 className="text-success">{pickupSuccess}</h4>
        }
    }


    const courierErrormsg = () => {
        if(checkError) {
            return <h3 className="text-danger">{checkError}</h3>
        }
    }

    const courierHistory = rateDetails.map((p,i) => (
        <tr key={i}>    
            <td>{p.courier_name}</td>
            <td>{p.shipment_charge_total}</td>  
            <td onClick={() => clickSubmitShipment1(p.courier_id,p.shipment_charge_total)}><button className="btn btn-info btn-gra-my-p">Choose</button></td>                                 
        </tr>
    ))
 
    const showCourierDetails = () => (
        (checkRate) &&
            <Table striped bordered hover size="sm"> 
                <thead>           
                    <tr>                                 
                        <th>Courier<br/>Name</th>
                        <th>Shipment<br/>Charge (£)</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {courierHistory}
                </tbody>
            </Table>   
    )

    const trackingHistory = trackDetails.map((t,i) => (
        <tr key={i}>
            <td>{t.tracking_number}</td>
            <td>{t.status}</td>
        </tr>
    ))

    const showTrackingDetails = () => (
       (checkTrack) && <><br/><Table striped bordered hover size="sm"> 
                <thead>           
                    <tr>                                 
                        <th>Tracking<br/>Number</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {trackingHistory}
                </tbody>
            </Table>  </>
    )

    const shipmentTracking = async () => {
      
        let trackDetails = await fetch(`https://api.easyship.com/track/v1/status?easyship_shipment_id=${shipmentID}&page=1&per_page=25`,{
            method:'GET',
            headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${SHIPMENT_KEY}`
            }
        })

        let trackRes = await trackDetails.json()
       
        var result = trackRes.shipments;
        setTrackDetails(result);
        setCheckTrack(true);
       
    } 


    const showModal = () => {
        return (
            <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    <Modal.Title>Shipment Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={clickSubmitShipment}>

                <div className="form-group">
                    <label className="text-muted">Box Type<span style={{color:"red"}}> *</span></label>
                        <select required
                            onChange={handleChangeforShipment('boxType')} 
                            className="form-control">
                            <option >Select Box type</option>
                            <option value="Small">Small</option>
                            <option value="Medium">Medium</option>
                            <option value="Large">Large</option>
                            <option value="Custom">Custom</option>
                        </select>
                </div>

                    <div className="form-group">
                        <label className="text-muted">Length (cm)<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChangeforShipment('length')} type="text" className="form-control" value={length} required/>
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Width (cm)<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChangeforShipment('width')} type="number" className="form-control" value={width} required/>
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Height (cm)<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChangeforShipment('height')} type="number" className="form-control" value={height} required/>
                    </div>
                   
                    <div className="form-group">
                        <label className="text-muted">Weight (kg)<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChangeforShipment('weight')} type="number" className="form-control" value={weight} autoFocus required/>
                    </div>
                    
                   
                    
                    {/* <div className="form-group">
                        <label className="text-muted">Message</label>
                        <input onChange={handleChangeforShipment('message')} type="text" className="form-control" value={message}/>
                    </div> */}
                    <button disabled={btnloading3} className="btn btn-outline-primary">{btnloading3 ? "Loading..." : "Submit"}</button>
                </form>
                <br/>
                    {showCourierDetails()}
                    {courierErrormsg()}
                </Modal.Body>

            </Modal>
        )
    }

    const showPickupModal = () => {
        return (
            <Modal show={pickupModal} onHide={() => setPickupModal(false)} dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    <Modal.Title>Request Pickup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={clickPickupRequest}>

                    <div className="form-group">
                        <label className="text-muted">Preferred Date<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChangeforPickup('preferred_date')} type="date" className="form-control" value={preferred_date} required/>
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Preferred Maximum time<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChangeforPickup('preferred_max_time')} type="datetime-local" className="form-control" value={preferred_max_time} required/>
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Preferred Minimum time<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChangeforPickup('preferred_min_time')} type="datetime-local" className="form-control" value={preferred_min_time} required/>
                    </div>
                    
                    <button className="btn btn-outline-primary" disabled={btnloading5}>{btnloading5 ? "Loading..." : "Submit"}</button>
                </form>
                <br/>
                   {shippingPickupErrorMsg()}
                    {pickupSuccessMsg()} 
                </Modal.Body>

            </Modal>
        )
    }

    const handleChangeforDelayed = name => event => {
        setValues4({ ...values4, [name]: event.target.value});
    }

    const clickSubmitDelayed = (event) => {
        event.preventDefault();
        setValues4({...values4});
        updateOrdStatus(user._id, token,match.params.orderId, {orderId:match.params.orderId, status:"Delayed",reason,resolution_time}).then(
            data => {
                if (data.error) {
                    console.log("Status update failed");
                } else {
                    setShowDelayed(() => setShowDelayed(false))
                    setValues4({
                        ...values4,
                        reason:'',
                        resolution_time:''
                    })
                    loadOrders(match.params.orderId);
                }
            }
        );

    }

    const showDelayModal = () => {
        return (
            <Modal show={showdelayed} onHide={() => setShowDelayed(false)}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={clickSubmitDelayed}>
                    <div className="form-group">
                        <label className="text-muted">Reason<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChangeforDelayed('reason')} type="text" className="form-control" value={reason} required/>
                    </div>
                    <div className="form-group">
                        <label className="text-muted">Resolution Time<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChangeforDelayed('resolution_time')} type="number" className="form-control" value={resolution_time} required/>
                    </div>
                    <button  className="btn btn-outline-primary">Add</button>
                </form>
                </Modal.Body>

            </Modal>
        )
    }

    const handleChangeforCancelled = name => event => {
        setValues5({ ...values5, [name]: event.target.value});
    }

    const clickSubmitCancelled = (event) => {
        event.preventDefault();
        setOpen(true);
        setValues5({...values5});
        updateOrdStatus(user._id, token,match.params.orderId, {orderId:match.params.orderId, status:"Order Cancelled",cancelled_reason:cancelled_reason}).then(
            data => {
                if (data.error) {
                    console.log("Status update failed");
                } else {
                    setShowCancelled(() => setShowCancelled(false))
                    setValues5({
                        ...values5,
                        cancelled_reason:''
                       
                    })
                    loadOrders(match.params.orderId);
                }
            }
        );

    }

    const showCancelModal = () => {
        return (
            <Modal show={showCancelled} onHide={() => setShowCancelled(false)}>
                <Modal.Header /* closeButton */>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={clickSubmitCancelled}>
                    <div className="form-group">
                        <label className="text-muted">Reason<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChangeforCancelled('cancelled_reason')} type="text" className="form-control" value={cancelled_reason} required/>
                    </div>
                    
                    <button  className="btn btn-outline-primary">Continue</button>
                </form>
                </Modal.Body>

            </Modal>
        )
    }

    const handleChangeReason = (e) => {
        setRejectReason(e.target.value);
    }

    const clickSubmitReason = (e) => {

        updateOrdStatus1(user._id, token,match.params.orderId, {retType,orderId:match.params.orderId,cancelled_reason:reject_reason, status:"Return Rejected"}).then(
            data => {
                if (data.error) {
                    console.log("Status update failed");
                } else {
                    loadOrders(match.params.orderId);
                    setShowRejected(false);
                }
            }
        );

    }

    const showRejectReasonModal = () => {
        return (
            <Modal show={showRejected} onHide={() => setShowRejected(false)}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={clickSubmitReason}>
                    <div className="form-group">
                        <label className="text-muted">Reason<span style={{color:"red"}}> *</span></label>
                        <input onChange={handleChangeReason} type="text" className="form-control" value={reject_reason} required/>
                    </div>
                    
                    <button  className="btn btn-outline-primary">Continue</button>
                </form>
                </Modal.Body>

            </Modal>
        )
    }



    const handleStatusChange = async (e, orderId,paymentId,subscription_type, subId) => {
      
        if(e.target.value !== "Update Status"){
            if(window.confirm("Do you want to change this status?"))
            {
                var tempPayID="";
       
                if(e.target.value === "Shipped") {
                    setShow(() => setShow(true))
                    return 0;
                } 
                else if(e.target.value === "Delayed") {
                    setShowDelayed(()=>setShowDelayed(true));
                    return 0;
                } 
                else if(e.target.value === "Order Cancelled") {
                    setShowCancelled(()=>setShowCancelled(true));
                    if(status !== "Awaiting Payment")
                    {

                        if((paymentId!=="") && (!isSubCancelled) && (subscription_paid) && (String(subId) === String(subscriptionid))  && (subscription_type === 1 || subscription_type === 0 || subscription_type === 2))
                        {     
                        
                            const response = await fetch(`${API}/cancel-subscription`, {
                            method:'POST',
                            headers: {'Content-Type': 'application/json',},
                            body: JSON.stringify({ 
                                'paymentid': String(subId),
                                'userid': String(UserID)             
                                })
                            });
                            
                            const deleted = await response.json();
                            let updateuser = await updateUserSubscriptionHistoryUser(user._id,token,{userId:UserID, subscriptionid:subId});

                            if(refund_id === ''){

                                const refund = await fetch(`${API}/refund`, {
                                    method:'POST',
                                    headers: {'Content-Type': 'application/json',},
                                    body: JSON.stringify({ 
                                    'paymentintentid': String(paymentId),
                                    'userid': String(UserID)        
                                    })
                                });  
                                const refunddetails = await refund.json();

                            }
                                                  
                        }
                        else if((paymentId !=="" && paymentId !== undefined && paymentId !== "null" && paymentId !== "undefined") && (subscription_type !== 1 || subscription_type !== 0 || subscription_type !== 2)){
                           
                            if(refund_id === ''){

                                const refund = await fetch(`${API}/refund`, {
                                    method:'POST',
                                    headers: {'Content-Type': 'application/json',},
                                    body: JSON.stringify({ 
                                        'paymentintentid': String(paymentId),
                                        'userid': String(UserID)        
                                        })
                                    });  
                                    const refunddetails = await refund.json();

                            }
                             
                        }
                       
                    }                  
                    return 0;
                } 

                updateOrdStatus(user._id, token,orderId, {orderId:orderId, status:e.target.value}).then(
                    data => {
                        if (data.error) {
                            console.log("Status update failed");
                        } else {
                            loadOrders(match.params.orderId);
                        }
                    }
                );
            }
        }
        
    }; 

    const handleStatusChange1 = async (e,orderId,paymentId,subscription_type,subId) => {

        if(window.confirm("Do you want to change this status?"))
        {
            var tempPayID="";
          

            if(e.target.value === "Return Completed"){

               
                if(retType === 2)
                {
                  

                    if((paymentId!=="") && (!isSubCancelled) && (subscription_paid) && (String(subId) === String(subscriptionid))  && (subscription_type === 1 || subscription_type === 0 || subscription_type === 2))
                    {      

                        const response = await fetch(`${API}/cancel-subscription`, {
                        method:'POST',
                        headers: {'Content-Type': 'application/json',},
                        body: JSON.stringify({ 
                            'paymentid': String(subId),
                            'userid': String(UserID)             
                            })
                        });
                        
                        const deleted = await response.json();
                        let updateuser = await updateUserSubscriptionHistoryUser(user._id,token,{userId:UserID, subscriptionid:subId});
                       
                        if(refund_id === ''){

                            const refund = await fetch(`${API}/refund`, {
                                method:'POST',
                                headers: {'Content-Type': 'application/json',},
                                body: JSON.stringify({ 
                                  'paymentintentid': String(paymentId),
                                  'userid': String(UserID)        
                                  })
                              });  
                              const refunddetails = await refund.json();

                        }
                        
                          updateOrdStatus(user._id, token,match.params.orderId, {retType,orderId:match.params.orderId, status:"Return Completed"}).then(
                            data => {
                                if (data.error) {
                                    console.log("Status update failed");
                                } else {
                                    loadOrders(match.params.orderId);
                            
                                }
                            }
                        );
                         
                    }
                      else if((paymentId !=="" && paymentId !== undefined && paymentId !== "null" && paymentId !== "undefined") && (subscription_type !== 1 || subscription_type !== 0 || subscription_type !== 2)){
                        
                        if(refund_id === ''){

                            const refund = await fetch(`${API}/refund`, {
                                method:'POST',
                                headers: {'Content-Type': 'application/json',},
                                body: JSON.stringify({ 
                                  'paymentintentid': String(paymentId),
                                  'userid': String(UserID)        
                                  })
                              });  
                              const refunddetails = await refund.json();

                        }
                        

                        updateOrdStatus(user._id, token,match.params.orderId, {retType,orderId:match.params.orderId, status:"Return Completed"}).then(
                            data => {
                                if (data.error) {
                                    console.log("Status update failed");
                                } else {
                                    loadOrders(match.params.orderId);
                            
                                }
                            }
                        );
                         
                      }

                } 
                else if(retType === 1)
                {
                    updateOrdStatus(user._id, token,orderId, {retType,orderId:orderId, status:"Return Completed"}).then(
                        data => {
                            if (data.error) {
                                console.log("Status update failed");
                            } else {
                                loadOrders(match.params.orderId);
                        
                            }
                        }
                    );
                }

            }else if(e.target.value === "Return Accepted") {
               
                    updateOrdStatus1(user._id, token,orderId, {orderId:orderId, status:"Return Accepted",retType}).then(
                    data => {
                        if (data.error) {
                            console.log("Status update failed");
                        } else {
                            loadOrders(match.params.orderId);
                    
                        }
                    }
                );
            } else if(e.target.value === "Return Rejected"){
                setShowRejected(true);
                return 0;
            }                  
        }

    }

    const [statusList1,setstatuslist1] = useState([
        { label:"Return Requested",value: "Return Requested"},
        { label:"Return Accepted",value: "Return Accepted"},
        { label:"Return Rejected",value: "Return Rejected"},
        { label:"Return Completed",value: "Return Completed"}
    ])

    const showStatus = (o,status1,paymentId1,sub_type1,subId) => (
        <div className="form-group">


        {(status1 === "Awaiting Payment") && (<select
                className="form-control"
                onChange={e => handleStatusChange(e, o,paymentId1,sub_type1,subId)}
            > <option>Update Status</option>
            {
                statusValues.map((status, index) => (
                (status1 === status ? '' : (
                    status === "Order Cancelled" && <option  key={index} value={status}>{status}</option>
                ))
                
            ))}
        </select>)}
            
        {(status1 === "Awaiting Customer Pre-configuration") && (<select
                className="form-control"
                onChange={e => handleStatusChange(e, o,paymentId1,sub_type1,subId)}
            > <option>Update Status</option>
            {
                statusValues.map((status, index) => (
                (status1 === status ? ''
                  : ((status !== "Awaiting Payment" && status !== "Customer Pre-configuration Complete") && <option key={index} value={status}>
                  {status}
              </option>) )
                
            ))}
        </select>)}

        {(status1 === "Safely Pre-configuration Complete") && (<select
                className="form-control"
                onChange={e => handleStatusChange(e, o,paymentId1,sub_type1,subId)}
            > <option>Update Status</option>
            {
                statusValues.map((status, index) => (
                (status1 === status ? ''
                  : ((status !== "Awaiting Payment" && status !== "Awaiting Customer Pre-configuration" && status!== "Customer Pre-configuration Complete") && <option key={index} value={status}>
                  {status}
              </option>) )
                
            ))}
        </select>)}

        {(status1 === "Customer Pre-configuration Complete") && (<select
                className="form-control"
                onChange={e => handleStatusChange(e, o,paymentId1,sub_type1,subId)}
            > <option>Update Status</option>
            {
                statusValues.map((status, index) => (
                (status1 === status ? ''
                  : ((status !== "Awaiting Payment" && status !== "Awaiting Customer Pre-configuration") && <option key={index} value={status}>
                  {status}
              </option>) )
                
            ))}
        </select>)}

        {(status1 === "Shipped") && (<select
                className="form-control"
                onChange={e => handleStatusChange(e, o,paymentId1,sub_type1,subId)}
            > <option>Update Status</option>
            {
                statusValues.map((status, index) => (
                (status1 === status ? ''
                  : ((status !== "Awaiting Payment" && status !== "Awaiting Customer Pre-configuration" && status !== "Customer Pre-configuration Complete" && status !== "Safely Pre-configuration Complete") && <option key={index} value={status}>
                  {status}
              </option>) )
                
            ))}
        </select>)}

        {(status1 === "Delivered") && (<select
                className="form-control"
                onChange={e => handleStatusChange(e, o,paymentId1,sub_type1,subId)}
            > <option>Update Status</option>
            {
                statusValues.map((status, index) => (
                (status1 === status ? ''
                  : ((status !== "Awaiting Payment" && status !== "Awaiting Customer Pre-configuration" && status !== "Customer Pre-configuration Complete" && status !== "Safely Pre-configuration Complete" && status !== "Shipped" && status!=="Delayed") && <option key={index} value={status}>
                  {status}
              </option>) )
                
            ))}
        </select>)}

        {/* {(status1 === "Order Cancelled") && (<select
                className="form-control"
                onChange={e => handleStatusChange(e, o,paymentId1,sub_type1)}
            > 
            {
                statusValues.map((status, index) => (
                (status1 === status && ''
                  )
                
            ))}
        </select>)} */}

        {(status1 === "Delayed") && (<select
                className="form-control"
                onChange={e => handleStatusChange(e, o,paymentId1,sub_type1,subId)}
            > <option>Update Status</option>
            {
                statusValues.map((status, index) => (
                (status1 === status ? ''
                  : ((status !== "Awaiting Payment" && status !== "Awaiting Customer Pre-configuration" && status !== "Customer Pre-configuration Complete" && status !== "Safely Pre-configuration Complete") && <option key={index} value={status}>
                  {status}
              </option>) )
                
            ))}
        </select>)}

        {(status1 === "Return Requested") && (<select
                className="form-control"
                onChange={e => handleStatusChange1(e, o,paymentId1,sub_type1,subId)}
            ><option>Update Status</option>
            {
                statusList1.map((s) => (
                (status1 === s.value ? ''
                  : (<option key={s.value} value={s.value}>
                  {s.label}
              </option>) )
                
            ))}
        </select>)}

        {(status1 === "Return Accepted") && (<select
                className="form-control"
                onChange={e => handleStatusChange1(e, o,paymentId1,sub_type1,subId)}
            >
           <option>Update Status</option>
            <option value="Return Completed">Return Completed</option>
        </select>)}

            </div>
                
    );



const showRecentAddress = () => {
    return (
       
       
                <div className="row">
                    
                    <div className="col-md-6 px-0 mb-3">
                        <div className="col-md-12 bord-line">
                        <div className="col-md-10 py-3 wid-60-per-in">
                            <h5 className="pb-3 color-blk-in font-one-re">OrderId:&nbsp;{id}</h5>
                                <p className="pb-0 mb-0">
                                <span>Product Name:&nbsp; &nbsp;{title}</span><br/>
                                <span>Product Description:&nbsp;<span dangerouslySetInnerHTML={{__html: order_description}}></span> &nbsp;</span><br/>
                                {(system_type !== "" && system_type !== undefined && system_type !== null) && (<><span>System Type:&nbsp;&nbsp;{system_type}</span><br/></>)}  
                                <span>Quantity:&nbsp; &nbsp;{quantity}</span><br/>
                                <span>Purchase Price:&nbsp; &nbsp;£{price.toFixed(2)} </span><br/>
                               
                
                                <span>Odered Date: &nbsp;<Moment format='DD/MM/YYYY'>{createdAt}</Moment> </span><br/> <br/><br/>
                                <span>{(status === "Awaiting Payment") || ((paymentId === "undefined") || (paymentId === "null") || (paymentId === undefined)) ?  (<div className="alert alert-danger" role="alert">
                                    Waiting for Customer Payment!
                                    </div> ):''}</span>  <br/>
                                
                                </p>
                                <hr/>
                                {source && <h5><b>Source:</b>&nbsp;{source}</h5>}
                                {notes.length >=1 && <><h5 className="pb-3 color-blk-in font-one-re">Notes</h5>
                                
                                    {
                                        notes.map((n,i) => (
                                            <p className="pb-0 mb-0" key={i}>
                                                <span>Note:&nbsp;{n.user_notes}</span><br/>
                                                <span>Date:&nbsp;<Moment format='DD/MM/YYYY'>{n.createdAt}</Moment></span><hr/>
                                            </p>
                                       
                                        ))
                                    }  
                                    </>
                                }

                                    {Number(discount) !==0 && <><h5 className="pb-3 color-blk-in font-one-re">Discount Details</h5>
                                <span>Discount price(£):&nbsp;&nbsp;{discount.toFixed(2)}</span><br/>
                                <span>Coupon Name:&nbsp;&nbsp;{coupon_name}</span> </>}                           
                                    
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 px-0 mb-3">
                        <div className="col-md-12 bord-line">
                        <div className="col-md-10 py-3 wid-60-per-in">
                        <h5 className="pb-3 color-blk-in font-one-re">Change Status</h5>
                                {showStatus(id1,status,paymentId,subscription_type, subscriptionId)}
                                <span><b>Status:</b>&nbsp; &nbsp;{status}</span> 
                                {((status === "Shipped") && (!checkShipment)) && (<>&nbsp;&nbsp;<button disabled={btnloading4} className="btn btn-info btn-gra-my-p" onClick={() => clickShipmentDetails()}>{btnloading4 ? "Loading..." : "Create Label"}</button><br/></>)}
                                {((status === "Shipped") && (checkShipment) && (pickupDate === "")) && (<>&nbsp;&nbsp;<button  className="btn btn-info btn-gra-my-p" onClick={() => setPickupModal(true)} >Pickup</button><br/></>)}
                                {((status === "Shipped") && (checkShipment) && (pickupDate !== "")) && (<><br/><span><b>Pickup:</b>&nbsp;<Moment format="DD/MM/YYYY">{pickupDate}</Moment></span><br/></>)}
                              
                                {((status === "Shipped") && (checkShipment)) && (<>&nbsp;&nbsp;<button  className="btn btn-info btn-gra-my-p" onClick={() => shipmentTracking()}>Track info</button></>)}
                                {((status === "Shipped") && (checkShipment)) && (<>&nbsp;&nbsp;<Base64Downloader base64={"data:image/png;base64," + label} downloadName="Label" className="btn btn-info btn-gra-my-p">Download Label</Base64Downloader><br/></>)}
                                {/* <img style={{ maxHeight: "100%", maxWidth: "100%" }} src={"data:image/png;base64," + label} /> */}
                           
                                {shippingErrorMsg()}
                                {showTrackingDetails()}
                                <hr/>
                                {(status === "Order Cancelled" || status === "Return Rejected") && <><span><b>Cancelled Reason:</b>&nbsp;  {cancelledReason}</span><br/><hr/></>}
                            {returnData.length >=1 && <div>
                                {returnData.map(r => (<div>
                                    <span><b>Return Type:</b>&nbsp;  {r.return_type === 1 ? 'Replacement' : (r.return_type === 2 && 'Refund' )}</span><br/>
                                      
                                    <span><b>Return Reason:</b>&nbsp;  {r.return_reason}</span><br/>
                                    <span><b>Return Message:</b>&nbsp;  {r.return_message}</span><br/>                                                              
                                    {r.photo !== undefined  && <ShowReturnImg item={r._id} />}
                                    <hr/>
                                    </div>))}
                            </div>}
                           
                            {((status === "Order Cancelled" || status === "Return Completed") || refund_id)  && <div> <hr/>
                          
                                <h5 className="pb-3 color-blk-in font-one-re">Refund History</h5>
                                <span>Refund ID:&nbsp;&nbsp;{refund_id}</span><br/>
                                <span>Refund Amount:&nbsp;&nbsp;£ {Number(refund_amount)/100}</span><br/>
                                <span>Refund Date:&nbsp;&nbsp;{new Date(refund_date * 1000).toLocaleDateString("en-GB")}</span><br/>
                                <span>Refund Status:&nbsp;&nbsp;{refund_status}</span>
                                </div>}
                            
                            {pin_code && <div><hr/>
                           
                            <h5 className="pb-3 color-blk-in font-one-re">Delivery Address</h5>
                                <p className="pb-0 mb-0">
                                    {prefix}.{firstname}&nbsp;{lastname} | {phone}<br/>
                                    {house_no} 
                                    {area !== undefined && <><br/><span>{area}</span></>}<br/>
                                    {city}{state !== undefined && <span>|{state}</span>} <br/>
                                    {country}<br/>
                                    {pin_code}<br/>
                                </p>
                                </div>}
                                <p>Email:&nbsp;{email}</p>
                                <hr/>
                                <h5 className="pb-3 color-blk-in font-one-re">Payment Details</h5>
                                <p className="pb-0 mb-0">
                                <span>Payment: &nbsp;{(status === "Awaiting Payment") || ((paymentId === "undefined") || (paymentId === "null") || (paymentId === undefined)) ? 'UnPaid' : 'Paid'}</span> <br/>
                                <span>Payment ID: &nbsp;{(status === "Awaiting Payment") || ((paymentId === "undefined") || (paymentId === "null") || (paymentId === undefined)) ? '' : paymentId}</span> <br/>
                                <span>Amount Paid: &nbsp;{(status === "Awaiting Payment") || ((paymentId === "undefined") || (paymentId === "null") || (paymentId === undefined)) ? '' : <span>£ {price}</span>}</span> <br/>
                                <span>Payment Date: &nbsp;{(status === "Awaiting Payment") || ((paymentId === "undefined") || (paymentId === "null") || (paymentId === undefined)) ? '' : <Moment format='DD/MM/YYYY'>{payment_date}</Moment>} </span><br/>
                                </p>
                                <hr/>
                                {(Number(subscription_type) === 1 || Number(subscription_type) === 0 || Number(subscription_type) === 2) && (<h5 className="pb-3 color-blk-in font-one-re">Subscription Details</h5>)}
                                
                                <p className="pb-0 mb-0">
                                {(subscription_type_user === 1 || subscription_type_user === 0 || subscription_type_user === 2) && (<span>Plan:&nbsp;{subscription_type_user === 1 ? 'Gold' : (subscription_type_user ===  0 ? 'Silver' : subscription_type_user === 2 && 'Bronze')}<br/></span>)}
                                {(subscription_type_user === 1 || subscription_type_user === 0 || subscription_type_user === 2) && (<span>Amount:&nbsp;£ {subscription_amount}<br/></span>) }
                                
                                {(subscription_type_user === 1 || subscription_type_user === 0 || subscription_type_user === 2) && <span>Status:&nbsp;{(subscription_paid) ? 'Active' : 'InActive'}<br/></span> }
                               {((subscription_type_user === 1 || subscription_type_user === 0 || subscription_type_user === 2)) && <span>Payment Status:{ (subscription_paid) ? 'Paid' : 'UnPaid'} </span>}
                                </p>
                               
                            </div>
                        </div>
                    </div>
                </div>

    )
}

    const orderHistory = orderlog.map((p,i) => (
        <tr key ={i}>
            <td><Moment format='DD/MM/YYYY HH:mm'>{p.updatedAt}</Moment></td>
            <td>{p.reason}</td>
            <td>{p.added_by.name}</td>
        </tr>
    ))

    const newOrderlog = () => (
                    <div>
                        <h4 className="card-title" style={{textAlign:'center'}}>Order Logs</h4>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>           
                                    <tr>
                                        <th>Date</th>
                                        <th>Reason</th>
                                        <th>Added by</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderHistory}
                                </tbody>                    
                            </table>
                            {orderlog.length === 0 && <><br/><h4 style={{textAlign:'center'}}>Sorry! No Order logs found</h4></>}
                        </div>
                    </div>
                     
    )

    const handleChangeCheck = (e,id) => {
      
       if(e.target.checked){
        var temp = Number(count) + 1
        setCount(temp)
       }
       else {
        var temp = Number(count) - 1
        setCount(temp)
       }

       updateTechnicianStatus(user._id,token,{suborderId:id,technician_verified:e.target.checked}).then(
            data => {
                if (data.error) {
                    console.log("Status update failed");
                } else {
                    loadSubOrders(match.params.orderId); 
                }
            }
        );
       

    }

    const updateSafelyConfigStatus = () => {
        
        if(window.confirm("Do you want to change this status?"))
        {
            setBtnloading2(true);
            updateOrdStatus(user._id, token,match.params.orderId, {orderId:match.params.orderId, status:"Safely Pre-configuration Complete"}).then(
                data => {
                    if (data.error) {
                        console.log("Status update failed");
                    } else {
                        loadOrders(match.params.orderId);
                    }
                }
            );
        }
    }

    const showCongiguration = () => {
        return (
            <div>
                <span>{(status === "Awaiting Customer Pre-configuration" || status === "Awaiting Payment") && (<div className="alert alert-danger" role="alert" style={{textAlign:'center'}}>Waiting for Pre-Configuration to Complete!</div>)}</span>

            <div className="table-responsive pt-3 px-2">
            <table className="table table-bordered table-responsive">
              <thead>
                <tr>

                    <th className="pend-table-hd">
                        Technician<br/>
                        verified
                    </th>
                   
                  <th className="pend-table-hd">
                    Products
                  </th>
                  <th className="pend-table-hd">
                  Device ID <br/>(Enter Device ID Manually/Add Device)
                  </th>
                  <th className="pend-table-hd">
                    Assign Device
                  </th>
                  
                  <th className="pend-table-hd">
                    Room Name
                  </th>
                </tr>
              </thead>
              <tbody>
                  {suborders.map((s,i) => (
                        <tr>
                            <td><center>
                                <input type="checkbox" defaultChecked={s.technician_verified} onChange={e => handleChangeCheck(e,s._id)} />
                                </center>
                            </td>
                            
                            <td key={i} className="font-we-prod">
                            {s.productid.name}
                            </td>
                            <td key={i+100}>
                                {deviceID(s._id,s.deviceid)}
                            </td>
                            <td key={i+1000}>
                            {assignDevice(s._id,s.assign_device)}
                            </td>
                            
                            <td>
                            {roomName(s._id,s.room_name,i)}
                            </td>
                      </tr> 
                  ))}
                
              </tbody>
            </table>
            {(btnloading) ? <div className='loader-container'><div className='loader'></div></div> : ""}  
          </div>
          </div>
        )
    }

    /* const downloadPDF = () => {

        const doc = new jsPDF('l','in',[57,32]);
        
         doc.autoTable({
        body: [ ...suborders.map(p => [p.room_name])
        ]
        }) 
        
        doc.save('RoomName.pdf')
    } */

    const downloadPDF = () => {
        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: [57, 32] 
        });
        for(var i=0;i<suborders.length;i++) { 
           /*  doc.setFontSize(50); */
            doc.text(suborders[i]['room_name'],30,15,'center');
            if(i === suborders.length - 1){
                break;
            }
            doc.addPage();
        }    
        doc.save(`${match.params.orderId}.pdf`);
    }

    const csvData = [ ...suborders.map(p => [p.room_name])];

    const contains = (element,arr) => {
        if(arr.includes(element))
        {
                return true;
            }    
        return false;
    }

    return (
        <AdminLayout className="container" topTitle="Order Details">
            <div className="row">     
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body"> 
                            <h4 className="card-title" style={{textAlign:'center'}}>Order Details</h4>
                  
                            {showModal()}
                            {showPickupModal()}
                            {showDelayModal()}
                            {showCancelModal()}
                            {showRejectReasonModal()}

                            {(spinloading) ? <div className='loader-container'><div className='loader'></div></div> : ""}
                               {!spinloading && showRecentAddress()}
                              
                                
                                {((title.includes("Customised Safely Package")) || (title.includes("Customised SafelyTeam Package")) || (adId)) && showCongiguration()}
                                <div className="col-md-12 py-3 text-right">
                           
                           {(status !== "Awaiting Payment" && status !== "Awaiting Customer Pre-configuration" && status !== "Order Cancelled" && (title.includes("Customised Safely Package") || title.includes("Customised SafelyTeam Package") || adId)) && (<button type="button" className="btn btn-info btn-gra-my-p" style={{borderRadius: '25px'}}onClick={() => downloadPDF()} >Download PDF</button>)}
                           {(status !== "Awaiting Payment" && status !== "Awaiting Customer Pre-configuration" && status !== "Order Cancelled" && (title.includes("Customised Safely Package") || title.includes("Customised SafelyTeam Package") || adId)) && (<CSVLink data={csvData} style={{color:'white'}} filename={`${match.params.orderId}.csv`} className="btn btn-info btn-gra-my-p">Download CSV</CSVLink>)}
                           {((length1 === count) && (status === "Awaiting Customer Pre-configuration" ||status === "Customer Pre-configuration Complete")) && <button className="btn btn-info btn-gra-my-p" onClick={() => updateSafelyConfigStatus()}>{btnloading2 ? "Loading..." : "Safely Pre-configuration Complete"}</button>}
                            {!spinloading && <Link to={`/admin/welcome/${match.params.orderId}`} className="btn btn-info btn-gra-my-p" target="_blank">Welcome PDF</Link>}
                            </div>
                                    <br/>
                                
                                    {!spinloading && newOrderlog()}

                                    <Backdrop className={classes.backdrop} open={open} >
                                        <CircularProgress color="inherit" />
                                    </Backdrop>  

                        </div>
                        
                    </div>
                </div>
           </div>
        </AdminLayout>
    )
}

export default OrderDetails
