import React from 'react'
import { withRouter } from 'react-router-dom'
import { isAuthenticated } from '../../auth/index'
import { logout } from '../../auth/index'
import CustomScripts from '../../hooks/customscripts';


const isActive = (history, path) => {
  if(history.location.pathname === path) {
      return { color: 'black'};
  } /* else {
      return {color: 'white'};
  } */
}

const Sidebar = ({history}) => {

    const {user: {lastname,prefix}} = isAuthenticated() 
    CustomScripts('/themes/admin/js/off-canvas.js');
    CustomScripts('/themes/admin/js/hoverable-collapse.js');
    CustomScripts('/themes/admin/js/template.js');    
  


    return (
        <nav className="sidebar sidebar-offcanvas white-bg-ad bg-saf-blue" id="sidebar">
          <ul className="nav">
            <li className="nav-item prof-name-text mb-3">
              <img src={require('../admin/images/faces/face.png')} alt="profile" className="mr-2" style={{width:'20%'}}/>
    {prefix}. {lastname}
            </li>
            <li className="nav-item ord-btm-pa">
              <a className="nav-link tp-btm-pad-sin" style={isActive(history,'/user/dashboard')} href="/user/dashboard"> 
                <span className="menu-title">Dashboard</span>
              </a>
            </li>
            <li className="nav-item">
              <span className="nav-link tp-btm-pad-sin"> 
                <span className="menu-title" style={{color:'#91fff0'}}>Your Orders</span>
              </span>
            </li>
            <li className="nav-item">
              <a className="nav-link tp-btm-pad-sin" style={isActive(history,'/user/allorders')} href="/user/allorders">
                <span className="menu-title">All Orders</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link tp-btm-pad-sin" style={isActive(history,'/user/yourorders')} href="/user/yourorders">
                <span className="menu-title">Open Orders</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link tp-btm-pad-sin" style={isActive(history,'/user/pendingconfiguration')} href="/user/pendingconfiguration">
              
                <span className="menu-title">Pending Configuration</span>
              </a>
            </li>
            <li className="nav-item ord-btm-pa">
              <a className="nav-link tp-btm-pad-sin" style={isActive(history,'/user/cancelledorders')} href="/user/cancelledorders">
                
                <span className="menu-title">Cancelled Orders</span>
              </a>
            </li>
             
             <li className="nav-item">
              <span className="nav-link tp-btm-pad-sin" href="#/">
                
                <span className="menu-title" style={{color:'#91fff0'}}>Addresses</span>
              </span>
            </li>
            <li className="nav-item">
              <a className="nav-link tp-btm-pad-sin" style={isActive(history,'/user/alladdress')} href="/user/alladdress">
                
                <span className="menu-title">All Addresses</span>
              </a>
            </li>
            <li className="nav-item ord-btm-pa">
              <a className="nav-link tp-btm-pad-sin" style={isActive(history,'/user/recentaddress')} href="/user/recentaddress">
              
                <span className="menu-title">Recently used</span>
              </a>
            </li> 
            <li className="nav-item ord-btm-pa">
              <a className="nav-link tp-btm-pad-sin" style={isActive(history,'/user/subscription/history')} href="/user/subscription/history">
              
                <span className="menu-title">Subscription History</span>
              </a>
            </li> 
            <li className="nav-item">
              <span className="nav-link tp-btm-pad-sin"> 
                <span className="menu-title" style={{color:'#91fff0'}}>Cards</span>
              </span>
            </li>
   
            <li className="nav-item ord-btm-pa">
              <a className="nav-link tp-btm-pad-sin" style={isActive(history,'/user/manage/cards')} href="/user/manage/cards">
                <span className="menu-title">Manage Cards</span>
              </a>
            </li>
            <li className="nav-item ord-btm-pa">
              <a className="nav-link tp-btm-pad-sin" style={isActive(history,'/user/changepassword')} href="/user/changepassword">
                
                <span className="menu-title">Change Password</span>
              </a>
            </li> 
            <li className="nav-item ord-btm-pa">
              <span className="nav-link tp-btm-pad-sin" style={{cursor: 'pointer'}} 
              onClick={() => logout(() => {
                history.push("/login");
            })}>
                
                <span className="menu-title">Logout</span>
              </span>
            </li>    
      </ul>
    </nav> 
    )
}

export default withRouter(Sidebar);
