import React, {useState} from 'react'
import { isAuthenticated } from '../auth/index'
import { Redirect } from "react-router-dom"
import { createCategory } from './apiAdmin'
import AdminLayout from '../theme/admin/AdminLayout'

const AddCategory = () => {

    const [name, setName] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)

    // user and token from local storage
    const { user, token} = isAuthenticated()

    const [values, setValues] = useState({
        btnloading:false
    });

    const { btnloading } = values

    const handleChange = (e) => {
        setError('')
        setName(e.target.value)
        setValues({...values,btnloading:false});
    };

    const clickSubmit = (e) => {
        e.preventDefault();
        setError('');
        setSuccess(false);
        setValues({...values,btnloading:true});
        //call function for api to create category
        createCategory(user._id,token,{name})
        .then(data => {
            if(data.error) {
                setError(data.error);
                setValues({...values,btnloading:false});
                setSuccess(false);
            } else {
                setError("");
                setSuccess(true);
                setValues({...values,btnloading:false});
            }
        });
    };

    const newCategoryForm = () => (
     
        <form onSubmit={clickSubmit}>
            <div className="form-group">
                <label className="text-muted">Category Name<span style={{color:"red"}}> *</span></label>
                <input type="text" className="form-control" onChange={handleChange} value={name} autoFocus/>
                
            </div>
            <button disabled={btnloading} className="btn btn-outline-primary">
            {btnloading ? "Loading..." : "Add"}
            </button>
        </form>
        
    );

    const showSuccess = () => {
        if(success) {
            return <Redirect to="/admin/categories" />
        }
    };

    const showError = () => {
        if(error) {
            if(error.includes("11000 duplicate key error collection")){
                return <div className="alert alert-danger" role="alert">Category name already exists!</div>
            }
            return <div className="alert alert-danger" role="alert">{error}</div>
        }
    };
 
    return (
        <AdminLayout className="container" topTitle="Add Category">
            <div className="row">  
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title" style={{textAlign:'center'}}>Add Category</h4>
          
                            {showSuccess()}
                            {showError()}
                            {newCategoryForm()}

                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
};


export default AddCategory;