import React, {useState} from 'react'
import { Redirect } from "react-router-dom"
import { createPreorder } from './apiAdmin'
import AdminLayout from '../theme/admin/AdminLayout'

const AddPreOrder = () => {
    const [values, setValues] = useState({
        email:'',
        reg_type:'',
        error:'',
        success:false,
        btnloading:false
    });

    const { btnloading, email, reg_type, error, success } = values

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value,btnloading:false});
    };

    const clickSubmit = (e) => {
        e.preventDefault();
        setValues({...values, error: false,btnloading:true });
        createPreorder({email,reg_type})
        .then(data => {
            if(data.error) {
                setValues({...values, error: data.error, success:false,btnloading:false})
            } else {
                setValues({
                    ...values,
                    email:'',
                    reg_type: '',
                    error: '',
                    btnloading:false,
                    success: true
                })
            }
        });
    };

    const preorderForm = () => (
     
        <form onSubmit={clickSubmit}>
            <div className="form-group">
                <label className="text-muted">Email<span style={{color:"red"}}> *</span></label>
                <input type="email" className="form-control" onChange={handleChange('email')} value={email} autoFocus required/>
                
            </div>

            <div className="form-group">
                 <label className="text-muted">Type<span style={{color:"red"}}> *</span></label>
                 <select required
                    onChange={handleChange('reg_type')} 
                    className="form-control">
                        <option >Select Type</option>
                        <option value="1">Pre-Order Booking</option>
                        <option value="2">Top Banner</option>
                 </select>
             </div>

            <button disabled={btnloading} className="btn btn-outline-primary">
            {btnloading ? "Loading..." : "Add"}
            </button>
        </form>
        
    );

    const showSuccess = () => {
        if(success) {
            return <Redirect to="/admin/preorder/bookings" />
        }
    };

    const showError = () => {
        if(error) {
            if(error === "11000 duplicate key error collection: STM.newsletters index: email already exists") {
                return <div className="title-mini stay-marg" style={{color:'red'}}>{email} already exists!</div>
            }
            else {
                return <div className="title-mini stay-marg" style={{color:'red'}}>{error}</div>
            }    
        }
    };

    return (
        <AdminLayout className="container" topTitle="Add Pre-Order">
            <div className="row">  
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title" style={{textAlign:'center'}}>Add Pre-Order</h4>
          
                            {showSuccess()}
                            {showError()}
                            {preorderForm()}

                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default AddPreOrder
