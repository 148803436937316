import React,{useState, useEffect, useRef} from 'react'
import { isAuthenticated } from '../../auth/index'
import { getAds, getAdsProducts, createMultiplePreorder} from './Priorities/apiUser'
import {API} from '../../config'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import NewLayout from './NewLayout'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
}));

const Packages = () => {

    const emailRef = useRef()

    const [ads, setAds] = useState([])
    let [adsProducts, setAdsProducts] = useState([]);
    const [spinloading, setSpinloading] = useState(true);

    const [emailVal, setEmailVal] = useState({
        email:'',
        success_msg:false,
        error_msg:''
    })

    const {email, success_msg, error_msg} = emailVal;

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    useEffect(() => {
        loadAds();
    },[])

    const loadAds = () => {
        getAds().then(async data => {
            if(data.error) {
                console.log(data.error);
            } else {
                var adRes = [];
                for(var i=0;i<data.length;i++){
                    let result = await getAdsProducts({Id:data[i]._id})
                    adRes.push(result)
                }
                setAds(data);
                setAdsProducts(adRes);
                setSpinloading(false)
            }
        });
    };

    const GetPrice = (Id) => {
        var sum=0;
        if(adsProducts.length >=1){
            for(var i=0;i<adsProducts.length;i++){
                var tempVal1 = adsProducts[i];
            for(var j=0;j<tempVal1.length;j++){
                if(tempVal1[i].adsId === Id){                   
                    sum+=tempVal1[j].prod_price;
                }
            }
        }             
        }
        return sum;
    };

    const checkProducts = (Id) => {
        var tempVal = "";
        if(adsProducts.length >=1){
            for(var i=0;i<adsProducts.length;i++){
                var tempVal1 = adsProducts[i];
                
                for(var j=0;j<tempVal1.length;j++){
                    if(tempVal1[i].adsId === Id){
                        tempVal += `<div class="col-md-6 px-0 pb-2">${tempVal1[j].productId.name}</div>`
                       
                    }
                } 
              
                if(tempVal1.length===4 && tempVal1[i].adsId === Id)  
                {
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`                   
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                }
                else if(tempVal1.length===6 && tempVal1[i].adsId === Id)  
                {
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`   
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>` 
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                    tempVal +=`<div class="col-md-12 px-0 pb-2"></div>`
                                 
                }
            }
            return <div className="row" dangerouslySetInnerHTML={{__html: tempVal}}></div>
        }
        return <div></div>
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    }

    const handleChangeEmail = name => event => {
        emailRef.current.style.border='';
        setOpen(false);
        setEmailVal({...emailVal, [name]:event.target.value, success_msg:false, error_msg:''})
    }

    const setAdToken = (id,name,description,price) => {
        if(!isAuthenticated()){
            // store email to database
            createMultiplePreorder({email, reg_type:4})
            .then(data => {
                if(data.error){
                    if(data.emailErr){
                        emailRef.current.focus();
                        emailRef.current.style.border = '1px solid red';
                        setEmailVal({...emailVal, error_msg:data.error, success_msg:false})   
                        setOpen(true); 
                    }
                    else {
                        setEmailVal({...emailVal, error_msg:data.error, success_msg:false})   
                        setOpen(true);    
                    }
                       
                }
                else {
                    localStorage.setItem('_guest',JSON.stringify({email}))
                    localStorage.setItem('ads',JSON.stringify({adID:id,name:name,description:description,salePrice:price}));
                    return  window.location.href="/adstep";
                }
            })
        }
        else {
            localStorage.setItem('ads',JSON.stringify({adID:id,name:name,description:description,salePrice:price}));
            return  window.location.href="/adstep";
        }     
    }

    const checkImgAlt = (name) => { // Make Image alt for Packages

        if(String(name) === "Habitat Package"){
            return "Habitat Package- Flood alarm/Air quality sensor/ Gateway/Heat alarm";
        }
        else if(String(name) === "Defender Package"){
            return "Defender Package- Gateway/Contact Sensor/Keypad/Siren"
        }
        else if(String(name) === "Habitat + Defender Package"){
            return "SafelyTeam HD Package- Complete home security solution in the UK"
        }
        
        return "";
        
    }

    const showPackages = () => (
        <section id="choose-mob">
            <div className="container ">
                <div className="col-md-12 center-whole pb-5 ">
                    <h3 className="slide-title-info mb-5 mb-md-0 wht-head-i"></h3>
                        {(spinloading) ? <div className='loader-container'><div className='loader'></div></div> : ""}
                    </div>
                <div className="row">
                {ads.map((s,i) => (
                <div key={i} className="col-md-4 mb-5 wow fadeInUp" data-wow-delay="0.2s">
                    <div className="col-md-12 pb-5 wr-round-border text-mid-cen px-0">
                    <div className="col-md-12 px-0 text-center pb-4 tp-menu-show"><img src={`${API}/ads/image/${s._id}`} className="img-fluid border-lef-right-radiius" alt={checkImgAlt(s.category_name)} /></div>
                    <div className="col-md-12">
                        <div className="col-md-12 safely-pad-top-gyap">
                        {(s.category_name.length>20)?
                        <p className="secu-sta" style={{lineHeight:'1.5'}}>{s.category_name}</p>:
                        <p className="secu-sta" style={{lineHeight:'1.5'}}>{s.category_name}<br/>&nbsp;</p>}
                        </div>
                        
                    
                    <div className="col-md-12 px-0 text-center pb-4 sh-img"><img src={`${API}/ads/image/${s._id}`} className="img-fluid" alt={checkImgAlt(s.category_name)} /></div>
                    <div className="col-md-12 mb-3">
                        <p className="prod-inc">Products included</p>
                        <div className="col-md-12">
                    
                        {checkProducts(s._id)}
                                
                        </div>
                    </div>
                    <div className="col-md-12 pt-4">
                        <div className="row">
                        <div className="col-md-6 price-fon pt-2 wid-50-pers">
                            
                            <div className="row">
                                {/* Strike off amount */}
                            {/* <div className="col-md-6 wid-50-pers line-trg">
                                <span className="font-min">£</span>{GetPrice(s._id)}
                                </div> */}
                        <div className="col-md-6 wid-50-pers red-txt"> <span className="font-min">£</span>{s.package_salePrice} </div>
                        <div className="clearfix"></div>
                        </div></div>
                        <div className="col-md-6 wid-50-pers"><a className="btn btn-success text-center mt-1" onClick={() => setAdToken(s._id,s.category_name,s.category_description,s.package_salePrice)} style={{fontSize: '0.8rem'}}>Buy Now</a>
                        
                        </div>
                        <div className="clearfix"></div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            ))}
                </div>
            </div>
        </section>
    )

    /* const showErrorEmail = () => (
        <div className="alert alert-danger" style={{ display: error_msg ? '' : 'none' }}>
            {error_msg}
        </div>
    ); */

    return (
        <NewLayout topTitle="SafelyTeam - Packages">
            
            <section> 
                
                <div className="col-md-12 px-0 pt-5">
                    <div className="col-md-12 px-0 inner-tp-pad ">                           
                        <div className="container abt-text-top-container">
                            <div className="col-md-12 ">
                                
                                <h1 className="text-center blue-acti">Choose your package</h1>   
                               {/*  {!isAuthenticated() && <><input ref={emailRef} className="form-control col-md-6 offset-3" onChange={handleChangeEmail('email')} type="email" placeholder="Enter your email here..." value={email} /><br/></>}     */}

                                {!isAuthenticated() && 
                                <div className="col-md-6 offset-3 plr-0px">
                                    <div className="row">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">

                                            <span style={{backgroundColor:'#21dfde'}} className="input-group-text get-offer"><span style={{border:'none',color:'white',  fontSize: '0.8rem',fontWeight: '600',background:'none'}}  >Enter your email</span></span>
                                        
                                        </div>
                                        <input style={{borderColor:'#21dfde'}} ref={emailRef} className="form-control input-bord" onChange={handleChangeEmail('email')} type="email" placeholder="eg. example@mail.com" value={email} />
                                    </div>
                                    </div>
                                </div>
                                }
  
                                    {showPackages()} 
                          
                                    {/* {showErrorEmail()}   */} 
                                    <div className={classes.root}>
                                
                                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                                    
                                            <Alert onClose={handleClose} severity="error">{error_msg}</Alert>
                                                                                        
                                        </Snackbar>
                                    </div>    
                            </div>        
                        </div>               
                    </div>             
                </div>
            </section> 
        </NewLayout>
    )
}

export default Packages
