import React, {useState, useEffect} from 'react'
import { Link, withRouter } from 'react-router-dom'
import {itemTotal} from './Priorities/cartHelpers'
import CustomScripts from '../../hooks/customscripts';
import { logout, isAuthenticated } from '../../auth/index'
import {getHomePage} from '../../admin/apiAdmin'
import { createPreorder } from './Priorities/apiUser'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

const Header = ({history}) => {   
    //CustomScripts('/themes/users/js/jquery.min.html');
    CustomScripts('/themes/users/js/bootstrap.bundle.min.js');
    //CustomScripts('/themes/users/js/owl.carousel.min.js');
    //CustomScripts('/themes/users/js/jquery.pagepiling.min.js');
    //CustomScripts('/themes/users/js/jquery.fancybox.min.js');
    //CustomScripts('/themes/users/js/jquery.validate.min.js');
    CustomScripts('/themes/users/js/script.js'); 
    //CustomScripts('/themes/users/js/wow.js');
    const [top_banner, setTopBanner] = useState('');

    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSucces] = useState(false);
    const [btnloading, setBtnloading] = useState(false);

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [open1 , setOpen1] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    }

    const handleClose1 = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen1(false);
    }

    const loadHome = () => {
        getHomePage().then(data => {
            if(data.error){
                console.log(data.error);
            } else {            
                if(data[0].top_banner){
                    setTopBanner(data[0].top_banner);
                }
            }
        })
    }

    useEffect(() => {
        loadHome()
    },[])

    const handleChange = (e) => {
        setError('')
        setSucces(false);
        setEmail(e.target.value)
    };

    const clickSubmit = (e) => {
        e.preventDefault();
        setBtnloading(true);
        setError('');
        setSucces(false);
        createPreorder({email,reg_type:2})
        .then(data => {
            if(data.error) {
                setError(data.error);
                setOpen(true)
                setBtnloading(false);
            } else {
                setError("");
                setEmail('');
                setSucces(true);
                setOpen1(true);
                setBtnloading(false)
            }
        });
    };

    

    return (
      <>
                <div className="wrapper">
                    <header id="header" className="header header-fixed">
                        <div className="header-bg"></div>
                       {/* <div className="col-md-12 pt-1 pb-2 btm-bor tp-menu-show">
                            <ul className="list-inline text-center mb-0">
                                                          
                            </ul>
                        </div> */}
                                <div className="container-fluid clearfix" id="sticky-nav" style={{paddingTop: '0.5rem'}}>
                                    <div className="col-md-8 pt-1 text-center pr-0 sh-img">
                                        <a href="/" title="Cart" className="ico-fo"><img src={require('./img/logo.png')}  width="75" alt="Logo | Home Security Solutions in UK | Mobile Monitoring" className="shopping-cart-icon"/></a>
                                        <a href="/revieworder" className="ico-fo"><img src={require('./img/cart.svg')} width="20" alt="Cart | Best DIY Smart Home Security Systems | Safely" />  <sup>
                                            <small className="cart-badge">{itemTotal()}</small>
                                            </sup>
                                        </a>
                                        {!isAuthenticated() && (
                                                <a href="/login" className="ico-fo"><img src={require('./img/account.svg')} style={{marginLeft:'5px'}} width="20" alt="Account - Best Smart Home Security Systems in the UK" title="Login" className="login-icon"/></a>
                                                )}
                                                {isAuthenticated() && (
                                                <a href="/user/dashboard" title="My Account" className="ico-fo"><img src={require('./img/account.svg')} style={{marginLeft:'5px'}} width="20" alt="Account - Best Smart Home Security Systems in the UK" title="My Account" className="my-account-icon"/></a>
                                                )}
                                            {/* <a href="/login#Intro" title="Login" className="ico-fo"><img src={require('./img/account.svg')} width="20" alt="" className="login-icon" /></a> */}
                                            {isAuthenticated() && (
                                                <span style={{cursor: 'pointer'}} onClick={() => logout(() => {
                                                    history.push("/login");
                                                })} className="ico-fo" ><img src={require('./img/logout.png')} style={{marginLeft:'5px'}} width="16" alt="Logout" title="Logout" /></span>
                                                )}
                                            <span style={{fontSize:'1.2rem',  marginLeft: '10px'}}><i className="ti-mobile"></i><a href="tel:0808 501 5388">0808 501 5388</a></span>  
                                            {window.location.href=="https://safelyteam.com/#choose-mob" || window.location.href=="https://safelyteam.com" ||window.location.href=="https://safelyteam.com/" || window.location.href=="https://www.safelyteam.com/#choose" || window.location.href=="https://www.safelyteam.com" ||window.location.href=="https://www.safelyteam.com/" ?
                                                <a href="/#choose-mob" style={{marginRight:'0px',marginLeft:'2px', marginTop:'10px'}}  data-src={window.location.href} className="ico-fo round-butt btn btn-primary" title="Packages">Packages</a>:
                                                <a href="/packages" style={{marginRight:'0px',marginLeft:'2px', marginTop:'10px'}} data-src={window.location.href} className="ico-fo round-butt btn btn-primary" title="Packages">Packages</a>
                                            }

                                            <a href="/step1" data-src={window.location.href} style={{marginRight:'0px', marginTop:'10px'}} className="ico-fo round-butt btn btn-primary mb-pt-5" title="Bespoke Systems">Bespoke Systems</a>        
                                                                            
                                    </div>
                           
                                    <button className="nav-toggle-btn a-nav-toggle menu-show">
                                        <span className="nav-toggle">
                                            <span className="stick stick-1"></span>
                                            <span className="stick stick-2"></span>
                                            <span className="stick stick-3"></span>
                                        </span>
                                    </button>

                                    <div className="col-md-12 tp-menu-show">
                                        <div className="row">
                                            <div className="col-md-2 text-left" style={{paddingTop: '0.3rem'}}>
                                                <a href="/"><img src={require('./img/logo.png')} className="logo-wi-mob" alt="Logo | Home Security Solutions in UK | Mobile Monitoring" /></a>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="header-contacts">
                                                    <nav className="navbar navbar-expand-sm bg-transparent navbar-dark justify-content-center">

                                                        <ul className="navbar-nav">
                                                    {/*  <li className="nav-item"><a className="nav-link" href="/aboutus">About us</a></li> */}
                                                            {/* <li className="nav-item">
                                                                <a className="nav-link" href="/how-it-works">How it works</a>
                                                            </li> */}
                                                            <li className="nav-item">
                                                                <a className="nav-link" href="/product-listing">Products</a>
                                                            </li>
                                                        {/*  <!-- Dropdown --> */}
                                                            <li className="nav-item dropdown ">
                                                                <a className="nav-link dropdown-toggle" href="#/" id="navbardrop" data-toggle="dropdown">
                                                                    Support
                                                                </a>
                                                                <div className="dropdown-menu drop-menu ">
                                                                    <a className="dropdown-item" href="/contactus">Contact us</a>
                                                                    <a className="dropdown-item" href="/faq">FAQ</a>      
                                                                    <a className="dropdown-item" href="https://safelyteam.zendesk.com/hc/en-gb" target="_blank">Help Centre</a>                                                       
                                                                </div>
                                                            </li>
                                                            <li className="nav-item"><a className="nav-link" href="/crime">Crime in your Area</a></li>
                                                            <li className="nav-item"> <a className="nav-link" href="http://blog.safelyteam.com/">Blog</a> </li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>

                                            <div className="col-md-5 pt-1 text-left pr-0">
                                                {/* <Link to="/" className="ico-fo"><img src={require('./img/search.svg')} width="20" alt="" /></Link> */}
                                                <a href="/revieworder" title="Cart" className="ico-fo"><img src={require('./img/cart.svg')} style={{marginTop:'8px'}} width="20" alt="Cart | Best DIY Smart Home Security Systems | Safely" className="shopping-cart-icon"/>
                                                    <sup>
                                                        <small className="cart-badge">{itemTotal()}</small>
                                                    </sup>
                                                </a>
                                                {!isAuthenticated() && (
                                                <a href="/login#Intro"   className="ico-fo"><img src={require('./img/account.svg')} style={{marginTop:'8px'}} width="20" alt="Account - Best Smart Home Security Systems in the UK" title="Login" className="login-icon"/></a>
                                                )}
                                                {isAuthenticated() && (
                                                <a href="/user/dashboard" title="My Account" className="ico-fo"><img src={require('./img/account.svg')} style={{marginTop:'8px'}} width="20" alt=" Account - Best Smart Home Security Systems in the UK" title="My Account" className="my-account-icon"/></a>
                                                )}
                                                {isAuthenticated() && (
                                                <span style={{cursor: 'pointer'}} onClick={() => logout(() => {
                                                    history.push("/login");
                                                })} className="ico-fo" ><img src={require('./img/logout.png')} style={{marginTop:'8px'}} width="16" alt="Logout" title="Logout" /></span>
                                                )}
                                                  <span style={{float:'right', marginTop:'8px'}}><a href="tel:0808 501 5388"><i className="ti-mobile" style={{fontSize:'1.2rem',marginRight:'3px'}}></i>0808 501 5388</a></span> 
                                                {window.location.href=="https://safelyteam.com/#choose" || window.location.href=="https://safelyteam.com" ||window.location.href=="https://safelyteam.com/" || window.location.href=="https://www.safelyteam.com/#choose" || window.location.href=="https://www.safelyteam.com" ||window.location.href=="https://www.safelyteam.com/" ?
                                                <a href="/#choose"  data-src={window.location.href} className="ico-fo btn btn-primary round-butt" title="packages">Packages</a>:
                                                <a href="/packages"  data-src={window.location.href} className="ico-fo btn btn-primary round-butt" title="packages">Packages</a>
                                                }
                                                <a href="/step1"  data-src={window.location.href} className="ico-fo btn btn-primary round-butt" title="Bespoke Systems">Bespoke Systems</a>       
                                                             
                                            </div>     
                                                                            
                                        </div>
                                    </div>
                                </div>            
                    </header>

                </div>

                <div className="hide-menu a-nav-toggle"></div>
                <div className="menu">
                    <nav className="menu-main" id="accordion">
                        <ul id="menuMain">
                            {/* <li data-menuanchor="Intro" className="active"><a href="/aboutus">About us</a></li> */}
                            {/* <li data-menuanchor="Services"><a href="#Services">For Business</a></li>
                            <li data-menuanchor="Projects"><a href="#Projects">Reviews</a></li> */}                                   
                            
                            <li data-menuanchor="Clients"><a href="/product-listing">Products</a></li>
                            {/*  <li data-menuanchor="Experience"><a href="/how-it-works">How it works</a></li> */}
                            <li data-menuanchor="Awards"><a href="/crime">Crime in your Area</a></li>
                            <li> <a href="http://blog.safelyteam.com/">Blog</a> </li>
                            <li data-menuanchor="Testimonials"><a href="https://safelyteam.zendesk.com/hc/en-gb" target="_blank">Support</a></li>
                            <li data-menuanchor="Testimonials"><a href="/contactus">Contact us</a></li>
                            <li data-menuanchor="Testimonials"><a href="/faq">FAQ</a></li>
                            <li data-menuanchor="Testimonials"><a href="https://safelyteam.zendesk.com/hc/en-gb" target="_blank">Help Centre</a> </li>
                        </ul>
                    </nav>
                </div>

                <section>
                    <div className="col-md-12 pt-4 bg-top-gra-one bot-mar1">
                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6 text-center text-col-offer bot-mar" dangerouslySetInnerHTML={{__html: top_banner}}></div>
                            <div className="col-md-3 right-padd-offer">                         
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text get-offer"><button style={{border:'none',  fontSize: '0.8rem',fontWeight: '600',background:'none'}} onClick={clickSubmit} disabled={btnloading} >{btnloading ? "Loading..." : "Get Offer"}</button></span>                                 
                                    </div>
                                    <input type="text" onChange={handleChange} value={email} className="form-control input-bord" placeholder="Enter Your Email"  />
                                    <em className="top-priv">You may receive email offers from us in accordance with our <a href="/privacy" target="_blank" className="top-priv-link">Privacy Policy</a>.</em>
                                    <div className={classes.root}>     

                                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>                                             
                                            <Alert onClose={handleClose} severity="error">{error}</Alert>                                                                                  
                                        </Snackbar>
                                        <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose1}>                                                                             
                                            <Alert onClose={handleClose} severity="success">Youe email has been succesfully subscribed.</Alert>                                                  
                                        </Snackbar>                                             
                                    </div>
                                </div>         
                            </div>
                        </div>
                    </div>
                </section> 
        </>
    )
}

export default withRouter(Header);
