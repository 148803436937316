import React, {Fragment, useState, useEffect} from 'react'
import { Redirect } from 'react-router-dom'
import Nav from '../Priorities/Nav'
import Footer from '../Footer'
import Subscribe from '../Subscribe'
import { getAdsbyId, getSubscriptions, getAdsProducts } from '../Priorities/apiUser'
import {Helmet} from 'react-helmet'

const AdReviewOrder = () => {

    const params = new URLSearchParams(window.location.search.substring(1));

    const adId = params.get("id");
    const source = params.get("source");

    const [spinloading, setSpinloading] = useState(true);

    const [valMsg, setvalMsg] = useState(false);

    const [ values, setValues] = useState({
        title:'',
        package_salePrice:''
    })

    const { title, package_salePrice} = values;

    const [plans, setPlans] = useState({
        type1: '',
        type2: '',
        type3: '',
        subscription_type:-1,
        error:'',
        packageprice:0,
        singlepackageprice:0,
        packagecount:1,
        type1class:'col-md-12 bord-line',
        type2class:'col-md-12 bord-line',   
        type3class:'col-md-12 bord-line',   
        success: false
    })

    const {type1,type2, type3, error,packageprice,singlepackageprice,packagecount, success,type1class,type2class, type3class} = plans;

    const [values2, setValues2] = useState({
        gold_price:'',
        silver_price:'',
        bronze_price:''
    })
    const { gold_price, silver_price, bronze_price} = values2;

    const [adsProducts, setAdsProducts] = useState([]);


    const loadAds = (Id) => {
        getAdsbyId(Id).then(data => {
            if(data.error){
                setSpinloading(false)
                setEmpty(true);
               
            }
            else{
                setValues({
                    ...values,
                    package_salePrice:data.package_salePrice,
                    title: data.category_name
                })
               
                loadAdsProducts(data._id);
            }
        })
    }

    const loadSubscriptions = () => {
        getSubscriptions()
        .then(data => {
            if(data.error) {
                setValues2({...values2,error2:data.error})
            } else {
                var tempGold=0;
                var tempSilver=0;
                var tempBronze=0;
                for(var i=0;i<data.length;i++){
                    if(data[i].plan === "Gold"){
                        tempGold = data[i].price;
                    }
                    else if(data[i].plan === "Silver"){
                        tempSilver = data[i].price;
                    }
                    else if(data[i].plan === "Bronze"){
                        tempBronze = data[i].price;
                    }
                }
                setValues2({
                ...values2,
                gold_price:tempGold,
                silver_price:tempSilver,
                bronze_price:tempBronze,
                error2:''  
                })
                
            }
        }) 
    }

    const loadAdsProducts = (Id) => {
        getAdsProducts({Id:Id}).then(data => {
            if (data.error) {
                console.log(data.error);
                
            } else {
                setAdsProducts(data);
                setSpinloading(false)
            
            }
        });
    };

    const [ empty, setEmpty] = useState(false);
    

    useEffect(() => {
        if(localStorage.getItem('package')){
            localStorage.removeItem('package');
            localStorage.removeItem('packageQnty');
            localStorage.removeItem('adpackageQnty');
            localStorage.removeItem('adpackage');
            localStorage.removeItem('type');
        }       

        if(adId && source){
            loadAds(adId);
            loadSubscriptions();
        }
        else {
            setSpinloading(false)
            setEmpty(true)
        }
        
    },[])


    const handleChange = name => event => {
        if(name === 'type1') {
            setvalMsg(false)
            setPlans({...plans, type1class:'col-md-12 bord-line true', type2class:'col-md-12 bord-line',  type3class:'col-md-12 bord-line', [name]:0,subscription_type:0,packagecount:1})
        } else if(name === 'type2') {
            setvalMsg(false)
            setPlans({...plans,[name]:1, type2class:'col-md-12 bord-line true', type1class:'col-md-12 bord-line', type3class:'col-md-12 bord-line',subscription_type:1,packagecount:1})
        }else if(name === 'type3') {
            setvalMsg(false)
            setPlans({...plans,[name]:2, type3class:'col-md-12 bord-line true', type1class:'col-md-12 bord-line', type2class:'col-md-12 bord-line', subscription_type:2,packagecount:1})
        } else {
            setPlans({...plans, [name]:event.target.value});
        }
    }

    const clickSubmit = (event) => {
        event.preventDefault();
       
        if(type1===0 || type2===1 || type3 === 2){
        setPlans({...plans, error: false });

        let packageproducts="";
        let packageprice="";
        let packagetempPrice="";
        let packageproductid="";
        let packageQuantity="";
        let packagetempQuantity="";
        let packageName="";
        adsProducts.map(p => {
          let qty=1;
          packageproducts+=p.productId.name+",";
          packageprice+=p.prod_price+",";
          packagetempPrice+=p.prod_price+",";
          packageproductid+=p.productId._id+",";
          packageQuantity+=qty+",";
          packagetempQuantity+=qty+",";
          packageName+=qty+" x "+p.productId.name+"<br/>";
        });
        //Set Package Title
        let PackageHead= title;   
        localStorage.setItem('adTypePackage', JSON.stringify({ adpackageproducts:[packageproducts.trim(',')],adpackagetempName:packageproducts.trim(','),adpackageprice:[packageprice.trim(',')],adpackageproductid:packageproductid.trim(','),adpackageQuantity:[packageQuantity.trim(',')],adpackagetempPrice:packagetempPrice.trim(','), adpackagetempQuantity:packagetempQuantity.trim(','),adpackageTotprice:[package_salePrice],adpackageName:[packageName],adpackageHead:[PackageHead] })) 
        localStorage.setItem('adTypePackageQnty', JSON.stringify({val:1})); 
        localStorage.setItem('adsType',JSON.stringify({adID: adId, source:source}))
        
        localStorage.setItem('AdType', JSON.stringify( plans ))
        
                setPlans({
                    ...plans,
                    type1: '',
                    type2: '',
                    type3:'',
                    subscription_type:'',
                    type1class:'',
                    type2class:'',  
                    type3class:'',                  
                    error:'',
                    success: true
                    
                })
                /* window.location.href="/ad/shipping"; */
            }
            else{
                setvalMsg(true);
                setPlans({
                    ...plans,
                    error:'Please Choose one Subscription model'
                })
            }
        
            
    }


    const adPackDetails = () => {
       
        if(adId){          
        return (     
            <tr>                        
                            
                <td>
                    {title}
                    <br></br>{/* <span dangerouslySetInnerHTML={{__html: packData.adpackageName}}></span>  */}
                </td>               
                <td>
             
                   
                    £ 0
                </td>      

          </tr>
        )}
    };


    const showSummary = () => {
        return (
            <div className="col-md-12 plr-0px">
                                <div className="container plr-0px">
                                    <div className="col-md-10 mx-auto mb-5 plr-0px">
                                        
                                        <table className="table table-bordered table-responsive table-review">
                                            <thead>
                                                <tr>
                                               
                                                <th>Package Name</th>
                                                <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>                        
                                                {(localStorage.getItem("package")) ? adPackDetails() : (localStorage.getItem("adpackage") && adPackDetails())}      
                                                {adPackDetails()}
                                            </tbody>
                                        </table>
                                    </div>

                                
                                    <>
                                    <div className="col-md-12 round-top-btm-pad">
<div className="row bg-rev-bl">

<div className="col-md-9 exp-padd-top">
  <p className="exp-text-lt pb-5"><span style={{fontWeight: 'bold'}}>Experience the full capabilities of your<br/> Safely Team System.</span> We are now offering 1-Month Free Gold <br/>monitoring in all our packages.</p>
</div>

<div className="col-md-3 px-0 sh-img pb-3">
  <img src={require('../img/round-month.png')} className="img-fluid"/>
</div>

</div>
<div className="col-md-3 px-0 tp-menu-show rount-month-pos">
  <img src={require('../img/round-month.png')} className="img-fluid"/>
</div>
</div>
                                    <div className=" col-md-12">
                                        <div className="row bg-light-inner choose-sub-padd pb-4">                    
                                               
                                              
                                        <div className="col-md-4 mb-3">
                                                    <div className={type3class}>
                                                    
                                                        <div className="row">
                                                            <div className="col-md-1 wid-5-per">
                                                                <div className="form-check">
                                                                    <label className="form-check-label">
                                                                        <input id="radio3" type="radio" value={type3} defaultChecked={type3} onChange={handleChange('type3')} className="form-check-input" name="optradio" required />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        <label for="radio3" className="col-md-11">
                                                            <div className="row">
                                                            <div className="col-md-9 py-3 wid-60-per-in">                                 
                                                                <h5>Bronze</h5>
                                                                <p>✔ Full access to Safely Connect App</p>
                                            <ul style={{marginLeft:'20px'}}>
                                                <li>&nbsp;Ability to arm/disarm system and monitor sensors from anywhere.</li>
                                                </ul>
                                            <p>✔ Instant App Notifications</p>
                                            <p>✔ Smart Rules & Scenes</p>
                                            <p>✔ 1-year warranty for all products</p>
                                            <p>✔ All 3rd Party Integrations</p>
                                            <p>✔ Device battery monitoring</p>
                                            <p>✔ Post Sales Service and Support Available</p>
                                            <p>✔ Customized pre-programming of all devices</p>
                                                                
                                                            </div>
                                                            
                                                            <div className="col-md-2 pt-3">
                                                                <h5>£{bronze_price}/mo</h5>
                                                            </div>
                                                            </div>
                                                            </label>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className={type1class}>
                                                    
                                                        <div className="row">
                                                            <div className="col-md-1 wid-5-per">
                                                                <div className="form-check">
                                                                    <label className="form-check-label">
                                                                        <input id="radio1" type="radio" value={type1} defaultChecked={type1} onChange={handleChange('type1')}  className="form-check-input" name="optradio" required />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <label for="radio1" className="col-md-11"> 
                                                            <div className="row">
                                                            <div className="col-md-9 py-3 wid-60-per-in">       
                                                                                                 
                                                                <h5>Silver</h5>
                                                                <p>✔ Full access to Safely Connect App</p>
                                                <p>✔ Instant App Notifications</p>
                                                <p>✔ Smart Rules & Scenes</p>
                                                <span>(Scenes allow you to create automated actions in your home using different devices. For example, you can create a scene that, when you get back from work at 6:30pm, turns the radio and kettle on for you.)</span>
                                                <p>✔ 1-year warranty for all products </p>
                                                <p>✔ All 3rd Party Integrations </p>
                                                <p>✔ Device battery monitoring </p>
                                                <p>✔ Post Sales Service and Support Available </p>
                                                <p>✔ Customized pre-programming of all devices </p>
                                                <p>✔ Cellular 4G Backup </p>
                                                <p>✔ Battery Backup</p>
                                                <ul style={{marginLeft:'20px'}}>
                                                <li>&nbsp;Provides connectivity during power or internet outages</li>
                                                </ul>
                                                                
                                                              
                                                            </div>

                                                            <div className="col-md-2 pt-3">
                                                                <h5>£{silver_price}/mo</h5>
                                                            </div>
                                                            </div>  
                                                            </label>  
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className={type2class}>
                                                    
                                                        <div className="row">
                                                            <div className="col-md-1 wid-5-per">
                                                                <div className="form-check">
                                                                    <label className="form-check-label">
                                                                        <input id="radio2" type="radio" value={type2} defaultChecked={type2} onChange={handleChange('type2')} className="form-check-input" name="optradio" required />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        <label for="radio2" className="col-md-11">
                                                            <div className="row">
                                                            <div className="col-md-9 py-3 wid-60-per-in">                                 
                                                                <h5>Gold</h5>
                                                                <p>✔ Full access to Safely Connect App</p>
                                        <p>✔ Instant App Notifications</p>
                                        <p>✔ Smart Rules & Scenes</p>
                                        <p>✔ All 3rd Party Integrations</p>
                                        <p>✔ Device battery monitoring</p>
                                        <p>✔ Post Sales Service and Support Available</p>
                                        <p>✔ Customized pre-programming of all devices</p>
                                        <p>✔ Cellular 4G Backup</p>
                                        <p>✔ Battery Backup</p>
                                        <ul style={{marginLeft:'20px'}}>
                                                <li>&nbsp;Provides connectivity during power or internet outages</li>
                                                </ul>
                                            <p>✔ 24/7 Live Monitoring for:</p>
                                            <ul style={{marginLeft:'20px'}}>
                                                <li>&nbsp;Security Alarms</li>
                                                <li>&nbsp;Assistance Alarms</li>
                                                <li>&nbsp;Safety Alarms(Flood & fire)</li>
                                                <li>&nbsp;Complete system health monitoring and tamper alarms</li>
                                            </ul>
                                            <p>✔ 2-Year Warranty</p>
                                            <p>✔ Exclusive 10% Discount on future orders</p>
                                                                
                                                            </div>
                                                            
                                                            <div className="col-md-2 pt-3">
                                                                <h5>£{gold_price}/mo</h5>
                                                            </div>
                                                            </div>
                                                            </label>
                                                        </div>

                                                    </div>
                                                </div>

                                                
                                               
                                        </div>
                                    </div>
</>
                             
                        
                                    <div className="col-md-12 plr-0px">
                                        <div className="row pt-5">
                                            
                                        </div>

                                      

                                     

                                        <div className="row py-3 mb-5">
                                            <div className="col-md-12">
                                            {(valMsg)?
                                                <div className='alert alert-danger'>{error}</div> :  showError()}
                                                {showSuccess()}
                                   
                                                <button onClick={clickSubmit} className="btn btn-success pull-right mb-3" data-toggle="modal" data-target="#send-request">Proceed to checkout</button>                         
                                            </div>                         
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
        )
    }

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            <span dangerouslySetInnerHTML={{__html: error}}></span>
        </div>
    );

    const showSuccess = () => (
       
       success && <Redirect to="/ad/shipping" /> 
    );

    const showEmptyCart = () => {
        if(empty){

        
        return (
                        <div className="col-md-12 px-0">
                            <div className="col-md-12" style={{backgroundColor: '#f5f5f5', border: 'solid 1px #e7e7e7', borderTop: '0px'}}>
                                <br/>
                                <h4 style={{textAlign:'center',color:'red'}}>Buying for the First Time? Go forward and build your customised package <a href="/step1">here</a>.</h4>
                                
                            </div>
                            <br/>
                            <br/>
                        </div>              
                
        )
    }
    }

    return (
        <Fragment style={{overflowY: 'scroll', overflowX: 'hidden'}}>
             <Helmet>
                <title>Safely Team – Choose Plan​</title>
            </Helmet>
                <Nav />
               
                        <div className="section pp-scrollable slide-dark slide-dark-footer">
                        
                            <div className="col-md-12 top-det-sp-in">

                                <div className="col-md-12 pt-5 text-center">
                                    <h3>Choose one subscription plan for your package</h3>
                                       
                                </div>
                                {(spinloading) ? <div className='loader-container'><div className='loader'></div></div> : ""}
                                {(!empty && !spinloading) && showSummary()}
                                {showEmptyCart()}

                                
                                
                            </div>
                            <Subscribe />
                        </div>
                        <Footer />
                   
                
            
        </Fragment>
    )
}

export default AdReviewOrder
