import React, {Fragment, useState} from 'react'
import { Redirect } from 'react-router-dom'
import { reset } from '../user/apiUser'
import Header from '../theme/user/Header'
import Footer from '../theme/user/Footer'
import {Helmet} from 'react-helmet'

const ChangePassword = ({match}) => {

    const [values, setValues] = useState({
        salt:'',
        userId:'',
        password: '',
        password1: '',
        error: '',
        success: false
    });

    const {password, password1, success, error} = values

    const [btnloading, setbtnloading] = useState(false);

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value});
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        setbtnloading(true);
        setValues({...values, error: false });
        reset({userId:match.params.userId,salt:match.params.salt,password, password1})
        .then(data => {
            if(data.error) {
                setValues({...values, error: data.error, success:false})
                setbtnloading(false)
            } else {
                setValues({
                    ...values,
                    password: '',
                    password1: '',
                    error: '',
                    success: true
                })
                setbtnloading(false)
            }
        })
    }

    const showError = () => (
        <>
        <br/>
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
        </>
    );

    const showSuccess = () => {
        if(success) {
            return <Redirect to="/login" />
        }
    };
    const showChangePwd = () => {
        return (
            <div className="row ptb-inner-login">
                <div className="col-md-3">      
                </div>
                <div className="col-md-6 center-whole">
                    <form onSubmit={clickSubmit}>
                        <div className="form-group">
                            <label className="text-muted">Enter New Password<span style={{color:"red"}}> *</span></label>
                            <input onChange={handleChange('password')} type="password" className="form-control" value={password}  required />
                        </div>
                        <div className="form-group">
                            <label className="text-muted">Confirm New Password<span style={{color:"red"}}> *</span></label>
                            <input onChange={handleChange('password1')} type="password" className="form-control" value={password1}  required />
                        </div>
                        <button disabled={btnloading} className="btn btn-success">{btnloading ? "Loading..." : "Change Password"}</button>
                    </form>
                </div>
                <div className="col-md-3">      
                </div>
            </div>
        )
    }

    return (
        <div>
            <Header />
            <Helmet>
                <title>SafelyTeam – Reset Your Password​​​</title>
            </Helmet>

            <section> 
                <div className="col-md-12 px-0 pt-5">
                    <div className="col-md-12 px-0 inner-tp-pad ">                             
                        <div className="container abt-text-top-container pt-5">
                            <div className="col-md-12 py-5">
                                <h1 className="text-center pt-5 blue-acti">Reset Your Password? </h1>                 
                                    {showChangePwd()}
                                    {showSuccess()}
                                    {showError()}
                            </div>
                        </div>
                    </div>
                                
                </div>
            </section> 
            <Footer />
        </div> 
                
    )
}

export default ChangePassword
