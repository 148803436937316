export const addItem = (item, next) => {
    let cart = []
    if(typeof window !== 'undefined') {
        if(localStorage.getItem('cart')) {
            cart = JSON.parse(localStorage.getItem('cart'))
        }
        cart.push({
            ...item,
            count: 1
        });

        cart = Array.from(new Set(cart.map((p) => (p._id)))).map(id => {
            return cart.find(p => p._id === id)
        });

        localStorage.setItem("cart", JSON.stringify(cart));
        
    }
};

export const addItem1 = (item, next) => {
    let cart = []
    if(typeof window !== 'undefined') {
        if(localStorage.getItem('cart1')) {
            cart = JSON.parse(localStorage.getItem('cart1'))
        }
        cart.push({
            ...item,
            count: 1
        });

        cart = Array.from(new Set(cart.map((p) => (p._id)))).map(id => {
            return cart.find(p => p._id === id)
        });

        localStorage.setItem("cart1", JSON.stringify(cart));
        
    }
};

export const addItemCount = (item, count) => {
    let cart = []
    if(typeof window !== 'undefined') {
        if(localStorage.getItem('cart')) {
            cart = JSON.parse(localStorage.getItem('cart'))
        }
        cart.push({
            ...item,
            count: count
        });

        cart = Array.from(new Set(cart.map((p) => (p._id)))).map(id => {
            return cart.find(p => p._id === id)
        });

        localStorage.setItem("cart", JSON.stringify(cart));
        
    }
};

export const addItemCount1 = (item, count) => {
    let cart = []
    if(typeof window !== 'undefined') {
        if(localStorage.getItem('cart1')) {
            cart = JSON.parse(localStorage.getItem('cart1'))
        }
        cart.push({
            ...item,
            count: count
        });

        cart = Array.from(new Set(cart.map((p) => (p._id)))).map(id => {
            return cart.find(p => p._id === id)
        });

        localStorage.setItem("cart1", JSON.stringify(cart));
        
    }
};


export const itemTotal = () => {
    if(typeof window !== "undefined") {
        if(localStorage.getItem("cart")) {
            return JSON.parse(localStorage.getItem("cart")).length;
        }
    }
    return 0;
};

export const getCart = () => {
    if(typeof window !== "undefined") {
        if(localStorage.getItem("cart")) {
            return JSON.parse(localStorage.getItem("cart"));
        }
    }
    return [];
}

export const getCart1 = () => {
    if(typeof window !== "undefined") {
        if(localStorage.getItem("cart1")) {
            return JSON.parse(localStorage.getItem("cart1"));
        }
    }
    return [];
}

export const getQues = () => {
    if(typeof window !== "undefined") {
        if(localStorage.getItem("ques")) {
            return JSON.parse(localStorage.getItem("ques"));
        }
    }
    return 0;
}

export const updateItem = (productId, count) => {
    let cart = [];
    if(typeof window !== "undefined") {
        if(localStorage.getItem("cart")) {
            cart = JSON.parse(localStorage.getItem("cart"));
        }

        cart.map((product, i) => {
            if(product._id === productId) {
                cart[i].count = count;
            }
        });

        localStorage.setItem("cart",JSON.stringify(cart));
    }
};

export const updateItem1 = (productId, count) => {
    let cart = [];
    if(typeof window !== "undefined") {
        if(localStorage.getItem("cart1")) {
            cart = JSON.parse(localStorage.getItem("cart1"));
        }

        cart.map((product, i) => {
            if(product._id === productId) {
                cart[i].count = count;
            }
        });

        localStorage.setItem("cart1",JSON.stringify(cart));
    }
};

export const removeItem = (productId) => {
    let cart = [];
    if(typeof window !== "undefined") {
        if(localStorage.getItem("cart")) {
            cart = JSON.parse(localStorage.getItem("cart"));
        }
        
        cart.map((product, i) => {
            if(product._id === productId) {
                cart.splice(i,1);
            }
        });

        localStorage.setItem("cart",JSON.stringify(cart));
    }
    window.location.reload();
    return cart;
};

export const removeItem1 = (productId) => {
    let cart = [];
    if(typeof window !== "undefined") {
        if(localStorage.getItem("cart")) {
            cart = JSON.parse(localStorage.getItem("cart"));
        }
        
        cart.map((product, i) => {
            if(product._id === productId) {
                cart.splice(i,1);
            }
        });

        localStorage.setItem("cart",JSON.stringify(cart));
    }   
    
};

export const removeItem2 = (productId) => {
    let cart = [];
    if(typeof window !== "undefined") {
        if(localStorage.getItem("cart1")) {
            cart = JSON.parse(localStorage.getItem("cart1"));
        }
        
        cart.map((product, i) => {
            if(product._id === productId) {
                cart.splice(i,1);
            }
        });

        localStorage.setItem("cart1",JSON.stringify(cart));
    }   
    
};

export const emptyCart = () => {
    if(typeof window !== 'undefined') {
        localStorage.removeItem('cart')
        
    }
};

export const emptyQues = () => {
    if(typeof window !== 'undefined') {
        localStorage.removeItem('ques')
    }
};

export const emptyType = () => {
    if(typeof window !== 'undefined') {
        localStorage.removeItem('type')
    }
};