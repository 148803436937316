import React, {useState,useEffect} from 'react'
import AdminLayout from "../theme/admin/AdminLayout"
import { isAuthenticated } from '../auth/index'
import { Redirect,Link } from "react-router-dom"
import { updatePreorder, getPreorder} from './apiAdmin'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const UpdatePreOrder = ({match}) => {

    const classes = useStyles();

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState({
        email: '',
        status: '',
        reg_type:'',
        error: '',
        btnloading:false,
        success: false
    });

    const {email,reg_type,status,btnloading,success, error} = values

    const { user, token} = isAuthenticated()

    const init = preOrderId => {
        getPreorder(preOrderId).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
                setLoading(false);
            } else {
                // populate the state
                setValues({
                    ...values,
                    email: data.email,
                    status: data.status,
                    reg_type: data.reg_type
                });
                setLoading(false);
            }
        });
    };


    useEffect(() => {
        init(match.params.preOrderId);
    }, []);

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value,btnloading:false});
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: false,btnloading:true });
        updatePreorder(user._id,token,{id:match.params.preOrderId,email, status, reg_type})
        .then(data => {
            if(data.error) {
                setValues({...values, error: data.error, success:false,btnloading:false})
            } else {
                setValues({
                    ...values,
                    email:'',
                    status: '',
                    error: '',
                    btnloading:false,
                    success: true
                })
            }
        })
    }

    const [regTypes, setRegTypes] = useState([
        {label:"Pre-Order Booking", value:"1"},
        {label:"Top Banner", value:"2"}
    ])

    const [statusTypes, setStatusTypes] = useState([
        {label:"Subscribe", value:"1"},
        {label:"UnSubscribe", value:"2"},
    ])
 
    const updateNewsletterForm = () => (
        <form onSubmit={clickSubmit}>
            <div className="form-group">
                <label className="text-muted">Email</label>
                <input onChange={handleChange('email')} type="text" className="form-control"  value={email}  readOnly/>      
            </div>

            <div className="form-group">
                 <label className="text-muted">Type</label>
                 <select required disabled
                    onChange={handleChange('reg_type')} 
                    className="form-control">
                       <option>Select type</option> 
                       {
                           regTypes.map(cus => (
                           (Number(reg_type) === Number(cus.value) ? <option key={cus.value} value={cus.value} selected>{cus.label}</option>
                           : <option key={cus.value} value={cus.value}>{cus.label}</option>)
                           ))
                       }
                </select>
            </div>

            <div className="form-group">
                 <label className="text-muted">Status</label>
                 <select required
                    onChange={handleChange('status')} 
                    className="form-control">
                        <option>Select type</option> 
                       {
                           statusTypes.map(cus => (
                           (Number(status) === Number(cus.value) ? <option key={cus.value} value={cus.value} selected>{cus.label}</option>
                           : <option key={cus.value} value={cus.value}>{cus.label}</option>)
                           ))
                       }
                                  
                 </select>
             </div>
             <button  className="btn btn-outline-primary" disabled={btnloading}> {btnloading ? "Loading..." : "Update"} </button>
            <div><Link className="nav-link" to="/admin/preorder/bookings">Go Back to List </Link></div>
        </form>
    );

    const showSuccess = () => {
        if(success) {
            return <Redirect to="/admin/preorder/bookings" />
        }
    };

    const showError = () => {
        if(error) {
            return <h3 className="text-danger">{error}</h3>
        }
    };

    return (
        <AdminLayout className="container" topTitle="Update Pre-Order">
                <div className="row">              
                    <div className="col-lg-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title" style={{textAlign:'center'}}>Update Pre-Order</h4>
                                    <div className="row">
                                        <div className="col-md-8 offset-md-2">
               
                                            {showSuccess()}
                                            {showError()}
                                            {!loading && updateNewsletterForm()}

                                            <Backdrop className={classes.backdrop} open={loading} >
                                                <CircularProgress color="inherit" />
                                            </Backdrop>  
                   
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminLayout>
    )
}

export default UpdatePreOrder
